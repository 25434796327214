import type { Property } from '@/domain/property';
import type {
  PropertyGroup,
  PropertyGroupAnalytics,
  PropertyGroupBulkInsertRequest,
  PropertyGroupDTO,
  PropertyGroupSearchResult,
} from '@/domain/property-groups';
import { api } from '@/utils/configuration';
import { crudService, getAxiosFetcher } from '@/utils/request';

export const PropertyGroupService = {
  search: getAxiosFetcher<PropertyGroupSearchResult[], []>(() => [api.PROPERTY_GROUPS]),

  findById: getAxiosFetcher<PropertyGroup, [number | string]>((id) => [
    `${api.PROPERTY_GROUPS}/${id}`,
  ]),

  findAnalyticsById: getAxiosFetcher<PropertyGroupAnalytics, [number | string]>((id) => [
    `${api.PROPERTY_GROUPS}/${id}/analytics`,
  ]),

  create: (propertyGroup: Partial<PropertyGroupDTO>) =>
    crudService.post<PropertyGroup>(propertyGroup, api.PROPERTY_GROUPS),

  update: (propertyGroup: Partial<PropertyGroup>) =>
    crudService.put<PropertyGroup>(propertyGroup, `${api.PROPERTY_GROUPS}/${propertyGroup.id}`),

  delete: (propertyGroup: Partial<PropertyGroup>) =>
    crudService.delete<void>(`${api.PROPERTY_GROUPS}/${propertyGroup.id}`),

  searchProperties: getAxiosFetcher<Property, [number | string]>((id) => [
    `${api.PROPERTY_GROUPS}/${id}/properties`,
  ]),

  addProperty: (id: number | string, property: Partial<Property>) =>
    crudService.post<void>(property, `${api.PROPERTY_GROUPS}/${id}/properties/${property.id}`),

  removeProperty: (id: number | string, property: Partial<Property>) =>
    crudService.delete<void>(`${api.PROPERTY_GROUPS}/${id}/properties/${property.id}`),

  bulkAddProperties: (id: number, propertyIds: number[]) =>
    crudService.put<void>(propertyIds, `${api.PROPERTY_GROUPS}/${id}/add-properties`),

  addPropertiesToPropertyGroups: (request: PropertyGroupBulkInsertRequest) =>
    crudService.put<void>(request, `${api.PROPERTY_GROUPS}/properties`),

  removePropertiesToPropertyGroups: (request: PropertyGroupBulkInsertRequest) =>
    crudService.delete<void>(`${api.PROPERTY_GROUPS}/properties`, { data: request }),
};
