import type { Column, RowHighlightInfo } from '@/@propify-components';
import { dateTimeColumn, Table } from '@/@propify-components';
import { ModalFormButton } from '@/components/Button/formButton';
import ExternalLink from '@/components/ExternalLink';
import { enumColumn } from '@/components/TableUtils';
import { useAppContext } from '@/contexts/AppContext';
import type { Application, ApplicationFile } from '@/domain/applications';
import { ApplicationFileStatus } from '@/domain/applications';
import type { UserLookup } from '@/domain/user';
import useEnumeration from '@/hooks/useEnumeration';
import FilesUploadModal from '@/pages/maintenance/components/FilesUploadModal/FilesUploadModal';
import { ApplicationsService } from '@/services/applications';
import { settings } from '@/services/settings';
import { handleError } from '@/utils/utils';
import { DeleteOutlined, EditOutlined, UploadOutlined } from '@ant-design/icons';
import { Button, Col, message, Popconfirm, Row, Tooltip } from 'antd';
import type { AxiosRequestConfig } from 'axios';
import moment from 'moment';
import type { FC } from 'react';
import { useMemo, useState } from 'react';
import ApplicationFileModal from './ApplicationFileModal';
import ApproveRejectFileModal from './ApproveRejectFileModal';

type Props = {
  application: Application;
  refetchApplication: () => void;
};

const ApplicationFilesTable: FC<Props> = ({ application, refetchApplication }) => {
  const [uploadFileId, setUploadFileId] = useState<number>();
  const [pageSize, setPageSize] = useState(10);
  const [selectedFile, setSelectedFile] = useState<ApplicationFile>();
  const [deleting, setDeleting] = useState(false);
  const [sendingReminderId, setSendingReminderId] = useState<number>();

  const { users } = useAppContext();

  const usersMapByLoginId = useMemo<Record<number, UserLookup>>(
    () =>
      users.reduce((map, user) => {
        return {
          ...map,
          [user.loginId]: user,
        };
      }, {}),
    [users],
  );

  const { data: applicationFileStatusEnum } = useEnumeration('application-file-status');

  const handleDeleteFileRequest = (id: number) => {
    setDeleting(true);
    ApplicationsService.deleteFileRequest(application.id, id)
      .then(() => {
        message.success('File request deleted');
        refetchApplication();
      })
      .catch((error) => {
        handleError(error, {
          toastFallbackMessage: 'There was an error deleting the file request',
        });
      })
      .finally(() => {
        setDeleting(false);
      });
  };

  const sendRequestReminder = (id: number) => {
    setSendingReminderId(id);
    ApplicationsService.sendFileRequestReminder(application.id, id)
      .then(() => {
        refetchApplication();
        message.success('The File Request Reminder was sent successfully');
      })
      .catch((error) => {
        handleError(error, {
          toastFallbackMessage: 'There was a problem sending the File Request Reminder',
        });
      })
      .finally(() => {
        setSendingReminderId(undefined);
      });
  };

  const columns: Column<ApplicationFile>[] = [
    enumColumn({ key: 'type', title: 'File Type' }, { enumName: 'application-file-type' }),
    {
      key: 'link',
      title: 'Link',
      render: (row) =>
        row.status === ApplicationFileStatus.REQUESTED ? (
          ''
        ) : (
          <ExternalLink key={row.id} href={row.url}>
            View
          </ExternalLink>
        ),
    },
    {
      key: 'uploadStatus',
      title: 'Upload Status',
      render: (row) =>
        row.status !== ApplicationFileStatus.REQUESTED ? 'Uploaded' : 'Not Uploaded',
    },
    dateTimeColumn({ title: 'Creation Date', key: 'createTime', width: 160 }),
    dateTimeColumn({ title: 'Submit Date', key: 'submitTime', width: 160 }),
    {
      key: 'status',
      title: 'Review Status',
      render: (row) => {
        switch (row.status) {
          case ApplicationFileStatus.REQUESTED:
            return (
              <Button
                onClick={() => sendRequestReminder(row.id)}
                type="primary"
                loading={row.id === sendingReminderId}
              >
                Send Reminder
              </Button>
            );
          case ApplicationFileStatus.SUBMITTED:
            return (
              <Button type="primary" onClick={() => setSelectedFile(row)}>
                Begin Review
              </Button>
            );
          case ApplicationFileStatus.REJECTED:
            return (
              <Tooltip placement="top" title={row.rejectionReason}>
                Rejected
              </Tooltip>
            );

          default:
            return applicationFileStatusEnum.find((f) => f.value === row.status)?.displayName;
        }
      },
    },
    {
      key: 'noteToApplicant',
      title: 'Note To Applicant',
      showTooltip: true,
    },
    {
      key: 'reviewedByLoginId',
      title: 'Review By',
      render: (row) =>
        usersMapByLoginId[row.reviewedByLoginId!]?.displayName ??
        (row.reviewedByLoginId ? `User ID ${row.reviewedByLoginId}` : ''),
    },
    dateTimeColumn({ title: 'Review Date', key: 'reviewTime', width: 160 }),
    dateTimeColumn(
      { title: 'Last Reminder Sent', key: 'analytics.lastReminderSentTime', width: 160 },
      { getValue: (r) => r.analytics?.lastReminderSentTime },
    ),
    {
      key: 'actions',
      title: 'Actions',
      hideSort: true,
      render: (row) => (
        <>
          {row.status === ApplicationFileStatus.REQUESTED && (
            <>
              <Button icon={<UploadOutlined />} onClick={() => setUploadFileId(row.id)} />

              <Popconfirm
                title="Are you sure?"
                okText="Yes"
                cancelText="No"
                onConfirm={() => handleDeleteFileRequest(row.id)}
                disabled={deleting}
              >
                <Button
                  type="link"
                  danger
                  icon={<DeleteOutlined color="red" />}
                  loading={deleting}
                  disabled={deleting}
                />
              </Popconfirm>
            </>
          )}
          {[ApplicationFileStatus.REQUESTED, ApplicationFileStatus.SUBMITTED].includes(
            row.status,
          ) && (
            <ModalFormButton icon={<EditOutlined />} onSuccess={() => refetchApplication()}>
              {(props) => (
                <ApplicationFileModal
                  {...props}
                  applicationId={application.id}
                  applicationFile={row}
                />
              )}
            </ModalFormButton>
          )}
        </>
      ),
    },
  ];

  const handleCreateFile = (file: File, _: unknown, config: AxiosRequestConfig) => {
    return ApplicationsService.submitFile(application.id, uploadFileId!, file, config);
  };

  const getRowHighlightProps = (row: ApplicationFile): RowHighlightInfo | null => {
    if (
      row.fileId &&
      moment(row.submitTime).isBefore(moment().subtract(30, 'days')) &&
      ![ApplicationFileStatus.ACCEPTED, ApplicationFileStatus.REJECTED].includes(row.status)
    ) {
      return {
        className: 'highlightOrange',
        tooltipText: 'This file was uploaded more than 30 days ago.',
      };
    }

    return null;
  };

  return (
    <>
      <Row justify="end" gutter={[16, 16]}>
        <Col>
          <ModalFormButton buttonText="Request File" onSuccess={() => refetchApplication()}>
            {(props) => <ApplicationFileModal {...props} applicationId={application.id} />}
          </ModalFormButton>
        </Col>
      </Row>

      <Table
        key={pageSize}
        pageSize={pageSize}
        onPageSizeChange={(value) => setPageSize(value)}
        data={application?.files}
        className="full-height"
        columns={columns}
        rowKeyExtractor={(row) => row.id}
        settings={settings('unit-application-file-information')}
        getRowHighlightProps={getRowHighlightProps}
      />

      {selectedFile && (
        <ApproveRejectFileModal
          applicationId={application.id!}
          file={selectedFile}
          onClose={() => setSelectedFile(undefined)}
          onSuccess={() => {
            setSelectedFile(undefined);
            refetchApplication();
          }}
        />
      )}

      <FilesUploadModal
        visible={uploadFileId !== undefined}
        onSuccess={() => {
          refetchApplication();
          setUploadFileId(undefined);
        }}
        onCancel={() => setUploadFileId(undefined)}
        onCreateFile={handleCreateFile}
        filesLimitAmount={1}
      />
    </>
  );
};

export default ApplicationFilesTable;
