import type { ModalFormProps } from '@/components/Button/formButton';
import { AntInput, AntTextArea } from '@/components/Formik/CreateAntField';
import { PropertyGroupService } from '@/services/property-groups';
import { handleError } from '@/utils/utils';
import { Form, message, Modal, Typography } from 'antd';
import { Field, Formik } from 'formik';
import type { FC } from 'react';
import * as Yup from 'yup';

type Values = {
  name: string;
  description: string;
};

interface Props extends ModalFormProps {
  propertyIds?: number[];
}

const validationSchema = Yup.object().shape({
  name: Yup.string().required('Property Group Name is required'),
  description: Yup.string().required('Description is required'),
});

const AddPropertyGroupModal: FC<Props> = ({ propertyIds = [], onSuccess, onCancel }) => {
  const submit = (values: Values) => {
    const requestValue = {
      ...values,
      propertyIds,
    };

    PropertyGroupService.create(requestValue)
      .then(() => {
        message.success('Property group created successfully');
        onSuccess();
      })
      .catch((error) => {
        handleError(error, {
          toastFallbackMessage: 'There was an error creating the property group',
        });
      });
  };

  return (
    <Formik<Values>
      onSubmit={submit}
      validationSchema={validationSchema}
      initialValues={{
        name: '',
        description: '',
      }}
    >
      {({ handleSubmit, submitCount, submitForm }) => (
        <Modal
          visible
          onCancel={onCancel}
          onOk={submitForm}
          okText="Add Property Group"
          title="Create Property Group"
          maskClosable={false}
          okButtonProps={{
            // @ts-ignore
            ['data-testid']: 'submit-button',
          }}
          wrapProps={{
            ['data-testid']: 'create-property-group-modal',
          }}
        >
          <Form labelCol={{ span: 8 }} wrapperCol={{ span: 20 }} onSubmitCapture={handleSubmit}>
            <p>
              <Typography.Text>{`A new Property Group will be created with the ${
                propertyIds.length
              } selected ${
                propertyIds.length > 1 ? 'properties' : 'property'
              } included`}</Typography.Text>
            </p>

            <Field
              component={AntInput}
              label="Property Group Name"
              name="name"
              type="text"
              hasFeedback
              submitCount={submitCount}
              data-testid="name-input"
            />

            <Field
              component={AntTextArea}
              label="Description"
              name="description"
              type="text"
              rows={4}
              hasFeedback
              submitCount={submitCount}
              data-testid="description-input"
              style={{ minHeight: '100px' }}
            />
          </Form>
        </Modal>
      )}
    </Formik>
  );
};

export default AddPropertyGroupModal;
