import type { EntityType } from '../types';

export const parseResponse = <T = any>(response: T[], entityType: EntityType) =>
  response.reduce((result, entity) => {
    const key = entity[entityType?.keyExpression || 'id'];
    const value = entity[entityType?.displayNameExpression || 'name'];

    return {
      ...result,
      [key]: value,
    };
  }, {});
