// Fix eslint warnings in separate ticket: https://skyboxcapital.atlassian.net/browse/PN-8348
/* eslint-disable @typescript-eslint/no-explicit-any */
import { SHORT_DATE_TIME_FORMAT_WITH_TIMEZONE } from '@/utils/time';
import get from 'lodash/get';
import moment from 'moment-timezone';
import type { CSSProperties, ReactNode } from 'react';
import type { FormatCurrencyOptions, Suggestions } from '../../shared/utils';
import { formatCurrency, formatPercentage } from '../../shared/utils';
import type { TooltipProps } from '../../shared/utils/material';
import {
  SHORT_DATE_FORMAT,
  SHORT_DATE_TIME_FORMAT,
  SHORT_TIME_FORMAT,
} from '../../shared/utils/time';

export interface ColumnHeaderTooltip {
  placement?: TooltipProps['placement'];
  title: string | NonNullable<ReactNode>;
  showInfoIcon?: boolean;
}

export interface Column<T = any> {
  title: string;
  renderTitle?: () => NonNullable<ReactNode>;
  key: Suggestions<Extract<keyof T, string>>;
  dataIndex?: string;
  align?: Align;
  class?: string;
  width?: number;
  render?: (item: T, itemIndex: number) => any;
  sortBy?: string;
  sortFn?: (a: T, b: T) => number;
  total?: any;
  minWidth?: number;
  maxWidth?: number;
  visible?: boolean;
  hideSort?: boolean;
  getValueForExport?: (item: T) => any;
  className?: (item: T) => string;
  showTooltip?: boolean;
  renderTooltip?: (item: T, itemIndex: number) => any;
  autoWidth?: boolean;
  onFilter?: (row: T, index: number) => void;
  styles?: (item: T) => CSSProperties;
  filterTooltip?: string;
  headerTooltip?: ColumnHeaderTooltip;
}

export enum Align {
  left = 'left',
  center = 'center',
  right = 'right',
}

export enum SortDirection {
  ASCENDING = 'asc',
  DESCENDING = 'desc',
}

export interface RowHighlightInfo {
  className?: string;
  tooltipText?: string;
}

const dateSortFn = <T = any>(getValue?: (row: T) => any) =>
  getValue ? (a: T, b: T) => ((getValue(a) || '') > (getValue(b) || '') ? 1 : -1) : undefined;

export const dateTimeColumnRender = <T = any>(
  data: T,
  key: keyof T,
  getValue?: (rowData: T) => any,
) => {
  const value = getValue ? getValue(data) : data[key];
  return value ? moment(value).format(SHORT_DATE_TIME_FORMAT) : '';
};

export interface ColumnSettings<T = any> {
  getValue?: (rowData: T) => any;
  defaultValue?: ReactNode;
}

export interface PercentageDiffColumnSettings<T> extends ColumnSettings<T> {
  positiveColor?: CSSProperties['color'];
  negativeColor?: CSSProperties['color'];
}

export const dateTimeColumn = <T = any>(column: Column<T>, settings?: ColumnSettings<T>) => ({
  width: 170,
  sortBy: !settings?.getValue ? column.key : undefined,
  render: (data: T) => dateTimeColumnRender(data, column.key as keyof T, settings?.getValue),
  sortFn: dateSortFn(settings?.getValue),
  ...column,
});

export interface DateTimeColumnWithTimezoneSettings<T> extends ColumnSettings<T> {
  getTimezone?: (data: T) => string | undefined;
}

export const dateTimeColumnWithTimezone = <T = any>(
  column: Column<T>,
  settings?: DateTimeColumnWithTimezoneSettings<T>,
) => ({
  width: 170,
  sortBy: !settings?.getValue ? column.key : undefined,
  render: (data: T) => {
    const value = settings?.getValue ? settings?.getValue(data) : data[column.key as keyof T];
    if (!value) {
      return undefined;
    }
    const timezone = settings?.getTimezone?.(data);
    return timezone
      ? moment.tz(value, timezone).format(SHORT_DATE_TIME_FORMAT_WITH_TIMEZONE)
      : moment(value).local().format(SHORT_DATE_TIME_FORMAT);
  },
  sortFn: dateSortFn(settings?.getValue),
  ...column,
});

export const dateColumn = <T = any>(
  column: Column<T>,
  settings?: ColumnSettings<T>,
): Column<T> => ({
  width: 120,
  sortBy: !settings?.getValue ? column.key : undefined,
  render: (data: T) => {
    const value = settings?.getValue ? settings?.getValue(data) : data[column.key as keyof T];
    return value ? moment(value).format(SHORT_DATE_FORMAT) : settings?.defaultValue || '';
  },
  sortFn: dateSortFn(settings?.getValue),
  ...column,
});

export const booleanColumn = <T = any>(
  column: Column<T>,
  settings?: ColumnSettings<T>,
): Column<T> => ({
  width: 120,
  sortBy: column.key,
  render: (data: T) => {
    const value: boolean = settings?.getValue
      ? settings?.getValue(data)
      : data[column.key as keyof T];
    return value ? 'Yes' : 'No';
  },
  ...column,
});

export const dateColumnBrowserTime = <T = any>(
  column: Column<T>,
  settings?: ColumnSettings<T>,
) => ({
  width: 100,
  type: 'date',
  sortBy: !settings?.getValue ? column.key : undefined,
  render: (data: any) => {
    const value = settings?.getValue ? settings?.getValue(data) : data[column.key];
    // FIXME: Reading unit object is wrong
    const { timezone } = data.unit.property;
    return value ? moment.tz(value, timezone).local().format(SHORT_DATE_FORMAT) : undefined;
  },
  sortFn: dateSortFn(settings?.getValue),
  ...column,
});

export const timeColumn = <T = any>(column: Column<T>, settings?: ColumnSettings<T>) => ({
  width: 100,
  render: (data: T) => {
    const value = settings?.getValue ? settings?.getValue(data) : data[column.key as keyof T];
    return value ? moment(`1970-01-01T${value}`).format(SHORT_TIME_FORMAT) : undefined;
  },
  ...column,
});

type Getter<T = any> = (value: any) => T;

const renderColumn =
  (renderer: Getter<ReactNode>) =>
  <T = any>(column: Column<T>, settings?: ColumnSettings<T>) => ({
    width: 130,
    align: Align.right,
    sortBy: column.sortBy || (!settings?.getValue ? column.key : undefined),
    render: (data: T) => {
      const value = settings?.getValue ? settings?.getValue(data) : data[column.key as keyof T];

      if (settings?.defaultValue && (value === undefined || value === null)) {
        return settings.defaultValue;
      }

      return renderer(value);
    },
    sortFn:
      column.sortFn ||
      (settings?.getValue
        ? (a, b) => (settings.getValue?.(a) > settings?.getValue?.(b) ? 1 : -1)
        : undefined),
    ...column,
  });

export const percentageColumn = renderColumn(formatPercentage);

type CurrencyColumnSettings<T> = ColumnSettings<T> & FormatCurrencyOptions;

export const currencyColumn = <T = any>(
  column: Column<T>,
  settings?: CurrencyColumnSettings<T>,
) => ({
  width: 130,
  align: Align.right,
  sortBy: column.sortBy || (!settings?.getValue ? column.key : undefined),
  render: (data: T) => {
    const value = settings?.getValue ? settings?.getValue(data) : data[column.key as keyof T];

    if (settings?.defaultValue && (value === undefined || value === null)) {
      return settings.defaultValue;
    }

    return formatCurrency(value, {
      disableAccountingStyle: settings?.disableAccountingStyle,
      noEmptyDecimals: settings?.noEmptyDecimals,
    });
  },
  sortFn:
    column.sortFn ||
    (settings?.getValue
      ? (a, b) => (settings.getValue?.(a) > settings?.getValue?.(b) ? 1 : -1)
      : undefined),
  ...column,
});

export const percentageDiffColumn = <T = any>(
  column: Column<T>,
  settings?: PercentageDiffColumnSettings<T>,
) => ({
  align: Align.right,
  width: 140,
  render: (row: T) => {
    const value = settings?.getValue ? settings?.getValue(row) : row[column.key as keyof T];
    if (value === undefined || isNaN(value)) {
      return '';
    }
    return formatPercentage(value);
  },
  styles: (row: T): CSSProperties => {
    const value = settings?.getValue ? settings?.getValue(row) : row[column.key as keyof T];
    if (value === undefined || isNaN(value)) {
      return {};
    }
    return {
      color: value >= 0 ? settings?.positiveColor || 'green' : settings?.negativeColor || 'red',
    };
  },
  sortBy: column.sortBy || (!settings?.getValue ? column.key : undefined),
  sortFn:
    column.sortFn ||
    (!column.sortBy
      ? (a, b) => {
          const valueA = settings?.getValue ? settings?.getValue(a) : a[column.key as keyof T];
          const valueB = settings?.getValue ? settings?.getValue(b) : b[column.key as keyof T];

          if (valueA === undefined || valueA === null || isNaN(valueA)) {
            return -1;
          }

          if (valueB === undefined || valueB === null || isNaN(valueB)) {
            return 1;
          }

          return valueA > valueB ? 1 : -1;
        }
      : undefined),
  ...column,
});

export const isColumnVisible = (column: Column) => column.visible !== false;

const collator = new Intl.Collator('en', {
  numeric: true,
  sensitivity: 'base',
});

const getDisplayValue = (value: any) => {
  if (value && value.props && value.props.children) {
    return value.props.children;
  }
  return value;
};

export const sorter =
  (columns: Column[], sortBy: string, sortDir: SortDirection) => (a: any, b: any) => {
    const column = columns.find((each) => (each.sortBy || each.key) === sortBy);
    if (!column) {
      return 0;
    }

    if (column.sortFn) {
      return (sortDir === SortDirection.ASCENDING ? 1 : -1) * column.sortFn(a, b);
    }

    let valueA = get(a, column.key);
    let valueB = get(b, column.key);

    if (column.render && !column.sortBy) {
      const displayValueA = getDisplayValue(column.render(a, 0));
      const displayValueB = getDisplayValue(column.render(b, 0));
      const firstValue = displayValueA.props?.displayValue || displayValueA || '';
      const secondValue = displayValueB.props?.displayValue || displayValueB || '';
      // when the column is a react element
      valueA = `${firstValue}`.replace(/[^0-9a-zA-Z.]/g, '');
      valueB = `${secondValue}`.replace(/[^0-9a-zA-Z.]/g, '');
    }

    if (typeof valueA === 'number' && typeof valueB === 'number') {
      return sortDir === SortDirection.ASCENDING ? valueA - valueB : valueB - valueA;
    }

    return sortDir === SortDirection.ASCENDING
      ? collator.compare(valueA || '', valueB || '')
      : collator.compare(valueB || '', valueA || '');
  };

export const updateColumn = (columns: Column[], column: Partial<Column>) => {
  const columnIndex = columns.findIndex((col) => col.key === column.key);

  if (columnIndex === -1) {
    columns.push(column as Column);
    return;
  }

  // eslint-disable-next-line no-param-reassign
  columns[columnIndex] = {
    ...columns[columnIndex],
    ...column,
  };
};

export const DEFAULT_COLUMN_WIDTH = 150;
export const DEFAULT_COLUMN_MIN_WIDTH = 60;
export const DEFAULT_COLUMN_MAX_WIDTH = 375;
