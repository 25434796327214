import type { ModalFormProps } from '@/components/Button/formButton';
import PropertyGroupsTable from '@/components/PropertyGroupsTable/PropertyGroupsTable';
import { PropertyGroupService } from '@/services/property-groups';
import { handleError } from '@/utils/utils';
import { Alert, message, Modal, Space } from 'antd';
import { useState, type FC } from 'react';
import styles from './styles.module.less';

export interface Props extends ModalFormProps {
  propertyIds: number[];
}

const RemovePropertiesFromPropertyGroupsModal: FC<Props> = ({
  onSuccess,
  onCancel,
  propertyIds,
}) => {
  const [loading, setLoading] = useState(false);
  const [selection, setSelection] = useState<number[]>([]);

  const handleSubmit = async () => {
    if (selection.length === 0) {
      return;
    }

    try {
      setLoading(true);
      await PropertyGroupService.removePropertiesToPropertyGroups({
        propertyGroupIds: selection,
        propertyIds,
      });
      message.success('Properties removed from property group(s)');
      onSuccess();
    } catch (error) {
      handleError(error, {
        toastFallbackMessage:
          'There was an error removing the properties from the property group(s)',
      });
    } finally {
      setLoading(false);
    }
  };

  const alertMessage = `Remove the ${
    propertyIds.length === 1 ? 'selected property' : propertyIds.length + ' selected properties'
  } from the Property Group(s) selected from the table below.`;

  return (
    <Modal
      title="Remove From Property Group(s)"
      visible
      onCancel={onCancel}
      onOk={() => handleSubmit()}
      okText="Remove From Group(s)"
      okType="danger"
      okButtonProps={{ loading, disabled: selection.length === 0 }}
      width="1000px"
      wrapProps={{ 'data-testid': 'modal-remove-properties' }}
    >
      <Space direction="vertical" size={10} style={{ width: '100%' }}>
        <Alert message={alertMessage} type="info" showIcon />
        <PropertyGroupsTable
          selection={selection}
          setSelection={setSelection}
          className={styles.propertyGroupsTable}
        />
      </Space>
    </Modal>
  );
};

export default RemovePropertiesFromPropertyGroupsModal;
