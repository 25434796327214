import NoteColumn from '@/components/Notes/NoteColumn/NoteColumn';
import type { EntityType as BaseEntityType } from '@/domain/entity-type';
import type { Note } from '@/domain/note';
import type { EntityType } from '@/notmagic/types';
import type { FC } from 'react';

type Props = {
  rootEntityType: EntityType;
  rootEntityId: number;
  value?: Note;
  onValidateEntity: () => void;
};

const Component: FC<Props> = ({ value, rootEntityType, rootEntityId, onValidateEntity }) => {
  return (
    <NoteColumn
      entityType={(rootEntityType.name ?? (rootEntityType.name as unknown)) as BaseEntityType}
      entityId={rootEntityId}
      note={value}
      onNoteChange={onValidateEntity}
    />
  );
};

export default {
  Component,
  getValueForExport: (value: any) => value?.note,
  hideSort: true,
};
