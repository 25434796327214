import type { ClassNameMap } from '../../shared/utils/material';
import type { Column } from '../index';
import FooterCell from './FooterCell';
import StickyColumns from './StickyColumns';

interface Props {
  columns: Column[];
  isSelectable: boolean;
  isDraggable: boolean;
  pinnedColumns: string[];
  styles: ClassNameMap<string>;
}

const Footer = (props: Props) => (
  <div style={{ display: 'flex', flex: 1 }} data-test="tableFooter">
    <StickyColumns
      renderContainer={(children) => <>{children}</>}
      columns={props.columns}
      isSelectable={props.isSelectable}
      isDraggable={props.isDraggable}
      renderCell={(column) => <FooterCell key={column.key} column={column} styles={props.styles} />}
      pinnedColumns={props.pinnedColumns}
      styles={props.styles}
    />
  </div>
);

export default Footer;
