import type {
  Leasing,
  LeasingCancelRequest,
  LeasingSearchParams,
  LeasingSearchResult,
} from '@/domain/leasing';
import { api } from '@/utils/configuration';
import { crudService, getAxiosFetcher } from '@/utils/request';

export const LeasingService = {
  findById: getAxiosFetcher<LeasingSearchResult, [id: number]>((id) => [`${api.LEASINGS}/${id}`]),

  search: getAxiosFetcher<LeasingSearchResult[], [LeasingSearchParams?]>((params) => [
    api.LEASINGS,
    { params },
  ]),

  cancel: (leasingId: number, version: number, body: LeasingCancelRequest) =>
    crudService.post<LeasingSearchResult>(body, `${api.LEASINGS}/${leasingId}/cancel`, {
      params: { version },
    }),

  update: (leasing: Leasing) =>
    crudService.put<LeasingSearchResult>(leasing, `${api.LEASINGS}/${leasing.id}`),

  remind: (leasingId: number) => crudService.post<void>({}, `${api.LEASINGS}/${leasingId}/remind`),
};
