import type { New } from '@/domain/base';
import type { ScopeItem } from '@/domain/scope-items';
import type {
  ReOpenWorkOrderBody,
  WorkOrder,
  WorkOrderActivity,
  WorkOrderActivityCommentsRequest,
  WorkOrderBulkUpdateRequest,
  WorkOrderFile,
  WorkOrderFileRequest,
  WorkOrderImage,
  WorkOrderImageBulkUpdateRequest,
  WorkOrderImageRequest,
  WorkOrderPriority,
  WorkOrderScheduleProposal,
  WorkOrderScopeItem,
  WorkOrderScopeItemImageRequest,
  WorkOrderScopeItemSearchResult,
  WorkOrdersCount,
  WorkOrderSearchParams,
  WorkOrderSearchResult,
  WorkOrderVendorRequest,
} from '@/domain/work-order';
import { api } from '@/utils/configuration';
import { crudService, getAxiosFetcher } from '@/utils/request';
import type { AxiosRequestConfig } from 'axios';
import type { Operation } from 'fast-json-patch';
import queryString from 'query-string';

interface CompleteWorkOrderBody {
  completionNote?: string;
}

export const WorkOrdersService = {
  search: getAxiosFetcher<WorkOrderSearchResult[], [WorkOrderSearchParams?]>((params) => [
    api.WORK_ORDERS,
    { params },
  ]),

  findById: getAxiosFetcher<WorkOrder, [number]>((id) => [`${api.WORK_ORDERS}/${id}`]),

  create: (workOrder: Partial<WorkOrder>) =>
    crudService.post<WorkOrder>(workOrder, api.WORK_ORDERS),

  update: (workOrder: Partial<WorkOrder>) =>
    crudService.put<WorkOrder>(workOrder, `${api.WORK_ORDERS}/${workOrder.id}`),

  addToJobs: (jobId: number, ids: number[]) =>
    crudService.put<WorkOrder>({ ids, jobId }, api.WORK_ORDERS),

  updateBulk: (request: WorkOrderBulkUpdateRequest) =>
    crudService.put<WorkOrder>(request, api.WORK_ORDERS),

  accept: (id: number, version: number) =>
    crudService.post<WorkOrder>({}, `${api.WORK_ORDERS}/${id}/accept?version=${version}`),

  assignVendor: (id: number, body: WorkOrderVendorRequest, version: number) =>
    crudService.post<WorkOrder>(body, `${api.WORK_ORDERS}/${id}/assign?version=${version}`),

  unassignVendor: (id: number, body: WorkOrderActivityCommentsRequest, version: number) =>
    crudService.post<WorkOrder>(body, `${api.WORK_ORDERS}/${id}/unassign?version=${version}`),

  complete: (id: number, version: number, body?: CompleteWorkOrderBody) =>
    crudService.post<WorkOrder>(body, `${api.WORK_ORDERS}/${id}/finalize?version=${version}`),

  getPriorities: getAxiosFetcher<WorkOrderPriority[], []>(() => [`${api.WORK_ORDERS}/priority`]),

  updatePriority: (workOrder: Partial<WorkOrderPriority>) =>
    crudService.put<WorkOrderPriority>(workOrder, `${api.WORK_ORDERS}/priority/${workOrder.id}`),

  bulkImagesDelete: (workOrderId: number, imageIds: number[]) =>
    crudService.delete<void>(`${api.WORK_ORDERS}/${workOrderId}/images?imageIds=${imageIds}`),

  bulkImagesUpdate: (workOrderId: number, request: WorkOrderImageBulkUpdateRequest) =>
    crudService.put<WorkOrder>(request, `${api.WORK_ORDERS}/${workOrderId}/images`),

  createPriority: (workOrder: Partial<WorkOrderPriority>) =>
    crudService.post<WorkOrderPriority>(workOrder, `${api.WORK_ORDERS}/priority`),

  uploadImage: (
    workOrderId: number,
    file: File,
    request: WorkOrderImageRequest,
    config?: AxiosRequestConfig,
  ) => {
    const formData = new FormData();

    formData.append('file', file);
    formData.append('request', new Blob([JSON.stringify(request)], { type: 'application/json' }));

    return crudService.post<WorkOrderImage>(
      formData,
      `${api.WORK_ORDERS}/${workOrderId}/images`,
      config,
    );
  },

  deleteImage: (workOrderId: number, imageId: number) =>
    crudService.delete<void>(`${api.WORK_ORDERS}/${workOrderId}/images/${imageId}`),

  getActivities: getAxiosFetcher<WorkOrderActivity[], [number]>((id) => [
    `${api.WORK_ORDERS}/${id}/activities`,
  ]),

  uploadFile: (
    workOrderId: number,
    file: File,
    request: WorkOrderFileRequest = {},
    config?: AxiosRequestConfig,
  ) => {
    const formData = new FormData();

    formData.append('file', file);
    formData.append('request', new Blob([JSON.stringify(request)], { type: 'application/json' }));

    return crudService.post<WorkOrderFile>(
      formData,
      `${api.WORK_ORDERS}/${workOrderId}/files`,
      config,
    );
  },

  deleteFile: (workOrderId: number, fileId: number) =>
    crudService.delete<void>(`${api.WORK_ORDERS}/${workOrderId}/files/${fileId}`),

  bulkDelete: (workOrderId: number, fileIds: number[]) =>
    crudService.delete<void>(`${api.WORK_ORDERS}/${workOrderId}/files?fileIds=${fileIds}`),

  cancel: (workOrder: Partial<WorkOrder>) =>
    crudService.post<WorkOrder>(
      workOrder,
      `${api.WORK_ORDERS}/${workOrder.id}/cancel?version=${workOrder.version}`,
    ),

  patch: (id: number, operations: Operation[], version: number) =>
    crudService.patch<WorkOrder>(operations, `${api.WORK_ORDERS}/${id}?version=${version}`),

  reOpen: (id: number, version: number, body: ReOpenWorkOrderBody) =>
    crudService.post<WorkOrder>(
      body,
      `${api.WORK_ORDERS}/${id}/reopen?${queryString.stringify({ version })}`,
    ),

  getDefaultUserGroupId: getAxiosFetcher<number, []>(() => [
    `${api.WORK_ORDERS}/default-user-group-id`,
  ]),

  getCounts: getAxiosFetcher<WorkOrdersCount[], []>(() => [`${api.WORK_ORDERS}/counts`]),

  getScheduleProposals: getAxiosFetcher<WorkOrderScheduleProposal[], [id: number]>((id) => [
    `${api.WORK_ORDERS}/${id}/schedule-proposals`,
  ]),

  getScopeItems: getAxiosFetcher<WorkOrderScopeItemSearchResult[], [id: number]>((id) => [
    `${api.WORK_ORDERS}/${id}/scope-items`,
  ]),

  addScopeItem: (workOrderId: number, scopeItem: New<ScopeItem>) =>
    crudService.post<ScopeItem>(scopeItem, `${api.WORK_ORDERS}/${workOrderId}/scope-items`),

  patchScopeItem: (
    workOrderId: number,
    scopeItemId: number,
    operations: Operation[],
    version: number,
  ) =>
    crudService.patch<WorkOrderScopeItem>(
      operations,
      `${api.WORK_ORDERS}/${workOrderId}/scope-items/${scopeItemId}?version=${version}`,
    ),

  deleteScopeItem: (workOrderId: number, scopeItemId: number) =>
    crudService.delete<void>(`${api.WORK_ORDERS}/${workOrderId}/scope-items/${scopeItemId}`),

  associateImagesWithScopeItem: (
    workOrderId: number,
    scopeItemId: number,
    payload: WorkOrderScopeItemImageRequest,
  ) =>
    crudService.post<void>(
      payload,
      `${api.WORK_ORDERS}/${workOrderId}/scope-items/${scopeItemId}/images`,
    ),
};
