import type { Enum } from '@/domain/base';
import type {
  InsertPropertyDTO,
  ListingImage,
  Property,
  PropertyBulkUpdateRequest,
  PropertySearchResult,
  PropertyUpdateRequest,
  TransactionStatus,
  Valuation,
} from '@/domain/property';
import type { ListingStatus, UnitStatus } from '@/domain/unit';
import { api } from '@/utils/configuration';
import { crudService, getAxiosFetcher } from '@/utils/request';
import type { Operation } from 'fast-json-patch';

export interface PropertySearchParams {
  keyword?: string;
  state?: string | string[];
  communityId?: number;
  transactionStatus?: TransactionStatus | TransactionStatus[];
  promotionId?: number;
  propertyGroupId?: number;
  unitListingStatus?: ListingStatus | ListingStatus[];
  unitStatus?: UnitStatus | UnitStatus[];
}

export const PropertyService = {
  findById: getAxiosFetcher<Property, [id: number | string]>((id) => [`${api.PROPERTIES}/${id}`]),

  search: getAxiosFetcher<PropertySearchResult[], [PropertySearchParams?]>((params) => [
    api.PROPERTIES,
    { params },
  ]),

  create: (property: InsertPropertyDTO) => crudService.post<Property>(property, api.PROPERTIES),

  bulk: (request: InsertPropertyDTO[]) => crudService.post<void>(request, `${api.PROPERTIES}/bulk`),

  update: (property: PropertyUpdateRequest) =>
    crudService.put<Property>(property, `${api.PROPERTIES}/${property.id}`),

  patch: (id: number, operations: Operation[], version: number) =>
    crudService.patch<Property>(operations, `${api.PROPERTIES}/${id}?version=${version}`),

  searchImages: getAxiosFetcher<ListingImage[], [propertyId: number]>((propertyId) => [
    `${api.PROPERTIES}/${propertyId}/images`,
  ]),

  moveImage: (propertyId: number, request: any) =>
    crudService.put<void>(request, `${api.PROPERTIES}/${propertyId}/images`),

  deleteImage: (propertyId: number, imageId: number) =>
    crudService.delete<void>(`${api.PROPERTIES}/${propertyId}/images/${imageId}`),

  updateImage: (listingImage: ListingImage) =>
    crudService.put<void>(
      listingImage,
      `${api.PROPERTIES}/${listingImage.propertyId}/images/${listingImage.id}`,
    ),

  bulkUpdate: (change: PropertyBulkUpdateRequest) =>
    crudService.put<void>(change, `${api.PROPERTIES}`),

  getValuations: getAxiosFetcher<Valuation[], [number]>((propertyId) => [
    `${api.PROPERTIES}/${propertyId}/valuations`,
  ]),

  createValuation: (propertyId: number, valuation: Partial<Valuation>) =>
    crudService.post<Valuation>(valuation, `${api.PROPERTIES}/${propertyId}/valuations`),

  updateValuation: (propertyId: number, valuation: Partial<Valuation>) =>
    crudService.put<Valuation>(
      valuation,
      `${api.PROPERTIES}/${propertyId}/valuations/${valuation.id}`,
    ),

  deleteValuation: (propertyId: number, valuationId: number) =>
    crudService.delete<void>(`${api.PROPERTIES}/${propertyId}/valuations/${valuationId}`),

  states: getAxiosFetcher<Enum[], []>(() => [`${api.PROPERTIES}/states`]),

  cities: getAxiosFetcher<string[], []>(() => [`${api.PROPERTIES}/cities`]),

  msas: getAxiosFetcher<string[], []>(() => [`${api.PROPERTIES}/msas`]),
};
