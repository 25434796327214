import { useAppContext } from '@/contexts/AppContext';
import type { EntityType } from '@/domain/entity-type';
import type { Note } from '@/domain/note';
import { NoteType } from '@/domain/note';
import { replaceMentionedUserIdsByNames } from '@/pages/notes/utils';
import { PlusOutlined } from '@ant-design/icons';
import { Tooltip } from 'antd';
import type { FC } from 'react';
import { useMemo, useState } from 'react';
import { ModalFormButton } from '../../Button/formButton';
import CreateNoteForm from '../create';
import NoteAudit from '../NoteAudit/NoteAudit';
import ViewAllNotesModal from '../ViewAllNotesModal';
import './styles.less';

interface Props {
  note?: Note;
  entityType: EntityType;
  entityId: number;
  otherEntities?: Record<string, number>;
  onNoteChange?: (newNote?: Note, entityId?: number) => void;
  readOnly?: boolean;
  loadingTasks?: boolean;
  createNoteType?: NoteType | string;
}

const NoteColumn: FC<Props> = ({
  note,
  onNoteChange,
  entityType,
  entityId,
  otherEntities,
  readOnly = false,
  loadingTasks,
  createNoteType,
}) => {
  const [tooltipVisible, setTooltipVisible] = useState(false);
  const { users } = useAppContext();

  const usersForMentions = useMemo(
    () =>
      users.map((user) => ({
        id: user.id,
        value: user.displayName,
      })),
    [users],
  );

  const fullLastNote = useMemo(
    () => `${replaceMentionedUserIdsByNames(note?.note || '', usersForMentions)}`,
    [note?.note, usersForMentions],
  );

  const noteTooltipContent = useMemo(
    () =>
      fullLastNote ? (
        <div className="note-tooltip-content">
          {/* This extra div is necessary to make the tooltip max-width equal to the tooltip footer */}
          <div className="note-text-wrapper">
            <div className="note-text">{fullLastNote}</div>
          </div>
          <div className="note-tooltip-footer">
            <div className="note-added-by">{note && <NoteAudit note={note} />}</div>
            <div>
              <ModalFormButton
                buttonType="link"
                buttonText="View all notes"
                onOpen={() => setTooltipVisible(false)}
              >
                {(props) => (
                  <ViewAllNotesModal
                    {...props}
                    entityType={entityType}
                    entityId={entityId}
                    otherEntities={otherEntities}
                    refetch={() => onNoteChange?.()}
                  />
                )}
              </ModalFormButton>
            </div>
          </div>
        </div>
      ) : (
        ''
      ),
    [fullLastNote, note, entityType, entityId, otherEntities, onNoteChange],
  );

  return (
    <div className="note-column">
      {!readOnly && (
        <ModalFormButton
          icon={<PlusOutlined />}
          buttonType="primary"
          onSuccess={onNoteChange}
          loading={loadingTasks}
          key="add-note-btn"
          tooltipTitle="Add a note"
          buttonClassName="add-note-button"
        >
          {(props) => (
            <CreateNoteForm
              {...props}
              entityType={entityType}
              entityId={entityId}
              otherEntities={otherEntities}
              defaultType={createNoteType || NoteType.GENERAL}
            />
          )}
        </ModalFormButton>
      )}
      {fullLastNote ? (
        <Tooltip
          placement="leftTop"
          title={noteTooltipContent}
          arrowPointAtCenter
          overlayClassName="note-tooltip"
          onVisibleChange={setTooltipVisible}
          visible={tooltipVisible}
        >
          <span
            className="latest-note-text"
            style={{ overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}
          >
            {fullLastNote}
          </span>
        </Tooltip>
      ) : readOnly ? (
        'N/A'
      ) : (
        <span className="add-note-text">Add a note</span>
      )}
    </div>
  );
};

export default NoteColumn;
