import type { Lookup } from '@/domain/base';
import { Select } from 'antd';
import debounce from 'lodash/debounce';
import type { CSSProperties, FC, FocusEvent } from 'react';
import { useEffect, useState } from 'react';

type Props = {
  value?: number;
  onChange: (value?: number) => void;
  onBlur?: (event: FocusEvent) => void;
  style?: CSSProperties;
  onSearch: (keyword?: string, id?: number) => Promise<Lookup[]>;
  placeholder?: string;
  disabled?: boolean;
};

const AutoComplete: FC<Props> = ({ value, style, onSearch, onChange, onBlur, ...props }) => {
  const [options, setOptions] = useState<Lookup[]>([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (value) {
      setLoading(true);
      onSearch(undefined, value)
        .then(setOptions)
        .finally(() => setLoading(false));
    }
  }, [value]);

  const handleSearch = debounce((searchText: string) => {
    const keyword = searchText && searchText.replace(/[|&;$%@"<>()+,`]/g, '');

    if (keyword) {
      setLoading(true);
      onSearch(searchText)
        .then(setOptions)
        .finally(() => setLoading(false));
    }
  }, 800);

  return (
    <Select
      {...props}
      showSearch
      allowClear
      defaultActiveFirstOption={false}
      loading={loading}
      notFoundContent={null}
      filterOption={false}
      style={style}
      value={options.length ? value : undefined} // The conditional is to prevent briefly displaying a number while options load
      onChange={(changed?: number) => !changed && onChange()}
      onSelect={(selected?: number) => onChange(selected)}
      onSearch={handleSearch}
      onBlur={(event: FocusEvent) => onBlur ?? event}
      options={options.map((option) => ({
        label: option.displayName,
        value: option.id,
      }))}
    />
  );
};

export default AutoComplete;
