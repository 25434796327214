import AddressDetails from '@/components/AddressDetails/AddressDetails';
import { ModalFormButton } from '@/components/Button/formButton';
import type { Application, ApplicationSearchResult } from '@/domain/applications';
import { ApplicationFileStatus, ApplicationStatus } from '@/domain/applications';
import type { RenewalDTO } from '@/domain/renewal';
import { RenewalApplicationStatus, RenewalStatus } from '@/domain/renewal';
import type { UnitApplication } from '@/domain/unit-applications';
import { UnitApplicationStatus } from '@/domain/unit-applications';
import { ApplicationsService } from '@/services/applications';
import { UnitApplicationsService } from '@/services/unit-applications';
import { handleError } from '@/utils/utils';
import { StarFilled } from '@ant-design/icons';
import { Badge, Button, Card, message, Modal, Tabs, Tooltip } from 'antd';
import type { FC, ReactNode } from 'react';
import { useMemo, useState } from 'react';
import ApplicantDetails from '../../../../components/ApplicantDetails/ApplicantDetails';
import { ApplicationResolutionModal } from './ApplicationResolutionModal';
import ApplicationScreeningResults from './ApplicationScreeningResults';
import EmploymentInformation from './EmploymentInformation';
import ApplicationFilesTable from './FilesInformation/ApplicationFilesTable';

interface Props {
  application: ApplicationSearchResult;
  applicationCancelled: boolean;
  onUpdateApplication: (application?: Application) => void;
  onDeleteApplication?: () => void;
  isPrimaryApplicant: boolean;
  unitApplication?: UnitApplication;
  renewal?: RenewalDTO;
  onScreeningResultUpdate: (application: ApplicationSearchResult) => void;
}

type TabKey =
  | 'applicantDetails'
  | 'addressDetails'
  | 'screeningResults'
  | 'employmentInformation'
  | 'files';

interface Tab {
  key: TabKey;
  title: ReactNode;
}

const ApplicationDetails: FC<Props> = ({
  application,
  applicationCancelled,
  onUpdateApplication,
  onDeleteApplication,
  isPrimaryApplicant,
  unitApplication,
  renewal,
  onScreeningResultUpdate,
}) => {
  const [loading, setLoading] = useState(false);
  const [deleting, setDeleting] = useState(false);
  const [currentTab, setCurrentTab] = useState<TabKey>('applicantDetails');

  const isUnitApplicationCancelledOrDenied =
    unitApplication?.status === UnitApplicationStatus.CANCELLED ||
    unitApplication?.status === UnitApplicationStatus.DENIED ||
    renewal?.status === RenewalStatus.DECLINED;

  const isApplicationCancelled = () => {
    return application.invitationStatus === RenewalApplicationStatus.CANCELLED;
  };

  const actionButtonsEnable =
    !isApplicationCancelled() &&
    !isUnitApplicationCancelledOrDenied &&
    (application.status === ApplicationStatus.UNDER_REVIEW ||
      application.status === ApplicationStatus.MISSING_INFORMATION);

  const isApproveApplicantDisabled = !application.validatedIncome || !actionButtonsEnable;

  const filesToReviewCount =
    application?.files?.filter((f) => f.status === ApplicationFileStatus.SUBMITTED)?.length ?? 0;

  const unitApplicationApplicationId = useMemo(
    () => (unitApplication?.applications || []).find((a) => a.applicationId === application.id)?.id,
    [unitApplication?.applications, application.id],
  );

  const canRemoveProspectApplication =
    !isPrimaryApplicant &&
    !applicationCancelled &&
    !!unitApplication &&
    unitApplicationApplicationId !== undefined &&
    ![
      UnitApplicationStatus.APPROVED,
      UnitApplicationStatus.CANCELLED,
      UnitApplicationStatus.HELD,
      UnitApplicationStatus.DENIED,
    ].includes(unitApplication.status);

  const cardTitle = () => (
    <div style={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
      {application.person.firstName} {application.person.lastName}
      {isPrimaryApplicant && (
        <Tooltip title="Primary Applicant">
          <StarFilled style={{ color: '#1890FF' }} />
        </Tooltip>
      )}
    </div>
  );

  const tabs: Tab[] = [
    {
      key: 'applicantDetails',
      title: 'Applicant Details',
    },
    {
      key: 'addressDetails',
      title: 'Address Information',
    },
    {
      key: 'employmentInformation',
      title: 'Employment Information',
    },
    {
      key: 'screeningResults',
      title: 'Screening Results',
    },
    {
      key: 'files',
      title:
        filesToReviewCount > 0 ? (
          <Tooltip title="Number of file uploads that are ready to be reviewed.">
            <span>File Uploads</span>
            <Badge count={filesToReviewCount} className="menuBadge" />
          </Tooltip>
        ) : (
          'File Uploads'
        ),
    },
  ];

  const handleUnderReview = () => {
    Modal.confirm({
      title: 'Update Prospect Application Status?',
      content: (
        <h3>
          Please confirm you want to update the status of this prospect application to Under Review?
        </h3>
      ),
      icon: '',
      okText: 'Yes',
      cancelText: 'No',
      onOk() {
        setLoading(true);
        ApplicationsService.startReview(application.id, application.version)
          .then((app) => {
            message.success('Application was successfully updated');
            onUpdateApplication(app);
          })
          .catch((error) => {
            handleError(error, {
              toastFallbackMessage: 'There was an error updating the application',
            });
          })
          .finally(() => {
            setLoading(false);
          });
      },
    });
  };

  const handleRemoveApplication = () => {
    Modal.confirm({
      title: 'Remove Prospect Application?',
      content: 'Please confirm you want to remove this prospect application',
      icon: '',
      okText: 'Remove',
      okType: 'danger',
      okButtonProps: { type: 'primary' },
      cancelText: 'Cancel',
      onOk() {
        if (
          unitApplicationApplicationId === undefined ||
          !unitApplication ||
          !onDeleteApplication
        ) {
          return;
        }

        setDeleting(true);
        UnitApplicationsService.deleteProspectApplication(
          unitApplication.id,
          unitApplicationApplicationId,
        )
          .then(() => {
            message.success('Application was successfully removed');
            onDeleteApplication();
          })
          .catch((error) => {
            handleError(error, {
              toastFallbackMessage: 'There was an error removing the application',
            });
          })
          .finally(() => {
            setDeleting(false);
          });
      },
    });
  };

  return (
    <Card
      title={cardTitle()}
      extra={[
        <Button
          key="remove-application"
          disabled={!canRemoveProspectApplication}
          onClick={() => handleRemoveApplication()}
          danger
          type="primary"
          loading={deleting}
          style={{ marginRight: 10 }}
        >
          Remove Application
        </Button>,
        <ModalFormButton
          key="deny-application"
          buttonType="default"
          buttonText="Deny Applicant"
          buttonStyle={{ marginRight: 10 }}
          buttonClassName={actionButtonsEnable ? 'danger' : ''}
          onSuccess={(app) => onUpdateApplication(app)}
          disabled={!actionButtonsEnable}
        >
          {(props) => (
            <ApplicationResolutionModal
              {...props}
              application={application}
              renewal={renewal}
              isUnitApplication={!!unitApplication?.id}
            />
          )}
        </ModalFormButton>,
        application.status === ApplicationStatus.SUBMITTED ? (
          <Button
            key="move-to-under-review"
            size="small"
            className="green"
            onClick={handleUnderReview}
            loading={loading}
          >
            Move to Under Review
          </Button>
        ) : (
          <ModalFormButton
            key="approve-application"
            buttonType="primary"
            buttonText="Approve Applicant"
            buttonClassName={actionButtonsEnable ? 'green' : ''}
            onSuccess={(app) => onUpdateApplication(app)}
            disabled={isApproveApplicantDisabled}
          >
            {(props) => (
              <ApplicationResolutionModal
                {...props}
                application={application}
                approve
                renewal={renewal}
              />
            )}
          </ModalFormButton>
        ),
      ]}
    >
      <Tabs defaultActiveKey={currentTab} onChange={(key) => setCurrentTab(key as TabKey)}>
        {tabs.map((tab) => (
          <Tabs.TabPane key={tab.key} tab={tab.title} style={{ padding: '10px 15px' }}>
            {tab.key === 'screeningResults' && (
              <ApplicationScreeningResults
                application={application}
                onUpdateApplication={onScreeningResultUpdate}
                applicationCancelled={applicationCancelled}
              />
            )}

            {tab.key === 'applicantDetails' && (
              <ApplicantDetails
                application={application}
                onUpdateApplication={onUpdateApplication}
              />
            )}

            {tab.key === 'addressDetails' && (
              <AddressDetails application={application} onUpdateApplication={onUpdateApplication} />
            )}

            {tab.key === 'employmentInformation' && (
              <EmploymentInformation
                application={application}
                onUpdateApplication={onUpdateApplication}
              />
            )}

            {tab.key === 'files' && (
              <ApplicationFilesTable
                application={application}
                refetchApplication={() => onUpdateApplication()}
              />
            )}
          </Tabs.TabPane>
        ))}
      </Tabs>
    </Card>
  );
};

export default ApplicationDetails;
