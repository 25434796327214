import { useEffect, useRef } from 'react';

const useEffectWithCounter = (
  effect: (counter: number) => void | (() => void),
  deps?: readonly any[],
) => {
  // Initialized with -1 so the first render is always 0
  // and "if (!counter) {...}" can be used to check that
  const ref = useRef(-1);
  ref.current += 1;

  const fn = () => effect(ref.current);

  // eslint-disable-next-line @typescript-eslint/no-invalid-this
  useEffect.apply(this, deps ? [fn, deps] : [fn]);
};

export default useEffectWithCounter;
