import { Table } from '@/@propify-components';
import SeeMoreIcon from '@/assets/icons/see-more.svg?react';
import { Modal, Tooltip } from 'antd';
import { useState } from 'react';
import type { ColumnComponentType, ColumnComponentTypeProps, CountModal } from '../types';

type RowType = {
  row: any;
  modal: CountModal;
  displayValue: number | string;
};

type Props = ColumnComponentTypeProps<RowType>;

const Component: ColumnComponentType<Props> = ({ value }) => {
  const [open, setOpen] = useState(false);

  const { row, modal, displayValue } = value || {};

  // Must set `"value": "{'row': $v, 'displayValue': '...', 'modal': {...}}"` in metadata
  if (!row || !modal || typeof modal === 'string') {
    return null;
  }

  const icon = (
    <SeeMoreIcon onClick={() => setOpen(true)} style={{ marginLeft: 5, cursor: 'pointer' }} />
  );

  return (
    <div>
      {displayValue}

      {modal.tooltip ? <Tooltip title={modal.tooltip}>{icon}</Tooltip> : icon}

      <Modal visible={open} title={modal.title} onCancel={() => setOpen(false)} footer={null}>
        <Table
          columns={[
            {
              key: 'title',
              title: modal?.idColumnTitle,
              hideSort: true,
              width: 243,
              styles: ({ bold }) => ({ fontWeight: bold ? 'bold' : undefined }),
            },
            {
              key: 'value',
              title: modal?.valueColumnTitle,
              hideSort: true,
              width: 243,
              styles: ({ bold }) => ({ fontWeight: bold ? 'bold' : undefined }),
            },
          ]}
          data={Object.entries(modal.items).map(([key, item]) => ({
            ...item,
            key,
            value: row[key],
          }))}
          rowKeyExtractor={(r) => r.key}
        />
      </Modal>
    </div>
  );
};

export default {
  Component,
};
