import * as Colors from '@/utils/styles/colors';
import type { Moment } from 'moment';
import moment from 'moment';
import type { FC } from 'react';
import { useMemo } from 'react';

interface Props {
  date: string | Moment | null | undefined;
}

const DateDistinctionDot: FC<Props> = ({ date }) => {
  const color = useMemo(() => {
    if (!date) {
      return '';
    }

    const parsedDate = moment(date);

    if (parsedDate.isBefore(moment())) {
      return Colors.red;
    }

    if (parsedDate.isBefore(moment().add(24, 'hours'))) {
      return Colors.yellow;
    }

    return '';
  }, [date]);

  if (!color) {
    return <span className="emptyDistinctionDot" />;
  }

  return (
    <svg
      viewBox="0 0 100 100"
      className="distinctionDot"
      style={{
        width: '8px',
        height: '8px',
        margin: '7px',
        animation: 'blinking 2.5s ease-out 0s infinite normal',
      }}
    >
      <circle cx="50" cy="50" r="40" fill={color} />
    </svg>
  );
};

export default DateDistinctionDot;
