import { PropertyGroupService } from '@/services/property-groups';
import { useFetch } from '@/utils/request';

export const usePropertyGroup = (id: number | undefined) => {
  const result = useFetch(
    {
      fetcher: id ? PropertyGroupService.findById : undefined,
      errorMessage: 'There was an error fetching the property groups',
    },
    id || 0,
  );

  return result;
};

export const usePropertyGroupAnalytics = (id: number | undefined) => {
  const result = useFetch(
    {
      fetcher: id ? PropertyGroupService.findAnalyticsById : undefined,
      errorMessage: 'There was an error while loading the analytics',
    },
    id || 0,
  );

  return result;
};
