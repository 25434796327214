import type { CreditRecommendation } from '@/domain/applications';
import type { FC } from 'react';
import ApprovalIcon, { getBooleanStatus, getCreditRecommendationStatus } from './ApprovalIcon';

type Props = {
  credit: CreditRecommendation;
  criminal?: boolean;
  background?: boolean;
  singleApplication?: boolean;
  iconSize?: number;
  marginTop?: number;
  /**
   * This will show gray icons and the same text in all the pop-overs
   * @see https://skyboxcapital.atlassian.net/browse/FB-614
   */
  applicantRemoved?: boolean;
};

const ApplicationRecommendations: FC<Props> = ({
  credit,
  criminal,
  background,
  singleApplication,
  iconSize,
  marginTop = 12,
  applicantRemoved = false,
}) => {
  return (
    <div style={{ marginTop, display: 'flex', justifyContent: 'center' }}>
      <ApprovalIcon
        size={iconSize}
        status={getCreditRecommendationStatus(credit)}
        icon="credit"
        singleApplication={singleApplication}
        applicantRemoved={applicantRemoved}
      />

      <ApprovalIcon
        size={iconSize}
        status={getBooleanStatus(criminal)}
        icon="criminal"
        singleApplication={singleApplication}
        applicantRemoved={applicantRemoved}
      />

      <ApprovalIcon
        size={iconSize}
        status={getBooleanStatus(background)}
        icon="background"
        singleApplication={singleApplication}
        applicantRemoved={applicantRemoved}
      />
    </div>
  );
};

export default ApplicationRecommendations;
