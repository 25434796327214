import type { SavedFilter } from '@/domain/saved-filter';
import EntityTableButton from '@/notmagic/components/EntityTable/components/EntityTableButton/EntityTableButton';
import { useEntityTableContext } from '@/notmagic/Context';
import { SavedFilterService } from '@/services/saved-filter';
import { handleError } from '@/utils/utils';
import { DeleteOutlined } from '@ant-design/icons';
import FilterListIcon from '@material-ui/icons/FilterList';
import { Button, Drawer, Empty, Input, message, Popconfirm, Tabs, Typography } from 'antd';
import type { FC } from 'react';
import { useEffect, useMemo, useState } from 'react';
import { useEndpoint } from '../../../../hooks/request';
import type { EntityType } from '../../../../types';
import { useParameters } from '../../hooks/useParameters';
import type { Tab, TableFilter } from '../../types';
import ActiveFilterField from '../ActiveFilterField';
import styles from './styles.module.less';

type FilterValues = Record<string, string | number | boolean | undefined>;

type Props = {
  entityType: EntityType;
  filters: TableFilter[];
  currentTab: Tab | undefined;
};

const EntityTableFilters: FC<Props> = ({ entityType, filters, currentTab }) => {
  const { parameters: query, updateParameters } = useParameters();
  const [drawerFilterVisible, setDrawerFilterVisible] = useState(false);
  const [filterTab, setFilterTab] = useState('allFilters');
  const [filterName, setFilterName] = useState<string>();
  const activeFilters = useMemo(
    () => filters.filter((filter) => query[filter.key] !== undefined),
    [filters, query],
  );
  const [filterValues, setFilterValues] = useState({});
  const { filterTypes } = useEntityTableContext();

  useEffect(() => {
    setFilterValues(
      activeFilters
        .map((filter) => filter.key)
        .reduce(
          (obj, key) => ({
            ...obj,
            [key]: query[key],
          }),
          {},
        ),
    );
  }, [activeFilters, query]);

  const {
    data: savedFilters = [],
    isValidating: loadingSavedFilters,
    mutate: updateSavedFilters,
  } = useEndpoint<SavedFilter[]>(['/saved-filters', { key: entityType.name }]);

  const onFilterChange = (newFilters: FilterValues) =>
    setFilterValues({ ...filterValues, ...newFilters });

  const onApplyFilters = () => {
    setDrawerFilterVisible(false);
    updateParameters({
      ...query,
      ...filterValues,
    });
  };

  const onApplyOneFilter = (newFilters: FilterValues) => {
    updateParameters({
      ...query,
      ...newFilters,
    });
    setDrawerFilterVisible(false);
  };

  const onLoadFilters = (newFilters: FilterValues) => {
    updateParameters(newFilters);
    setDrawerFilterVisible(false);
  };

  const onClearAllFilters = () => {
    const newQuery = Object.keys(query).reduce(
      (obj, key) => ({
        ...obj,
        [key]: filters.some((filter) => filter.key === key) ? undefined : query[key],
      }),
      {},
    );
    setDrawerFilterVisible(false);
    updateParameters(newQuery, undefined);
  };

  const onRemoveFilter = (key: string) =>
    updateParameters({
      ...query,
      [key]: undefined,
    });

  const onSaveFilters = () => {
    if (!Object.keys(filterValues)?.length) {
      return message.error('You do not have any selected filters');
    }

    return SavedFilterService.create({
      key: entityType.name,
      name: filterName,
      parameters: filterValues,
    })
      .then(() => {
        message.success('Filter successfully saved.');
        updateSavedFilters();
      })
      .catch((error) => {
        handleError(error, {
          toastFallbackMessage: 'There has been an error while saving the filter',
        });
      });
  };

  const onDeleteSavedFilter = (id: number) => {
    SavedFilterService.delete(id)
      .then(() => {
        message.success('Filter successfully deleted.');
        updateSavedFilters();
      })
      .catch((error) => {
        handleError(error, {
          toastFallbackMessage: 'There has been an error while deleting the filter',
        });
      });
  };

  return (
    <>
      <Drawer
        data-testid="entity-table-filters-drawer"
        className={styles.filterDrawer}
        title="Filters"
        placement="right"
        visible={drawerFilterVisible}
        width={350}
        onClose={() => {
          setDrawerFilterVisible(false);
        }}
        bodyStyle={{ padding: 0 }}
        footer={
          filterTab === 'allFilters' && (
            <>
              <Button
                data-testid="entity-table-filters-apply-button"
                size="small"
                onClick={() => onApplyFilters()}
              >
                Apply
              </Button>
              <Button
                data-testid="entity-table-filters-clear-button"
                size="small"
                type="link"
                onClick={() => onClearAllFilters()}
              >
                Clear Filters
              </Button>
              <Popconfirm
                placement="topLeft"
                title={
                  <div>
                    <div>Add a name to your filters:</div>
                    <div>
                      <Input
                        placeholder="Enter the filter name"
                        onChange={(e) => setFilterName(e.target.value)}
                      />
                    </div>
                  </div>
                }
                onConfirm={onSaveFilters}
                okText="Save"
                cancelText="Cancel"
                okButtonProps={{ disabled: filterName === '' }}
              >
                <Button
                  data-testid="entity-table-filters-save-button"
                  size="small"
                  type="link"
                  style={{ marginRight: '15px' }}
                >
                  Save Filters
                </Button>
              </Popconfirm>
            </>
          )
        }
      >
        <Tabs
          defaultActiveKey="allFilters"
          activeKey={filterTab}
          onChange={(value) => setFilterTab(value)}
          defaultValue="allFilters"
          centered
        >
          <Tabs.TabPane tab="All Filters" key="allFilters">
            {filters?.map((filter) => {
              const { Component } = filterTypes[filter.type];

              return (
                <div
                  data-testid={`entity-table-filter-${filter.key}`}
                  data-filter-type={filter.type}
                  key={filter.key}
                  style={{ display: 'flex', flexDirection: 'column' }}
                >
                  <div className={styles.filterLabel} data-testid="filterLabel">
                    <span className={styles.title}>{filter.label || filter.key}</span>
                  </div>

                  <Component
                    {...filter.configuration}
                    editable
                    value={filterValues[filter.key]}
                    onChangeValue={(value: any) => onFilterChange({ [filter.key]: value })}
                    otherValues={filterValues}
                    currentTab={currentTab}
                  />
                </div>
              );
            })}
          </Tabs.TabPane>

          <Tabs.TabPane tab="My Filters" key="myFilters">
            {!loadingSavedFilters && !savedFilters?.length && (
              <Empty
                data-testid="entity-table-no-saved-filters"
                description={
                  <div>
                    <Typography.Title level={4}>You have no saved filters.</Typography.Title>
                    <Typography.Text>
                      You can create you own combination of filters and save it for future use.
                    </Typography.Text>
                  </div>
                }
              />
            )}

            {savedFilters?.map((filter) => (
              <div key={filter.id} className={styles.savedFilter} data-testid="savedFilter">
                {filter.name}
                <div className={styles.savedFilterActions}>
                  <Button
                    size="small"
                    onClick={() => {
                      onLoadFilters(filter.parameters as FilterValues);
                    }}
                  >
                    Apply
                  </Button>
                  <Popconfirm
                    placement="topLeft"
                    title="Are you sure you want to delete this saved filter?"
                    onConfirm={() => onDeleteSavedFilter(filter.id)}
                    okText="Delete"
                    cancelText="Cancel"
                  >
                    <Button size="small" icon={<DeleteOutlined />} />
                  </Popconfirm>
                </div>
              </div>
            ))}
          </Tabs.TabPane>
        </Tabs>
      </Drawer>

      <div data-testid="finderBar" className={styles.finderBar}>
        <EntityTableButton
          data-testid="entity-table-open-filters-button"
          type={Object.keys(filterValues).length ? 'primary' : 'default'}
          icon={<FilterListIcon />}
          onClick={() => setDrawerFilterVisible(true)}
          className={styles.filterIconButton}
        />

        {activeFilters.map((filter) => (
          <ActiveFilterField
            key={filter.key}
            onFilterChange={onFilterChange}
            onApplyFilters={onApplyOneFilter}
            onClear={onRemoveFilter}
            filter={filter}
            value={query[filter.key]}
          />
        ))}
      </div>
    </>
  );
};

export default EntityTableFilters;
