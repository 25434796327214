import type { ModalFormProps } from '@/components/Button/formButton';
import { AntInput, AntSelect } from '@/components/Formik/CreateAntField';
import type { New } from '@/domain/base';
import type { Vehicle } from '@/domain/unit-applications';
import useStates from '@/hooks/useStates';
import { RenewalService } from '@/services/renewal';
import type { FormikSubmitFn } from '@/utils/types';
import { generateNumbersBetween, handleError } from '@/utils/utils';
import { Form, Modal } from 'antd';
import { Field, Formik } from 'formik';
import type { FC } from 'react';
import { useState } from 'react';
import * as Yup from 'yup';

interface Props extends ModalFormProps<[Vehicle]> {
  renewalId: number;
}

const validationSchema = Yup.object().shape({
  make: Yup.string().trim().nullable().required('This field is required.'),
  model: Yup.string().trim().nullable().required('This field is required.'),
  year: Yup.number()
    .min(1900)
    .max(new Date().getFullYear())
    .nullable()
    .required('This field is required.'),
  color: Yup.string().trim().nullable().required('This field is required.'),
  licensePlate: Yup.string().trim().nullable().required('This field is required.'),
  state: Yup.string().trim().nullable().required('This field is required.'),
});

const yearsOptions = generateNumbersBetween(1950, new Date().getFullYear()).map((n) => ({
  label: n,
  value: n,
}));

export const AddVehicle: FC<Props> = ({ onSuccess, onCancel, renewalId }) => {
  const [loading, setLoading] = useState(false);
  const { stateOptions } = useStates();

  const addVehicle: FormikSubmitFn<New<Vehicle>> = async (values, actions) => {
    setLoading(true);
    try {
      const vehicleInserted = await RenewalService.addVehicle(renewalId, values);
      onSuccess(vehicleInserted);
      actions.resetForm();
    } catch (error) {
      handleError(error, { toastFallbackMessage: 'There was an error adding the vehicle' });
    } finally {
      setLoading(false);
    }
  };

  return (
    <Formik<New<Vehicle>>
      onSubmit={addVehicle}
      validationSchema={validationSchema}
      initialValues={{
        make: '',
        model: '',
        year: new Date().getFullYear(),
        color: '',
        licensePlate: '',
        state: '',
      }}
    >
      {({ handleSubmit, submitForm, resetForm }) => (
        <Modal
          visible
          onCancel={() => {
            onCancel?.();
            resetForm();
          }}
          onOk={submitForm}
          okButtonProps={{
            disabled: loading,
            loading,
          }}
          okText="Save"
          title="Add Vehicle"
          maskClosable={false}
        >
          <Form labelCol={{ span: 6 }} wrapperCol={{ span: 18 }} onSubmitCapture={handleSubmit}>
            <Field component={AntInput} label="Make" name="make" type="text" hasFeedback />
            <Field component={AntInput} label="Model" name="model" type="text" hasFeedback />
            <Field component={AntInput} label="Color" name="color" type="text" hasFeedback />
            <Field
              component={AntInput}
              label="License Plate"
              name="licensePlate"
              type="text"
              hasFeedback
            />
            <Field name="year" label="Year" component={AntSelect} options={yearsOptions} />
            <Field name="state" label="State" component={AntSelect} options={stateOptions} />
          </Form>
        </Modal>
      )}
    </Formik>
  );
};
