import { ArrowBack, ArrowForward } from '@/utils/icons';
import { createStyles } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import type { FC, MouseEventHandler } from 'react';
import { useCallback } from 'react';

export enum ArrowSize {
  small = '1em',
  medium = '2em',
  large = '3em',
}

export enum ArrowPosition {
  INSIDE = '0px',
  OUTSIDE = '-5em',
}

type Props = {
  onClick?: MouseEventHandler;
  size?: ArrowSize;
  position?: ArrowPosition;
};

const useStyles = makeStyles(
  createStyles({
    arrow: {
      display: 'flex',
      position: 'fixed',
      zIndex: 1,
      color: '#1990ff',
      borderRadius: '50%',
      top: () => `calc(95% / 2)`,
      fontSize: (props: Props) => props.size ?? ArrowSize.medium,
      padding: '0.2em',
      margin: '0 0.2em',
      backgroundColor: 'rgba(0,0,0,.4)',
    },
  }),
);

export const NextArrow: FC<Props> = ({ onClick, size, position = ArrowPosition.INSIDE }) => {
  const classes = useStyles({ onClick, size, position });

  const handleClick: MouseEventHandler = useCallback(
    (event) => {
      event.stopPropagation();
      onClick?.(event);
    },
    [onClick],
  );

  return (
    <div className={classes.arrow} style={{ right: position }} onClick={handleClick}>
      <ArrowForward style={{ color: 'white' }} />
    </div>
  );
};

export const PrevArrow: FC<Props> = ({ onClick, size, position = ArrowPosition.INSIDE }) => {
  const classes = useStyles({ onClick, size, position });

  const handleClick: MouseEventHandler = useCallback(
    (event) => {
      event.stopPropagation();
      onClick?.(event);
    },
    [onClick],
  );

  return (
    <div className={classes.arrow} style={{ left: position }} onClick={handleClick}>
      <ArrowBack style={{ color: 'white' }} />
    </div>
  );
};
