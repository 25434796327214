import { InfoCircleFilled } from '@ant-design/icons';
import { Col, Row, Tooltip } from 'antd';
import { useFormikContext } from 'formik';
import type { FC } from 'react';
import AttributeField from './AttributeField';
import styles from './styles.module.less';
import type { Attribute, AttributeValue, EditablePageSaveFn } from './types';

interface Props {
  attributes: Attribute[];
  errorSubmitting?: boolean;
  onSave?: EditablePageSaveFn;
  separation?: number;
}

const FieldList: FC<Props> = ({ attributes, onSave, errorSubmitting, separation }) => {
  const formProps = useFormikContext<AttributeValue>();

  return (
    <Row data-testid="field-list" gutter={separation}>
      {attributes
        .filter((attr) => !attr.hide)
        .map((attr) => (
          <Col
            key={attr.key || attr.fieldName}
            span={attr.colSize ? attr.colSize : 12}
            className={`${styles.attributeBox} ${
              attr.borderless ? styles.borderlessAttribute : ''
            }`}
          >
            <Row
              className={styles.attributeLabel}
              data-testid={`attribute-${attr.testId || attr.key}-label`}
            >
              <span>
                {attr.label}{' '}
                {attr.labelTooltip?.trim() ? (
                  <Tooltip title={attr.labelTooltip}>
                    <InfoCircleFilled className={styles.tooltipIcon} />
                  </Tooltip>
                ) : undefined}
              </span>{' '}
              {attr.onCustomEdit && (
                <div className={styles.customEditContainer}>{attr.onCustomEdit()}</div>
              )}
            </Row>

            <Row
              className={styles.attributeValue}
              data-testid={`attribute-${attr.testId || attr.key}-value`}
            >
              <AttributeField
                attribute={attr}
                formProps={formProps}
                onSave={onSave}
                errorSubmitting={errorSubmitting}
              />
            </Row>
          </Col>
        ))}
    </Row>
  );
};

export default FieldList;
