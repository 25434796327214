import type { Country, Enum } from '@/domain/base';
import { api } from '@/utils/configuration';
import { getAxiosFetcher, getRawAxiosFetcher } from '@/utils/request';

export const EnumerationService = {
  getEnums: getRawAxiosFetcher<Enum[], [enumType: string]>(() =>
    getAxiosFetcher((enumType) => [`${api.ENUMERATION}/${enumType}`]),
  ),

  getCountriesList: getAxiosFetcher<Country[], []>(() => [
    'https://restcountries.com/v3.1/all?fields=name,cca2',
  ]),
};
