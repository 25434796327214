import type { Column } from '@/@propify-components';
import { Align, dateTimeColumn, Table } from '@/@propify-components';
import { ModalFormButton } from '@/components/Button/formButton';
import CreateNoteForm from '@/components/Notes/create';
import UserId from '@/components/UserId';
import { useAppContext } from '@/contexts/AppContext';
import type { EntityType } from '@/domain/entity-type';
import type { Note, NoteWithMentions } from '@/domain/note';
import { getNotesWithReplacedMentionedUserIds } from '@/pages/notes/utils';
import { NoteService } from '@/services/notes';
import { handleError } from '@/utils/utils';
import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { Button, Card, Empty, message, Popconfirm } from 'antd';
import moment from 'moment';
import type { FC } from 'react';
import { useEffect, useState } from 'react';
import popoverColumn from '../TableUtils/PopoverColumn';

export interface Props {
  entityType: EntityType | string;
  entityId: number;
  refreshIndex?: number;
  includeAddButton?: boolean;
}

const NotesTable: FC<Props> = ({ entityType, entityId, includeAddButton, refreshIndex }) => {
  const [notes, setNotes] = useState<NoteWithMentions[]>([]);

  const { users, currentUser } = useAppContext();

  const load = () => {
    NoteService.search({ entityType, entityId }).then((rawNotes) =>
      setNotes(getNotesWithReplacedMentionedUserIds(rawNotes, users)),
    );
  };

  useEffect(() => load(), [refreshIndex]);

  const handleDelete = (note: Note) => () =>
    NoteService.delete(note.id)
      .then(() => {
        load();
        message.success('Note deleted');
      })
      .catch((error) => {
        handleError(error, { toastFallbackMessage: 'Failed to delete Note' });
      });

  const handleEdit = () => load();

  const columns: Column<NoteWithMentions>[] = [
    dateTimeColumn({ title: 'Time', key: 'createTime' }),
    {
      title: 'User',
      key: 'createLoginId',
      render: (note: Note) => <UserId loginId={note.createLoginId} />,
      sortBy: 'createLoginId',
    },
    popoverColumn({ title: 'Notes', key: 'displayNote', sortBy: 'displayNote' }),
    {
      title: 'Alert',
      key: 'alert',
      width: 200,
      render: (note: Note) =>
        note.alert
          ? `${note.alertRole} @ ${moment(note.alertTime).format('M/D/YY h:mma')}`
          : 'None',
    },
    {
      title: 'Actions',
      key: 'actions',
      width: 100,
      align: Align.center,
      hideSort: true,
      render: (note: Note) =>
        currentUser?.login.id === note.createLoginId ? (
          <>
            <Popconfirm
              title="Are you sure?"
              onConfirm={handleDelete(note)}
              okText="Yes"
              cancelText="No"
            >
              <Button icon={<DeleteOutlined />} type="link" danger />
            </Popconfirm>
            <ModalFormButton icon={<EditOutlined />} onSuccess={handleEdit} buttonType="link">
              {(props) => (
                <CreateNoteForm
                  {...props}
                  entityId={entityId}
                  entityType={entityType}
                  note={note}
                />
              )}
            </ModalFormButton>
          </>
        ) : null,
    },
  ];

  const hasNotes = notes && notes.length > 0;

  const notesTable = hasNotes ? (
    <Table
      data={notes}
      columns={columns}
      rowKeyExtractor={(row) => row.id}
      className="propify-scrollable-table"
      responsive={false}
      data-testid="note-table"
    />
  ) : (
    <Empty />
  );

  const addButton = (
    <ModalFormButton key="1" buttonText="Add Note" buttonType="default" onSuccess={() => load()}>
      {(props) => <CreateNoteForm {...props} entityId={entityId} entityType={entityType} />}
    </ModalFormButton>
  );

  return includeAddButton ? (
    <Card bordered={false} extra={[addButton]}>
      {notesTable}
    </Card>
  ) : (
    notesTable
  );
};

export default NotesTable;
