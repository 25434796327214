import { handleError } from '@/utils/utils';
import type { Moment } from 'moment';
import moment from 'moment';
import isEqual from 'react-fast-compare';
import { rowJSViewGetter } from './view';

export type Constraint = Record<string, any>;
// The row and the value can be literally anything,
export type Row = any;
export type Value = any;
export type Getter = (row: Row, key: string) => any;

export interface MangoQueryInterface {
  query: (rows: Row[], query: any) => Row[];
  aggregations: (rows: Row[], query: any) => Row[];
}

const MangoQuery = ((): MangoQueryInterface => {
  // In cases where
  // an object is passed instead of an array
  const objectify = (a: any) => {
    const rows = [];
    for (const key in a) {
      const o = {};
      o[key] = a[key];
      rows.push(o);
    }
    return rows;
  };

  const parseOperators = (operators: any) => {
    if (!Array.isArray(operators)) {
      return objectify(operators);
    }
    return operators;
  };
  const isNull = (val: Value) => {
    return val === '' || val === undefined || val === null;
  };

  const isNotNull = (val: Value) => {
    return !isNull(val);
  };

  const _get = (row: Row, key: string, getter: Getter) => {
    if (typeof key == 'number') return key;
    else if (getter) return getter(row, key);
    else return row[key];
  };

  const Mango: any = {
    satisfies: (row: Row, constraints: Constraint, getter: Getter) => {
      if (typeof constraints === 'string') return Mango.Query(constraints, getter)(row);
      else return Mango.leftHandSide.onRowMatch(row, constraints, getter);
    },

    Query: (constraints: Constraint, getter: Getter) => {
      return (row: Row) => {
        return Mango.leftHandSide.onRowMatch(row, constraints, getter);
      };
    },

    Reduce: (constraints: Constraint, getter: Getter) => {
      return (row: Row) => {
        return Mango.leftHandSide.onRowReduce(row, constraints, getter);
      };
    },

    query: (rows: Row[], constraints: Constraint, getter: Getter) => {
      const filter = Mango.Query(
        (({ $reduce, ...constraint }) => constraint)(constraints),
        typeof getter == 'string' ? (obj: Row, key: string) => obj[getter as any][key] : getter,
      );
      const mapper = Mango.Reduce(
        constraints,
        typeof getter == 'string' ? (obj: Row, key: string) => obj[getter as any][key] : getter,
      );
      const filteredRows = rows.filter(filter);
      return constraints?.$reduce ? filteredRows.map(mapper) : filteredRows || [];
    },

    aggregations: (rows: Row[], constraints: Constraint, getter: Getter) => {
      // const filter = Mango.Query(
      //   (({ $reduce, ...constraint }) => constraint)(constraints),
      //   typeof getter == 'string' ? (obj: Row, key: string) => obj[getter as any][key] : getter,
      // );
      const mapper = Mango.Reduce(
        constraints,
        typeof getter == 'string' ? (obj: Row, key: string) => obj[getter as any][key] : getter,
      );

      // const filteredRows = rows.filter(filter);
      return rows.map(mapper);
    },

    leftHandSide: {
      // test whether a row satisfies a constraints hash
      onRowMatch: function (row: Row, constraints: Constraint, getter: Getter) {
        for (const key in constraints) {
          if (this[key]) {
            if (!this[key](row, constraints[key], getter)) return false;
          } else {
            const val = getter ? getter(row, key) : row[key];
            const res = this.filterOperators._satisfies(val, constraints[key], row, getter);
            if (!res) return false;
          }
        }
        return true;
      },
      onRowReduce: function (row: Row, constraints: Constraint, getter: Getter) {
        for (const key in constraints) {
          let response;
          const value = getter ? getter(row, key) : row[key];
          if (value) {
            response = this.reduceOperators._updates(value || response, constraints[key], row);
          } else {
            response = this.reduceOperators._creates(constraints[key], row);
          }
          row[key] = response;
        }
        return row;
      },

      $count: function (
        row: Row,
        condition: { $constraints: Constraint[]; $constraint: Constraint },
        getter: Getter,
      ) {
        const res = condition.$constraints
          .map(function (c: any) {
            return Mango.satisfies(row, c, getter);
          })
          .filter(function (v: any) {
            return v;
          }).length;
        return this.filterOperators._satisfies(res, condition.$constraint);
      },

      $same: (row: Row, condition: string[], getter: Getter) => {
        if (Array.isArray(condition)) {
          const values = condition
            .map((key) => {
              return getter ? getter(row, key) : row[key];
            })
            .filter(isNotNull);

          if (values.length == 0) return true;
          for (let i = 0; i < values.length; i++) {
            if (values[i] != values[0]) return false;
          }
          return true;
        }
        throw new Error('$same requires array value ');
      },

      $not: function (row: Row, constraint: Constraint, getter: Getter) {
        return !this.onRowMatch(row, constraint, getter);
      },

      $or: function (row: Row, constraint: Constraint, getter: Getter) {
        const parsedConstraints = parseOperators(constraint);
        for (let i = 0; i < parsedConstraints.length; i++) {
          if (this.onRowMatch(row, parsedConstraints[i], getter)) return true;
        }
        return false;
      },

      $and: function (row: Row, constraint: Constraint, getter: Getter) {
        const parsedConstraints = parseOperators(constraint);
        for (let i = 0; i < parsedConstraints.length; i++) {
          if (!this.onRowMatch(row, parsedConstraints[i], getter)) return false;
        }
        return true;
      },

      $nor: function (row: Row, constraint: Constraint, getter: Getter) {
        return !this.$or(row, constraint, getter);
      },

      $expr: function (row: Row, expr: any, getter: Getter) {
        let result = true;

        for (const key in expr) {
          if (this.filterOperators[key]) {
            const parts = expr[key];
            const constraint = parts[0];
            const aggExp = parts[1];

            const operation = Object.keys(aggExp)[0];
            const operands = aggExp[operation];
            const value = this.agg[operation](row, operands, getter);
            result = result && this.filterOperators[key](value, constraint);
          }
        }
        return result;
      },

      /**
       * Implementation of some aggregations
       * so we can perform map/reduce
       * on queries
       */
      agg: {
        $sum: (row: Row, operands: string[], getter: Getter) => {
          let sum = 0;
          for (let i = 0; i < operands.length; i++) {
            const key = operands[i];
            const val = _get(row, key, getter);
            if (val == +val) {
              sum += +val;
            }
          }
          return sum;
        },

        $min: (row: Row, operands: string[], getter: Getter) => {
          let min = +Infinity;
          for (let i = 0; i < operands.length; i++) {
            const key = operands[i];
            let val = _get(row, key, getter);
            if (val == +val) val = +val;
            if (val < min) {
              min = val;
            }
          }
          return min;
        },

        $max: (row: Row, operands: any, getter: Getter) => {
          let max = -Infinity;
          for (let i = 0; i < operands.length; i++) {
            const key = operands[i];
            let val = _get(row, key, getter);
            if (val == +val) val = +val;
            if (val > max) {
              max = val;
            }
          }
          return max;
        },

        $divide: (row: Row, operands: string[], getter: Getter) => {
          const num = _get(row, operands[0], getter);
          const den = _get(row, operands[1], getter);
          return num / den;
        },

        $same: (row: Row, condition: string[], getter: Getter) => {
          if (Array.isArray(condition)) {
            const values = condition
              .map(function (key) {
                return getter ? getter(row, key) : row[key];
              })
              .filter(isNotNull);

            if (values.length == 0) return true;
            for (let i = 0; i < values.length; i++) {
              if (values[i] != values[0]) return false;
            }
            return true;
          }
          throw new Error('$same requires array value');
        },
      },

      reduceOperators: {
        _updates: function (value: Value, constraint: Constraint, row?: any) {
          let newValue = value;
          for (const constraintKey in constraint) {
            if (this[constraintKey]) {
              newValue = this[constraintKey](value, constraint[constraintKey], row);
            }
          }
          return newValue || value;
        },
        _creates: function (constraint: Constraint, row: Row) {
          let newValue;
          for (const constraintKey in constraint) {
            if (this[constraintKey]) {
              newValue = this[constraintKey](undefined, constraint[constraintKey], row);
            }
          }
          return newValue;
        },
        $cb: function (
          value: Value,
          constraint: (value: Value | Row, row: Row) => unknown,
          row: Row,
        ) {
          const fn = new Function(
            'value',
            'row',
            'props',
            `return (${constraint})(value || row, row, props)`,
          );
          return fn(value, row || {}, { date: moment });
        },
        $key: function (value: Value, constraint?: string, row?: Row) {
          return rowJSViewGetter(value || constraint, row);
        },
        $concat: function (value: Value[], constraint: Constraint[], row: Row) {
          let concatenatedValue = '';
          for (const key of constraint) {
            // We check that key is type of string
            // since the $concat operator can accept
            // string and objects
            if (typeof key === 'string') {
              const getValue = this.$key(undefined, key, row);
              if (getValue) {
                concatenatedValue = `${concatenatedValue} ${getValue}`;
              }
            }
          }
          return concatenatedValue;
        },
        $sortBy: function (values: Value, constraint: Constraint) {
          if (!values) {
            return null;
          }
          if (typeof constraint === 'string') {
            return values.sort((a: Row, b: Row) => a[constraint].localeCompare(b[constraint]));
          }
          if (Array.isArray(constraint)) {
            return values.sort((a: Row, b: Row) => {
              if (constraint[1] === 'ASC') {
                return b[constraint[0]].localeCompare(a[constraint[0]]);
              }
              return a[constraint[0]].localeCompare(b[constraint[0]]);
            });
          }
          return values;
        },
      },

      filterOperators: {
        // $callback (with function)
        $cb: (value: Value, constraint: (value: Value, row: Row) => unknown, row: Row) => {
          const fn = new Function(
            'value',
            'row',
            'props',
            `return !!(${constraint})(value || row, row, props)`,
          );
          return fn(value, row || {}, { date: moment });
        },

        // evaluate JS code from string
        // as will be needed in the metadata
        // this needs to be tested further
        $fn: function (value: Value, constraint: any) {
          return constraint(new Function('row', `value`));
        },

        // test whether a single value matches a particular constraint
        _satisfies: function (value: Value, constraint: any, row?: any, getter?: any): any {
          if (constraint === value) return true;
          if (typeof value === 'string') {
            if (value[0] === '[' || value[0] === '{') {
              try {
                // eslint-disable-next-line no-param-reassign
                value = JSON.parse(value);
              } catch (error) {
                handleError(error, { displayToast: false });
              }
            }
          }
          if (constraint instanceof RegExp) return this.$regex(value, new RegExp(constraint));
          else if (Array.isArray(constraint)) {
            return this.$in(value, constraint);
          } else if (constraint && typeof constraint === 'object') {
            if (constraint instanceof Date) {
              return this.$eq(value, constraint.getTime());
            } else if (constraint.$regex) {
              return this.$regex(value, new RegExp(constraint.$regex, constraint.$options));
            } else if (constraint instanceof RegExp) {
              return this.$regex(value, constraint);
            } else {
              for (const key in constraint) {
                if (!this[key] && value?.[key]) {
                  return this._satisfies(value[key], constraint[key], row, getter);
                } else if (!this[key]) {
                  return this.$eq(value, constraint);
                } else if (!this[key](value, constraint[key], row, getter)) {
                  return false;
                }
              }
              return true;
            }
          } else if (constraint === '' || constraint === null || constraint === undefined)
            return this.$null(value);
          else if (Array.isArray(value)) {
            for (let i = 0; i < value.length; i++) if (this.$eq(value[i], constraint)) return true;
            return false;
          } else return this.$eq(value, constraint);
        },

        $eq: function (value: Value, match: Value) {
          if (value === match) return true;
          else if (Array.isArray(value)) {
            for (let i = 0; i < value.length; i++) if (this.$eq(value[i], match)) return true;
            return false;
          } else if (match === null || match === undefined || match === '') {
            return this.$null(value);
          } else if (value === null || value === '' || value === undefined) return false;
          // we know from above the constraint is not null
          else if (value instanceof Date) {
            if (match instanceof Date) {
              return value.getTime() == match.getTime();
            } else if (typeof match == 'number') {
              return value.getTime() == match;
            } else if (typeof match == 'string')
              return value.getTime() == new Date(match).getTime();
          } else {
            return value == match;
          }
          return false;
        },

        $exists: (value: Value, constraint: Constraint) => {
          return (value != undefined) == (constraint && true);
        },

        $deepEquals: (value: Value, constraint: Constraint) => {
          if (typeof isEqual === 'undefined') {
            return JSON.stringify(value) == JSON.stringify(constraint); //
          } else {
            return isEqual(value, constraint);
          }
        },

        $not: function (values: Value, constraint: Constraint) {
          return !this._satisfies(values, constraint);
        },

        $ne: function (values: Value, constraint: Constraint) {
          return !this._satisfies(values, constraint);
        },

        $nor: function (values: Value, constraint: Constraint) {
          return !this.$or(values, constraint);
        },

        $and: function (values: Value, constraint: Constraint) {
          if (!Array.isArray(constraint)) {
            throw new Error('$and needs an array of objects');
          }
          for (let i = 0; i < constraint.length; i++) {
            const res = this._satisfies(values, constraint[i]);
            if (!res) return false;
          }
          return true;
        },

        // Identical to $in, but allows for different semantics
        $or: function (values: Value, constraint: Constraint) {
          const allValues = !Array.isArray(values) ? [values] : values;
          for (let v = 0; v < allValues.length; v++) {
            for (let i = 0; i < constraint.length; i++) {
              if (this._satisfies(allValues[v], constraint[i])) {
                return true;
              }
            }
          }

          return false;
        },

        /**
         * returns true if all of the values in the array are null
         * @param values
         * @returns {boolean}
         */
        $null: function (values: Value) {
          if (values === '' || values === null || values === undefined) {
            return true;
          } else if (Array.isArray(values)) {
            if (values.length == 0) return true;
            for (let v = 0; v < values.length; v++) {
              if (!this.$null(values[v])) {
                return false;
              }
            }
            return true;
          } else return false;
        },

        /**
         * returns true if any of the values are keys of the constraint
         */
        $in: function (values: string | string[], constraint: string[]) {
          if (!Array.isArray(constraint)) throw new Error('$in requires an array operand');
          let result = false;
          const allValues = !Array.isArray(values) ? [values] : values;
          for (let v = 0; v < allValues.length; v++) {
            const val = allValues[v];
            for (let i = 0; i < constraint.length; i++) {
              if (constraint.indexOf(val) >= 0 || this._satisfies(val, constraint[i])) {
                result = true;
                break;
              }
            }
          }

          return result;
        },

        $likeI: (values: Value, constraint: string) => {
          return String(values).toLowerCase().indexOf(constraint) >= 0;
        },

        $like: (values: Value, constraint: Constraint) => {
          return values.indexOf(constraint) >= 0;
        },

        $startsWith: (values: Value, constraint: Constraint) => {
          if (!values) return false;
          return values.startsWith(constraint);
        },

        $endsWith: (values: Value, constraint: Constraint) => {
          if (!values) return false;
          return values.endsWith(constraint);
        },

        $allMatch: (values: Value, constraint: Constraint) => {
          if (Array.isArray(values)) {
            let result = true;
            for (let i = 0; i < values.length; i++) {
              if (!Mango.leftHandSide.filterOperators._satisfies(values[i], constraint)) {
                result = false;
              }
            }
            return result;
          } else return Mango.leftHandSide.filterOperators._satisfies(values, constraint);
        },

        $elemMatch: (values: Value, constraint: Constraint) => {
          if (Array.isArray(values)) {
            for (let i = 0; i < values.length; i++) {
              if (Mango.leftHandSide.filterOperators._satisfies(values[i], constraint)) return true;
            }
            return false;
          } else return Mango.leftHandSide.filterOperators._satisfies(values, constraint);
        },

        $contains: (values: Value, constraint: Constraint) => {
          // Do not use includes here
          // indexOf is faster
          return values.indexOf(constraint) >= 0;
        },

        $nin: function (values: Value, constraint: string[]) {
          return !this.$in(values, constraint);
        },

        $regex: (values: Value, constraint: RegExp) => {
          if (Array.isArray(values)) {
            for (let i = 0; i < values.length; i++) {
              // see https://stackoverflow.com/questions/3891641/regex-test-only-works-every-other-time
              if (new RegExp(constraint).test(values[i])) {
                return true;
              }
            }
          }
          return constraint.test(values);
        },

        $gte: function (values: Value, ref: any): unknown {
          if (Array.isArray(values)) {
            const self = { ...this };
            return values.every(function (v) {
              return self.$gte(v, ref);
            });
          }

          return !this.$null(values) && values >= this.resolve(ref);
        },

        $gt: function (values: Value, ref: any): unknown {
          if (Array.isArray(values)) {
            const self = { ...this };
            return values.every(function (v) {
              return self.$gt(v, ref);
            });
          }
          return !this.$null(values) && values > this.resolve(ref);
        },

        $lt: function (values: Value, ref: any): any {
          if (Array.isArray(values)) {
            const self = { ...this };
            return values.every(function (v) {
              return self.$lt(v, ref);
            });
          }
          return !this.$null(values) && values < this.resolve(ref);
        },

        $lte: function (values: Value, ref: any): any {
          if (Array.isArray(values)) {
            const self = { ...this };
            return values.every(function (v) {
              return self.$lte(v, ref);
            });
          }
          return !this.$null(values) && values <= this.resolve(ref);
        },

        $before: function (values: Value, ref: number | string | Date | Moment) {
          let allValues = { ...values },
            thisRef = ref;
          if (typeof ref === 'string') thisRef = Date.parse(ref);
          if (typeof values === 'string') allValues = Date.parse(values);
          return this.$lte(allValues, thisRef);
        },

        $after: function (values: Value, ref: number | string | Date | Moment) {
          let allValues = { ...values },
            thisRef = ref;
          if (typeof ref === 'string') thisRef = Date.parse(ref);
          if (typeof values === 'string') allValues = Date.parse(values);

          return this.$gte(allValues, thisRef);
        },

        $type: (values: Value, ref: string) => {
          return typeof values == ref;
        },

        $size: (values: Value, ref: number) => {
          return (
            typeof values == 'object' && (values.length == ref || Object.keys(values).length == ref)
          );
        },

        $mod: (values: Value, ref: number[]) => {
          return values % ref[0] == ref[1];
        },

        $equal: function (value: Value, constraint: Constraint) {
          return this.$eq(value, constraint);
        },

        $between: function (values: Value, ref: (number | string | Date | Moment)[]) {
          return this._satisfies(values, { $after: ref[0], $before: ref[1] });
        },

        resolve: (ref: { $date?: string }) => {
          if (typeof ref === 'object') {
            if (ref.$date) return Date.parse(ref.$date);
          }
          return ref;
        },
      },
    },
  };

  Mango.leftHandSide.filterOperators.$equal = Mango.leftHandSide.filterOperators.$eq;
  Mango.leftHandSide.filterOperators.$any = Mango.leftHandSide.filterOperators.$or;
  Mango.leftHandSide.filterOperators.$all = Mango.leftHandSide.filterOperators.$and;

  Mango.valueSatisfiesConstraint = function (value: Value, constraint: Constraint) {
    return this.leftHandSide.filterOperators._satisfies(value, constraint);
  };

  if (typeof window != 'undefined') (window as any).Query = Mango;

  return Mango;
  // eslint-disable-next-line @typescript-eslint/no-invalid-this
}).call(this);

Array.prototype.query = function (q) {
  return MangoQuery.query(this, q);
};

Array.prototype.aggregations = function (q) {
  return MangoQuery.aggregations(this, q);
};

export default MangoQuery;
