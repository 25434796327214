import CheckIcon from '@/assets/icons/check.svg?react';
import EntityTableButton from '@/notmagic/components/EntityTable/components/EntityTableButton/EntityTableButton';
import type { Settings } from '@/services/settings';
import ViewColumnOutlinedIcon from '@material-ui/icons/ViewColumnOutlined';
import { Button, Dropdown, Tooltip } from 'antd';
import type { FC } from 'react';
import { useEffect, useMemo, useState } from 'react';
import type { TableColumn } from '../../types';
import classes from './styles.module.less';

export type DisplayColumnsProps = {
  columns: TableColumn[];
  setColumns: (columns: TableColumn[]) => void;
  settings?: Settings;
  className?: string;
};

const DisplayColumns: FC<DisplayColumnsProps> = ({ columns, setColumns, settings, className }) => {
  const [show, setShow] = useState(false);
  const [visibleColumnKeys, setVisibleColumnKeys] = useState([] as string[]);

  useEffect(() => {
    setVisibleColumnKeys(columns.filter((column) => !column.hidden).map((column) => column.key));
  }, [columns]);

  const isApplyDisabled = useMemo(
    () => !columns.some((column) => !!column.hidden === visibleColumnKeys.includes(column.key)),
    [columns, visibleColumnKeys],
  );

  const toggleColumn = (key: string) =>
    setVisibleColumnKeys(
      visibleColumnKeys.includes(key)
        ? visibleColumnKeys.filter((each) => each !== key)
        : [...visibleColumnKeys, key],
    );

  const handleApply = () => {
    setColumns(
      columns.map((column) => ({
        ...column,
        hidden: !visibleColumnKeys.includes(column.key),
      })),
    );
    settings?.setVisibleColumns(
      columns?.reduce(
        (visibleColumns, column) => ({
          ...visibleColumns,
          [column.key]: column.hidden ?? false,
        }),
        {},
      ),
    );
    setShow(false);
  };

  const menu = (
    <div className={classes.displayColumns}>
      <div className={classes.columnsContainer}>
        {columns
          .filter((column) => column.title !== '')
          .map((column) => (
            <div
              key={column.key}
              className={`${classes.displayColumnsItem} ${
                !visibleColumnKeys.includes(column.key) ? classes.hidden : ''
              }`}
              onClick={() => toggleColumn(column.key)}
            >
              <div className={classes.displayColumnLabel}>{column.title}</div>
              {visibleColumnKeys.includes(column.key) ? <CheckIcon /> : undefined}
            </div>
          ))}
      </div>
      <div className={classes.applyContainer}>
        <Button
          type="primary"
          disabled={isApplyDisabled}
          onClick={handleApply}
          className={classes.applyButton}
        >
          Apply
        </Button>
      </div>
    </div>
  );

  return (
    <Dropdown
      overlay={menu}
      trigger={['click']}
      visible={show}
      onVisibleChange={setShow}
      className={className}
      placement="bottomRight"
      data-test="displayColumns"
    >
      <Tooltip placement="bottom" title="Toggle Columns">
        <EntityTableButton className={classes.displayColumnButton}>
          <ViewColumnOutlinedIcon />
        </EntityTableButton>
      </Tooltip>
    </Dropdown>
  );
};

export default DisplayColumns;
