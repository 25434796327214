import type { Enum, Lookup, LookupParams, PropifyEntity } from '@/domain/base';
import type { LeadSearchResult } from '@/domain/lead';
import type { Phone } from '@/domain/phone';
import type { Resident, ResidentSearchResult, ScheduledCharge } from '@/domain/resident';
import type { ShowingSearchResult } from '@/domain/showing';
import type { Unit } from '@/domain/unit';
import type { Address } from './address';
import type { EmailAddress } from './emails';
import type { LeasingSearchResult } from './leasing';
import type { Prospect } from './prospect';
import type { CollectionSearchResult } from './resident-collection';

export enum PartyType {
  PERSON = 'PERSON',
  ORGANIZATION = 'ORGANIZATION',
}

export enum PartyRoleType {
  PROSPECT = 'PROSPECT',
  RESIDENT = 'RESIDENT',
  USER = 'USER',
  PROPERTY_MANAGER = 'PROPERTY_MANAGER',
  VENDOR = 'VENDOR',
  COLLECTIONS_AGENCY = 'COLLECTIONS_AGENCY',
  GOVERNMENT = 'GOVERNMENT',
  HOUSING_AUTHORITY = 'HOUSING_AUTHORITY',
  HOA = 'HOA',
  LAW_OFFICE = 'LAW_OFFICE',
  REAL_ESTATE_OFFICE = 'REAL_ESTATE_OFFICE',
  UTILITY_PROVIDER = 'UTILITY_PROVIDER',
  LESSEE = 'LESSEE',
}

export enum MaritalStatus {
  CIVIL_UNION = 'CIVIL_UNION',
  COMMON_LAW = 'COMMON_LAW',
  DIVORCED = 'DIVORCED',
  DOMESTIC_PARTNERSHIP = 'DOMESTIC_PARTNERSHIP',
  MARRIED = 'MARRIED',
  OTHER = 'OTHER',
  SEPARATED = 'SEPARATED',
  SINGLE = 'SINGLE',
  UNKNOWN = 'UNKNOWN',
  WIDOWED = 'WIDOWED',
}

export interface PartyRole extends PropifyEntity {
  partyId: number;
}

export interface PartyRoleTypeEnum extends Enum<PartyRoleType> {
  fulfilledByPartyType: PartyType;
}

export interface Party extends PropifyEntity {
  emailAddresses: EmailAddress[];
  phones: Phone[];
  partyType: PartyType;
  roles: string[];
  hasLogin: boolean;
  primaryEmailAddress?: string;
  responseNeeded?: boolean;
}

export interface Person extends Party {
  partyType: PartyType.PERSON;
  firstName?: string;
  lastName?: string;
  yardiProspectCode?: string;
  yardiProspectHmy?: number;
  dateOfBirth?: string;
  maritalStatus: MaritalStatus;
  fullBalancePayment: boolean;
  paymentInEvictionStatus: boolean;
}

export interface PersonDuplicateResult extends Person {
  publicAccount?: boolean;
}

export interface Organization extends Party {
  partyType: PartyType.ORGANIZATION;
  name: string;
  address: Address;
  ein?: string;
  url?: string;
  companyContacts?: Person[];
}

export interface AccountValidations {
  emailVerified: boolean;
  phoneVerified: boolean;
  paymentVerified: boolean;
  identityVerified: boolean;
  paymentVerifiedGlobally: boolean;
  identityVerifiedGlobally: boolean;
  phoneVerifiedGlobally: boolean;
}

export interface DetailedPerson extends Person {
  units: Unit[];
  showings: ShowingSearchResult[];
  leads: LeadSearchResult[];
  residents: ResidentSearchResult[];
  charges: ScheduledCharge[];
  accountValidations: AccountValidations;
  prospect: Prospect;
  collections: CollectionSearchResult[];
  activeLeasings: LeasingSearchResult[];
}

export type DetailedParty =
  | (DetailedPerson & { partyType: PartyType.PERSON })
  | (Organization & { partyType: PartyType.ORGANIZATION });

export interface PersonEvent extends PropifyEntity {
  personId: number;
  eventName: string;
  eventDate: string;
  occurredDate: string;
  appliedDate: string;
  approvedDate: string;
  signedDate: string;
  moveInDate: string;
  moveOutDate: string;
  leaseFromDate: string;
  leaseToDate: string;
  rentPrice: number;
  rentDeposit: number;
  unitId: number;
  reason: string;
  monthToMonth: boolean;
}

export interface PersonEventSearchResult extends PersonEvent {
  unit: Unit;
  resident: Resident;
  person: Person;
}

export interface PersonEventSearchParams {
  eventId?: number;
  personId?: number;
  unitId?: number;
  propertyId?: number;
  residentId?: number[];
  eventName?: string | string[];
  eventDateFrom?: string;
  eventDateTo?: string;
  residentsOnly?: boolean;
}

export interface PartyLookup extends Lookup {
  emailAddresses: EmailAddress[];
  phones: Phone[];
}

export interface PartyLookupParams extends LookupParams {
  partyRoleId?: number | number[];
}

export type PartiesSearchParams = {
  id?: number | number[];
  keyword?: string;
  partyType?: PartyType;
  partyRoleType?: string;
  hasLogin?: boolean;
  sortBy?: string;
  limit?: number;
};

export type PartiesMerge = {
  ids: number[];
  firstName?: string;
  lastName?: string;
};
