import FloatingEditableAttribute from '@/components/FloatingEditableAttribute';
import EnumSelect from '@/notmagic/components/EntityTable/components/EnumSelect';
import type {
  ColumnComponentType,
  ColumnComponentTypeProps,
} from '@/notmagic/components/EntityTable/types';
import { EditOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import { Formik } from 'formik';
import { useRef, useState } from 'react';

type Props = ColumnComponentTypeProps<string> & {
  enumeration?: string;
  update?: (newValue: string) => Promise<any>;
  displayName?: string;
  filter?: string;
};

type Values = {
  field?: string;
};

const EditableComponent: ColumnComponentType<Props> = ({
  value,
  displayName,
  enumeration,
  update,
  textIfNull,
}) => {
  const [editing, setEditing] = useState(false);
  const baseRef = useRef<HTMLDivElement>(null);

  const onSubmit = async (values: Values) => {
    await update!(values.field!);
    setEditing(false);
  };

  return (
    <div ref={baseRef} className="floating-editing-container">
      {editing ? (
        baseRef.current && (
          <Formik<Values>
            initialValues={{
              field: value,
            }}
            onSubmit={onSubmit}
          >
            {({ isSubmitting, handleSubmit, setFieldValue, isValid, values }) => (
              <FloatingEditableAttribute
                baseElement={baseRef.current!}
                loading={isSubmitting}
                onCancel={() => setEditing(false)}
                onConfirm={handleSubmit}
                disabled={!isValid}
              >
                <EnumSelect
                  enumeration={enumeration!}
                  value={values.field}
                  onChangeValue={(newValue) => setFieldValue('field', newValue)}
                />
              </FloatingEditableAttribute>
            )}
          </Formik>
        )
      ) : (
        <div className="display-value">
          <span>{displayName ?? textIfNull}</span>

          <Button
            className="edit-button"
            icon={<EditOutlined />}
            type="link"
            size="small"
            onClick={() => setEditing(true)}
          />
        </div>
      )}
    </div>
  );
};

const Component: ColumnComponentType<Props> = (props) => {
  if (!props.editable) {
    return <>{props.displayName ? props.displayName : props.textIfNull}</>;
  }
  return <EditableComponent {...props} />;
};

export default {
  Component,
  sort: (a: string, b: string) => {
    if (!a && !b) {
      return 0;
    }
    if (!a) {
      return 1;
    }
    if (!b) {
      return -1;
    }
    return a ? (b ? a.localeCompare(b) : -1) : 0;
  },
};
