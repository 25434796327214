import type { FormikSubmitFn } from '@/utils/types';
import { Button, Drawer, Modal, Popconfirm } from 'antd';
import type { FormikProps } from 'formik';
import { Formik } from 'formik';
import pickBy from 'lodash/pickBy';
import type { FC } from 'react';
import type { ModalFormProps } from '../Button/formButton';
import type { BulkUpdateField, BulkUpdateFormValues } from './BulkUpdateForm';
import BulkUpdateForm from './BulkUpdateForm';

export type BulkUpdateFormVariant = 'modal' | 'drawer';

export interface BulkUpdateProps extends ModalFormProps<[BulkUpdateFormValues]> {
  fields?: BulkUpdateField[];
  variant: BulkUpdateFormVariant;
  visible?: boolean;
}

const BulkUpdate: FC<BulkUpdateProps> = ({
  onSuccess,
  onCancel,
  visible = true,
  fields,
  variant,
}) => {
  const getInitialValues = () => {
    const values: BulkUpdateFormValues = {};

    (fields || []).forEach(({ name }) => {
      values[name] = undefined;
    });

    return values;
  };

  const handleSubmit: FormikSubmitFn<BulkUpdateFormValues> = (values, actions) => {
    onSuccess(pickBy(values, (x) => x));
    actions.setSubmitting(false);
    actions.resetForm({ values: getInitialValues() });
  };

  const renderForm = (props: FormikProps<BulkUpdateFormValues>) => {
    return variant === 'modal' ? (
      <Modal
        title="Bulk Update"
        visible={visible}
        onCancel={onCancel}
        onOk={props.submitForm}
        maskClosable={false}
      >
        <BulkUpdateForm formikProps={props} fields={fields} />
      </Modal>
    ) : (
      visible && (
        <Drawer
          title="Bulk Update"
          placement="right"
          visible
          width={350}
          onClose={() => onCancel?.()}
          footer={
            <div style={{ textAlign: 'right' }}>
              <Button size="large" onClick={onCancel} style={{ marginRight: '15px' }}>
                Cancel
              </Button>
              <Popconfirm
                placement="topLeft"
                title="Are you sure?"
                onConfirm={props.submitForm}
                okText="Save"
                cancelText="Cancel"
              >
                <Button size="large" type="primary">
                  Save
                </Button>
              </Popconfirm>
            </div>
          }
        >
          <BulkUpdateForm formikProps={props} fields={fields} layout="vertical" />
        </Drawer>
      )
    );
  };

  return (
    <Formik<BulkUpdateFormValues> initialValues={getInitialValues()} onSubmit={handleSubmit}>
      {renderForm}
    </Formik>
  );
};

export default BulkUpdate;
