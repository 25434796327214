import type { Enum } from '@/domain/base';
import { useCallback, useEffect, useMemo, useState, type FC } from 'react';
import { useImmutableEndpoint } from '../hooks/request';

const SingleFetcher: FC<{ enumName: string; onFetch: (result: Enum[]) => void }> = ({
  enumName,
  onFetch,
}) => {
  const { data } = useImmutableEndpoint<Enum[]>(`/enumerations/${enumName}`);

  useEffect(() => {
    if (data) {
      onFetch(data);
    }
  }, [data]);

  return null;
};

type Props = {
  enumerationNames: string[];
  onFetchAll: (enumerations: Record<string, Record<string, string>>) => void;
};

const EnumerationsFetcher: FC<Props> = ({ enumerationNames, onFetchAll }) => {
  const [results, setResults] = useState<Record<string, Record<string, string>>>({});

  const allFetched = useMemo(
    () => enumerationNames.every((enumName) => results[enumName]),
    [results, enumerationNames],
  );

  useEffect(() => {
    if (allFetched) {
      onFetchAll(results);
    }
  }, [allFetched]);

  const handleFetch = useCallback((enumName: string, result: Enum[]) => {
    setResults((previous) => ({
      ...previous,
      [enumName]: result.reduce(
        (map, _enum) => ({
          ...map,
          [_enum.value]: _enum.displayName,
        }),
        {},
      ),
    }));
  }, []);

  return (
    <>
      {enumerationNames.map((e) => (
        <SingleFetcher key={e} enumName={e} onFetch={(data) => handleFetch(e, data)} />
      ))}
    </>
  );
};

export default EnumerationsFetcher;
