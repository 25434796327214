import type { FormikAutoCompleteType } from '@/components/Formik/FormikAutoComplete';
import PhoneMaskInput from '@/components/PhoneMaskInput';
import type { Location } from '@/domain/location';
import { ISO_DATE_FORMAT } from '@/utils/time';
import { Checkbox, DatePicker, Form, Input, InputNumber, Radio, Select, TimePicker } from 'antd';
import type { RadioChangeEvent } from 'antd/lib/radio';
import { getIn } from 'formik';
import moment from 'moment-timezone';
import type { ChangeEventHandler } from 'react';
import LookupSelect from '../LookupSelect';

const FormItem = Form.Item;
const { Option } = Select;
const { TextArea } = Input;

interface CreateAntFieldType extends FormikAutoCompleteType {
  selectOptions: any[];
  type: any;
  getOptionValue: (option: any) => string | number | undefined;
  getOptionLabel: (option: any) => string;
  loading?: boolean;
  afterOnChange?: (value: any) => void;
  required?: boolean;
}

type FieldType =
  | 'Select'
  | 'DatePicker'
  | 'Input'
  | 'PhoneMaskInput'
  | 'Number'
  | 'Password'
  | 'TextArea'
  | 'TimePicker'
  | 'Checkbox'
  | 'Location'
  | 'YesNo';

const CreateAntField =
  (AntComponent: any, fieldType: FieldType) =>
  ({
    field,
    form,
    hasFeedback,
    label,
    required,
    selectOptions,
    submitCount,
    type,
    getOptionValue,
    getOptionLabel,
    formItemStyle,
    loading,
    afterOnChange,
    ...props
  }: CreateAntFieldType) => {
    const touched = getIn(form.touched, field.name);
    const submitted = submitCount > 0;
    const hasError = getIn(form.errors, field.name);
    const submittedError = hasError && submitted;
    const touchedError = hasError && touched;
    const onInputChange: ChangeEventHandler<any> = (e: any) => {
      let value = e?.target?.value ?? e;
      switch (fieldType) {
        case 'Checkbox':
          value = e?.target?.checked;
          break;
        case 'TextArea':
        case 'Input':
          value = value || null;
          break;
        default:
          break;
      }
      form.setFieldValue(field.name, value);
    };

    const onChange = (value: any) => {
      if (fieldType === 'DatePicker') {
        if (props.localDateTime) {
          value.tz(moment.tz.guess());
          form.setFieldValue(field.name, value ? value.format('YYYY-MM-DDTHH:mm:ss') : '');
        } else {
          form.setFieldValue(field.name, value ? value.format(ISO_DATE_FORMAT) : '');
        }
      } else if (fieldType === 'Checkbox') {
        form.setFieldValue(field.name, value.target.checked);
      } else {
        form.setFieldValue(field.name, value);
      }
      afterOnChange?.(value);
    };

    const convertType = (value: any) => {
      if (typeof value === 'boolean') {
        return String(value);
      }

      if (fieldType === 'DatePicker' || fieldType === 'TimePicker') {
        return value && moment(value);
      }

      return value;
    };

    let componentProps;
    if (type === 'checkbox' && fieldType === 'Checkbox') {
      componentProps = {
        ...field,
        ...props,
        onChange,
      };
    } else if (fieldType === 'TextArea') {
      componentProps = {
        ...field,
        ...props,
        autoSize: true,
        onChange: type ? onInputChange : onChange,
        value: convertType(field.value),
      };
    } else {
      componentProps = {
        ...field,
        ...props,
        onChange: type ? onInputChange : onChange,
        value: convertType(field.value),
      };
    }

    if (type === 'number') {
      componentProps.type = 'number';
    }

    const submitStatus = submittedError || touchedError ? 'error' : 'success';
    const status = loading ? 'validating' : submitStatus;

    return (
      <FormItem
        required={required}
        label={label}
        hasFeedback={!!((hasFeedback && submitted) || (hasFeedback && touched))}
        help={submittedError || touchedError ? hasError : false}
        validateStatus={status}
        style={formItemStyle}
        extra={componentProps.extra}
      >
        <AntComponent {...componentProps}>
          {selectOptions &&
            selectOptions.map((option: any) => (
              <Option
                key={getOptionValue(option)}
                value={convertType(getOptionValue(option))}
                disabled={option.disabled}
                title={option.title}
              >
                {getOptionLabel(option)}
              </Option>
            ))}
        </AntComponent>
      </FormItem>
    );
  };

const LocationInput = (props: { value: Location; onChange: (value: any) => void }) => {
  const { value, onChange } = props;

  return (
    <>
      <span>Latitude: </span>
      <InputNumber
        value={value.latitude}
        onChange={(latitude) => {
          onChange({ ...value, latitude });
        }}
      />
      <span>Longitude: </span>
      <InputNumber
        value={value.longitude}
        onChange={(longitude) => {
          onChange({ ...value, longitude });
        }}
      />
    </>
  );
};

const YesNo = ({
  value,
  onChange,
}: {
  value: Location;
  onChange: (e: RadioChangeEvent) => void;
}) => (
  <Radio.Group onChange={onChange} value={value}>
    <Radio value="yes">Yes</Radio>
    <Radio value="no">No</Radio>
  </Radio.Group>
);

export const AntSelect = CreateAntField(Select, 'Select');
export const AntDatePicker = CreateAntField(DatePicker, 'DatePicker');
export const AntInput = CreateAntField(Input, 'Input');
export const AntPhoneMaskInput = CreateAntField(PhoneMaskInput, 'PhoneMaskInput');
export const AntInputNumber = CreateAntField(InputNumber, 'Number');
export const AntPassword = CreateAntField(Input.Password, 'Password');
export const AntTextArea = CreateAntField(TextArea, 'TextArea');
export const AntTimePicker = CreateAntField(TimePicker, 'TimePicker');
export const AntCheckbox = CreateAntField(Checkbox, 'Checkbox');
export const AntLocation = CreateAntField(LocationInput, 'Location');
export const AntYesNo = CreateAntField(YesNo, 'YesNo');
export const AntLookupSelect = CreateAntField(LookupSelect, 'Select');
