import { Card } from 'antd';
import type { Operation } from 'fast-json-patch';
import { Formik } from 'formik';
import zipObjectDeep from 'lodash/zipObjectDeep';
import type { FC, ReactNode } from 'react';
import { useMemo, useState } from 'react';
import isEqual from 'react-fast-compare';
import type * as Yup from 'yup';
import FieldList from './FieldList';
import type { Attribute, AttributeValue, EditablePageSaveFn } from './types';

const isTextAttribute = (attribute?: Attribute) =>
  attribute && ['Text', 'TextArea', 'TextEditor'].includes(attribute.type);

export const getOperationsFromAttributes = (attributes: Attribute[], values: AttributeValue) => {
  const operations: Operation[] = Object.entries(values).map(([key, value]) => {
    const getAttribute = attributes.find((attribute) => attribute.fieldName === key);
    const attributeValue = isTextAttribute(getAttribute) && value === '' ? null : value;

    return {
      op: 'replace',
      path: `/${(getAttribute?.path ?? getAttribute?.fieldName)?.replaceAll('.', '/')}`,
      value: attributeValue,
    };
  });

  return operations;
};

export interface EditableAttributesProps {
  title: string;
  attributes: Attribute[];
  onSave?: EditablePageSaveFn;
  validationSchema?: Yup.ObjectSchema<any>;
  breakAfter?: boolean;
  render?: () => ReactNode;
  extraTitle?: ReactNode;
  separation?: number;
}

const EditableAttributes: FC<EditableAttributesProps> = ({
  title,
  attributes,
  validationSchema,
  onSave,
  breakAfter,
  extraTitle,
  separation,
}) => {
  const [errorSubmitting, setErrorSubmitting] = useState(false);

  const handleFormSubmit: EditablePageSaveFn<AttributeValue> = async (values, actions) => {
    setErrorSubmitting(false);

    const valuesToSave = Object.fromEntries(
      // Some fields we might need to do an individual save
      // that uses a different endpoint
      Object.entries(values).filter(([key, attributeValue]) => {
        const attribute = attributes.find((attr) => attr.fieldName === key) as Attribute;
        if (attribute?.onSave && !isEqual(attribute?.value, attributeValue)) {
          attribute?.onSave(
            {
              attributes: [attribute],
              values: { [key]: attributeValue },
            },
            actions,
          );
          return false;
        }

        return (
          typeof attribute?.onSave === 'undefined' && !isEqual(attribute?.value, attributeValue)
        );
      }),
    );

    // We get all other properties
    // that don't have onSave on the attributes
    // only if there were changes — and we send only what has changed
    if (Object.entries(valuesToSave)?.length) {
      // This awaits tells the button to show a spinner until completion
      await onSave?.({ attributes, values: valuesToSave }, actions);
    }

    actions.resetForm({ values });
  };

  const initialValues = useMemo<AttributeValue>(() => {
    const zip = (attrs: Attribute[]) =>
      zipObjectDeep(
        attrs.map((attr) => attr.key || attr.fieldName),
        attrs.map((attr) => attr.value),
      );

    let values = zip(attributes);

    attributes
      .filter((a) => a.type === 'Attributes' && a.nestedAttributes)
      .forEach((a) => {
        values = {
          ...values,
          ...zip(a.nestedAttributes!),
        };
      });

    return values;
  }, [attributes]);

  return (
    <div className={breakAfter ? 'breakAfter' : ''}>
      <div className="grid-bluecards">
        <Card title={title} bordered={false} extra={extraTitle}>
          <Formik<AttributeValue>
            initialValues={initialValues}
            enableReinitialize
            validationSchema={validationSchema}
            onSubmit={handleFormSubmit}
          >
            {() => (
              <FieldList
                attributes={attributes}
                onSave={onSave!}
                errorSubmitting={errorSubmitting}
                separation={separation}
              />
            )}
          </Formik>
        </Card>
      </div>
    </div>
  );
};

export default EditableAttributes;
