import { useAppContext } from '@/contexts/AppContext';
import { useMemo } from 'react';

const useVentures = () => {
  const { ventures } = useAppContext();

  const ventureNames = useMemo(
    () => ventures.reduce((map, venture) => ({ ...map, [venture.id]: venture.name }), {}),
    [ventures],
  );
  const ventureOptions = useMemo(() => ventures.map((v) => ({ label: v.name, value: `${v.id}` })), [
    ventures,
  ]);

  return { ventureNames, ventureOptions };
};

export default useVentures;
