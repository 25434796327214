import type { CSSProperties, FC, ReactNode } from 'react';

interface Props {
  href: string;
  styles?: CSSProperties;
  children: ReactNode;
}

const ExternalLink: FC<Props> = ({ href, styles, children }) => {
  return (
    <a
      style={styles}
      target="_blank"
      /// @ts-ignore
      disabled={!href}
      rel="noopener noreferrer"
      href={href}
    >
      {children}
    </a>
  );
};

export default ExternalLink;
