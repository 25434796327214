import classNames from 'classnames';
import React from 'react';
import { ResizableBox } from 'react-resizable';
import { arrayMove, SortableContainer, SortableElement, SortableHandle } from 'react-sortable-hoc';
import type { Column } from '..';
import {
  Align,
  DEFAULT_COLUMN_MAX_WIDTH,
  DEFAULT_COLUMN_MIN_WIDTH,
  DEFAULT_COLUMN_WIDTH,
  SortDirection,
} from '..';
import { FlagOutlined, Pinned, SortRow, SortTable } from '../../shared/utils/icons';
import type { ClassNameMap } from '../../shared/utils/material';
import { Checkbox } from '../../shared/utils/material';
import HeaderTitle from './HeaderTitle';
import StickyColumns from './StickyColumns';

interface Props {
  columns: Column[];
  allSelected: boolean;
  toggleAllSelected: (event: any) => void;
  onColumnResize: (event: any, data: any, column: any) => void;
  sortedBy?: string;
  sortDir?: SortDirection;
  onSortChange?: (sortedBy: string, sortDir: SortDirection) => void;
  isSelectable: boolean;
  singleSelection: boolean;
  selectionDisabled: boolean;
  isDraggable: boolean;
  showTooltip: boolean;
  setColumnPinned: (columnKey: string, pinned: boolean) => void;
  pinnedColumns: string[];
  onChangeColumnOrder: (newColumnOrder: string[]) => void;
  styles: ClassNameMap<string>;
}

const HeaderDraggable = SortableHandle((props: any) => {
  return (
    <>
      <span
        className={props.styles.tableHeaderTitle}
        title={props.column.title}
        style={{
          textAlign: props.column.align,
        }}
      >
        <HeaderTitle column={props.column} styles={props.styles} />
      </span>
    </>
  );
});

const SortableHeaderElement = SortableElement((props: any) => {
  const styles = props.styles;
  const column = props.column as Column;
  const width = column.width ? column.width : DEFAULT_COLUMN_WIDTH;
  const minWidth = column.minWidth ? column.minWidth : DEFAULT_COLUMN_MIN_WIDTH;
  const maxWidth = column.maxWidth ? column.maxWidth : DEFAULT_COLUMN_MAX_WIDTH;
  const sortByKey = column.sortBy || column.key;

  const sortBy = (value?: string) => {
    if (value === undefined || !props.onSortChange) {
      return;
    }

    if (props.sortedBy === value) {
      if (props.sortDir === SortDirection.ASCENDING) {
        props.onSortChange(value, SortDirection.DESCENDING);
      } else {
        props.onSortChange(undefined, undefined);
      }
    } else {
      props.onSortChange(value, SortDirection.ASCENDING);
    }
  };

  const getSortDirection = () => {
    if (props.sortedBy === sortByKey && props.sortDir === SortDirection.DESCENDING) {
      return 'UP';
    }
    if (props.sortedBy === sortByKey && props.sortDir === SortDirection.ASCENDING) {
      return 'DOWN';
    }
    return undefined;
  };

  return (
    <ResizableBox
      key={column.key}
      width={width}
      height={35}
      minConstraints={[minWidth, 0]}
      maxConstraints={[maxWidth, Infinity]}
      axis="x"
      onResizeStop={(event: any, data: any) => props.onColumnResize(event, data, column)}
      className={classNames('propify-table-header-cell', styles.tableHeaderCell, {
        [styles.tableCellRight]: props.column.align === Align.right,
        [styles.tableCellCenter]: props.column.align === Align.center,
      })}
    >
      <div
        style={{
          cursor: !column.hideSort ? 'pointer' : undefined,
        }}
        className={styles.headerContainer}
        data-test="tableHeaderItem"
        data-testid={`table-header-item-${column.key}`}
      >
        <HeaderDraggable width={width} {...props} />

        {props.showPin && (
          <Pinned
            active={!!props.pinned}
            style={{
              cursor: 'pointer',
              flexShrink: 0,
              transform: !props.pinned ? 'rotate(90deg)' : 'none',
              marginRight: !props.pinned ? 4 : 0,
            }}
            onClick={() => props.setColumnPinned(column.key)}
          />
        )}

        {!column.hideSort && (
          <SortTable
            className="propify-table-sort-column-icon"
            width={11}
            onClick={() => sortBy(sortByKey)}
            direction={getSortDirection()}
            style={{
              marginLeft: 3,
              cursor: 'pointer',
              flexShrink: 0,
            }}
          />
        )}
      </div>
    </ResizableBox>
  );
});

interface SortableHeaderContainerProps extends Props {
  children: React.ReactElement;
}

const SortableHeaderContainer = SortableContainer((props: SortableHeaderContainerProps) => {
  return (
    <div className="tableHeader" data-test="tableHeader" role="rowheader">
      {props.children}
    </div>
  );
});

const Header = (props: Props) => {
  const styles = props.styles;
  const onSortEnd = ({ oldIndex, newIndex }: { oldIndex: number; newIndex: number }) => {
    const columnsPinnedToTheLeft: string[] = [];
    const nonPinnedColumns: string[] = [];
    const columnsPinnedToTheRight: string[] = [];

    props.columns
      .map((column) => column.key)
      .forEach((column) => {
        if (props.pinnedColumns.includes(column)) {
          if (nonPinnedColumns.length === 0) {
            columnsPinnedToTheLeft.push(column);
          } else {
            columnsPinnedToTheRight.push(column);
          }
        } else {
          nonPinnedColumns.push(column);
        }
      });

    props.onChangeColumnOrder([
      ...columnsPinnedToTheLeft,
      ...arrayMove(nonPinnedColumns, oldIndex, newIndex),
      ...columnsPinnedToTheRight,
    ]);
  };

  const renderCell = (
    column: Column,
    index: number,
    pinned: boolean,
    collection: number,
    showPin?: boolean,
  ) => (
    <SortableHeaderElement
      key={column.key}
      index={index}
      column={column}
      showPin={props.columns.length <= 2 ? pinned : showPin}
      pinned={pinned}
      collection={collection}
      role="cell"
      {...props}
    />
  );

  return (
    <StickyColumns
      styles={styles}
      renderContainer={(children) => {
        const propsWithChildren = {
          ...props,
          children,
        };
        return (
          <SortableHeaderContainer
            axis="x"
            lockAxis="x"
            lockToContainerEdges
            helperClass={styles.tableHeaderSorting}
            useDragHandle
            onSortEnd={onSortEnd}
            {...propsWithChildren}
          />
        );
      }}
      columns={props.columns}
      isSelectable={props.isSelectable}
      singleSelection={props.singleSelection}
      isDraggable={props.isDraggable}
      renderSortIcon={() => (
        <span
          className={`${styles.tableCell} ${styles.tableDragDropCell} ${styles.tableDragDropCellHeader}`}
          data-test="tableSortHandle"
        >
          <SortRow />
        </span>
      )}
      renderTooltip={
        props.showTooltip
          ? () => (
              <span className={`${styles.tableCell} ${styles.tableFlagCellHeader}`}>
                <FlagOutlined className="flagIcon" />
              </span>
            )
          : null
      }
      renderCheckbox={() => (
        <span
          className={`${styles.tableCell} ${styles.tableDragDropCell} ${styles.tableDragDropCellHeader} ${styles.tableCheckbox}`}
          data-test="tableHeaderCheckbox"
          role="checkbox"
          aria-checked={props.allSelected}
        >
          <Checkbox
            tabIndex={-1}
            size="small"
            checked={props.allSelected}
            onChange={props.toggleAllSelected}
            disabled={props.selectionDisabled}
          />
        </span>
      )}
      renderRadioButton={() => (
        <span
          className={`${styles.tableCell} ${styles.tableDragDropCell} ${styles.tableDragDropCellHeader}`}
        />
      )}
      renderCell={renderCell}
      pinnedColumns={props.pinnedColumns}
    />
  );
};

export default Header;
