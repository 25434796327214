export const blue = '#1890ff';
export const yellow = '#faad14';
export const orange = '#FF8A00';
export const green = '#52c41a';
export const red = '#f5222d';
export const gray = '#bfbfbf';
export const gray5 = '#d9d9d9';
export const gray7 = '#8c8c8c';

export const StatusColors = {
  GENERAL: blue,
  ACTIVE: green,
  APPROVED: green,
  COMING_SOON: yellow,
  COMPLETE: blue,
  INCOMPLETE: yellow,
  PENDING: yellow,
  WARNING: red,
  DENIED: red,
  ERROR: red,
  CANCELED: red,
  INACTIVE: gray,
};
