import { CarouselModal } from '@/components/Carousel';
import Map from '@/components/map';
import MapModal from '@/components/map/Modal';
import { ImageOutlined, MapOutlined } from '@/utils/icons';
import { Button } from 'antd';
import { useEffect, useState } from 'react';
import styles from './styles.module.less';
import type { ImageBlockType } from './types';

const ImageBlock = ({ images, extra }: ImageBlockType) => {
  const [galleryOpen, setGalleryOpen] = useState(false);
  const [mapOpen, setMapOpen] = useState(false);
  const [section, setSection] = useState<'IMAGE_CONTAINER' | 'MAP'>('MAP');

  const firstImage = images?.[0];

  const hasImage = !!firstImage?.src;

  useEffect(() => {
    if (images?.length > 0) {
      setSection('IMAGE_CONTAINER');
    }
  }, [images]);

  // Let's check the points actually have a latitude/longitude
  const hasMapPoints = extra.map?.points.some((point) => !!point.latitude && !!point.longitude);

  const onContainerClick = () => {
    if (section === 'IMAGE_CONTAINER' && !galleryOpen) {
      setGalleryOpen(true);
    }
  };

  const renderImageContainer = () => {
    if (!hasImage) {
      return <div className={styles.noImagesContainer}>No images yet</div>;
    }

    return <div onClick={() => onContainerClick()} style={{ width: '100%', height: '100%' }} />;
  };

  const renderMap = () =>
    extra.map ? (
      <>
        <div style={{ height: 340 }} onClick={() => setMapOpen(true)}>
          <Map
            points={extra.map.points}
            options={{
              zoom: 50,
              mapTypeControl: false,
              draggable: false,
              scaleControl: false,
              scrollwheel: false,
              disableDefaultUI: true,
            }}
          />
        </div>
        <MapModal visible={mapOpen} points={extra.map.points} onCancel={() => setMapOpen(false)} />
      </>
    ) : null;

  return (
    <div
      className={styles.imageContainer}
      style={{ backgroundImage: hasImage ? `url(${firstImage.src})` : '' }}
    >
      <div
        className={styles.imageActions}
        style={{ justifyContent: hasMapPoints ? 'space-between' : 'flex-end' }}
      >
        {hasMapPoints && (
          <div className={styles.imageOrMap}>
            <Button
              className={section === 'IMAGE_CONTAINER' ? styles.activeSection : ''}
              onClick={() => setSection('IMAGE_CONTAINER')}
              type="link"
              icon={<ImageOutlined />}
            />
            <Button
              className={section === 'MAP' ? styles.activeSection : ''}
              onClick={() => setSection('MAP')}
              type="link"
              icon={<MapOutlined />}
            />
          </div>
        )}
        {extra?.actions}
      </div>

      {section === 'MAP' && renderMap()}

      {section === 'IMAGE_CONTAINER' && renderImageContainer()}
      <CarouselModal
        options={images}
        initialSlide={0}
        open={galleryOpen}
        onClose={() => setGalleryOpen(false)}
      />
    </div>
  );
};

export default ImageBlock;
