import type { ModalFormProps } from '@/components/Button/formButton';
import SectionSplashScreen from '@/components/PageLoading/SplashSection';
import { useAppContext } from '@/contexts/AppContext';
import type { Option } from '@/domain/base';
import type { Lessee } from '@/domain/lease';
import { LesseeType } from '@/domain/lease';
import { PartyRoleType } from '@/domain/person';
import type { RenewalDTO } from '@/domain/renewal';
import { RenewalStatus } from '@/domain/renewal';
import useEnumeration from '@/hooks/useEnumeration';
import { LeaseService } from '@/services/leases';
import { RenewalService } from '@/services/renewal';
import { useFetch } from '@/utils/request';
import { SHORT_DATE_FORMAT } from '@/utils/time';
import { getFullName, handleError } from '@/utils/utils';
import { Alert, Col, Divider, message, Modal, Row, Select, Statistic, Typography } from 'antd';
import moment from 'moment';
import type { FC } from 'react';
import { useState } from 'react';
import { Link } from 'react-router-dom';

interface Props extends ModalFormProps<[RenewalDTO]> {
  renewalId: number;
}

const DenyRenewalModal: FC<Props> = ({ onCancel, onSuccess, renewalId }) => {
  const { brands } = useAppContext();
  const [declinedReason, setDeclinedReason] = useState<string>();
  const [declinedBy, setDeclinedBy] = useState<PartyRoleType>(PartyRoleType.PROPERTY_MANAGER);

  const { data: declineRenewalReasons } = useEnumeration('declined-renewal-reason');

  const decisionMadeByOptions: Option[] = [
    { label: 'Property Manager', value: PartyRoleType.PROPERTY_MANAGER },
    { label: 'Resident', value: PartyRoleType.RESIDENT },
  ];

  const { data: renewal } = useFetch(
    {
      fetcher: RenewalService.get,
      errorMessage: 'There was an error fetching the renewal',
    },
    renewalId,
  );

  const { data: lease } = useFetch(
    {
      fetcher: renewal?.leaseId ? LeaseService.findById : undefined,
      errorMessage: 'There was an error fetching the lease',
    },
    renewal?.leaseId || 0,
  );

  const handleDeny = () => {
    if (!renewal) {
      return;
    }

    RenewalService.update({
      ...renewal,
      status:
        declinedBy === PartyRoleType.PROPERTY_MANAGER
          ? RenewalStatus.DECLINED
          : RenewalStatus.REFUSED,
      declinedBy,
      declinedReason,
    })
      .then((updated) => {
        message.success('Renewal successfully updated');
        onSuccess(updated);
      })
      .catch((error) => {
        handleError(error, { toastFallbackMessage: 'Something went wrong updating the renewal' });
      });
  };

  return (
    <Modal
      visible
      width="80vw"
      title="Deny Renewal"
      onCancel={onCancel}
      okButtonProps={{
        danger: true,
        type: 'primary',
        disabled: !declinedBy || !declinedReason,
      }}
      okText="Deny Renewal"
      onOk={handleDeny}
    >
      {renewal && lease ? (
        <>
          <Row>
            <Col md={4}>
              <Statistic
                title="Address"
                valueRender={() => (
                  <Link to={`/properties/${lease.unit.propertyId}`}>
                    {lease!.unit.property!.address!.addr1}
                  </Link>
                )}
              />
            </Col>
            <Col md={4}>
              <Statistic
                title="Lease End Date"
                value={lease.endDate ? moment(lease.endDate).format(SHORT_DATE_FORMAT) : 'N/A'}
              />
            </Col>
            <Col md={4}>
              <Statistic
                title="Brand"
                value={brands.find((each) => each.id === lease.unit.property?.brandId)?.name}
              />
            </Col>
            <Col md={4}>
              <Statistic title="Number of Residents" value={lease?.lessees.length || 'N/A'} />
            </Col>
            <Col md={4}>
              <Statistic
                title="Primary Resident"
                valueRender={() => {
                  const primaryLessee = lease.lessees.find(
                    (lessee: Lessee) => lessee.type === LesseeType.PRIMARY,
                  );
                  return primaryLessee ? (
                    <Link to={`/person/${primaryLessee.partyId}`}>
                      {getFullName({
                        firstName: primaryLessee.person.firstName,
                        lastName: primaryLessee.person.lastName,
                      })}
                    </Link>
                  ) : (
                    'N/A'
                  );
                }}
              />
            </Col>
          </Row>
          <Divider />
          <Alert
            message={
              <span>
                <strong>Deny Renewal:</strong> Are you sure you wish to deny the opportunity to
                renew this lease?
              </span>
            }
            type="warning"
            showIcon
          />
          <Row gutter={20} style={{ marginTop: 22 }}>
            <Col md={8}>
              <Typography.Title level={5}>Decision Made By</Typography.Title>
              <Select
                value={declinedBy}
                onChange={(value) => {
                  setDeclinedBy(value);
                  setDeclinedReason(undefined);
                }}
                style={{ width: '100%' }}
                options={decisionMadeByOptions}
              />
            </Col>
            <Col md={16}>
              <Typography.Title level={5}>Reason Given</Typography.Title>
              <Select
                value={declinedReason}
                onChange={setDeclinedReason}
                style={{ width: '100%' }}
                options={declineRenewalReasons
                  .filter((option) => {
                    // @ts-ignore
                    return option.decisionMaker === declinedBy;
                  })
                  .map((option) => ({
                    label: option.displayName,
                    value: option.value,
                  }))}
              />
            </Col>
          </Row>
        </>
      ) : (
        <SectionSplashScreen />
      )}
    </Modal>
  );
};

export default DenyRenewalModal;
