import type Big from 'big.js';

export type Suggestions<T extends U, U = string> = T | (U & {});

export const currencyFormatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
});

export type FormatCurrencyOptions = { disableAccountingStyle?: boolean; noEmptyDecimals?: boolean };

export const formatCurrency = (
  value?: string | number | Big,
  options: FormatCurrencyOptions = {
    disableAccountingStyle: false,
    noEmptyDecimals: false,
  },
) => {
  if (value == null) {
    return '';
  }

  const numericValue = Number(value);
  // Has no decimals
  const isInteger = numericValue === Math.floor(numericValue);

  const parts = currencyFormatter.formatToParts(numericValue);

  const price = parts
    .filter((p) => {
      if (p.type === 'currency' || p.type === 'integer' || p.type === 'group') {
        return true;
      }

      // Removes empty decimals if option is set
      if (p.type === 'decimal' || p.type === 'fraction') {
        return !isInteger || !options.noEmptyDecimals;
      }

      return false;
    })
    .map((p) => p.value)
    .join('');

  return numericValue < 0 ? (options.disableAccountingStyle ? `-${price}` : `(${price})`) : price;
};

export const formatPercentage = (value?: string | number | Big, noDecimals?: boolean) => {
  if (value == null) {
    return '';
  }

  const formatted = noDecimals
    ? parseInt(typeof value === 'string' ? value : `${Number(value)}`, 10)
    : Number(value).toFixed(2);
  return `${formatted}%`;
};
