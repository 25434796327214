import FloatingInlineTextEdit from '@/components/FloatingInlineEdit/FloatingInlineTextEdit';
import type {
  ColumnComponentType,
  ColumnComponentTypeProps,
  ColumnType,
} from '@/notmagic/components/EntityTable/types';
import { Popover } from 'antd';

type PopoverProps = {
  title: string;
};

interface Props extends ColumnComponentTypeProps {
  popover?: true | PopoverProps;
}

const Component: ColumnComponentType<Props> = ({
  value,
  popover,
  editable,
  update,
  textIfNull,
}) => {
  const showPopover = popover === true || !!popover?.title;
  const title = popover !== true ? popover?.title : undefined;

  const editableValue = editable ? (
    <FloatingInlineTextEdit
      initialValue={value}
      onConfirm={async (newValue, done, fail) => {
        try {
          await update?.(newValue);
          done();
        } catch (error) {
          fail(error);
        }
      }}
      displayValue={value || textIfNull}
    />
  ) : (
    <>{value || textIfNull}</>
  );

  return showPopover ? (
    <Popover
      content={() => (
        <div
          style={{
            whiteSpace: 'pre-line',
            padding: '4px 8px',
            width: '300px',
          }}
        >
          {value || textIfNull}
        </div>
      )}
      title={title}
      placement="left"
    >
      <div className="cellContentWithPopover">{editableValue}</div>
    </Popover>
  ) : (
    editableValue
  );
};

const Definition: ColumnType = {
  Component,
  sort: (a: string, b: string) => (a === b ? 0 : a < b ? -1 : 1),
};

export default Definition;
