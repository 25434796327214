import { Button } from 'antd';
import { useCallback, useState, type FC } from 'react';
import type { GlobalAction, GlobalActionData } from '../../types';

type Props = GlobalAction & {
  type: 'nav' | 'popover';
  data: GlobalActionData;
  refreshData: () => void;
  bulkUpdate: (ids: number[]) => void;
  disabled?: boolean;
};

const GlobalActionComponent: FC<Props> = ({
  type,
  label,
  Component,
  onClick,
  data,
  refreshData,
  bulkUpdate,
  disabled,
}) => {
  // Should render `Component` instead of calling `onClick`
  const shouldRender = !!Component;
  const [showExtra, setShowExtra] = useState(false);

  const handleOnClick = useCallback(() => {
    if (shouldRender) {
      setShowExtra(true);
    } else {
      onClick?.(data);
    }
  }, [shouldRender, onClick, data]);

  const handleOnFinish = useCallback(() => {
    setShowExtra(false);
  }, []);

  return (
    <span>
      {type === 'nav' && (
        <Button onClick={handleOnClick} disabled={disabled}>
          {label}
        </Button>
      )}

      {type === 'popover' && (
        <div onClick={handleOnClick} style={{ padding: '5px 15px' }}>
          {label}
        </div>
      )}

      {Component && showExtra && (
        <Component
          {...data}
          onFinish={handleOnFinish}
          refreshData={refreshData}
          bulkUpdate={bulkUpdate}
        />
      )}
    </span>
  );
};

export default GlobalActionComponent;
