import type { Enum } from '@/domain/base';
import type {
  CallEntity,
  Communication,
  EmailEntity,
  NoteEntity,
  SMSEntity,
} from '@/domain/communication';
import { CommunicationDirection, CommunicationType } from '@/domain/communication';
import { PartyType } from '@/domain/person';
import type { Ticket, TicketSearchResult } from '@/domain/ticket';
import { GENERAL_SUBSKILL_ID } from '@/domain/ticket';
import type { SubSkill, TicketGroup } from '@/domain/ticket-groups';
import type { User, UserLookup } from '@/domain/user';
import { groupArrayBy } from '@/utils/utils';
import moment from 'moment';
import type { MessageStatus } from './Cards/base';
import type { ContactHistoryCommunication, ContactHistoryPerson } from './ContactHistory';
import type { TicketCardType } from './TicketsList';

export enum PropifyCRMTabs {
  INFO = 'INFO',
  TICKETS = 'TICKETS',
  DUPLICATES = 'DUPLICATES',
  TOUCHES = 'TOUCHES',
}

export const fillTicketNames = (
  user: User,
  ticketGroups: TicketGroup[],
  users: UserLookup[],
  subSkills: SubSkill[],
  ticketStatusEnum: Enum[],
  ticketTypesEnum: Enum[],
) => (ticket: TicketSearchResult): TicketCardType => ({
  ...ticket,
  isClaimBadgeVisible: ticket.ticketClaimUserId === user.id,
  groupName: ticket.ticketGroupId
    ? ticketGroups.find((ticketGroup) => ticketGroup.id === ticket.ticketGroupId)?.name || ''
    : 'Uncategorized',
  createdByName: users.find((u) => u.loginId === ticket.createLoginId)?.displayName || '',
  subSkillName: ticket.subskillId
    ? subSkills.find((subSkill) => subSkill.id === ticket.subskillId)?.displayName || ''
    : 'General',
  statusName: ticketStatusEnum.find((status) => status.value === ticket.status)?.displayName || '',
  typeName: ticketTypesEnum.find((type) => type.value === ticket.type)?.displayName || '',
  agentName:
    users.find((u) => u.id === ticket.preferredUserId)?.displayName || 'No preferred agent',
});

export const sortPersonTickets = (a: Ticket, b: Ticket) => {
  if (a.hasOwnProperty('ticketClaimUserId')) {
    return -1;
  }
  if (b.hasOwnProperty('ticketClaimUserId')) {
    return 1;
  }
  return 0;
};

export const addCommunicationHistoryDates = (
  tickets: Communication[],
  previousItems: ContactHistoryCommunication[],
) => {
  const insertDate = (date: string) => ({
    entity: {} as any, // Not necessary to have a type since it's not used for type DATE
    type: CommunicationType.DATE,
    createTime: moment(date).format('YYYY-MM-DDTHH:mm:ss'),
    key: `${CommunicationType.DATE}-${date}`,
  });

  const newHistoryItems: ContactHistoryCommunication[] = tickets
    .filter((t) => t.type !== CommunicationType.DATE)
    .map((ticket) => ({
      ...ticket,
      key: ticket.entity.id.toString(),
    }))
    .filter((t) => previousItems.every((pi) => pi.key !== t.key));

  let lastDate = '';

  return [...newHistoryItems, ...previousItems]
    .sort((a, b) => moment(a.createTime).valueOf() - moment(b.createTime).valueOf())
    .reduce<ContactHistoryCommunication[]>((result, item) => {
      const itemDate = moment(item.createTime).format('YYYY-MM-DD');
      const newArray = [...result];

      if (lastDate !== itemDate) {
        lastDate = itemDate;
        newArray.push(insertDate(itemDate));
      }

      if (item.type !== CommunicationType.DATE) {
        newArray.push(item);
      }

      return newArray;
    }, []);
};

export const getContactName = (contact: ContactHistoryPerson | undefined): string => {
  switch (contact?.partyType) {
    case PartyType.ORGANIZATION:
      return contact.name;

    case PartyType.PERSON:
      return `${contact.firstName} ${contact.lastName}`;

    default:
      return '';
  }
};

export const mapCallProps = (
  item: ContactHistoryCommunication,
  contact: ContactHistoryPerson | undefined,
  queueList: Enum[],
) => {
  const entity = item.entity as CallEntity;

  return {
    id: item.entity.id,
    time: item.createTime,
    direction: entity.direction,
    user: getContactName(contact),
    group: queueList.find((queueItem) => queueItem.value === entity.queueName)?.displayName,
    uri: entity.recordingUri,
    ticketIds: entity.ticketIds,
  };
};

export const mapEmailProps = (
  item: ContactHistoryCommunication,
  contact: ContactHistoryPerson | undefined,
  users: UserLookup[],
) => {
  const entity = item.entity as EmailEntity;

  let userByDirection: string = '';

  if (entity.direction === CommunicationDirection.OUTBOUND && entity.readLoginId) {
    userByDirection = users.find((u) => u.loginId === entity.readLoginId)?.displayName ?? '';
  } else {
    userByDirection = getContactName(contact);
  }

  return {
    time: item.createTime,
    direction: entity.direction,
    user: userByDirection,
    group: entity.ticketGroupName,
    title: entity.subject,
    emailId: entity.id,
    ticketIds: entity.ticketIds,
    ...(entity.direction === CommunicationDirection.INBOUND
      ? { status: (entity.readTime ? 'read' : 'unread') as MessageStatus }
      : {}),
  };
};

export const mapSMSProps = (
  item: ContactHistoryCommunication,
  contact: ContactHistoryPerson | undefined,
  users: UserLookup[],
) => {
  const entity = item.entity as SMSEntity;
  let userByDirection;

  if (entity.direction === CommunicationDirection.OUTBOUND && entity.readLoginId) {
    userByDirection = users.find((u) => u.loginId === entity.readLoginId)?.displayName ?? '';
  } else {
    userByDirection = getContactName(contact);
  }

  return {
    time: item.createTime,
    direction: entity.direction,
    user: userByDirection,
    body: entity.body,
    smsId: entity.id,
    ticketIds: entity.ticketIds,
    mediaURIs: entity.mediaURIs,
    ...(entity.direction === CommunicationDirection.INBOUND
      ? { status: (entity.readTime ? 'read' : 'unread') as MessageStatus }
      : {}),
  };
};

export const mapNoteProps = (item: ContactHistoryCommunication, users: UserLookup[]) => {
  const entity = item.entity as NoteEntity;

  return {
    time: item.createTime,
    direction: CommunicationDirection.OUTBOUND,
    user: users.find((u) => u.loginId === entity.createLoginId)?.displayName || '',
    note: entity.note,
    ticketIds: entity.ticketIds,
  };
};

export const getTicketGroupSkillsHierarchy = (groups: TicketGroup[], subSkills: SubSkill[]) =>
  groupArrayBy(subSkills, 'ticketGroupId').map(({ value, key }) => ({
    value: parseInt(key, 10),
    label: groups.find((g) => g.id === parseInt(key, 10))?.name || 'Uncategorized',
    children: [
      { value: GENERAL_SUBSKILL_ID, label: 'General' },
      ...value.map((child) => ({
        value: child.id,
        label: child.displayName,
      })),
    ],
  }));
