/* eslint-disable turbo/no-undeclared-env-vars */
import { handleError } from '@/utils/utils';

interface Environment {
  environment: string;
  authClientUrl: string;
  smartHomeSetupClientUrl: string;
  maintenanceClientUrl: string;
  tenantClientUrl: string;
}

let init: ((value: Environment | PromiseLike<Environment>) => void) | undefined;
let environmentPromise = new Promise<Environment>((resolve) => {
  init = resolve;
});

export const EnvironmentService = {
  init: () => {
    const loadEnvironment = fetch(`${window.location.origin}/environment.json`)
      .then<Environment>((response) => response.json())
      .catch<Environment>((error) => {
        handleError(error, { displayToast: false });
        return {
          environment: '',
          authClientUrl: '',
          smartHomeSetupClientUrl: '',
          maintenanceClientUrl: '',
          tenantClientUrl: '',
        };
      });
    environmentPromise = loadEnvironment;
    if (init) {
      init(loadEnvironment);
      init = undefined;
    }
  },

  getEnvironment: async () => {
    const fromEnv = process.env.POD_ENVIRONMENT;

    if (typeof fromEnv === 'string' && fromEnv && fromEnv !== 'undefined' && fromEnv !== 'null') {
      return fromEnv;
    }

    return (await environmentPromise).environment;
  },

  getAuthClientUrl: async () => {
    const fromEnv = process.env.AUTH_CLIENT_URL;

    if (typeof fromEnv === 'string' && fromEnv && fromEnv !== 'undefined' && fromEnv !== 'null') {
      return fromEnv;
    }

    return (await environmentPromise).authClientUrl;
  },

  getSmartHomeSetupClientUrl: async () => {
    const fromEnv = process.env.SMART_HOME_SETUP_CLIENT_URL;

    if (typeof fromEnv === 'string' && fromEnv && fromEnv !== 'undefined' && fromEnv !== 'null') {
      return fromEnv;
    }

    return (await environmentPromise).smartHomeSetupClientUrl;
  },

  getMaintenanceClientUrl: async () => {
    const fromEnv = process.env.MAINTENANCE_CLIENT_URL;

    if (typeof fromEnv === 'string' && fromEnv && fromEnv !== 'undefined' && fromEnv !== 'null') {
      return fromEnv;
    }

    return (await environmentPromise).maintenanceClientUrl;
  },

  getTenantClientUrl: async () => {
    const fromEnv = process.env.TENANT_CLIENT_URL;

    if (typeof fromEnv === 'string' && fromEnv && fromEnv !== 'undefined' && fromEnv !== 'null') {
      return fromEnv;
    }

    return (await environmentPromise).tenantClientUrl;
  },
};
