import EntityTableButton from '@/notmagic/components/EntityTable/components/EntityTableButton/EntityTableButton';
import { DownloadOutlined } from '@ant-design/icons';
import type { TableRef } from '@propify/components';
import { Tooltip } from 'antd';
import type { FC, RefObject } from 'react';
import { useState } from 'react';
import { CSVLink } from 'react-csv';
import styles from './styles.module.less';

export interface Props {
  tableRef: RefObject<TableRef<any, any>>;
  filename?: string;
  totalResults?: number;
}

const ExportButton: FC<Props> = ({ tableRef, filename, totalResults: total }) => {
  const [data, setData] = useState<any[]>([]);

  const handleClick = () => {
    setData(tableRef?.current?.dataExport() || []);
  };

  const getFilename = () => {
    if (filename) {
      return /\.\w+$/.test(filename) ? filename : `${filename}.csv`;
    }
    return 'export.csv';
  };

  return (
    <CSVLink data={data} filename={getFilename()}>
      <Tooltip
        placement="bottom"
        title={
          <>
            Export{' '}
            {typeof total === 'number' ? (
              <>
                {total} {total === 1 ? 'result' : 'results'}
              </>
            ) : (
              ''
            )}
          </>
        }
      >
        <EntityTableButton
          data-test="exportTableButton"
          onClick={handleClick}
          disabled={total === 0}
          className={styles.exportButton}
          style={{ minHeight: 34 }}
        >
          <DownloadOutlined />
        </EntityTableButton>
      </Tooltip>
    </CSVLink>
  );
};

export default ExportButton;
