import type { ValidateFunction } from 'ajv';
import Ajv from 'ajv';
import type { Table } from '../components/EntityTable/types';
import type { EntityType } from '../types';
import EntitySchema from './entity-type.json';
import MetadataSchema from './table-definition.json';

const entitySchemaValidator = new Ajv().compile<EntityType>(EntitySchema);
const metadataSchemaValidator = new Ajv().compile<Table>(MetadataSchema);

export const isEntitySchemaValid = (
  entityType: EntityType & { $schema?: string },
  schemaValidator: ValidateFunction<EntityType> = entitySchemaValidator,
): [boolean, typeof entitySchemaValidator.errors | undefined] => {
  const result = schemaValidator(entityType);
  return [result, schemaValidator.errors];
};

export const isMetadataSchemaValid = (
  tableMetadata: Table,
  schemaValidator: ValidateFunction<Table> = metadataSchemaValidator,
): [boolean, typeof metadataSchemaValidator.errors | undefined] => {
  const result = schemaValidator(tableMetadata);
  return [result, schemaValidator.errors];
};

export const logInvalidEntitySchema = (
  type: string,
  errors: typeof entitySchemaValidator.errors,
) => {
  // eslint-disable-next-line no-console
  console.error(`ERROR: JSON schema for entity type ${type} is not valid`, errors);
};

export const logInvalidMetadataSchema = (errors: typeof metadataSchemaValidator.errors) => {
  // eslint-disable-next-line no-console
  console.error(`ERROR: JSON schema for metadata is not valid`, errors);
};
