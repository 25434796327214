import type { ConfirmFnType } from '@/components/FloatingInlineEdit';
import FloatingInlineDropdownEdit from '@/components/FloatingInlineEdit/FloatingInlineDropdownEdit';
import { ApplicationType } from '@/domain/applications';
import useEnumeration from '@/hooks/useEnumeration';

interface Props {
  onConfirm: ConfirmFnType<string>;
  applicationType: ApplicationType;
  hideEdit?: boolean;
  editDisabled?: boolean;
}

const EditableApplicationType = ({ onConfirm, applicationType, hideEdit, editDisabled }: Props) => {
  const { options: applicationTypesOptions } = useEnumeration('application-type');

  return (
    <FloatingInlineDropdownEdit
      options={applicationTypesOptions}
      onConfirm={onConfirm}
      initialValue={applicationType}
      displayValue={
        applicationTypesOptions.find((option) => option.value === applicationType)?.label
      }
      hideEdit={hideEdit}
      editDisabled={editDisabled}
      editTooltip={
        applicationType === ApplicationType.PRIMARY
          ? "Promote another applicant to 'Primary' in order to alter this applicant's role."
          : undefined
      }
    />
  );
};

export default EditableApplicationType;
