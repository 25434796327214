import type {
  FilterComponentType,
  FilterComponentTypeProps,
} from '@/notmagic/components/EntityTable/types';
import { Select } from 'antd';

type Props = FilterComponentTypeProps;

const options = [
  {
    label: 'Yes',
    value: 'true',
  },
  {
    label: 'No',
    value: 'false',
  },
];

const Component: FilterComponentType<Props> = ({ editable, value, onChangeValue }) => {
  if (Array.isArray(value)) {
    // eslint-disable-next-line no-console
    console.error('ERROR: Filters of type BOOLEAN only support a single value');
    return <></>;
  }

  if (!editable) {
    return <>{value === 'true' ? 'Yes' : 'No'}</>;
  }

  return (
    <Select
      value={value}
      options={options}
      onChange={onChangeValue}
      style={{ width: '100%' }}
      allowClear
    />
  );
};

export default {
  Component,
  jsonataQuery: (value: string, key: string) =>
    value === 'true' ? `$boolean($v.${key})` : `$not($boolean($v.${key}))`,
};
