import { AntInputNumber } from '@/components/Formik/CreateAntField';
import type { RenewalOption } from '@/domain/renewal';
import type { FormikSubmitFn } from '@/utils/types';
import { Form, Modal } from 'antd';
import { Field, Formik } from 'formik';
import type { FC } from 'react';
import * as Yup from 'yup';

export interface LeaseTermFormValues {
  minMonths: number;
  maxMonths: number;
}

interface Props {
  renewalOptions: RenewalOption[];
  renewalOptionToEdit?: RenewalOption;
  visible?: boolean;
  onCancel?: () => void;
  onAdd?: (newOption: LeaseTermFormValues) => void;
  onEdit?: (newValues: LeaseTermFormValues, renewalOption: RenewalOption) => void;
  // Only relevant if renewalOptionToEdit is set.
  optionToEditIndex?: number;
}

const minimumDays = 1;
const maximumDays = 120;

const AddLeaseTerm: FC<Props> = ({
  renewalOptions,
  renewalOptionToEdit,
  onAdd,
  onEdit,
  onCancel,
  visible = true,
  optionToEditIndex = -1,
}) => {
  const validationSchema = Yup.object().shape({
    minMonths: Yup.number()
      .typeError('Must be a number')
      .required('Required')
      .min(minimumDays)
      .max(maximumDays)
      .test('is-min', 'Min should be greater than the previous term Max Date.', (value: number) => {
        const previousOptionIndex = renewalOptionToEdit
          ? renewalOptions.findIndex((o) => o.id === renewalOptionToEdit.id) - 1
          : renewalOptions.length - 1;

        const maxMonthsToCompare =
          previousOptionIndex >= 0 ? renewalOptions[previousOptionIndex]?.maxMonths : 0;

        return !maxMonthsToCompare || value > maxMonthsToCompare;
      })
      .test('is-whole', 'Number should be whole', (value: number) =>
        new RegExp(/^\d+$/).test((value as unknown) as string),
      ),
    maxMonths: Yup.number()
      .typeError('Must be a number')
      .required('Required')
      .max(maximumDays)
      .min(Yup.ref('minMonths'))
      .test('is-max', 'Max should be smaller than the next term Min Date.', (value: number) => {
        if (optionToEditIndex === -1) return true;

        const nextOptionIndex =
          optionToEditIndex === renewalOptions.length - 1 ? -1 : optionToEditIndex + 1;

        const minMonthsToCompare =
          nextOptionIndex >= 0 ? renewalOptions[nextOptionIndex]?.minMonths : false;

        return !minMonthsToCompare || value < minMonthsToCompare;
      })
      .test('is-whole', 'Number should be whole', (value: number) =>
        new RegExp(/^\d+$/).test((value as unknown) as string),
      ),
  });

  const addTerm: FormikSubmitFn<LeaseTermFormValues> = (values, actions) => {
    onAdd?.(values);
    actions.resetForm();
  };

  const editTerm: FormikSubmitFn<LeaseTermFormValues> = (values, actions) => {
    if (!renewalOptionToEdit) return;

    onEdit?.(values, renewalOptionToEdit);
    actions.resetForm();
  };

  return (
    <Formik<LeaseTermFormValues>
      enableReinitialize
      onSubmit={renewalOptionToEdit ? editTerm : addTerm}
      validationSchema={validationSchema}
      initialValues={{
        minMonths: renewalOptionToEdit?.minMonths ?? 0,
        maxMonths: renewalOptionToEdit?.maxMonths ?? 0,
      }}
    >
      {({ handleSubmit, submitForm, resetForm }) => (
        <Modal
          visible={visible}
          onCancel={() => {
            onCancel?.();
            resetForm();
          }}
          onOk={submitForm}
          okText="Save"
          title={renewalOptionToEdit ? 'Edit Term' : 'Add Term'}
          maskClosable={false}
        >
          <Form labelCol={{ span: 9 }} wrapperCol={{ span: 18 }} onSubmitCapture={handleSubmit}>
            <Field name="minMonths" label="Minimum Length (Months)" component={AntInputNumber} />
            <Field name="maxMonths" label="Maximum Length (Months)" component={AntInputNumber} />
          </Form>
        </Modal>
      )}
    </Formik>
  );
};

export default AddLeaseTerm;
