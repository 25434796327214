import { Badge, Tooltip } from 'antd';
import isEmpty from 'lodash/isEmpty';
import type { FC } from 'react';
import { useMemo } from 'react';
import styles from '../styles.module.less';

type Summary = Record<string, number>;

interface Props {
  label: string;
  count?: number | string;
  showBadge?: boolean;
  tooltipText?: string;
  summary?: Summary;
  summaryKeys?: string[];
}

const TabWithBadge: FC<Props> = ({
  label,
  count,
  showBadge = true,
  tooltipText = '',
  summary,
  summaryKeys,
}) => {
  const badgeCount = useMemo(() => {
    if (count !== undefined) {
      return count;
    }

    if (!summary || isEmpty(summaryKeys)) {
      return undefined;
    }

    return Object.entries(summary).reduce((counter, summaryEntry) => {
      return summaryKeys?.includes(summaryEntry[0]) ? counter + summaryEntry[1] : counter;
    }, 0);
  }, [count, summary, summaryKeys]);

  return (
    <span className={styles.tabWithBadge}>
      {label}
      {showBadge && badgeCount !== undefined && (
        <Tooltip title={tooltipText}>
          <Badge count={badgeCount} overflowCount={999} style={{ backgroundColor: '#0096FA' }} />
        </Tooltip>
      )}
    </span>
  );
};

export default TabWithBadge;
