import { useEffect, useState } from 'react';
import type { JsonataWorkerProps } from '../workers/jsonata.worker';
import JsonataWorker from '../workers/jsonata.worker?worker';

export type ReturnTypes<Data> = [(data: JsonataWorkerProps<Data>) => void, Worker];

/**
 * @param callback This takes the function passed in the first render, ignoring the rest
 */
export const useJsonata = <Data = any, Result = any>(
  callback: (result: Result) => void,
): ReturnTypes<Data> => {
  const [[run, worker]] = useState<ReturnTypes<Data>>(() => {
    const _worker = new JsonataWorker();

    const _run = (props: JsonataWorkerProps) => {
      _worker.postMessage(props);
    };

    _worker.onmessage = (messageEvent: MessageEvent<Result>) => {
      callback(messageEvent.data);
    };

    return [_run, _worker];
  });

  useEffect(() => {
    return () => {
      worker.terminate();
    };
  }, [worker]);

  // These two values are immutables so it's safe to add them to hook dependencies
  return [run, worker];
};
