import type { ModalFormProps } from '@/components/Button/formButton';
import { AntInput, AntInputNumber, AntSelect } from '@/components/Formik/CreateAntField';
import type { AdditionalIncome, Application } from '@/domain/applications';
import { Frequency } from '@/domain/applications';
import type { New, Option } from '@/domain/base';
import useEnumeration from '@/hooks/useEnumeration';
import { ApplicationsService } from '@/services/applications';
import { handleError } from '@/utils/utils';
import { Form, Modal } from 'antd';
import type { Operation } from 'fast-json-patch';
import { Field, Formik } from 'formik';
import type { FC } from 'react';
import { useCallback, useState } from 'react';
import * as Yup from 'yup';

interface Props extends ModalFormProps<[Application]> {
  additionalIncome?: AdditionalIncome;
  application: Application;
}

const validationSchema = Yup.object().shape({
  source: Yup.string().trim().required('Required').typeError('Should be a string'),
  frequency: Yup.string().oneOf(Object.values(Frequency)).required('Required'),
  amount: Yup.number().required('Required').typeError('Should be a number'),
});

const AdditionalIncomeEditModal: FC<Props> = ({
  additionalIncome,
  onCancel,
  application,
  onSuccess,
}) => {
  const [submitting, setSubmitting] = useState(false);
  const { options: frequencyOptions } = useEnumeration('application-additional-income-frequency');
  const save = useCallback(
    async (values: New<AdditionalIncome>) => {
      try {
        setSubmitting(true);
        const operations: Operation[] = [
          {
            op: 'replace',
            path: `/additionalIncome`,
            value: values,
          },
        ];
        const updatedApplication = await ApplicationsService.patch(
          application.id,
          operations,
          application.version,
        );
        onSuccess(updatedApplication);
      } catch (error) {
        handleError(error, {
          toastFallbackMessage: 'There was an error saving the additional income',
        });
      } finally {
        setSubmitting(false);
      }
    },
    [application, onSuccess],
  );

  return (
    <Formik<New<AdditionalIncome>>
      onSubmit={save}
      validationSchema={validationSchema}
      initialValues={{
        source: additionalIncome?.source ?? '',
        frequency: (additionalIncome?.frequency ?? '') as Frequency,
        amount: additionalIncome?.amount ?? 0,
      }}
    >
      {({ handleSubmit, submitCount, submitForm }) => (
        <Modal
          visible
          onCancel={onCancel}
          onOk={() => submitForm()}
          okText="Save"
          title="Edit Additional Income"
          okButtonProps={{ loading: submitting, disabled: submitting }}
        >
          <Form layout="vertical" onSubmitCapture={handleSubmit}>
            <Field
              component={AntInput}
              label="Source"
              name="source"
              data-testid="source"
              type="text"
              hasFeedback
              submitCount={submitCount}
              required
            />

            <Field
              component={AntSelect}
              label="Frequency"
              name="frequency"
              data-testid="frequency"
              selectOptions={frequencyOptions}
              getOptionLabel={(option: Option<Frequency>) => option.label}
              getOptionValue={(option: Option<Frequency>) => option.value}
              hasFeedback
              submitCount={submitCount}
              required
            />

            <Field
              component={AntInputNumber}
              label="Additional Amount"
              name="amount"
              data-testid="amount"
              hasFeedback
              submitCount={submitCount}
              required
            />
          </Form>
        </Modal>
      )}
    </Formik>
  );
};

export default AdditionalIncomeEditModal;
