import type {
  ColumnComponentType,
  ColumnComponentTypeProps,
  ColumnType,
} from '@/notmagic/components/EntityTable/types';
import MessagesModal from '@/pages/prospects/leads/MessagesModal';
import { Tooltip } from 'antd';
import { useState } from 'react';

type Value = {
  lastLeadMessage: string | undefined;
  leadIds: number[];
};

const Component: ColumnComponentType<ColumnComponentTypeProps<Value>> = ({ value }) => {
  const [showMessagesModal, setShowMessagesModal] = useState(false);

  if (!value || !value?.lastLeadMessage) {
    return null;
  }

  return (
    <>
      <Tooltip title={value.lastLeadMessage}>
        <div className="lastLeadMessage" onClick={() => setShowMessagesModal(true)}>
          <a>{value.lastLeadMessage}</a>
        </div>
      </Tooltip>
      {showMessagesModal && (
        <MessagesModal leadIds={value.leadIds} onClose={() => setShowMessagesModal(false)} />
      )}
    </>
  );
};

const Definition: ColumnType = {
  Component,
  getValueForExport: (value: Value) => value.lastLeadMessage || '',
};

export default Definition;
