import FloatingInlineDropdownEdit from '@/components/FloatingInlineEdit/FloatingInlineDropdownEdit';
import type {
  ColumnComponentType,
  ColumnComponentTypeProps,
  ColumnType,
} from '@/notmagic/components/EntityTable/types';
import { handleError } from '@/utils/utils';
import { CheckCircleOutlined, CloseCircleOutlined } from '@ant-design/icons';

// TODO: Add support for edit

type Props = ColumnComponentTypeProps<boolean> & {
  booleanIcons?: boolean;
};

const ICONS = {
  YES: (
    <CheckCircleOutlined
      className="entityTableBooleanIcon entityTableBooleanIconTrue"
      style={{ color: 'green' }}
    />
  ),
  NO: (
    <CloseCircleOutlined
      className="entityTableBooleanIcon entityTableBooleanIconFalse"
      style={{ color: 'red' }}
    />
  ),
};

const Component: ColumnComponentType<Props> = ({
  value,
  editable,
  update,
  textIfNull,
  booleanIcons,
}) => {
  const displayValue = value ? 'Yes' : value === false ? 'No' : textIfNull;

  const icon = booleanIcons
    ? value
      ? ICONS.YES
      : value === false
      ? ICONS.NO
      : textIfNull
    : undefined;

  if (!editable) {
    return <>{icon || displayValue}</>;
  }

  return (
    <FloatingInlineDropdownEdit
      displayValue={icon || displayValue}
      initialValue={value?.toString() ?? ''}
      options={[
        {
          value: 'true',
          label: 'Yes',
        },
        {
          value: 'false',
          label: 'No',
        },
      ]}
      onConfirm={(newValue: string, done, fail) => {
        if (!update) {
          return;
        }

        update(newValue === 'true')
          .then(done)
          .catch((error: Error) => {
            handleError(error, { displayToast: false });
            fail(error);
          });
      }}
    />
  );
};

const Definition: ColumnType = {
  Component,
  sort: (a: boolean, b: boolean) => (a === b ? 0 : a < b ? -1 : 1),
  getValueForExport: (value: boolean) => (value !== undefined ? (value ? 'Yes' : 'No') : ''),
};

export default Definition;
