import { AntInput, AntInputNumber } from '@/components/Formik/CreateAntField';
import type { Charge } from '@/domain/lease';
import useEnumeration from '@/hooks/useEnumeration';
import type { FormikSubmitFn } from '@/utils/types';
import { Form, Modal, Select } from 'antd';
import { Field, Formik } from 'formik';
import type { FC } from 'react';
import * as Yup from 'yup';

export interface FeeFormValues {
  name: string;
  amount?: number;
  type: string;
}

interface Props {
  chargeToEdit?: Charge;
  visible?: boolean;
  onCancel?: () => void;
  onAdd?: (newFee: FeeFormValues) => void;
  onEdit?: (newValues: FeeFormValues, charge: Charge) => void;
}

const AddItemModal: FC<Props> = ({ onCancel, chargeToEdit, visible = true, onAdd, onEdit }) => {
  const validationSchema = Yup.object().shape({
    name: Yup.string().required('Fee Name is required'),
    type: Yup.string().required('Type is required'),
    amount: Yup.number()
      .typeError('Invalid number')
      .required('Fee Amount is required')
      .min(1, 'Must be greater than 0'),
  });

  const addFee: FormikSubmitFn<FeeFormValues> = (values, actions) => {
    onAdd?.(values);
    actions.resetForm();
  };

  const editFee: FormikSubmitFn<FeeFormValues> = (values, actions) => {
    if (!chargeToEdit) return;

    onEdit?.(values, chargeToEdit);
    actions.resetForm();
  };

  const { options: leaseChargeTypeEnumOptions } = useEnumeration('lease-charge-type');

  return (
    <Formik<FeeFormValues>
      enableReinitialize
      onSubmit={chargeToEdit ? editFee : addFee}
      validationSchema={validationSchema}
      initialValues={{
        name: chargeToEdit?.name ?? '',
        amount: chargeToEdit?.amount,
        type: chargeToEdit?.type ?? '',
      }}
    >
      {({ handleSubmit, submitForm, values, setFieldValue, errors }) => (
        <Modal
          visible={visible}
          onCancel={onCancel}
          onOk={submitForm}
          okText="Save"
          okButtonProps={{
            // @ts-ignore
            ['data-testid']: 'submit-button',
          }}
          title={chargeToEdit ? 'Edit Fee' : 'Add Fee'}
          maskClosable={false}
        >
          <Form labelCol={{ span: 6 }} wrapperCol={{ span: 18 }} onSubmitCapture={handleSubmit}>
            <Field
              component={AntInput}
              label="Fee Name"
              name="name"
              type="text"
              hasFeedback
              data-testid="fee-name-input"
            />
            <Form.Item
              label="Type"
              name="type"
              help={errors.type}
              validateStatus={errors.type && 'error'}
            >
              <Select
                defaultValue={values.type}
                onChange={(newValue) => {
                  setFieldValue('type', newValue);
                }}
                data-testid="type-input"
              >
                {leaseChargeTypeEnumOptions.map((type) => {
                  return (
                    <Select.Option
                      key={type.value}
                      value={type.value}
                      data-testid={`type-option-${type.value}`}
                    >
                      {type.label}
                    </Select.Option>
                  );
                })}
              </Select>
            </Form.Item>
            <Field
              component={AntInputNumber}
              label="Fee Amount"
              name="amount"
              hasFeedback
              style={{ width: '100%' }}
              data-testid="fee-amount-input"
            />
          </Form>
        </Modal>
      )}
    </Formik>
  );
};

export default AddItemModal;
