import type { FC } from 'react';
import type { ClassNameMap } from '../../shared/utils/material';
import { InfoIcon, Tooltip } from '../../shared/utils/material';
import type { Column } from '../utils';

interface Props {
  column: Column;
  styles: ClassNameMap<string>;
}

const HeaderTitle: FC<Props> = ({ column, styles }) => {
  const title = column.renderTitle ? column.renderTitle() : column.title;
  return column.headerTooltip ? (
    <>
      <Tooltip
        title={column.headerTooltip.title}
        placement={column.headerTooltip.placement || 'top'}
        classes={{ tooltip: styles.tooltip }}
        arrow
      >
        <span style={{ display: 'flex', alignItems: 'center' }}>
          {title}
          {column.headerTooltip.showInfoIcon && (
            <InfoIcon style={{ marginLeft: 5, color: '#1990ff', fontSize: '0.89rem' }} />
          )}
        </span>
      </Tooltip>
    </>
  ) : (
    <>{title}</>
  );
};

export default HeaderTitle;
