import type { LookupParams } from '@/domain/base';
import type { PromotionLookup, PromotionSearchResult } from '@/domain/promotions';
import { api } from '@/utils/configuration';
import { getAxiosFetcher } from '@/utils/request';

export const PromotionService = {
  search: getAxiosFetcher<PromotionSearchResult[], []>(() => [api.PROMOTIONS]),

  lookup: getAxiosFetcher<PromotionLookup[], LookupParams[]>((params) => [
    `${api.PROMOTIONS}/lookup`,
    { params },
  ]),
};
