import type { ModalFormProps } from '@/components/Button/formButton';
import { ModalFormButton } from '@/components/Button/formButton';
import { AntCheckbox, AntInput, AntSelect, AntTextArea } from '@/components/Formik/CreateAntField';
import CreateNoteForm from '@/components/Notes/create';
import type { Entities } from '@/domain/entity-type';
import { EntityType } from '@/domain/entity-type';
import type { Note } from '@/domain/note';
import { NoteType } from '@/domain/note';
import type { Task } from '@/domain/tasks';
import type { UserLookup } from '@/domain/user';
import { NoteService } from '@/services/notes';
import { TasksService } from '@/services/tasks';
import { SHORT_DATE_FORMAT, SHORT_DATE_TIME_FORMAT } from '@/utils/time';
import { enumToLabel, handleError } from '@/utils/utils';
import { DeleteOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, Form, Modal, Popconfirm } from 'antd';
import { Field, Formik } from 'formik';
import moment from 'moment';
import type { FC } from 'react';
import { useEffect, useState } from 'react';
import * as Yup from 'yup';
import styles from './styles.module.less';

interface Props extends ModalFormProps<[Task]> {
  onDelete: (taskId: number) => void;
  task: Task;
  users: UserLookup[];
  otherEntities?: Entities;
  showResponsibleField?: boolean;
  onNoteSaved?: (note: Note) => void;
}

const validationSchema = Yup.object().shape({
  name: Yup.string().trim().required('Task name is required'),
  description: Yup.string().trim().required('Description is required'),
  responsible: Yup.array().of(Yup.string()),
  required: Yup.boolean().required('The field is required'),
  status: Yup.array().of(Yup.string()),
  externalLink: Yup.string().trim(),
});

interface CreateTaskValues {
  name: string;
  description?: string;
  responsible: string;
  required: boolean;
  status: string;
  externalLink: string;
}

const EditTaskModal: FC<Props> = ({
  task,
  onSuccess,
  onDelete,
  onCancel,
  users,
  otherEntities,
  showResponsibleField,
  onNoteSaved,
}) => {
  const [notes, setNotes] = useState<Note[]>([]);
  const [editMode, setEditMode] = useState(false);

  const onSave = async (values: CreateTaskValues) => {
    try {
      const taskToCreate = {
        ...task,
        ...(values as Task),
        externalLink: values.externalLink?.trim() ? values.externalLink : undefined,
      };
      const taskCreated = await TasksService.update(taskToCreate);
      onSuccess(taskCreated);
    } catch (error) {
      handleError(error, { toastFallbackMessage: 'There was an error saving the task' });
    }
  };

  const onDeleteTask = async () => {
    try {
      await TasksService.delete(task.id);
      onDelete(task.id);
      onCancel?.();
    } catch (error) {
      handleError(error, { toastFallbackMessage: 'There was an error deleting the task' });
    }
  };

  const getNotes = async () => {
    try {
      const getTaskNotes = await NoteService.search({
        entityType: EntityType.TASK,
        entityId: task.id,
      });
      setNotes(getTaskNotes);
    } catch (error) {
      handleError(error, { toastFallbackMessage: 'There was an error getting notes' });
    }
  };

  useEffect(() => {
    getNotes();
  }, []);

  const onTriggerCancel = () => {
    if (editMode) {
      setEditMode(false);
      return;
    }
    onCancel?.();
  };

  const onSaveNote = (note: Note) => {
    setNotes((prevState) => [...prevState, note]);
    onNoteSaved?.(note);
  };

  return (
    <Formik<CreateTaskValues>
      onSubmit={onSave}
      validationSchema={validationSchema}
      initialValues={{
        name: task.name,
        description: task.description,
        responsible: task.responsible,
        required: task.required,
        status: task.status || 'NOT_STARTED',
        externalLink: task.externalLink || '',
      }}
    >
      {({ values, handleSubmit, submitCount }) => (
        <Modal
          visible
          title={task.name}
          footer={[
            <div key="footer" className={styles.modalFooter}>
              <div>{values.required ? 'Required' : 'Not Required'}</div>

              <Popconfirm
                title="Are you sure?"
                onConfirm={() => onDeleteTask()}
                okText="Yes"
                cancelText="No"
              >
                <Button
                  icon={<DeleteOutlined />}
                  type="link"
                  size="small"
                  danger
                  title="Delete question"
                />
              </Popconfirm>

              <Button onClick={() => onTriggerCancel()}>Cancel</Button>

              {editMode ? (
                <Button onClick={() => onSave(values)}>Save</Button>
              ) : (
                <Button onClick={() => setEditMode(true)}>Edit</Button>
              )}
            </div>,
          ]}
          onCancel={onCancel}
          className="modalWithoutPadding"
        >
          <Form onSubmitCapture={handleSubmit}>
            <div className={styles.editTaskModalForm}>
              {editMode && (
                <div className={styles.fieldGrid}>
                  <span>Task Name</span>
                  <Field
                    component={AntInput}
                    name="name"
                    type="text"
                    placeholder="Task Name"
                    hasFeedback
                    submitCount={submitCount}
                  />
                </div>
              )}
              <div className={styles.fieldGrid}>
                <span>Description</span>
                {editMode ? (
                  <Field
                    component={AntTextArea}
                    name="description"
                    type="text"
                    placeholder="Description"
                    hasFeedback
                    submitCount={submitCount}
                  />
                ) : (
                  values.description
                )}
              </div>

              {showResponsibleField && (
                <div className={styles.flexFields}>
                  <span>Responsible</span>
                  {editMode ? (
                    <Field
                      component={AntSelect}
                      name="responsible"
                      hasFeedback
                      formItemStyle={{ width: '100%' }}
                      selectOptions={['PROPERTY_MANAGER', 'RESIDENT']}
                      getOptionLabel={(option: string) => enumToLabel(option)}
                      getOptionValue={(option: string) => option}
                    />
                  ) : (
                    enumToLabel(values.responsible)
                  )}
                </div>
              )}

              <div className={styles.flexFields}>
                <span>Status</span>
                {editMode ? (
                  <Field
                    component={AntSelect}
                    name="status"
                    hasFeedback
                    formItemStyle={{ width: '100%' }}
                    selectOptions={['NOT_STARTED', 'IN_PROGRESS', 'COMPLETED', 'DOES_NOT_APPLY']}
                    getOptionLabel={(option: string) => enumToLabel(option)}
                    getOptionValue={(option: string) => option}
                  />
                ) : (
                  enumToLabel(values.status)
                )}
              </div>

              {editMode ? (
                <Field
                  component={AntCheckbox}
                  label="Is task required?"
                  name="required"
                  type="checkbox"
                  checked={values.required}
                  hasFeedback
                />
              ) : (
                <div className={styles.fieldGrid}>
                  <span>Notes</span>
                  {notes.map((note) => (
                    <div key={note.id} className={styles.flexFields} style={{ paddingLeft: 15 }}>
                      <span>{moment(note.createTime).format(SHORT_DATE_FORMAT)}</span>
                      <div style={{ whiteSpace: 'pre-wrap' }}>{note.note}</div>
                    </div>
                  ))}
                  <ModalFormButton
                    icon={<PlusOutlined />}
                    buttonType="link"
                    buttonText="Add note"
                    key="add-note-btn"
                    onSuccess={onSaveNote}
                  >
                    {(props) => (
                      <CreateNoteForm
                        {...props}
                        defaultType={NoteType.GENERAL}
                        entityType={EntityType.TASK}
                        entityId={task.id}
                        otherEntities={{
                          [EntityType.TASK]: [task.id],
                          ...(otherEntities || {}),
                        }}
                      />
                    )}
                  </ModalFormButton>
                  ,
                </div>
              )}

              <div className={styles.flexFields}>
                <span>External Link</span>
                {editMode ? (
                  <Field
                    component={AntInput}
                    name="externalLink"
                    type="text"
                    placeholder="External Link"
                    hasFeedback
                    submitCount={submitCount}
                  />
                ) : (
                  values.externalLink
                )}
              </div>

              <div className={`${styles.flexFields} ${styles.completedRow}`}>
                <div className={styles.flexFields}>
                  <span>Completed By</span>
                  {users.find((u) => u.loginId === task.completedByLoginId)?.displayName ?? 'N/A'}
                </div>

                <div className={styles.flexFields}>
                  <span>Completed On</span>
                  {task.completedTime
                    ? moment(task.completedTime).format(SHORT_DATE_TIME_FORMAT)
                    : 'N/A'}
                </div>
              </div>
            </div>
          </Form>
        </Modal>
      )}
    </Formik>
  );
};

export default EditTaskModal;
