import type { Charge, UtilityResponsiblePartyRoleType, UtilityType } from '@/domain/lease';
import { ChargeFrequency } from '@/domain/lease';
import type { RenewalDTO, RenewalOption } from '@/domain/renewal';
import useEnumeration from '@/hooks/useEnumeration';
import '@/pages/prospects/styles.less';
import { DownOutlined, PercentageOutlined, PlusOutlined, UpOutlined } from '@ant-design/icons';
import { formatCurrency } from '@propify/components';
import { Button, Col, Input, Row, Space, Spin } from 'antd';
import classNames from 'classnames';
import { useFormikContext } from 'formik';
import { Form, InputNumber, Select } from 'formik-antd';
import type { FC } from 'react';
import { useEffect, useMemo, useState } from 'react';
import type { FeeFormValues } from './AddItemModal';
import AddItemModal from './AddItemModal';
import EditFeeWrapper from './EditFeeWrapper';
import type { RenewalsModalValues } from './RenewalsModal';
import { useRenewalUtilities } from './RenewalTermsModalUtilities';
import './styles.less';

type TableType = 'monthly-fee' | 'one-time-fee' | 'promotion' | 'utilities';

type Data = {
  id?: number;
  description?: string;
  value: any;
};

interface Props {
  renewal: RenewalDTO;
  renewalOption?: RenewalOption;
  updateRenewalOption?: (r: RenewalOption, index: number) => void;
  updateRenewalCharges?: (r: Partial<Charge>[]) => void;
  data?: Data[];
  type?: TableType;
  showActions?: boolean;
  hideCollapseButton?: boolean;
  summaryText?: string;
  title?: string;
  editable?: boolean;
  showYearOverYearEscalator?: boolean;
  renewalOptionIndex?: number;
  loading?: boolean;
  charges?: Charge[];
}

const TableWithSummary: FC<Props> = ({
  data = [],
  summaryText,
  title,
  showActions,
  hideCollapseButton = false,
  renewal,
  renewalOption,
  updateRenewalOption,
  type,
  editable = true,
  showYearOverYearEscalator = false,
  renewalOptionIndex,
  loading,
  charges = [],
}) => {
  const { isValid, errors, setFieldValue, values } = useFormikContext<RenewalsModalValues>();
  const [isCollapsed, setIsCollapsed] = useState(false);
  const [showMonthlyFees, setShowMonthlyFees] = useState(false);
  const isFeeType = ['monthly-fee', 'one-time-fee'].includes(type!);

  const { options: utilitiesOptions, isValidating: loadingUtilitiesOptions } = useEnumeration(
    'utility-responsible-party-role-type',
  );

  const { options: leaseChargeTypeEnumOptions } = useEnumeration('lease-charge-type');

  const summary = useMemo(() => {
    if (type === 'monthly-fee') {
      return charges.reduce((prev, ac) => {
        if (ac.frequency === ChargeFrequency.MONTHLY) {
          return Number(prev) + Number(ac?.amount || 0);
        }
        return prev;
      }, 0);
    }

    return data.reduce((prev, ac) => Number(prev) + Number(ac?.value), 0);
  }, [data, charges, type]);

  useEffect(() => {
    const newValue = [...renewal.utilities];
    setFieldValue('utilities', newValue);
  }, [renewal.utilities]);

  const { values: utilitiesValues, setUtilityValue } = useRenewalUtilities(
    type === 'utilities',
    renewal,
  );

  const handleUtilityChange = (
    newResponsibleValue: UtilityResponsiblePartyRoleType,
    utilityType: UtilityType,
  ) => {
    setUtilityValue(utilityType, newResponsibleValue);
  };

  const filteredCharges = useMemo(() => {
    if (type === 'monthly-fee') {
      return charges.filter((c) => c.frequency === ChargeFrequency.MONTHLY);
    }

    if (type === 'one-time-fee') {
      return charges.filter((c) => c.frequency === ChargeFrequency.ONE_TIME);
    }

    return charges;
  }, [charges, type]);

  const handleAddMonthlyFee = (fee: FeeFormValues) => {
    if (!renewalOption || renewalOptionIndex === undefined) {
      return;
    }

    const updatedRenewalOption = {
      ...renewalOption,
      charges: [
        ...renewalOption.charges,
        {
          name: fee.name,
          amount: fee.amount,
          type: fee.type,
          frequency: ChargeFrequency.MONTHLY,
        } as Charge,
      ],
    };

    const updatedRenewalOptions = [...values.options];
    updatedRenewalOptions[renewalOptionIndex] = updatedRenewalOption;

    setFieldValue('options', updatedRenewalOptions);
    setShowMonthlyFees(false);
  };

  return (
    <div className="table-summary">
      {title && (
        <div className="header">
          <div className="title">{title}</div>

          {showActions && (
            <div className="header-actions">
              <Button size="small" className="add" onClick={() => setShowMonthlyFees(true)}>
                <PlusOutlined />
              </Button>

              {!hideCollapseButton && (
                <Button
                  className="collapse"
                  size="small"
                  onClick={() => setIsCollapsed(!isCollapsed)}
                >
                  {isCollapsed ? <DownOutlined /> : <UpOutlined />}
                </Button>
              )}
            </div>
          )}
        </div>
      )}

      {loading && (
        <div style={{ textAlign: 'center', marginTop: 8 }}>
          <Spin />
        </div>
      )}

      {!loading &&
        !isCollapsed &&
        isFeeType &&
        filteredCharges.map((c, index) => (
          <Row key={c.id} className="header wrapper-currency">
            <Col>{c.name}</Col>
            <Col>{leaseChargeTypeEnumOptions.find((o) => o.value === c.type)?.label || ''}</Col>
            <Col>
              <div className="wrapper">
                <Space style={{ width: '100%' }}>
                  <div>{formatCurrency(c?.amount)}</div>
                  {editable && renewalOption && (
                    <EditFeeWrapper
                      chargeToEdit={c}
                      chargeIndex={index}
                      renewalOption={renewalOption}
                    />
                  )}

                  {type === 'one-time-fee' && (
                    <EditFeeWrapper
                      chargeToEdit={c}
                      chargeIndex={index}
                      renewalCharges={filteredCharges}
                    />
                  )}
                </Space>
              </div>
            </Col>
          </Row>
        ))}

      {!loading &&
        type === 'utilities' &&
        !isCollapsed &&
        !loadingUtilitiesOptions &&
        data.map((d) => (
          <Row key={d?.id} className="header">
            <Col xs={12}>{d?.description}</Col>
            <Col xs={10}>
              <div className="wrapper">
                {type === 'utilities' && (
                  <Select
                    name="_utilities"
                    options={utilitiesOptions}
                    value={utilitiesValues[d.value]}
                    onChange={(responsible) => {
                      const utilityType = d.value;
                      handleUtilityChange(responsible, utilityType);
                    }}
                    style={{ width: '100%' }}
                    className={classNames({
                      ['no-utility-selected']:
                        !isValid && errors.utilities && !utilitiesValues[d.value],
                    })}
                  />
                )}
              </div>
            </Col>
          </Row>
        ))}

      {!loading && type === 'promotion' && (
        <Row className={classNames('header')}>
          <Col xs={8}>Promotion</Col>
          <Col xs={16}>
            <Input.TextArea
              value={renewalOption?.promotionDetails}
              onChange={(e) => {
                if (!renewalOption || renewalOptionIndex === undefined) {
                  throw new Error('"renewalOption" and "renewalOptionIndex" must be specified');
                }

                updateRenewalOption?.(
                  {
                    ...renewalOption,
                    promotionDetails: e.target.value,
                  },
                  renewalOptionIndex,
                );
              }}
            />
          </Col>
        </Row>
      )}

      {showYearOverYearEscalator && (
        <Row className="header" align="top" style={{ height: 'auto' }}>
          <Col xs={14}>Year over year escalator</Col>
          <Col xs={10}>
            <Form.Item name={`options[${renewalOptionIndex}].yearOverYearEscalator`}>
              <Space style={{ width: '100%' }}>
                <InputNumber
                  style={{ width: '100%' }}
                  name={`options[${renewalOptionIndex}].yearOverYearEscalator`}
                />
                <PercentageOutlined />
              </Space>
            </Form.Item>
          </Col>
        </Row>
      )}

      {summaryText && (
        <Row className={classNames('header', 'row-actions', 'last-row')}>
          <Col xs={12}>{summaryText}</Col>
          <Col xs={10}>{formatCurrency(!isNaN(summary) ? summary : 0)}</Col>
        </Row>
      )}

      <AddItemModal
        visible={showMonthlyFees}
        onCancel={() => setShowMonthlyFees(false)}
        onAdd={handleAddMonthlyFee}
      />
    </div>
  );
};

export default TableWithSummary;
