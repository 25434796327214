import EditablePage from '@/components/Formik/EditablePage';
import { useAppContext } from '@/contexts/AppContext';
import type { RenewalDTO } from '@/domain/renewal';
import { RenewalApplicationStatus, RenewalStatus } from '@/domain/renewal';
import '@/pages/prospects/styles.less';
import { LeaseService } from '@/services/leases';
import { RenewalService } from '@/services/renewal';
import { UnitService } from '@/services/units';
import { useFetch } from '@/utils/request';
import { SHORT_DATE_FORMAT } from '@/utils/time';
import { getAddressDescription } from '@/utils/utils';
import { InfoCircleFilled } from '@ant-design/icons';
import { CircularProgress } from '@material-ui/core';
import { formatCurrency } from '@propify/components';
import { Alert, Col, Row, Space, Tooltip } from 'antd';
import moment from 'moment';
import type { FC } from 'react';
import { useMemo } from 'react';
import { Link } from 'react-router-dom';
import PetsTable from './pets/PetsTable';
import RenewalsResidentsTable from './RenewalsResidentsTable';
import RenewalTerms from './RenewalTerms';

interface Props {
  renewal: RenewalDTO;
  isLoading: boolean;
}

const RenewalsModalContent: FC<Props> = ({ renewal, isLoading }) => {
  const { brands } = useAppContext();

  const { data: unit, isValidating: isLoadingUnit } = useFetch(
    {
      fetcher: renewal?.unitId ? UnitService.findById : undefined,
      errorMessage: 'There was an error fetching the renewal',
    },
    renewal?.unitId || 0,
  );

  const { data: lease, isValidating: isLoadingLease } = useFetch(
    {
      fetcher: renewal?.leaseId ? LeaseService.findById : undefined,
      errorMessage: 'There was an error fetching the renewal',
    },
    renewal?.leaseId || 0,
  );

  const { data: renewalAnalytics, isValidating: isLoadingRenewalAnalytics } = useFetch(
    {
      fetcher: renewal?.id ? RenewalService.findAnalyticsById : undefined,
      errorMessage: 'There was an error fetching the renewal',
    },
    renewal?.id || 0,
  );

  const address = unit?.property?.address;

  const renewalBrand = useMemo(
    () => brands.find((b) => b.id === unit?.property?.brandId)?.name || '',
    [lease, brands],
  );

  if (isLoading || isLoadingUnit || isLoadingLease || isLoadingRenewalAnalytics) {
    return <CircularProgress size={10} />;
  }

  return (
    <>
      <EditablePage
        fullWidth
        singleColumn
        sections={[
          {
            title: 'Details',
            type: 'EDITABLE_ATTRIBUTES',
            breakAfter: true,
            attributes: [
              {
                fieldName: 'address',
                colSize: 4,
                label: 'Address',
                type: 'Link',
                readOnly: true,
                render: () => (
                  <Link to={`/properties/${unit?.property?.id}`}>
                    {getAddressDescription(address)}
                  </Link>
                ),
              },
              {
                value: formatCurrency(unit?.marketRent),
                fieldName: 'currentMonthlyRent',
                colSize: 6,
                label: 'Current Monthly Rent',
                type: 'Text',
                readOnly: true,
              },
              {
                fieldName: 'currentOutstandingBalance',
                colSize: 4,
                label: 'Current Outstanding Balance',
                render: () => (
                  <span style={{ color: '#F11E1E' }}>
                    {lease?.resident?.balance ? formatCurrency(lease.resident.balance) : ''}
                  </span>
                ),
                type: 'Text',
                readOnly: true,
              },
              {
                fieldName: 'zestimate',
                colSize: 6,
                label: 'Zestimate',
                render: () => (
                  <span style={{ color: '#1890FF' }}>
                    {formatCurrency(unit?.zillowEstimatedRent)}
                  </span>
                ),
                type: 'Text',
                readOnly: true,
              },
              {
                fieldName: 'workOrder',
                colSize: 4,
                label: 'Work Orders (Last 12 Months)',
                value: renewalAnalytics?.workOrderCount,
                type: 'Text',
                readOnly: true,
              },
              {
                fieldName: 'currentLeaseStartDate',
                colSize: 4,
                label: 'Current Lease Start Date',
                value: lease?.startDate ? moment(lease?.startDate).format(SHORT_DATE_FORMAT) : '',
                type: 'Text',
                readOnly: true,
              },
              {
                fieldName: 'currentLeaseEndDate',
                colSize: 6,
                label: 'Current Lease End Date',
                value: lease?.endDate ? moment(lease.endDate).format(SHORT_DATE_FORMAT) : '',
                type: 'Text',
                readOnly: true,
              },
              {
                fieldName: 'timesInCollectionsProcess',
                colSize: 4,
                label: 'Times in Collections Process',
                value: renewalAnalytics?.collectionCount,
                type: 'Text',
                readOnly: true,
              },
              {
                fieldName: 'brand',
                colSize: 4,
                label: 'Brand',
                value: renewalBrand,
                type: 'Text',
                readOnly: true,
              },
            ],
          },
        ]}
      />
      <Row gutter={24}>
        <Col xs={12}>
          <EditablePage
            fullWidth
            sections={[
              {
                title: '',
                extraTitle: (
                  <Space>
                    <span>Residents</span>
                    <Tooltip title="Adding new residents, removing carryover residents, and managing the screening requirements of carryover residents must be handled through the Renewal Details page.">
                      <InfoCircleFilled style={{ color: '#1890FF' }} />
                    </Tooltip>
                  </Space>
                ),
                type: 'WRAPPER',
                children: (
                  <RenewalsResidentsTable
                    residents={
                      renewal?.applications.filter(
                        (a) =>
                          !a.status ||
                          a.status === RenewalApplicationStatus.ACCEPTED ||
                          a.status === RenewalApplicationStatus.CANCELLED,
                      ) || []
                    }
                  />
                ),
                breakAfter: false,
                attributes: [],
              },
            ]}
          />
        </Col>
        <Col xs={12}>
          <EditablePage
            fullWidth
            singleColumn
            sections={[
              {
                title: 'Pets',
                type: 'WRAPPER',
                children: <PetsTable pets={renewal?.pets || []} />,
                breakAfter: false,
                attributes: [],
              },
            ]}
          />
        </Col>
      </Row>
      <EditablePage
        fullWidth
        singleColumn
        sections={[
          {
            title: 'Renewal Terms',
            type: 'WRAPPER',
            breakAfter: true,
            children: (
              <>
                {renewal?.status === RenewalStatus.COUNTERED && (
                  <Row className="section-container">
                    <Col xs={24} className="section">
                      <Alert
                        message={`Terms Countered: ${renewal.counterOffer}`}
                        type="warning"
                        showIcon
                      />
                    </Col>
                  </Row>
                )}

                {renewal ? <RenewalTerms renewal={renewal} /> : undefined}
              </>
            ),
          },
        ]}
      />
    </>
  );
};

export default RenewalsModalContent;
