import type {
  ColumnComponentType,
  ColumnComponentTypeProps,
} from '@/notmagic/components/EntityTable/types';
import ApplicationApprovals from '@/pages/prospects/unit-applications/ApplicationApprovals';

type RowType = {
  idApproval: boolean;
  creditApproval: boolean;
  criminalApproval: boolean;
  backgroundApproval: boolean;
};

type Props = ColumnComponentTypeProps<RowType>;

const Component: ColumnComponentType<Props> = ({ value }) => {
  // Must set `"value": "$v"` in metadata
  if (!value) {
    return null;
  }

  return (
    <ApplicationApprovals
      id={value.idApproval}
      credit={value.creditApproval}
      criminal={value.criminalApproval}
      background={value.backgroundApproval}
    />
  );
};

export default {
  Component,
  getValueForExport: (row: any) =>
    [
      row.creditApproval ? 'Credit' : null,
      row.criminalApproval ? 'Criminal' : null,
      row.backgroundApproval ? 'Background' : null,
    ]
      .filter((v) => v)
      .join(', '),
};
