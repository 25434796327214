import type { ModalFormProps } from '@/components/Button/formButton';
import '@/components/Files/styles.less';
import type { WorkOrderImageType } from '@/domain/work-order';
import { getErrorMessage, getFileUploadLimit, handleError } from '@/utils/utils';
import { FileOutlined, FolderOpenOutlined } from '@ant-design/icons';
import { FileIcon } from '@propify/components';
import { Button, message, Progress, Select } from 'antd';
import Modal from 'antd/lib/modal/Modal';
import type { AxiosRequestConfig } from 'axios';
import classNames from 'classnames';
import isEmpty from 'lodash/isEmpty';
import type { FC, ReactNode } from 'react';
import { useState } from 'react';
import { useDropzone } from 'react-dropzone';
import '../styles.less';

export enum FilesUploadModalTypes {
  FILES = 'FILES',
  IMAGES = 'IMAGES',
  WORK_ORDER_IMAGES = 'WORK_ORDER_IMAGES',
}

interface Props extends ModalFormProps {
  visible?: boolean;
  title?: string;
  description?: string;
  emptyIcon?: ReactNode;
  type?: FilesUploadModalTypes;
  acceptedFiles?: string[];
  acceptedFilesLabel?: string;
  onCreateFile: (file: File, request: any, config: AxiosRequestConfig) => Promise<unknown>;
  filesLimitAmount?: number;
  multiple?: boolean;
  extraFooter?: ReactNode;
  closeWhenDone?: boolean;
}

export type UploadProgress = {
  file: File;
  fileName: string;
  progress: number;
  uploading: boolean;
  success: boolean;
  promise?: Promise<unknown | void>;
  error?: string;
  uploadedFile?: unknown;
  type?: WorkOrderImageType;
  path?: string;
};

const DEFAULT_DESC_FILES =
  'Drag & drop your files here or click the icon to browse your device for files.';
const DEFAULT_DESC_IMAGES =
  'Drag & drop your images here or click the icon to browse your device for images.';

const FilesUploadModal: FC<Props> = ({
  onSuccess,
  onCancel,
  visible = true,
  type = FilesUploadModalTypes.FILES,
  title = type === FilesUploadModalTypes.FILES ? 'Upload Files' : 'Upload Images',
  description = type === FilesUploadModalTypes.FILES ? DEFAULT_DESC_FILES : DEFAULT_DESC_IMAGES,
  emptyIcon = <FileOutlined className="file-icon" />,
  acceptedFiles = ['.doc', '.docx', '.pdf', '.xls', '.xlsx', '.xlsm', 'image/jpeg', 'image/png'],
  acceptedFilesLabel = 'Files should be .doc, .pdf, .xls, .xlsx, .xlsm, .jpeg or .png',
  onCreateFile,
  filesLimitAmount,
  multiple,
  extraFooter,
  closeWhenDone,
}) => {
  const [state, setState] = useState<'SELECT_FILES' | 'UPLOAD_FILES' | 'DONE'>('SELECT_FILES');
  const [filesToUpload, setFilesToUpload] = useState<UploadProgress[]>([]);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    multiple,
    accept: acceptedFiles,
    disabled: filesLimitAmount ? filesToUpload.length >= filesLimitAmount : undefined,
    onDrop: (accepted, fileRejections) => {
      const files: UploadProgress[] = accepted.map((f) => ({
        ...f,
        fileName: f.name,
        type: undefined,
        file: f,
        progress: 0,
        uploading: false,
        success: true,
        path: URL.createObjectURL(f),
      }));
      setFilesToUpload([...filesToUpload, ...files]);
      const errors = fileRejections.map((obj) => obj.errors.map((err) => err.message)).flat();
      errors.forEach((error) => {
        message.error(error);
      });
    },
  });

  const updateArray = <T,>(array: T[], index: number, setter: (value: T) => T) => {
    const newArray = [...array];
    newArray[index] = setter(newArray[index]);
    return newArray;
  };

  const closeDialog = () => {
    onSuccess();
    setState('SELECT_FILES');
    setFilesToUpload([]);
  };

  const revokeAllURL = () => {
    filesToUpload.forEach((u) => URL.revokeObjectURL(u.path || ''));
  };

  const createFile = (index: number, file: UploadProgress) => {
    const doNotSetType = [FilesUploadModalTypes.FILES, FilesUploadModalTypes.IMAGES].includes(type);

    return onCreateFile(file.file, doNotSetType ? {} : { type: file.type }, {
      onUploadProgress: (progressEvent) => {
        const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
        setFilesToUpload((prev) =>
          updateArray(prev, index, (prog) => ({ ...prog, progress: percentCompleted })),
        );
      },
    })
      .then((data) => {
        setFilesToUpload((prev) =>
          updateArray(prev, index, (prog) => ({
            ...prog,
            uploadedFile: data,
            uploading: false,
            success: true,
            progress: 100,
          })),
        );
      })
      .catch((error) => {
        handleError(error, { displayToast: false });
        setFilesToUpload((prev) =>
          updateArray(prev, index, (prog) => ({
            ...prog,
            error: getErrorMessage(error, 'Error'),
            uploading: false,
            success: false,
          })),
        );
      })
      .finally(() => {
        revokeAllURL();
      });
  };

  const startUpload = () => {
    setState('UPLOAD_FILES');

    const limit = getFileUploadLimit();

    const progress: UploadProgress[] = filesToUpload.map((f, index) => ({
      ...f,
      file: f.file,
      promise: limit(() => createFile(index, f)),
      fileName: f.fileName,
      progress: 0,
      uploading: true,
      success: false,
    }));

    Promise.all(progress.map(({ promise }) => promise)).finally(() => {
      setState('DONE');
      if (closeWhenDone) {
        closeDialog();
      }
    });

    setFilesToUpload(progress);
  };

  const handleSubmit = () => {
    if (state === 'SELECT_FILES') {
      startUpload();
    }

    if (state === 'DONE') {
      closeDialog();
    }
  };

  const handleRetry = () => {
    const limit = getFileUploadLimit();

    const newUploadsArray: UploadProgress[] = filesToUpload.map((u, index) => {
      if (!u.error) {
        return u;
      }

      return {
        ...u,
        error: undefined,
        uploading: true,
        progress: 0,
        success: false,
        promise: limit(() => createFile(index, u)),
      };
    });

    setState('UPLOAD_FILES');
    setFilesToUpload(newUploadsArray);

    Promise.all(newUploadsArray.map(({ promise }) => promise)).finally(() => {
      setState('DONE');
    });
  };

  const getTypeLabel = () => {
    return type === FilesUploadModalTypes.FILES ? 'Files' : 'Images';
  };

  const getFooter = () => {
    switch (state) {
      case 'SELECT_FILES':
        return [
          extraFooter,
          <Button
            data-testid="files-upload-cancel-button"
            key="cancel"
            onClick={() => onCancel?.()}
          >
            Cancel
          </Button>,
          <Button
            data-testid="files-upload-upload-button"
            key="upload"
            type="primary"
            onClick={() => handleSubmit()}
            disabled={
              filesToUpload.length === 0 ||
              (type === FilesUploadModalTypes.WORK_ORDER_IMAGES &&
                filesToUpload.some((f) => !f.type))
            }
          >
            Upload {getTypeLabel()}
          </Button>,
        ];

      case 'UPLOAD_FILES':
        return [
          extraFooter,
          <Button data-testid="files-upload-uploading-button" key="uploading" disabled>
            Uploading
          </Button>,
        ];

      case 'DONE': {
        const hasErrors = filesToUpload.some((u) => !!u.error);

        return [
          extraFooter,
          ...(hasErrors
            ? [
                <Button data-testid="files-upload-retry-button" key="retry" onClick={handleRetry}>
                  Retry
                </Button>,
              ]
            : []),
          <Button
            data-testid="files-upload-done-button"
            key="done"
            type="primary"
            onClick={() => handleSubmit()}
          >
            Done
          </Button>,
        ];
      }
      default:
        return undefined;
    }
  };

  const removeFile = (index: number) => {
    const files = [...filesToUpload];
    files.splice(index, 1);
    setFilesToUpload(files);
  };

  const handleTypeChange = (fileIndex: number) => (newType: WorkOrderImageType) => {
    const updatedFiles = [...filesToUpload];

    updatedFiles[fileIndex] = {
      ...updatedFiles[fileIndex],
      type: newType,
    };

    setFilesToUpload(updatedFiles);
  };

  const bulkTypeUpdate = (_type: WorkOrderImageType) => {
    const filesUpdated = filesToUpload.map((f) => ({
      ...f,
      type: _type,
    }));
    setFilesToUpload(filesUpdated);
  };

  const shouldHideDragAndDrop = !multiple && !isEmpty(filesToUpload);

  return (
    <Modal
      visible={visible}
      title={title}
      className="file-upload-modal"
      closable={state !== 'DONE'}
      width={600}
      onCancel={onCancel}
      afterClose={() => setFilesToUpload([])}
      footer={getFooter()}
      maskClosable={false}
      wrapProps={{
        ['data-testid']: 'files-upload-modal',
      }}
    >
      {state !== 'DONE' && !shouldHideDragAndDrop && (
        <div
          data-testid="files-upload-dropzone"
          className={classNames('dropzone', {
            active: isDragActive,
          })}
          {...getRootProps()}
        >
          <input {...getInputProps()} />

          {isEmpty(filesToUpload) && emptyIcon}

          <div className="description" data-testid="files-upload-description">
            {description}
            <div className="subtitle">{acceptedFilesLabel}</div>
          </div>

          <Button className="browse-container">
            <div className="browse">
              <FolderOpenOutlined /> Browse {getTypeLabel()}
            </div>
          </Button>
        </div>
      )}

      <div className="files-to-upload">
        {type === FilesUploadModalTypes.WORK_ORDER_IMAGES &&
          filesToUpload.length > 0 &&
          state !== 'DONE' && (
            <div data-testid="files-upload-wo-bulk-photo-types" className="bulk-update">
              <Select
                placeholder="Select photo type"
                style={{ width: '100%' }}
                onSelect={bulkTypeUpdate}
              >
                <Select.Option value="BEFORE">Mark all images as Before</Select.Option>
                <Select.Option value="AFTER">Mark all images as After</Select.Option>
              </Select>
            </div>
          )}

        <div className="list">
          {filesToUpload.map((upload, index) => (
            // eslint-disable-next-line react/no-array-index-key
            <div data-testid="files-upload-image-row" className="item" key={index}>
              {type === FilesUploadModalTypes.FILES ? (
                <FileIcon fileName={upload.fileName} />
              ) : (
                <img className="img-dropped" key={upload.path} src={upload.path} />
              )}

              <div className="progressBar">
                {upload.fileName}

                <Progress
                  percent={upload.progress}
                  status={upload.error ? 'exception' : upload.uploading ? 'active' : undefined}
                />

                {state !== 'DONE' && (
                  <div className="remove" onClick={() => !upload.uploading && removeFile(index)}>
                    Remove
                  </div>
                )}
              </div>

              {type === FilesUploadModalTypes.WORK_ORDER_IMAGES && state !== 'DONE' && (
                <Select
                  placeholder="Select photo type"
                  value={upload.type}
                  onChange={handleTypeChange(index)}
                  style={{ width: 125 }}
                >
                  <Select.Option value="BEFORE">Before</Select.Option>
                  <Select.Option value="AFTER">After</Select.Option>
                </Select>
              )}

              {!upload.uploading && !upload.success && (
                <div className="upload-error">
                  <div className="error-message">{upload.error || 'Error'}</div>
                </div>
              )}
            </div>
          ))}
        </div>
      </div>
    </Modal>
  );
};

export default FilesUploadModal;
