import useEffectWithCounter from '@/hooks/useEffectWithCounter';
import { filterSelectOptions } from '@/utils/utils';
import { Select, Tag } from 'antd';
import type { SizeType } from 'antd/lib/config-provider/SizeContext';
import type { FormikProps, FormikValues } from 'formik';
import type { FC } from 'react';
import { useMemo } from 'react';
import type { Filter, SelectFilterType } from './types';

interface SelectFieldProps {
  filter: Filter & SelectFilterType;
  value: any;
  formProps: Pick<FormikProps<FormikValues>, 'values' | 'setFieldValue'> | undefined;
  onChange: (value: any) => void;
  size?: SizeType;
}

const SelectField: FC<SelectFieldProps> = ({ filter, value, onChange, formProps, size }) => {
  useEffectWithCounter(
    (renderCount) => {
      if (renderCount > 0) {
        formProps?.setFieldValue(filter.key, '');
      }
    },
    [filter.filterBy, formProps?.values[filter.filterBy || '']],
  );

  const options = useMemo(
    () =>
      filter.filterBy
        ? filter.options.filter(
            (opt) =>
              filter.filterBy && opt[filter.filterBy] === formProps?.values[filter.filterBy || ''],
          )
        : filter.options.map((o) => ({
            ...o,
            value:
              typeof value === 'number' && typeof o.value !== 'number' ? Number(o.value) : o.value,
          })),
    [filter.filterBy, filter.options, formProps?.values, value],
  );

  const tagRender = (props: any) => {
    const { label, value: tagValue, closable, onClose } = props;

    let tagLabel = label;

    const onPreventMouseDown = (event: any) => {
      event.preventDefault();
      event.stopPropagation();
    };

    if (label === tagValue && filter.optionsForGetValue) {
      tagLabel = filter.optionsForGetValue.find((o) => o.value === tagValue)?.label ?? label;
    }

    return (
      <Tag
        onMouseDown={onPreventMouseDown}
        closable={closable}
        onClose={onClose}
        style={{ marginRight: 3 }}
      >
        {tagLabel}
      </Tag>
    );
  };

  return (
    <Select
      size={size}
      options={options}
      allowClear={!filter.required}
      loading={filter.loading}
      mode={filter.multiple ? 'multiple' : undefined}
      disabled={filter.readOnly}
      value={value}
      onChange={onChange}
      style={{ width: '100%' }}
      filterOption={filterSelectOptions('label')}
      tagRender={tagRender}
      showSearch={filter.showSearch}
    />
  );
};

export default SelectField;
