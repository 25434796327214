import { Button } from 'antd';
import type { FC } from 'react';
import { useState } from 'react';
import NumberRange from '../NumberRange';
import './styles.less';

interface Props {
  value: any;
  onChange: (value: any) => void;
  displayFlex?: boolean;
}

const ActiveNumberRange: FC<Props> = ({ value, onChange, displayFlex }) => {
  const [range, setRange] = useState(value);

  const handleApply = () => {
    if (range) {
      onChange(range);
    }
  };

  return (
    <div className="container-number-range">
      <NumberRange value={range} onChange={setRange} displayFlex={displayFlex} />
      <Button size="large" className="button" onClick={() => handleApply()}>
        Apply
      </Button>
    </div>
  );
};

export default ActiveNumberRange;
