import type { ModalFormProps } from '@/components/Button/formButton';
import PropertyGroupsTable from '@/components/PropertyGroupsTable/PropertyGroupsTable';
import { PropertyGroupService } from '@/services/property-groups';
import { handleError } from '@/utils/utils';
import { Alert, message, Modal } from 'antd';
import { useState, type FC } from 'react';
import styles from './styles.module.less';

export interface Props extends ModalFormProps {
  propertyIds: number[];
}

const AddPropertiesToPropertyGroupsModal: FC<Props> = ({ onSuccess, onCancel, propertyIds }) => {
  const [loading, setLoading] = useState(false);
  const [selection, setSelection] = useState<number[]>([]);

  const handleSubmit = async () => {
    if (selection.length === 0) {
      return;
    }

    try {
      setLoading(true);
      await PropertyGroupService.addPropertiesToPropertyGroups({
        propertyGroupIds: selection,
        propertyIds,
      });
      message.success('Properties added to property group(s)');
      onSuccess();
    } catch (error) {
      handleError(error, {
        toastFallbackMessage: 'There was an error adding the properties to the property group(s)',
      });
    } finally {
      setLoading(false);
    }
  };

  const alertMessage = `Add the ${
    propertyIds.length === 1 ? 'selected property' : propertyIds.length + ' selected properties'
  } to the Property Group(s) selected from the table below.`;

  return (
    <Modal
      title="Add Properties to Property Group(s)"
      visible
      onCancel={onCancel}
      onOk={() => handleSubmit()}
      okText="Add to Group(s)"
      okButtonProps={{ loading, disabled: selection.length === 0 }}
      width="1000px"
      wrapProps={{ 'data-testid': 'modal-add-properties' }}
      className={styles.addPropertyToPropertyGroupModal}
    >
      <Alert message={alertMessage} type="info" showIcon />

      <PropertyGroupsTable
        selection={selection}
        setSelection={setSelection}
        className={styles.propertyGroupsTable}
      />
    </Modal>
  );
};

export default AddPropertiesToPropertyGroupsModal;
