import type { ModalFormProps } from '@/components/Button/formButton';
import OccupantFormModal from '@/components/Occupants/OccupantFormModal';
import type { New } from '@/domain/base';
import type { Occupant } from '@/domain/unit-applications';
import { RenewalService } from '@/services/renewal';
import { handleError } from '@/utils/utils';
import { message } from 'antd';
import type { FC } from 'react';
import { useState } from 'react';

interface Props extends ModalFormProps<[New<Occupant>]> {
  renewalId: number;
}

const RenewalOccupantModal: FC<Props> = ({ renewalId, onSuccess, onCancel }) => {
  const [loading, setLoading] = useState(false);

  const addOccupant = (newOccupant: New<Occupant>) => {
    if (!renewalId) {
      return;
    }
    setLoading(true);
    RenewalService.addOccupant(renewalId, newOccupant)
      .then((occupant) => {
        message.success('Occupant added successfully');
        onSuccess(occupant);
      })
      .catch((error) => {
        handleError(error, {
          toastFallbackMessage: 'There has been an error while adding the occupant',
        });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return <OccupantFormModal onCancel={onCancel} onSuccess={addOccupant} loading={loading} />;
};

export default RenewalOccupantModal;
