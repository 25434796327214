import type {
  FilterComponentType,
  FilterComponentTypeProps,
} from '@/notmagic/components/EntityTable/types';
import type { OptionValues } from '@/notmagic/types';
import { Select } from 'antd';

type Props = FilterComponentTypeProps & {
  multiple?: boolean;
  options?: OptionValues[];
};

const Component: FilterComponentType<Props> = ({
  value,
  onChangeValue,
  options,
  editable,
  multiple,
}) => {
  if (!options) {
    return (
      <div style={{ color: 'red' }}>{`"options" must be configured for this type of filter`}</div>
    );
  }

  if (!editable) {
    return <>{options.find((option) => `${option.value}` === value)!.label}</>;
  }

  return (
    <Select
      onChange={(newValue) => onChangeValue(newValue)}
      style={{ width: '100%' }}
      options={options.map((option) => ({
        value: `${option.value}`,
        label: option.label,
      }))}
      value={value}
      mode={multiple ? 'multiple' : undefined}
    />
  );
};

export default {
  Component,
  jsonataQuery: (value: string, key: string) => `$v.${key} = '${value}'`,
};
