import type { Filter, FilterValuesType } from './types';

// Filters values that are equals to the default value
export const getSubmittableValues = (
  values: FilterValuesType,
  filters: Filter[],
  searchFilterKey: string | undefined,
): FilterValuesType => {
  const result: FilterValuesType = {};
  if (searchFilterKey && values[searchFilterKey]) {
    result[searchFilterKey] = values[searchFilterKey];
  }

  // eslint-disable-next-line no-restricted-syntax, guard-for-in
  for (const key in values) {
    const filter = filters.find((f) => f.key === key);
    if (filter && !filter.hide) {
      result[key] = values[key];
    }
  }

  return result;
};
