import type { New } from '@/domain/base';
import type { Charge } from '@/domain/lease';
import type {
  CreateRenewalDTO,
  RenewalAnalytics,
  RenewalApplication,
  RenewalDTO,
  RenewalOption,
  RenewalSearchParams,
  RenewalSearchResult,
  UpdateRenewalDTO,
} from '@/domain/renewal';
import type { Occupant, Pet, Vehicle } from '@/domain/unit-applications';
import { api } from '@/utils/configuration';
import { crudService, getAxiosFetcher } from '@/utils/request';

export const RenewalService = {
  search: getAxiosFetcher<RenewalSearchResult[], [params?: RenewalSearchParams]>((params) => [
    api.RENEWALS,
    { params },
  ]),

  get: getAxiosFetcher<RenewalDTO, [id: number]>((id) => [`${api.RENEWALS}/${id}`]),

  create: (body: CreateRenewalDTO) => crudService.post<RenewalDTO>(body, api.RENEWALS),

  update: (body: UpdateRenewalDTO) =>
    crudService.put<RenewalDTO>(body, `${api.RENEWALS}/${body.id}`),

  findAnalyticsById: getAxiosFetcher<RenewalAnalytics, [id: number]>((id) => [
    `${api.RENEWALS}/${id}/analytics`,
  ]),

  addVehicle: (renewalId: number, vehicle: New<Vehicle>) =>
    crudService.post<Vehicle>(vehicle, `${api.RENEWALS}/${renewalId}/vehicles`),

  deleteVehicle: (renewalId: number, vehicleId: number) =>
    crudService.delete<void>(`${api.RENEWALS}/${renewalId}/vehicles/${vehicleId}`),

  deleteOccupant: (renewalId: number, occupantId: number) =>
    crudService.delete<void>(`${api.RENEWALS}/${renewalId}/occupants/${occupantId}`),

  addOccupant: (renewalId: number, occupant: New<Occupant>) =>
    crudService.post<Occupant>(occupant, `${api.RENEWALS}/${renewalId}/occupants`),

  addPet: (renewalId: number, pet: New<Pet>) =>
    crudService.post<Pet>(pet, `${api.RENEWALS}/${renewalId}/pets`),

  deletePet: (renewalId: number, petId: number) =>
    crudService.delete<void>(`${api.RENEWALS}/${renewalId}/pets/${petId}`),

  addOption: (renewalId: number, option: New<RenewalOption>) =>
    crudService.post<RenewalOption>(option, `${api.RENEWALS}/${renewalId}/options`),

  addCharge: (renewalId: number, optionId: number, charge: New<Charge>) =>
    crudService.post<Charge>(charge, `${api.RENEWALS}/${renewalId}/options/${optionId}/charges`),

  createApplication: (
    renewalId: number,
    application: Pick<RenewalApplication, 'emailAddress' | 'type'>,
  ) =>
    crudService.post<RenewalApplication>(application, `${api.RENEWALS}/${renewalId}/applications`),

  deleteApplication: (renewalId: number, applicantId: number) =>
    crudService.delete<void>(`${api.RENEWALS}/${renewalId}/applications/${applicantId}`),

  updateApplication: (renewalId: number, applicant: RenewalApplication) =>
    crudService.put<void>(applicant, `${api.RENEWALS}/${renewalId}/applications/${applicant.id}`),
};
