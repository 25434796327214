import type { New } from '@/domain/base';
import type {
  CreateUnitApplicationApplicationRequest,
  Occupant,
  Pet,
  UnitApplication,
  UnitApplicationAnalytics,
  UnitApplicationApplication,
  UnitApplicationCancelRequest,
  UnitApplicationDeniedRequest,
  UnitApplicationSearchParams,
  UnitApplicationSearchResult,
  Vehicle,
} from '@/domain/unit-applications';
import { api } from '@/utils/configuration';
import { crudService, getAxiosFetcher } from '@/utils/request';

export const UnitApplicationsService = {
  search: getAxiosFetcher<UnitApplicationSearchResult[], [UnitApplicationSearchParams?]>(
    (params) => [api.UNIT_APPLICATIONS, { params }],
  ),

  findById: getAxiosFetcher<UnitApplication, [id: number]>((id) => [
    `${api.UNIT_APPLICATIONS}/${id}`,
  ]),

  findAnalyticsById: getAxiosFetcher<UnitApplicationAnalytics, [id: number]>((id) => [
    `${api.UNIT_APPLICATIONS}/${id}/analytics`,
  ]),

  createApplication: (unitApplicationId: number, body: CreateUnitApplicationApplicationRequest) =>
    crudService.post<UnitApplicationApplication>(
      body,
      `${api.UNIT_APPLICATIONS}/${unitApplicationId}/applications`,
    ),

  addOccupant: (unitApplicationId: number, occupant: New<Occupant>) =>
    crudService.post<Occupant>(occupant, `${api.UNIT_APPLICATIONS}/${unitApplicationId}/occupants`),

  updateOccupant: (unitApplicationId: number, occupant: Occupant) =>
    crudService.put<Occupant>(
      occupant,
      `${api.UNIT_APPLICATIONS}/${unitApplicationId}/occupants/${occupant.id}`,
    ),

  deleteOccupant: (unitApplicationId: number, occupantId: number) =>
    crudService.delete<void>(
      `${api.UNIT_APPLICATIONS}/${unitApplicationId}/occupants/${occupantId}`,
    ),

  addPet: (unitApplicationId: number, pet: New<Pet>) =>
    crudService.post<Pet>(pet, `${api.UNIT_APPLICATIONS}/${unitApplicationId}/pets`),

  updatePet: (unitApplicationId: number, pet: Pet) =>
    crudService.put<Pet>(pet, `${api.UNIT_APPLICATIONS}/${unitApplicationId}/pets/${pet.id}`),

  deletePet: (unitApplicationId: number, petId: number) =>
    crudService.delete<void>(`${api.UNIT_APPLICATIONS}/${unitApplicationId}/pets/${petId}`),

  addVehicle: (unitApplicationId: number, vehicle: New<Vehicle>) =>
    crudService.post<Vehicle>(vehicle, `${api.UNIT_APPLICATIONS}/${unitApplicationId}/vehicles`),

  updateVehicle: (unitApplicationId: number, vehicle: Vehicle) =>
    crudService.put<Vehicle>(
      vehicle,
      `${api.UNIT_APPLICATIONS}/${unitApplicationId}/vehicles/${vehicle.id}`,
    ),

  deleteVehicle: (unitApplicationId: number, vehicleId: number) =>
    crudService.delete<void>(`${api.UNIT_APPLICATIONS}/${unitApplicationId}/vehicles/${vehicleId}`),

  deleteProspectApplication: (unitApplicationId: number, prospectApplicationId: number) =>
    crudService.delete<void>(
      `${api.UNIT_APPLICATIONS}/${unitApplicationId}/applications/${prospectApplicationId}`,
    ),

  updateApplication: (unitApplicationId: number, payload: UnitApplicationApplication) =>
    crudService.put<UnitApplicationApplication>(
      payload,
      `${api.UNIT_APPLICATIONS}/${unitApplicationId}/applications/${payload.id}`,
    ),

  update: (body: UnitApplication) =>
    crudService.put<UnitApplication>(body, `${api.UNIT_APPLICATIONS}/${body.id}`),

  deny: (id: number, version: number, body: UnitApplicationDeniedRequest) =>
    crudService.post<UnitApplication>(
      body,
      `${api.UNIT_APPLICATIONS}/${id}/deny?version=${version}`,
    ),

  approve: (id: number, version: number) =>
    crudService.post<UnitApplication>(
      {},
      `${api.UNIT_APPLICATIONS}/${id}/approve?version=${version}`,
    ),

  cancel: (id: number, version: number, body: UnitApplicationCancelRequest) =>
    crudService.post<UnitApplication>(
      body,
      `${api.UNIT_APPLICATIONS}/${id}/cancel?version=${version}`,
    ),
};
