import type { PetPolicy, PetPolicySearchResult } from '@/domain/pet-policy';
import { api } from '@/utils/configuration';
import { crudService, getAxiosFetcher } from '@/utils/request';
import type { Operation } from 'fast-json-patch';

export const PetPolicyService = {
  search: getAxiosFetcher<PetPolicySearchResult[], []>(() => [api.PET_POLICY]),

  create: (policy: Partial<PetPolicy>) => crudService.post<PetPolicy>(policy, api.PET_POLICY),

  patch: (id: number, operations: Operation[], version: number) =>
    crudService.patch<PetPolicy>(operations, `${api.PET_POLICY}/${id}?version=${version}`),
};
