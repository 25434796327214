import EntityForm from '@/notmagic/components/EntityTable/components/EntityForm';
import type { Entity, EntityType } from '@/notmagic/types';
import { crudService } from '@/utils/request';
import { handleError } from '@/utils/utils';
import { Button, Modal } from 'antd';
import type { FC } from 'react';
import { useState } from 'react';

type Props = {
  entityType: EntityType;
  onSuccess: (entity: Entity) => void;
};

const CreateEntityButton: FC<Props> = ({ entityType, onSuccess }) => {
  const [modalVisible, setModalVisible] = useState(false);
  const [isSaving, setIsSaving] = useState(false);

  const onSubmit = (values: EntityType) => {
    setIsSaving(true);
    crudService
      .post<Entity>(values, entityType.endpoint!)
      .then((updatedEntity) => {
        setModalVisible(false);
        onSuccess(updatedEntity);
      })
      .catch((error) => {
        handleError(error, {
          toastFallbackMessage: `There was an error while creating the ${entityType.displayName}`,
        });
      })
      .finally(() => setIsSaving(false));
  };

  return (
    <>
      <Button type="primary" onClick={() => setModalVisible(true)}>
        Create {entityType.displayName}
      </Button>
      {modalVisible && (
        <EntityForm entityType={entityType} onSubmit={onSubmit}>
          {({ children, handleSubmit, dirty, isValid }) => (
            <Modal
              title={`Create ${entityType.displayName}`}
              visible
              onCancel={() => {
                setModalVisible(false);
              }}
              onOk={handleSubmit}
              okText="Save"
              okButtonProps={{
                disabled: !dirty || !isValid,
                loading: isSaving,
              }}
              maskClosable={false}
            >
              {children}
            </Modal>
          )}
        </EntityForm>
      )}
    </>
  );
};

export default CreateEntityButton;
