import { ModalFormButton } from '@/components/Button/formButton';
import type { EditablePageSaveFn } from '@/components/Formik/EditableAttributes/types';
import type { Section } from '@/components/Formik/EditablePage';
import EditablePage from '@/components/Formik/EditablePage';
import type { AdditionalIncome, Application, ApplicationEmployer } from '@/domain/applications';
import { EmploymentStatus } from '@/domain/applications';
import useEnumeration from '@/hooks/useEnumeration';
import { ApplicationsService } from '@/services/applications';
import { handleError } from '@/utils/utils';
import { EditOutlined } from '@ant-design/icons';
import { message, Radio, Space } from 'antd';
import noop from 'lodash/noop';
import type { FC } from 'react';
import AdditionalIncomeEditModal from './AdditionalIncomeEditModal';

type Props = {
  application: Application;
  onUpdateApplication: (application: Application) => void;
};

type UpdateType = 'CURRENT_EMPLOYER' | 'PREVIOUS_EMPLOYER' | 'ADDITIONAL_INCOME';
type SectionConfig = {
  title: string;
  patchPrefix: string;
};

const sectionsConfig: Record<UpdateType, SectionConfig> = {
  CURRENT_EMPLOYER: {
    title: 'Current Occupation',
    patchPrefix: 'currentEmployer',
  },
  PREVIOUS_EMPLOYER: {
    title: 'Previous Occupation',
    patchPrefix: 'previousEmployer',
  },
  ADDITIONAL_INCOME: {
    title: 'Additional Income',
    patchPrefix: 'additionalIncome',
  },
};

const EmploymentInformation: FC<Props> = ({ application, onUpdateApplication }) => {
  const { options: stateOptions } = useEnumeration('state');
  const { options: frequencyOptions } = useEnumeration('application-additional-income-frequency');

  const onSaveApplication: (updateType: UpdateType) => EditablePageSaveFn =
    (updateType) =>
    async ({ values }) => {
      const sectionConfigPatchPrefix = sectionsConfig[updateType].patchPrefix;

      ApplicationsService.update(application.id, {
        ...application,
        [sectionConfigPatchPrefix]: {
          ...application[sectionConfigPatchPrefix],
          ...values,
        },
      })
        .then((updatedApplication) => {
          message.success('Occupation information updated successfully');
          onUpdateApplication(updatedApplication);
        })
        .catch((error) => {
          handleError(error, {
            toastFallbackMessage: 'There was an error updating the application',
          });
        });
    };

  const getOccupationSection = (
    updateType: UpdateType,
    employer?: ApplicationEmployer,
  ): Section => {
    const sectionConfig = sectionsConfig[updateType];
    return {
      title: sectionConfig.title,
      onSave: onSaveApplication(updateType),
      type: 'EDITABLE_ATTRIBUTES',
      attributes: [
        {
          value: employer?.employer,
          fieldName: 'employer',
          path: `${sectionConfig.patchPrefix}.employer`,
          label: 'Employer Name',
          type: 'Text',
          colSize: 4,
        },
        {
          value: employer?.jobTitle,
          fieldName: 'jobTitle',
          path: `${sectionConfig.patchPrefix}.jobTitle`,
          label: 'Job Title',
          type: 'Text',
          colSize: 4,
        },
        {
          value: employer?.monthlyIncome,
          fieldName: 'monthlyIncome',
          path: `${sectionConfig.patchPrefix}.monthlyIncome`,
          label: 'Monthly Income',
          type: 'Number',
          colSize: 4,
        },
        {
          value: employer?.employmentStartDate,
          fieldName: 'employmentStartDate',
          path: `${sectionConfig.patchPrefix}.employmentStartDate`,
          label: 'Employed Since',
          type: 'Date',
          colSize: 4,
        },
        {
          value: employer?.supervisorName,
          fieldName: 'supervisorName',
          path: `${sectionConfig.patchPrefix}.supervisorName`,
          label: 'Supervisor Name',
          type: 'Text',
          colSize: 4,
        },
        {
          value: employer?.phoneNumber,
          fieldName: 'phoneNumber',
          path: `${sectionConfig.patchPrefix}.phoneNumber`,
          label: 'Employer Phone',
          type: 'Text',
          colSize: 4,
        },
        {
          value: employer?.addr1,
          fieldName: 'addr1',
          path: `${sectionConfig.patchPrefix}.addr1`,
          label: 'Employer Address 1',
          type: 'Text',
          colSize: 4,
        },
        {
          value: employer?.addr2,
          fieldName: 'addr2',
          path: `${sectionConfig.patchPrefix}.addr2`,
          label: 'Employer Address 2',
          type: 'Text',
          colSize: 4,
        },
        {
          value: employer?.city,
          fieldName: 'city',
          path: `${sectionConfig.patchPrefix}.city`,
          label: 'City',
          type: 'Text',
          colSize: 4,
        },
        {
          value: employer?.state,
          fieldName: 'state',
          path: `${sectionConfig.patchPrefix}.state`,
          label: 'State',
          type: 'Select',
          options: stateOptions,
          colSize: 4,
        },
        {
          value: employer?.postalCode,
          fieldName: 'postalCode',
          path: `${sectionConfig.patchPrefix}.postalCode`,
          label: 'Zip Code',
          type: 'Text',
          colSize: 4,
        },
        {
          value: employer?.country,
          fieldName: 'country',
          path: `${sectionConfig.patchPrefix}.country`,
          label: 'Country',
          type: 'Text',
          colSize: 4,
        },
      ],
    };
  };

  const getAdditionalIncomeSection = (
    updateType: UpdateType,
    additionalIncome?: AdditionalIncome,
  ): Section => {
    return {
      title: sectionsConfig[updateType].title,
      onSave: onSaveApplication(updateType),
      type: 'EDITABLE_ATTRIBUTES',
      extraTitle: (
        <ModalFormButton
          buttonText="Edit"
          buttonType="primary"
          icon={<EditOutlined />}
          onSuccess={(updatedApp) => onUpdateApplication(updatedApp)}
          key="edit"
        >
          {(props) => (
            <AdditionalIncomeEditModal
              {...props}
              additionalIncome={additionalIncome}
              application={application}
            />
          )}
        </ModalFormButton>
      ),
      attributes: [
        {
          value: additionalIncome?.source,
          fieldName: 'source',
          path: 'additionalIncome.source',
          label: 'Additional Source',
          type: 'Text',
          colSize: 4,
          readOnly: true,
        },
        {
          value: additionalIncome?.frequency,
          fieldName: 'frequency',
          path: 'additionalIncome.frequency',
          label: 'Frequency',
          type: 'Select',
          options: frequencyOptions,
          colSize: 4,
          readOnly: true,
        },
        {
          value: additionalIncome?.amount,
          fieldName: 'amount',
          path: 'additionalIncome.amount',
          label: 'Additional Amount',
          type: 'Text',
          colSize: 4,
          readOnly: true,
        },
      ],
    };
  };

  const EmploymentInformationStatus = () => {
    return (
      <Radio.Group value={application.employmentStatus}>
        <Space direction="vertical">
          <Radio value={EmploymentStatus.EMPLOYED}>I am currently employed</Radio>
          <Radio value={EmploymentStatus.UNEMPLOYED}>I am currently unemployed</Radio>
          <Radio value={EmploymentStatus.RETIRED}>I am a retired person</Radio>
          <Radio value={EmploymentStatus.STUDENT}>I am student</Radio>
        </Space>
      </Radio.Group>
    );
  };

  return (
    <div className="applicant-details">
      <div className="employment-information-status">
        <EmploymentInformationStatus />
      </div>
      <EditablePage
        singleColumn
        onSave={noop}
        sections={[
          getOccupationSection('CURRENT_EMPLOYER', application.currentEmployer),
          getOccupationSection('PREVIOUS_EMPLOYER', application.previousEmployer),
          getAdditionalIncomeSection('ADDITIONAL_INCOME', application.additionalIncome),
        ]}
      />
    </div>
  );
};

export default EmploymentInformation;
