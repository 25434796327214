import type { Column } from '@/@propify-components';
import { Table } from '@/@propify-components';
import SearchField from '@/components/FilterBarWithURLQuery/SearchField';
import type { PropertyGroupSearchResult } from '@/domain/property-groups';
import { usePropertyGroups } from '@/hooks/services/usePropertyGroups';
import { useMemo, useState, type FC } from 'react';
import styles from './styles.module.less';

interface Props {
  selection: number[];
  setSelection: (selection: number[]) => void;
  className?: string;
}

const columns: Column<PropertyGroupSearchResult>[] = [
  {
    title: 'Property Group Name',
    key: 'name',
    width: 390,
  },
  {
    title: 'Description',
    key: 'description',
    width: 390,
  },
  {
    title: 'Properties',
    key: 'propertyCount',
    width: 130,
  },
];

const PropertyGroupsTable: FC<Props> = ({ selection, setSelection, className }) => {
  const [pageSize, setPageSize] = useState(50);
  const [keyword, setKeyword] = useState<string>();

  const { data: propertyGroups = [], isValidating: loading } = usePropertyGroups();

  const filteredPropertyGroups = useMemo(
    () =>
      propertyGroups.filter((propertyGroup) => {
        if (!keyword) {
          return true;
        }

        return [propertyGroup.name, propertyGroup.description].some((field) =>
          field?.toLowerCase().includes(keyword.toLowerCase()),
        );
      }),
    [propertyGroups, keyword],
  );

  return (
    <div className={styles.propertyGroupsTable}>
      <SearchField
        placeholder="Search"
        onSearch={(term) => setKeyword(term?.trim() || '')}
        defaultValue=""
        style={{ width: 250 }}
      />

      <Table
        key="property-groups-table"
        pageSize={pageSize}
        onPageSizeChange={setPageSize}
        loading={loading}
        data={filteredPropertyGroups}
        rowKeyExtractor={(row) => row.id}
        columns={columns}
        selection={selection}
        onSelectionChange={setSelection}
        className={`propify-scrollable-table ${className}`}
        responsive={false}
        style={{ maxWidth: '100%' }}
      />
    </div>
  );
};

export default PropertyGroupsTable;
