import EmptyState from '@/components/EmptyState/EmptyState';
import SectionSplashScreen from '@/components/PageLoading/SplashSection';
import type { EntityType } from '@/domain/entity-type';
import type { Note } from '@/domain/note';
import { Card, Row } from 'antd';
import isEmpty from 'lodash/isEmpty';
import type { CSSProperties, FC } from 'react';
import { useEffect, useMemo, useState } from 'react';
import '../../styles.less';
import NotesDescription from './NotesDescription';
import NotesHeader from './NotesHeader';

type Props = {
  notes: Note[];
  entityId: number;
  entityType: EntityType;
  refetchNotes: () => void;
  loadingNotes: boolean;
  style?: CSSProperties;
};

const Notes: FC<Props> = ({ notes, entityId, entityType, refetchNotes, loadingNotes, style }) => {
  const [selectedNote, setSelectedNote] = useState<Note>();
  const [onNoteClick, setOnNoteClick] = useState(false);

  useEffect(() => {
    setSelectedNote(undefined);
  }, [notes]);

  const notesMapped = useMemo(
    () =>
      notes?.map((note) => (
        <NotesDescription
          key={note.id}
          note={note}
          setSelectedNote={setSelectedNote}
          setOnNoteClick={setOnNoteClick}
          onNoteClick={onNoteClick}
        />
      )),
    [notes, onNoteClick],
  );

  return (
    <Card
      className="notes-container"
      title={
        <NotesHeader
          entityId={entityId}
          entityType={entityType}
          refetchNotes={refetchNotes}
          selectedNote={selectedNote}
          onNoteClick={onNoteClick}
        />
      }
      bordered={false}
      style={style}
    >
      {notesMapped}

      {loadingNotes && (
        <Row className="loading-notes">
          <SectionSplashScreen />
        </Row>
      )}
      {isEmpty(notes) && !loadingNotes && <EmptyState description="There are no Notes yet." />}
    </Card>
  );
};

export default Notes;
