import { useAppContext } from '@/contexts/AppContext';
import type { FC } from 'react';

type Props = {
  loginId?: number;
};

const UserId: FC<Props> = ({ loginId }) => {
  const { users } = useAppContext();

  if (!loginId || !users) {
    return <>N/A</>;
  }

  const user = users.find((u) => u.loginId === loginId);
  if (!user) {
    return <>N/A</>;
  }
  return <>{user.displayName}</>;
};

export default UserId;
