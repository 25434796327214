// use localStorage to store the authority info, which might be sent from server in actual project.
import type { Permission } from '@/domain/permissions';
import type { User } from '@/domain/user';
import { redirectToAuthClient } from '@/utils/redirectToAuthClient';
import memoize from 'lodash/memoize';
import uniq from 'lodash/uniq';

export function getAuthority(str?: string): string | string[] {
  const authorityString = typeof str === 'undefined' ? localStorage.getItem('roles') : str;
  let authority;
  try {
    if (authorityString) {
      authority = JSON.parse(authorityString);
    }
  } catch (e) {
    authority = authorityString;
  }
  if (typeof authority === 'string') {
    return [authority];
  }
  return authority;
}

const triggerLocalStorageEvent = (key: string) => {
  const ev = new StorageEvent('storage', { key });
  window.dispatchEvent(ev);
};

export function setAccessToken(token: string): void {
  localStorage.setItem('access_token', token);
  triggerLocalStorageEvent('access_token');
}

export function setRefreshToken(token: string): void {
  localStorage.setItem('refresh_token', token);
}

export const getAccessToken = () => localStorage.getItem('access_token');

export const getRefreshToken = () => localStorage.getItem('refresh_token');

export const setPermissions = (permissions: Permission[]) => {
  localStorage.setItem('permissions', JSON.stringify(permissions));
  triggerLocalStorageEvent('permissions');
};

export const getPermissions = memoize(
  () => {
    const value = localStorage.getItem('permissions');

    if (!value) {
      return undefined;
    }

    return JSON.parse(value) as Permission[];
  },
  () => localStorage.getItem('permissions'),
);

export const getPermissionsFromUser = (user: User) => {
  const permissions = user.roles.map((role) => role.type.permissions).flat();
  return uniq(permissions);
};

export const loggedIn = () => {
  const token = getRefreshToken();
  return !!token;
};

export const isUserInRole = (user: Partial<User>, checkRole: string) =>
  (user?.roles || []).some((role) => role.type.name === checkRole);

export function logout(): void {
  localStorage.removeItem('access_token');
  localStorage.removeItem('refresh_token');
  localStorage.removeItem('permissions');
  localStorage.removeItem('roles');
  localStorage.removeItem('user_profile');
  redirectToAuthClient();
}

export const hasPermissions = (key: Permission): boolean | undefined =>
  getPermissions()?.includes(key);
