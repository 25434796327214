import UnitsAutoComplete from '@/pages/unit/components/autocomplete';
import { DatePicker, Input, Select } from 'antd';
import type { FormikProps, FormikValues } from 'formik';
import type { FC } from 'react';
import ActiveFilterBoolean from '../ActiveFilterBoolean';
import ActiveFilterDateRange from '../ActiveFilterDateRange';
import ActiveFilterSelect from '../ActiveFilterSelect';
import ActiveFilterText from '../ActiveFilterText';
import ActiveNumberRange from '../ActiveNumberRange';
import { DateRangePicker } from '../DateRangePicker';
import NumberRange from '../NumberRange';
import ProspectsAutocomplete from '../ProspectsAutocomplete';
import SelectField from './SelectField';
import './styles.less';
import type { Filter } from './types';
import { FilterType } from './types';

interface Props {
  filter: Filter;
  value: any;
  formProps: Pick<FormikProps<FormikValues>, 'values' | 'setFieldValue'> | undefined;
  onChange: (value: any) => void;
  hiddenLabel?: boolean;
  isActiveField?: boolean;
}

const FilterDrawerField: FC<Props> = ({
  filter,
  value,
  onChange,
  hiddenLabel,
  formProps,
  isActiveField,
}) => {
  const getInputByType = () => {
    switch (filter.type) {
      case FilterType.BOOLEAN_OPTIONAL:
        if (isActiveField) {
          return <ActiveFilterBoolean onChange={onChange} />;
        }

        return (
          <Select
            size="large"
            options={[
              { label: filter.labelYes ?? 'Yes', value: 'true' },
              { label: filter.labelNo ?? 'No', value: 'false' },
            ]}
            allowClear
            value={value !== undefined ? `${value}` : undefined}
            onChange={(newValue: string): void => onChange(newValue === 'true')}
            style={{ width: '100%' }}
          />
        );

      case FilterType.DATE:
        return <DatePicker value={value} onChange={onChange} style={{ width: '100%' }} />;

      case FilterType.DATE_RANGE:
        if (isActiveField) {
          return <ActiveFilterDateRange onChange={onChange} value={value} />;
        }

        return <DateRangePicker value={value} onChange={onChange} style={{ width: '100%' }} />;

      case FilterType.PRICE_RANGE:
        if (isActiveField) {
          return <ActiveNumberRange value={value} onChange={onChange} displayFlex />;
        }
        return <NumberRange value={value} onChange={onChange} displayFlex />;

      case FilterType.SELECT:
        if (isActiveField) {
          return (
            <ActiveFilterSelect
              filter={filter}
              onChange={onChange}
              value={value}
              formProps={formProps}
            />
          );
        }
        return (
          <SelectField
            filter={filter}
            onChange={onChange}
            value={value}
            formProps={formProps}
            size="large"
          />
        );
      case FilterType.FREE_SELECT:
        return (
          <Select
            allowClear={!filter.required}
            mode="tags"
            value={value}
            onChange={onChange}
            style={{ width: '100%' }}
          />
        );

      case FilterType.TEXT:
        if (isActiveField) {
          return <ActiveFilterText value={value} onChange={onChange} />;
        }
        return <Input size="large" value={value} onChange={(ev) => onChange(ev.target.value)} />;

      case FilterType.NUMBER:
        return (
          <Input
            type="number"
            size="large"
            value={value}
            onChange={(ev) => onChange(ev.target.value)}
          />
        );

      case FilterType.UNIT:
        return <UnitsAutoComplete value={value} onChange={onChange} style={{ width: '100%' }} />;

      case FilterType.PROSPECT:
        return (
          <ProspectsAutocomplete value={value} onChange={onChange} style={{ width: '100%' }} />
        );

      default:
        return <div>Unknown Filter Type</div>;
    }
  };

  return (
    <div className="filter-drawer-field">
      {!hiddenLabel && <div className="label">{filter.label}</div>}
      <div className="input">{getInputByType()}</div>
    </div>
  );
};

export default FilterDrawerField;
