import type { PropertySearchParams } from '@/services/property';
import { PropertyService } from '@/services/property';
import { useFetch } from '@/utils/request';

export const useProperties = (filters: PropertySearchParams | undefined) => {
  return useFetch(
    {
      fetcher: filters ? PropertyService.search : undefined,
      errorMessage: 'There was a error getting the properties',
    },
    filters,
  );
};
