import type { Enum } from '@/domain/base';
import type { Duration } from 'moment';
import moment from 'moment';

export const SHORT_TIME_FORMAT = 'h:mm A';
export const SHORT_DATE_FORMAT = 'MM/DD/YYYY';
export const SHORT_DATE_TIME_FORMAT = `${SHORT_DATE_FORMAT} ${SHORT_TIME_FORMAT}`;
export const SHORT_DATE_TIME_FORMAT_WITH_TIMEZONE = `${SHORT_DATE_FORMAT} ${SHORT_TIME_FORMAT} (z)`;
export const DATE_TIME_FORMAT = `${SHORT_DATE_FORMAT} [at] ${SHORT_TIME_FORMAT}`;
export const ISO_DATE_FORMAT = 'YYYY-MM-DD';
export const MONTH_DAY_FORMAT = 'MM/DD';
export const FULL_TIME_FORMAT = 'HH:mm:ss';
export const LOCAL_DATE_TIME_FORMAT = `${ISO_DATE_FORMAT}T${FULL_TIME_FORMAT}`;
export const SHORT_MONTH_FORMAT = 'MMM';
export const SHORT_DAY_FORMAT = 'DD';
export const SHORT_MONTH_DAY_FORMAT = `${SHORT_MONTH_FORMAT} ${SHORT_DAY_FORMAT}`;
export const LOCAL_DATE_TIME_Z_FORMAT = `${ISO_DATE_FORMAT}T${FULL_TIME_FORMAT}Z`;
export const MONTH_YEAR_DATE_FORMAT = 'MM/YYYY';

export const timezones: Enum[] = [
  {
    displayName: 'America/Adak',
    value: 'America/Adak',
  },
  {
    displayName: 'America/Anchorage',
    value: 'America/Anchorage',
  },
  {
    displayName: 'America/Chicago',
    value: 'America/Chicago',
  },
  {
    displayName: 'America/Denver',
    value: 'America/Denver',
  },
  {
    displayName: 'America/Los_Angeles',
    value: 'America/Los_Angeles',
  },
  {
    displayName: 'America/New_York',
    value: 'America/New_York',
  },
  {
    displayName: 'America/Phoenix',
    value: 'America/Phoenix',
  },
  {
    displayName: 'America/Puerto_Rico',
    value: 'America/Puerto_Rico',
  },
  {
    displayName: 'America/Virgin',
    value: 'America/Virgin',
  },
  {
    displayName: 'Pacific/Honolulu',
    value: 'Pacific/Honolulu',
  },
  {
    displayName: 'Pacific/Saipan',
    value: 'Pacific/Saipan',
  },
  {
    displayName: 'US/Alaska',
    value: 'US/Alaska',
  },
  {
    displayName: 'US/Aleutian',
    value: 'US/Aleutian',
  },
  {
    displayName: 'US/Arizona',
    value: 'US/Arizona',
  },
  {
    displayName: 'US/Central',
    value: 'US/Central',
  },
  {
    displayName: 'US/East-Indiana',
    value: 'US/East-Indiana',
  },
  {
    displayName: 'US/Eastern',
    value: 'US/Eastern',
  },
  {
    displayName: 'US/Hawaii',
    value: 'US/Hawaii',
  },
  {
    displayName: 'US/Indiana-Starke',
    value: 'US/Indiana-Starke',
  },
  {
    displayName: 'US/Michigan',
    value: 'US/Michigan',
  },
  {
    displayName: 'US/Mountain',
    value: 'US/Mountain',
  },
  {
    displayName: 'US/Pacific',
    value: 'US/Pacific',
  },
  {
    displayName: 'US/Pacific-New',
    value: 'US/Pacific-New',
  },
  {
    displayName: 'US/Samoa',
    value: 'US/Samoa',
  },
];

export type DayOfWeek =
  | 'MONDAY'
  | 'TUESDAY'
  | 'WEDNESDAY'
  | 'THURSDAY'
  | 'FRIDAY'
  | 'SATURDAY'
  | 'SUNDAY';

export const days = [
  {
    value: 'MONDAY',
    label: 'Monday',
  },
  {
    value: 'TUESDAY',
    label: 'Tuesday',
  },
  {
    value: 'WEDNESDAY',
    label: 'Wednesday',
  },
  {
    value: 'THURSDAY',
    label: 'Thursday',
  },
  {
    value: 'FRIDAY',
    label: 'Friday',
  },
  {
    value: 'SATURDAY',
    label: 'Saturday',
  },
  {
    value: 'SUNDAY',
    label: 'Sunday',
  },
];

export const formatSeconds = (seconds: number) => {
  const sec = Math.floor(seconds % 60);
  const min = Math.floor(seconds / 60);
  return `${min}:${sec.toString().padStart(2, '0')}`;
};

export const secondsToHoursMinutes = (seconds: number | undefined) => {
  if (seconds === undefined) {
    return undefined;
  }

  const hours = (seconds < 0 ? Math.ceil : Math.floor)(seconds / 3600);
  const minutes = Math.floor(Math.abs((seconds % 3600) / 60));
  return `${hours}:${minutes.toString().padStart(2, '0')}`;
};

export const formatDuration = (duration: Duration): string => {
  const years = duration.years() > 0 ? `${duration.years()} y, ` : '';
  const months = duration.years() > 0 || duration.months() > 0 ? `${duration.months()} m, ` : '';
  const day = `${duration.days()} d`;

  return `${years}${months}${day}`;
};

export const formatAge = (date: string) => {
  const duration = moment.duration(moment().diff(date));
  return formatDuration(duration);
};

export const daysRemaining = (date: string) => {
  const eventDate = moment(date);
  const todayDate = moment();
  return eventDate.diff(todayDate, 'days');
};
