import type { Props as BulkUpdateProps } from '@/components/BulkUpdate/BulkUpdateButton';
import BulkUpdate from '@/components/BulkUpdate/BulkUpdateButton';
import { Ellipsis } from '@/utils/icons';
import { RouteContext } from '@ant-design/pro-layout';
import { Button, Popover } from 'antd';
import type { ButtonProps } from 'antd/lib/button';
import classNames from 'classnames';
import type { CSSProperties, FC, ReactNode } from 'react';
import { useContext } from 'react';
import type { Props as ModalFormButtonProps } from '../Button/formButton';
import { ModalFormButton } from '../Button/formButton';
import SectionSplashScreen from '../PageLoading/SplashSection';
import type { Props as ExportButtonProps } from '../TableUtils/ExportButton';
import ExportButton from '../TableUtils/ExportButton';
import styles from './styles.module.less';

interface BaseAction {
  key: string;
  type: string;
  primary?: boolean;
}

export interface BulkUpdateAction extends BaseAction, BulkUpdateProps {
  type: 'bulkUpdate';
}

export interface ExportButtonAction extends BaseAction, ExportButtonProps {
  type: 'exportButton';
}

export interface ModalFormButtonAction<R extends any[] = []>
  extends BaseAction,
    ModalFormButtonProps<R> {
  type: 'modalFormButton';
}

export interface ButtonAction extends BaseAction, Omit<ButtonProps, 'type'> {
  type: 'button';
}

export interface PrimaryAction extends Omit<ButtonAction, 'type'> {
  type: 'primary';
}

export interface WrapperAction extends Omit<ButtonAction, 'type'> {
  type: 'wrapper';
}

export type Action =
  | BulkUpdateAction
  | ExportButtonAction
  | ModalFormButtonAction<any[]>
  | ButtonAction
  | WrapperAction
  | PrimaryAction;

export interface Props {
  title?: string | ReactNode;
  className?: string;
  fullHeight?: boolean;
  scrollX?: boolean;
  extra?: ReactNode[];
  content?: ReactNode | undefined;
  actions?: Action[];
  loading?: boolean;
  pageContentStyle?: CSSProperties;
  pageHeaderClassName?: string;
  ignoreActionsOrder?: boolean;
  children: ReactNode;
}

const Page: FC<Props> = (props) => {
  const {
    title,
    children,
    actions = [],
    className,
    scrollX = false,
    fullHeight = false,
    extra,
    content,
    loading = false,
    pageContentStyle = {},
    pageHeaderClassName = '',
    ignoreActionsOrder = false,
  } = props || {};
  const { title: titleRoute } = useContext(RouteContext) || {};

  const getActions = (by: string) => {
    const currentActions = actions;
    const checkIfPrimaryItems = currentActions.some((action) => action?.primary);
    switch (by) {
      case 'popover':
        if (checkIfPrimaryItems || ignoreActionsOrder) {
          return currentActions.filter((action) => !action?.primary);
        }
        return currentActions.slice(1);
      default:
      case 'nav':
        if (checkIfPrimaryItems || ignoreActionsOrder) {
          return currentActions.filter((action) => action?.primary);
        }
        return currentActions[0] ? [currentActions[0]] : [];
    }
  };

  const renderActionsButtons = (actionRows: Action[]) => {
    return actionRows.map((action) => {
      const key = action.key || action.type;
      switch (action.type) {
        case 'wrapper':
          return <>{action.children}</>;
        case 'bulkUpdate':
          return <BulkUpdate {...action} key={key} />;
        case 'exportButton':
          return <ExportButton {...action} key={key} />;
        case 'modalFormButton':
          const { children: c, ...others } = action;
          return (
            <ModalFormButton {...others} key={key}>
              {c}
            </ModalFormButton>
          );
        case 'button':
          return <Button {...action} type="default" key={key} />;
        case 'primary':
          return <Button {...action} type="primary" key={key} />;
      }
    });
  };

  const renderRightBar = () => {
    if (!actions) {
      return null;
    }

    return (
      <>
        {renderActionsButtons(getActions('nav')!)}
        {getActions('popover')!.length > 0 && (
          <Popover
            content={
              <div className={styles.popoverActions}>
                {renderActionsButtons(getActions('popover')!)}
              </div>
            }
            placement="bottomLeft"
            trigger="focus"
          >
            <Button icon={<Ellipsis />} className={styles.navHandler} />
          </Popover>
        )}
      </>
    );
  };

  return (
    <>
      <div
        data-testid="propify-page-header"
        className={`${styles.pageHeader} propify-page-header ${pageHeaderClassName}`}
      >
        <div className={styles.pageHeaderLeft}>
          {content ? content : <h1>{title || titleRoute}</h1>}
        </div>
        <div className={styles.pageHeaderRight}>
          <div className={styles.pageHeaderRightExtra}>{extra}</div>
          {renderRightBar()}
        </div>
      </div>
      <div
        className={classNames(styles.pageContent, className, {
          [styles.fullHeight]: fullHeight,
          [styles.noHorizontalScroll]: !scrollX,
        })}
        style={pageContentStyle}
      >
        {loading ? <SectionSplashScreen /> : children}
      </div>
    </>
  );
};

export default Page;
