import type { FilterDefinition } from '../types';
import BOOLEAN from './BOOLEAN';
import DATE_RANGE from './DATE_RANGE';
import ENTITY from './ENTITY';
import ENUM from './ENUM';
import FREE_SELECT from './FREE_SELECT';
import NUMBER from './NUMBER';
import NUMBER_RANGE from './NUMBER_RANGE';
import OPTIONS from './OPTIONS';
import TEXT from './TEXT';

const filterTypes: Record<string, FilterDefinition> = {
  BOOLEAN,
  DATE_RANGE,
  ENTITY,
  ENUM,
  FREE_SELECT,
  NUMBER,
  NUMBER_RANGE,
  OPTIONS,
  TEXT,
};

export default filterTypes;
