import type { Column, TableRef } from '@/@propify-components';
import { isColumnVisible, Table } from '@/@propify-components';
import type { PropertySearchResult } from '@/domain/property';
import type { Settings } from '@/services/settings';
import type { RefObject } from 'react';
import { useState } from 'react';

interface Props {
  properties: PropertySearchResult[];
  loadingProperties: boolean;
  tableRef?: RefObject<TableRef<PropertySearchResult, number>>;
  tableSettings: Settings;
  selection?: number[];
  setSelection: (selection: number[]) => void;
  columns: Column<PropertySearchResult>[];
  className?: string;
}

const PropertiesTable = ({
  properties,
  tableRef,
  loadingProperties,
  tableSettings,
  selection,
  setSelection,
  columns,
  className,
}: Props) => {
  const [pageSize, setPageSize] = useState(50);

  return (
    <Table
      key={`properties-table-${pageSize}`}
      ref={tableRef}
      pageSize={pageSize}
      onPageSizeChange={setPageSize}
      loading={loadingProperties}
      data={properties}
      rowKeyExtractor={(row) => row.id}
      columns={columns.filter(isColumnVisible)}
      selection={selection}
      onSelectionChange={setSelection}
      settings={tableSettings}
      className={`propify-page-table propify-scrollable-table ${className}`}
      responsive={false}
    />
  );
};

export default PropertiesTable;
