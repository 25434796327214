import type { Enum, EnumerationName } from '@/domain/base';
import { EnumerationService } from '@/services/enumeration';
import { useFetch } from '@/utils/request';
import { enumToOptions } from '@/utils/utils';
import { useMemo } from 'react';

const useEnumeration = <T = string, R extends Enum<T> = Enum<T>>(
  name: EnumerationName | string,
  filter?: (enumItem: R) => boolean,
) => {
  const { data, isValidating, mutate, error } = useFetch(
    {
      fetcher: EnumerationService.getEnums.of<R[]>(),
      errorMessage: 'There was an error while loading the enumeration',
      fallbackData: [] as R[],
    },
    name,
  );

  const filtered = useMemo(
    () => (filter ? (data || []).filter(filter) : data || []),
    [data, filter],
  );

  const options = useMemo(() => enumToOptions<T, R>(filtered), [filtered]);

  const displayNamesMap: Map<T, string> = useMemo(
    () => new Map(filtered.map((e) => [e.value, e.displayName])),
    [filtered],
  );

  return {
    data: filtered,
    isValidating,
    mutate,
    error,
    options,
    displayNamesMap,
  };
};

export default useEnumeration;
