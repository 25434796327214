import type {
  Unit,
  UnitBulkUpdateBody,
  UnitPricingSearchResult,
  UnitSearchParams,
  UnitSearchResult,
  UnitUpdateRequest,
  YardiMismatch,
} from '@/domain/unit';
import { api } from '@/utils/configuration';
import { crudService, getAxiosFetcher } from '@/utils/request';
import type { Operation } from 'fast-json-patch';
import pickBy from 'lodash/pickBy';
import LookupService from './lookup';

export const UnitService = {
  findById: getAxiosFetcher<Unit, [id: number]>((id) => [`${api.UNIT}/${id}`]),

  search: getAxiosFetcher<UnitSearchResult[], [UnitSearchParams?]>((params) => [
    api.UNIT,
    { params },
  ]),

  yardiMismatches: getAxiosFetcher<YardiMismatch[], [id: number]>((id) => [
    `${api.UNIT}/${id}/yardi-mismatches`,
  ]),

  searchForPricing: getAxiosFetcher<UnitPricingSearchResult[], [UnitSearchParams?]>(
    (params = {}) => [`${api.UNIT}/pricing`, { params }],
  ),

  create: (unit: Partial<Unit>) => crudService.post<Unit>(unit, api.UNIT),

  update: (unit: UnitUpdateRequest) => crudService.put<Unit>(unit, `${api.UNIT}/${unit.id}`),

  bulkUpdate: (payload: UnitBulkUpdateBody) => crudService.put<void>(payload, `${api.UNIT}`),

  patch: (id: number, operations: Operation[], version: number) =>
    crudService.patch<Unit>(operations, `${api.UNIT}/${id}?version=${version}`),

  lookup: (keyword?: string, id?: number) =>
    LookupService.units(pickBy({ q: keyword, id }, (x) => x)),

  completeReview: (unitId: number) =>
    crudService.post<void>({}, `${api.UNIT}/${unitId}/pricing/review-complete`),
};
