import type { Column } from '@/@propify-components/Table';
import Table, { dateColumn } from '@/@propify-components/Table';
import AddPropertiesToPropertyGroupModal from '@/components/AddPropertiesToPropertyGroupModal/AddPropertiesToPropertyGroupModal';
import { ModalFormButton } from '@/components/Button/formButton';
import FilterBar from '@/components/FilterBarWithURLQuery';
import type { Filter, FilterValuesType } from '@/components/FilterBarWithURLQuery/types';
import { FilterType } from '@/components/FilterBarWithURLQuery/types';
import EditablePage from '@/components/Formik/EditablePage';
import HeaderInfoBlocks from '@/components/headers/InfoBlocks';
import NotesTable from '@/components/Notes';
import CreateNoteForm from '@/components/Notes/create';
import Page from '@/components/Page';
import SectionSplashScreen from '@/components/PageLoading/SplashSection';
import { enumColumn } from '@/components/TableUtils';
import { brandColumn } from '@/components/TableUtils/BrandColumn';
import UserId from '@/components/UserId';
import { useAppContext } from '@/contexts/AppContext';
import { EntityType } from '@/domain/entity-type';
import { NoteType } from '@/domain/note';
import type { Property } from '@/domain/property';
import { useProperties } from '@/hooks/services/useProperties';
import { usePropertyGroup, usePropertyGroupAnalytics } from '@/hooks/services/usePropertyGroup';
import useEnumeration from '@/hooks/useEnumeration';
import type { PropertySearchParams } from '@/services/property';
import { PropertyGroupService } from '@/services/property-groups';
import { SHORT_DATE_TIME_FORMAT } from '@/utils/time';
import { getAddressDescription, handleError } from '@/utils/utils';
import { DeleteOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import { Button, Col, message, Modal, Popconfirm, Row } from 'antd';
import moment from 'moment';
import type { FC } from 'react';
import { useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router';
import { Link } from 'react-router-dom';

const PropertyGroupDetails: FC = () => {
  const { id: idParam } = useParams();
  const [filters, setFilters] = useState<PropertySearchParams>();
  const [notesRefreshIndex, setNotesRefreshIndex] = useState(0);
  const navigate = useNavigate();
  const [loadingPropertyGroupDelete, setLoadingPropertyGroupDelete] = useState(false);
  const {
    data: propertyGroup,
    isValidating: loadingPropertyGroup,
    mutate: refetchPropertyGroup,
  } = usePropertyGroup(+idParam!);
  const { options: listingStatusOptions } = useEnumeration('listing-status');
  const { options: unitStatus } = useEnumeration('unit-status');
  const { brands } = useAppContext();
  const {
    data: propertyGroupAnalytics,
    isValidating: loadingPropertyGroupAnalytics,
    mutate: refetchPropertyGroupAnalytics,
  } = usePropertyGroupAnalytics(propertyGroup?.id);

  const {
    data: properties = [],
    isValidating: loading,
    mutate: refetchProperties,
  } = useProperties(
    propertyGroup
      ? {
          propertyGroupId: propertyGroup.id,
          ...filters,
        }
      : undefined,
  );

  const onRemovePropertyGroup = () => {
    if (!propertyGroup?.id) {
      return;
    }
    setLoadingPropertyGroupDelete(true);
    PropertyGroupService.delete(propertyGroup)
      .then(() => {
        message.success('Property group removed successfully');
        navigate(`/properties/property-groups`);
      })
      .catch((error) => {
        handleError(error, {
          toastFallbackMessage: 'There was an error trying to remove the file',
        });
      })
      .finally(() => {
        setLoadingPropertyGroupDelete(false);
      });
  };

  const onRemovePropertyFromGroup = (addedProperty: Property) => {
    if (!propertyGroup?.id) {
      return;
    }
    PropertyGroupService.removeProperty(propertyGroup?.id, addedProperty)
      .then(() => {
        message.success('Property removed from group successfully');
        refetchPropertyGroupAnalytics();
        refetchProperties();
      })
      .catch((error) => {
        handleError(error, {
          toastFallbackMessage:
            'There was an error trying to remove the property from the property group',
        });
      });
  };

  const columns: Column<Property>[] = [
    {
      title: 'Address',
      key: 'address',
      render: (row) =>
        row?.id ? (
          <Link to={`/properties/${row?.id}`}>{getAddressDescription(row.address)}</Link>
        ) : null,
      width: 150,
    },
    brandColumn(
      { title: 'Brand', key: 'brandId' },
      { getValue: (row) => brands.find((b) => b.id === row.brandId)?.name },
    ),
    enumColumn(
      { title: 'Listing Status', key: 'unitListingStatus', width: 150 },
      { enumName: 'listing-status' },
    ),
    enumColumn(
      { title: 'Occupancy Status', key: 'unitStatus', width: 150 },
      { enumName: 'unit-status' },
    ),
    dateColumn({ title: 'Acquisition', key: 'acquisitionDate' }),
    {
      title: 'Actions',
      key: 'actions',
      width: 110,
      render: (row) => (
        <>
          <Popconfirm
            title="Are you sure?"
            okText="Yes"
            cancelText="No"
            onConfirm={() => onRemovePropertyFromGroup(row)}
            okButtonProps={{
              // @ts-ignore
              ['data-testid']: `confirm-button-${row.id}`,
            }}
          >
            <Button
              type="link"
              danger
              icon={<DeleteOutlined />}
              data-testid={`property-table-action-button-${row.id}`}
            />
          </Popconfirm>
        </>
      ),
    },
  ];

  const allFilters = useMemo<Filter[]>(
    (): Filter[] => [
      {
        key: 'unitStatus',
        label: 'Occupancy Status',
        type: FilterType.SELECT,
        options: unitStatus,
        multiple: true,
      },
      {
        key: 'unitListingStatus',
        label: 'Listing Status',
        type: FilterType.SELECT,
        options: listingStatusOptions,
        multiple: true,
      },
      {
        type: FilterType.SELECT,
        key: 'brandId',
        label: 'Brand',
        options: brands?.map((brand) => ({
          label: brand.name,
          value: `${brand.id}`,
        })),
      },
    ],
    [brands, unitStatus, listingStatusOptions],
  );

  const handleKeywordsChange = (keyword: string) => {
    setFilters((prevFilters) => ({ ...prevFilters, keyword }));
  };

  const handleFiltersApplied = (values: FilterValuesType) => {
    const newFilter: PropertySearchParams = {
      ...(values.keyword ? { keyword: values.keyword as string } : {}),
      ...(values.brandId ? { brandId: values.brandId as string } : {}),
      ...(values.unitListingStatus ? { unitListingStatus: values.unitListingStatus } : {}),
      ...(values.unitStatus ? { unitStatus: values.unitStatus } : {}),
    };

    setFilters(newFilter);
  };

  if (loadingPropertyGroup || !propertyGroup?.id || loadingPropertyGroupAnalytics) {
    return (
      <div data-testid="section-splash-screen">
        <SectionSplashScreen />
      </div>
    );
  }

  return (
    <Page
      fullHeight
      scrollX={false}
      title={propertyGroup?.name}
      data-testid="property-group-details"
      actions={[
        {
          type: 'button',
          key: 'removePropertyGroup',
          children: 'Delete Property Group',
          className: 'danger',
          onClick: () =>
            Modal.confirm({
              icon: <ExclamationCircleOutlined />,
              content: <h3>Are you sure you want to remove this property group?</h3>,
              onOk() {
                onRemovePropertyGroup();
              },
            }),
          loading: loadingPropertyGroupDelete,
        },
      ]}
    >
      <HeaderInfoBlocks
        items={[
          {
            label: `ID`,
            value: propertyGroup?.id.toString(),
          },
          {
            label: `Properties`,
            value: propertyGroupAnalytics?.propertyCount?.toString(),
          },
          {
            label: `Occupied Properties`,
            value: propertyGroupAnalytics?.occupiedPropertyCount?.toString(),
          },
          {
            label: `Vacant Properties`,
            value: propertyGroupAnalytics?.vacantPropertyCount?.toString(),
          },
        ]}
      />
      <Row gutter={24}>
        <Col span={12}>
          <EditablePage
            singleColumn
            fullWidth
            withSpacing={true}
            sections={[
              {
                title: 'Property Group Details',
                type: 'EDITABLE_ATTRIBUTES',
                attributes: [
                  {
                    fieldName: 'name',
                    label: 'Name',
                    value: propertyGroup.name,
                    type: 'Text',
                    readOnly: true,
                  },
                  {
                    fieldName: 'description',
                    label: 'Description',
                    value: propertyGroup?.description,
                    type: 'Text',
                    readOnly: true,
                  },
                  {
                    fieldName: 'createdBy',
                    label: 'Created By',
                    value: propertyGroup.createLoginId && (
                      <UserId loginId={propertyGroup.createLoginId} />
                    ),
                    type: 'Text',
                    readOnly: true,
                    borderless: true,
                  },
                  {
                    fieldName: 'createTime',
                    label: 'Date Created',
                    value:
                      propertyGroup.createTime &&
                      moment(propertyGroup.createTime).format(SHORT_DATE_TIME_FORMAT),
                    type: 'Text',
                    readOnly: true,
                    borderless: true,
                  },
                ],
              },
            ]}
          />
        </Col>
        <Col span={12}>
          <EditablePage
            singleColumn
            fullWidth
            withSpacing={true}
            sections={[
              {
                title: 'Notes',
                type: 'WRAPPER',
                extraTitle: [
                  <ModalFormButton
                    buttonText="Add Note"
                    buttonType="link"
                    key="addNote"
                    onSuccess={() => setNotesRefreshIndex(notesRefreshIndex + 1)}
                  >
                    {(props) => (
                      <CreateNoteForm
                        {...props}
                        entityId={propertyGroup?.id}
                        entityType={EntityType.PROPERTY_GROUP}
                        defaultType={NoteType.GENERAL}
                        withContext
                      />
                    )}
                  </ModalFormButton>,
                ],
                children: (
                  <div style={{ marginTop: 20 }}>
                    <NotesTable
                      refreshIndex={notesRefreshIndex}
                      entityType={EntityType.PROPERTY_GROUP}
                      entityId={propertyGroup?.id}
                    />
                  </div>
                ),
                breakAfter: true,
                attributes: [],
              },
            ]}
          />
        </Col>
        <Col span={24}>
          <EditablePage
            singleColumn
            fullWidth
            withSpacing={true}
            sections={[
              {
                title: 'Properties',
                type: 'WRAPPER',
                extraTitle: [
                  <ModalFormButton
                    buttonText="Add Properties"
                    buttonType="primary"
                    key="addProperties"
                    onSuccess={() => {
                      refetchPropertyGroup();
                      refetchPropertyGroupAnalytics();
                      refetchProperties();
                    }}
                  >
                    {(props) => (
                      <AddPropertiesToPropertyGroupModal {...props} propertyGroup={propertyGroup} />
                    )}
                  </ModalFormButton>,
                ],
                children: (
                  <>
                    <FilterBar
                      filters={allFilters}
                      onFilterApplied={handleFiltersApplied}
                      onSearch={handleKeywordsChange}
                      myFilterKey="propertyGroup"
                      searchFilterKey="keyword"
                      values={filters as FilterValuesType}
                    />
                    <Table
                      data-testid="properties-table"
                      rowKeyExtractor={(f) => f.id}
                      loading={loading}
                      pageSize={5}
                      data={properties}
                      columns={columns}
                    />
                  </>
                ),
                breakAfter: false,
                attributes: [],
              },
            ]}
          />
        </Col>
      </Row>
    </Page>
  );
};

export default PropertyGroupDetails;
