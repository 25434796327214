import type { EntityAssociation } from '@/domain/base';

/* eslint sort-keys: "error" */
export const api = {
  ACQUISITIONS_PORTFOLIO: '/acquisitions/portfolios',
  ACQUISITIONS_PROPERTY: '/acquisitions/properties',
  AMENITY_TYPES: '/amenity-types',
  APPLICATIONS: '/applications',
  AUDIT: '/audits',
  AUTH: '/auth',
  BIDS: '/bids',
  BRANDS: '/brands',
  BULK_OPERATIONS: '/bulk-operations',
  COMMUNICATIONS: '/communications',
  COMMUNITIES: '/communities',
  COMPANY: '/companies',
  CONTACTS: '/contacts',
  CUSTOM_FIELDS: '/custom-fields',
  DASHBOARDS: '/dashboards',
  DISPOSITIONS: '/dispositions',
  DISPUTES: '/disputes',
  DOCUMENT_TEMPLATES: '/document-templates',
  EMAILS: '/emails',
  EMAIL_TEMPLATES: '/email-templates',
  ENUMERATION: '/enumerations',
  FILE: '/files',
  FIXED_ASSETS: '/fixed-assets',
  FIXED_ASSET_CATEGORIES: '/fixed-asset-categories',
  FIXED_ASSET_TYPES: '/fixed-asset-types',
  FORMS: '/forms',
  FUNDS: '/funds',
  GROUP_EMAILS: '/email-groups',
  HOLDING_COMPANIES: '/holding-companies',
  INSURANCE_POLICIES: '/insurance-policies',
  JOB: '/jobs',
  LEAD: '/leads',
  LEASE: '/leases',
  LEASE_DOCUMENT_TEMPLATES: '/lease-document-templates',
  LEASINGS: '/leasings',
  LESSEES: '/lessees',
  LISTING_PROCESS: '/listings',
  LOGINS: '/logins',
  LOOKUP: '/lookup',
  MOVE_INS: '/residents/move-ins',
  MOVE_OUTS: '/residents/move-outs',
  NOTE: '/notes',
  NOTIFICATION_CONFIG: '/notification-config',
  OPENING_HOURS: '/opening-hours',
  OPENING_HOURS_OVERRIDE: '/opening-hours-overrides',
  ORGANIZATIONS: '/organizations',
  ORGANIZATION_DASHBOARD: '/organization-dashboards',
  PARTIES: '/parties',
  PAYMENTS: '/payments',
  PAYMENT_METHODS: '/payment-methods',
  PAYMENT_SETTINGS: '/payment-settings',
  PERSON: '/persons',
  PERSON_EVENT: '/person-events',
  PET_POLICY: '/pet-policies',
  PHONE: '/phones',
  PHONE_CALLS: '/phone-calls',
  PROMOTIONS: '/promotions',
  PROPERTIES: '/properties',
  PROPERTY_GROUPS: '/property-groups',
  PROPERTY_MANAGER_SERVICES: '/property-manager-services',
  PROPERTY_MANAGER_VENDORS: '/property-manager-vendors',
  PROSPECT: '/prospects',
  RECURRING_SERVICE_PROPERTIES: '/recurring-service-properties',
  RECURRING_SERVICE_PROVIDER: '/recurring-service-providers',
  RECURRING_WORK_ORDER_TEMPLATES: '/recurring-work-order-templates',
  REGIONS: '/regions',
  RENEWALS: '/renewals',
  REPORT: '/reports',
  RESIDENT: '/residents',
  RESIDENT_COLLECTIONS: '/residents/collections',
  SAVED_FILTERS: '/saved-filters',
  SCHEDULED_CHARGE: '/scheduled-charges',
  SCOPE_ITEMS: '/scope-items',
  SERVICES: '/services',
  SERVICE_QUESTIONS: '/service-questions',
  SERVICE_REGIONS: '/service-regions',
  SHOWINGS: '/showings',
  SMART_HOME_CODES: '/smart-home/lock-codes',
  SMART_HOME_SYSTEM: '/smart-home/systems',
  SMART_HOME_THERMOSTAT_PROFILES: '/smart-home/thermostat-profiles',
  SMS_MESSAGES: '/sms-messages',
  STATE_ASSUMPTIONS: '/acquisitions/state-assumptions',
  SUB_SKILLS: '/subskills',
  SURVEYS: '/surveys',
  SURVEY_TARGETS: '/survey-targets',
  TASKS: '/tasks',
  TASK_TEMPLATE: '/task-templates',
  TICKETS: '/tickets',
  TICKET_GROUPS: '/ticket-groups',
  TICKET_GROUPS_EMAILS: '/email-groups',
  TICKET_REASONS: '/ticket-reasons',
  TICKET_TEMPLATES: '/ticket-templates',
  TICKET_TRIGGERS: '/ticket-triggers',
  TOUCH: '/touches',
  TURN_REHABS: '/turn-rehabs',
  UI_OPTIONS: '/ui-options',
  UNDERWRITING_PROFILE: '/acquisitions/underwriting-profiles',
  UNIT: '/units',
  UNIT_APPLICATIONS: '/unit-applications',
  UNIT_ONBOARDINGS: '/unit-onboardings',
  USER: '/users',
  USER_GROUPS: '/user-groups',
  USER_ROLE_TYPES: '/user-role-types',
  UTILITIES: '/utilities',
  VENDOR: '/vendors',
  VENTURES: '/ventures',
  VISITS: '/visits',
  WARRANTIES: '/warranties',
  WORK_ORDERS: '/work-orders',
};

export const serializeAssociation = (association?: EntityAssociation): string | undefined =>
  association ? `${association.entityType}:${association.entityId}` : undefined;
