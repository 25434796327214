import { Button } from 'antd';
import type { ButtonProps } from 'antd/lib/button';
import type { FC } from 'react';
import styles from './styles.module.less';

const EntityTableButton: FC<ButtonProps> = (props) => {
  return <Button {...props} className={`${styles.entityTableButton} ${props.className || ''}`} />;
};

export default EntityTableButton;
