import type {
  CreateDocumentTemplateResponse,
  DocumentTemplate,
  DocumentTemplatePreviewRequest,
  DocumentTemplateSearchParams,
  DocumentTemplateSearchResult,
  DropboxSignConfiguration,
} from '@/domain/document-templates';
import { api } from '@/utils/configuration';
import { crudService, getAxiosFetcher } from '@/utils/request';

export const DocumentTemplatesService = {
  search: getAxiosFetcher<DocumentTemplateSearchResult[], [DocumentTemplateSearchParams?]>(
    (params) => [api.DOCUMENT_TEMPLATES, { params }],
  ),

  config: getAxiosFetcher<DropboxSignConfiguration, []>(() => [`${api.DOCUMENT_TEMPLATES}/config`]),

  create: (file: Blob, request: Partial<DocumentTemplate>) => {
    const formData = new FormData();
    formData.append('file', file);
    formData.append('request', new Blob([JSON.stringify(request)], { type: 'application/json' }));

    return crudService.post<CreateDocumentTemplateResponse>(formData, api.DOCUMENT_TEMPLATES);
  },

  delete: (documentTemplateId: number) =>
    crudService.delete<void>(`${api.DOCUMENT_TEMPLATES}/${documentTemplateId}`),

  getPreviewUrl: getAxiosFetcher<string, [DocumentTemplatePreviewRequest]>((params) => [
    `${api.DOCUMENT_TEMPLATES}/preview-url`,
    { params },
  ]),

  update: (documentTemplate: Partial<DocumentTemplate>) =>
    crudService.put<DocumentTemplate>(
      documentTemplate,
      `${api.DOCUMENT_TEMPLATES}/${documentTemplate.id}`,
    ),

  getEditUrl: getAxiosFetcher<string, [documentTemplateId: number]>((documentTemplateId) => [
    `${api.DOCUMENT_TEMPLATES}/${documentTemplateId}/edit-url`,
  ]),
};
