import { useAppContext } from '@/contexts/AppContext';
import type { Note } from '@/domain/note';
import type { UserLookup } from '@/domain/user';
import { DATE_TIME_FORMAT } from '@/utils/time';
import moment from 'moment';
import type { FC } from 'react';
import { useMemo } from 'react';

interface Props {
  note: Note;
}

const NoteAudit: FC<Props> = ({ note }) => {
  const { users } = useAppContext();

  const usersMapByLoginId = useMemo<Record<number, UserLookup>>(
    () =>
      users.reduce((map, user) => {
        return {
          ...map,
          [user.loginId]: user,
        };
      }, {}),
    [users],
  );

  return (
    <div style={{ color: '#989898' }}>
      <span style={{ color: '#1890ff' }}>
        {usersMapByLoginId[note.createLoginId]?.displayName ?? ''}
      </span>{' '}
      on {moment(note.createTime).format(DATE_TIME_FORMAT)}
    </div>
  );
};

export default NoteAudit;
