import { AntDatePicker, AntInput, AntTextArea } from '@/components/Formik/CreateAntField';
import EntitySelect from '@/notmagic/components/EntityTable/components/EntitySelect';
import EnumSelect from '@/notmagic/components/EntityTable/components/EnumSelect';
import { useEntityTableContext } from '@/notmagic/Context';
import type { EntityType } from '@/notmagic/types';
import { SHORT_DATE_FORMAT } from '@/utils/time';
import { InfoCircleOutlined } from '@ant-design/icons';
import { Checkbox, Form, Tooltip } from 'antd';
import type { FieldProps, FormikProps, FormikValues } from 'formik';
import { Field, Formik } from 'formik';
import type { FC, ReactNode } from 'react';
import { useMemo, useRef } from 'react';
import * as Yup from 'yup';
import styles from './styles.module.less';

type Props = {
  entityType: EntityType;
  values?: any;
  onSubmit: (entity: EntityType) => void;
  children: (props: any) => ReactNode;
};

const EntityForm: FC<Props> = ({ entityType, values, children, onSubmit }) => {
  const { entityTypes } = useEntityTableContext();

  const validationSchema = useMemo(
    () =>
      Yup.lazy(() =>
        Yup.object(
          entityType.attributes
            ?.filter((attribute) => !attribute.readOnly || !values)
            .reduce((map, attribute) => {
              let validation;
              switch (attribute.type) {
                case 'number':
                  validation = Yup.number();
                  break;
                case 'string':
                  validation = Yup.string();
                  break;
                case 'boolean':
                  validation = Yup.boolean();
                  break;
                default:
                  validation = Yup.mixed();
              }

              return attribute.required
                ? {
                    ...map,
                    [attribute.name]: validation.required(),
                  }
                : map;
            }, {}),
        ),
      ),
    [entityType, values],
  );

  const initialValues = useMemo<any>(
    () => ({
      ...entityType.attributes?.reduce(
        (map, attribute) =>
          attribute.required
            ? {
                ...map,
                [attribute.name]: attribute.type === 'boolean' ? false : '',
              }
            : map,
        {},
      ),
      ...values,
    }),
    [entityType, values],
  );

  const formikRef = useRef<FormikProps<FormikValues>>(null);

  return (
    <Formik
      innerRef={formikRef}
      validationSchema={validationSchema}
      initialValues={initialValues}
      onSubmit={(newValues) => onSubmit(newValues)}
    >
      {({ handleSubmit, submitCount, dirty, isValid }) => {
        const grandChildren = (
          <Form layout="vertical" className={styles.magicEntityForm}>
            {entityType.attributes
              ?.filter((attribute) => !attribute.readOnly || !values)
              .map((attribute) => {
                if (['string', 'number'].includes(attribute.type)) {
                  return (
                    <Field
                      key={attribute.name}
                      component={attribute.multiline ? AntTextArea : AntInput}
                      label={
                        <>
                          {attribute.displayName}{' '}
                          {attribute.required && <span className={styles.requiredField}>*</span>}
                        </>
                      }
                      placeholder={attribute.placeholder}
                      name={attribute.name}
                      type={attribute.type === 'number' ? 'number' : 'text'}
                      hasFeedback
                      submitCount={submitCount}
                    />
                  );
                }

                if (attribute.type === 'entity') {
                  return (
                    <Field key={attribute.name} name={attribute.name}>
                      {({ field, form }: FieldProps) => (
                        <Form.Item label={attribute.displayName}>
                          <EntitySelect
                            entityType={entityTypes[attribute.configuration.entityType]}
                            filter={attribute.configuration?.filterOptions}
                            value={field.value}
                            onChangeValue={(value) => form.setFieldValue(field.name, value)}
                            allowClear={!attribute.required}
                          />
                        </Form.Item>
                      )}
                    </Field>
                  );
                }

                if (attribute.type === 'enum') {
                  return (
                    <Field key={attribute.name} name={attribute.name}>
                      {({ field, form }: FieldProps) => (
                        <Form.Item key={attribute.name} label={attribute.displayName}>
                          <EnumSelect
                            enumeration={attribute.configuration.enumeration}
                            value={field.value}
                            onChangeValue={(value) => form.setFieldValue(field.name, value)}
                            multiple={attribute.configuration.multiple}
                            allowClear
                          />
                        </Form.Item>
                      )}
                    </Field>
                  );
                }

                if (attribute.type === 'boolean') {
                  return (
                    <Field key={attribute.name} name={attribute.name}>
                      {({ field, form }: FieldProps) => (
                        <Form.Item key={attribute.name}>
                          <Checkbox
                            checked={field.value}
                            onChange={(e) => form.setFieldValue(field.name, e.target.checked)}
                          >
                            {attribute.displayName}
                            {attribute.help && (
                              <Tooltip placement="right" title={attribute.help}>
                                <InfoCircleOutlined style={{ height: '5px', color: '#1890ff' }} />
                              </Tooltip>
                            )}
                          </Checkbox>
                        </Form.Item>
                      )}
                    </Field>
                  );
                }

                if (attribute.type === 'local_date') {
                  return (
                    <Field
                      key={attribute.name}
                      component={AntDatePicker}
                      label={attribute.displayName}
                      name={attribute.name}
                      placeholder={attribute.placeholder}
                      hasFeedback
                      submitCount={submitCount}
                      format={SHORT_DATE_FORMAT}
                      style={{ width: '100%' }}
                    />
                  );
                }

                // TODO: Support more types as they become necessary
                return null;
              })}
          </Form>
        );

        return children({ children: grandChildren, dirty, isValid, handleSubmit });
      }}
    </Formik>
  );
};

export default EntityForm;
