import { isEntitySchemaValid, logInvalidEntitySchema } from '@/notmagic/schema';
import type { EntityType } from '@/notmagic/types';
import APPLICATION from './APPLICATION.json';
import BID from './BID.json';
import BRAND from './BRAND.json';
import COLLECTION from './COLLECTION.json';
import COMMUNITY from './COMMUNITY.json';
import COMPANY from './COMPANY.json';
import DISPOSITION from './DISPOSITION.json';
import DOCUMENT_TEMPLATE from './DOCUMENT_TEMPLATE.json';
import JOB from './JOB.json';
import LEAD from './LEAD.json';
import LEASING from './LEASING.json';
import LISTING_PROCESS from './LISTING_PROCESS.json';
import MOVE_IN from './MOVE_IN.json';
import MOVE_OUT from './MOVE_OUT.json';
import PERSON from './PERSON.json';
import PROMOTION from './PROMOTION.json';
import PROPERTY from './PROPERTY.json';
import PROPERTY_GROUP from './PROPERTY_GROUP.json';
import PROSPECT from './PROSPECT.json';
import RECURRING_SERVICE_PROVIDER from './RECURRING_SERVICE_PROVIDER.json';
import RENEWAL from './RENEWAL.json';
import RESIDENT from './RESIDENT.json';
import SERVICE from './SERVICE.json';
import SERVICE_CATEGORY from './SERVICE_CATEGORY.json';
import SERVICE_SUBCATEGORY from './SERVICE_SUBCATEGORY.json';
import SHOWING from './SHOWING.json';
import SMART_HOME_SYSTEM from './SMART_HOME_SYSTEM.json';
import SMART_HOME_THERMOSTAT_PROFILE from './SMART_HOME_THERMOSTAT_PROFILE.json';
import TASK from './TASK.json';
import TURN_REHAB from './TURN_REHAB.json';
import UNIT from './UNIT.json';
import UNIT_APPLICATION from './UNIT_APPLICATION.json';
import UNIT_ONBOARDING from './UNIT_ONBOARDING.json';
import USER_GROUP from './USER_GROUP.json';
import USER_LOOKUP from './USER_LOOKUP.json';
import USER_LOOKUP_BY_LOGIN_ID from './USER_LOOKUP_BY_LOGIN_ID.json';
import UTILITY from './UTILITY.json';
import VENDOR from './VENDOR.json';
import VENDOR_LOOKUP from './VENDOR_LOOKUP.json';
import VENTURE from './VENTURE.json';
import WORK_ORDER from './WORK_ORDER.json';

const validateAndExport = (entities: Record<string, object>): Record<string, EntityType> => {
  const exports: Record<string, EntityType> = {};

  Object.entries(entities).forEach(([name, entityType]) => {
    const [valid, errors] = isEntitySchemaValid(entityType as EntityType);

    if (valid) {
      exports[name] = entityType as EntityType;
    } else {
      logInvalidEntitySchema(name, errors);
    }
  });

  return exports;
};

export default validateAndExport({
  APPLICATION,
  BID,
  BRAND,
  COLLECTION,
  COMMUNITY,
  COMPANY,
  DISPOSITION,
  DOCUMENT_TEMPLATE,
  JOB,
  LEAD,
  LEASING,
  MOVE_IN,
  MOVE_OUT,
  PERSON,
  PROMOTION,
  PROPERTY,
  PROPERTY_GROUP,
  PROSPECT,
  RECURRING_SERVICE_PROVIDER,
  RENEWAL,
  RESIDENT,
  SERVICE,
  SERVICE_CATEGORY,
  SERVICE_SUBCATEGORY,
  SHOWING,
  SMART_HOME_SYSTEM,
  SMART_HOME_THERMOSTAT_PROFILE,
  TASK,
  TURN_REHAB,
  UNIT,
  UNIT_APPLICATION,
  UNIT_ONBOARDING,
  USER_GROUP,
  USER_LOOKUP,
  USER_LOOKUP_BY_LOGIN_ID,
  UTILITY,
  VENDOR,
  VENDOR_LOOKUP,
  VENTURE,
  WORK_ORDER,
  LISTING_PROCESS,
});
