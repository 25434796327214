import classNames from 'classnames';
import type { CSSProperties } from 'react';
import React, { Fragment } from 'react';
import type { SortEndHandler } from 'react-sortable-hoc';
import { SortableContainer, SortableElement } from 'react-sortable-hoc';
import type { ClassNameMap } from '../../shared/utils/material';
import { useMediaQuery, useTheme } from '../../shared/utils/material';
import type { DataId, RowKeyExtractorType } from '../Table.types';

interface Props<T, K extends DataId> {
  'data-testid'?: string;
  loading: boolean;
  data: T[];
  renderHeader?: () => React.ReactElement;
  renderFooter?: () => React.ReactElement;
  renderLoading?: () => React.ReactElement;
  renderItem: (item: T, itemIndex: number) => React.ReactElement;
  itemKeyExtractor?: RowKeyExtractorType<T, K>;
  widthFitContent?: boolean;
  style?: CSSProperties;
  onSortEnd?: SortEndHandler;
  responsive?: boolean;
  styles: ClassNameMap<string>;
}

const SortableItem = SortableElement((props: React.PropsWithoutRef<any>) => (
  <Fragment {...props} />
));

const SortableCont = SortableContainer((props: React.PropsWithoutRef<any>) => (
  <Fragment {...props} />
));

const Item = ({
  item,
  itemIndex,
  renderItem,
}: {
  item: any;
  itemIndex: number;
  renderItem: (item: any, itemIndex: number) => React.ReactElement;
}) => {
  return <SortableItem index={itemIndex}>{renderItem(item, itemIndex)}</SortableItem>;
};

const List = <T, K extends DataId>(props: Props<T, K>) => {
  const {
    data,
    renderItem: renderParentItem,
    itemKeyExtractor,
    renderHeader: renderParentHeader,
    onSortEnd,
    widthFitContent,
    style,
    loading,
    renderLoading,
    renderFooter,
    responsive = true,
    styles,
  } = props;
  const theme = useTheme();
  const isMobileMediaQuery = useMediaQuery(theme.breakpoints.down('sm'));

  let isMobile = isMobileMediaQuery;
  if (!responsive) {
    isMobile = false;
  }

  const getItemKey = (item: any, index: number) =>
    itemKeyExtractor?.(item, index) ?? `item_${item?.id ?? index}`;

  const renderHeader = () => renderParentHeader && renderParentHeader();

  const renderItem = (item: T, itemIndex: number) => {
    const itemKey = getItemKey(item, itemIndex);
    return <Item renderItem={renderParentItem} item={item} itemIndex={itemIndex} key={itemKey} />;
  };

  return (
    <SortableCont
      axis="y"
      lockAxis="y"
      lockToContainerEdges
      helperClass="draggedRow"
      useDragHandle
      onSortEnd={onSortEnd}
    >
      <div
        className={classNames(styles.tableContainer, {
          [styles.tableContainerFitContent]: widthFitContent,
          [styles.hideScrolls]: loading,
          [styles.tableContainerMobile]: isMobile,
        })}
        style={style}
        data-testid={props['data-testid']}
        role="table"
        data-test="tableList"
      >
        {loading && typeof renderLoading === 'function' && renderLoading()}
        <div
          style={{ display: 'flex', flex: 1, width: '100%', overflowX: 'auto' }}
          className="propify-table-scrollable-element"
        >
          <div style={{ flex: 1 }}>
            <div>
              {renderHeader()}
              <div className={styles.table}>{data && data.map(renderItem)}</div>
            </div>
            {renderFooter && renderFooter()}
          </div>
        </div>
      </div>
    </SortableCont>
  );
};
export default List;
