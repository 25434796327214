import type { ModalFormProps } from '@/components/Button/formButton';
import { AntCheckbox, AntInput, AntSelect } from '@/components/Formik/CreateAntField';
import type { New, Option } from '@/domain/base';
import type { Pet } from '@/domain/unit-applications';
import { PetSex, PetType } from '@/domain/unit-applications';
import useEnumeration from '@/hooks/useEnumeration';
import { RenewalService } from '@/services/renewal';
import type { FormikSubmitFn } from '@/utils/types';
import { handleError } from '@/utils/utils';
import { Form, Modal } from 'antd';
import { Field, Formik } from 'formik';
import type { FC } from 'react';
import { useState } from 'react';
import * as Yup from 'yup';

interface Props extends ModalFormProps<[Pet]> {
  renewalId: number;
}

const validationSchema = Yup.object().shape({
  name: Yup.string().trim().nullable().required('This field is required.'),
  age: Yup.number().nullable().required('This field is required.').min(1),
  weight: Yup.number().nullable().required('This field is required.').min(1),
  color: Yup.string().trim().nullable().required('This field is required.'),
  breed: Yup.string().trim().nullable().required('This field is required.'),
});

export const AddPet: FC<Props> = ({ onSuccess, onCancel, renewalId }) => {
  const [loading, setLoading] = useState(false);

  const { options: petTypeOptions, isValidating: loadingPetTypes } = useEnumeration('pet-type');
  const { options: petSexOptions, isValidating: loadingPetSexEnum } = useEnumeration('pet-sex');

  const addPet: FormikSubmitFn<New<Pet>> = async (values, actions) => {
    setLoading(true);
    try {
      const petInserted = await RenewalService.addPet(renewalId, values);
      onSuccess(petInserted);
      actions.resetForm();
    } catch (error) {
      handleError(error, { toastFallbackMessage: 'There was an error adding the pet' });
    } finally {
      setLoading(false);
    }
  };

  return (
    <Formik<New<Pet>>
      onSubmit={addPet}
      validationSchema={validationSchema}
      initialValues={{
        name: '',
        breed: '',
        type: PetType.CAT,
        sex: PetSex.M,
        age: 10,
        weight: 10,
        color: '',
        serviceAnimal: false,
        spayedNeutered: false,
      }}
    >
      {({ handleSubmit, submitForm, resetForm, values }) => (
        <Modal
          visible
          onCancel={() => {
            onCancel?.();
            resetForm();
          }}
          onOk={submitForm}
          okButtonProps={{
            disabled: loading,
            loading,
          }}
          okText="Save"
          title="Add Pet"
          maskClosable={false}
        >
          <Form labelCol={{ span: 6 }} wrapperCol={{ span: 18 }} onSubmitCapture={handleSubmit}>
            <Field component={AntInput} label="Name" name="name" type="text" hasFeedback />
            <Field
              component={AntSelect}
              name="type"
              label="Pet Type"
              hasFeedback
              disabled={loadingPetTypes}
              selectOptions={petTypeOptions}
              getOptionLabel={(option: Option) => option.label}
              getOptionValue={(option: Option) => option.value}
            />
            <Field
              component={AntSelect}
              name="sex"
              label="Gender"
              hasFeedback
              disabled={loadingPetSexEnum}
              selectOptions={petSexOptions}
              getOptionLabel={(option: Option) => option.label}
              getOptionValue={(option: Option) => option.value}
            />
            <Field component={AntInput} label="Breed" name="breed" type="text" hasFeedback />
            <Field component={AntInput} label="Age" name="age" type="number" hasFeedback />
            <Field
              component={AntInput}
              label="Weight (lbs.)"
              name="weight"
              type="number"
              hasFeedback
            />
            <Field component={AntInput} label="Color" name="color" type="text" hasFeedback />
            <Field
              component={AntCheckbox}
              label="Service Animal"
              name="serviceAnimal"
              type="checkbox"
              checked={values.serviceAnimal}
              hasFeedback
            />
            <Field
              component={AntCheckbox}
              label="Spayed Neutered"
              name="spayedNeutered"
              checked={values.spayedNeutered}
              type="checkbox"
              hasFeedback
            />
          </Form>
        </Modal>
      )}
    </Formik>
  );
};
