import type { Column } from '@/@propify-components';
import { booleanColumn, Table } from '@/@propify-components';
import type { ConfirmFnType } from '@/components/FloatingInlineEdit';
import FloatingInlineDropdownEdit from '@/components/FloatingInlineEdit/FloatingInlineDropdownEdit';
import FloatingInlineNumberEdit from '@/components/FloatingInlineEdit/FloatingInlineNumberEdit';
import { enumColumn } from '@/components/TableUtils';
import { ApplicationType } from '@/domain/applications';
import type { RenewalApplication, RenewalDTO } from '@/domain/renewal';
import { RenewalApplicationStatus, RenewalStatus } from '@/domain/renewal';
import type { ApplicationToRemove } from '@/pages/prospects/renewals/components/RenewalsDetail';
import { hideButton } from '@/pages/prospects/renewals/components/RenewalsDetail';
import EditableApplicationType from '@/pages/prospects/unit-applications/components/EditableApplicationType';
import LookupService from '@/services/lookup';
import { RenewalService } from '@/services/renewal';
import { useFetch } from '@/utils/request';
import { getBooleanOptions, handleError } from '@/utils/utils';
import { DeleteOutlined, StarFilled } from '@ant-design/icons';
import { formatCurrency } from '@propify/components';
import { Button, message, Modal, Space, Spin, Tag, Tooltip } from 'antd';
import type { FC } from 'react';
import { useCallback, useMemo } from 'react';
import { Link } from 'react-router-dom';
import type { KeyedMutator } from 'swr';

interface Props {
  renewalId: number;
  renewalApplications: RenewalApplication[];
  onRemoveApplication: (applicationToRemove: ApplicationToRemove) => void;
  refetchRenewal: KeyedMutator<RenewalDTO>;
  refetchRenewalApplications: () => void;
  onRemovePrimaryApplication: () => void;
  renewalStatus: RenewalStatus;
}

export const strikeThroughIfCancelled = (row: RenewalApplication) =>
  row.status === RenewalApplicationStatus.CANCELLED ? 'strikeThrough' : '';

const CarryoverResidents: FC<Props> = ({
  renewalId,
  renewalApplications,
  onRemoveApplication,
  refetchRenewal,
  refetchRenewalApplications,
  onRemovePrimaryApplication,
  renewalStatus,
}) => {
  const residentsIds = useMemo(
    () => renewalApplications.map((a) => a.personId as number),
    [renewalApplications],
  );

  const { data: residents = [], isValidating: loadingResidents } = useFetch(
    {
      fetcher: residentsIds.length > 0 ? LookupService.party : undefined,
      errorMessage: 'There was an error fetching the residents',
    },
    { id: residentsIds },
  );

  const handleRenewalApplicationUpdate = useCallback(
    (applicant: RenewalApplication, propName: string): ConfirmFnType<number> =>
      async (value: number, done, fail) => {
        if (!renewalId || !applicant.id || typeof value !== 'number') {
          done();
          return;
        }

        try {
          await RenewalService.updateApplication(renewalId, {
            ...applicant,
            [propName]: value,
          });
          await refetchRenewal();
          done();
        } catch (error) {
          handleError(error, { toastFallbackMessage: 'Renewal Application Update Failed' });
          fail();
        }
      },
    [refetchRenewal, renewalId],
  );

  const booleanOptions = useMemo(
    () =>
      getBooleanOptions().map((item) => ({
        ...item,
        value: String(item.value),
      })),
    [],
  );

  const handleScreeningUpdate: ConfirmFnType<RenewalApplication> = (
    renewalApplication: RenewalApplication,
    done,
    fail,
  ) => {
    const updateRenewalApplication = () => {
      RenewalService.updateApplication(renewalId, renewalApplication)
        .then(() => {
          message.success('Renewal updated successfully');
          refetchRenewal();
          refetchRenewalApplications();
          done();
        })
        .catch((error) => {
          handleError(error, {
            toastFallbackMessage: 'There was an error updating the renewal',
          });
          fail();
        });
    };

    if (renewalApplication.requiresScreening) {
      const resident = residents.find((r) => r.id === renewalApplication.personId);

      Modal.confirm({
        title: 'Require screening',
        content: (
          <Space direction="vertical">
            <span>
              Are you sure you want to require screening for {resident?.displayName || ''}
            </span>
            <span>This action cannot be undone</span>
          </Space>
        ),
        okText: 'Yes, require screening',
        onOk: updateRenewalApplication,
        onCancel: fail,
      });
    } else {
      updateRenewalApplication();
    }
  };

  const handleUpdateApplicantType =
    (renewalApplication: RenewalApplication): ConfirmFnType<string> =>
    (value, done, fail) => {
      RenewalService.updateApplication(renewalId, {
        ...renewalApplication,
        type: value as ApplicationType,
      })
        .then(() => {
          message.success('Application type was successfully updated');

          refetchRenewalApplications();
          refetchRenewal?.();
          done();
        })
        .catch((error) => {
          handleError(error, {
            toastFallbackMessage: 'There was an error updating the application type',
          });
          fail(error);
        });
    };

  const canUpdateApplicantType = ![
    RenewalStatus.DECLINED,
    RenewalStatus.CANCELLED,
    RenewalStatus.REFUSED,
    RenewalStatus.ACCEPTED,
    RenewalStatus.RENEWED,
  ].includes(renewalStatus);

  const columns: Column<RenewalApplication>[] = [
    {
      key: 'id',
      title: 'ID',
      width: 80,
      className: strikeThroughIfCancelled,
    },
    {
      key: 'displayName',
      title: 'Applicant',
      render: (row) => {
        if (loadingResidents) {
          return <Spin />;
        }

        const resident = residents.find((r) => r.id === row.personId);

        return (
          <Space className={strikeThroughIfCancelled(row)}>
            <Link
              to={`/person/${row.personId}`}
              style={{ overflow: 'hidden', textOverflow: 'ellipsis' }}
            >{`${resident?.displayName ?? row.personId}`}</Link>

            {row.type === ApplicationType.PRIMARY && (
              <Tooltip title="Primary Applicant">
                <StarFilled style={{ color: '#2B90FD' }} />
              </Tooltip>
            )}
          </Space>
        );
      },
    },
    enumColumn(
      {
        key: 'type',
        title: 'Type',
        render: (row) => {
          const isItemPrimaryApplicant = row.type === ApplicationType.PRIMARY;

          const editDisabled = isItemPrimaryApplicant || !canUpdateApplicantType;

          return (
            <EditableApplicationType
              onConfirm={handleUpdateApplicantType(row)}
              applicationType={row.type}
              hideEdit={!canUpdateApplicantType}
              editDisabled={editDisabled}
            />
          );
        },
      },
      { enumName: 'application-type' },
    ),
    {
      key: 'validatedIncome',
      title: 'Validated Monthly Income',
      className: (row) => `${strikeThroughIfCancelled(row)} highlightBlue`,
      width: 200,
      render: (row) => {
        if (row.status === RenewalApplicationStatus.CANCELLED || hideButton(renewalStatus)) {
          return formatCurrency(row.validatedIncome || 0);
        }

        return (
          <FloatingInlineNumberEdit
            onConfirm={handleRenewalApplicationUpdate(row, 'validatedIncome')}
            initialValue={row.validatedIncome || 0}
            displayValue={formatCurrency(row.validatedIncome || 0)}
          />
        );
      },
    },
    booleanColumn({
      key: 'requireScreening',
      title: 'Requires Screening?',
      width: 170,
      className: strikeThroughIfCancelled,
      render: (row: RenewalApplication) => {
        if (row.status === RenewalApplicationStatus.CANCELLED || hideButton(renewalStatus)) {
          return row.requiresScreening ? 'Yes' : 'No';
        }

        return (
          <FloatingInlineDropdownEdit
            options={booleanOptions}
            onConfirm={(requireScreening, done, fail) => {
              handleScreeningUpdate(
                { ...row, requiresScreening: requireScreening === 'true' },
                done,
                fail,
              );
            }}
            initialValue={`${!!row.requiresScreening}`}
            displayValue={row.requiresScreening ? 'Yes' : 'No'}
          />
        );
      },
    }),
    {
      key: 'actions',
      title: '',
      width: 100,
      render: (row) => {
        if (row.status === RenewalApplicationStatus.CANCELLED) {
          return <Tag color="red">Cancelled</Tag>;
        }

        if (hideButton(renewalStatus)) {
          return <></>;
        }

        return (
          <Button
            data-testid="applications-list-modal-remove-button"
            icon={<DeleteOutlined />}
            type="link"
            danger
            onClick={() => {
              if (row.type === ApplicationType.PRIMARY) {
                onRemovePrimaryApplication();
                return;
              }

              const resident = residents.find((r) => r.id === row.personId);

              onRemoveApplication?.({
                personId: row.personId,
                renewalApplicationId: row.id,
                personName: `${resident?.displayName || row.personId}`,
              });
            }}
          />
        );
      },
    },
  ];

  return (
    <div>
      <Table rowKeyExtractor={(r) => `${r.id}`} data={renewalApplications} columns={columns} />
    </div>
  );
};

export default CarryoverResidents;
