import { ModalFormButton } from '@/components/Button/formButton';
import type {
  ColumnComponentType,
  ColumnComponentTypeProps,
} from '@/notmagic/components/EntityTable/types';
import AdjustRentModal from '@/pages/property/components/AdjustRentModal';
import { EditOutlined } from '@ant-design/icons';
import { Align, formatCurrency } from '@propify/components';

type RowType = {
  unitId: number;
  marketRent: number;
};
type Props = ColumnComponentTypeProps<RowType>;

const Component: ColumnComponentType<Props> = ({ value, onValidateEntity }) => {
  if (!value || !value.unitId || value.marketRent === undefined) {
    // Metadata must set the "value" prop for this cell to be rendered correctly
    return null;
  }

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'space-between',
      }}
    >
      <span>{formatCurrency(value.marketRent)}</span>
      <ModalFormButton
        buttonType="link"
        icon={<EditOutlined />}
        onSuccess={() => onValidateEntity()}
        loading={false}
      >
        {(properties) => <AdjustRentModal {...properties} unitId={value.unitId} />}
      </ModalFormButton>
    </div>
  );
};

export default {
  Component,
  hideSort: true, // TODO: sorting in 'mapColumn' must be fixed
  align: Align.right,
  getValueForExport: (value: any) => formatCurrency(value),
};
