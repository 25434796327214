import type { Entities, EntityType } from '@/domain/entity-type';
import type { Note } from '@/domain/note';
import { NoteType } from '@/domain/note';
import { NoteService } from '@/services/notes';
import { useFetch } from '@/utils/request';
import { CloseOutlined, PlusOutlined } from '@ant-design/icons';
import { Col, Row } from 'antd';
import Modal from 'antd/lib/modal/Modal';
import type { FC } from 'react';
import type { ModalFormProps } from '../Button/formButton';
import { ModalFormButton } from '../Button/formButton';
import SectionSplashScreen from '../PageLoading/SplashSection';
import CreateNoteForm from './create';
import ViewAllNotes from './ViewAllNotes';

interface Props extends ModalFormProps {
  entityType: EntityType;
  entityId: number;
  otherEntities?: Entities;
  visible?: boolean;
  refetch?: () => void;
  selectedNoteFromParent?: Note;
  hideCreate?: boolean;
}

const ViewAllNotesModal: FC<Props> = ({
  entityType,
  entityId,
  visible = true,
  onCancel,
  otherEntities,
  refetch,
  selectedNoteFromParent,
  hideCreate,
}) => {
  const { data: notes = [], isValidating: loadingNotes, mutate: refetchNotes } = useFetch(
    {
      fetcher: NoteService.search,
      errorMessage: 'There was an error while fetching notes',
    },
    {
      entityId,
      entityType,
    },
  );

  return (
    <Modal
      visible={visible}
      closable={false}
      style={{ minWidth: 880 }}
      title={
        <Row align="middle">
          <Col flex={1} className="title">
            Notes
          </Col>
          {!hideCreate && (
            <Col style={{ marginRight: 10 }}>
              <ModalFormButton
                icon={<PlusOutlined />}
                buttonType="primary"
                buttonClassName="add-note-button"
                onSuccess={() => {
                  refetch?.();
                  refetchNotes();
                }}
              >
                {(props) => (
                  <CreateNoteForm
                    {...props}
                    defaultType={NoteType.GENERAL}
                    entityType={entityType}
                    entityId={entityId}
                    otherEntities={otherEntities}
                  />
                )}
              </ModalFormButton>
            </Col>
          )}
          <Col className="close-icon" onClick={onCancel}>
            <CloseOutlined style={{ color: '#1890FF' }} />
          </Col>
        </Row>
      }
      onCancel={onCancel}
      className="view-notes-modal"
      footer={null}
    >
      {loadingNotes ? (
        <Row justify="center" align="middle" style={{ height: '100px' }}>
          <SectionSplashScreen />
        </Row>
      ) : (
        <ViewAllNotes
          entityId={entityId}
          entityType={entityType}
          notes={notes}
          refetch={() => {
            refetchNotes();
            refetch?.();
          }}
          loading={loadingNotes}
          selectedNoteFromParent={selectedNoteFromParent || notes[0]}
        />
      )}
    </Modal>
  );
};

export default ViewAllNotesModal;
