import type {
  Lead,
  LeadAnalyticsParams,
  LeadAnalyticsResult,
  LeadBulkUpdateRequest,
  LeadSearchParams,
  LeadSearchResult,
  LeadsSummary,
} from '@/domain/lead';
import { api } from '@/utils/configuration';
import { crudService, getAxiosFetcher } from '@/utils/request';
import type { Operation } from 'fast-json-patch';

export const LeadService = {
  findById: getAxiosFetcher<Lead, [number]>((id) => [`${api.LEAD}/${id}`]),

  search: getAxiosFetcher<LeadSearchResult[], [params: LeadSearchParams]>((params) => [
    api.LEAD,
    { params },
  ]),

  analytics: getAxiosFetcher<LeadAnalyticsResult[], [params: LeadAnalyticsParams]>((params) => [
    `${api.LEAD}/analytics`,
    { params },
  ]),

  summary: getAxiosFetcher<LeadsSummary, []>(() => [`${api.LEAD}/summary`]),

  create: (lead: Partial<Lead>) => crudService.post<Lead>(lead, api.LEAD),

  update: (lead: Partial<Lead>) => crudService.put<Lead>(lead, `${api.LEAD}/${lead.id}`),

  patch: (id: number, operations: Operation[], version: number) =>
    crudService.patch<Lead>(operations, `${api.LEAD}/${id}?version=${version}`),

  bulkUpdate: (request: LeadBulkUpdateRequest, params: LeadSearchParams) =>
    crudService.put<Lead[]>(request, api.LEAD, { params }),
};
