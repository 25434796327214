import type { WorkOrder } from '@/domain/work-order';
import { WorkOrderStatus } from '@/domain/work-order';
import { red } from '@/utils/styles/colors';
import { SHORT_DATE_FORMAT } from '@/utils/time';
import EditOutlined from '@ant-design/icons/EditOutlined';
import { Button, Space } from 'antd';
import moment from 'moment';
import type { FC } from 'react';
import { useState } from 'react';
import DeferWorkOrderModal from './DeferWorkOrderModal';

interface Props {
  workOrder: Pick<
    WorkOrder,
    'id' | 'version' | 'status' | 'deferredUntil' | 'partyResponsibleForDeferral'
  >;
  onSuccess: (wo: WorkOrder) => void;
}

const DeferWorkOrderButton: FC<Props> = ({ workOrder, onSuccess }) => {
  const [showDeferModal, setShowDeferModal] = useState(false);

  const isDeferDisabled = [
    WorkOrderStatus.FINALIZED,
    WorkOrderStatus.UNABLE_TO_COMPLETE,
    WorkOrderStatus.CANCELLED,
  ].includes(workOrder.status as WorkOrderStatus);

  const closeModal = () => {
    setShowDeferModal(false);
  };

  const openModal = () => {
    setShowDeferModal(true);
  };

  const handleSuccess = (updatedWorkOrder: WorkOrder) => {
    onSuccess(updatedWorkOrder);
    closeModal();
  };

  const isDeferredUntilInTheFuture = workOrder.deferredUntil
    ? moment(workOrder.deferredUntil).isAfter(moment(), 'day')
    : false;

  return (
    <>
      {isDeferredUntilInTheFuture ? (
        <Space>
          <span style={{ color: red }}>
            {moment(workOrder.deferredUntil).format(SHORT_DATE_FORMAT)}
          </span>
          <Button
            icon={<EditOutlined />}
            disabled={isDeferDisabled}
            type="link"
            onClick={openModal}
          />
        </Space>
      ) : (
        <Button type="primary" onClick={openModal} disabled={isDeferDisabled}>
          Defer
        </Button>
      )}
      {showDeferModal && (
        <DeferWorkOrderModal
          workOrder={workOrder}
          onSuccess={handleSuccess}
          onCancel={closeModal}
        />
      )}
    </>
  );
};

export default DeferWorkOrderButton;
