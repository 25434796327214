import type { WorkOrderStatus } from '@/domain/work-order';
import type { WorkOrderParticipantType } from '@/domain/work-order-participants';
import type {
  ColumnComponentType,
  ColumnComponentTypeProps,
} from '@/notmagic/components/EntityTable/types';
import DeferWorkOrderButton from '@/pages/maintenance/components/WorkOrders/DeferWorkOrderButton';

type RowType = {
  id: number;
  version: number;
  status: WorkOrderStatus;
  deferredUntil?: string;
  partyResponsibleForDeferral?: WorkOrderParticipantType;
};

type Props = ColumnComponentTypeProps<RowType>;

const Component: ColumnComponentType<Props> = ({ value, refreshEntities }) => {
  if (!value) {
    return null;
  }

  return (
    <DeferWorkOrderButton
      workOrder={{
        id: value.id,
        version: value.version,
        status: value.status,
        deferredUntil: value.deferredUntil,
        partyResponsibleForDeferral: value.partyResponsibleForDeferral,
      }}
      onSuccess={() => refreshEntities()}
    />
  );
};

export default {
  Component,
};
