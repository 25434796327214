import type { Address } from '@/domain/address';
import type { New, PropifyEntity } from '@/domain/base';
import type { Community } from '@/domain/community';
import type { Amenity } from './amenity';
import type { CreateUnitDTO, ListingStatus, UnitStatus } from './unit';

export enum SewerType {
  SEPTIC = 'Septic',
  CITY = 'City',
}

export enum WaterType {
  WELL = 'Well',
  CITY = 'City',
}

export enum HeatingType {
  GAS = 'Gas',
  ELECTRIC = 'Electric',
}

export enum PropertyType {
  SINGLE_FAMILY = 'SINGLE_FAMILY',
  MULTI_FAMILY = 'MULTI_FAMILY',
  TOWNHOUSE = 'TOWNHOUSE',
  CONDOMINIUM = 'CONDOMINIUM',
}

export interface Property extends PropifyEntity {
  communityId?: number;
  sewerType?: SewerType;
  waterType?: WaterType;
  heatingType?: HeatingType;
  address?: Address;
  fund?: string;
  msa?: string;
  description?: string;
  county?: string;
  holdingCompany?: string;
  holdingCompanyId?: number;
  yearBuilt?: number | string;
  timezone?: string;
  type: PropertyType;
  transactionStatus: TransactionStatus;
  amenities?: Amenity[];
  marketingDescription?: string;
  virtualTourUrl?: string;
  marketRent?: number;
  petPolicyId?: number;
  stories: number;
  safeMode: boolean;
  commonDoorLockCode?: string;
  foreignDbCode?: string;
  foreignDbId?: number;
  acquisitionSource?: string;
  acquisitionDate?: string;
  pool?: boolean;
  lotSquareFeet?: number;
  taxId?: string;
  taxIdPin?: string;
  basement?: boolean;
  garageCapacity?: number;
  floodZone?: boolean;
  hoa?: boolean;
  brandId?: number;
  fundId?: number;
  community?: Community;
  ventureId?: number;
  propertyGroupId?: number;
  propertyGroupIds?: number[];
  hasLeaseDocumentTemplates?: boolean;
}

export interface InsertPropertyDTO extends New<Property> {
  units?: New<CreateUnitDTO>[];
}

export interface PropertyUpdateRequest extends Omit<Property, 'amenities'> {
  amenities?: New<Amenity>[];
}

export interface PropertySearchResult extends Property {
  listingImages?: ListingImage[];
  valuation?: Valuation;
  unitStatus?: UnitStatus;
  unitListingStatus?: ListingStatus;
  unitCurrentRent?: number;
  unitListedRent?: number;
  unitId?: number;
}

export enum TransactionStatus {
  HFS = 'HFS',
  SOLD = 'SOLD',
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
  PENDING = 'PENDING',
  CANCELLED = 'CANCELLED',
}

type MITSImageType = 'FLOORPLAN' | 'PHOTO';

export interface ListingImage extends PropifyEntity {
  propertyId: number;
  url: string;
  fileName: string;
  caption: string;
  rank: number;
  mitsType: MITSImageType;
}

export interface ListingImageSource extends ListingImage {
  src: string;
}

export interface Valuation extends PropifyEntity {
  amount: number;
  date: string;
  source: string;
  filesCount: number;
}

export interface PropertyBulkUpdateRequest {
  ids: number[];
  petPolicyId?: number;
  commonDoorLockCode?: string;
  communityId?: number;
}
