import classNames from 'classnames';
import difference from 'lodash/difference';
import uniq from 'lodash/uniq';
import PropTypes from 'prop-types';
import type { RefObject } from 'react';
import React from 'react';
import Loader from '../Loader';
import { withStyles } from '../shared/utils/material';
/* eslint-disable import/order */
import type { DataId, Props, State } from './Table.types';
import { inMemorySettings } from './Table.types';
import type { SortDirection } from './utils';
import { DEFAULT_COLUMN_MAX_WIDTH, DEFAULT_COLUMN_WIDTH, sorter } from './utils';
import {
  isAllSelected,
  isItemSelected,
  toggleAllSelected,
  toggleItemSelected,
} from './utils/selection';
import Footer from './_private/Footer';
import Header from './_private/Header';
import List from './_private/List';
import Pagination from './_private/Pagination';
import Row from './_private/Row';
import { getPinColumnsData } from './_private/StickyColumns';
/* eslint-enable import/order */

const DEFAULT_CLASSES = {};

class Table<T, K extends DataId> extends React.PureComponent<Props<T, K>, State<T>> {
  public tableRef: RefObject<HTMLDivElement>;

  constructor(props: Props<T, K>) {
    super(props);

    this.tableRef = React.createRef();

    this.state = {
      pageNumber: 1,
      sortedBy: props.sortedBy,
      sortDir: props.sortDir,
      displayData: [],
      pinnedColumns: props.pinnedColumns?.length
        ? props.pinnedColumns
        : this.props.settings!.getPinnedColumns() || [],
      calculatedColumnWidths: {},
    };

    this.sortedData = [];
  }

  public static propTypes = {
    loading: PropTypes.bool,
    columns: PropTypes.array.isRequired,
    rowKeyExtractor: PropTypes.func.isRequired,
    selection: PropTypes.array,
    data: PropTypes.array.isRequired,
    pinnedColumns: PropTypes.array,
  };

  public static defaultProps: Partial<Props<any, DataId>> = {
    loading: false,
    selection: [],
    pinnedColumns: [],
    settings: {
      getColumnOrder: () => inMemorySettings.columnOrder,
      setColumnOrder: (columnOrder: string[]) => {
        inMemorySettings.columnOrder = columnOrder;
      },
      getColumnWidth: (key: string) => inMemorySettings.columnWidths[key],
      setColumnWidth: (key: string, width: number) => {
        inMemorySettings.columnWidths[key] = width;
      },
      getVisibleColumns: () => undefined,
      setVisibleColumns: () => {},
      getPinnedColumns: () => inMemorySettings.pinnedColumns,
      setPinnedColumns: (pinnedColumns: string[]) => {
        inMemorySettings.pinnedColumns = pinnedColumns;
      },
    },
    classes: DEFAULT_CLASSES,
  };

  private previousSelection?: { itemIndex: number; selected: boolean };

  private sortedData: T[];

  private pinnedColumns: string[] = this.props.settings?.getPinnedColumns() || [];

  // eslint-disable-next-line react/sort-comp
  private handleColumnResize = (_event: any, data: any, column: any) => {
    this.props.settings?.setColumnWidth(column.key, data.size.width);
    this.forceUpdate();
  };

  private updatePinnedColumns = (columnKey: string) => {
    const { columnsPinnedToTheLeft, columnsPinnedToTheRight } = getPinColumnsData(
      this.getColumns(),
      this.pinnedColumns,
    );

    const leftPinnedColumnKeys = columnsPinnedToTheLeft.map((col) => col.key);
    const rightPinnedColumnKeys = columnsPinnedToTheRight.map((col) => col.key);

    const leftPinnedIndex = leftPinnedColumnKeys.indexOf(columnKey);
    const rightPinnedIndex = rightPinnedColumnKeys.indexOf(columnKey);

    if (leftPinnedIndex === -1 && rightPinnedIndex === -1) {
      this.pinnedColumns.push(columnKey);
    } else {
      if (leftPinnedIndex !== -1) {
        leftPinnedColumnKeys.splice(leftPinnedIndex, leftPinnedColumnKeys.length);
      } else {
        rightPinnedColumnKeys.splice(0, rightPinnedIndex + 1);
      }

      this.pinnedColumns = [...leftPinnedColumnKeys, ...rightPinnedColumnKeys];
    }

    this.setState({ pinnedColumns: [...this.pinnedColumns] });
    this.props.settings?.setPinnedColumns(this.pinnedColumns);
  };

  private toggleAllSelected = (selected: boolean) => {
    const displayDataKeys = toggleAllSelected(this.state.displayData, this.props.rowKeyExtractor);

    const selection = selected
      ? uniq([...this.props.selection!, ...displayDataKeys])
      : difference(this.props.selection, displayDataKeys);

    if (typeof this.props.onSelectionChange === 'function') {
      this.props.onSelectionChange(selection as K[]);
    }
  };

  private toggleItemSelected = (itemIndex: number, selected: boolean, shiftKeyPressed: boolean) => {
    const selection = toggleItemSelected(
      this.sortedData,
      this.props.rowKeyExtractor,
      this.props.selection!,
      { itemIndex, shiftKeyPressed },
      this.previousSelection,
    );

    this.previousSelection = {
      itemIndex,
      selected,
    };

    if (typeof this.props.onSelectionChange === 'function') {
      this.props.onSelectionChange(selection);
    }
  };

  private onChangeColumnOrder = (newColumnOrder: string[]) => {
    this.props.settings?.setColumnOrder(newColumnOrder);
    this.forceUpdate();
  };

  private getColumns = () => {
    const columnsOrder =
      this.props.settings!.getColumnOrder() ?? this.props.columns.map((column) => column.key);

    return this.props.columns
      .filter(
        (column) =>
          !this.props.settings ||
          !this.props.settings.getVisibleColumns() ||
          typeof this.props.settings.getVisibleColumns()![column.key] === 'undefined' ||
          this.props.settings.getVisibleColumns()![column.key],
      )
      .map((column) => {
        const width =
          this.props.settings?.getColumnWidth(column.key) ??
          this.state.calculatedColumnWidths[column.key] ??
          column.width;

        return { ...column, width };
      })
      .sort((a, b) => columnsOrder.indexOf(a.key) - columnsOrder.indexOf(b.key));
  };

  private getSortedBy = () => {
    if (this.props.onSortChange) {
      return this.props.sortedBy;
    }

    return this.state?.sortedBy;
  };

  private getSortDir = () => {
    if (this.props.onSortChange) {
      return this.props.sortDir;
    }

    return this.state?.sortDir;
  };

  private sortData = (sortedBy: string, sortDir: SortDirection) => {
    const columns = this.getColumns();
    const sortFunction = sorter(columns, sortedBy, sortDir);

    return [...this.props.data].sort(sortFunction);
  };

  private sortChange = (sortedBy: string, sortDir: SortDirection) => {
    if (this.props.onSortChange) {
      this.props.onSortChange(sortedBy, sortDir);
    } else {
      this.setState({
        sortedBy,
        sortDir,
        pageNumber: 1,
      });

      this.sortedData = this.sortData(sortedBy, sortDir);

      if (this.props.pageSize) {
        this.setDisplayData(this.getPage(1, this.props.pageSize));
      } else {
        this.setDisplayData(this.sortedData);
      }
    }
  };

  private setDisplayData = (data: T[]) => {
    this.setState({ displayData: data });
  };

  public componentDidMount() {
    this.setSortedData();
  }

  public componentDidUpdate(prevProps: Props<T, K>, prevState: State<T>) {
    if (
      this.props.data !== prevProps.data ||
      this.props.sortedBy !== prevProps.sortedBy ||
      this.props.sortDir !== prevProps.sortDir
    ) {
      this.setSortedData();
    }

    if (this.state.displayData !== prevState.displayData) {
      this.updateColumnsWithAutomaticWidth();
    }
  }

  private updateColumnsWithAutomaticWidth() {
    const autoWidthColumns = this.getColumns().filter((col) => col.autoWidth);
    const table = this.tableRef.current;

    if (!autoWidthColumns.length || !table) {
      return;
    }

    const newValues: Record<string, number> = {};

    autoWidthColumns.forEach((col) => {
      const cells = [...(table.querySelectorAll(`[data-column-key="${col.key}"]`) as any)];
      const maxChildWidth = cells.reduce((max, next) => {
        const child = next.children[0];

        return child instanceof HTMLElement && child.offsetWidth > max ? child.offsetWidth : max;
      }, 0);

      if (maxChildWidth > 0) {
        const extraSpaceForPadding = 30;

        // To prevent columns being too narrow
        const width = Math.max(
          col.width || DEFAULT_COLUMN_WIDTH,
          maxChildWidth + extraSpaceForPadding,
        );

        // To respect the maximum width for a column
        newValues[col.key] = Math.min(width, col.maxWidth || DEFAULT_COLUMN_MAX_WIDTH);
      }
    });

    this.setState({ calculatedColumnWidths: newValues });
  }

  private setSortedData() {
    if (this.props.sortedBy && this.props.sortDir) {
      this.sortedData = this.sortData(this.props.sortedBy, this.props.sortDir);
    } else {
      this.sortedData = this.props.data && [...this.props.data];
    }
    if (this.props.pageSize) {
      let pageNumber;
      if (
        this.props.data.length / this.props.pageSize >
        this.state.pageNumber * this.props.pageSize
      ) {
        pageNumber = this.state.pageNumber;
      } else {
        this.setState({
          pageNumber: 1,
        });
        pageNumber = 1;
      }

      this.setDisplayData(this.getPage(pageNumber, this.props.pageSize));
    } else {
      this.setDisplayData(this.sortedData);
    }
  }

  private renderHeader = () => (
    <Header
      styles={this.props.classes || DEFAULT_CLASSES}
      allSelected={isAllSelected(
        this.props.selection!,
        this.state.displayData,
        this.props.rowKeyExtractor,
      )}
      toggleAllSelected={(event) => this.toggleAllSelected(event.target.checked)}
      columns={this.getColumns()}
      sortedBy={this.getSortedBy()}
      sortDir={this.getSortDir()}
      onSortChange={this.sortChange}
      onColumnResize={this.handleColumnResize}
      isSelectable={!!this.props.onSelectionChange}
      selectionDisabled={!!this.props.selectionDisabled}
      isDraggable={!!this.props.onRowDrop}
      setColumnPinned={this.updatePinnedColumns}
      showTooltip={!this.props.hideTooltipColumn! && !!this.props.getRowHighlightProps}
      pinnedColumns={this.state.pinnedColumns}
      onChangeColumnOrder={this.onChangeColumnOrder}
      singleSelection={!!this.props.singleSelection}
    />
  );

  private scrollToTop = () => {
    const scrollable = this.tableRef.current?.getElementsByClassName(
      'propify-table-scrollable-element',
    )?.[0];

    scrollable?.scrollTo(scrollable.scrollLeft, 0);
  };

  private onChangePage = (pageNumber: number) => {
    this.setState({
      pageNumber,
    });

    this.setDisplayData(this.getPage(pageNumber, this.props.pageSize!));

    this.scrollToTop();
  };

  private getPage = (pageNumber: number, pageSize: number) => {
    const start = (pageNumber - 1) * pageSize;
    const end = Math.min(start + pageSize, this.sortedData.length);

    return this.sortedData.slice(start, end);
  };

  private renderRow = (item: T, itemIndex: number) => {
    const rowInfo = this.props.getRowHighlightProps?.(item, itemIndex);

    return (
      <Row
        key={this.props.rowKeyExtractor(item)}
        styles={this.props.classes || DEFAULT_CLASSES}
        onClick={this.props.onRowClick}
        columns={this.getColumns()}
        item={item}
        itemIndex={itemIndex}
        draggable={!!this.props.onRowDrop}
        selectable={!!this.props.onSelectionChange}
        selectionDisabled={!!this.props.selectionDisabled}
        selected={isItemSelected(
          this.props.selection!,
          this.props.rowKeyExtractor(item, itemIndex),
        )}
        singleSelection={this.props.singleSelection}
        toggleSelected={(event) =>
          this.props.singleSelection
            ? this.props.onSelectionChange?.([this.props.rowKeyExtractor(item, itemIndex)])
            : this.toggleItemSelected(
                this.props.pageSize
                  ? this.props.pageSize * (this.state.pageNumber - 1) + itemIndex
                  : itemIndex,
                event.target.checked,
                event.nativeEvent.shiftKey,
              )
        }
        pinnedColumns={this.state.pinnedColumns}
        className={rowInfo?.className}
        showTooltip={!this.props.hideTooltipColumn && !!this.props.getRowHighlightProps}
        tooltipText={rowInfo?.tooltipText}
        responsive={this.props.responsive}
      />
    );
  };

  public dataExport() {
    const { data } = this.props;
    const columns = this.getColumns();
    return (
      data &&
      data.map((row: any, rowIndex: number) => {
        const rowObj: any = {};
        columns.forEach((column) => {
          let value;
          if (typeof column.getValueForExport === 'function') {
            value = column.getValueForExport(row);
          } else if (typeof column.render === 'function') {
            value = column.render(row, rowIndex);
            if (value && value.props && value.props.children) {
              value = value.props.children;
            }
          } else {
            value = row[`${column.key}` as string];
          }
          if (value === 'undefined' || `${value}`?.includes('[object Object]')) {
            value = '';
          }
          rowObj[column.title] = value || '';
        });
        return rowObj;
      })
    );
  }

  private renderFooter = () => {
    const columns = this.getColumns();

    return (
      <div
        style={{
          display: 'flex',
          flex: 1,
          flexDirection: 'column',
          position: 'sticky',
          bottom: 0,
          zIndex: 51,
        }}
      >
        {columns.some((column) => column.total) && (
          <Footer
            columns={columns}
            isSelectable={!!this.props.onSelectionChange}
            isDraggable={!!this.props.onRowDrop}
            pinnedColumns={this.state.pinnedColumns}
            styles={this.props.classes || DEFAULT_CLASSES}
          />
        )}
        {this.props.renderFooterExtra && this.props.renderFooterExtra()}
      </div>
    );
  };

  public render() {
    return (
      <div className={classNames('propify-table', this.props.className)} ref={this.tableRef}>
        <List
          styles={this.props.classes || DEFAULT_CLASSES}
          data-testid={this.props['data-testid']}
          style={this.props.style}
          loading={this.props.loading!}
          data={this.state.displayData}
          renderHeader={this.renderHeader}
          renderItem={this.renderRow}
          onSortEnd={this.props.onRowDrop}
          renderFooter={this.renderFooter}
          itemKeyExtractor={this.props.rowKeyExtractor}
          widthFitContent={this.props.widthFitContent}
          renderLoading={() => (
            <div className="tableLoader">
              <Loader size={40} color="#ccc" data-test="tableLoader" />
            </div>
          )}
          responsive={this.props.responsive}
        />
        {this.props.pageSize && (
          <Pagination
            styles={this.props.classes || DEFAULT_CLASSES}
            current={this.state.pageNumber}
            pageSize={this.props.pageSize}
            onChange={this.onChangePage}
            total={this.props.data.length}
            onShowSizeChange={(pageSize) => this.props.onPageSizeChange?.(pageSize)}
            pageSizeOptions={
              this.props.pageSizeOptions ||
              (typeof this.props.onPageSizeChange === 'function' ? [10, 25, 50, 100] : [])
            }
            responsive={this.props.responsive}
          />
        )}
      </div>
    );
  }
}

export default withStyles((theme) => ({
  unpinnedHeader: {
    transform: 'rotate(45deg)',
  },
  pinnedHeader: {
    color: '#1890ff',
    transform: 'rotate(-45deg)',
  },
  phoenixStickyColumn: {
    position: 'sticky',
    display: 'flex',
    zIndex: 1,
  },
  clickableRow: {
    cursor: 'pointer',
  },
  paginationContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    margin: '5px 0 0 0',
    height: 33,
  },
  displayRow: {
    marginLeft: 5,
    color: '#525252',
    fontWeight: 500,
    fontSize: '0.93rem',

    '& button': {
      textTransform: 'capitalize',
    },
    '& a': {
      color: '#001529',
      textDecoration: 'none',
    },
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  pagination: {
    display: 'flex',
    gap: 32,
    alignItems: 'center',
    '& button': {
      minHeight: 29,
      minWidth: 33,
    },
    // To be removed once the repo is moved out
    '& .MuiButton-containedPrimary': {
      color: '#fff',
      backgroundColor: '#1990ff',
    },
    '& .MuiButton-textPrimary': {
      color: '#323232',
    },
    '& .MuiButton-outlinedPrimary': {
      color: '#1990ff',
      border: '1px solid rgb(25 144 255 / 77%)',
    },
  },
  pagesSummary: {
    minWidth: 90,
    fontSize: '0.93rem',
    fontWeight: 500,
    lineHeight: '28px',
    color: '#525252',
    padding: '0 12px',
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  paginationButtons: {
    display: 'flex',
    gap: '6px',
  },
  ellipsis: {
    alignSelf: 'end',
    color: '#d0d0d0',
    paddingTop: 1,
  },
  arrowButtons: {
    display: 'flex',
    gap: '5px',
    alignItems: 'center',
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  arrowButton: {
    '& span': {
      color: '#1890ff',
    },
  },
  tableStickyColumn: {
    zIndex: 11,

    '&:last-child &:first-child': {
      marginLeft: -1,
      borderLeft: '1px solid #f0f0f0',
    },
  },
  tableCell: {
    position: 'relative',
    display: 'flex',
    flex: '0 0 auto',
    alignItems: 'center',
    padding: '0 13px',
    lineHeight: '55px',
    overflow: 'hidden',
    fontWeight: 400,
    fontSize: '0.86rem',

    '&:not(.anticon), button span': {
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
      display: 'block',
    },
    '& button': {
      display: 'inline-flex',
      justifyContent: 'center',
      alignItems: 'center',
    },

    '> div:first-child, > span:first-child': {
      height: '100%',
    },
    '& .floating-editing-container': {
      marginTop: 12,
      height: 32,
      [theme.breakpoints.down('sm')]: {
        width: 'fit-content',
        marginTop: 0,
      },
      '& .display-value': {
        lineHeight: '32px',
        '& .anticon:not(.entityTableBooleanIcon)': {
          color: '#1890FF !important',
        },
        '& .edit-button': {
          [theme.breakpoints.down('sm')]: {
            visibility: 'visible',
            marginLeft: 7,
          },
        },
      },
    },
    '& .ant-btn-primary .anticon, & .atn-btn-primary, & .btn-primary': {
      color: '#fff',
    },
  },
  filterableIcon: {
    marginLeft: 4,
  },

  highlighted: {
    backgroundColor: '#edf9ff',
  },

  tableDragDropCell: {
    display: 'flex !important',
    alignItems: 'center',
    justifyContent: 'center',
    width: 30,
    padding: '0 8px',
  },

  tableDragDropCellHeader: {
    display: 'flex',
    backgroundColor: '#f1f1f1',
    borderRight: '1px solid rgb(0, 0, 0, 0.1)',
  },

  tableFlagCell: {
    display: 'flex !important',
  },

  tableFlagCellHeader: {
    display: 'flex !important',
    backgroundColor: '#f1f1f1',
    borderRight: '1px solid rgb(0, 0, 0, 0.1)',
  },

  expandRowButton: {
    position: 'absolute',
    right: 0,
    borderRadius: 2,
    marginTop: 2,
    background: '#fff',
    boxShadow: '-9px 1px 5px rgb(255 255 255)',
  },

  openedRow: {
    display: 'grid !important',
  },

  tableRow: {
    display: 'flex',
    flex: '0 1 auto',
    flexDirection: 'row',
    height: 56,
    borderBottom: '1px solid rgba(0,0,0,.06)',
    transition: 'all 0.3s ease-in-out',
    backgroundColor: '#fff',

    [theme.breakpoints.down('sm')]: {
      height: 'inherit',
    },

    '& a': {
      color: '#1890FF',
    },
    '& .rowHandle': {
      cursor: 'grab',
    },
    '& .draggedRow .rowHandle': {
      cursor: 'grabbing !important',
    },
    '&:hover &:selected': {
      backgroundColor: '#f0f6fb',

      checkboxCell: {
        backgroundColor: '#f0f6fb',
      },
    },
    '&not(:last-child)': {
      borderBottom: '1px solid #f0f0f0',
    },
  },
  tableRowMobile: {
    border: '1px solid rgba(0,0,0,.06)',
    margin: '5px 0',

    '& .detailsRow': {
      '& .pinned': {
        display: 'grid',
      },
    },
  },
  tableCellRight: {
    textAlign: 'right',
  },
  tableCellCenter: {
    textAlign: 'center',
  },
  tableHeaderCell: {
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    padding: '0 12px',
    backgroundColor: '#f1f1f1',
    borderLeft: '1px solid rgba(0, 0, 0, 0.1)',

    '&:hover': {
      cursor: 'move',
    },
    '& .react-resizable-handle': {
      position: 'absolute',
      right: 0,
      width: 7,
      height: '100%',
      cursor: 'ew-resize',
      opacity: 0,
    },
    '& .flex-table-ghost-handle': {
      display: 'none',
      '&:hover': {
        position: 'relative',
        top: -2,
        zIndex: 5,
        display: 'unset',
        height: 19,
        marginRight: 4,
        color: '#bfbfbf',
        cursor: 'move',
        opacity: 1,
      },
    },
  },
  tableHeaderTitle: {
    width: '100%',
    zIndex: 10,
    height: 17,
    overflow: 'hidden',
    color: '#001529',
    fontWeight: 500,
    fontSize: '0.89rem',
    lineHeight: '16px',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    cursor: 'move',
    paddingRight: 5,
  },
  tableHeaderSorting: {
    zIndex: 1000,
  },
  headerContainer: {
    display: 'flex',
    width: '100%',
    alignItems: 'center',
    justifyContent: 'center',

    '& .sorterFull': {
      marginTop: -4,
    },
    '& .columnSorter': {
      marginLeft: 4,
      color: '#bfbfbf',
    },
    '& .sorterInner': {
      display: 'inline-flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
  },
  hideScrolls: {
    overflow: 'hidden !important',
  },
  // TO DO: Hide box shadows in pinned columns
  // when scrollbar is 0
  hideBoxShadows: {
    boxShadow: 'unset !important',
  },
  tableContainer: {
    position: 'relative',
    flex: 1,
    height: '100%',
    border: '1px solid rgb(0, 0, 0, 0.1)',
    borderRadius: 4,
    overflow: 'auto',
    display: 'flex',
    transition: 'box-shadow 0.3s ease-in-out',
    [theme.breakpoints.down('sm')]: {
      overflow: 'unset',
    },
    '& button': {
      '& span:not(.anticon)': {
        color: 'unset',
      },
    },
    '& .onlyMobile': {
      display: 'none',
      [theme.breakpoints.down('sm')]: {
        display: 'inherit',
      },
    },
    '& .pinned': {
      background: '#fafafa',
      boxShadow: '0 0 0 1px #e9e9e9, 0 0 10px 1px #d1d1d1',
      clipPath: 'inset(0px -15px 0px 0)',
      transition: 'box-shadow 0.3s ease-in-out',

      '&:empty': {
        boxShadow: 'unset !important',
      },
      '& span, & div': {
        '&:last-child': {
          borderRight: 0,
        },
        '&:first-child': {
          borderLeft: 0,
        },
      },
    },
    '& .pinnedRight': {
      clipPath: 'inset(0px 0 0px -15px)',
    },
    // These styles will be removed once
    // the table is moved to its own repo
    '& .MuiCheckbox-root, & .MuiIconButton-colorSecondary, & .Mui-checked': {
      '& .MuiSvgIcon-fontSizeSmall': {
        fontSize: '1.10rem !important',
      },
      '&:hover': {
        backgroundColor: 'transparent !important',
      },
    },
    '& .Mui-checked': {
      color: '#1890FF',
      '& span, & .MuiSvgIcon-root': {
        fill: '#1890FF',
        color: '#1890FF',
      },
    },
    '& .tableHeader': {
      position: 'sticky',
      top: 0,
      zIndex: 12,
      display: 'flex',
      flex: '0 1 auto',
      flexDirection: 'row',
      color: '#000',
      fontWeight: 500,
      backgroundColor: '#f1f1f1',
      height: 35,
      boxShadow: '0px 0px 9px -2px rgba(0, 0, 0, 0.34)',
      transition: 'box-shadow 0.3s ease-in-out',

      [theme.breakpoints.down('sm')]: {
        display: 'none',
      },

      '& .pinned': {
        boxShadow: '0 0 0 1px #dddddd, 0 0 12px 3px #d3d3d3',
        transition: 'box-shadow 0.3s ease-in-out',

        '&:empty': {
          boxShadow: 'unset !important',
        },
      },
    },
    '& .tableLoader': {
      position: 'absolute',
      top: 0,
      right: 0,
      bottom: 0,
      left: 0,
      zIndex: 13,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      backgroundColor: 'rgba(241, 241, 241, 0.65)',
    },
  },
  tableCheckbox: {
    display: 'flex !important',
    padding: 0,
    [theme.breakpoints.down('sm')]: {
      height: 39,
    },
  },
  tableContainerMobile: {
    border: 0,
    flexDirection: 'row',
    height: 'auto',

    '& .MuiAccordion-root': {
      display: 'grid',
      width: '100%',
      backgroundColor: 'inherit !important',
      borderColor: 'inherit !important',
    },
    '& .MuiAccordionSummary-root': {
      margin: 0,
      display: 'flex',
      justifyContent: 'flex-start',
      width: '100%',
      flex: 'none',
      padding: '0 16px 0 10px',

      '& .MuiAccordionSummary-content': {
        margin: 0,
        padding: 0,
      },
    },
    '& .detailsRow': {
      display: 'grid',
      '& span:first-child': {
        width: '100%',
      },
      '& span': {
        minWidth: 'fit-content',
      },
      '& a': {
        minWidth: 'fit-content',
      },
    },
    '& .pinned': {
      background: 'transparent !important',
      boxShadow: 'unset !important',
    },
  },
  tableCellMobile: {
    display: 'flex !important',
    width: '100% !important',
    maxWidth: '100% !important',
    justifyContent: 'space-between',
    borderTop: '1px solid #eaeaea',
    textAlign: 'left',

    '& span:first-child': {
      fontWeight: 500,
    },
  },
  tableCellMobileTitle: {
    lineHeight: '40px',
    width: 'fit-content !important',
    padding: 0,
    '& .floating-editing-container': {
      height: 'unset !important',
    },
    '& button': {
      display: 'none',
    },
  },
  tableRowMobileTitle: {
    width: '90%',
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    maxHeight: '37px',
    overflow: 'hidden',
    gap: '10px',

    '& .pinned': {
      gap: '10px',
    },
    '& .floating-editing-container': {
      display: 'contents',
      '& button': {
        display: 'none',
      },
    },
  },
  tableContainerFitContent: {
    width: 'fit-content',
    maxWidth: '100%',
    borderRightWidth: 0,
  },
  table: {
    flex: 1,
  },
  hideLeftShadows: {
    '& .pinnedLeft, & .tableHeader.pinnedLeft': {
      boxShadow: 'unset !important',
    },
  },
  hideTopShadows: {
    '& .tableHeader': {
      boxShadow: 'unset !important',
    },
  },
  hideRightShadows: {
    '& .pinnedRight': {
      boxShadow: 'unset !important',
    },
  },
  tooltip: {
    fontSize: '0.875rem !important',
  },
  cellTooltip: {
    margin: 0,
    fontSize: '0.875rem !important',
  },
}))(Table) as new <T, K extends DataId>(props: Props<T, K>) => Table<T, K>;

export type TableRef<T, K extends DataId> = Table<T, K>;
