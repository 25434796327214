import { DocumentTemplateStatus } from '@/domain/document-templates';
import EntityTable from '@/notmagic/components/EntityTable';
import type { RowActionType } from '@/notmagic/components/EntityTable/types';
import metadata from '@/notmagic/metadata/tables/document-templates.json';
import { DocumentTemplatesService } from '@/services/document-templates';
import { FileService } from '@/services/files';
import { handleError } from '@/utils/utils';
import { DeleteFilled, DownloadOutlined, EditOutlined, SearchOutlined } from '@ant-design/icons';
import { Button, message, Popconfirm } from 'antd';
import { useState } from 'react';
import { ModalFormButton } from '../Button/formButton';
import AddDocumentTemplate from './AddDocumentTemplate';
import DocumentTemplatePreviewModal from './DocumentTemplatePreviewModal';
import EditDocumentTemplate from './EditDocumentTemplate';

const PreviewTemplate: RowActionType = ({ row }) => {
  return (
    <ModalFormButton icon={<SearchOutlined />} buttonType="link" key="previewTemplate">
      {(props) => (
        <DocumentTemplatePreviewModal
          {...props}
          documentTemplateName={row.name}
          documentTemplateDescription={row.description}
          templateIds={[row.id]}
        />
      )}
    </ModalFormButton>
  );
};

const DownloadTemplate: RowActionType = ({ row: documentTemplate }) => {
  const [loading, setLoading] = useState(false);
  return (
    <Button
      disabled={!documentTemplate.fileId}
      type="link"
      onClick={() => {
        setLoading(true);
        FileService.getDownloadLink(documentTemplate.fileId)
          .then((link) => window.open(link, '_blank'))
          .catch((error) => {
            handleError(error, {
              toastFallbackMessage: 'There was an error getting the download link',
            });
          })
          .finally(() => setLoading(false));
      }}
      loading={loading}
    >
      <DownloadOutlined />
    </Button>
  );
};

const ArchiveTemplate: RowActionType = ({ row: documentTemplate, validateEntity }) => {
  const [loading, setLoading] = useState(false);
  return (
    <Popconfirm
      title="Are you sure you want to archive this document template?"
      onConfirm={() => {
        setLoading(true);
        DocumentTemplatesService.update({
          ...documentTemplate,
          status: DocumentTemplateStatus.ARCHIVED,
        })
          .then(() => {
            message.info(`Document template archived`);
            validateEntity();
          })
          .catch((error) => {
            handleError(error, {
              toastFallbackMessage: 'There was an error updating the document template',
            });
          })
          .finally(() => setLoading(false));
      }}
      okText="Yes"
      cancelText="No"
    >
      <Button
        type="link"
        disabled={documentTemplate.status === DocumentTemplateStatus.ARCHIVED}
        loading={loading}
        danger
      >
        <DeleteFilled />
      </Button>
    </Popconfirm>
  );
};

const EditTemplate: RowActionType = ({ row }) => {
  return (
    <ModalFormButton icon={<EditOutlined />} buttonType="link" key="previewTemplate">
      {(props) => (
        <EditDocumentTemplate
          {...props}
          documentTemplateTitle={row.name}
          documentTemplateId={row.id}
        />
      )}
    </ModalFormButton>
  );
};

export default () => (
  <EntityTable
    table={metadata}
    globalActions={[
      {
        label: 'Add Document Template',
        primary: true,
        Component: AddDocumentTemplate,
      },
    ]}
    rowActions={[PreviewTemplate, DownloadTemplate, EditTemplate, ArchiveTemplate]}
  />
);
