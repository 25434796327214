import type { FC } from 'react';

type Props = {
  html: string;
  className?: string;
};

const RawHtml: FC<Props> = ({ html, className }) => {
  return (
    <div
      className={className}
      // eslint-disable-next-line react/no-danger
      dangerouslySetInnerHTML={{ __html: html || '' }}
    />
  );
};

export default RawHtml;
