import { ModalFormButton } from '@/components/Button/formButton';
import EditablePage from '@/components/Formik/EditablePage';
import type { LeaseSearchResult } from '@/domain/lease';
import { ChargeFrequency } from '@/domain/lease';
import type { RenewalDTO } from '@/domain/renewal';
import { RenewalStatus } from '@/domain/renewal';
import useEnumeration from '@/hooks/useEnumeration';
import { RenewalService } from '@/services/renewal';
import { enumToLabel, handleError } from '@/utils/utils';
import { formatCurrency } from '@propify/components';
import { message } from 'antd';
import sumBy from 'lodash/sumBy';
import type { FC } from 'react';
import { useMemo, useState } from 'react';
import ApplicationsListModal from '../../components/ApplicationsListModal/ApplicationsListModal';

interface Props {
  renewal: RenewalDTO;
  lease: LeaseSearchResult | undefined;
  totalValidatedIncome: number | undefined;
  rentToVerifiedIncome: number | undefined;
  onRenewalUpdate: () => void;
}

const RenewalsOverview: FC<Props> = ({
  renewal,
  lease,
  totalValidatedIncome,
  rentToVerifiedIncome,
  onRenewalUpdate,
}) => {
  const [removingApplication, setRemovingApplication] = useState(false);
  const { data: renewalStatus } = useEnumeration('renewal-status');

  const currentMonthlyCharges = useMemo(
    () =>
      lease?.charges?.filter(
        (c: { frequency: ChargeFrequency }) => c.frequency === ChargeFrequency.MONTHLY,
      ),
    [lease],
  );

  const newMonthlyCharges = useMemo(
    () =>
      renewal.options
        .flatMap((o) => o.charges)
        .filter((c) => c.frequency === ChargeFrequency.MONTHLY),
    [renewal.options],
  );

  const handleApplicationRemoval = (applicationId: number) => {
    setRemovingApplication(true);
    RenewalService.deleteApplication(renewal.id, applicationId)
      .then(() => {
        message.success('Invitation successfully rescinded');
        onRenewalUpdate();
      })
      .catch((error) =>
        handleError(error, {
          toastFallbackMessage: 'Something went wrong rescinding the invitation',
        }),
      )
      .finally(() => {
        setRemovingApplication(false);
      });
  };

  const outstandingInvitations = renewal.applications.filter(
    (a) => a.type !== 'PRIMARY' && a.status === 'PENDING',
  );

  return (
    <EditablePage
      fullWidth
      singleColumn
      sections={[
        {
          title: 'Renewal Overview',
          type: 'EDITABLE_ATTRIBUTES',
          breakAfter: false,
          attributes: [
            {
              value: renewalStatus.find((rs) => rs.value === renewal.status)?.displayName || '',
              fieldName: 'status',
              label: 'Status',
              type: 'Text',
              readOnly: true,
              colSize: 6,
            },
            {
              value: lease?.endDate,
              fieldName: 'endDate',
              label: 'Current Lease End Date',
              type: 'Date',
              readOnly: true,
              colSize: 6,
            },
            {
              value: renewal?.startDate,
              fieldName: 'startDate',
              label: 'New Lease Start Date',
              type: 'Date',
              readOnly: true,
              colSize: 6,
            },
            {
              value: formatCurrency(sumBy(currentMonthlyCharges, 'amount')),
              fieldName: 'currentMonthlyTotal',
              label: 'Current Lease Monthly Total',
              type: 'Text',
              readOnly: true,
              colSize: 6,
            },
            {
              value:
                renewal?.status === RenewalStatus.ACCEPTED
                  ? formatCurrency(sumBy(newMonthlyCharges, 'amount'))
                  : '',
              fieldName: 'newMonthlyTotal',
              label: 'New Lease Monthly Total',
              type: 'Text',
              readOnly: true,
              colSize: 6,
            },
            {
              value: enumToLabel(lease?.type || ''),
              fieldName: 'type',
              label: 'Type',
              type: 'Text',
              readOnly: true,
              colSize: 6,
            },
            {
              value: formatCurrency(totalValidatedIncome),
              fieldName: 'verified',
              label: 'Total Verified Monthly Income',
              type: 'Text',
              readOnly: true,
              colSize: 6,
            },
            {
              value: (rentToVerifiedIncome || 0).toFixed(2),
              fieldName: 'income',
              label: 'Rent to Monthly Verified Income',
              type: 'Text',
              readOnly: true,
              colSize: 6,
              labelTooltip:
                'This is the ratio of the current Base Rent of the unit to Total Monthly Verified Income.',
            },
            {
              value: (
                <>
                  {outstandingInvitations.length || 0}
                  {' - '}
                  <ModalFormButton buttonText="Details" buttonType="link">
                    {(props) => (
                      <ApplicationsListModal
                        {...props}
                        type="renewal"
                        applications={outstandingInvitations}
                        onApplicationRemove={handleApplicationRemoval}
                        submitting={removingApplication}
                      />
                    )}
                  </ModalFormButton>
                </>
              ),
              fieldName: 'invitations',
              label: 'Outstanding Invitations',
              type: 'Text',
              readOnly: true,
              colSize: 6,
            },
            {
              value: lease?.occupants?.length || 0,
              fieldName: 'occupants',
              label: 'Additional Occupants',
              type: 'Text',
              readOnly: true,
              colSize: 6,
            },
          ],
        },
      ]}
    />
  );
};

export default RenewalsOverview;
