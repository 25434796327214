// import { evaluateJSFromString } from '..';

export const cleanKeyView = (key: string) => {
  return key?.replaceAll('$', 'row.').replaceAll('].', ']?.').replaceAll('.', '?.');
};
export const rowJSViewGetter = (code: string, row: any) => {
  if (!code) {
    return;
  }

  const fn = new Function('row', `return row.${cleanKeyView(code)}`);

  return fn(row);
};

export const linkJSViewGetter = (code: string, row: any) => {
  if (!code) {
    return;
  }

  const fn = new Function('row', `return \`${code.replaceAll('.', '?.')}\``);

  return fn(row)?.replaceAll('?.', '.');
};

export type Constraint = Record<string, any>;
// The row and the value can be literally anything,
export type Row = any;
export type Value = any;
export type Getter = (row: Row, key: string) => any;

export interface MangoMapReduceInterface {
  aggregations: (row: Row, query: any) => Row[];
}

export interface MangoViewOperators {
  // Mango Map Reduce views
  // $key and $concat support for now
  // with $sum, $max and other operators
  // coming soon

  // $key properties
  // is for JS views, to be used like:
  // $key: 'keyInArray',
  // for example:
  // $key: 'reviewDueDate',
  // or
  // $key: 'userIds.length'
  $key?: string;
  // $concat is for merging
  // one or more properties
  // and can be used like:

  // $concat: ['firstName', 'lastName'],

  // or with properties for a custom column
  // like an icon
  // $concat: [
  // 'firstName',
  // 'lastName',
  // {
  //  $icon: 'star',
  //  show: {
  //   // Mango queries for filtering if show this custom column:
  //   status: 'APPROVED' }
  // }]
  $concat?: string[] | any[] | Record<string, any[]>;
  $cb?: string;
}
