import type {
  Application,
  ApplicationFee,
  ApplicationFile,
  ApplicationFileBulkCreateRequest,
  ApplicationResolutionForApproveRequest,
  ApplicationResolutionForDenyRequest,
  ApplicationSearchParams,
  ApplicationSearchResult,
} from '@/domain/applications';
import { api } from '@/utils/configuration';
import { crudService, getAxiosFetcher } from '@/utils/request';
import type { AxiosRequestConfig } from 'axios';
import type { Operation } from 'fast-json-patch';

export const ApplicationsService = {
  search: getAxiosFetcher<ApplicationSearchResult[], [ApplicationSearchParams?]>((params) => [
    api.APPLICATIONS,
    { params },
  ]),

  findById: getAxiosFetcher<Application, [id: number]>((id) => [`${api.APPLICATIONS}/${id}`]),

  update: (id: number, body: Application) =>
    crudService.put<Application>(body, `${api.APPLICATIONS}/${id}`),

  patch: (id: number, operations: Operation[], version: number) =>
    crudService.patch<Application>(operations, `${api.APPLICATIONS}/${id}?version=${version}`),

  approve: (
    applicationId: number,
    version: number,
    request: ApplicationResolutionForApproveRequest,
  ) =>
    crudService.post<Application>(
      request,
      `${api.APPLICATIONS}/${applicationId}/approve?version=${version}`,
    ),

  deny: (applicationId: number, version: number, request: ApplicationResolutionForDenyRequest) =>
    crudService.post<Application>(
      request,
      `${api.APPLICATIONS}/${applicationId}/deny?version=${version}`,
    ),

  startReview: (applicationId: number, version: number) =>
    crudService.post<Application>(
      {},
      `${api.APPLICATIONS}/${applicationId}/start-review?version=${version}`,
    ),

  createFile: (applicationId: number, body: Pick<ApplicationFile, 'type' | 'sendEmail'>) =>
    crudService.post<ApplicationFile>(body, `${api.APPLICATIONS}/${applicationId}/files`),

  updateFile: (applicationId: number, file: ApplicationFile) =>
    crudService.put<ApplicationFile>(file, `${api.APPLICATIONS}/${applicationId}/files/${file.id}`),

  sendFileRequestReminder: (applicationId: number, id: number) =>
    crudService.post<void>({}, `${api.APPLICATIONS}/${applicationId}/files/${id}/send-reminder`),

  deleteFileRequest: (applicationId: number, id: number) =>
    crudService.delete<void>(`${api.APPLICATIONS}/${applicationId}/files/${id}`),

  submitFile: (applicationId: number, fileId: number, file: File, config: AxiosRequestConfig) => {
    const formData = new FormData();
    formData.append('file', file);
    return crudService.post<void>(
      formData,
      `${api.APPLICATIONS}/${applicationId}/files/${fileId}/submit-file`,
      config,
    );
  },

  bulkFile: (applicationId: number, body: ApplicationFileBulkCreateRequest) =>
    crudService.post<void>(body, `${api.APPLICATIONS}/${applicationId}/files/bulk`),

  getMinApplicationFee: getAxiosFetcher<ApplicationFee, [ApplicationSearchParams?]>(() => [
    `${api.APPLICATIONS}/min-application-fee`,
  ]),

  getApplicationFee: getAxiosFetcher<ApplicationFee, []>(() => [
    `${api.APPLICATIONS}/application-fee`,
  ]),

  updateApplicationFee: (fee: ApplicationFee) =>
    crudService.put<void>(fee, `${api.APPLICATIONS}/application-fee`),
};
