import type { EntityType } from '@/domain/entity-type';
import type { File } from '@/domain/file';
import type { ListingImage } from '@/domain/property';
import { api } from '@/utils/configuration';
import { crudService, getAxiosFetcher } from '@/utils/request';
import type { AxiosRequestConfig } from 'axios';

interface FileSearchParams {
  startTime?: string;
  endTime?: string;
  entityType?: EntityType;
  entityId?: number;
}

export const FileService = {
  findById: getAxiosFetcher<File, [id: number]>((id) => [`${api.FILE}/${id}`]),

  search: getAxiosFetcher<File[], [FileSearchParams]>((params) => [api.FILE, { params }]),

  create: (
    request: any,
    stream: any,
    onUploadProgress?: AxiosRequestConfig['onUploadProgress'],
  ) => {
    const formData = new FormData();
    formData.append('file', stream);
    formData.append('request', new Blob([JSON.stringify(request)], { type: 'application/json' }));

    return crudService.post<File>(
      formData,
      api.FILE,
      onUploadProgress ? { onUploadProgress } : undefined,
    );
  },

  createPropertyImage: (propertyId: number, file: Blob, json?: unknown) => {
    const formData = new FormData();
    formData.append('file', file);
    if (json !== undefined) {
      formData.append('json', new Blob([JSON.stringify(json)], { type: 'application/json' }));
    }

    return crudService.post<ListingImage>(formData, `${api.PROPERTIES}/${propertyId}/images`);
  },

  delete: (id: number) => crudService.delete<void>(`${api.FILE}/${id}`),

  getDownloadLink: getAxiosFetcher<string, [number]>((fileId) => [`${api.FILE}/${fileId}/url`]),

  getDownloadLinks: getAxiosFetcher<Record<number, string>, [number[]]>((fileIds) => [
    `${api.FILE}/url`,
    { params: { id: fileIds } },
  ]),
};

export const downloadFileFromURL = getAxiosFetcher<Blob, [string]>((url) => [
  url,
  {
    responseType: 'blob',
    headers: {
      Accept: 'application/octet-stream',
      'Content-Type': 'application/octet-stream',
      // When requesting images, without these headers Chrome attempts to use the cache
      // and it does not store in the cache the cors headers from the original response (when we render an image preview)
      // resulting in a CORS Error
      'Cache-Control': 'no-cache',
      Pragma: 'no-cache',
      Expires: '0',
    },
  },
]);
