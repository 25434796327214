import type { RenewalDTO } from '@/domain/renewal';
import type { Vehicle } from '@/domain/unit-applications';
import { RenewalService } from '@/services/renewal';
import { handleError } from '@/utils/utils';
import { DeleteOutlined } from '@ant-design/icons';
import { Button, Popconfirm } from 'antd';
import type { FC } from 'react';
import { useState } from 'react';
import VehiclesInformation from '../../unit-applications/details/VehiclesInformation';

interface Props {
  renewal: RenewalDTO;
  refetch: () => void;
}

const RenewalVehiclesWrapper: FC<Props> = ({ renewal, refetch }) => {
  const [loading, setLoading] = useState(false);

  const onDelete = async (vehicle: Vehicle) => {
    setLoading(true);
    try {
      await RenewalService.deleteVehicle(renewal.id, vehicle.id);
      refetch();
    } catch (error) {
      handleError(error, { toastFallbackMessage: 'There was an error deleting the vehicle' });
    } finally {
      setLoading(false);
    }
  };

  const vehicleActions = (vehicle: Vehicle) => (
    <div>
      <Popconfirm
        title="Are you sure?"
        onConfirm={() => onDelete(vehicle)}
        okText="Yes"
        cancelText="No"
        okButtonProps={{
          loading,
        }}
      >
        <Button icon={<DeleteOutlined />} type="link" danger />
      </Popconfirm>
    </div>
  );

  return <VehiclesInformation vehicles={renewal.vehicles} renderActions={vehicleActions} />;
};

export default RenewalVehiclesWrapper;
