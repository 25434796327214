import type { Column } from '@/@propify-components';
import { booleanColumn, Table } from '@/@propify-components';
import EmptyState from '@/components/EmptyState/EmptyState';
import { enumColumn } from '@/components/TableUtils';
import type { Pet } from '@/domain/unit-applications';
import { settings } from '@/services/settings';
import { enumToLabel } from '@/utils/utils';
import isEmpty from 'lodash/isEmpty';
import type { CSSProperties, FC } from 'react';

interface Props {
  pets: Pet[];
  renderActions?: (v: Pet) => void;
  style?: CSSProperties;
}

const PetInformation: FC<Props> = ({ pets, renderActions, style }) => {
  const columns: Column<Pet>[] = [
    {
      key: 'id',
      title: 'ID',
    },
    {
      key: 'name',
      title: 'Name',
    },
    enumColumn({ title: 'Gender', key: 'sex' }, { enumName: 'pet-sex' }),
    {
      key: 'type',
      title: 'Type',
      render: (row) => enumToLabel(row.type),
    },
    {
      key: 'weight',
      title: 'Weight (lbs.)',
    },
    {
      key: 'age',
      title: 'Age',
    },
    {
      key: 'color',
      title: 'Color',
    },
    booleanColumn({ title: 'Service Animal', key: 'serviceAnimal' }),
    booleanColumn({ title: 'Spayed/Neutered', key: 'spayedNeutered' }),
    {
      key: 'breed',
      title: 'Breed',
    },
    ...(renderActions
      ? [
          {
            title: '',
            key: 'actions',
            width: 100,
            render: (pet: Pet) => renderActions(pet),
          },
        ]
      : []),
  ];

  if (isEmpty(pets)) {
    return <EmptyState description="No pets yet" />;
  }

  return (
    <Table
      data={pets}
      className="full-height"
      columns={columns}
      rowKeyExtractor={(row) => row.id}
      settings={settings('unit-application-pet-information')}
      style={style}
    />
  );
};

export default PetInformation;
