import type { PropifyEntity } from '@/domain/base';
import type { CollectionPayment } from '@/domain/resident-collection';
import { api } from '@/utils/configuration';
import { crudService, getAxiosFetcher } from '@/utils/request';

export interface CollectionPaymentSearchParams {
  collectionId?: number;
  residentId?: number;
}

const basePath = (collectionId: number) => `${api.RESIDENT_COLLECTIONS}/${collectionId}/payments`;

export const CollectionPaymentsService = {
  search: getAxiosFetcher<CollectionPayment[], [number, CollectionPaymentSearchParams]>(
    (collectionId, params = {}) => [basePath(collectionId), { params }],
  ),

  findById: getAxiosFetcher<CollectionPayment, [number, number]>((collectionId, id) => [
    `${basePath(collectionId)}/${id}`,
  ]),

  create: (
    collectionId: number,
    body: Omit<CollectionPayment, keyof PropifyEntity | 'collectionId'>,
  ) => crudService.post<CollectionPayment>(body, basePath(collectionId)),

  delete: (collectionId: number, collectionNextPaymentId: number) =>
    crudService.delete<void>(`${basePath(collectionId)}/${collectionNextPaymentId}`),
};
