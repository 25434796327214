import type { Column } from '@/@propify-components';
import type { EntityType } from '@/notmagic/types';
import type { ReactElement } from 'react';
import { Link } from 'react-router-dom';
import type { ColumnType, TableColumn } from '../types';

export const mapColumn = (
  columnTypes: Record<string, ColumnType>,
  column: TableColumn,
  entityType: EntityType,
  refreshEntities: () => void,
  onValidateEntity: (id: number) => void,
  updateColumn: (row: any, key: string, newValue: any) => Promise<any>,
): Column => {
  const type = column.type || 'GENERIC';

  const wrapWithLink = (to: string, content: ReactElement) => {
    if (to) {
      if (to.trim().startsWith('http')) {
        return (
          <a target="_blank" rel="noopener noreferrer" href={to}>
            {content}
          </a>
        );
      }

      return <Link to={to}>{content}</Link>;
    }

    return content;
  };

  const styles = (row: any) =>
    row[`${column.key}$color`]
      ? {
          color: row[`${column.key}$color`],
        }
      : {};

  if (!columnTypes[type]) {
    // eslint-disable-next-line no-console
    console.error(`Couldn't find entity type "${type}"`);
  }

  const { Component, sort, hideSort, getValueForExport, align } = columnTypes[type];

  return {
    title: column.title,
    key: column.key,
    width: column.width,
    align,
    hideSort,
    styles,
    render: (row: any) => {
      const children = (
        <Component
          {...column.configuration}
          value={row[column.key]}
          textIfNull={column.textIfNull}
          displayName={row[column.key + '$displayName']}
          update={(newValue: any) => updateColumn(row, column.key, newValue)}
          rootEntityType={entityType}
          rootEntityId={row.id}
          onValidateEntity={() => onValidateEntity(row.id)}
          refreshEntities={refreshEntities}
          editable={!!row[column.key + '$editable']}
        />
      );

      return column.link ? wrapWithLink(row[`${column.key}$link`], children) : children;
    },
    sortFn: sort
      ? (a: any, b: any) =>
          sort(
            a[column.key + '$displayName'] ?? a[column.key],
            b[column.key + '$displayName'] ?? b[column.key],
          )
      : undefined,
    getValueForExport: (row: any) =>
      getValueForExport
        ? getValueForExport(row[column.key + '$displayName'] ?? row[column.key])
        : row[column.key + '$displayName'] ?? row[column.key],
  };
};
