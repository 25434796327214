import type { CreditRecommendation } from '@/domain/applications';
import type {
  ColumnComponentType,
  ColumnComponentTypeProps,
} from '@/notmagic/components/EntityTable/types';
import ApplicationRecommendations from '@/pages/prospects/unit-applications/ApplicationRecommendations';

type RowType = {
  creditRecommendation: CreditRecommendation;
  criminalRecommendation: boolean;
  backgroundRecommendation: boolean;
};

type Props = ColumnComponentTypeProps<RowType>;

const Component: ColumnComponentType<Props> = ({ value }) => {
  // Must set `"value": {props in RowType}` in metadata
  if (!value) {
    return null;
  }

  return (
    <ApplicationRecommendations
      credit={value.creditRecommendation}
      criminal={value.criminalRecommendation}
      background={value.backgroundRecommendation}
      singleApplication
    />
  );
};

export default {
  Component,
  getValueForExport: (row: any) =>
    [
      row.creditRecommendation ? `Credit: ${row.creditRecommendation}` : null,
      row.criminalRecommendation ? `Criminal: ${row.criminalRecommendation}` : null,
      row.backgroundRecommendation ? `Background: ${row.backgroundRecommendation}` : null,
    ]
      .filter((v) => v)
      .join(', '),
};
