import EnumValue from '@/components/TableUtils/EnumValue';
import type {
  ColumnComponentType,
  ColumnComponentTypeProps,
} from '@/notmagic/components/EntityTable/types';
import { ToolOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';

type RowType = {
  jobId: number;
  jobType: string;
  unitHasOtherJobs: number;
};

type Props = ColumnComponentTypeProps<RowType>;

const Component: ColumnComponentType<Props> = ({ value }) => {
  // Must set `"value": {props in RowType}` in metadata
  if (!value?.jobId) {
    return null;
  }

  return (
    <>
      <Link to={`/maintenance/jobs/${value.jobId}`}>
        {value.jobId} - <EnumValue enumName="job-type" value={value.jobType} />
      </Link>

      {value.unitHasOtherJobs ? (
        <ToolOutlined style={{ fontSize: '16px', color: '#08c', marginLeft: '5px' }} />
      ) : (
        ''
      )}
    </>
  );
};

export default {
  Component,
};
