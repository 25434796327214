import type { Note, NoteUserMentioned, NoteWithMentions } from '@/domain/note';
import type { UserLookup } from '@/domain/user';
import isEmpty from 'lodash/isEmpty';
import uniq from 'lodash/uniq';

export const getMentionedUserIds = (note: string) => {
  return (note.match(/(?=^|\W)@\d+(?=$|\W)/g) || []).map((tag) => Number(tag.slice(1)));
};

export const getMentionedUsers = (note: string, allUsers: UserLookup[]): NoteUserMentioned[] => {
  const mentionedUserIds = getMentionedUserIds(note);
  let mentionedUsers: NoteUserMentioned[] = [];
  if (mentionedUserIds.length > 0) {
    mentionedUsers = allUsers
      .filter((user) => mentionedUserIds.includes(user.id))
      .map((user) => ({ id: user.id, value: user.displayName }));
  }

  return mentionedUsers;
};

export const replaceMentionedUserNamesByIds = (
  noteWithNames: string,
  usersMentioned: NoteUserMentioned[],
): string => {
  let noteWithIds = noteWithNames;

  if (!isEmpty(usersMentioned)) {
    usersMentioned.forEach((userMentioned) => {
      noteWithIds = noteWithIds.replaceAll(
        new RegExp(`(\\W|^)@${userMentioned.value}($|\\W)`, 'g'),
        `$1@${userMentioned.id}$2`,
      );
    });
  }
  // eslint-disable-next-line no-control-regex
  return noteWithIds.replaceAll(/[^\x00-\x7F]/g, '');
};

export const replaceMentionedUserIdsByNames = (note: string, users: NoteUserMentioned[]) => {
  let noteContent = note;
  users.forEach((user) => {
    noteContent = noteContent.replaceAll(
      new RegExp(`(\\W|^)@${user.id}($|\\W)`, 'g'),
      `$1@${user.value}$2`,
    );
  });
  return noteContent;
};

export const getNotesWithReplacedMentionedUserIds = (
  notes: Note[],
  allUsers: UserLookup[],
): NoteWithMentions[] => {
  const mentionedUserIds = uniq(notes.map((note: Note) => getMentionedUserIds(note.note)).flat());

  const users = allUsers
    .filter((user) => mentionedUserIds.includes(user.id))
    .map((user) => ({ id: user.id, value: user.displayName }));

  return notes.map((note: Note) => {
    return {
      ...note,
      displayNote: replaceMentionedUserIdsByNames(note.note, users),
    };
  });
};
