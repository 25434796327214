import React from 'react';
import { SortableHandle } from 'react-sortable-hoc';
import { SortRow } from '../../shared/utils/icons';
import type { ClassNameMap } from '../../shared/utils/material';
import type { Column } from '../index';

interface Props<T> {
  columns: Column<T>[];
  renderContainer: (children: any) => React.ReactElement;
  isSelectable: boolean;
  singleSelection?: boolean;
  isDraggable: boolean;
  renderCheckbox?: () => React.ReactElement | null;
  renderRadioButton?: () => React.ReactElement | null;
  renderTooltip?: null | (() => React.ReactElement);
  renderSortIcon?: null | (() => React.ReactElement);
  renderCell: (
    column: Column<T>,
    index: number,
    pinned: boolean,
    collection: number,
    showPin?: boolean,
  ) => any;
  pinnedColumns: string[];
  responsive?: boolean;
  styles: ClassNameMap<string>;
}

export const getPinColumnsData = (allColumns: Column[], pinnedColumns: string[]) => {
  const columnsPinnedToTheLeft: Column[] = [];
  const nonPinnedColumns: Column[] = [];
  const columnsPinnedToTheRight: Column[] = [];

  allColumns.forEach((column) => {
    if (pinnedColumns.includes(column.key)) {
      if (nonPinnedColumns.length === 0) {
        columnsPinnedToTheLeft.push(column);
      } else {
        columnsPinnedToTheRight.push(column);
      }
    } else {
      nonPinnedColumns.push(column);
    }
  });

  return {
    columnsPinnedToTheLeft,
    nonPinnedColumns,
    columnsPinnedToTheRight,
  };
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const SortHandle = SortableHandle(({ styles }: any) => {
  return (
    <span
      className={`${styles.tableCell} ${styles.tableDragDropCell} rowHandle`}
      data-test="tableSortHandle"
    >
      <SortRow className="rowHandle" />
    </span>
  );
});

const StickyColumns = <T extends any>({
  columns,
  pinnedColumns,
  renderContainer,
  renderCell,
  renderCheckbox,
  renderRadioButton,
  renderTooltip,
  isSelectable,
  singleSelection,
  isDraggable,
  renderSortIcon = null,
  styles,
}: Props<T>) => {
  const renderChildren = () => {
    const { columnsPinnedToTheLeft, nonPinnedColumns, columnsPinnedToTheRight } = getPinColumnsData(
      columns,
      pinnedColumns,
    );

    const renderSortHandler = () => {
      if (typeof renderSortIcon === 'function') {
        return renderSortIcon();
      }
      return <SortHandle styles={styles} />;
    };
    return (
      <>
        <div
          style={{
            left: 0,
          }}
          className={`${styles.tableStickyColumn} ${styles.phoenixStickyColumn} pinned pinnedLeft`}
          data-test="tableColumnsPinnedLeft"
          role="group"
        >
          {isDraggable && renderSortHandler()}
          {isSelectable && !singleSelection && renderCheckbox && renderCheckbox()}
          {isSelectable && singleSelection && renderRadioButton && renderRadioButton()}
          {renderTooltip?.()}
          {columnsPinnedToTheLeft.map((column, index) => renderCell(column, index, true, 0, true))}
        </div>
        {nonPinnedColumns.map((column, index) =>
          renderCell(column, index, false, 1, nonPinnedColumns.length === index + 1 || index === 0),
        )}
        <div
          style={{
            right: 0,
          }}
          className={`${styles.tableStickyColumn} ${styles.phoenixStickyColumn} pinned pinnedRight`}
          data-test="tableColumnsPinnedRight"
          role="rowgroup"
        >
          {columnsPinnedToTheRight.map((column, index) => renderCell(column, index, true, 2, true))}
        </div>
      </>
    );
  };
  return renderContainer(renderChildren());
};

export default StickyColumns;
