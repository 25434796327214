import CompleteTaskModal from '@/components/tasks/CompleteTaskModal';
import type { Address } from '@/domain/address';
import type { ProcessType } from '@/domain/process';
import type {
  ColumnComponentType,
  ColumnComponentTypeProps,
  ColumnType,
} from '@/notmagic/components/EntityTable/types';
import { Button } from 'antd';
import { useState } from 'react';

type RowType = {
  id: number;
  name: string;
  noteRequired: boolean;
  address: Address | undefined;
  processId: number;
  processType: ProcessType;
};

const Component: ColumnComponentType<ColumnComponentTypeProps<RowType>> = ({
  value,
  refreshEntities,
}) => {
  const [showModal, setShowModal] = useState(false);

  // Must set `"value": {props in RowType}` in metadata
  if (!value?.id) {
    return null;
  }

  return (
    <>
      <Button onClick={() => setShowModal(true)}>Complete Task</Button>

      {showModal && (
        <CompleteTaskModal
          onSuccess={() => {
            refreshEntities();
            setShowModal(false);
          }}
          onCancel={() => setShowModal(false)}
          task={value}
        />
      )}
    </>
  );
};

const Definition: ColumnType = {
  Component,
  hideSort: true,
  getValueForExport: () => '',
};

export default Definition;
