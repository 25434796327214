import { Card } from 'antd';
import classNames from 'classnames';
import isEmpty from 'lodash/isEmpty';
import type { FC, ReactNode } from 'react';
import './styles.less';

interface InfoBoxComponentProps<T = any, M = any> {
  item: T;
  additionalProps: M;
}

interface InfoBoxesCategory<T = any, M = any> {
  title: string;
  boxComponent: FC<InfoBoxComponentProps<T, M>>;
  items: T[];
  additionalProps: M;
}

export type InfoBoxesSection = {
  title: string;
  categories: InfoBoxesCategory[];
  breakAfter?: boolean;
  footer?: ReactNode;
};

interface Props {
  section: InfoBoxesSection;
  breakAfter?: boolean;
}

const InfoBoxSection: FC<Props> = ({ section }) => {
  return (
    <div
      className={classNames('grid-bluecards', 'info-box-section', {
        breakAfter: section?.breakAfter,
      })}
    >
      <Card title={section.title} bordered={false}>
        {section.categories.map(
          (category) =>
            !isEmpty(category.items) && (
              <div className="info-box-category" key={`info-box-category-${category.title}`}>
                <div className="info-box-category-title">{category.title}</div>
                <div>
                  {category.items.map((item, index) => (
                    <category.boxComponent
                      // eslint-disable-next-line react/no-array-index-key
                      key={`box-component-${index}`}
                      item={item}
                      additionalProps={category.additionalProps}
                    />
                  ))}
                </div>
              </div>
            ),
        )}
      </Card>
      {section.footer}
    </div>
  );
};

export default InfoBoxSection;
