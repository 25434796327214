import type { IconProps } from './types';

function Ellipsis(props: IconProps) {
  return (
    <svg width="18" height="4" viewBox="0 0 18 4" fill="none" {...props}>
      <circle cx="2" cy="2" r="2" fill="#1890FF" />
      <circle cx="9" cy="2" r="2" fill="#1890FF" />
      <circle cx="16" cy="2" r="2" fill="#1890FF" />
    </svg>
  );
}

export default Ellipsis;
