import type { CustomField } from '@/domain/custom-fields';
import type { CRMTicketEventData, Ticket, TicketSearchResult } from '@/domain/ticket';
import { TicketStatus } from '@/domain/ticket';
import type { User } from '@/domain/user';
import type { Effect, Subscription } from '@/models/connect';
import { CustomFieldsService } from '@/services/custom-fields';
import { TicketService } from '@/services/tickets';
import { handleError } from '@/utils/utils';
import type { MenuDataItem } from '@ant-design/pro-layout';
import isEqual from 'react-fast-compare';
import type { Reducer } from 'redux';
import type { ConnectState } from './connect.d';

export interface GlobalModelState {
  collapsed: boolean;
  customFields: CustomField[];
  tickets: TicketSearchResult[];
  ticketsContacts: MenuDataItem[];
  audioPlaying: string | undefined;
  lastTicketClaimed: Ticket | undefined;
}

export interface GlobalModelType {
  namespace: 'global';
  state: GlobalModelState;
  effects: {
    fetchCustomFields: Effect;
    fetchTickets: Effect;
    updateTicket: Effect;
    playAudio: Effect;
    audioFinished: Effect;
    fetchLastTicketClaimed: Effect;
  };
  reducers: {
    saveCustomFields: Reducer<GlobalModelState>;
    changeLayoutCollapsed: Reducer<GlobalModelState>;
    saveTickets: Reducer<GlobalModelState>;
    setTicketsContacts: Reducer<GlobalModelState>;
    setAudioPlaying: Reducer<GlobalModelState>;
    saveLastTicketClaimed: Reducer<GlobalModelState>;
  };
  subscriptions: { setup: Subscription };
}

const defaultState: GlobalModelState = {
  collapsed: false,
  customFields: [],
  tickets: [],
  ticketsContacts: [],
  audioPlaying: undefined,
  lastTicketClaimed: undefined,
};

const parseContacts = (newTickets: TicketSearchResult[], user: User) => {
  return newTickets
    .filter((ticket) => ticket.ticketClaimUserId === user.id)
    .map((ticket) => ({
      name: ticket.contact || 'Unknown',
      ticketClaimExpirationTime: ticket.ticketClaimExpirationTime,
      params: {
        ...(ticket.partyId ? { partyId: ticket.partyId } : { newContact: ticket.contact }),
      },
    }));
};

const activeTicketStatuses = [TicketStatus.OPEN, TicketStatus.WRAP_UP];

const GlobalModel: GlobalModelType = {
  namespace: 'global',

  state: defaultState,

  effects: {
    *fetchCustomFields(_, { call, put }) {
      const data = (yield call(CustomFieldsService.search)) as CustomField[];

      yield put({
        type: 'saveCustomFields',
        payload: data,
      });
    },

    *updateTicket({ payload }, { put, select }) {
      try {
        const { ticket } = payload.ticket as CRMTicketEventData;

        const tickets = (yield select(
          (state: ConnectState) => state.global.tickets,
        )) as TicketSearchResult[];

        const isPresent = ticket.id && tickets.some((t) => t.id === ticket.id);
        const activeTicket = activeTicketStatuses.includes(ticket.status);
        const newTickets = activeTicket
          ? [
              ...(isPresent ? [] : [ticket]),
              ...tickets.map((t) => (t.id === ticket.id ? ticket : t)),
            ]
          : isPresent
          ? tickets.filter((t) => t.id !== ticket.id)
          : tickets;

        yield put({
          type: 'setTicketsContacts',
          payload: parseContacts(newTickets, payload.currentUser),
        });

        yield put({
          type: 'saveTickets',
          payload: newTickets,
        });
      } catch (error) {
        handleError(error, { displayToast: false });
      }
    },

    *fetchTickets({ payload }, { call, put, select }) {
      try {
        const newTickets = (yield call(TicketService.search, {
          status: activeTicketStatuses,
        })) as TicketSearchResult[];

        const currentTickets = (yield select(
          (state: ConnectState) => state.global.tickets,
        )) as TicketSearchResult[];
        const currentContacts = (yield select(
          (state: ConnectState) => state.global.ticketsContacts,
        )) as MenuDataItem[];
        const contacts = parseContacts(newTickets, payload.currentUser);

        if (!isEqual(currentContacts, contacts)) {
          yield put({
            type: 'setTicketsContacts',
            payload: contacts,
          });
        }
        if (!isEqual(currentTickets, newTickets)) {
          yield put({
            type: 'saveTickets',
            payload: newTickets,
          });
        }
      } catch (error) {
        handleError(error, { displayToast: false });
      }
    },
    *playAudio({ payload }, { put }) {
      yield put({
        type: 'setAudioPlaying',
        payload,
      });
    },
    *audioFinished(_, { put }) {
      yield put({
        type: 'setAudioPlaying',
        payload: undefined,
      });
    },
    *fetchLastTicketClaimed({ payload }, { put }) {
      yield put({
        type: 'saveLastTicketClaimed',
        payload,
      });
    },
  },

  reducers: {
    saveCustomFields(state = defaultState, { payload }): GlobalModelState {
      return {
        ...state,
        customFields: payload,
      };
    },
    changeLayoutCollapsed(state = defaultState, { payload }): GlobalModelState {
      return {
        ...state,
        collapsed: payload,
      };
    },
    saveTickets(state = defaultState, { payload }): GlobalModelState {
      return {
        ...state,
        tickets: payload,
      };
    },
    setTicketsContacts(state = defaultState, { payload }): GlobalModelState {
      return {
        ...state,
        ticketsContacts: payload,
      };
    },
    setAudioPlaying(state = defaultState, { payload }): GlobalModelState {
      return {
        ...state,
        audioPlaying: payload || undefined,
      };
    },
    saveLastTicketClaimed(state = defaultState, { payload }): GlobalModelState {
      return {
        ...state,
        lastTicketClaimed: payload,
      };
    },
  },

  subscriptions: {
    setup({ history }): void {
      // Subscribe history(url) change, trigger `load` action if pathname is `/`
      history.listen(({ pathname, search }: any): void => {
        if (typeof window.ga !== 'undefined') {
          window.ga('send', 'pageview', pathname + search);
        }
      });
    },
  },
};

export default GlobalModel;
