import EmptyState from '@/components/EmptyState/EmptyState';
import type { Application, ApplicationSearchResult } from '@/domain/applications';
import type { RenewalDTO } from '@/domain/renewal';
import { RenewalApplicationStatus } from '@/domain/renewal';
import type { ApplicationToRemove } from '@/pages/prospects/renewals/components/RenewalsDetail';
import Applicants from '@/pages/prospects/unit-applications/details/Applicants';
import { Spin } from 'antd';
import type { FC } from 'react';
import { useCallback } from 'react';

interface Props {
  renewal: RenewalDTO;
  renewalApplications: ApplicationSearchResult[] | undefined;
  loadingRenewalApplications: boolean;
  onRemoveRenewalApplication: (applicationToRemove: ApplicationToRemove) => void;
  onRemovePrimaryApplication: () => void;
  onApplicationUpdate: (application?: Application) => void;
  onApplicationDelete?: (application: Application) => void;
  onScreeningResultUpdate: (application: ApplicationSearchResult) => void;
}

const ProspectiveResidents: FC<Props> = ({
  renewalApplications,
  loadingRenewalApplications,
  onRemoveRenewalApplication,
  renewal,
  onRemovePrimaryApplication,
  onApplicationUpdate,
  onApplicationDelete,
  onScreeningResultUpdate,
}) => {
  const isApplicationCancelled = useCallback((application: ApplicationSearchResult) => {
    return application.invitationStatus === RenewalApplicationStatus.CANCELLED;
  }, []);

  if (!renewalApplications) {
    return <EmptyState description="There are no applicants yet." />;
  }

  return (
    <Spin spinning={loadingRenewalApplications}>
      <Applicants
        onRemoveApplication={onRemoveRenewalApplication}
        applications={renewalApplications}
        renewal={renewal}
        onApplicationUpdate={onApplicationUpdate}
        onApplicationRemoved={onApplicationDelete}
        onScreeningResultUpdate={onScreeningResultUpdate}
        isApplicationCancelled={isApplicationCancelled}
        onRemovePrimaryApplication={onRemovePrimaryApplication}
      />
    </Spin>
  );
};

export default ProspectiveResidents;
