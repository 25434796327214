import { DatePicker } from 'antd';
import moment from 'moment';
import type { CSSProperties, FC } from 'react';

export const TODAY: any = {
  label: 'Today',
  value: [moment().startOf('day'), moment().endOf('day')],
};
export const YESTERDAY: any = {
  label: 'Yesterday',
  value: [moment().startOf('day').subtract(1, 'day'), moment().subtract(1, 'day')],
};
export const LAST_7_DAYS: any = {
  label: 'Last 7 Days',
  value: [moment().startOf('day').subtract(7, 'days'), moment()],
};
export const LAST_30_DAYS: any = {
  label: 'Last 30 Days',
  value: [moment().startOf('day').subtract(30, 'days'), moment()],
};
export const LAST_60_DAYS: any = {
  label: 'Last 60 Days',
  value: [moment().startOf('day').subtract(60, 'days'), moment()],
};
export const LAST_365_DAYS: any = {
  label: 'Last 365 Days',
  value: [moment().startOf('day').subtract(365, 'days'), moment()],
};
export const THIS_MONTH: any = {
  label: 'This Month',
  value: [moment().startOf('month'), moment().endOf('month')],
};
export const THIS_YEAR: any = {
  label: 'This Year',
  value: [moment().startOf('year'), moment().endOf('year')],
};

export const DEFAULT_RANGES = [TODAY, YESTERDAY, LAST_7_DAYS, LAST_30_DAYS, THIS_MONTH, THIS_YEAR];

export const SQL_TIME_FORMAT = 'YYYY-MM-DD HH:mm:ss';

export const rangeToObject: any = (ranges: any[]) =>
  ranges.reduce((obj, item) => ({ ...obj, [item.label]: item.value }), {});

export interface Props {
  onChange: any;
  ranges?: any[];
  value: any;
  picker?: 'year' | 'month' | 'week' | 'quarter' | 'date' | 'time' | undefined;
  style?: CSSProperties;
  format?: string;
}

export const DateRangePicker: FC<Props> = (props) => {
  const { RangePicker } = DatePicker;

  return (
    <RangePicker
      size="large"
      format={props.format}
      picker={props.picker}
      value={props.value}
      onChange={props.onChange}
      ranges={rangeToObject(props.ranges || DEFAULT_RANGES)}
      style={props.style}
      placeholder={['Start Date', 'End Date']}
    />
  );
};
