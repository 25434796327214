import { DateRangePicker } from '@/components/DateRangePicker';
import type { DateRange } from '@/domain/date-range';
import type {
  FilterComponentType,
  FilterComponentTypeProps,
} from '@/notmagic/components/EntityTable/types';
import { ISO_DATE_FORMAT, SHORT_DATE_FORMAT } from '@/utils/time';
import { dateRangeToQueryParam, queryParamToDateRange } from '@/utils/utils';
import moment from 'moment';

type Props = FilterComponentTypeProps;

// TODO: Support open-ended date ranges. It's not supported by DateRangePicker

const Component: FilterComponentType<Props> = ({ value, onChangeValue, editable }) => {
  if (Array.isArray(value)) {
    // eslint-disable-next-line no-console
    console.error('ERROR: Filters of type DATE_RANGE only support a single value');
    return <></>;
  }

  const parsedValue = value ? queryParamToDateRange(value) : undefined;

  if (!editable) {
    if (!parsedValue) {
      return <></>;
    }

    return <>{dateRangeToQueryParam(parsedValue, SHORT_DATE_FORMAT)}</>;
  }

  return (
    <DateRangePicker
      value={parsedValue ? parsedValue?.map((date) => moment(date)) : [undefined, undefined]}
      onChange={(newValue: any[]) => {
        onChangeValue(dateRangeToQueryParam(newValue as DateRange, SHORT_DATE_FORMAT));
      }}
      style={{ width: '100%' }}
    />
  );
};

export default {
  Component,
  jsonataQuery: (value: string, key: string) => {
    const parsedValue = queryParamToDateRange(value);
    const startMs = parsedValue?.[0]
      ? moment.utc(parsedValue![0]?.format(ISO_DATE_FORMAT)).startOf('day').valueOf()
      : undefined;
    const endMs = parsedValue?.[1]
      ? moment.utc(parsedValue![1]?.format(ISO_DATE_FORMAT)).endOf('day').valueOf()
      : undefined;

    return (
      [
        startMs ? `$toMillis($v.${key}) >= ${startMs}` : undefined,
        endMs ? `$toMillis($v.${key}) <= ${endMs}` : undefined,
      ]
        .filter((x) => x)
        .join(' and ') || 'true'
    );
  },
};
