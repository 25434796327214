import { useAppContext } from '@/contexts/AppContext';
import type { Ticket} from '@/domain/ticket';
import { TicketPriority, TicketStatus } from '@/domain/ticket';
import type { ConnectState } from '@/models/connect';
import type {
  ColumnComponentType,
  ColumnComponentTypeProps,
  ColumnType,
} from '@/notmagic/components/EntityTable/types';
import { getContactPageURL } from '@/pages/tickets/utils';
import { TicketService } from '@/services/tickets';
import { handleError } from '@/utils/utils';
import { Button } from 'antd';
import moment from 'moment';
import { useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router';

type Value = {
  personId: number;
};

const Component: ColumnComponentType<ColumnComponentTypeProps<Value>> = ({ value }) => {
  const [handlingWorkLead, setHandlingWorkLead] = useState(false);
  const navigate = useNavigate();
  const { agentNameById } = useAppContext();
  const pollingTickets = useSelector((state: ConnectState) => state.global.tickets);

  const claimedTickets = useMemo(
    () => pollingTickets.filter((t) => !!t.ticketClaimUserId && t.status === TicketStatus.WRAP_UP),
    [pollingTickets],
  );

  if (!value) {
    return null;
  }

  const personTicket = claimedTickets.find((t) => t.partyId === value.personId);

  if (personTicket?.ticketClaimUserId) {
    return <div>{agentNameById[personTicket.ticketClaimUserId]}</div>;
  }

  const handleWorkLead = async () => {
    setHandlingWorkLead(true);

    let newTicket: Ticket;

    try {
      newTicket = await TicketService.create({
        partyId: value.personId,
        status: TicketStatus.WRAP_UP,
        priority: TicketPriority.STANDARD,
        dueTime: moment().utc().add(1, 'hour').toISOString(),
      });
    } catch (error) {
      handleError(error, { toastFallbackMessage: 'There was an error creating the ticket' });
      setHandlingWorkLead(false);
      return;
    }

    try {
      await TicketService.claim(newTicket.id);
      navigate(getContactPageURL(newTicket));
    } catch (error) {
      handleError(error, { toastFallbackMessage: 'There was an error claiming the ticket' });
      setHandlingWorkLead(false);
    }
  };

  return (
    <Button loading={handlingWorkLead} type="primary" onClick={handleWorkLead}>
      Work lead
    </Button>
  );
};

const Definition: ColumnType = {
  Component,
  // getValueForExport: (value: Value) => 'TODO: Implement? Maybe just return empty string or ignore from export',
};

export default Definition;
