import type { ModalFormProps } from '@/components/Button/formButton';
import LeaseTemplatesTable from '@/components/LeaseTemplatesTable/LeaseTemplatesTable';
import { DocumentTemplateStatus } from '@/domain/document-templates';
import { EntityType } from '@/domain/entity-type';
import { useDocumentTemplates } from '@/hooks/services/useDocumentTemplates';
import { LeaseDocumentTemplatesService } from '@/services/lease-document-templates';
import { filterSelectOptions, handleError } from '@/utils/utils';
import { WarningOutlined } from '@ant-design/icons';
import {
  Alert,
  Button,
  Divider,
  message,
  Modal,
  Popconfirm,
  Select,
  Space,
  Typography,
} from 'antd';
import { useCallback, useMemo, useState, type FC } from 'react';
import type { SortEndHandler } from 'react-sortable-hoc';
import styles from './styles.module.less';

export interface Props extends ModalFormProps {
  unitIds: number[];
}

const AssignLeaseTemplatesModal: FC<Props> = ({ unitIds, onCancel, onSuccess }) => {
  const { data: leaseTemplates = [] } = useDocumentTemplates({
    status: DocumentTemplateStatus.ACTIVE,
  });
  const [loading, setLoading] = useState(false);
  const [selectedLeaseTemplateIds, setSelectedLeaseTemplateIds] = useState<number[]>([]);
  const [value, setValue] = useState<number>();

  const handleRemoveLeaseTemplate = (idToRemove: number) => {
    setSelectedLeaseTemplateIds((prevIds) => prevIds.filter((id) => id !== idToRemove));
  };

  const [selectedLeaseTemplates, notSelectedLeaseTemplates] = useMemo(
    () => [
      selectedLeaseTemplateIds.map((id) => leaseTemplates.find((t) => t.id === id)!),
      leaseTemplates.filter((t) => !selectedLeaseTemplateIds.includes(t.id)),
    ],
    [leaseTemplates, selectedLeaseTemplateIds],
  );

  const handleAssignLeaseTemplates = useCallback(async () => {
    setLoading(true);
    try {
      await LeaseDocumentTemplatesService.bulk({
        leaseDocumentTemplateIdsToAdd: selectedLeaseTemplateIds,
        entityIds: unitIds,
        entityType: EntityType.UNIT,
      });
      message.success('Lease Templates assigned successfully');
      onSuccess?.();
    } catch (error) {
      handleError(error, { toastFallbackMessage: 'Error assigning lease templates' });
    } finally {
      setLoading(false);
    }
  }, [selectedLeaseTemplateIds, unitIds, onSuccess]);

  const handleReorder: SortEndHandler = ({ oldIndex, newIndex }) => {
    setSelectedLeaseTemplateIds((prevIds) => {
      const newIds = [...prevIds];
      newIds.splice(oldIndex, 1);
      newIds.splice(newIndex, 0, prevIds[oldIndex]);
      return newIds;
    });
  };

  return (
    <Modal
      title="Bulk Assign Lease Templates"
      visible
      onCancel={onCancel}
      width={1092}
      footer={[
        <Button
          type="default"
          key="cancel"
          disabled={loading}
          onClick={() => {
            onCancel?.();
          }}
        >
          Cancel
        </Button>,
        <Popconfirm
          title={
            <div className={styles.confirmTitle}>
              Proceeding with this action will overwrite any Lease Templates assigned to the
              selected Unit(s). Are you sure you wish to continue?
            </div>
          }
          onConfirm={handleAssignLeaseTemplates}
          okText="Yes"
          cancelText="No"
          key="confirm-assign-templates"
          disabled={loading || selectedLeaseTemplateIds.length === 0}
        >
          <Button
            type="primary"
            key="assign-templates"
            disabled={loading || selectedLeaseTemplateIds.length === 0}
            loading={loading}
            style={{marginLeft: 8}}
          >
            Assign Selected Templates
          </Button>
        </Popconfirm>,
      ]}
    >
      <div data-testid="modal-assign-lease-templates">
        <Space
          direction="vertical"
          size={20}
          style={{ width: '100%' }}
          className={styles.fullWidth}
        >
          <Alert
            message={
              <>
                <strong>Bulk Update</strong> {unitIds.length} Units selected
              </>
            }
            type="warning"
            showIcon
            icon={<WarningOutlined />}
          />
          <div className={styles.replaceDisclaimer}>
            The Lease Templates selected below will overwrite any previously assigned Lease
            Templates for the selected Units.
          </div>
          <Divider style={{ margin: 0 }} />
          <Typography className={styles.sectionTitle}>Add Lease Template</Typography>
          <Select<number>
            className={styles.leaseTemplatesSelect}
            options={notSelectedLeaseTemplates.map(({ id, name }) => ({
              value: id,
              label: name,
            }))}
            onSelect={(selectedValue) => {
              setSelectedLeaseTemplateIds((prevIds) => [...prevIds, Number(selectedValue)]);
              // Casting is to clear the value after selecting and removing the item from the options.
              // Otherwise the component shows the lease template id
              setValue(null as unknown as number);
            }}
            showSearch
            filterOption={filterSelectOptions('label')}
            value={value}
            data-testid="select-lease-template"
            placeholder="Type Lease Template Name Here"
          />
          {selectedLeaseTemplateIds.length > 0 ? (
            <>
              <Typography className={styles.sectionTitle}>Selected Templates</Typography>
              <LeaseTemplatesTable
                leaseTemplates={selectedLeaseTemplates}
                onRemove={handleRemoveLeaseTemplate}
                handleReorder={handleReorder}
                className={styles.leaseTemplatesTable}
                removeConfirmationText="Are you sure you wish to remove this Lease Template?"
              />
            </>
          ) : null}
        </Space>
      </div>
    </Modal>
  );
};

export default AssignLeaseTemplatesModal;
