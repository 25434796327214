import FloatingEditableAttribute from '@/components/FloatingEditableAttribute';
import { useAppContext } from '@/contexts/AppContext';
import EntitySelect from '@/notmagic/components/EntityTable/components/EntitySelect';
import type {
  ColumnComponentType,
  ColumnComponentTypeProps,
  ColumnType,
} from '@/notmagic/components/EntityTable/types';
import { useEntityTableContext } from '@/notmagic/Context';
import { EditOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import { Formik } from 'formik';
import { useMemo, useRef, useState } from 'react';

type Props = ColumnComponentTypeProps<number> & {
  entityType?: string;
  displayName?: string;
  filter?: string;
};

type Values = {
  field?: number;
};

// TODO: Refactor to reduce duplication in other column types
const EditableComponent: ColumnComponentType<Props> = ({
  value,
  displayName,
  entityType: entityTypeName,
  filter,
  update,
  textIfNull,
}) => {
  const { currentUser } = useAppContext();
  const { entityTypes } = useEntityTableContext();
  const entityType = entityTypes[entityTypeName!];
  const [editing, setEditing] = useState(false);
  const baseRef = useRef<HTMLDivElement>(null);

  const bindings = useMemo(() => ({ currentUser }), [currentUser]);

  const onSubmit = async (values: Values) => {
    await update!(values.field!);
    setEditing(false);
  };

  return (
    <div ref={baseRef} className="floating-editing-container">
      {editing ? (
        baseRef.current && (
          <Formik<Values>
            initialValues={{
              field: value,
            }}
            onSubmit={onSubmit}
          >
            {({ isSubmitting, handleSubmit, setFieldValue, isValid, values }) => (
              <FloatingEditableAttribute
                baseElement={baseRef.current!}
                loading={isSubmitting}
                onCancel={() => setEditing(false)}
                onConfirm={handleSubmit}
                disabled={!isValid}
              >
                <EntitySelect
                  entityType={entityType}
                  value={values.field != undefined ? values.field : 0}
                  onChangeValue={(newValue) =>
                    setFieldValue('field', newValue === 0 ? null : newValue)
                  }
                  filter={filter}
                  bindings={bindings}
                  nullOption={{ label: textIfNull ?? '', value: 0 }}
                />
              </FloatingEditableAttribute>
            )}
          </Formik>
        )
      ) : (
        <div className="display-value">
          <span>{displayName ?? textIfNull}</span>

          <Button
            className="edit-button"
            icon={<EditOutlined />}
            type="link"
            size="small"
            onClick={() => setEditing(true)}
          />
        </div>
      )}
    </div>
  );
};

const Component: ColumnComponentType<Props> = (props) => {
  if (props.editable) {
    return <EditableComponent {...props} />;
  }
  return <>{props.displayName ? props.displayName : props.textIfNull}</>;
};

const Definition: ColumnType = {
  Component,
  sort: (a: string, b: string) => {
    if (!a && !b) {
      return 0;
    }
    if (!a) {
      return -1;
    }
    if (!b) {
      return 1;
    }
    return a ? (b ? a.localeCompare(b) : -1) : 0;
  },
};

export default Definition;
