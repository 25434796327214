import CheckIcon from '@material-ui/icons/Check';
import { Button, Divider, Input, Typography } from 'antd';
import type { FormikProps, FormikValues } from 'formik';
import type { FC } from 'react';
import { useEffect, useMemo, useState } from 'react';
import type { Filter, SelectFilterType } from '../FilterBarWithURLQuery/types';
import './styles.less';

interface Props {
  filter: Filter & SelectFilterType;
  value: any;
  onChange: (value: any) => void;
  formProps: Pick<FormikProps<FormikValues>, 'values' | 'setFieldValue'> | undefined;
}

const { Text } = Typography;

const ActiveFilterSelect: FC<Props> = ({ filter, value, onChange, formProps }) => {
  const [itemsSelected, setItemsSelected] = useState<string[]>([]);
  const [searchText, setSearchText] = useState('');

  useEffect(() => {
    if (filter.multiple) {
      setItemsSelected(typeof value === 'string' ? [value] : value);
    } else {
      setItemsSelected([value]);
    }
  }, [value]);

  const options = useMemo(() => {
    if (searchText !== '' && filter.filterBy) {
      return filter.options.filter(
        (o) =>
          o.label.toLowerCase().includes(searchText.toLowerCase()) &&
          filter.filterBy &&
          o[filter.filterBy] === formProps?.values[filter.filterBy || ''],
      );
    }

    if (filter.filterBy) {
      return filter.options.filter(
        (opt) =>
          filter.filterBy && opt[filter.filterBy] === formProps?.values[filter.filterBy || ''],
      );
    }

    if (searchText !== '') {
      return filter.options.filter((o) => o.label.toLowerCase().includes(searchText.toLowerCase()));
    }

    return filter.options;
  }, [
    searchText,
    filter.options,
    value,
    filter.filterBy,
    formProps?.values[filter.filterBy || ''],
  ]);

  const handleClick = (v: string) => {
    const index = itemsSelected.indexOf(v);
    if (index > -1 && !filter.required) {
      const newItems = itemsSelected.filter((e) => e !== v);
      setItemsSelected(newItems);
    } else if (filter.multiple) {
      setItemsSelected((prevItemsSelected) => {
        return [...prevItemsSelected, v];
      });
    } else {
      setItemsSelected([v]);
    }
  };

  const handleApply = () => {
    if (filter.multiple) {
      onChange(itemsSelected);
    } else {
      onChange(itemsSelected[0]);
    }
  };

  return (
    <div className="container-select">
      <Input placeholder="Search" onChange={(e) => setSearchText(e.target.value)} />
      <Divider className="divider" />
      <ul className="ul">
        {options.map((o) => (
          <li
            key={o.value}
            className="li"
            style={itemsSelected.includes(o.value) ? { backgroundColor: '#f4f4f4' } : {}}
            onClick={() => handleClick(o.value)}
          >
            <Text>{o.label}</Text>
            {itemsSelected.includes(o.value) && <CheckIcon className="check-icon" />}
          </li>
        ))}
      </ul>
      <Divider className="divider" />
      <Button size="large" block className="button" onClick={() => handleApply()}>
        Apply
      </Button>
    </div>
  );
};

export default ActiveFilterSelect;
