import { Button } from 'antd';
import type { FC } from 'react';
import { useEffect, useState } from 'react';
import { DateRangePicker } from '../DateRangePicker';
import './styles.less';

interface Props {
  value: any;
  onChange: (value: any) => void;
}

const ActiveFilterDateRange: FC<Props> = ({ value, onChange }) => {
  const [dateValue, setDateValue] = useState();

  useEffect(() => {
    setDateValue(value);
  }, [value]);

  const handleApply = () => {
    if (dateValue) {
      onChange(dateValue);
    }
  };

  return (
    <div className="container-date-range">
      <DateRangePicker value={dateValue} onChange={setDateValue} />
      <Button size="large" className="button" onClick={() => handleApply()}>
        Apply
      </Button>
    </div>
  );
};

export default ActiveFilterDateRange;
