import { SearchOutlined } from '@ant-design/icons';
import { Button, Input } from 'antd';
import type { CSSProperties, FC } from 'react';
import { useState } from 'react';
import classes from './styles.module.less';

interface SearchFieldProps {
  defaultValue: string;
  placeholder: string;
  onSearch: (value: string) => void;
  style?: CSSProperties;
}

const SearchField: FC<SearchFieldProps> = ({ defaultValue, onSearch, placeholder, style }) => {
  const [value, setValue] = useState<string>(defaultValue);

  return (
    <Input
      data-testid="filter-search-field"
      className={classes.searchField}
      value={value}
      placeholder={placeholder}
      style={style}
      suffix={
        <Button
          data-testid="filter-search-field-submit"
          className={`searchIconButton ${value ? 'searchIconButtonActive' : ''}`}
          type="text"
          icon={<SearchOutlined style={{ color: '#1890ff' }} />}
          onClick={() => onSearch(value)}
        />
      }
      onPressEnter={(ev) => onSearch((ev.target as HTMLTextAreaElement).value)}
      onChange={(e) => {
        if (!e.target.value) {
          onSearch('');
        }
        setValue(e.target.value);
      }}
    />
  );
};

export default SearchField;
