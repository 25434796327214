import type { Option } from '@/domain/base';
import { Form } from 'antd';
import type { FormikProps } from 'formik';
import { Field } from 'formik';
import type { FC } from 'react';
import { Fragment } from 'react';
import { AntInput, AntInputNumber, AntSelect, AntTextArea } from '../Formik/CreateAntField';

export type BulkUpdateFormValues = Record<string, any>;

export enum BulkUpdateFieldType {
  Text = 'Text',
  Select = 'Select',
  Number = 'Number',
  TextArea = 'TextArea',
}

export type BulkUpdateField = {
  name: string;
  title?: string;
  type?: BulkUpdateFieldType;
  options?: Option<string | number>[];
  multiple?: boolean;
  required?: (values: BulkUpdateFormValues) => boolean;
  display?: (values: BulkUpdateFormValues) => boolean;
};

interface Props {
  fields?: BulkUpdateField[];
  formikProps: FormikProps<BulkUpdateFormValues>;
  layout?: 'horizontal' | 'vertical';
}

const BulkUpdateForm: FC<Props> = ({ fields, formikProps, layout = 'horizontal' }) => {
  const formItemLayout =
    layout === 'horizontal'
      ? {
          labelCol: { span: 8 },
          wrapperCol: { span: 16 },
        }
      : null;
  return (
    <Form {...formItemLayout} layout={layout}>
      {fields
        ?.filter((f) => !f.display || f.display(formikProps.values))
        .map(
          ({
            name,
            title,
            type = BulkUpdateFieldType.Text,
            options = [],
            multiple = false,
            required,
          }) => (
            <Fragment key={name}>
              {type === BulkUpdateFieldType.Text && (
                <Field
                  component={AntInput}
                  type="text"
                  label={title}
                  name={name}
                  hasFeedback
                  submitCount={formikProps.submitCount}
                  required={required ? required(formikProps.values) : false}
                />
              )}

              {type === BulkUpdateFieldType.Number && (
                <Field
                  component={AntInputNumber}
                  label={title}
                  name={name}
                  hasFeedback
                  submitCount={formikProps.submitCount}
                  required={required ? required(formikProps.values) : false}
                />
              )}

              {type === BulkUpdateFieldType.Select && (
                <Field
                  component={AntSelect}
                  options={options}
                  label={title}
                  name={name}
                  hasFeedback
                  submitCount={formikProps.submitCount}
                  mode={multiple ? 'multiple' : 'default'}
                  required={required ? required(formikProps.values) : false}
                />
              )}

              {type === BulkUpdateFieldType.TextArea && (
                <Field
                  component={AntTextArea}
                  type="text"
                  label={title}
                  name={name}
                  style={{ width: '100%' }}
                  hasFeedback
                  submitCount={formikProps.submitCount}
                  autoSize
                  required={required ? required(formikProps.values) : false}
                />
              )}
            </Fragment>
          ),
        )}
    </Form>
  );
};

export default BulkUpdateForm;
