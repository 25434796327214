import { ArrowSize, NextArrow, PrevArrow } from '@/components/Carousel/arrows';
import type { CarouselOption } from '@/components/Carousel/default';
import type { WithStyles } from '@material-ui/core';
import { Typography } from '@material-ui/core';
import Modal from 'antd/lib/modal';
import type { MouseEventHandler } from 'react';
import { forwardRef, useCallback, useImperativeHandle, useState } from 'react';
import type { Settings } from 'react-slick';
import Slider from 'react-slick';
import SectionSplashScreen from '../PageLoading/SplashSection';
import type { carouselStyles } from './default';

type Props = Settings &
  WithStyles<typeof carouselStyles> & {
    options?: CarouselOption[];
    height?: string | number;
    ref?: any;
    open?: boolean;
    onClose?: () => void;
  };

export type ModalCarouselRef = {
  open: () => void;
};

const ModalCarousel = forwardRef<ModalCarouselRef, Props>(
  ({ options, height, classes, ...props }, ref) => {
    const [visible, setVisible] = useState(false);

    useImperativeHandle(ref, () => ({
      open: () => {
        setVisible(true);
      },
    }));

    const ignoreClick: MouseEventHandler = useCallback((event) => {
      event.stopPropagation();
    }, []);

    const renderOverlay = (option: CarouselOption) =>
      option.overlay ? <span className={classes.overlay}>{option.overlay}</span> : null;

    const close = useCallback(() => {
      setVisible(false);
      props.onClose?.();
    }, [props.onClose]);

    const renderSlider = () => {
      return options?.length ? (
        <Slider
          infinite
          dots={false}
          useCSS={false}
          speed={0}
          slidesToShow={1}
          slidesToScroll={1}
          nextArrow={<NextArrow size={ArrowSize.large} />}
          prevArrow={<PrevArrow size={ArrowSize.large} />}
          {...props}
        >
          {options.map((option, index) => (
            <div key={option.src}>
              <div className={classes.imageContainer}>
                {renderOverlay(option)}
                <Typography className={classes.caption} variant="subtitle1">
                  {index + 1} of {options.length}
                </Typography>
                <img
                  className={classes.modalImage}
                  title=""
                  alt=""
                  src={option.src}
                  onClick={ignoreClick}
                />
              </div>
              {option.caption ? (
                <Typography className={classes.caption} variant="subtitle1" onClick={ignoreClick}>
                  {option.caption}
                </Typography>
              ) : null}
            </div>
          ))}
        </Slider>
      ) : (
        <SectionSplashScreen />
      );
    };

    const isOpen = props.open || visible;

    return (
      <Modal
        visible={isOpen}
        footer={null}
        onCancel={close}
        wrapClassName="carousel-modal"
        width="100vw"
      >
        {/* The slider needs to get unmounted when the modal is closed for the initialSlide prop to work correctly */}
        {isOpen ? <div onClick={close}>{renderSlider()}</div> : null}
      </Modal>
    );
  },
);

export default ModalCarousel;
