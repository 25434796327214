import type { ColumnComponentType, ColumnType } from '@/notmagic/components/EntityTable/types';
import { Spin } from 'antd';
import { Fragment, lazy, Suspense } from 'react';

const lazyIcon = (icon = 'QuestionCircleOutlined') =>
  lazy(() =>
    import('@ant-design/icons').then((module) => {
      return {
        default: module?.[icon] || module?.QuestionCircleOutlined,
      };
    }),
  );

const Component: ColumnComponentType = ({ value }) => {
  const Icon = lazyIcon(value) || Fragment;

  return value ? (
    <Suspense fallback={<Spin size="small" />}>
      <Icon />
    </Suspense>
  ) : null;
};

const Definition: ColumnType = {
  Component,
  sort: (a: string, b: string) => (a === b ? 0 : a < b ? -1 : 1),
};

export default Definition;
