import PropifyTable from '@/@propify-components/Table';
import { useAppContext } from '@/contexts/AppContext';
import CreateEntityButton from '@/notmagic/components/EntityTable/components/CreateEntityButton';
import EditEntityButton from '@/notmagic/components/EntityTable/components/EditEntityButton';
import { useEntityTableContext } from '@/notmagic/Context';
import { isMetadataSchemaValid, logInvalidMetadataSchema } from '@/notmagic/schema';
import type { Entity } from '@/notmagic/types';
import { settings } from '@/services/settings';
import { crudService } from '@/utils/request';
import { handleError } from '@/utils/utils';
import { DeleteOutlined } from '@ant-design/icons';
import type { SortDirection } from '@propify/components';
import { Alert, Button, message, Popconfirm } from 'antd';
import classNames from 'classnames';
import type { FC } from 'react';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useEntitySearch } from '../../hooks/request';
import EntitiesFetcher from '../EntitiesFetcher';
import EnumerationsFetcher from '../EnumerationsFetcher';
import DisplayColumns from './components/DisplayColumns';
import Events from './components/events';
import ExportButton from './components/ExportButton';
import EntityTableFilters from './components/Filters/Filters';
import Page from './components/Page';
import SearchField from './components/SearchField/SearchField';
import EntityTableTabs from './components/Tabs';
import { useDataGroupedByTabs } from './hooks/useDataGroupedByTabs';
import { useFilteredData } from './hooks/useFilteredData';
import { useParameters } from './hooks/useParameters';
import { useTransformedData } from './hooks/useTransformedData';
import styles from './styles.module.less';
import type { GlobalAction, Table, TableColumn } from './types';
import { mapColumn } from './utils/mapColumn';

type RowActionType = FC<{
  row: any;
  validateEntity: () => void;
}>;

type Props = {
  table: Table;
  // TODO: remove this prop. Pages consuming EntityTable should not assume anything about the metadata
  parameters?: Record<string, any>;
  globalActions?: GlobalAction[];
  rowActions?: RowActionType[];
};

const EntityTableComponent: FC<Props> = ({
  table,
  globalActions,
  parameters: fixedParameters,
  rowActions,
}) => {
  const { columnTypes, entityTypes } = useEntityTableContext();
  // If this line fails it's because entity type doesn't exist
  // in '/metadata/entity-types' or schema validation didn't pass
  const entityType = entityTypes[table.entityType];
  const entitySettings = settings(entityType.name);
  const tableRef = useRef(null);
  const { parameters: query, updateParameters } = useParameters();
  const [selection, setSelection] = useState<any[]>([]);
  const [currentTabKey, setCurrentTabKey] = useState(table.tabs?.[0]?.key || '0');
  const [entityColumns, setEntityColumns] = useState(table.columns || []);
  const [columns, setColumns] = useState<TableColumn[]>([]);
  const { currentUser } = useAppContext();

  const currentTab = useMemo(() => {
    return table.tabs?.find((tab) => tab.key === currentTabKey) ?? { key: '0', title: '' };
  }, [table.tabs, currentTabKey]);

  const enableSelection = useMemo(
    () => globalActions?.some((a) => a.needsSelection),
    [globalActions],
  );

  const [pageSize, setPageSize] = useState(50);
  const [sortBy, setSortBy] = useState(currentTab.sort?.[0]);
  const [sortDirection, setSortDirection] = useState(currentTab.sort?.[1] || 'ASC');

  const tabQueryParameters = useMemo(
    () => ({
      ...table.queryParameters,
      ...currentTab.queryParameters,
    }),
    [table.queryParameters, currentTab.queryParameters],
  );

  // 2. It needs to take the query parameters from `location` and run them through the elements in the `queryParameters`
  // object for the current tab key. If the query parameter is not listed there then it's not sent to the backend for
  // this tab ever.
  const tabFixedQueryParameters = Object.keys(tabQueryParameters)
    .filter((key) => tabQueryParameters[key] !== null)
    .reduce(
      (obj, key) => ({
        ...obj,
        [key]:
          tabQueryParameters[key] === '$currentUserId' ? currentUser?.id : tabQueryParameters[key],
      }),
      {},
    );

  const requestQueryParameters = {
    ...Object.keys(query)
      .filter((queryParameter) => currentTab.queryParameters?.hasOwnProperty(queryParameter))
      .reduce(
        (obj, key) => ({
          ...obj,
          [key]: query[key],
        }),
        {},
      ),
    ...tabFixedQueryParameters,
    ...fixedParameters,
  };

  // 3. Make the request with the query parameters necessary. The request should repeat whenever
  //  `requestQueryParameters` changes
  const {
    data: rawData,
    onDelete: onDeleteEntity,
    onValidate: onValidateEntity,
    mutate: refreshEntities,
    isValidating: loading,
    onBulkValidate,
  } = useEntitySearch(
    table.endpoint || entityType.endpoint,
    entityType.endpoint,
    requestQueryParameters,
  );

  const enumerationNames = useMemo(
    () =>
      table.columns
        .filter((column) => column.type === 'ENUM' && column.configuration.enumeration)
        .map((column) => column.configuration.enumeration as string)
        .filter((value, index, array) => array.indexOf(value) === index),
    [table.columns],
  );

  const [enumerations, setEnumerations] = useState<Record<string, Record<string, string>>>();

  const entityTypeNames = useMemo(
    () =>
      table.columns
        .filter((column) => column.type === 'ENTITY')
        .map((column) => column.configuration.entityType as string)
        .filter((value, index, array) => array.indexOf(value) === index),
    [table.columns],
  );

  const [entityLookups, setEntityLookups] = useState<Record<string, Record<string, string>>>();

  const { transformedData, transforming } = useTransformedData(
    table,
    rawData,
    enumerations,
    entityLookups,
  );

  const { tabRowMap: rowsGroupedByTabs, grouping } = useDataGroupedByTabs(
    table,
    transformedData,
    currentTab,
  );

  const tabBadgeCounters = useMemo(
    () =>
      Object.keys(rowsGroupedByTabs).reduce(
        (accumulator, key) => ({
          ...accumulator,
          [key]: rowsGroupedByTabs[key].length,
        }),
        {},
      ),
    [rowsGroupedByTabs],
  );

  const { filteredData: filteredRowsForCurrentTab, filtering } = useFilteredData(
    query,
    table.keywords,
    table.filters,
    rowsGroupedByTabs[currentTabKey],
  );

  const patch = useCallback(async (row: any, key: string, value: any) => {
    const editable = row[`${key}$editable`];

    const updateEntityType =
      typeof editable === 'object'
        ? entityTypes[editable.entityType]
        : entityTypes[table.entityType];
    const id = typeof editable === 'object' ? editable.id : row.id;
    const version = typeof editable === 'object' ? editable.version : row.version;

    try {
      await crudService.patch<Entity>(
        [
          {
            op: 'replace',
            path: `/${editable.key || key}`,
            value,
          },
        ],
        `${updateEntityType.endpoint}/${id}?version=${version}`,
      );
      message.success(`Entity updated`);
      onValidateEntity(row.id);
    } catch (error) {
      handleError(error, { toastFallbackMessage: `There was an error while updating the entity` });
    }
  }, []);

  useEffect(() => {
    const getColumns = () => {
      const columnsArray = Object.values(entityColumns)
        .filter((column) => !column.hidden)
        .map((column) =>
          mapColumn(
            columnTypes,
            column,
            entityType,
            () => refreshEntities(),
            onValidateEntity,
            patch,
          ),
        );

      const isDeleteButtonRendered = entityType.acls?.some(
        (acl) =>
          acl.type === 'ALLOW' && (acl.actions === undefined || acl.actions.includes('DELETE')),
      );

      const isEditButtonRendered = entityType.acls?.some(
        (acl) => acl.type === 'ALLOW' && acl.actions?.includes('UPDATE'),
      );

      if (rowActions?.length || isDeleteButtonRendered || isEditButtonRendered) {
        columnsArray.push({
          title: 'Actions',
          key: 'actions',
          autoWidth: true,
          render: (row: Entity) => (
            <>
              {rowActions?.map((RowAction, index) => (
                <RowAction
                  // eslint-disable-next-line react/no-array-index-key
                  key={`action-${index}`}
                  row={row}
                  validateEntity={() => onValidateEntity(row.id)}
                />
              ))}
              {isEditButtonRendered && (
                <EditEntityButton
                  key={`edit-${row.id}`}
                  entityType={entityType}
                  onSuccess={(entity: any) => onValidateEntity(entity.id)}
                  entity={row}
                />
              )}
              {isDeleteButtonRendered && (
                <Popconfirm title="Are you sure?" onConfirm={() => onDeleteEntity(row.id)}>
                  <Button type="link" danger icon={<DeleteOutlined />} />
                </Popconfirm>
              )}
            </>
          ),
        });
      }

      setColumns(columnsArray);
    };

    getColumns();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [entityColumns, entityType, enumerations, entityLookups]);

  const getRowHighlightProps = useCallback(
    (row: any) => row[`_highlight_${currentTabKey}`] || row._highlight,
    [currentTabKey, table.highlight],
  );

  const filteredColumns = useMemo(() => {
    return columns.filter((column) => !currentTab.hideColumns?.includes(column.key!));
  }, [columns, currentTab]);

  const onSortChange = (sortedByValue?: string, sortDirValue?: string) => {
    setSortBy(sortedByValue);
    setSortDirection((sortDirValue as string)?.toLocaleUpperCase() || 'ASC');
  };

  useEffect(() => {
    onSortChange(currentTab.sort?.[0], currentTab.sort?.[1] || 'ASC');
  }, [currentTab.sort]);

  const isAddButtonRendered = entityType.acls?.some(
    (acl) => acl.type === 'ALLOW' && (acl.actions === undefined || acl.actions.includes('CREATE')),
  );

  const content = (
    <>
      <EnumerationsFetcher enumerationNames={enumerationNames} onFetchAll={setEnumerations} />
      <EntitiesFetcher entityTypeNames={entityTypeNames} onFetchAll={setEntityLookups} />

      {table.tabs && table.tabs.length > 1 && (
        <EntityTableTabs
          tabs={table.tabs}
          tabBadgeCounters={tabBadgeCounters}
          currentTabKey={currentTabKey}
          onChangeTab={setCurrentTabKey}
        />
      )}

      {table.useSSE && <Events entityType={entityType} refreshEntities={() => refreshEntities()} />}

      <div id="actions" className={styles.entityTableActions}>
        <div>
          {table.filters && table.filters.length ? (
            <EntityTableFilters
              entityType={entityType}
              filters={table.filters}
              currentTab={currentTab}
            />
          ) : null}
        </div>

        <div style={{ flex: 'unset' }}>
          <ExportButton
            key="export-button"
            tableRef={tableRef}
            filename={entityType.name}
            totalResults={filteredRowsForCurrentTab?.length}
          />

          <DisplayColumns
            columns={entityColumns}
            setColumns={setEntityColumns}
            settings={entitySettings}
          />

          {table.keywords && (
            <SearchField
              placeholder="Search"
              onSearch={(text) =>
                updateParameters({
                  ...query,
                  keywords: text || null,
                })
              }
              defaultValue={query.keywords ? `${query?.keywords}` : ''}
            />
          )}
        </div>
      </div>

      <PropifyTable
        data-testid="entity-table"
        loading={loading || transforming || filtering || grouping || !columns?.length}
        columns={filteredColumns as TableColumn & { key: string; title: string }[]}
        ref={tableRef}
        key={`${currentTabKey}-${pageSize}`}
        data={filteredRowsForCurrentTab}
        rowKeyExtractor={(row: any) => row.id!}
        pageSize={pageSize}
        onPageSizeChange={setPageSize}
        getRowHighlightProps={getRowHighlightProps}
        // TODO: entityTable should compose propify-scrollable-table when it is migrated to css modules
        className={classNames(styles.entityTable, 'propify-scrollable-table')}
        responsive={false}
        selection={enableSelection ? selection : undefined}
        onSelectionChange={enableSelection ? setSelection : undefined}
        sortedBy={sortBy}
        sortDir={sortDirection?.toLocaleLowerCase() as SortDirection}
        onSortChange={onSortChange}
      />
    </>
  );

  const clearSelection = useCallback(() => {
    setSelection([]);
  }, []);

  return table.header ? (
    <Page
      fullHeight
      title={`${table.header?.title || entityType.displayName}`}
      globalActions={globalActions}
      actionsData={{
        rawData,
        tabData: filteredRowsForCurrentTab,
        entityType,
        selection,
        clearSelection,
      }}
      refreshData={() => refreshEntities()}
      bulkUpdate={onBulkValidate}
      extra={[
        isAddButtonRendered && (
          <CreateEntityButton
            entityType={entityType}
            onSuccess={(entity: any) => onValidateEntity(entity.id)}
          />
        ),
      ]}
    >
      {content}
    </Page>
  ) : (
    <>{content}</>
  );
};

const EntityTable: FC<Props> = (props) => {
  const { table: metadata } = props;

  // It's ready to render after default filters are applied to query parameters
  const [readyToRender, setReadyToRender] = useState<any>(false);

  const [validatedMetadata, validationErrors] = useMemo(() => {
    const [valid, errors] = isMetadataSchemaValid(metadata);
    if (!valid) {
      logInvalidMetadataSchema(errors);
      return [undefined, errors];
    }

    return [metadata];
  }, [metadata]);

  const { updateParameters } = useParameters();

  // 1. Apply necessary default filters
  useEffect(() => {
    const filtersWithDefaultValue = metadata.filters?.filter((filter) => !!filter.defaultValue);
    if (filtersWithDefaultValue?.length) {
      updateParameters(
        filtersWithDefaultValue.reduce(
          (result, filter) => ({
            ...result,
            [filter.key]: filter.defaultValue,
          }),
          {},
        ),
      );
    }
    setReadyToRender(true);
  }, [metadata.filters, updateParameters]);

  if (!readyToRender) {
    return null;
  }

  return validatedMetadata ? (
    <EntityTableComponent {...props} table={validatedMetadata} />
  ) : (
    <>
      <Alert
        data-testid="entity-table-invalid-metadata-alert"
        message="The metadata provided for this table is not valid."
        type="error"
        showIcon
      />
      {/* This is to make it easier to read errors in tests */}
      <span style={{ display: 'none', visibility: 'hidden' }}>
        {JSON.stringify(validationErrors, null, 2)}
      </span>
    </>
  );
};

export default EntityTable;
