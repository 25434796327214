import type { CSSProperties, Key, ReactElement } from 'react';
import type { SortEndHandler } from 'react-sortable-hoc';
import type { ClassNameMap } from '../shared/utils/material';
import type { Column, RowHighlightInfo, SortDirection } from './utils';
import type { Settings } from './utils/settings';

export type DataId = Key;

export type RowKeyExtractorType<T, K> = (row: T, rowIndex?: number) => K;

export type Props<T, K extends DataId> = {
  'data-testid'?: string;
  loading?: boolean;
  pageSize?: number;
  pageSizeOptions?: string[] | number[];
  onPageSizeChange?: (page: number) => void;
  data: T[];
  columns: Column<T>[];
  rowKeyExtractor: RowKeyExtractorType<T, K>;
  getRowHighlightProps?: (row: T, rowIndex?: number) => RowHighlightInfo | null;
  selection?: K[];
  onSelectionChange?: (selection: K[]) => void;
  selectionDisabled?: boolean;
  sortedBy?: string;
  sortDir?: SortDirection;
  onSortChange?: (sortedBy: string, sortDir: SortDirection) => void;
  setColumnPinned?: (columnKey: string, pinned: boolean) => void;
  pinnedColumns?: string[];
  renderFooterExtra?: () => ReactElement;
  settings?: Settings;
  ref?: any;
  onRowClick?: (row: T) => void;
  widthFitContent?: boolean;
  style?: CSSProperties;
  onRowDrop?: SortEndHandler;
  hideTooltipColumn?: boolean;
  className?: string;
  singleSelection?: boolean;
  responsive?: boolean;
  classes?: ClassNameMap<string>;
};

export interface State<T> {
  pageNumber: number;
  sortedBy: string | undefined;
  sortDir: SortDirection | undefined;
  displayData: T[];
  pinnedColumns: string[];
  calculatedColumnWidths: Record<string, number>;
}

export interface InMemorySettings {
  columnOrder: string[] | undefined;
  columnWidths: Record<string, number>;
  pinnedColumns: string[];
}

export const inMemorySettings: InMemorySettings = {
  columnOrder: undefined,
  columnWidths: {},
  pinnedColumns: [],
};
