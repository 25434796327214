import type { FC } from 'react';
import './styles.less';

interface Props {
  description: string;
  fontSize?: number;
}

const EmptyState: FC<Props> = ({ description, fontSize }) => {
  return (
    <div className="empty-state">
      <div>
        <svg
          width="122"
          height="122"
          viewBox="0 0 122 122"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M61 110.562C88.3726 110.562 110.562 88.3726 110.562 61C110.562 33.6274 88.3726 11.4375 61 11.4375C33.6274 11.4375 11.4375 33.6274 11.4375 61C11.4375 88.3726 33.6274 110.562 61 110.562Z"
            stroke="#95A5B6"
            strokeWidth="7.625"
            strokeMiterlimit="10"
          />
          <path
            d="M25.954 25.9536L96.0468 96.0464"
            stroke="#95A5B6"
            strokeWidth="7.625"
            strokeMiterlimit="10"
          />
        </svg>
        <div style={{ fontSize }}>{description}</div>
      </div>
    </div>
  );
};

export default EmptyState;
