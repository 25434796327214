import { EditOutlined } from '@ant-design/icons';
import { Button, InputNumber } from 'antd';
import type { FormikProps } from 'formik';
import { Formik } from 'formik';
import type { FC } from 'react';
import { useRef, useState } from 'react';
import * as Yup from 'yup';
import type { FloatingInlineEditProps } from '.';
import FloatingEditableAttribute from '../FloatingEditableAttribute';
import './styles.less';

export type FloatingInlineNumberEditProps = Omit<FloatingInlineEditProps<number>, 'inputRender'> & {
  min?: number;
  max?: number;
};

type Values = {
  field: number;
};

const FloatingInlineNumberEdit: FC<FloatingInlineNumberEditProps> = ({
  initialValue,
  displayValue,
  onConfirm,
  min,
  max,
}) => {
  const [editing, setEditing] = useState(false);
  const baseRef = useRef<HTMLDivElement>(null);
  const formRef = useRef<FormikProps<Values>>(null);

  const submit = (values: Values) => {
    onConfirm(
      values.field,
      () => {
        setEditing(false);
      },
      () => {
        formRef.current?.setSubmitting(false);
      },
    );
  };

  const validationSchema = Yup.object().shape({
    field: Yup.number()
      .test('valid-min', `Value cannot be lower than ${min}`, (value) =>
        value && typeof min === 'number' ? value >= min : true,
      )
      .test('valid-max', `Value cannot be higher than ${max}`, (value) =>
        value && typeof max === 'number' ? value <= max : true,
      ),
  });

  return (
    <div ref={baseRef} className="floating-editing-container">
      {editing ? (
        baseRef.current && (
          <Formik<Values>
            innerRef={formRef}
            initialValues={{
              field: initialValue || 0,
            }}
            onSubmit={submit}
            validationSchema={validationSchema}
          >
            {({ isSubmitting, handleSubmit, values, setFieldValue, isValid }) => (
              <FloatingEditableAttribute
                baseElement={baseRef.current!}
                loading={isSubmitting}
                onCancel={() => setEditing(false)}
                onConfirm={handleSubmit}
                disabled={!isValid}
              >
                <InputNumber
                  autoFocus
                  disabled={isSubmitting}
                  min={min}
                  value={values.field}
                  onChange={(value) => {
                    setFieldValue('field', value);
                  }}
                  style={{ width: '100%' }}
                />
              </FloatingEditableAttribute>
            )}
          </Formik>
        )
      ) : (
        <div className="display-value">
          <span>{displayValue}</span>

          <Button
            className="edit-button"
            icon={<EditOutlined />}
            type="link"
            size="small"
            onClick={() => setEditing(true)}
          />
        </div>
      )}
    </div>
  );
};

export default FloatingInlineNumberEdit;
