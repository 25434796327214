import type { ModalFormProps } from '@/components/Button/formButton';
import { useAppContext } from '@/contexts/AppContext';
import type { Lookup, New } from '@/domain/base';
import type { Entities, EntityType } from '@/domain/entity-type';
import type { Note, NoteContext, NoteUserMentioned } from '@/domain/note';
import { NoteType } from '@/domain/note';
import useEnumeration from '@/hooks/useEnumeration';
import { getMentionedUsers, replaceMentionedUserNamesByIds } from '@/pages/notes/utils';
import { NoteService } from '@/services/notes';
import { SHORT_DATE_TIME_FORMAT } from '@/utils/time';
import { getOperations, handleError } from '@/utils/utils';
import {
  Button,
  Checkbox,
  Col,
  DatePicker,
  Form,
  Input,
  message,
  Modal,
  Row,
  Select,
  Space,
  Switch,
} from 'antd';
import type { FormInstance } from 'antd/lib/form';
import pick from 'lodash/pick';
import type { Moment } from 'moment';
import moment from 'moment';
import type { FC } from 'react';
import { useCallback, useEffect, useRef, useState } from 'react';
import NoteEditor from './NoteEditor';
import './styles.less';

interface Props extends ModalFormProps<[Note, number]> {
  entityType: EntityType | string;
  entityId: number;
  otherEntities?: Entities;
  processJobEntities?: Entities;
  withContext?: boolean;
  note?: Note;
  defaultType?: NoteType | string;
  showSaveToProcessJob?: boolean;
}

const CreateNoteForm: FC<Props> = ({
  onSuccess,
  onCancel,
  withContext,
  otherEntities = {},
  processJobEntities = {},
  defaultType,
  note,
  entityId,
  entityType,
  showSaveToProcessJob = false,
}) => {
  const { users } = useAppContext();
  const [alert, setAlert] = useState(false);
  const [contextEntities, setContextEntities] = useState<Lookup[]>([]);
  const [type, setType] = useState('');
  const [context, setContext] = useState('');
  const [loading, setLoading] = useState(false);
  const [saveToProcessJob, setSaveToProcessJob] = useState(false);
  const [mentionedUsers, setMentionedUsers] = useState<NoteUserMentioned[]>([]);

  const formRef = useRef<FormInstance>(null);

  const { data: contexts } = useEnumeration('note-context');
  const { data: types } = useEnumeration('note-type');

  useEffect(() => {
    if (note?.id) {
      setMentionedUsers(getMentionedUsers(note.note, users));
      setAlert(!!note.alert);
    }
  }, [note, users]);

  const handleAlertChange = useCallback(
    (isChecked: boolean) => {
      setAlert(type === 'FOLLOW_UP' ? true : isChecked);
    },
    [type],
  );

  const lookUpEntities = useCallback(() => {
    NoteService.lookup(entityId, entityType, context as NoteContext).then(setContextEntities);
  }, [context, entityId, entityType]);

  useEffect(() => {
    formRef?.current?.setFieldsValue({ contextEntityId: undefined });
    if (context && !contextEntities?.length) {
      lookUpEntities();
    }
  }, [context, contextEntities, lookUpEntities]);

  useEffect(() => {
    if (type === 'FOLLOW_UP') {
      handleAlertChange(true);
      formRef?.current?.setFieldsValue({ alert: true });
    }
  }, [type, handleAlertChange]);

  const reset = () => {
    formRef?.current?.resetFields();
    setAlert(false);
  };

  const createNote = (values: Note) => {
    let entities: Entities = {
      [entityType]: [entityId],
      ...otherEntities,
    };

    if (saveToProcessJob) {
      entities = {
        ...entities,
        ...processJobEntities,
      };
    }

    const noteToCreate: New<Note> = {
      type: values.type || defaultType || NoteType.GENERAL,
      ...pick(values, ['context', 'alert', 'alertTime']),
      note: replaceMentionedUserNamesByIds(values.note, mentionedUsers),
      alertRole: values.alert ? 'USER' : '',
      entities,
    };

    const { contextEntityId } = values as any;
    if (values.context && values.context.length > 0 && contextEntityId) {
      noteToCreate.entities![values.context] = contextEntityId;
    } else {
      noteToCreate.context = undefined;
    }

    return NoteService.create(noteToCreate);
  };

  const patchNote = (values: Note) => {
    if (typeof note?.id !== 'number' || typeof note?.version !== 'number') {
      return Promise.reject();
    }

    const operations = getOperations({
      ...values,
      note: replaceMentionedUserNamesByIds(values.note, mentionedUsers),
    });

    return NoteService.patch(note.id, operations, note.version);
  };

  const handleSubmit = () => {
    setLoading(true);

    formRef?.current
      ?.validateFields()
      .then((values) => {
        const request = note?.id ? patchNote(values as Note) : createNote(values as Note);

        request
          .then(async (value) => {
            message.success(note?.id ? 'Note updated' : 'Note created');
            reset();
            await onSuccess?.(value, entityId);
          })
          .finally(() => {
            setLoading(false);
          });
      })
      .catch((error) => {
        handleError(error, { displayToast: false });
        setLoading(false);
      });
  };

  const noteValidator = async (_: any, value: string) => {
    if (!value || value.trim().length === 0) {
      throw new Error('Note is required');
    }
  };

  const handleCancel = () => {
    reset();
    onCancel?.();
  };

  const handleOnSelectUserMention = (item: NoteUserMentioned) => {
    setMentionedUsers((prevState) => [...prevState, { ...item }]);
  };

  const getContextTypes = () => {
    const elements = contexts.map(({ value, displayName }) => ({
      value,
      label: displayName,
    }));
    elements.unshift({ value: '', label: '' });

    return elements;
  };

  return (
    <Modal
      visible
      title={note?.id ? 'Update note' : 'Add a note'}
      confirmLoading={loading}
      maskClosable={false}
      width={880}
      className="notes-modal"
      closable={false}
      footer={
        <Space>
          {showSaveToProcessJob && (
            <Checkbox
              value={saveToProcessJob}
              onChange={() => setSaveToProcessJob(!saveToProcessJob)}
            >
              Save to Process/Job
            </Checkbox>
          )}

          <Button data-testid="note-form-cancel-button" onClick={handleCancel}>
            Cancel
          </Button>

          <Button
            data-testid="note-form-submit-button"
            loading={loading}
            onClick={handleSubmit}
            type="primary"
          >
            {note?.id ? 'Update' : 'Create'}
          </Button>
        </Space>
      }
    >
      <Form
        ref={formRef}
        className="notes-form"
        data-testid="modal-add-note"
        layout="vertical"
        initialValues={
          note?.id
            ? {
                ...note,
                alertTime: note.alertTime ? moment(note.alertTime) : undefined,
              }
            : { note: '', type: defaultType }
        }
      >
        {withContext && (
          <>
            <Form.Item
              name="type"
              label="Type"
              rules={[{ required: true, message: 'Type is required' }]}
            >
              <Select
                value={type}
                onChange={setType}
                options={types.map(({ value, displayName }) => ({
                  value,
                  label: displayName,
                }))}
              />
            </Form.Item>

            <Form.Item label="Context">
              <Input.Group compact>
                <Form.Item noStyle name="context">
                  <Select
                    value={context}
                    onChange={setContext}
                    style={{ width: '40%' }}
                    options={getContextTypes()}
                  />
                </Form.Item>
                <Form.Item
                  noStyle
                  name="contextEntityId"
                  rules={[{ required: !!context, message: 'Context entity is required' }]}
                >
                  <Select
                    style={{ width: '60%' }}
                    options={contextEntities.map(({ id, displayName }) => ({
                      value: id,
                      label: displayName,
                    }))}
                  />
                </Form.Item>
              </Input.Group>
            </Form.Item>
          </>
        )}

        <Form.Item
          name="note"
          label="Note"
          rules={[
            {
              validateTrigger: 'onBlur',
              validator: noteValidator,
              required: true,
              message: 'Note is required',
            },
          ]}
        >
          <NoteEditor onUserSelected={handleOnSelectUserMention} className="note-editor" />
        </Form.Item>

        <Row justify="space-between">
          <Col>
            <Form.Item label="Alert" name="alert" className="note-alert">
              <Switch checked={type === 'FOLLOW_UP' || alert} onChange={handleAlertChange} />
            </Form.Item>
          </Col>

          <Col>
            <Form.Item
              label="Alert Time"
              name="alertTime"
              className="note-alert-time"
              rules={[
                {
                  type: 'object',
                  required: alert,
                  message: 'Alert time is required for alerts.',
                  whitespace: true,
                },
              ]}
            >
              <DatePicker
                placeholder="Select date and time"
                disabledDate={(date: Moment | undefined) =>
                  !date || date.isSameOrBefore(moment().subtract(10, 'minutes'))
                }
                showTime
                format={SHORT_DATE_TIME_FORMAT}
              />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};

export default CreateNoteForm;
