import { getBooleanOptions } from '@/utils/utils';
import { Typography } from 'antd';
import type { FC } from 'react';
import './styles.less';

interface Props {
  onChange: (value: any) => void;
}

const { Text } = Typography;

const options = getBooleanOptions().map((opt) => ({ ...opt, value: String(opt.value) }));

const ActiveFilterBoolean: FC<Props> = ({ onChange }) => {
  return (
    <div className="container-boolean">
      <ul className="ul">
        {options.map((o) => (
          <li key={o.value} className="li" onClick={() => onChange(o.value === 'true')}>
            <Text>{o.label}</Text>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default ActiveFilterBoolean;
