import type { PropifyEntity } from '@/domain/base';
import type { Entities, EntityType } from '@/domain/entity-type';

export enum NoteContext {
  APPLICATION = 'APPLICATION',
  COLLECTIONS = 'COLLECTIONS',
  LEAD = 'LEAD',
  MAINTENANCE = 'MAINTENANCE',
  RENEWAL = 'RENEWAL',
}

export enum NoteType {
  GENERAL = 'GENERAL',
  MOVE_IN = 'MOVE_IN',
  MOVE_OUT = 'MOVE_OUT',
  COLLECTIONS = 'COLLECTIONS',
  RENEWAL = 'RENEWAL',
  EVICTION = 'EVICTION',
  SHOWING = 'SHOWING',
  APPLICATION = 'APPLICATION',
  MAINTENANCE = 'MAINTENANCE',
  LISTING = 'LISTING',
  SYSTEM = 'SYSTEM',
}

export interface Note extends PropifyEntity {
  type: NoteType;
  context?: NoteContext;
  note: string;
  alert?: boolean;
  alertRole: string;
  transparent?: boolean;
  alertTime?: string;
  entities?: Entities;
}

export interface NoteWithMentions extends Note {
  displayNote: string;
}

export interface NoteUserMentioned {
  id: number;
  value: string;
}

export interface NoteSearchParams {
  startTime?: string;
  endTime?: string;
  entityType?: EntityType | EntityType[] | string | string[];
  entityId?: number | number[];
}

export interface NoteLookupParams {
  entityId: number;
  entityType: EntityType | string;
  context: NoteContext;
}
