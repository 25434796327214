import type { File } from '@/domain/file';
import { downloadFileFromURL } from '@/services/files';
import { handleError } from '@/utils/utils';
import FileSaver from 'file-saver';
import JSZip from 'jszip';

export const downloadFile = async (
  file: File,
  errorMessage = 'There was an error downloading the file',
) => {
  try {
    const downloaded = await downloadFileFromURL(file.url);
    FileSaver.saveAs(downloaded, file.fileName);
  } catch (error) {
    handleError(error, { toastFallbackMessage: errorMessage });
  }
};

export const enumeratedFileName = (fileName: string, index: number) => {
  const result = /\.\w+$/.exec(fileName);

  if (!index || !fileName) {
    return fileName;
  }

  const filename = result ? fileName.substr(0, result.index) : fileName;
  const extension = result ? fileName.substr(result.index) : '';

  return `${filename} (${index})${extension}`;
};

export const zipAndDownload = async (
  files: Pick<File, 'fileName' | 'url'>[],
  zipFileName: string,
) => {
  const blobs = await Promise.all(files.map((file) => downloadFileFromURL(file.url)));

  const zip = new JSZip();
  for (let i = 0; i < files.length; i += 1) {
    const file = files[i];
    const blob = blobs[i];
    const index = files.filter((f) => f.fileName === file.fileName).findIndex((f) => f === file);

    zip.file(enumeratedFileName(file.fileName, index), blob);
  }

  const blob = await zip.generateAsync({ type: 'blob' });
  FileSaver.saveAs(blob, zipFileName);
};
