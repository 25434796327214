import AutoComplete from '@/components/autocomplete';
import { UnitService } from '@/services/units';
import type { CSSProperties, FC } from 'react';

type Props = {
  value?: number;
  onChange: (value: any) => void;
  onBlur?: (value: any) => void;
  style?: CSSProperties;
  className?: string;
  onSearch?: (keyword?: string) => any;
  disabled?: boolean;
};

const UnitsAutoComplete: FC<Props> = (props) => (
  <AutoComplete placeholder="Search units" onSearch={UnitService.lookup} {...props} />
);

export default UnitsAutoComplete;
