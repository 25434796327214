import type { Section } from '@/components/Formik/EditablePage';
import EditablePage from '@/components/Formik/EditablePage';
import type { Application, ApplicationAddress } from '@/domain/applications';
import type { Option } from '@/domain/base';
import useEnumeration from '@/hooks/useEnumeration';
import { ApplicationsService } from '@/services/applications';
import { EnumerationService } from '@/services/enumeration';
import { OptionsService } from '@/services/options';
import { useFetch } from '@/utils/request';
import { handleError } from '@/utils/utils';
import { formatCurrency } from '@propify/components';
import { message } from 'antd';
import noop from 'lodash/noop';
import type { Moment } from 'moment';
import moment from 'moment';
import { useMemo, type FC } from 'react';
import type { SavedEditableAttributes } from '../Formik/EditableAttributes/types';

interface Props {
  application: Application;
  onUpdateApplication: (application: Application) => void;
}

type AddressType = 'CURRENT' | 'PREVIOUS';
type SectionConfig = {
  title: string;
  patchPrefix: string;
};

const sectionsConfig: Record<AddressType, SectionConfig> = {
  CURRENT: {
    title: 'Current Address',
    patchPrefix: 'currentAddress',
  },
  PREVIOUS: {
    title: 'Previous Address',
    patchPrefix: 'previousAddress',
  },
};

const AddressDetails: FC<Props> = ({ application, onUpdateApplication }) => {
  const { options: stateOptions } = useEnumeration('state');
  const { data: reasonsForMoving = [] } = useFetch(
    {
      fetcher: OptionsService.getByName,
      errorMessage: 'There was an error while loading the denial reasons',
    },
    'application-address-moving-reason',
  );

  const reasonForMovingOptions = useMemo<Option[]>(
    () => [
      { value: '', label: '' },
      ...reasonsForMoving.map((o) => ({ label: o.value, value: o.value })),
    ],
    [reasonsForMoving],
  );

  const { data: countries = [] } = useFetch({
    fetcher: EnumerationService.getCountriesList,
    errorMessage: 'There was an error while loading the countries list',
  });

  const sortedCountries = useMemo(
    () => countries.sort((a, b) => a.name.common.localeCompare(b.name.common)),
    [countries],
  );

  const onSaveApplication = async (
    addressType: AddressType,
    { values }: SavedEditableAttributes,
  ) => {
    const sectionConfigPatchPrefix = sectionsConfig[addressType].patchPrefix;

    ApplicationsService.update(application.id, {
      ...application,
      [sectionConfigPatchPrefix]: {
        ...application[sectionConfigPatchPrefix],
        ...values,
      },
    })
      .then((updatedApplication) => {
        message.success('Address updated successfully');
        onUpdateApplication(updatedApplication);
      })
      .catch((error) => {
        handleError(error, { toastFallbackMessage: 'There was an error updating the address' });
      });
  };

  const getAddressSection = (addressType: AddressType, address?: ApplicationAddress): Section => {
    const sectionConfig = sectionsConfig[addressType];
    return {
      title: sectionConfig.title,
      type: 'EDITABLE_ATTRIBUTES',
      onSave: (attributes) => onSaveApplication(addressType, attributes),
      attributes: [
        {
          value: address?.addr1,
          fieldName: 'addr1',
          path: `${sectionConfig.patchPrefix}.addr1`,
          testId: `${sectionConfig.patchPrefix}.addr1`,
          label: 'Address 1',
          type: 'Text',
          colSize: 4,
        },
        {
          value: address?.addr2,
          fieldName: 'addr2',
          path: `${sectionConfig.patchPrefix}.addr2`,
          testId: `${sectionConfig.patchPrefix}.addr2`,
          label: 'Address 2',
          type: 'Text',
          colSize: 4,
        },
        {
          value: address?.city || '-',
          fieldName: 'city',
          path: `${sectionConfig.patchPrefix}.city`,
          testId: `${sectionConfig.patchPrefix}.city`,
          label: 'City',
          type: 'Text',
          colSize: 4,
        },
        {
          value: address?.state,
          fieldName: 'state',
          path: `${sectionConfig.patchPrefix}.state`,
          testId: `${sectionConfig.patchPrefix}.state`,
          label: 'State',
          type: 'Select',
          options: stateOptions,
          colSize: 4,
        },
        {
          value: address?.postalCode,
          fieldName: 'postalCode',
          path: `${sectionConfig.patchPrefix}.postalCode`,
          testId: `${sectionConfig.patchPrefix}.postalCode`,
          label: 'Zip Code',
          type: 'Text',
          colSize: 4,
        },
        {
          value: address?.country,
          fieldName: 'country',
          path: `${sectionConfig.patchPrefix}.country`,
          testId: `${sectionConfig.patchPrefix}.country`,
          label: 'Country',
          type: 'Select',
          options: sortedCountries.map((c) => ({ label: c.name.common, value: c.cca2 })),
          colSize: 4,
        },
        {
          value: formatCurrency(address?.monthlyRentOrMortgagePaymentAmount),
          fieldName: 'monthlyRentOrMortgagePaymentAmount',
          path: `${sectionConfig.patchPrefix}.monthlyRentOrMortgagePaymentAmount`,
          testId: `${sectionConfig.patchPrefix}.monthlyRentOrMortgagePaymentAmount`,
          label: 'Rent or Mortgage Payments',
          type: 'Number',
          colSize: 4,
        },
        {
          value: address?.moveInDate,
          fieldName: 'moveInDate',
          path: `${sectionConfig.patchPrefix}.moveInDate`,
          testId: `${sectionConfig.patchPrefix}.moveInDate`,
          label: 'Residency Start Date',
          type: 'Date',
          colSize: 4,
          disabledDate: (date: Moment) => date.isAfter(moment()),
        },
        {
          value: address?.was30DaysNoticeGiven,
          fieldName: 'was30DaysNoticeGiven',
          path: `${sectionConfig.patchPrefix}.was30DaysNoticeGiven`,
          testId: `${sectionConfig.patchPrefix}.was30DaysNoticeGiven`,
          label: 'Was 30 Days Notice Given?',
          type: 'Boolean',
          colSize: 4,
        },
        {
          value: address?.reasonForMoving ?? '',
          fieldName: 'reasonForMoving',
          path: `${sectionConfig.patchPrefix}.reasonForMoving`,
          testId: `${sectionConfig.patchPrefix}.reasonForMoving`,
          label: 'Reason for moving',
          type: 'Select',
          options: reasonForMovingOptions,
          colSize: 4,
          fieldStyle: { width: '400px' },
        },
        {
          value: address?.owned,
          fieldName: 'owned',
          path: `${sectionConfig.patchPrefix}.owned`,
          testId: `${sectionConfig.patchPrefix}.owned`,
          label: 'Owned',
          type: 'Boolean',
          readOnly: true,
          colSize: 4,
        },
      ],
    };
  };

  return (
    <EditablePage
      fullWidth
      onSave={noop}
      sections={[
        getAddressSection('CURRENT', application.currentAddress),
        getAddressSection('PREVIOUS', application.previousAddress),
      ]}
    />
  );
};

export default AddressDetails;
