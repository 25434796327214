import { useAppContext } from '@/contexts/AppContext';
import type { NoteUserMentioned } from '@/domain/note';
import { Mentions } from 'antd';
import type { FC } from 'react';

interface Props {
  className?: string;
  value?: string;
  onChange?: (note: string) => void;
  onUserSelected: (user: NoteUserMentioned) => void;
  placeholder?: string;
}

const NoteEditor: FC<Props> = ({ className, onChange, onUserSelected, placeholder, value }) => {
  const { users } = useAppContext();

  return (
    <Mentions
      autoSize={{ minRows: 4 }}
      onSelect={(option) => {
        onUserSelected({ id: Number(option.key), value: option.value! });
      }}
      value={value}
      onChange={onChange}
      className={className}
      placeholder={placeholder}
    >
      {users.map((user) => (
        <Mentions.Option key={user.id.toString()} value={user.displayName}>
          {user.displayName}
        </Mentions.Option>
      ))}
    </Mentions>
  );
};

export default NoteEditor;
