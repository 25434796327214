import type { AmenityType } from '@/domain/amenity';
import type { Enum } from '@/domain/base';
import type { BrandSearchResult } from '@/domain/brand';
import type { TicketGroup } from '@/domain/ticket-groups';
import type { User, UserLookup } from '@/domain/user';
import type { DefaultUserGroups, UserGroup } from '@/domain/userGroups';
import type { Venture } from '@/domain/venture';
import noop from 'lodash/noop';
import { createContext, useContext } from 'react';
import type { KeyedMutator } from 'swr/dist/types';

interface AppContextModel {
  users: UserLookup[];
  usersByLoginId: Record<number, UserLookup>;
  loadingUsers: boolean;
  refetchCurrentUser: () => void;
  agents: UserLookup[];
  agentNameById: Record<number, string>;
  currentUser?: User;
  loadingCurrentUser: boolean;
  brands: BrandSearchResult[];
  ventures: Venture[];
  states: Enum[];
  userGroups: UserGroup[];
  userGroupsById: Record<number, string>;
  updateUserGroups: KeyedMutator<UserGroup[]>;
  defaultUserGroups: DefaultUserGroups | undefined;
  ticketGroups: TicketGroup[];
  updateTicketGroups: KeyedMutator<TicketGroup[]>;
  amenityTypes: AmenityType[];
}

export const AppContext = createContext<AppContextModel>({
  agents: [],
  users: [],
  agentNameById: {},
  usersByLoginId: {},
  refetchCurrentUser: noop,
  loadingUsers: true,
  loadingCurrentUser: false,
  brands: [],
  ventures: [],
  states: [],
  userGroups: [],
  userGroupsById: [],
  updateUserGroups: () => Promise.resolve([]),
  defaultUserGroups: undefined,
  ticketGroups: [],
  updateTicketGroups: () => Promise.resolve([]),
  amenityTypes: [],
});

export const useAppContext = () => useContext(AppContext);
