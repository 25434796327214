import AuditsTable from '@/components/Audits';
import { ModalFormButton } from '@/components/Button/formButton';
import EditablePage from '@/components/Formik/EditablePage';
import HashTabs from '@/components/HashTab/hashTab';
import type { Action } from '@/components/Page';
import Page from '@/components/Page';
import SectionSplashScreen from '@/components/PageLoading/SplashSection';
import CarryoverResidents from '@/components/Renewals/CarryoverResidents/CarryoverResidents';
import RenewalOccupantModal from '@/components/Renewals/Occupants/RenewalOccupantModal';
import RenewalOccupantsTab from '@/components/Renewals/Occupants/RenewalOccupantsTab';
import ProspectiveResidents from '@/components/Renewals/ProspectiveResidents/ProspectiveResidents';
import RenewalsSetProposalTermsModalButton from '@/components/Renewals/RenewalsSetProposalTermsModalButton/RenewalsSetProposalTermsModalButton';
import type { ApplicationSearchResult } from '@/domain/applications';
import { EntityType } from '@/domain/entity-type';
import type { LeasingSearchResult } from '@/domain/leasing';
import { ProcessType } from '@/domain/process';
import type { RenewalDTO } from '@/domain/renewal';
import { RenewalApplicationStatus, RenewalStatus } from '@/domain/renewal';
import { CollectionStatus } from '@/domain/resident-collection';
import DenyRenewalModal from '@/pages/prospects/renewals/components/DenyRenewalModal';
import { ApplicationsService } from '@/services/applications';
import { LeaseService } from '@/services/leases';
import { LeasingService } from '@/services/leasings';
import { NoteService } from '@/services/notes';
import { RenewalService } from '@/services/renewal';
import ResidentCollectionsService from '@/services/resident-collections';
import { useFetch } from '@/utils/request';
import { getAddressDescription, handleError } from '@/utils/utils';
import { WarningOutlined } from '@ant-design/icons';
import { Alert, Button, Col, message, Modal, Popconfirm, Row, Space, Statistic, Tabs } from 'antd';
import type { FormikHelpers } from 'formik';
import moment from 'moment';
import { Fragment, useCallback, useState } from 'react';
import { useParams } from 'react-router';
import { Link } from 'react-router-dom';
import type { InviteCoSignerValues } from '../../components/InviteCoSignerModal/InviteCoSignerModal';
import InviteCoSignerModal from '../../components/InviteCoSignerModal/InviteCoSignerModal';
import Notes from '../../unit-applications/components/Notes';
import { AddVehicle } from './AddVehicle';
import { AddPet } from './pets/AddPet';
import RenewalPetsWrapper from './pets/RenewalPetsWrapper';
import RemovePrimaryApplicantModal from './RemovePrimaryApplicantModal';
import RenewalsOverview from './RenewalsOverview';
import RenewalVehiclesWrapper from './RenewalVehiclesWrapper';

type TabKey = 'overview' | 'pets' | 'vehicles' | 'occupants' | 'audits';

interface Tab {
  key: TabKey;
  title: string;
  actions?: Action[];
}

export interface ApplicationToRemove {
  personId: number;
  personName: string;
  renewalApplicationId: number;
}

export const hideButton = (status: RenewalStatus) =>
  [
    RenewalStatus.ACCEPTED,
    RenewalStatus.RENEWED,
    RenewalStatus.REFUSED,
    RenewalStatus.DECLINED,
    RenewalStatus.CANCELLED,
  ].includes(status);

const RenewalsDetail = () => {
  const { id: idParam } = useParams();
  const [isInviting, setIsInviting] = useState(false);
  const [inviteCoSignerModalVisible, setInviteCoSignerModalVisible] = useState(false);
  const [removingApplication, setRemovingApplication] = useState(false);
  const [applicationToRemove, setApplicationToRemove] = useState<ApplicationToRemove>();
  const [showRemovePrimaryApplicationModal, setShowRemovePrimaryApplicationModal] = useState(false);
  const id = Number(idParam);

  const { data: renewal, mutate: refetchRenewal } = useFetch(
    {
      fetcher: id ? RenewalService.get : undefined,
      errorMessage: 'There was an error fetching the renewal',
    },
    id,
  );

  const {
    data: renewalApplications,
    mutate: refetchRenewalApplications,
    isValidating: loadingRenewalApplications,
  } = useFetch(
    {
      fetcher: renewal?.id ? ApplicationsService.search : undefined,
      errorMessage: 'There was an error fetching the applications',
    },
    { renewalId: renewal?.id, excludeCancelledRenewalApplications: false },
  );

  const { data: lease } = useFetch(
    {
      fetcher: renewal?.leaseId ? LeaseService.findById : undefined,
      errorMessage: 'There was an error fetching the lease',
    },
    renewal?.leaseId || 0,
  );

  const { data: renewalAnalytics, mutate: refetchRenewalAnalytics } = useFetch(
    {
      fetcher: renewal?.id ? RenewalService.findAnalyticsById : undefined,
      errorMessage: 'There was an error getting the renewal analytics',
    },
    renewal?.id || 0,
  );

  const { data: collectionsResult = [] } = useFetch(
    {
      fetcher: lease?.residentId ? ResidentCollectionsService.search : undefined,
      errorMessage: 'There was an error getting the renewal analytics',
    },
    {
      status: [
        CollectionStatus.OUTSTANDING_BALANCE,
        CollectionStatus.PENDING_EVICTION,
        CollectionStatus.EVICTION,
        CollectionStatus.COLLECTIONS_AGENCY,
      ],
      residentId: lease?.residentId,
    },
  );

  const {
    data: notes = [],
    isValidating: loadingNotes,
    mutate: refetchNotes,
  } = useFetch(
    {
      fetcher: renewal?.id ? NoteService.search : undefined,
      errorMessage: 'There was an error while fetching notes',
    },
    {
      entityId: renewal?.id || 0,
      entityType: EntityType.RENEWAL,
    },
  );

  const onScreeningResultUpdate = useCallback(
    (application: ApplicationSearchResult) => {
      refetchRenewalApplications(
        (currentApplications) =>
          currentApplications?.map((a) => (a.id === application.id ? application : a)),
        false,
      );
    },
    [refetchRenewalApplications],
  );

  const onApplicantsUpdate = useCallback(() => {
    refetchRenewalApplications();
    refetchRenewal();
    refetchRenewalAnalytics();
  }, [refetchRenewal, refetchRenewalAnalytics, refetchRenewalApplications]);

  const tabs: Tab[] = [
    {
      key: 'overview',
      title: 'Overview',
    },
    {
      key: 'pets',
      title: 'Pets',
      actions: [
        {
          key: 'addPet',
          type: 'modalFormButton',
          buttonType: 'primary',
          buttonText: 'Add Pet',
          onSuccess: () => refetchRenewal(),
          disabled: !renewal?.id,
          children: (props) => <AddPet {...props} renewalId={renewal!.id} />,
        },
      ],
    },
    {
      key: 'vehicles',
      title: 'Vehicles',
      actions: [
        {
          key: 'addVehicle',
          type: 'modalFormButton',
          buttonType: 'primary',
          buttonText: 'Add Vehicle',
          disabled: !renewal?.id,
          onSuccess: () => refetchRenewal(),
          children: (props) => <AddVehicle {...props} renewalId={renewal!.id} />,
        },
      ],
    },
    {
      key: 'occupants',
      title: 'Occupants',
      actions: [
        {
          key: 'addOccupant',
          type: 'modalFormButton',
          buttonType: 'primary',
          buttonText: 'Add Occupant',
          disabled: !renewal?.id,
          onSuccess: () => refetchRenewal(),
          children: (props) => <RenewalOccupantModal {...props} renewalId={renewal!.id} />,
        },
      ],
    },
    {
      key: 'audits',
      title: 'Audits',
    },
  ];
  const currentTab = tabs.find((t) => t.key === (location.hash || '').substring(1)) || tabs[0];

  if (!renewal || !lease) {
    return <SectionSplashScreen />;
  }

  const onApprove = () => {
    RenewalService.update({
      ...renewal,
      status: RenewalStatus.APPROVED,
    })
      .then(() => {
        refetchRenewal();
        message.success('Renewal successfully updated');
      })
      .catch((error) => {
        handleError(error, { toastFallbackMessage: 'Something went wrong updating the renewal' });
      });
  };

  const declineButton = () => (
    <Fragment key="deny">
      <ModalFormButton
        buttonText="Decline Renewal"
        onSuccess={() => refetchRenewal()}
        buttonClassName="danger"
      >
        {(props) => <DenyRenewalModal {...props} renewalId={renewal.id} />}
      </ModalFormButton>
    </Fragment>
  );

  const confirmEditSigners = async () => {
    const leasingId = renewal.associatedProcesses
      .filter((process) => process.processType === ProcessType.LEASING)
      .map((process) => process.processId)
      .sort()
      .slice(-1)[0]; // Get the last one

    if (!leasingId) {
      return;
    }

    let leasing: LeasingSearchResult;
    try {
      leasing = await LeasingService.findById(leasingId);
    } catch (error) {
      handleError(error, {
        toastFallbackMessage: 'There was an error fetching the leasing',
      });
      return;
    }

    try {
      await LeasingService.cancel(leasing.id, leasing.version, { comment: 'Edit signers.' });
    } catch (error) {
      handleError(error, {
        toastFallbackMessage: 'There was an error trying to update the leasing',
      });
      return;
    }

    message.success('The leasing was cancelled');

    let response: RenewalDTO;
    try {
      response = await RenewalService.get(renewal.id);
    } catch (error) {
      handleError(error, {
        toastFallbackMessage: 'There was an error fetching the renewal',
      });
      return;
    }

    try {
      const newRenewal = await RenewalService.update({
        ...response,
        status: RenewalStatus.OFFER_PENDING,
      });
      refetchRenewal(newRenewal, false);
    } catch (error) {
      handleError(error, {
        toastFallbackMessage: 'There was an error trying to update the renewal',
      });
    }
  };

  const handleEditSigners = () => {
    Modal.confirm({
      width: '750px',
      icon: null,
      content: (
        <Alert
          icon={<WarningOutlined />}
          message={
            <>
              <p>
                This action will return the renewal to a status of <strong>Offer Pending.</strong>
              </p>
              <p>
                You will be able to edit terms and add or remove residents from the renewal. The
                primary resident will need to accept renewal terms again if this action is
                completed.{' '}
                <strong>
                  THIS ACTION WILL VOID A LEASE ASSOCIATED WITH THIS RENEWAL AND CANCEL THE LEASING
                  PROCESS.
                </strong>
              </p>
              <p>Are you sure you want to re-edit the terms of this renewal?</p>
            </>
          }
          type="warning"
          showIcon
        />
      ),
      title: 'Warning',
      okText: 'Confirm',
      cancelText: 'Cancel',
      onOk() {
        confirmEditSigners();
      },
    });
  };

  const getExtra = () => {
    if (renewal.status === RenewalStatus.NEW) {
      return [
        declineButton(),
        <Popconfirm
          key="approve"
          title="Are you sure?"
          onConfirm={() => onApprove()}
          okText="Yes"
          cancelText="No"
        >
          <Button size="small" style={{ marginRight: 5 }} className="green">
            Approve
          </Button>
        </Popconfirm>,
      ];
    }

    if (
      [
        RenewalStatus.OFFERED,
        RenewalStatus.OFFER_PENDING,
        RenewalStatus.COUNTERED,
        RenewalStatus.NEGOTIATION,
      ].includes(renewal.status) ||
      (renewal.status === RenewalStatus.APPROVED &&
        renewalAnalytics?.lastUnitMarketRentUpdateTime &&
        moment(renewalAnalytics.lastUnitMarketRentUpdateTime).isAfter(
          moment().startOf('day').subtract(30, 'days'),
        ))
    ) {
      return [
        <RenewalsSetProposalTermsModalButton
          key="proposalTerms"
          renewalId={renewal.id}
          refreshEntities={refetchRenewal}
          showOnlySaveProposalButton={
            renewal.status === RenewalStatus.APPROVED &&
            moment(renewalAnalytics?.lastUnitMarketRentUpdateTime).isAfter(
              moment().startOf('day').subtract(30, 'days'),
            )
          }
        />,
        ...(renewal.status !== RenewalStatus.NEGOTIATION ? [declineButton()] : []),
      ];
    }

    if (renewal.status === RenewalStatus.APPROVED) {
      return [declineButton()];
    }

    return [];
  };

  const handleInviteCoSignerSubmit = (
    values: InviteCoSignerValues,
    helpers: FormikHelpers<InviteCoSignerValues>,
  ) => {
    const { emailAddress, type } = values;

    const typeLabel = type === 'COSIGNER' ? 'Co-Signer' : 'Guarantor';

    setIsInviting(true);
    RenewalService.createApplication(renewal.id, { emailAddress, type })
      .then(() => {
        message.success(`${typeLabel} invited successfully`);
        helpers.resetForm();
        setInviteCoSignerModalVisible(false);
        refetchRenewal();
      })
      .catch((err) => {
        handleError(err, {
          toastFallbackMessage: `There has been an error while inviting the ${typeLabel}`,
        });
      })
      .finally(() => {
        setIsInviting(false);
        helpers.setSubmitting(false);
      });
  };

  const handleRemoveApplication = (renewalApplicationId: number) => {
    setRemovingApplication(true);
    RenewalService.deleteApplication(renewal.id, renewalApplicationId)
      .then(() => {
        onApplicantsUpdate();
        message.success('Application removed successfully');
      })
      .catch((err) => {
        handleError(err, {
          toastFallbackMessage: 'Something went wrong removing the application',
        });
      })
      .finally(() => {
        setRemovingApplication(false);
        setApplicationToRemove(undefined);
      });
  };

  const carryoverResidentsApplications = renewal.applications.filter(
    (a) => a.personId && !a.applicationId && a.status !== RenewalApplicationStatus.REJECTED,
  );

  const getExtraButton = () => {
    if (renewal && renewal.status !== RenewalStatus.NEW && !hideButton(renewal.status)) {
      return (
        <Button type="primary" onClick={() => setInviteCoSignerModalVisible(true)}>
          Invite Co-Signer
        </Button>
      );
    }

    return <></>;
  };

  return (
    <Page
      fullHeight
      extra={getExtra()}
      content={
        <>
          <Statistic title="Id" valueRender={() => renewal.id} />
          <Statistic
            title="Address"
            valueRender={() =>
              lease?.unit?.property?.address ? (
                <Link to={`/properties/${lease.unit.property.id}`}>
                  {getAddressDescription(lease.unit.property.address)}
                </Link>
              ) : null
            }
          />
        </>
      }
      actions={[
        ...(currentTab.actions || []),
        ...(renewal.status === RenewalStatus.ACCEPTED &&
        renewal.associatedProcesses.some((ap) => ap.processType === ProcessType.LEASING)
          ? [
              {
                key: 'editSigners',
                type: 'button',
                onClick: () => handleEditSigners(),
                children: 'Edit Signers',
              } as Action,
            ]
          : []),
      ]}
    >
      <HashTabs
        className="propify-subheader-tabs"
        activeKey={currentTab.key}
        defaultActiveKey="overview"
      >
        {tabs.map((tab) => (
          <Tabs.TabPane key={tab.key} tab={tab.title} style={{ padding: '10px 15px' }}>
            {tab.key === 'overview' && (
              <>
                <Row gutter={24}>
                  <Col xs={14}>
                    {renewal ? (
                      <RenewalsOverview
                        renewal={renewal}
                        lease={lease}
                        totalValidatedIncome={renewalAnalytics?.totalValidatedIncome}
                        rentToVerifiedIncome={renewalAnalytics?.rentToVerifiedIncome}
                        onRenewalUpdate={() => refetchRenewal()}
                      />
                    ) : null}
                  </Col>
                  <Col xs={10}>
                    <Notes
                      notes={notes}
                      entityId={renewal.id || 0}
                      entityType={EntityType.RENEWAL}
                      refetchNotes={refetchNotes}
                      loadingNotes={loadingNotes}
                    />
                  </Col>
                </Row>
                {collectionsResult.length > 0 && (
                  <Row gutter={24}>
                    <Col xs={24}>
                      <Alert
                        icon={<WarningOutlined />}
                        message={
                          <span>
                            One or more individuals involved in this Renewal has an{' '}
                            <Link to="/residents/collections">active Collections process</Link>
                          </span>
                        }
                        type="warning"
                        showIcon
                      />
                    </Col>
                  </Row>
                )}

                <Row gutter={24}>
                  <Col xs={24}>
                    <EditablePage
                      fullWidth
                      singleColumn
                      sections={[
                        {
                          title: 'Carryover Residents',
                          type: 'WRAPPER',
                          breakAfter: false,
                          attributes: [],
                          children: (
                            <CarryoverResidents
                              refetchRenewal={refetchRenewal}
                              renewalId={renewal.id}
                              renewalStatus={renewal.status}
                              renewalApplications={carryoverResidentsApplications}
                              onRemoveApplication={setApplicationToRemove}
                              refetchRenewalApplications={refetchRenewalApplications}
                              onRemovePrimaryApplication={() =>
                                setShowRemovePrimaryApplicationModal(true)
                              }
                            />
                          ),
                        },
                      ]}
                    />
                  </Col>
                </Row>

                <Row gutter={24}>
                  <Col xs={24}>
                    <EditablePage
                      fullWidth
                      singleColumn
                      sections={[
                        {
                          title: 'Prospective Residents',
                          extraTitle: getExtraButton(),
                          type: 'WRAPPER',
                          breakAfter: false,
                          attributes: [],
                          children: (
                            <ProspectiveResidents
                              renewal={renewal}
                              renewalApplications={renewalApplications}
                              loadingRenewalApplications={loadingRenewalApplications}
                              onRemoveRenewalApplication={setApplicationToRemove}
                              onRemovePrimaryApplication={() => {
                                setShowRemovePrimaryApplicationModal(true);
                              }}
                              onApplicationUpdate={onApplicantsUpdate}
                              onApplicationDelete={onApplicantsUpdate}
                              onScreeningResultUpdate={onScreeningResultUpdate}
                            />
                          ),
                        },
                      ]}
                    />
                  </Col>
                </Row>
              </>
            )}
            {tab.key === 'pets' && (
              <RenewalPetsWrapper renewal={renewal} refetch={() => refetchRenewal()} />
            )}
            {tab.key === 'vehicles' && (
              <RenewalVehiclesWrapper renewal={renewal} refetch={() => refetchRenewal()} />
            )}
            {tab.key === 'occupants' && (
              <RenewalOccupantsTab renewal={renewal} refetchRenewal={() => refetchRenewal()} />
            )}
            {tab.key === 'audits' && (
              <AuditsTable entityType={EntityType.RENEWAL} entityId={renewal.id} />
            )}
          </Tabs.TabPane>
        ))}
      </HashTabs>

      {inviteCoSignerModalVisible && (
        <InviteCoSignerModal
          onCancel={() => setInviteCoSignerModalVisible(false)}
          loading={isInviting}
          onSubmit={handleInviteCoSignerSubmit}
        />
      )}

      {applicationToRemove && (
        <Modal
          visible
          onCancel={() => setApplicationToRemove(undefined)}
          onOk={() => handleRemoveApplication(applicationToRemove.renewalApplicationId)}
          title="Confirm Resident Removal"
          okText="Confirm Removal"
          okButtonProps={{ danger: true, loading: removingApplication }}
          cancelText="Cancel"
        >
          <Space direction="vertical" size={18}>
            <Alert
              showIcon
              type="warning"
              message={
                <span>
                  Are you sure you want to remove{' '}
                  <Link to={`/person/${applicationToRemove.personId}`} target="_blank">
                    {applicationToRemove.personName}
                  </Link>{' '}
                  from this Lease?
                </span>
              }
            />
            {renewal?.status === RenewalStatus.COUNTERED ? (
              <Alert
                showIcon
                type="warning"
                message={
                  <div>
                    <strong>This Renewal is in a Countered status.</strong> Renewal Terms must be
                    resubmitted before the applicants will be able to view and respond to the
                    proposed terms (once all non-removed applicants are approved).
                  </div>
                }
              />
            ) : null}
          </Space>
        </Modal>
      )}

      {showRemovePrimaryApplicationModal && (
        <RemovePrimaryApplicantModal
          renewal={renewal}
          onRemove={onApplicantsUpdate}
          renewalApplications={renewal.applications}
          onClose={() => setShowRemovePrimaryApplicationModal(false)}
        />
      )}
    </Page>
  );
};

export default RenewalsDetail;
