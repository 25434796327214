import type { ModalFormProps } from '@/components/Button/formButton';
import { AntSelect, AntTextArea } from '@/components/Formik/CreateAntField';
import type { Application } from '@/domain/applications';
import type { UiOption } from '@/domain/base';
import { RenewalStatus, type RenewalDTO } from '@/domain/renewal';
import { ApplicationsService } from '@/services/applications';
import { OptionsService } from '@/services/options';
import { useFetch } from '@/utils/request';
import type { FormikSubmitFn } from '@/utils/types';
import { handleError } from '@/utils/utils';
import { Alert, Form, message, Modal, Space, Typography } from 'antd';
import { Field, Formik } from 'formik';
import type { FC } from 'react';
import * as Yup from 'yup';
import { getBooleanStatus, getCreditRecommendationStatus } from '../ApprovalIcon';

export type Values = {
  resolution: string;
  resolutionType: string;
};

interface Props extends ModalFormProps<[Application]> {
  approve?: boolean;
  application: Application;
  isUnitApplication?: boolean;
  renewal?: RenewalDTO;
}

const fieldValidation = Yup.string().typeError('Required').trim('Required').required('Required');

const denyValidationSchema = Yup.object({
  resolution: fieldValidation,
  resolutionType: fieldValidation,
});

const approveValidationSchema = Yup.object({
  resolution: fieldValidation,
});

export const ApplicationResolutionModal: FC<Props> = ({
  onSuccess,
  onCancel,
  approve = false,
  application,
  isUnitApplication,
  renewal,
}) => {
  const { data: denialReasons = [] } = useFetch(
    {
      fetcher: OptionsService.getByName,
      errorMessage: 'There was an error while loading the denial reasons',
    },
    'application-denial-reason',
  );

  const resolveApplication: FormikSubmitFn<Values> = (values, { setSubmitting }) => {
    let resolvePromise: Promise<Application>;

    if (approve) {
      resolvePromise = ApplicationsService.approve(application.id, application.version, {
        resolution: values.resolution,
      });
    } else {
      resolvePromise = ApplicationsService.deny(application.id, application.version, {
        resolution: values.resolution,
        resolutionType: values.resolutionType,
      });
    }

    setSubmitting(true);
    resolvePromise
      ?.then((app) => {
        message.success(`Application successfully ${approve ? 'approved' : 'denied'}`);
        onSuccess(app);
      })
      .catch((error) => {
        handleError(error, {
          toastFallbackMessage: `There was an error ${
            approve ? 'approving' : 'denying'
          } the application`,
        });
      })
      .finally(() => {
        setSubmitting(false);
      });
  };

  const allConfirmationsApproved = [
    getCreditRecommendationStatus(application.creditRecommendations),
    getBooleanStatus(application.criminalRecommendation),
    getBooleanStatus(application.backgroundRecommendation),
  ].every((s) => s === 'accepted');

  return (
    <Formik<Values>
      onSubmit={resolveApplication}
      validationSchema={approve ? approveValidationSchema : denyValidationSchema}
      initialValues={{
        resolution: '',
        resolutionType: '',
      }}
    >
      {({ handleSubmit, submitCount, submitForm, isSubmitting, isValid }) => (
        <Modal
          visible
          onCancel={isSubmitting ? undefined : onCancel}
          onOk={submitForm}
          okText={approve ? (allConfirmationsApproved ? 'Approve' : 'Confirm Approval') : 'Deny'}
          title={
            approve
              ? allConfirmationsApproved
                ? 'Approve Application'
                : 'Confirm Applicant Approval'
              : 'Confirm Prospect Application Denial'
          }
          maskClosable={false}
          okButtonProps={{
            loading: isSubmitting,
            disabled: isSubmitting || !isValid,
          }}
          cancelButtonProps={{ disabled: isSubmitting }}
        >
          <Space direction="vertical" style={{ width: '100%' }}>
            {renewal?.status === RenewalStatus.COUNTERED ? (
              <Alert
                message={
                  <Typography.Text>
                    <strong>This Renewal is in a Countered status.</strong> Renewal Terms must be
                    resubmitted before the applicants will be able to view and respond to the
                    proposed terms (once all non-removed applicants are approved).
                  </Typography.Text>
                }
                showIcon
                type="warning"
              />
            ) : null}
            {!allConfirmationsApproved && !isUnitApplication && (
              <Alert
                type="warning"
                showIcon
                message="Denied applicants must be removed from a Renewal before the Renewal Terms will
              display to any remaining applicants. Removing denied applicant(s) when all other
              applicants are either approved or carryover residents will result in Renewal Terms
              immediately displaying to remaining applicants."
              />
            )}
            <Form labelCol={{ span: 24 }} wrapperCol={{ span: 24 }} onSubmitCapture={handleSubmit}>
              {!approve && (
                <Field
                  name="resolutionType"
                  label="Denial reason:"
                  component={AntSelect}
                  submitCount={submitCount}
                  selectOptions={denialReasons}
                  getOptionLabel={(option: UiOption) => option.value}
                  getOptionValue={(option: UiOption) => option.value}
                  style={{ width: '100%' }}
                />
              )}
              <Typography.Text>{approve ? 'Resolution note:' : 'Comments:'}</Typography.Text>
              <Field
                component={AntTextArea}
                name="resolution"
                type="text"
                hasFeedback
                submitCount={submitCount}
                style={{ width: '100%', minHeight: '100px' }}
              />
            </Form>
          </Space>
        </Modal>
      )}
    </Formik>
  );
};
