import type { ModalFormProps } from '@/components/Button/formButton';
import PropertiesTable from '@/components/PropertiesTable/PropertiesTable';
import type { FilterParams } from '@/components/PropertiesTableFilters/PropertiesTableFilters';
import PropertiesTableFilters from '@/components/PropertiesTableFilters/PropertiesTableFilters';
import type { PropertySearchResult } from '@/domain/property';
import type { PropertyGroup } from '@/domain/property-groups';
import { useProperties } from '@/hooks/services/useProperties';
import { usePropertiesTableColumns } from '@/hooks/usePropertiesTableColumns';
import type { PropertySearchParams } from '@/services/property';
import { PropertyGroupService } from '@/services/property-groups';
import { settings } from '@/services/settings';
import { handleError } from '@/utils/utils';
import { Alert, message, Modal, Space } from 'antd';
import { useMemo, useState, type FC } from 'react';
import styles from './styles.module.less';

export interface Props extends ModalFormProps {
  propertyGroup: PropertyGroup;
}

const tableSettings = settings('properties');

const AddPropertiesToPropertyGroupModal: FC<Props> = ({ propertyGroup, onSuccess, onCancel }) => {
  const [loading, setLoading] = useState(false);
  const [selection, setSelection] = useState<number[]>([]);
  const [filters, setFilters] = useState<FilterParams>({});
  const { columns: tableColumns } = usePropertiesTableColumns({
    columns: [
      'address.addr1',
      'address.city',
      'address.state',
      'msa',
      'transactionStatus',
      'ventureId',
      'brandId',
      'holdingCompanyId',
      'community',
      'propertyGroups',
    ],
    propertyLinkOnNewTab: true,
  });

  const { data: allProperties = [], isValidating: loadingProperties } = useProperties(
    filters as PropertySearchParams,
  );

  const { data: propertyGroupProperties = [] } = useProperties({
    propertyGroupId: propertyGroup.id,
  });

  const properties = useMemo(
    () =>
      allProperties.filter((p) =>
        propertyGroupProperties.every((property: PropertySearchResult) => property.id !== p.id),
      ),
    [allProperties, propertyGroupProperties],
  );

  const handleSubmit = async () => {
    if (selection.length === 0) {
      return;
    }

    try {
      setLoading(true);
      await PropertyGroupService.bulkAddProperties(propertyGroup.id, selection);
      message.success('Properties added to property group');
      onSuccess();
    } catch (error) {
      handleError(error, {
        toastFallbackMessage: 'There was an error adding the properties to the property group',
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal
      title="Add Properties to Property Group"
      visible
      onCancel={onCancel}
      onOk={() => handleSubmit()}
      width="98vw"
      okText="Add Selected Properties to Group"
      okButtonProps={{ loading, disabled: selection.length === 0 }}
    >
      <div data-testid="modal-add-properties">
        <Space direction="vertical" size={10} style={{ width: '100%' }}>
          <Alert
            message={`The ${selection.length} selected properties will be added to ${propertyGroup.name}`}
            type="info"
            showIcon
          />
          <PropertiesTableFilters
            columns={tableColumns}
            filters={filters}
            setFilters={setFilters}
            properties={properties}
            tableSettings={tableSettings}
            showExport={false}
          />
          <PropertiesTable
            properties={properties}
            columns={tableColumns}
            selection={selection}
            setSelection={setSelection}
            tableSettings={tableSettings}
            loadingProperties={loadingProperties}
            className={styles.addPropertiesTable}
          />
        </Space>
      </div>
    </Modal>
  );
};

export default AddPropertiesToPropertyGroupModal;
