import type { New } from '@/domain/base';
import type { EmailAddress } from '@/domain/emails';
import type { PartyLookupParams, Person, PersonDuplicateResult } from '@/domain/person';
import type { Phone } from '@/domain/phone';
import LookupService from '@/services/lookup';
import { api } from '@/utils/configuration';
import { crudService, getAxiosFetcher } from '@/utils/request';
import type { Operation } from 'fast-json-patch';

export const PersonService = {
  findById: getAxiosFetcher<Person, [id: number]>((id) => [`${api.PERSON}/${id}`]),

  create: (prospect: Partial<Person>) => crudService.post<Person>(prospect, api.PERSON),

  update: (prospect: Partial<Person>) =>
    crudService.put<Person>(prospect, `${api.PERSON}/${prospect.id}`),

  patch: (id: number, operations: Operation[], version: number): Promise<Person> =>
    crudService.patch<Person>(operations, `${api.PERSON}/${id}?version=${version}`),

  lookup: (params: PartyLookupParams) => LookupService.party(params),

  findDuplicates: getAxiosFetcher<PersonDuplicateResult[], [number]>((id) => [
    `${api.PERSON}/${id}/duplicates`,
  ]),

  invite: (id: number) => crudService.post<void>({}, `${api.PERSON}/${id}/invite`),

  updatePrimaryPhone: (id: number, currentIndex: number, nextIndex: number, version: number) =>
    PersonService.patch(
      id,
      [
        {
          op: 'replace',
          path: `/phones/${currentIndex}/primary`,
          value: false,
        },
        {
          op: 'replace',
          path: `/phones/${nextIndex}/primary`,
          value: true,
        },
      ],
      version,
    ),

  updatePrimaryEmail: (id: number, currentIndex: number, nextIndex: number, version: number) =>
    PersonService.patch(
      id,
      [
        {
          op: 'replace',
          path: `/emailAddresses/${currentIndex}/primary`,
          value: false,
        },
        {
          op: 'replace',
          path: `/emailAddresses/${nextIndex}/primary`,
          value: true,
        },
      ],
      version,
    ),

  bulkUpdate: (change: Record<string, any>) => crudService.put<void>(change, `${api.PERSON}`),

  addPhone: (id: number, version: number, { type, rawNumber, primary }: New<Phone>) => {
    const operation: Operation = {
      op: 'add',
      path: `/phones/-`,
      value: { type, rawNumber, primary },
    };

    return PersonService.patch(id, [operation], version);
  },

  addEmail: (id: number, version: number, { address, primary }: New<EmailAddress>) => {
    const operation: Operation = {
      op: 'add',
      path: `/emailAddresses/-`,
      value: { address, primary },
    };

    return PersonService.patch(id, [operation], version);
  },

  removePhone: (id: number, version: number, phoneIndex: number) => {
    const operation: Operation = {
      op: 'remove',
      path: `/phones/${phoneIndex}`,
    };

    return PersonService.patch(id, [operation], version!);
  },

  removeEmail: (id: number, version: number, index: number) => {
    const operation: Operation = {
      op: 'remove',
      path: `/emailAddresses/${index}`,
    };

    return PersonService.patch(id, [operation], version);
  },

  findByResidentId: getAxiosFetcher<Person[], [residentId: number]>((residentId) => [
    `${api.PERSON}?residentId=${residentId}`,
  ]),
};
