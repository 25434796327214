import type { ColumnType } from '@/notmagic/components/EntityTable/types';
import APPLICATION_APPROVAL from './APPLICATION_APPROVAL';
import APPLICATION_RECOMMENDATION from './APPLICATION_RECOMMENDATION';
import APPROVAL from './APPROVAL';
import APPROVE_UNIT_APPLICATION from './APPROVE_UNIT_APPLICATION';
import BOOLEAN from './BOOLEAN';
import COMMON_ACCESS_INFORMATION from './COMMON_ACCESS_INFORMATION';
import COUNT_MODAL from './COUNT_MODAL';
import CREATE_TICKET from './CREATE_TICKET';
import DATETIME from './DATETIME';
import ENTITY from './ENTITY';
import ENUM from './ENUM';
import FILTER from './FILTER';
import GENERIC from './GENERIC';
import ICON from './ICON';
import LEAD_MESSAGES from './LEAD_MESSAGES';
import LOCAL_DATE from './LOCAL_DATE';
import LOCAL_TIME from './LOCAL_TIME';
import NEXT_PAYMENT_DUE from './NEXT_PAYMENT_DUE';
import NOTE from './NOTE';
import NUMBER from './NUMBER';
import PROPERTY_GROUPS from './PROPERTY_GROUPS';
import RENEWALS_REOPEN from './RENEWALS_REOPEN';
import RENEWALS_SET_PROPOSAL_TERMS from './RENEWALS_SET_PROPOSAL_TERMS';
// eslint-disable-next-line import/order
import RENEWAL_ACTIONS from './RENEWAL_ACTIONS';
import TASK_COMPLETE_ACTION from './TASK_COMPLETE_ACTION';
import TASK_HISTORY from './TASK_HISTORY';
import TASK_NAME from './TASK_NAME';
import TIME_AGO from './TIME_AGO';
import UNIT_RENTAL_PRICING_BASE_RENT from './UNIT_RENTAL_PRICING_BASE_RENT';
import UNIT_RENTAL_PRICING_PROMOTIONS from './UNIT_RENTAL_PRICING_PROMOTIONS';
import WORK_LEAD from './WORK_LEAD';
import WORK_ORDER_DEFERRED_UNTIL from './WORK_ORDER_DEFERRED_UNTIL';
import WORK_ORDER_JOB from './WORK_ORDER_JOB';

const columnTypes: Record<string, ColumnType> = {
  BOOLEAN,
  DATETIME,
  ENTITY,
  ENUM,
  FILTER,
  GENERIC,
  ICON,
  LEAD_MESSAGES,
  LOCAL_DATE,
  LOCAL_TIME,
  NEXT_PAYMENT_DUE,
  NOTE,
  NUMBER,
  TASK_COMPLETE_ACTION,
  TASK_HISTORY,
  TIME_AGO,
  APPLICATION_APPROVAL,
  APPLICATION_RECOMMENDATION,
  APPROVAL,
  APPROVE_UNIT_APPLICATION,
  COMMON_ACCESS_INFORMATION,
  COUNT_MODAL,
  CREATE_TICKET,
  PROPERTY_GROUPS,
  RENEWALS_REOPEN,
  RENEWALS_SET_PROPOSAL_TERMS,
  RENEWAL_ACTIONS,
  TASK_NAME,
  UNIT_RENTAL_PRICING_BASE_RENT,
  UNIT_RENTAL_PRICING_PROMOTIONS,
  WORK_LEAD,
  WORK_ORDER_DEFERRED_UNTIL,
  WORK_ORDER_JOB,
};

export default columnTypes;
