import RenewalsSetProposalTermsModalButton from '@/components/Renewals/RenewalsSetProposalTermsModalButton/RenewalsSetProposalTermsModalButton';
import { RenewalStatus } from '@/domain/renewal';
import type {
  ColumnComponentType,
  ColumnComponentTypeProps,
} from '@/notmagic/components/EntityTable/types';

type RowType = {
  renewalId: number;
  renewalStatus: RenewalStatus;
  pendingProposal?: boolean;
};

type Props = ColumnComponentTypeProps<RowType>;

const Component: ColumnComponentType<Props> = ({ value, refreshEntities }) => {
  // Must set `"value": {props in RowType}` in metadata
  if (!value?.renewalId) {
    return null;
  }

  const { renewalId, renewalStatus, pendingProposal } = value;

  return (
    <RenewalsSetProposalTermsModalButton
      renewalId={renewalId}
      refreshEntities={refreshEntities}
      showOnlySaveProposalButton={renewalStatus === RenewalStatus.APPROVED && pendingProposal}
    />
  );
};

export default {
  Component,
  getValueForExport: () => '',
};
