import ExternalLink from '@/components/ExternalLink';
import EditablePage from '@/components/Formik/EditablePage';
import type { Application, ApplicationSearchResult } from '@/domain/applications';
import { ApplicationsService } from '@/services/applications';
import { handleError } from '@/utils/utils';
import { Col, message, Row } from 'antd';
import type { FC } from 'react';
import { useState } from 'react';
import { getBooleanStatus, getCreditRecommendationStatus } from '../ApprovalIcon';
import ScreeningResultCard from './ScreeningResultCard';
import './styles.less';

interface Props {
  application: Application;
  onUpdateApplication: (application: ApplicationSearchResult) => void;
  applicationCancelled: boolean;
}

const ApplicationScreeningResults: FC<Props> = ({
  application,
  onUpdateApplication,
  applicationCancelled,
}) => {
  const [updating, setUpdating] = useState(false);

  const updateApplication = async (updatedApplication: Application) => {
    try {
      setUpdating(true);
      await ApplicationsService.update(updatedApplication.id, updatedApplication);
      const searchResponse = await ApplicationsService.search({ id: updatedApplication.id });
      message.success('Application updated successfully');
      onUpdateApplication(searchResponse[0]);
    } catch (error) {
      handleError(error, { toastFallbackMessage: 'There was an error updating the application' });
    } finally {
      setUpdating(false);
    }
  };

  return (
    <div className="screening-results">
      <EditablePage
        singleColumn
        sections={[
          {
            title: 'Recommendations',
            type: 'WRAPPER',
            children: (
              <>
                {application.screeningResultLink && (
                  <>
                    <div className="title">Trans-Union Results:</div>
                    <div>
                      <ExternalLink href={application.screeningResultLink}>View</ExternalLink>
                    </div>
                  </>
                )}
                <Row gutter={24}>
                  <Col className="gutter-row" span={6}>
                    <ScreeningResultCard
                      status={getCreditRecommendationStatus(application.creditRecommendations)}
                      applicantRemoved={applicationCancelled}
                      icon="credit"
                      label="Credit"
                      size={94}
                    />
                  </Col>
                  <Col className="gutter-row" span={6}>
                    <ScreeningResultCard
                      status={getBooleanStatus(application.criminalRecommendation)}
                      applicantRemoved={applicationCancelled}
                      icon="criminal"
                      label="Criminal"
                      size={94}
                    />
                  </Col>
                  <Col className="gutter-row" span={6}>
                    <ScreeningResultCard
                      status={getBooleanStatus(application.backgroundRecommendation)}
                      applicantRemoved={applicationCancelled}
                      icon="background"
                      label="Background"
                      size={94}
                    />
                  </Col>
                </Row>
              </>
            ),
          },
          {
            title: 'Approvals',
            type: 'WRAPPER',
            children: (
              <Row gutter={24}>
                <Col span={6}>
                  <ScreeningResultCard
                    status={getBooleanStatus(application.creditApproval)}
                    applicantRemoved={applicationCancelled}
                    icon="credit"
                    label="Credit"
                    size={94}
                    onChange={(creditApproval: boolean | null) =>
                      updateApplication({ ...application, creditApproval })
                    }
                    disableSelect={updating || applicationCancelled}
                  />
                </Col>
                <Col span={6}>
                  <ScreeningResultCard
                    status={getBooleanStatus(application.criminalApproval)}
                    applicantRemoved={applicationCancelled}
                    icon="criminal"
                    label="Criminal"
                    size={94}
                    disableSelect={updating || applicationCancelled}
                    onChange={(criminalApproval: boolean | null) =>
                      updateApplication({ ...application, criminalApproval })
                    }
                  />
                </Col>
                <Col span={6}>
                  <ScreeningResultCard
                    status={getBooleanStatus(application.backgroundApproval)}
                    applicantRemoved={applicationCancelled}
                    icon="background"
                    label="Background"
                    size={94}
                    disableSelect={updating || applicationCancelled}
                    onChange={(backgroundApproval: boolean | null) =>
                      updateApplication({ ...application, backgroundApproval })
                    }
                  />
                </Col>
                <Col span={6}>
                  <ScreeningResultCard
                    status={getBooleanStatus(application.idApproval)}
                    applicantRemoved={applicationCancelled}
                    icon="id"
                    label="Id"
                    size={94}
                    disableSelect={updating || applicationCancelled}
                    onChange={(idApproval: boolean | null) =>
                      updateApplication({ ...application, idApproval })
                    }
                  />
                </Col>
              </Row>
            ),
          },
        ]}
      />
    </div>
  );
};

export default ApplicationScreeningResults;
