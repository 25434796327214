import type { PropifyEntity } from './base';
import type { ResidentSearchResult } from './resident';

export enum CollectionStatus {
  OUTSTANDING_BALANCE = 'OUTSTANDING_BALANCE',
  PENDING_EVICTION = 'PENDING_EVICTION',
  EVICTION = 'EVICTION',
  COLLECTIONS_AGENCY = 'COLLECTIONS_AGENCY',
  WRITE_OFF = 'WRITE_OFF',
  PAID = 'PAID',
}

export interface Collection extends PropifyEntity {
  residentId: number;
  status?: CollectionStatus;
  risk: CollectionRisk;
  resolveTime?: string;
  assignedLateFeeDate?: string;
}

export type CollectionRisk = 'HIGH' | 'MEDIUM' | 'LOW';

interface CollectionNextPayment {
  date: string;
  amount: number;
  id: number;
}

export type CollectionSearchParams = {
  status?: string | string[];
  risk?: CollectionRisk | CollectionRisk[];
  residentId?: number | number[];
};

export interface CollectionSearchResult extends Collection {
  resident: ResidentSearchResult;
  nextPayment?: CollectionNextPayment;
}

export interface CollectionPayment extends PropifyEntity {
  collectionId: number;
  date: string;
  amount: number;
}
