import { useEntityTableContext } from '@/notmagic/Context';
import { CloseCircleFilled } from '@ant-design/icons';
import { Button, Popover } from 'antd';
import type { FC } from 'react';
import { useEffect, useState } from 'react';
import type { TableFilter } from '../../types';
import styles from './styles.module.less';

interface Props {
  filter: TableFilter;
  onFilterChange: (filter: any) => void;
  onApplyFilters: (filter: any) => void;
  onClear: (key: string) => void;
  value?: any;
}

const ActiveFilterField: FC<Props> = ({ filter, onApplyFilters, onClear, value }) => {
  const [open, setOpen] = useState(false);
  const [newValue, setNewValue] = useState();

  useEffect(() => setNewValue(value), [filter, value]);

  const { filterTypes } = useEntityTableContext();
  const Component = filterTypes[filter.type].Component;

  return (
    <Popover
      content={
        <div>
          <div>
            <Component
              {...filter.configuration}
              editable
              value={newValue}
              onChangeValue={setNewValue}
            />
          </div>
          <div className={styles.applyButtonContainer}>
            <Button
              type="primary"
              onClick={() => {
                setOpen(false);
                onApplyFilters({ [filter.key]: newValue });
              }}
            >
              Apply
            </Button>
          </div>
        </div>
      }
      trigger="click"
      visible={open}
      placement="bottom"
      onVisibleChange={(visible) => setOpen(visible)}
    >
      <Button className={styles.filterButton} data-testid="filterButton">
        <span>{filter.label || filter.key}: </span>
        <span
          className={styles.filterButtonValue}
          style={{ marginLeft: 3 }}
          data-testid="filterButtonValue"
        >
          <Component {...filter.configuration} value={value} />
        </span>
        <CloseCircleFilled
          onClick={() => onClear(filter.key)}
          className={styles.removeFilterIcon}
          data-testid="removeFilterIcon"
        />
      </Button>
    </Popover>
  );
};

export default ActiveFilterField;
