import type { ParsedQuery } from 'query-string';
import { parse, stringify } from 'query-string';
import { useCallback, useMemo } from 'react';
import { useLocation, useNavigate } from 'react-router';

export type UpdateParametersFn = (newParams: ParsedQuery<string>, hash?: string) => void;

export const useParameters = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const parameters = useMemo(() => parse(location.search), [location.search]);

  const updateParameters = useCallback(
    (newParams: Record<string, any>, hash: string = location.hash) => {
      navigate({
        hash,
        search: stringify(newParams, { arrayFormat: 'none', skipNull: true }),
      });
    },
    [navigate, location.hash],
  );

  return {
    parameters,
    updateParameters,
    search: location.search,
    hash: location.hash,
  };
};
