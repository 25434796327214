import { ModalFormButton } from '@/components/Button/formButton';
import type { ColumnProps } from '@/magic/components/columns/types';
import RenewalsModal from '@/pages/prospects/renewals/components/RenewalsModal';
import type { FC } from 'react';

interface Props {
  renewalId: number;
  refreshEntities: ColumnProps['refreshEntities'];
  showOnlySaveProposalButton?: boolean;
}

const RenewalsSetProposalTermsModalButton: FC<Props> = ({
  renewalId,
  refreshEntities,
  showOnlySaveProposalButton,
}) => {
  return (
    <ModalFormButton buttonText="Set Proposal Terms" buttonType="primary">
      {(props) => (
        <RenewalsModal
          renewalId={renewalId}
          {...props}
          onRefresh={refreshEntities}
          showDeclineRenewal={!showOnlySaveProposalButton}
          showSaveAndSendOffer={!showOnlySaveProposalButton}
        />
      )}
    </ModalFormButton>
  );
};

export default RenewalsSetProposalTermsModalButton;
