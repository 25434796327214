import type { Column } from '@/@propify-components';
import { dateColumn, Table } from '@/@propify-components';
import ExternalLink from '@/components/ExternalLink';
import type { ConfirmFnType } from '@/components/FloatingInlineEdit';
import FloatingInlineDropdownEdit from '@/components/FloatingInlineEdit/FloatingInlineDropdownEdit';
import FloatingInlineNumberEdit from '@/components/FloatingInlineEdit/FloatingInlineNumberEdit';
import { enumColumn } from '@/components/TableUtils';
import type { Application, ApplicationSearchResult } from '@/domain/applications';
import { ApplicationStatus, ApplicationType } from '@/domain/applications';
import type { RenewalDTO } from '@/domain/renewal';
import { RenewalStatus } from '@/domain/renewal';
import type { TicketPriority } from '@/domain/ticket';
import { TicketStatus, TicketType } from '@/domain/ticket';
import type { UnitApplication } from '@/domain/unit-applications';
import { UnitApplicationStatus } from '@/domain/unit-applications';
import ApplicantName from '@/pages/prospects/components/ApplicantName';
import type { CollectionTicketFormValues } from '@/pages/resident/CollectionsBulkCreateTickets';
import CollectionsBulkCreateTickets from '@/pages/resident/CollectionsBulkCreateTickets';
import { ApplicationsService } from '@/services/applications';
import { RenewalService } from '@/services/renewal';
import { settings } from '@/services/settings';
import { TicketService } from '@/services/tickets';
import { UnitApplicationsService } from '@/services/unit-applications';
import { SHORT_DATE_FORMAT } from '@/utils/time';
import { getBooleanOptions, handleError } from '@/utils/utils';
import DeleteOutlined from '@ant-design/icons/DeleteOutlined';
import { formatCurrency } from '@propify/components';
import { Button, message, Popconfirm } from 'antd';
import moment from 'moment';
import type { FC } from 'react';
import { useEffect, useMemo, useState } from 'react';
import type { ApplicationToRemove } from '../../renewals/components/RenewalsDetail';
import ApplicationApprovals from '../ApplicationApprovals';
import ApplicationRecommendations from '../ApplicationRecommendations';
import EditableApplicationType from '../components/EditableApplicationType';
import ApplicationDetails from './ApplicationDetails';

interface Props {
  applications: ApplicationSearchResult[];
  onApplicationUpdate: (application?: Application) => void;
  removeApplication?: (row: ApplicationSearchResult) => void;
  onApplicationRemoved?: (application: Application) => void;
  unitApplication?: UnitApplication;
  onLoading?: (loading: boolean) => void;
  renewal?: RenewalDTO;
  onScreeningResultUpdate: (application: ApplicationSearchResult) => void;
  isRemoveApplicationDisabled?: (application: ApplicationSearchResult) => boolean;
  onRemoveApplication?: (application: ApplicationToRemove) => void;
  isApplicationCancelled: (application: ApplicationSearchResult) => boolean;
  onRemovePrimaryApplication?: () => void;
}

const tableSettings = settings('unit-application-applicants');

const Applicants: FC<Props> = ({
  applications,
  onApplicationUpdate,
  onApplicationRemoved,
  removeApplication,
  unitApplication,
  onLoading,
  renewal,
  onScreeningResultUpdate,
  isRemoveApplicationDisabled,
  onRemoveApplication,
  isApplicationCancelled,
  onRemovePrimaryApplication,
}) => {
  const [selectedApplicantIds, setSelectedApplicantIds] = useState<number[]>([]);
  const [createTicketForApplication, setCreateTicketForApplication] =
    useState<ApplicationSearchResult>();
  const [createTicketSaving, setCreateTicketSaving] = useState(false);

  useEffect(() => {
    if (selectedApplicantIds.length > 0) {
      return;
    }

    const selectedByDefault =
      applications.find((a) => a.type === ApplicationType.PRIMARY) || applications[0];

    if (selectedByDefault) {
      setSelectedApplicantIds([selectedByDefault.id]);
    }
  }, [applications, selectedApplicantIds]);

  const selectedApplication = useMemo(
    () => applications.find((a) => a.id === selectedApplicantIds[0]),
    [selectedApplicantIds, applications],
  );

  const isPrimaryApplicant = selectedApplication?.type === ApplicationType.PRIMARY;

  const booleanOptions = useMemo(
    () =>
      getBooleanOptions().map((item) => ({
        ...item,
        value: String(item.value),
      })),
    [],
  );

  const isProcessApproved =
    unitApplication?.status === UnitApplicationStatus.APPROVED ||
    renewal?.status === RenewalStatus.APPROVED;

  const handleValidatedIncomeUpdate =
    (application: ApplicationSearchResult): ConfirmFnType<number> =>
    (value: number, done, fail) => {
      onLoading?.(true);
      ApplicationsService.update(application.id, { ...application, validatedIncome: value })
        .then((app) => {
          message.success('Validated Income was successfully updated');
          onApplicationUpdate(app);
          done();
        })
        .catch((error) => {
          handleError(error, {
            toastFallbackMessage: 'There was an error updating the Validated Income',
          });
          fail(error);
        })
        .finally(() => {
          onLoading?.(false);
        });
    };

  const getStatusClassName = (row: ApplicationSearchResult) => {
    switch (row.status) {
      case ApplicationStatus.APPROVED:
        return 'cellGreenText';
      case ApplicationStatus.DENIED:
        return 'cellRedText';
      default:
        return '';
    }
  };

  const onCreateTicketsConfirm = (values: CollectionTicketFormValues) => {
    if (!createTicketForApplication || (!createTicketForApplication.unitApplication && !renewal)) {
      return;
    }

    setCreateTicketSaving(true);

    TicketService.create({
      ...values,
      partyId: createTicketForApplication.person.id,
      priority: values.priority as TicketPriority,
      status: TicketStatus.OPEN,
      entities: {
        ...(createTicketForApplication.unitApplication
          ? { UNIT_APPLICATION: [createTicketForApplication.unitApplication.id] }
          : {}),
        ...(renewal ? { RENEWAL: [renewal.id] } : {}),
        APPLICATION: [createTicketForApplication.id],
      },
    })
      .then(() => {
        message.success('Ticket successfully created');
        setCreateTicketForApplication(undefined);
        onApplicationUpdate();
      })
      .catch((error) => {
        handleError(error, { toastFallbackMessage: 'There was an error creating the ticket' });
      })
      .finally(() => {
        setCreateTicketSaving(false);
      });
  };

  const strikeThroughIfCancelled = (application: ApplicationSearchResult) =>
    isApplicationCancelled(application) ? 'strikeThrough' : '';

  const canUpdateApplicantType = useMemo((): boolean => {
    if (unitApplication) {
      return ![
        UnitApplicationStatus.DENIED,
        UnitApplicationStatus.CANCELLED,
        UnitApplicationStatus.LEASED,
        UnitApplicationStatus.APPROVED,
      ].includes(unitApplication?.status as UnitApplicationStatus);
    }

    if (renewal) {
      return ![
        RenewalStatus.DECLINED,
        RenewalStatus.CANCELLED,
        RenewalStatus.REFUSED,
        RenewalStatus.ACCEPTED,
        RenewalStatus.RENEWED,
      ].includes(renewal?.status as RenewalStatus);
    }

    return false;
  }, [unitApplication, renewal]);

  const handleUpdateApplicantType =
    (applicationToUpdate: ApplicationSearchResult): ConfirmFnType =>
    (value, done, fail) => {
      let savePromise: Promise<unknown> | null = null;

      if (unitApplication) {
        const unitApplicationApplication = unitApplication.applications.find(
          (a) => a.applicationId === applicationToUpdate.id,
        );

        if (!unitApplicationApplication) {
          return;
        }

        savePromise = UnitApplicationsService.updateApplication(unitApplication.id, {
          ...unitApplicationApplication,
          type: value as ApplicationType,
        });
      }

      if (renewal) {
        const renewalApplication = renewal.applications.find(
          (a) => a.applicationId === applicationToUpdate.id,
        );

        if (!renewalApplication) {
          return;
        }

        savePromise = RenewalService.updateApplication(renewal.id, {
          ...renewalApplication,
          type: value as ApplicationType,
        });
      }

      if (!savePromise) {
        return;
      }

      onLoading?.(true);
      savePromise
        .then(() => {
          message.success('Application type was successfully updated');
          onApplicationUpdate();
          done();
        })
        .catch((error) => {
          handleError(error, {
            toastFallbackMessage: 'There was an error updating the application type',
          });
          fail(error);
        })
        .finally(() => {
          onLoading?.(false);
        });
    };

  const columns: Column<ApplicationSearchResult>[] = [
    {
      key: 'id',
      title: 'ID',
      width: 80,
      className: strikeThroughIfCancelled,
    },
    dateColumn({
      title: 'Next Contact',
      key: 'nextContactDate',
      render: (row) =>
        row.nextContactDueTime ? (
          moment(row.nextContactDueTime).format(SHORT_DATE_FORMAT)
        ) : (
          <Button type="primary" onClick={() => setCreateTicketForApplication(row)}>
            Create Ticket
          </Button>
        ),
      width: 130,
    }),
    dateColumn({ title: 'Review Due', key: 'reviewDueDate' }),
    {
      key: 'applicant',
      title: 'Applicant',
      render: (row) => (
        <ApplicantName person={row.person} primary={row.type === ApplicationType.PRIMARY} />
      ),
      className: strikeThroughIfCancelled,
    },
    ...(renewal
      ? [
          {
            key: 'requiresPayment',
            title: 'Requires Payment?',
            width: 170,
            className: strikeThroughIfCancelled,
            render: (row: ApplicationSearchResult) => {
              const renewalApplication = renewal?.applications.find(
                (a) => a.applicationId === row.id,
              );
              if (!renewalApplication) {
                return null;
              }

              return (
                <FloatingInlineDropdownEdit
                  options={booleanOptions}
                  onConfirm={(requiresPayment, done, fail) => {
                    RenewalService.updateApplication(renewal!.id, {
                      ...renewalApplication,
                      requiresPayment: requiresPayment === 'true',
                    })
                      .then(() => {
                        message.success('Renewal updated');
                        onApplicationUpdate();
                        done();
                      })
                      .catch((error) => {
                        handleError(error, {
                          toastFallbackMessage: 'There was an error updating the renewal',
                        });
                        fail();
                      });
                  }}
                  initialValue={`${renewalApplication.requiresPayment}`}
                  displayValue={renewalApplication.requiresPayment ? 'Yes' : 'No'}
                />
              );
            },
          },
        ]
      : []),
    {
      key: 'recommendation',
      title: 'Recommendation',
      width: 170,
      render: (row) => (
        <ApplicationRecommendations
          credit={row.creditRecommendations}
          criminal={row.criminalRecommendation}
          background={row.backgroundRecommendation}
          singleApplication
          applicantRemoved={isApplicationCancelled(row)}
        />
      ),
    },
    {
      key: 'approvals',
      title: 'Approvals',
      width: 200,
      render: (row) => (
        <ApplicationApprovals
          id={row.idApproval}
          credit={row.creditApproval}
          criminal={row.criminalApproval}
          background={row.backgroundApproval}
          singleApplication
          applicantRemoved={isApplicationCancelled(row)}
        />
      ),
    },
    enumColumn(
      { title: 'Status', key: 'status', width: 100, className: (row) => getStatusClassName(row) },
      {
        enumName: 'application-status',
        getValue: (row) => (isApplicationCancelled(row) ? 'Removed' : row.status),
      },
    ),
    {
      key: 'screeningResults',
      title: 'Screening Results',
      width: 140,
      render: (row) =>
        row.screeningResultLink ? (
          <ExternalLink href={row.screeningResultLink}>View</ExternalLink>
        ) : null,
      hideSort: true,
    },
    {
      title: 'Applicant Type',
      key: 'type',
      render: (item) => {
        const isItemPrimaryApplicant = item.type === ApplicationType.PRIMARY;

        const editDisabled = isItemPrimaryApplicant || !canUpdateApplicantType;

        return (
          <EditableApplicationType
            onConfirm={handleUpdateApplicantType(item)}
            applicationType={item.type}
            hideEdit={!canUpdateApplicantType}
            editDisabled={editDisabled}
          />
        );
      },
    },
    {
      key: 'validatedIncome',
      title: 'Validated Monthly Income',
      className: (row) => `${strikeThroughIfCancelled(row)} highlightBlue`,
      render: (row) =>
        !isProcessApproved ? (
          <FloatingInlineNumberEdit
            onConfirm={handleValidatedIncomeUpdate(row)}
            initialValue={row.validatedIncome || 0}
            displayValue={formatCurrency(row.validatedIncome)}
            min={0}
          />
        ) : (
          formatCurrency(row.validatedIncome)
        ),
      width: 200,
    },
    dateColumn({ title: 'Created Date', key: 'createTime', width: 130 }),
    ...(removeApplication || onRemoveApplication
      ? ([
          {
            key: 'actions',
            title: '',
            width: 65,
            render: (row) => {
              const disabled = isRemoveApplicationDisabled
                ? isRemoveApplicationDisabled(row) || isApplicationCancelled(row)
                : isApplicationCancelled(row);

              if (removeApplication) {
                return (
                  <Popconfirm
                    title="Are you sure you wish to remove this individual from this application?"
                    onConfirm={() => {
                      removeApplication(row);
                    }}
                    okText="Yes"
                    cancelText="No"
                    disabled={disabled}
                  >
                    <Button
                      data-testid="applications-list-modal-remove-button"
                      icon={
                        <DeleteOutlined
                          disabled={disabled}
                          style={{ color: disabled ? 'gray' : undefined }}
                        />
                      }
                      type="link"
                      danger
                      disabled={disabled}
                    />
                  </Popconfirm>
                );
              }

              if (onRemoveApplication) {
                return (
                  <Button
                    data-testid="applications-list-modal-remove-button"
                    icon={
                      <DeleteOutlined
                        disabled={disabled}
                        style={{ color: disabled ? 'gray' : undefined }}
                      />
                    }
                    type="link"
                    danger
                    onClick={() => {
                      if (row.type === ApplicationType.PRIMARY && onRemovePrimaryApplication) {
                        onRemovePrimaryApplication();
                        return;
                      }

                      const renewalApplication = renewal?.applications.find(
                        (ra) => ra.applicationId === row.id,
                      );

                      if (!renewalApplication) {
                        return;
                      }

                      onRemoveApplication?.({
                        personId: row.person.id,
                        personName: `${row.person.firstName} ${row.person.lastName}`,
                        renewalApplicationId: renewalApplication.id,
                      });
                    }}
                    disabled={disabled}
                  />
                );
              }

              return null; // This should not happen
            },
          },
        ] as Column<ApplicationSearchResult>[])
      : []),
  ];

  return (
    <>
      <Table
        data-testid="applicants-table"
        data={applications}
        columns={columns}
        rowKeyExtractor={(row) => row.id}
        settings={tableSettings}
        selection={selectedApplicantIds}
        onSelectionChange={setSelectedApplicantIds}
        singleSelection
      />

      {selectedApplication && (
        <div style={{ marginTop: 20 }}>
          <ApplicationDetails
            application={selectedApplication}
            applicationCancelled={isApplicationCancelled(selectedApplication)}
            onUpdateApplication={onApplicationUpdate}
            onDeleteApplication={() => onApplicationRemoved?.(selectedApplication)}
            isPrimaryApplicant={isPrimaryApplicant}
            unitApplication={unitApplication}
            renewal={renewal}
            onScreeningResultUpdate={onScreeningResultUpdate}
          />
        </div>
      )}

      {createTicketForApplication && (
        <CollectionsBulkCreateTickets
          type={TicketType.AGENT_CREATED}
          subSkill="APPLICATION_PROCESSING"
          saving={createTicketSaving}
          onCancel={() => setCreateTicketForApplication(undefined)}
          onConfirm={onCreateTicketsConfirm}
        />
      )}
    </>
  );
};

export default Applicants;
