import { ModalFormButton } from '@/components/Button/formButton';
import CreateNoteForm from '@/components/Notes/create';
import ViewAllNotesModal from '@/components/Notes/ViewAllNotesModal';
import type { EntityType } from '@/domain/entity-type';
import type { Note } from '@/domain/note';
import { NoteType } from '@/domain/note';
import '@/pages/prospects/unit-applications/details/styles.less';
import { PlusOutlined } from '@ant-design/icons';
import noop from 'lodash/noop';
import type { FC } from 'react';
import { useEffect, useState } from 'react';

type Props = {
  entityId: number;
  entityType: EntityType;
  refetchNotes: () => void;
  onNoteClick: boolean;
  selectedNote?: Note;
};

const NotesHeader: FC<Props> = ({
  entityId,
  entityType,
  refetchNotes,
  selectedNote,
  onNoteClick,
}) => {
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    if (selectedNote) {
      setVisible(true);
    }
  }, [onNoteClick, selectedNote]);

  return (
    <div className="notes-header">
      <div className="title">Notes</div>
      <div className="actions-header">
        <ModalFormButton buttonType="link" buttonText="View all notes">
          {(props) => (
            <ViewAllNotesModal
              {...props}
              refetch={() => refetchNotes()}
              entityType={entityType}
              entityId={entityId}
            />
          )}
        </ModalFormButton>
        <ModalFormButton
          icon={<PlusOutlined />}
          buttonType="link"
          buttonClassName="add-note-button"
          onSuccess={() => refetchNotes()}
        >
          {(props) => (
            <CreateNoteForm
              {...props}
              defaultType={NoteType.GENERAL}
              entityType={entityType}
              entityId={entityId}
            />
          )}
        </ModalFormButton>
      </div>
      <ViewAllNotesModal
        entityType={entityType}
        entityId={entityId}
        visible={visible}
        onCancel={() => {
          setVisible(false);
        }}
        onSuccess={noop}
        refetch={() => refetchNotes()}
        selectedNoteFromParent={selectedNote}
      />
    </div>
  );
};

export default NotesHeader;
