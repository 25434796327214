import type { PropifyEntity } from '@/domain/base';

export enum DocumentTemplateType {
  LEASE = 'LEASE',
}

export enum DocumentTemplateStatus {
  ACTIVE = 'ACTIVE',
  ARCHIVED = 'ARCHIVED',
}

export interface DocumentTemplate extends PropifyEntity {
  name: string;
  description: string;
  status: DocumentTemplateStatus;
  signers: number;
  externalId: string;
}

export interface CreateDocumentTemplateResponse extends DocumentTemplate {
  url: string;
}

export interface DocumentTemplateSearchResult extends DocumentTemplate {
  type: DocumentTemplateType;
  archivedDate?: string;
  archivedBy?: number;
  lastEditedDate?: string;
  lastEditedBy?: number;
  assignedHomes: number;
  ids?: number | number[];
}

export interface DocumentTemplateSearchParams {
  status?: DocumentTemplateStatus;
}

export interface DropboxSignConfiguration {
  testMode: boolean;
  skipDomainVerification: boolean;
  locale: string;
  clientId: string;
}

export interface DocumentTemplatePreviewRequest {
  templateIds: number[];
}
