import type { New } from '@/domain/base';
import type { CustomField } from '@/domain/custom-fields';
import { api } from '@/utils/configuration';
import { crudService, getAxiosFetcher } from '@/utils/request';

export const CustomFieldsService = {
  search: getAxiosFetcher<CustomField[], []>(() => [api.CUSTOM_FIELDS]),

  create: (customField: New<CustomField>) =>
    crudService.post<CustomField>(customField, api.CUSTOM_FIELDS),

  findById: getAxiosFetcher<CustomField, [number]>((id) => [`${api.CUSTOM_FIELDS}/${id}`]),

  delete: (id: number) => crudService.delete<void>(`${api.CUSTOM_FIELDS}/${id}`),
};
