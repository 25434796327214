import uniq from 'lodash/uniq';
import { useEffect, useState } from 'react';
import type { HighlightRule, Table } from '../types';
import { useJsonata } from './useJsonata';

export const transformHighlight = (highlightRules: HighlightRule[] | undefined) => {
  if (!highlightRules?.length) {
    return [];
  }

  const parseHighlightRule = (rules: HighlightRule[]) =>
    rules
      .map(
        ({ filter, className, tooltip }) =>
          `${filter} ? {'className': '${className}', 'tooltipText': '${tooltip}' } : `,
      )
      .join('')
      .concat('undefined');

  const result = [
    `'_highlight': ${parseHighlightRule(highlightRules.filter((rule) => !rule.tabs?.length))}`,
  ];

  const tabKeys = highlightRules
    ? uniq(highlightRules.map((rule) => rule.tabs || []).flat()).sort()
    : [];

  tabKeys.forEach((tabKey) => {
    const rules = highlightRules?.filter((rule) => rule.tabs?.includes(tabKey)) || [];

    if (rules.length) {
      result.push(`'_highlight_${tabKey}': ${parseHighlightRule(rules)}`);
    }
  });

  return result;
};

export const useTransformedData = <T = any, D = any>(
  table: Table,
  rawData: D[],
  enumerations?: Record<string, Record<string, string>>,
  entityLookups?: Record<string, any>,
) => {
  const [transformedData, setTransformedData] = useState<T[]>([]);
  const [transforming, setTransforming] = useState(false);

  const [runJsonata] = useJsonata<D[], T[]>((result) => {
    setTransforming(false);
    setTransformedData(result);
  });

  useEffect(() => {
    if (!(rawData && enumerations && entityLookups)) {
      return;
    }

    setTransforming(true);
    const values = table.columns
      .filter((column) => column.value || column.type === 'ENUM' || column.type === 'ENTITY')
      .map((column) => {
        const valueStr = column.value ? [`'${column.key}': ${column.value}`] : [];
        const getValueStr = column.value || `$v.${column.key}`;

        if (column.type === 'ENUM') {
          // TODO: In the case `value` is an array (src/notmagic/metadata/tables/user-groups.json:26) it should look up
          //  the display name for each value in the array and return a comma separated string
          valueStr.push(`'${column.key}$displayName': (${getValueStr})
            ? $lookup($enumerations.'${column.configuration.enumeration}', ${getValueStr})
            : undefined
          `);
        } else if (column.type === 'ENTITY') {
          valueStr.push(`
            '${column.key}$displayName': (${getValueStr})
              ? (
                  $findInLookup := function($value) {
                    (
                      $x := $lookup(
                        $entityLookups.'${column.configuration.entityType}',
                        $string($value)
                      );
                      $x ? $x : ''
                    )
                  };

                  $valueIsArray := $contains($trim($string(${getValueStr})), /^\\[.*\\]$/);

                  $valueIsArray
                    ? (
                      $join(
                        $filter(
                          $map(
                            $eval(${getValueStr}),
                            function($id) { $findInLookup($id) }
                          ),
                          function($z) { $z }
                        ),
                        ', '
                      )
                    )
                    : $findInLookup(${getValueStr})
                )
              : undefined
          `);
        }

        return valueStr.join(',\n');
      });

    const extraValues = Object.keys(table.mapAttributes || {}).map((key) => {
      return `'${key}': ${table.mapAttributes![key]}`;
    });

    const editable = table.columns
      .filter((column) => !!column.configuration?.editable)
      .map((column) => {
        return `'${column.key}$editable': ${column.configuration?.editable}`;
      });

    const links = table.columns
      .filter((column) => !!column.link)
      .map((column) => {
        return `'${column.key}$link': ${column.link}`;
      });

    const colors = table.columns
      .filter((column) => !!column.textColor)
      .map((column) => {
        return `'${column.key}$color': ${column.textColor}`;
      });

    const highlight = transformHighlight(table.highlight);

    const expression = `
      [$map(
        $,
        function($v, $i, $a) {
          $merge([
            $v,
            $v.{
              ${
                [...values, ...extraValues, ...links, ...editable, ...highlight, ...colors].join(
                  ',\n',
                ) || ''
              }
            }
          ])
        }
      )]`;

    runJsonata({
      action: 'transform',
      data: rawData,
      expression,
      bindings: { enumerations, entityLookups },
    });
  }, [rawData, table, enumerations, entityLookups, runJsonata]);

  return {
    transformedData,
    transforming,
  };
};
