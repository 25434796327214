import type { RenewalOption } from '@/domain/renewal';
import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { Popconfirm } from 'antd';
import type { FC } from 'react';
import { useState } from 'react';
import type { LeaseTermFormValues } from './AddLeaseTerm';
import AddLeaseTerm from './AddLeaseTerm';

interface Props {
  optionToEditIndex: number;
  renewalOptions: RenewalOption[];
  showDelete?: boolean;
  onDelete?: () => void;
  onEdit?: (newValues: LeaseTermFormValues, renewalOption: RenewalOption) => void;
  renewalOptionToEdit: RenewalOption;
}

const EditTermWrapper: FC<Props> = ({
  optionToEditIndex,
  renewalOptions,
  showDelete = false,
  onDelete,
  renewalOptionToEdit,
  onEdit,
}) => {
  const [editing, setEditing] = useState(false);

  const handleEdit = (newValues: LeaseTermFormValues, renewalOption: RenewalOption) => {
    onEdit?.(newValues, renewalOption);
    setEditing(false);
  };

  return (
    <>
      <AddLeaseTerm
        visible={editing}
        renewalOptions={renewalOptions}
        renewalOptionToEdit={renewalOptionToEdit}
        onCancel={() => setEditing(false)}
        onEdit={handleEdit}
        optionToEditIndex={optionToEditIndex}
      />
      <div className="__actions">
        <div onClick={() => setEditing(true)}>
          <EditOutlined className="edit" />
        </div>
        {showDelete && (
          <Popconfirm title="Are you sure?" onConfirm={onDelete} okText="Yes" cancelText="No">
            <div>
              <DeleteOutlined className="delete" />
            </div>
          </Popconfirm>
        )}
      </div>
    </>
  );
};

export default EditTermWrapper;
