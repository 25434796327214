import ClockIcon from '@/assets/icons/clock.svg?react';
import { useAppContext } from '@/contexts/AppContext';
import type { Entities, EntityType } from '@/domain/entity-type';
import type { Note, NoteUserMentioned } from '@/domain/note';
import { NoteType } from '@/domain/note';
import {
  replaceMentionedUserIdsByNames,
  replaceMentionedUserNamesByIds,
} from '@/pages/notes/utils';
import { NoteService } from '@/services/notes';
import { DATE_TIME_FORMAT, LOCAL_DATE_TIME_Z_FORMAT, SHORT_DATE_TIME_FORMAT } from '@/utils/time';
import { getOperations, handleError } from '@/utils/utils';
import { DeleteOutlined, EditOutlined, PlusOutlined } from '@ant-design/icons';
import { InfoCard } from '@propify/components';
import { Button, Col, DatePicker, Form, message, Row, Switch } from 'antd';
import { Formik } from 'formik';
import isEmpty from 'lodash/isEmpty';
import type { Moment } from 'moment';
import moment from 'moment';
import type { FC } from 'react';
import { useCallback, useEffect, useMemo, useState } from 'react';
import * as Yup from 'yup';
import { ModalFormButton } from '../Button/formButton';
import EmptyState from '../EmptyState/EmptyState';
import SectionSplashScreen from '../PageLoading/SplashSection';
import CreateNoteForm from './create';
import NoteAudit from './NoteAudit/NoteAudit';
import NoteEditor from './NoteEditor';

interface Props {
  entityType: EntityType;
  entityId: number;
  otherEntities?: Entities;
  notes: Note[];
  refetch: () => void;
  loading: boolean;
  selectedNoteFromParent?: Note;
  showHeader?: boolean;
  showSaveToProcessJob?: boolean;
  processJobEntities?: Entities;
}

export type FormValues = {
  note: string;
  alert: boolean;
  alertTime?: Moment;
};

const validationSchema = Yup.object({
  note: Yup.string().trim().required('Required'),
});

const ViewAllNotes: FC<Props> = ({
  entityType,
  entityId,
  otherEntities,
  notes,
  loading,
  refetch,
  selectedNoteFromParent,
  showHeader,
  showSaveToProcessJob,
  processJobEntities,
}) => {
  const [deleting, setDeleting] = useState(false);
  const [editing, setEditing] = useState(false);
  const [selectedNote, setSelectedNote] = useState<Note>();
  const [noteToRemove, setNoteToRemove] = useState<Note>();
  const [noteToEdit, setNoteToEdit] = useState<Note>();
  const [mentionedUsers, setMentionedUsers] = useState<NoteUserMentioned[]>([]);

  const { users, currentUser } = useAppContext();

  const usersForMentions = useMemo(
    () =>
      users.map((user) => ({
        id: user.id,
        value: user.displayName,
      })),
    [users],
  );

  const handleDeleteNote = useCallback(() => {
    if (!noteToRemove) {
      return;
    }

    setDeleting(true);
    NoteService.delete(noteToRemove.id)
      .then(() => {
        refetch();
        setNoteToRemove(undefined);
        message.success('Note deleted');
      })
      .catch((error) => {
        handleError(error, { toastFallbackMessage: 'Failed to delete Note' });
      })
      .finally(() => setDeleting(false));
  }, [noteToRemove]);

  const handleOnSelectUserMention = (item: NoteUserMentioned) => {
    setMentionedUsers([...mentionedUsers, item]);
  };

  useEffect(() => {
    if (selectedNoteFromParent) {
      setSelectedNote(selectedNoteFromParent);
      return;
    }
    setSelectedNote(notes[0]);
  }, [notes, selectedNoteFromParent]);

  const handleEdit = useCallback(
    (values: FormValues) => {
      if (!noteToEdit) {
        return;
      }

      const operations = getOperations({
        ...values,
        alertTime: values.alertTime ? values.alertTime.format(LOCAL_DATE_TIME_Z_FORMAT) : undefined,
        alertRole: values.alert ? 'USER' : '',
        note: replaceMentionedUserNamesByIds(values.note, mentionedUsers),
      });

      setEditing(true);
      NoteService.patch(noteToEdit.id, operations, noteToEdit.version)
        .then(() => {
          refetch();
          setNoteToEdit(undefined);
          setMentionedUsers([]);
          message.success('Note edited');
        })
        .catch((error) => {
          handleError(error, { toastFallbackMessage: 'There was an error editing the note' });
        })
        .finally(() => {
          setEditing(false);
        });
    },
    [noteToEdit, mentionedUsers],
  );

  const initialValues = useMemo(() => {
    return {
      note: noteToEdit?.note || '',
      alert: noteToEdit?.alert || false,
      alertTime: noteToEdit?.alertTime ? moment(noteToEdit.alertTime) : undefined,
    };
  }, [noteToEdit]);

  return loading ? (
    <Row justify="center" align="middle" style={{ height: '100px' }}>
      <SectionSplashScreen />
    </Row>
  ) : (
    <div className="view-notes-modal">
      {showHeader && (
        <div>
          <Row align="middle">
            <Col flex={1} className="title">
              Notes
            </Col>
            <Col style={{ marginRight: 10 }}>
              <ModalFormButton
                icon={<PlusOutlined />}
                buttonType="primary"
                buttonClassName="add-note-button"
                onSuccess={() => {
                  refetch();
                }}
                disabled={!!noteToEdit || !!noteToRemove}
              >
                {(props) => (
                  <CreateNoteForm
                    {...props}
                    entityType={entityType}
                    entityId={entityId}
                    otherEntities={otherEntities}
                    processJobEntities={processJobEntities}
                    defaultType={NoteType.GENERAL}
                    showSaveToProcessJob={showSaveToProcessJob}
                  />
                )}
              </ModalFormButton>
            </Col>
          </Row>
        </div>
      )}
      <Row className="notes-container">
        <Col className="notes-column">
          {notes.map((note) => (
            <InfoCard
              key={`note-preview-${note.id}`}
              onClick={() => {
                setSelectedNote(note);
                setNoteToRemove(undefined);
                setNoteToEdit(undefined);
              }}
              active={selectedNote?.id === note.id}
              className={`note-preview ${selectedNote?.id === note.id ? 'selected' : ''}`}
              activeBackgroundColor="#fffaea"
              activeBoderColor="#ff9d29"
            >
              {/* This extra div is necessary to make the note text not wider than the audit div */}
              <div className="note-text-wrapper">
                <div className="note-text">
                  {replaceMentionedUserIdsByNames(note.note || '', usersForMentions)}
                </div>
              </div>
              <NoteAudit note={note} />
            </InfoCard>
          ))}
        </Col>
        {selectedNote && !noteToEdit && (
          <Col className="note-details-container">
            <div className="note-details">
              <Row gutter={[16, 8]} wrap={false}>
                <Col flex={1}>
                  <NoteAudit note={selectedNote} />
                </Col>
                {selectedNote.createLoginId === currentUser?.login.id && (
                  <>
                    <Col className="note-action">
                      <DeleteOutlined
                        data-testid="note-remove-icon"
                        style={{ fontSize: '1.225rem', color: '#F11E1E' }}
                        onClick={() => setNoteToRemove(selectedNote)}
                      />
                    </Col>
                    <Col className="note-action">
                      <EditOutlined
                        style={{ fontSize: '1.225rem', color: '#1890FF' }}
                        onClick={() => setNoteToEdit(selectedNote)}
                      />
                    </Col>
                  </>
                )}
              </Row>
              {selectedNote.alertTime && (
                <Row align="middle" className="alert-time">
                  <Col className="alert-icon">
                    <ClockIcon />
                  </Col>

                  <Col>{moment.utc(selectedNote.alertTime).local().format(DATE_TIME_FORMAT)}</Col>
                </Row>
              )}
              <Row>
                <div className="note-selected-text">
                  {replaceMentionedUserIdsByNames(selectedNote.note || '', usersForMentions)}
                </div>
              </Row>
            </div>
            {noteToRemove && (
              <Row className={`delete-overlay ${noteToRemove ? 'show' : 'hide'}`}>
                <Row
                  gutter={[16, 16]}
                  className="delete-overlay-content"
                  justify="center"
                  align="middle"
                >
                  <Col>Are you sure you want to delete this note?</Col>
                  <Col>
                    <Row gutter={[16, 16]}>
                      <Col>
                        <Button ghost onClick={() => setNoteToRemove(undefined)} loading={deleting}>
                          No
                        </Button>
                      </Col>
                      <Col>
                        <Button type="primary" danger onClick={handleDeleteNote} loading={deleting}>
                          Yes
                        </Button>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Row>
            )}
          </Col>
        )}
        {noteToEdit && (
          <Col flex={1} className="notes-form-container">
            <Formik<FormValues>
              onSubmit={handleEdit}
              validationSchema={validationSchema}
              initialValues={initialValues}
            >
              {({ handleSubmit, values, setFieldValue, submitForm }) => (
                <Form
                  onSubmitCapture={handleSubmit}
                  layout="vertical"
                  className="notes-form"
                  initialValues={initialValues}
                >
                  <Form.Item name="note">
                    <NoteEditor
                      onUserSelected={handleOnSelectUserMention}
                      className="note-editor"
                      onChange={(note: string) => setFieldValue('note', note)}
                    />
                  </Form.Item>
                  <Row justify="space-between">
                    <Col>
                      <Form.Item label="Alert" name="alert" className="note-alert">
                        <Switch
                          checked={values.alert}
                          onChange={(checked: boolean) => setFieldValue('alert', checked)}
                        />
                      </Form.Item>
                    </Col>

                    <Col>
                      <Form.Item label="Alert Time" name="alertTime" className="note-alert-time">
                        <DatePicker
                          placeholder="Select date and time"
                          disabledDate={(date: Moment | undefined) =>
                            !date || date.isSameOrBefore(moment().subtract(10, 'minutes'))
                          }
                          showTime
                          format={SHORT_DATE_TIME_FORMAT}
                          onChange={(alertTime) => setFieldValue('alertTime', alertTime)}
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row className="notes-form-actions" gutter={[16, 0]}>
                    <Col flex={1}>
                      <Button
                        icon={<DeleteOutlined />}
                        danger
                        type="link"
                        className="delete-note-button"
                        onClick={() => {
                          setNoteToRemove(noteToEdit);
                          setNoteToEdit(undefined);
                        }}
                      >
                        Delete note
                      </Button>
                    </Col>
                    <Col>
                      <Button
                        loading={editing}
                        onClick={() => {
                          setNoteToEdit(undefined);
                        }}
                      >
                        Cancel
                      </Button>
                    </Col>
                    <Col>
                      <Button
                        type="primary"
                        loading={editing}
                        onClick={submitForm}
                        disabled={editing}
                      >
                        Save
                      </Button>
                    </Col>
                  </Row>
                </Form>
              )}
            </Formik>
          </Col>
        )}

        {isEmpty(notes) && <EmptyState description="There are no Notes yet." />}
      </Row>
    </div>
  );
};

export default ViewAllNotes;
