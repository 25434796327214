import { useAppContext } from '@/contexts/AppContext';
import { enumToOptions } from '@/utils/utils';
import { useMemo } from 'react';

const useStates = () => {
  const { states } = useAppContext();

  const stateNames = useMemo<Record<string, string>>(
    () => states.reduce((map, state) => ({ ...map, [state.value]: state.displayName }), {}),
    [states],
  );

  const stateOptions = useMemo(
    () => enumToOptions(states).sort((a, b) => a.label.localeCompare(b.label)),
    [states],
  );

  return { states, stateNames, stateOptions };
};

export default useStates;
