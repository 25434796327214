import { handleError } from '@/utils/utils';
import { Button, Tooltip } from 'antd';
import type { ButtonType } from 'antd/es/button';
import type { CSSProperties, MouseEvent, ReactNode } from 'react';
import { useState } from 'react';

export interface ModalFormProps<R extends any[] = []> {
  onSuccess: (...values: R) => void | Promise<void>;
  onCancel?: (e?: MouseEvent) => void;
  submitting?: boolean;
}

export interface Props<R extends any[] = []> {
  onSuccess?: (...values: R) => void | unknown | Promise<unknown>;
  buttonText?: string;
  buttonType?: ButtonType;
  link?: boolean;
  buttonClassName?: string;
  buttonGhost?: boolean;
  icon?: ReactNode;
  loading?: boolean;
  tooltipTitle?: string;
  onOpen?: () => void;
  onClose?: () => void;
  buttonStyle?: CSSProperties;
  disabled?: boolean;
  children: (props: ModalFormProps<R>) => ReactNode;
  danger?: boolean;
}

export const ModalFormButton = <R extends any[]>({
  onSuccess,
  buttonText,
  buttonType,
  link,
  buttonClassName,
  buttonGhost,
  icon,
  loading,
  tooltipTitle,
  onOpen,
  onClose,
  buttonStyle,
  disabled,
  children,
  danger,
}: Props<R>) => {
  const [visible, setVisible] = useState(false);
  const [submitting, setSubmitting] = useState(false);

  const openModal = () => {
    setVisible(true);
    onOpen?.();
  };

  const closeModal = () => {
    setVisible(false);
    onClose?.();
  };

  const onSuccessFn = async (...args: R) => {
    if (onSuccess) {
      setSubmitting(true);
      try {
        await onSuccess(...args);
      } catch (error) {
        handleError(error, { displayToast: false, rethrowError: true });
      } finally {
        setSubmitting(false);
      }
    }

    closeModal();
  };

  const buttonNode = link ? (
    <a style={buttonStyle} onClick={openModal} className={buttonClassName}>
      {buttonText}
    </a>
  ) : (
    <Button
      style={buttonStyle}
      type={buttonType || 'default'}
      icon={icon}
      onClick={openModal}
      disabled={disabled}
      loading={loading}
      ghost={buttonGhost}
      className={buttonClassName}
      danger={danger}
    >
      {buttonText}
    </Button>
  );

  return (
    <>
      {tooltipTitle ? (
        <Tooltip placement="top" title={tooltipTitle}>
          {buttonNode}
        </Tooltip>
      ) : (
        buttonNode
      )}
      {visible
        ? children({
            onCancel: closeModal,
            onSuccess: onSuccessFn,
            submitting,
          })
        : null}
    </>
  );
};
