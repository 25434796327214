import TabWithBadge from '@/pages/tickets/components/TabWithBadge';
import { Tabs } from 'antd';
import type { FC } from 'react';
import type { Tab } from '../types';
import styles from './styles.module.less';

type Props = {
  tabs: Tab[];
  currentTabKey: string;
  onChangeTab: (key: string) => void;
  tabBadgeCounters: Record<string, number>;
};

const EntityTableTabs: FC<Props> = ({ currentTabKey, tabs, tabBadgeCounters, onChangeTab }) => {
  return (
    <Tabs
      className={styles.tabContainer}
      style={{ height: 45 }}
      onTabClick={onChangeTab}
      activeKey={currentTabKey}
      animated={false}
    >
      {tabs?.map((tab, index) => (
        <Tabs.TabPane
          id="tabpane"
          key={tab.key || index}
          tab={
            <TabWithBadge
              showBadge={!tab.hideBadge}
              label={tab.title}
              count={tabBadgeCounters[tab.key]}
              tooltipText={tab.badgeTooltipText}
            />
          }
        />
      ))}
    </Tabs>
  );
};

export default EntityTableTabs;
