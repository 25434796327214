import type { RowKeyExtractorType } from '../Table.types';

export const isAllSelected = <T, K>(
  selection: K[],
  data: T[],
  rowKeyExtractor: RowKeyExtractorType<T, K>,
): boolean => {
  return (
    Array.isArray(selection) &&
    Array.isArray(data) &&
    selection.length !== 0 &&
    data.map(rowKeyExtractor).every((item) => selection.includes(item))
  );
};

export const isItemSelected = <K>(selection: K[], itemId: K): boolean =>
  Array.isArray(selection) && selection.some((selectionId) => selectionId === itemId);

export const toggleAllSelected = <T, K>(
  data: T[],
  rowKeyExtractor: RowKeyExtractorType<T, K>,
): K[] => data.map((item) => rowKeyExtractor(item));

export const isInRange = (from: number, to: number, value: number) => value >= from && value <= to;

export const getRange = (from: number, to: number) => {
  // As selection could be in two directions, we sort the elements
  const fromIndex = Math.min(from, to);
  const toIndex = Math.max(from, to);
  return { fromIndex, toIndex };
};

export const toggleItemSelected = <T, K>(
  data: T[],
  rowKeyExtractor: RowKeyExtractorType<T, K>,
  selection: K[],
  currentSelection: { itemIndex: number; shiftKeyPressed: boolean },
  previousSelection?: { itemIndex: number; selected: boolean },
): K[] => {
  const { itemIndex } = currentSelection;
  const itemId = rowKeyExtractor(data[itemIndex]);

  if (currentSelection.shiftKeyPressed && previousSelection) {
    const { fromIndex, toIndex } = getRange(
      previousSelection.itemIndex,
      currentSelection.itemIndex,
    );

    const updatedSelection = [...selection];
    const shouldAdd = !isItemSelected(selection, itemId);
    const idsToUpdate = data.slice(fromIndex, toIndex + 1).map(rowKeyExtractor);

    if (shouldAdd) {
      idsToUpdate.forEach((idToAdd) => {
        if (!isItemSelected(updatedSelection, idToAdd)) {
          updatedSelection.push(idToAdd);
        }
      });

      return updatedSelection;
    }

    return updatedSelection.filter((item) => !idsToUpdate.includes(item));
  }

  return isItemSelected(selection, itemId)
    ? selection.filter((item) => item !== itemId)
    : [...selection, itemId];
};
