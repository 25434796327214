import type { ModalFormProps } from '@/components/Button/formButton';
import SectionSplashScreen from '@/components/PageLoading/SplashSection';
import { useAppContext } from '@/contexts/AppContext';
import type { Lessee } from '@/domain/lease';
import { LesseeType } from '@/domain/lease';
import type { CancelledRenewalReason, RenewalDTO } from '@/domain/renewal';
import { RenewalStatus } from '@/domain/renewal';
import { useLease } from '@/hooks/services/leases';
import { useRenewal } from '@/hooks/services/renewals';
import useEnumeration from '@/hooks/useEnumeration';
import { RenewalService } from '@/services/renewal';
import { SHORT_DATE_FORMAT } from '@/utils/time';
import { getFullName, handleError } from '@/utils/utils';
import { Alert, Col, Divider, message, Modal, Row, Select, Statistic, Typography } from 'antd';
import moment from 'moment';
import type { FC } from 'react';
import { useState } from 'react';
import { Link } from 'react-router-dom';

interface Props extends ModalFormProps<[RenewalDTO]> {
  renewalId: number;
}

const CancelRenewalModal: FC<Props> = ({ onCancel, onSuccess, renewalId }) => {
  const { brands } = useAppContext();
  const [cancelledReason, setCancelledReason] = useState<CancelledRenewalReason>();
  const { options: cancelRenewalReasons } = useEnumeration('cancelled-renewal-reason');

  const { data: renewal } = useRenewal(renewalId);
  const { data: lease } = useLease(renewal?.leaseId);

  const handleCancel = () => {
    if (!renewal) {
      return;
    }

    RenewalService.update({
      ...renewal,
      status: RenewalStatus.CANCELLED,
      cancelledReason,
    })
      .then((updated) => {
        message.success('Renewal successfully updated');
        onSuccess(updated);
      })
      .catch((error) => {
        handleError(error, { toastFallbackMessage: 'Something went wrong updating the renewal' });
      });
  };

  return (
    <Modal
      visible
      width="810px"
      title="Cancel Renewal"
      onCancel={onCancel}
      okButtonProps={{
        danger: true,
        type: 'primary',
        disabled: !cancelledReason,
      }}
      okText="Cancel Renewal"
      onOk={handleCancel}
    >
      {renewal && lease ? (
        <>
          <Row>
            <Col md={6} data-testid="cancel-renewal-modal-address">
              <Statistic
                title="Address"
                valueRender={() => (
                  <Link to={`/properties/${lease.unit.propertyId}`}>
                    {lease!.unit.property!.address!.addr1}
                  </Link>
                )}
              />
            </Col>
            <Col md={4} data-testid="cancel-renewal-modal-lease-end-date">
              <Statistic
                title="Lease End Date"
                value={lease.endDate ? moment(lease.endDate).format(SHORT_DATE_FORMAT) : 'N/A'}
              />
            </Col>
            <Col md={6} data-testid="cancel-renewal-modal-brand">
              <Statistic
                title="Brand"
                value={brands.find((each) => each.id === lease.unit.property?.brandId)?.name}
              />
            </Col>
            <Col md={4} data-testid="cancel-renewal-modal-number-of-residents">
              <Statistic
                title="Number of Residents"
                value={
                  lease?.lessees.filter((lessee: Lessee) => lessee.type !== LesseeType.COSIGNER)
                    .length || 'N/A'
                }
              />
            </Col>
            <Col md={4} data-testid="cancel-renewal-modal-primary-resident">
              <Statistic
                title="Primary Leaser"
                valueRender={() => {
                  const primaryLessee = lease.lessees.find(
                    (lessee: Lessee) => lessee.type === LesseeType.PRIMARY,
                  );
                  return primaryLessee ? (
                    <Link to={`/person/${primaryLessee.partyId}`}>
                      {getFullName({
                        firstName: primaryLessee.person.firstName,
                        lastName: primaryLessee.person.lastName,
                      })}
                    </Link>
                  ) : (
                    'N/A'
                  );
                }}
              />
            </Col>
          </Row>
          <Divider />
          <Alert
            message={
              <span>
                <strong>Cancel Renewal:</strong> Are you sure you wish to mark this Renewal as
                cancelled?
              </span>
            }
            type="warning"
            showIcon
            data-testid="cancel-renewal-modal-warning"
          />
          <Row gutter={20} style={{ marginTop: 22 }}>
            <Col md={24}>
              <Typography.Title level={5}>Reason Given</Typography.Title>
              <Select
                value={cancelledReason}
                onChange={setCancelledReason}
                style={{ width: '100%' }}
                options={cancelRenewalReasons}
                data-testid="cancel-renewal-modal-reason-given-select"
              />
            </Col>
          </Row>
        </>
      ) : (
        <SectionSplashScreen />
      )}
    </Modal>
  );
};

export default CancelRenewalModal;
