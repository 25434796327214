import { RenewalStatus } from '@/domain/renewal';
import type {
  ColumnComponentType,
  ColumnComponentTypeProps,
} from '@/notmagic/components/EntityTable/types';
import { RenewalService } from '@/services/renewal';
import { handleError } from '@/utils/utils';
import { Button, message, Modal, Tooltip } from 'antd';
import moment from 'moment';
import { useState } from 'react';

type RowType = {
  id: number;
  residentMoveOutDate?: string;
  residentStatus: string;
  unitStatus: string;
  status: string;
};

type Props = ColumnComponentTypeProps<RowType>;

const Component: ColumnComponentType<Props> = ({ value, refreshEntities }) => {
  const [visible, setVisible] = useState(false);
  const [saving, setSaving] = useState(false);

  // Must set `"value": {props in RowType}` in metadata
  if (!value?.id) {
    return null;
  }

  const buttonEnabled =
    value.status !== 'RENEWED' &&
    (!value.residentMoveOutDate ||
      moment(value.residentMoveOutDate).isAfter(moment().startOf('day'))) &&
    ['CURRENT', 'NOTICE', 'HOLDOVER'].includes(value.residentStatus) &&
    value.unitStatus === 'OCCUPIED';

  const buttonTooltip = buttonEnabled
    ? undefined
    : 'A Renewal may only be re-opened if the affiliated lease has not expired, the resident is still in an active status, and the property is occupied.';

  const handleConfirm = async () => {
    setSaving(true);

    try {
      const data = await RenewalService.get(value.id);

      await RenewalService.update({
        ...data,
        status: data.options?.length ? RenewalStatus.OFFER_PENDING : RenewalStatus.NEW,
      });

      message.success('Renewal was successfully re-opened');

      refreshEntities();
    } catch (error) {
      handleError(error, { toastFallbackMessage: 'There was an error re-opening the renewal' });
    } finally {
      setSaving(false);
    }
  };

  return (
    <>
      <Tooltip title={buttonTooltip}>
        <Button
          type="primary"
          disabled={!buttonEnabled}
          loading={saving}
          onClick={() => setVisible(true)}
        >
          Re-Open Renewal
        </Button>
      </Tooltip>

      <Modal
        visible={visible}
        title="Re-Open Renewal?"
        okText="Confirm"
        onOk={handleConfirm}
        onCancel={() => {
          if (!saving) {
            setVisible(false);
          }
        }}
        confirmLoading={saving}
        cancelButtonProps={{ disabled: saving }}
      >
        Are you sure you wish to re-open this Renewal?
        <br />
        The Renewal will be returned to a status of Offer Pending.
      </Modal>
    </>
  );
};

export default {
  Component,
  getValueForExport: () => '',
};
