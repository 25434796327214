import { RenewalService } from '@/services/renewal';
import { useFetch } from '@/utils/request';

export const useRenewal = (renewalId?: number) => {
  return useFetch(
    {
      fetcher: renewalId ? RenewalService.get : undefined,
      errorMessage: 'There was an error fetching the renewal',
    },
    renewalId || 0,
  );
};
