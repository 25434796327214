import type { SavedFilter, SavedFilterSearchParams } from '@/domain/saved-filter';
import { api } from '@/utils/configuration';
import { crudService, getAxiosFetcher } from '@/utils/request';

export const SavedFilterService = {
  search: getAxiosFetcher<SavedFilter[], [SavedFilterSearchParams]>((params) => [
    api.SAVED_FILTERS,
    { params },
  ]),

  create: (filterSaved: Partial<SavedFilter> = {}) =>
    crudService.post<SavedFilter>(filterSaved, `${api.SAVED_FILTERS}`),

  delete: (id: number) => crudService.delete<void>(`${api.SAVED_FILTERS}/${id}`),
};
