import AuditsTable from '@/components/Audits';
import { EntityType } from '@/domain/entity-type';
import type {
  ColumnComponentType,
  ColumnComponentTypeProps,
  ColumnType,
} from '@/notmagic/components/EntityTable/types';
import { HistoryOutlined } from '@ant-design/icons';
import { Button, Modal } from 'antd';
import { useState } from 'react';

const Component: ColumnComponentType<ColumnComponentTypeProps<number>> = ({ value: id }) => {
  const [showModal, setShowModal] = useState(false);

  // Must set `"value": "$v.id"` in metadata
  if (typeof id !== 'number') {
    return null;
  }

  return (
    <>
      <Button icon={<HistoryOutlined />} type="link" onClick={() => setShowModal(true)} />

      {showModal && (
        <Modal visible title="Task History" footer={null} onCancel={() => setShowModal(false)}>
          <AuditsTable entityType={EntityType.TASK} entityId={id} />
        </Modal>
      )}
    </>
  );
};

const Definition: ColumnType = {
  Component,
  hideSort: true,
  getValueForExport: () => '',
};

export default Definition;
