import type { New } from '@/domain/base';
import type {
  Lease,
  LeaseSearchParams,
  LeaseSearchResult,
  LeaseUpdateRequest,
} from '@/domain/lease';
import { api } from '@/utils/configuration';
import { crudService, getAxiosFetcher } from '@/utils/request';

export const LeaseService = {
  findById: getAxiosFetcher<LeaseSearchResult, [id: number]>((id) => [`${api.LEASE}/${id}`]),

  search: getAxiosFetcher<LeaseSearchResult[], [LeaseSearchParams?]>((params) => [
    api.LEASE,
    { params },
  ]),

  create: (lease: New<Lease>) => crudService.post<Lease>(lease, api.LEASE),

  update: (lease: LeaseUpdateRequest) =>
    crudService.put<LeaseSearchResult>(lease, `${api.LEASE}/${lease.id}`),

  getLeaseDocumentLink: getAxiosFetcher<string, [id: number]>((id) => [
    `${api.LEASE}/${id}/lease-document-link`,
  ]),
};
