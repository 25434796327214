import { dateTimeColumn, Table } from '@/@propify-components';
import UserId from '@/components/UserId';
import type { Audit } from '@/domain/base';
import type { EntityType } from '@/domain/entity-type';
import { AuditService } from '@/services/audits';
import { Empty } from 'antd';
import type { FC } from 'react';
import { useEffect, useState } from 'react';

export interface Props {
  entityType: EntityType | EntityType[] | string | string[];
  entityId?: number;
  startTime?: string;
  endTime?: string;
  refreshIndex?: number;
  propertyId?: number;
  unitId?: number;
}

export const AuditsTable: FC<Props> = ({
  refreshIndex,
  entityType,
  propertyId,
  unitId,
  entityId,
}) => {
  const [loading, setLoading] = useState(true);
  const [audits, setAudits] = useState<Audit[]>([]);
  const [pageSize, setPageSize] = useState(50);

  const load = () => {
    setLoading(true);

    if (Array.isArray(entityType)) {
      const promises: Promise<Audit[]>[] = [];

      entityType.forEach((type) => {
        if (type === 'PROPERTY') {
          promises.push(AuditService.search({ entityType: type, entityId: propertyId }));
        } else {
          promises.push(AuditService.search({ entityType: type, entityId: unitId }));
        }
      });

      Promise.all(promises).then((result) => {
        setAudits(
          result
            .reduce((memo, entityAudits) => memo.concat(entityAudits), [])
            .sort((a, b) => (a.createTime > b.createTime ? -1 : 1)),
        );

        setLoading(false);
      });
    } else if (entityType === 'PROPERTY') {
      AuditService.search({ entityType, entityId: propertyId }).then((response) => {
        setAudits(response);
        setLoading(false);
      });
    } else if (entityType === 'UNIT') {
      AuditService.search({ entityType, entityId: unitId }).then((response) => {
        setAudits(response);
        setLoading(false);
      });
    } else {
      AuditService.search({ entityType, entityId }).then((response) => {
        setAudits(response);
        setLoading(false);
      });
    }
  };

  useEffect(() => {
    load();
  }, [refreshIndex, pageSize]);

  return audits && audits.length > 0 ? (
    <Table
      loading={loading}
      pageSize={pageSize}
      onPageSizeChange={setPageSize}
      className="propify-scrollable-table"
      columns={[
        dateTimeColumn({ title: 'Time', key: 'createTime' }),
        {
          title: 'User',
          key: 'createLoginId',
          render: (row: { createLoginId: number }) => <UserId loginId={row.createLoginId} />,
        },
        {
          title: 'Field',
          key: 'field',
        },
        {
          title: 'Old Value',
          key: 'oldValue',
        },
        {
          title: 'New Value',
          key: 'newValue',
        },
        ...(Array.isArray(entityType)
          ? [
              {
                title: 'Type',
                key: 'entityType',
              },
            ]
          : []),
      ]}
      data={audits}
      rowKeyExtractor={(audit) => audit.id}
      responsive={false}
    />
  ) : (
    <Empty />
  );
};

export default AuditsTable;
