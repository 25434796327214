import React from 'react';

export interface IconProps {
  className?: string;
  width?: number;
  height?: number;
  style?: React.CSSProperties;
  onClick?: () => void;
}

type UpDown = 'UP' | 'DOWN';
type UpDownLeftRight = 'UP' | 'DOWN' | 'LEFT' | 'RIGHT';

interface SortProps {
  direction?: UpDown;
}

interface ChevronProps {
  direction?: UpDownLeftRight;
}

interface PinnedProps {
  active?: boolean;
}

export const SortTable = (props: IconProps & SortProps) => (
  <svg width={8} height={9} viewBox="0 0 8 9" fill="none" {...props}>
    <path d="M0 5L4 9L8 5H0Z" fill={props.direction === 'UP' ? '#1890ff' : '#afafaf'} />
    <path d="M0 4L4 0L8 4H0Z" fill={props.direction === 'DOWN' ? '#1890ff' : '#afafaf'} />
  </svg>
);

export const ArrowDown = (props: IconProps) => (
  <svg width="12" height="8" viewBox="0 0 12 8" fill="none" {...props}>
    <path
      d="M1.41 0.589966L6 5.16997L10.59 0.589966L12 1.99997L6 7.99997L0 1.99997L1.41 0.589966Z"
      fill="#323232"
    />
  </svg>
);

export const Arrow = (props: IconProps & ChevronProps) => {
  const renderIcon = (direction: UpDownLeftRight) => {
    switch (direction) {
      case 'LEFT':
        return <path d="M7.41 1.41L6 0L0 6L6 12L7.41 10.59L2.83 6L7.41 1.41Z" fill="#323232" />;
      case 'RIGHT':
        return (
          <path
            d="M1.99984 0L0.589844 1.41L5.16984 6L0.589844 10.59L1.99984 12L7.99984 6L1.99984 0Z"
            fill="#323232"
          />
        );
      case 'UP':
        return <path d="M6 0L0 6L1.41 7.41L6 2.83L10.59 7.41L12 6L6 0Z" fill="#323232" />;
      case 'DOWN':
      default:
        return (
          <path
            d="M10.59 0.589966L6 5.16997L1.41 0.589966L0 1.99997L6 7.99997L12 1.99997L10.59 0.589966Z"
            fill="#323232"
          />
        );
    }
  };
  return (
    <svg
      width="8"
      height="12"
      viewBox={`0 0 ${['LEFT', 'RIGHT'].includes(props.direction!) ? '8 12' : '12 8'}`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      {renderIcon(props.direction!)}
    </svg>
  );
};

export const SortRow = (props: IconProps) => (
  <svg
    width="12"
    height="8"
    viewBox="0 0 18 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path d="M0 12H18V10H0V12ZM0 7H18V5H0V7ZM0 0V2H18V0H0Z" fill="#959595" />
  </svg>
);

export const ArrowUp = (props: IconProps) => (
  <svg
    width="12"
    height="8"
    viewBox="0 0 12 8"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path d="M1.41 7.41L6 2.83L10.59 7.41L12 6L6 0L0 6L1.41 7.41Z" fill="#323232" />
  </svg>
);

export const Pinned = ({ active, onClick, style }: IconProps & PinnedProps) => (
  <svg height="13" viewBox="0 0 14 20" fill="none" onClick={onClick} style={style}>
    <path
      d="M9 2V7C9 8.12 9.37 9.16 10 10H4C4.65 9.14 5 8.1 5 7V2H9ZM12 0H2C1.45 0 1 0.45 1 1C1 1.55 1.45 2 2 2H3V7C3 8.66 1.66 10 0 10V12H5.97V19L6.97 20L7.97 19V12H14V10C12.34 10 11 8.66 11 7V2H12C12.55 2 13 1.55 13 1C13 0.45 12.55 0 12 0Z"
      fill={active ? '#1890ff' : '#666'}
    />
  </svg>
);

export const FlagOutlined: React.FC<IconProps> = ({ className, style, onClick, width, height }) => (
  <svg
    viewBox="64 64 896 896"
    focusable="false"
    className={className}
    data-icon="flag"
    width={width || '1em'}
    height={height || '1em'}
    fill="currentColor"
    aria-hidden="true"
    style={style}
    onClick={onClick}
  >
    <path d="M880 305H624V192c0-17.7-14.3-32-32-32H184v-40c0-4.4-3.6-8-8-8h-56c-4.4 0-8 3.6-8 8v784c0 4.4 3.6 8 8 8h56c4.4 0 8-3.6 8-8V640h248v113c0 17.7 14.3 32 32 32h416c17.7 0 32-14.3 32-32V337c0-17.7-14.3-32-32-32zM184 568V232h368v336H184zm656 145H504v-73h112c4.4 0 8-3.6 8-8V377h216v336z" />
  </svg>
);
