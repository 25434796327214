import { useAppContext } from '@/contexts/AppContext';
import type { Enum, Option } from '@/domain/base';
import type {
  FilterComponentType,
  FilterComponentTypeProps,
  FilterDefinition,
} from '@/notmagic/components/EntityTable/types';
import { filterSelectOptions } from '@/utils/utils';
import { Select, Spin } from 'antd';
import jsonata from 'jsonata';
import { useEffect, useMemo, useState } from 'react';
import { useImmutableEndpoint } from '../../../hooks/request';

type Props = FilterComponentTypeProps & {
  enumeration?: string;
  multiple?: boolean;
  filter?: string;
};

const Component: FilterComponentType<Props> = ({
  editable,
  enumeration,
  multiple,
  onChangeValue,
  value,
  filter,
  otherValues,
  currentTab,
}) => {
  const { currentUser } = useAppContext();
  const { data: options, isValidating: loading } = useImmutableEndpoint<Enum[]>(
    enumeration ? `/enumerations/${enumeration}` : undefined,
  );
  const [visibleOptions, setVisibleOptions] = useState<Option[]>();

  const selectedOptions = useMemo(
    () =>
      (options || []).filter((each) =>
        Array.isArray(value) ? value.includes(each.value) : value === each.value,
      ),
    [options, value],
  );

  useEffect(() => {
    const mapper = (option: Enum) => ({
      label: option.displayName,
      value: option.value,
    });

    if (!filter?.trim()) {
      setVisibleOptions([...(options || [])].map(mapper));
      return;
    }

    const expression = `[
      $filter(
        $,
        function($v, $i, $a) { ${filter} }
      )
    ]`;

    jsonata(expression)
      .evaluate(options || [], { ...otherValues, currentUser, currentTab })
      .then((result) => {
        setVisibleOptions(result.map(mapper));
      })
      .catch((error) => {
        // eslint-disable-next-line no-console
        console.error('There was an error filtering the options in the filter dropdown', error);
      });
  }, [options, filter, otherValues, currentTab]);

  if (!enumeration) {
    return (
      <div style={{ color: 'red' }}>
        {`"enumeration" must be configured for this type of filter`}
      </div>
    );
  }

  if (!editable) {
    if (loading) {
      return <Spin size="small" />;
    }

    return <>{selectedOptions.map((option) => option.displayName).join(', ')}</>;
  }

  return (
    <Select
      onChange={onChangeValue}
      style={{ width: '100%' }}
      options={visibleOptions}
      value={value}
      mode={multiple ? 'multiple' : undefined}
      showSearch
      allowClear
      loading={loading || !visibleOptions}
      filterOption={filterSelectOptions('label')}
    />
  );
};

const Filter: FilterDefinition = {
  Component,
  jsonataQuery: (value: string | string[], key: string) =>
    Array.isArray(value) ? `$v.${key} in ${JSON.stringify(value)}` : `$v.${key} = '${value}'`,
};

export default Filter;
