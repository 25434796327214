import { useImmutableEndpoint } from '@/notmagic/hooks/request';
import type { Entity, EntityType } from '@/notmagic/types';
import { filterSelectOptions } from '@/utils/utils';
import { Select } from 'antd';
import jsonata from 'jsonata';
import type { FC } from 'react';
import { useEffect, useState } from 'react';

type Props = {
  entityType: EntityType;
  multiple?: boolean;
  filter?: string;
  value?: number | number[];
  onChangeValue: (value?: number | number[]) => void;
  bindings?: Record<string, any>;
  allowClear?: boolean;
  nullOption?: {
    value: number;
    label: string;
  };
};

// TODO: support type-ahead lookup option
const EntitySelect: FC<Props> = ({
  entityType,
  multiple,
  filter,
  value,
  onChangeValue,
  bindings,
  allowClear,
  nullOption,
}) => {
  const [search, setSearch] = useState('');
  const [filteredEntities, setFilteredEntities] = useState<Entity[]>();
  const [options, setOptions] = useState<any[]>();

  const { data: entities, isValidating: loading } = useImmutableEndpoint<Entity[]>(
    entityType.endpoint,
  );

  useEffect(() => {
    if (entities) {
      const expression = `[
        $filter(
          $,
          function($v, $i, $a) {
            ${filter ? filter : 'true'}
          }
        )
      ]`;

      jsonata(expression)
        .evaluate(entities, bindings)
        .then(setFilteredEntities)
        // eslint-disable-next-line no-console
        .catch((error) => console.error(error));
    }
  }, [filter, entities, bindings]);

  useEffect(() => {
    if (filteredEntities) {
      const expression = `[$map(
        $,
        function($v, $i, $a) {
          $v.{
            'value': ${entityType.keyExpression ? entityType.keyExpression : 'id'},
            'label': ${entityType.displayNameExpression ? entityType.displayNameExpression : 'name'}
          }
        }
      )^(label)]`;

      jsonata(expression)
        .evaluate(filteredEntities)
        .then(setOptions)
        // eslint-disable-next-line no-console
        .catch((error) => console.error(error));
    }
  }, [
    filteredEntities,
    entityType.displayNameExpression,
    entityType.keyExpression,
    search,
    filter,
  ]);

  return (
    <Select
      value={nullOption ? (value ? value : nullOption.value) : value}
      options={
        nullOption
          ? [{ value: nullOption.value, label: nullOption.label }, ...(options ?? [])]
          : options
      }
      loading={loading}
      onChange={onChangeValue}
      onSearch={setSearch}
      showSearch
      allowClear={allowClear}
      defaultActiveFirstOption={false}
      mode={multiple ? 'multiple' : undefined}
      style={{ width: '100%' }}
      filterOption={filterSelectOptions('label')}
    />
  );
};

export default EntitySelect;
