import type { Column } from '@/@propify-components';
import { Table } from '@/@propify-components';
import type { DocumentTemplate } from '@/domain/document-templates';
import { DeleteFilled } from '@ant-design/icons';
import { Button, Popconfirm } from 'antd';
import { useMemo, type FC } from 'react';
import type { SortEndHandler } from 'react-sortable-hoc';

interface Props {
  leaseTemplates: DocumentTemplate[];
  onRemove?: (id: number) => void;
  handleReorder?: SortEndHandler;
  className?: string;
  hideActions?: boolean;
  loading?: boolean;
  removeConfirmationText: string;
}

const LeaseTemplatesTable: FC<Props> = ({
  leaseTemplates,
  hideActions,
  onRemove,
  handleReorder,
  className,
  loading,
  removeConfirmationText,
}) => {
  const columns = useMemo(() => {
    const _columns: Column<DocumentTemplate>[] = [
      {
        title: 'Lease Template Name',
        key: 'name',
        width: 468,
      },
      {
        title: 'Description',
        key: 'description',
        width: 423,
      },
    ];
    if (!hideActions) {
      _columns.push({
        title: '',
        key: 'actions',
        width: 60,
        render: (row, index) => (
          <Popconfirm
            title={removeConfirmationText}
            onConfirm={() => onRemove?.(row.id)}
            okText="Yes"
            cancelText="No"
          >
            <Button
              icon={<DeleteFilled />}
              type="link"
              size="small"
              danger
              data-testid={`remove-lease-template-${index}`}
            />
          </Popconfirm>
        ),
      });
    }
    return _columns;
  }, [hideActions]);
  return (
    <Table
      onRowDrop={handleReorder}
      rowKeyExtractor={(r) => r.id}
      data={leaseTemplates}
      className={className}
      loading={loading}
      columns={columns}
    />
  );
};

export default LeaseTemplatesTable;
