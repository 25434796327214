import type {
  LeaseDocumentTemplateEntityBulkRequest,
  LeaseDocumentTemplateEntitySearchResult,
  LeaseDocumentTemplateSearchParams,
  MoveLeaseDocumentTemplateEntityRequest,
} from '@/domain/lease-document-templates';
import { api, serializeAssociation } from '@/utils/configuration';
import { crudService, getAxiosFetcher } from '@/utils/request';

export const LeaseDocumentTemplatesService = {
  bulk: (request: LeaseDocumentTemplateEntityBulkRequest) =>
    crudService.put<void>(request, `${api.LEASE_DOCUMENT_TEMPLATES}/entities/bulk`),

  updateRank: (request: MoveLeaseDocumentTemplateEntityRequest) =>
    crudService.put<void>(request, `${api.LEASE_DOCUMENT_TEMPLATES}/entities/rank`),

  search: getAxiosFetcher<
    LeaseDocumentTemplateEntitySearchResult[],
    [LeaseDocumentTemplateSearchParams?]
  >((params) => [
    `${api.LEASE_DOCUMENT_TEMPLATES}/entities`,
    { params: { ...params, association: serializeAssociation(params?.association) } },
  ]),
};
