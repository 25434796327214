import DateDistinctionDot from '@/components/DateDistinctionDot';
import type { TicketPriority } from '@/domain/ticket';
import { TicketStatus, TicketType } from '@/domain/ticket';
import type {
  ColumnComponentType,
  ColumnComponentTypeProps,
  ColumnType,
} from '@/notmagic/components/EntityTable/types';
import type { CollectionTicketFormValues } from '@/pages/resident/CollectionsBulkCreateTickets';
import CollectionsBulkCreateTickets from '@/pages/resident/CollectionsBulkCreateTickets';
import { TicketService } from '@/services/tickets';
import { SHORT_DATE_FORMAT, SHORT_DATE_TIME_FORMAT } from '@/utils/time';
import { handleError } from '@/utils/utils';
import { Button, message } from 'antd';
import moment from 'moment';
import { useState } from 'react';

type RowType = {
  time: string | undefined;
  partyId: number | undefined;
  entities: Record<string, number> | undefined;
  subSkill: string | undefined;
  link?: string;
};

const Component: ColumnComponentType<ColumnComponentTypeProps<RowType>> = ({
  value,
  refreshEntities,
}) => {
  const [createTicket, setCreateTicket] = useState<RowType>();
  const [saving, setSaving] = useState(false);

  const onCreateTicketsConfirm = (values: CollectionTicketFormValues) => {
    setSaving(true);

    TicketService.create({
      ...values,
      partyId: createTicket?.partyId,
      priority: values.priority as TicketPriority,
      status: TicketStatus.OPEN,
      entities: createTicket?.entities,
    })
      .then(() => {
        message.success('Ticket successfully created');
        setCreateTicket(undefined);
        refreshEntities();
      })
      .catch((error) => {
        handleError(error, { toastFallbackMessage: 'There was an error creating the ticket' });
      })
      .finally(() => {
        setSaving(false);
      });
  };

  if (value?.time) {
    return (
      <>
        {moment(value?.time).format(SHORT_DATE_FORMAT)}
        <DateDistinctionDot date={value.time} />
      </>
    );
  }

  return (
    <>
      <Button type="primary" onClick={() => setCreateTicket(value)}>
        Create Ticket
      </Button>

      {createTicket && (
        <CollectionsBulkCreateTickets
          type={TicketType.AGENT_CREATED}
          subSkill={value?.subSkill}
          saving={saving}
          onCancel={() => setCreateTicket(undefined)}
          onConfirm={onCreateTicketsConfirm}
        />
      )}
    </>
  );
};

const Definition: ColumnType = {
  Component,
  getValueForExport: (value) =>
    value.time ? moment(value.time).format(SHORT_DATE_TIME_FORMAT) : '',
  sort: (a, b) => {
    if (!a?.time && !b?.time) {
      return 0;
    }
    if (!a?.time) {
      return 1;
    }
    if (!b?.time) {
      return -1;
    }
    return a.time.localeCompare(b.time);
  },
};

export default Definition;
