import type { PromotionLookup } from '@/domain/promotions';
import type {
  ColumnComponentType,
  ColumnComponentTypeProps,
} from '@/notmagic/components/EntityTable/types';
import { useImmutableEndpoint } from '@/notmagic/hooks/request';
import { getPromotionsPreview } from '@/pages/property/utils';
import { Popover } from 'antd';
import isEmpty from 'lodash/isEmpty';
import { useMemo } from 'react';

type Props = ColumnComponentTypeProps<number[]>;

const Component: ColumnComponentType<Props> = ({ value = [] }) => {
  const { data: promotions } = useImmutableEndpoint<any[]>('/promotions/lookup');

  const unitPromotions = useMemo(
    () => (value.length ? getPromotionsPreview(value, promotions) : []),
    [value, promotions],
  );

  if (!value.length) {
    // Metadata must set the "value" prop for this cell to be rendered correctly
    return null;
  }

  const promotionsText = (promotionsList: PromotionLookup[]) => {
    return (
      <div style={{ width: '300px', whiteSpace: 'break-spaces' }}>
        {promotionsList.map((promotion) => (
          <span key={promotion.id}>
            <strong>{promotion.displayName}</strong>
            <p>{promotion.text}</p>
          </span>
        ))}
      </div>
    );
  };

  return !isEmpty(unitPromotions) ? (
    <Popover content={() => promotionsText(unitPromotions)} title="Promotions" placement="left">
      <span style={{ cursor: 'pointer', textOverflow: 'ellipsis' }}>
        {unitPromotions.map((unitPromotion) => unitPromotion.displayName).join(', ')}
      </span>
    </Popover>
  ) : null;
};

export default {
  Component,
};
