import type { ModalFormProps } from '@/components/Button/formButton';
import { AntCheckbox, AntSelect, AntTextArea } from '@/components/Formik/CreateAntField';
import type { ApplicationFile } from '@/domain/applications';
import useEnumeration from '@/hooks/useEnumeration';
import { ApplicationsService } from '@/services/applications';
import type { FormikSubmitFn } from '@/utils/types';
import { filterSelectOptions, handleError } from '@/utils/utils';
import { Form, message, Modal } from 'antd';
import { Field, Formik } from 'formik';
import type { FC } from 'react';
import * as Yup from 'yup';

export type Values = {
  type: string | string[] | undefined;
  sendEmail: boolean;
  noteToApplicant?: string;
};

interface Props extends ModalFormProps {
  applicationFile?: ApplicationFile;
  applicationId: number;
}

const validationMultipleTypeSchema = {
  type: Yup.array().min(1, 'Required').required('Required'),
};

const validationTypeSchema = {
  type: Yup.string().trim('Required').required('Required'),
};

const validationSchema = {
  noteToApplicant: Yup.string()
    .nullable()
    .notRequired()
    .trim()
    .min(1, 'At least 1 character')
    .max(255, 'Note is too long. Max 255 characters'),
};

const ApplicationFileModal: FC<Props> = ({
  onSuccess,
  onCancel,
  applicationId,
  applicationFile,
}) => {
  const { options: applicationFileTypeOptions } = useEnumeration('application-file-type');

  const save: FormikSubmitFn<Values> = (values, { setSubmitting }) => {
    if (!values.type) {
      return;
    }

    setSubmitting(true);
    let promise;
    if (applicationFile) {
      applicationFile.type = values.type as string;
      applicationFile.sendEmail = values.sendEmail;
      applicationFile.noteToApplicant = values.noteToApplicant;
      promise = ApplicationsService.updateFile(applicationId, applicationFile);
    } else {
      const applicationFiles = (values.type as string[]).map((type: string) => ({
        type,
        sendEmail: values.sendEmail,
        noteToApplicant: values.noteToApplicant,
      }));
      promise = ApplicationsService.bulkFile(applicationId, { applicationFiles });
    }

    promise
      .then(() => {
        message.success('The file was successfully requested');
        onSuccess();
      })
      .catch((error) => {
        handleError(error, { toastFallbackMessage: 'There was an error requesting the file' });
      })
      .finally(() => {
        setSubmitting(false);
      });
  };

  const validations = Yup.object({
    ...validationSchema,
    ...(applicationFile ? validationTypeSchema : validationMultipleTypeSchema),
  });

  return (
    <Formik<Values>
      onSubmit={save}
      validationSchema={validations}
      initialValues={{
        type: applicationFile ? applicationFile.type : undefined,
        sendEmail: true,
        noteToApplicant: applicationFile ? applicationFile.noteToApplicant : undefined,
      }}
    >
      {({ handleSubmit, submitCount, submitForm, isSubmitting, values }) => (
        <Modal
          visible
          onCancel={isSubmitting ? undefined : onCancel}
          onOk={submitForm}
          cancelButtonProps={{ disabled: isSubmitting }}
          okButtonProps={{ disabled: isSubmitting, loading: isSubmitting }}
          okText="Submit"
          title="Request File"
          maskClosable={false}
        >
          <Form onSubmitCapture={handleSubmit}>
            <Field
              loading={!applicationFileTypeOptions.length}
              component={AntSelect}
              name="type"
              label="Type"
              options={applicationFileTypeOptions}
              submitCount={submitCount}
              hasFeedback
              mode={applicationFile ? undefined : 'multiple'}
              showSearch
              filterOption={filterSelectOptions('label')}
            />
            <Field
              component={AntTextArea}
              label="Note to Applicant"
              name="noteToApplicant"
              type="text"
              hasFeedback
              submitCount={submitCount}
              autoSize
            />
            <Field
              component={AntCheckbox}
              label="Send email to applicant informing them of new file request"
              name="sendEmail"
              type="checkbox"
              checked={values.sendEmail}
            />
          </Form>
        </Modal>
      )}
    </Formik>
  );
};

export default ApplicationFileModal;
