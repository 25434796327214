import { Align } from '@/@propify-components';
import FloatingEditableAttribute from '@/components/FloatingEditableAttribute';
import type {
  ColumnComponentType,
  ColumnComponentTypeProps,
  ColumnType,
} from '@/notmagic/components/EntityTable/types';
import { EditOutlined } from '@ant-design/icons';
import { Button, InputNumber } from 'antd';
import { Formik } from 'formik';
import { useRef, useState } from 'react';

type Props = ColumnComponentTypeProps<number> & {
  format?: 'number' | 'currency';
  /**
   * @see https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/NumberFormat/NumberFormat#options
   */
  numberFormatOptions?: Intl.NumberFormatOptions;
};

type Values = {
  field?: number;
};
const Component: ColumnComponentType<Props> = ({
  value,
  format = 'number',
  numberFormatOptions,
  textIfNull,
  editable,
  update,
}) => {
  const [editing, setEditing] = useState(false);
  const baseRef = useRef<HTMLDivElement>(null);

  const formatOptions: Intl.NumberFormatOptions = {
    maximumFractionDigits: 2,
    currencySign: 'accounting',
    ...(numberFormatOptions || {}),
    ...(format === 'currency'
      ? {
          style: 'currency',
          currency: 'USD',
        }
      : {}),
  };

  const displayValue =
    value !== undefined ? new Intl.NumberFormat('en-US', formatOptions).format(value) : textIfNull;

  if (!editable) {
    return <>{displayValue}</>;
  }

  const onSubmit = async (values: Values) => {
    await update!(values.field!);
    setEditing(false);
  };

  return (
    <div ref={baseRef} className="floating-editing-container">
      {editing ? (
        baseRef.current && (
          <Formik<Values>
            initialValues={{
              field: value,
            }}
            onSubmit={onSubmit}
          >
            {({ isSubmitting, handleSubmit, setFieldValue, isValid, values }) => (
              <FloatingEditableAttribute
                baseElement={baseRef.current!}
                loading={isSubmitting}
                onCancel={() => setEditing(false)}
                onConfirm={handleSubmit}
                disabled={!isValid}
              >
                <InputNumber
                  autoFocus
                  disabled={!isValid}
                  value={values.field}
                  onChange={(newValue) => setFieldValue('field', newValue)}
                  style={{ width: '100%' }}
                />
              </FloatingEditableAttribute>
            )}
          </Formik>
        )
      ) : (
        <div className="display-value">
          <span>{displayValue}</span>

          <Button
            className="edit-button"
            icon={<EditOutlined />}
            type="link"
            size="small"
            onClick={() => setEditing(true)}
          />
        </div>
      )}
    </div>
  );
};

const Definition: ColumnType = {
  Component,
  align: Align.right,
  sort: (a: number, b: number) => {
    if (a === b) return 0;
    if (a === undefined) return -1;
    if (b === undefined) return 1;
    return a < b ? -1 : 1;
  },
};

export default Definition;
