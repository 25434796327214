import BackgroundCheckIcon from '@/assets/icons/background-check.svg?react';
import CreditCardIcon from '@/assets/icons/credit-card.svg?react';
import CriminalCheckIcon from '@/assets/icons/criminal-check.svg?react';
import IdIcon from '@/assets/icons/id.svg?react';
import { CreditRecommendation } from '@/domain/applications';
import { Tooltip } from 'antd';
import classNames from 'classnames';
import type { FC } from 'react';
import './ApprovalIcon.less';

export type ApprovalStatus = 'waiting' | 'denied' | 'accepted' | 'conditional';

export type ApprovalIconProps = {
  icon: 'id' | 'credit' | 'criminal' | 'background';
  size?: number;
  singleApplication?: boolean;
  status: ApprovalStatus;
  /**
   * Shows a gray icon and specific message in pop-over if `true`
   * @see https://skyboxcapital.atlassian.net/browse/FB-614
   */
  applicantRemoved?: boolean;
};

const iconsMap = {
  id: IdIcon,
  credit: CreditCardIcon,
  criminal: CriminalCheckIcon,
  background: BackgroundCheckIcon,
};

const tooltipsTextsPlural = {
  'id-waiting': 'ID verification pending',
  'id-denied': 'One or more applicants denied due to ID verification',
  'id-accepted': 'All applicants approved based on ID verification ',
  'credit-waiting': 'Credit background pending',
  'credit-denied': 'One or more applicants denied due to credit screening',
  'credit-accepted': 'All applicants approved based on credit requirements',
  'credit-conditional': 'Applicant conditional due to credit screening',
  'criminal-waiting': 'Criminal background pending',
  'criminal-denied': 'One or more applicants denied due to criminal screening',
  'criminal-accepted': 'All applicants approved based on criminal requirements',
  'background-waiting': 'Eviction & bankruptcy pending',
  'background-denied': 'One or more applicants denied due to past eviction or bankruptcy screening',
  'background-accepted':
    'All applicants approved based on past eviction or bankruptcy requirements',
};

const tooltipsTextsSingular = {
  'id-waiting': 'ID verification pending',
  'id-denied': 'Applicant denied due to ID verification',
  'id-accepted': 'Applicant approved based on ID verification',
  'credit-waiting': 'Credit background pending',
  'credit-denied': 'Applicant denied due to credit screening',
  'credit-accepted': 'Applicant approved based on credit requirements',
  'credit-conditional': 'Applicant conditional due to credit screening',
  'criminal-waiting': 'Criminal background pending',
  'criminal-denied': 'Applicant denied due to criminal screening',
  'criminal-accepted': 'Applicant approved based on criminal requirements',
  'background-waiting': 'Eviction & bankruptcy pending',
  'background-denied': 'Applicant denied due to past eviction or bankruptcy screening',
  'background-accepted': 'Applicant approved based on past eviction or bankruptcy requirements',
};

const creditRecommendationsStatus: Record<CreditRecommendation, ApprovalStatus> = {
  [CreditRecommendation.APPROVED]: 'accepted',
  [CreditRecommendation.CONDITIONAL]: 'conditional',
  [CreditRecommendation.PENDING]: 'waiting',
  [CreditRecommendation.REJECTED]: 'denied',
};

export const getBooleanStatus = (approved?: boolean | null): ApprovalStatus =>
  approved === undefined || approved === null ? 'waiting' : approved ? 'accepted' : 'denied';

export const getCreditRecommendationStatus = (
  creditRecommendations: CreditRecommendation,
): ApprovalStatus => creditRecommendationsStatus[creditRecommendations];

const ApprovalIcon: FC<ApprovalIconProps> = ({
  icon,
  size = 30,
  singleApplication,
  status,
  applicantRemoved,
}) => {
  const getTooltipText = (id: string) => {
    if (applicantRemoved) {
      return 'Applicant has been removed from application';
    }

    const tooltipsTexts = singleApplication ? tooltipsTextsSingular : tooltipsTextsPlural;
    return tooltipsTexts[id];
  };

  const IconComponent = iconsMap[icon];

  return (
    <Tooltip placement="bottom" title={getTooltipText(`${icon}-${status}`)}>
      <IconComponent
        data-testid="approval-icon"
        className={classNames(
          'approval-icon',
          `approval-icon-status-${status}`,
          `approval-icon-type-${icon}`,
          {
            ['approval-icon-disabled']: applicantRemoved,
          },
        )}
        style={{ width: size, height: size }}
      />
    </Tooltip>
  );
};

export default ApprovalIcon;
