import { getOperationsFromAttributes } from '@/components/Formik/EditableAttributes';
import type { EditablePageSaveFn } from '@/components/Formik/EditableAttributes/types';
import EditablePage from '@/components/Formik/EditablePage';
import type { Application } from '@/domain/applications';
import useEnumeration from '@/hooks/useEnumeration';
import { ApplicationsService } from '@/services/applications';
import { PersonService } from '@/services/person';
import { handleError } from '@/utils/utils';
import { message } from 'antd';
import type { Moment } from 'moment';
import moment from 'moment';
import type { FC } from 'react';
import { Link } from 'react-router-dom';

interface Props {
  application: Application;
  onUpdateApplication: (application: Application) => void;
}

const ApplicantDetails: FC<Props> = ({ application, onUpdateApplication }) => {
  const { options: maritalStatusOptions } = useEnumeration('marital-status');
  const { options: nameSuffixesOptions } = useEnumeration('name-suffix');

  const onSaveApplication: EditablePageSaveFn = async ({ values, attributes }) => {
    const operations = getOperationsFromAttributes(attributes, values);
    ApplicationsService.patch(application.id, operations, application.version)
      .then((updatedApplication) => {
        message.success('Application updated successfully');
        onUpdateApplication(updatedApplication);
      })
      .catch((error) => {
        handleError(error, { toastFallbackMessage: 'There was an error updating the application' });
      });
  };

  const onSavePerson: EditablePageSaveFn = async ({ values, attributes }) => {
    const operations = getOperationsFromAttributes(attributes, values);
    PersonService.patch(application.person.id, operations, application.person.version)
      .then((updatedPerson) => {
        message.success('Application updated successfully');
        onUpdateApplication({ ...application, person: updatedPerson });
      })
      .catch((error) => {
        handleError(error, { toastFallbackMessage: 'There was an error updating the application' });
      });
  };

  return (
    <div className="applicant-details">
      <EditablePage
        singleColumn
        onSave={onSaveApplication}
        sections={[
          {
            title: '',
            type: 'EDITABLE_ATTRIBUTES',
            attributes: [
              {
                value: application.firstName,
                fieldName: 'firstName',
                key: 'firstName',
                label: 'First Name',
                type: 'Text',
                colSize: 4,
              },
              {
                value: application.middleName || '-',
                fieldName: 'middleName',
                key: 'middleName',
                label: 'Middle Name',
                type: 'Text',
                colSize: 4,
              },
              {
                value: application.lastName,
                fieldName: 'lastName',
                key: 'lastName',
                label: 'Last Name',
                type: 'Text',
                colSize: 4,
              },
              {
                value: application.nameSuffix,
                fieldName: 'nameSuffix',
                key: 'nameSuffix',
                label: 'Name Suffix',
                type: 'Select',
                options: nameSuffixesOptions,
                colSize: 4,
              },
              {
                value: application.person.dateOfBirth,
                fieldName: 'dateOfBirth',
                key: 'dateOfBirth',
                label: 'Date of Birth',
                type: 'Date',
                colSize: 4,
                onSave: onSavePerson,
                disabledDate: (date: Moment) => date.isAfter(moment()),
              },
              {
                value: application.person.maritalStatus,
                fieldName: 'maritalStatus',
                key: 'maritalStatus',
                label: 'Marital Status',
                type: 'Select',
                options: maritalStatusOptions,
                onSave: onSavePerson,
                colSize: 4,
              },
              {
                value: (
                  <Link to={`/contact?partyId=${application.person.id}`}>
                    {application.person.phones?.map((p) => p.rawNumber).join(', ')}
                  </Link>
                ),
                fieldName: 'phones',
                key: 'phones',
                label: 'Phone Number(s)',
                type: 'Text',
                readOnly: true,
                colSize: 4,
              },
              {
                value: (
                  <Link to={`/contact?partyId=${application.person.id}`}>
                    {application.person.emailAddresses?.map((e) => e.address).join(', ')}
                  </Link>
                ),
                fieldName: 'emailAddresses',
                key: 'emailAddresses',
                label: 'Emails',
                type: 'Text',
                readOnly: true,
                colSize: 4,
              },
            ],
          },
        ]}
      />
    </div>
  );
};

export default ApplicantDetails;
