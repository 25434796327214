import type { TableRef } from '@/@propify-components';
import { CloudDownloadOutlined, DownloadOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import type { FC, RefObject } from 'react';
import { useState } from 'react';
import { CSVLink } from 'react-csv';

export interface Props {
  tableRef: RefObject<TableRef<any, any>>;
  filename?: string;
  totalResults?: number;
}

const ExportButton: FC<Props> = ({ tableRef, filename, totalResults: total, children }) => {
  const [data, setData] = useState<any[]>([]);

  const handleClick = () => {
    setData(tableRef?.current?.dataExport?.() || []);
  };

  const getFilename = () => {
    if (filename) {
      return /\.\w+$/.test(filename) ? filename : `${filename}.csv`;
    }
    return 'export.csv';
  };

  return (
    <CSVLink data={data} filename={getFilename()}>
      <Button
        data-test="exportTableButton"
        title="Export results"
        onClick={handleClick}
        disabled={total === 0}
        icon={<CloudDownloadOutlined style={{ marginRight: 8 }} />}
      >
        {children ||
          (typeof total === 'number' ? (
            <>
              {`${total} ${total === 1 ? 'result' : 'results'}`} <DownloadOutlined />
            </>
          ) : (
            <>
              <DownloadOutlined /> Export
            </>
          ))}
      </Button>
    </CSVLink>
  );
};

export default ExportButton;
