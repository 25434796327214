import { EditOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import type { FormikProps } from 'formik';
import { Field, Formik } from 'formik';
import type { FC } from 'react';
import { useRef, useState } from 'react';
import type { FloatingInlineEditProps } from '.';
import FloatingEditableAttribute from '../FloatingEditableAttribute';
import { AntInput } from '../Formik/CreateAntField';

export interface Props extends Omit<FloatingInlineEditProps, 'inputRender'> {
  textColor?: string;
  validate?: (value: string) => string | undefined | null;
}

type Values = {
  field: string;
};

const FloatingInlineTextEdit: FC<Props> = ({
  initialValue,
  displayValue,
  onConfirm,
  textColor,
  validate,
}) => {
  const [editing, setEditing] = useState(false);
  const baseRef = useRef<HTMLDivElement>(null);
  const formRef = useRef<FormikProps<Values>>(null);

  const submit = (values: Values) => {
    onConfirm(
      values.field,
      () => {
        setEditing(false);
      },
      () => {
        formRef.current?.setSubmitting(false);
      },
    );
  };

  return (
    <div ref={baseRef} className="floating-editing-container">
      {editing ? (
        baseRef.current && (
          <Formik<Values>
            innerRef={formRef}
            initialValues={{
              field: initialValue || '',
            }}
            onSubmit={submit}
          >
            {({ isSubmitting, handleSubmit, isValid }) => (
              <FloatingEditableAttribute
                baseElement={baseRef.current!}
                loading={isSubmitting}
                onCancel={() => setEditing(false)}
                onConfirm={handleSubmit}
                disabled={!isValid}
              >
                <Field
                  component={AntInput}
                  type="text"
                  name="field"
                  validate={validate}
                  hasFeedback
                  data-testid="floating-input"
                />
              </FloatingEditableAttribute>
            )}
          </Formik>
        )
      ) : (
        <div className="display-value">
          <span className="value" style={{ color: textColor }}>
            {displayValue}
          </span>

          <Button
            className="edit-button"
            icon={<EditOutlined />}
            type="link"
            size="small"
            onClick={() => setEditing(true)}
            data-testid="edit-button"
          />
        </div>
      )}
    </div>
  );
};

export default FloatingInlineTextEdit;
