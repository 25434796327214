import type { Application, ApplicationType } from '@/domain/applications';
import type { ProcessAssociation } from '@/domain/process-association';
import type { Occupant, Pet, Vehicle } from '@/domain/unit-applications';
import type { Audit, New, PropifyEntity } from './base';
import type {
  Charge,
  LeaseSearchResult,
  UtilityResponsiblePartyRoleType,
  UtilityType,
} from './lease';
import type { Note } from './note';
import type { PartyRoleType, Person } from './person';

export enum RenewalStatus {
  NEW = 'NEW',
  REFUSED = 'REFUSED',
  APPROVED = 'APPROVED',
  OFFER_PENDING = 'OFFER_PENDING',
  OFFERED = 'OFFERED',
  COUNTERED = 'COUNTERED',
  ACCEPTED = 'ACCEPTED',
  DECLINED = 'DECLINED',
  RENEWED = 'RENEWED',
  CANCELLED = 'CANCELLED',
  NEGOTIATION = 'NEGOTIATION',
}

export enum CancelledRenewalReason {
  LEASE_GOING_MONTH_TO_MONTH = 'LEASE_GOING_MONTH_TO_MONTH',
  RENEWAL_HANDLED_OUTSIDE_OF_PROPIFY = 'RENEWAL_HANDLED_OUTSIDE_OF_PROPIFY',
  OTHER = 'OTHER',
  CANCELLED_LEASING = 'CANCELLED_LEASING',
}

export interface RenewalSearchParams {
  id?: number | number[];
  keyword?: string;
  state?: string | string[];
  brandId?: number | number[];
}

export interface Renewal extends PropifyEntity {
  leaseId: number;
  unitId: number;
  status: RenewalStatus;
  cancelledReason?: CancelledRenewalReason;
  declinedReason?: string;
  declinedBy?: PartyRoleType;
  declinedByLoginId?: number;
  declinedTime?: string;
  counterOfferAllowed: boolean;
  counterOffer?: string;
  leaseTerm?: number;
  startDate?: string;
}

export interface RenewalDTO extends Renewal {
  occupants: Occupant[];
  vehicles: Vehicle[];
  pets: Pet[];
  options: RenewalOption[];
  utilities: RenewalUtility[];
  charges: Charge[];
  applications: RenewalApplicationSearchResult[];
  associatedProcesses: ProcessAssociation[];
}

export type CreateRenewalDTO = Omit<New<Renewal>, 'unitId' | 'status' | 'counterOfferAllowed'>;

export interface UpdateRenewalDTO extends Omit<Renewal, 'unitId'> {
  options: RenewalOption[];
  utilities: RenewalUtility[];
  charges: Charge[];
}

export interface RenewalSearchResult extends Renewal {
  lease: LeaseSearchResult;
  rentalPrice: number;
  lastUnitMarketRentUpdate?: Audit;
  lastNote: Note;
  lastContactTime: string;
  applicationSearchResults: RenewalApplicationSearchResult[];
}

export enum RenewalApplicationStatus {
  PENDING = 'PENDING',
  REJECTED = 'REJECTED',
  ACCEPTED = 'ACCEPTED',
  CANCELLED = 'CANCELLED',
}

export interface RenewalOption extends PropifyEntity {
  renewalId: number;
  maxMonths: number;
  minMonths: number;
  charges: Charge[];
  promotionDetails?: string;
  yearOverYearEscalator?: number;
}

export interface RenewalUtility extends PropifyEntity {
  renewalId: number;
  type: UtilityType;
  responsiblePartyRoleType: UtilityResponsiblePartyRoleType;
}

export interface RenewalApplication extends PropifyEntity {
  renewalId: number;
  personId: number;
  applicationId?: number;
  emailAddress?: string;
  type: ApplicationType;
  status?: RenewalApplicationStatus;
  requiresScreening: boolean;
  requiresPayment: boolean;
  validatedIncome?: number;
}

export interface RenewalApplicationSearchResult extends RenewalApplication {
  application: Application;
  person: Person;
  residentSince: string;
}

export interface RenewalAnalytics {
  lastUnitMarketRentUpdateTime: string;
  totalValidatedIncome: number;
  rentToVerifiedIncome: number;
  workOrderCount: number;
  collectionCount: number;
}
