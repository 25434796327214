import type { LeaseSearchParams } from '@/domain/lease';
import { LeaseService } from '@/services/leases';
import { useFetch } from '@/utils/request';

export const useLeases = (params: LeaseSearchParams) => {
  return useFetch(
    {
      fetcher: LeaseService.search,
      errorMessage: 'There was an error fetching the leases',
    },
    params,
  );
};

export const useLease = (leaseId?: number) => {
  return useFetch(
    {
      fetcher: leaseId ? LeaseService.findById : undefined,
      errorMessage: 'There was an error fetching the lease',
    },
    leaseId || 0,
  );
};
