import type { CallDashboard } from './phone-calls';
import type { Ticket } from './ticket';

export enum CommunicationDirection {
  INBOUND = 'INBOUND',
  OUTBOUND = 'OUTBOUND',
}

export enum CommunicationType {
  PHONE_CALL = 'PHONE_CALL',
  EMAIL = 'EMAIL',
  MESSAGE = 'MESSAGE',
  NOTE = 'NOTE',
  DATE = 'DATE',
}

export enum CommunicationAssociateType {
  PHONE_CALL = 'PHONE_CALL',
  EMAIL = 'EMAIL',
  SMS_MESSAGE = 'SMS_MESSAGE',
}

export type CommunicationDashboard = {
  createDate: string;
  phoneCallCount: number;
  emailCount: number;
  smsMessageCount: number;
};

export type CommunicationsData = {
  communicationDashboard: CommunicationDashboard[];
  phoneCallDashboard: CallDashboard[];
};

export type EmailEntity = {
  id: number;
  direction: CommunicationDirection;
  from: string;
  to: string[];
  messageId: string;
  subject: string;
  createTime: string;
  readLoginId: number;
  readTime: string;
  ticketGroupName: string;
  entityType: string;
  entityId: number;
  ticketIds: number[];
};

export type CallEntity = {
  id: number;
  callSid: string;
  status: string;
  createTime: string;
  endTime: string;
  entityType: string;
  entityId: number;
  customerPhone: string;
  companyPhone: string;
  queueName: string;
  direction: CommunicationDirection;
  inBusinessHours: boolean;
  recordingUri: string;
  ticketIds: number[];
};

export type SMSEntity = {
  body: string;
  createTime: string;
  direction: CommunicationDirection;
  entityId: number;
  entityType: string;
  fromPhoneNumber: string;
  id: number;
  mediaURIs: string;
  messageId: string;
  messageServiceSid: string;
  readTime: string;
  readLoginId: number;
  status: string;
  toPhoneNumber: string;
  ticketIds: number[];
};

export type NoteEntity = {
  alert: boolean;
  alertRole: string;
  createTime: string;
  createLoginId: number;
  entities: { PERSON: number; TICKET: number };
  id: number;
  note: string;
  transparent: boolean;
  type: string;
  version: number;
  ticketIds: number[];
};

export type Communication = {
  createTime: string;
  type: CommunicationType;
  entity: EmailEntity | CallEntity | SMSEntity | NoteEntity;
};

export type AbandonedCallsGroup = {
  label: string;
  abandonedCalls: number;
  calls: number;
  abandonmentRate: number;
};

export type AbandonedCallsSearchParams = {
  queue?: string;
  createTime?: string;
};

export type AbandonedCallsSearchResult = {
  groupedByDate: AbandonedCallsGroup[];
  groupedByHour: AbandonedCallsGroup[];
  groupedByQueue: AbandonedCallsGroup[];
};

export interface CommunicationAssociateRequest {
  communications: Record<string, number[]>;
  partyIds: number[];
  ticket?: Partial<Ticket>;
}

export interface CommunicationEventsParams {
  partyId?: number;
  ticketId?: number;
}

export interface CommunicationEventData {
  type: CommunicationType;
  relatedTicketIds: number[];
  relatedPartyIds: number[];
  communication: EmailEntity | CallEntity | SMSEntity | NoteEntity;
}
