import { ModalFormButton } from '@/components/Button/formButton';
import { EditOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import type { ButtonType } from 'antd/lib/button';
import type { FC } from 'react';
import { useState } from 'react';
import type { BulkUpdateFormVariant } from './BulkUpdate';
import BulkUpdate from './BulkUpdate';
import type { BulkUpdateField } from './BulkUpdateForm';

export type Props = {
  onSuccess: (values: Record<string, any>) => void;
  disabled?: boolean;
  fields?: BulkUpdateField[];
  buttonType?: ButtonType;
  variant: BulkUpdateFormVariant;
};

const BulkUpdateButton: FC<Props> = ({ onSuccess, disabled, fields, buttonType, variant }) => {
  const [showDrawer, setShowDrawer] = useState(false);

  return variant === 'drawer' ? (
    <>
      <Button
        icon={<EditOutlined />}
        onClick={() => setShowDrawer(true)}
        disabled={disabled}
        type={buttonType}
      >
        Bulk Update
      </Button>
      <BulkUpdate
        variant={variant}
        fields={fields}
        visible={showDrawer}
        onCancel={() => setShowDrawer(false)}
        onSuccess={(values) => {
          setShowDrawer(false);
          onSuccess(values);
        }}
      />
    </>
  ) : (
    <ModalFormButton
      buttonText="Bulk Update"
      buttonType={buttonType}
      onSuccess={onSuccess}
      disabled={disabled}
    >
      {(p) => <BulkUpdate {...p} variant={variant} fields={fields} />}
    </ModalFormButton>
  );
};

export default BulkUpdateButton;
