import type { Lookup } from '@/domain/base';
import type { LookupType } from '@/services/lookup';
import LookupService from '@/services/lookup';
import { handleError, useDebounce } from '@/utils/utils';
import { Select } from 'antd';
import type { SelectProps } from 'antd/lib/select';
import type { FC } from 'react';
import { useEffect, useState } from 'react';

interface Props extends SelectProps<any> {
  /** Lookup function name or custom function */
  lookup: LookupType | (() => void);

  /**
   * Param name to use in the query string
   * E.g.: `keyword="q"` to send `?q=...`
   */
  keyword?: string;
  params?: Record<string, string | number>;
  multiple?: boolean;

  /**
   * If `false` results are fetched when the component is mounted
   * If `true` results are fetched on demand
   */
  lazy?: boolean;
}

const LookupSelect: FC<Props> = ({
  lookup,
  keyword,
  params,
  multiple = false,
  lazy = false,
  onSearch,
  ...props
}) => {
  const [loading, setLoading] = useState(false);
  const [options, setOptions] = useState<Lookup[]>([]);
  const [search, setSearch] = useState('');
  const debouncedSearch = useDebounce(search, 500);

  const lookupFn = LookupService[lookup as string] ?? lookup;
  if (typeof lookupFn !== 'function') {
    throw new Error('Property "lookup" must be a valid string or a function');
  }

  const load = () => {
    setLoading(true);

    const searchParams = { ...(params || {}) };
    if (lazy && keyword) {
      searchParams[keyword] = debouncedSearch;
    }

    return Promise.resolve(lookupFn(params || keyword ? searchParams : undefined))
      .then((list: Lookup[]) => {
        setOptions(list);
      })
      .catch((error) => {
        handleError(error, { toastMessage: 'Error loading lookup values' });
      })
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    if (!lazy || debouncedSearch) {
      load();
    }
  }, [lazy ? debouncedSearch : null]);

  return (
    <Select
      {...props}
      loading={loading}
      mode={multiple ? 'multiple' : props.mode}
      optionFilterProp="children" // To search by `displayName`
      searchValue={search}
      onSearch={(newSearch) => {
        setSearch(newSearch);
        onSearch?.(newSearch);
      }}
    >
      {options.map(({ id, displayName }) => (
        <Select.Option key={id} value={id} title="title">
          {displayName}
        </Select.Option>
      ))}
    </Select>
  );
};

export default LookupSelect;
