import type { CommunityAccessInformation } from '@/domain/community';
import type {
  ColumnComponentType,
  ColumnComponentTypeProps,
} from '@/notmagic/components/EntityTable/types';
import { getCommonAccessInformation } from '@/pages/communities/utils';

type RowType = {
  commonDoorLockCode: string | undefined;
  accessInformationList: CommunityAccessInformation;
};

const Component: ColumnComponentType<ColumnComponentTypeProps<RowType>> = ({ value }) => {
  if (!value) {
    return null;
  }

  return <>{getCommonAccessInformation(value.commonDoorLockCode, value.accessInformationList)}</>;
};

export default {
  Component,
  hideSort: true,
};
