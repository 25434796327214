import type { Enum } from '@/domain/base';
import type { PropertySearchResult } from '@/domain/property';
import { PropertyType } from '@/domain/property';
import { enumToLabel } from '@/utils/utils';
import { formatCurrency } from '@propify/components';
import { Typography } from 'antd';
import type { FC } from 'react';
import styles from './styles.module.less';

interface Props {
  property: PropertySearchResult;
  typesEnum: Enum<PropertyType>[];
}

const PropertySummary: FC<Props> = ({ property, typesEnum }) => {
  const isSingleUnit = property.type === PropertyType.SINGLE_FAMILY;

  return (
    <div className={styles.propertySummary}>
      <div>
        <Typography.Text>{property.address?.addr1}</Typography.Text>
      </div>
      <div>
        <Typography.Text strong>{formatCurrency(property.valuation?.amount)}</Typography.Text>
      </div>
      <div>
        <Typography.Text type="secondary">
          {typesEnum.find((t) => t.value === property.type)?.displayName ?? property.type}
        </Typography.Text>
      </div>

      {isSingleUnit ? (
        <>
          <hr />

          <div>
            <Typography.Text>
              Unit Status: {enumToLabel((property.unitStatus || '') as string)}
            </Typography.Text>
          </div>
          <div>
            <Typography.Text>
              Listing Status: {enumToLabel((property.unitListingStatus || '') as string)}
            </Typography.Text>
          </div>

          {property.unitStatus !== 'VACANT' && property.transactionStatus === 'ACTIVE' ? (
            <div>
              <Typography.Text>Rent: {formatCurrency(property.unitCurrentRent)}</Typography.Text>
            </div>
          ) : null}

          {property.unitListingStatus !== 'INACTIVE' ? (
            <div>
              <Typography.Text>
                Listed Rent: {formatCurrency(property.unitListedRent)}
              </Typography.Text>
            </div>
          ) : null}
        </>
      ) : null}
    </div>
  );
};

export default PropertySummary;
