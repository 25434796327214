import { ProspectService } from '@/services/prospect';
import type { CSSProperties, FC } from 'react';
import AutoComplete from './autocomplete';

interface Props {
  onChange: (newValue: number | undefined) => void;
  value?: number;
  style: CSSProperties;
}

const ProspectsAutocomplete: FC<Props> = (props) => {
  return <AutoComplete onSearch={(q) => ProspectService.lookup({ q })} {...props} />;
};

export default ProspectsAutocomplete;
