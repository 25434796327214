import { EditOutlined } from '@ant-design/icons';
import { Button, Select, Tooltip } from 'antd';
import type { FormikProps } from 'formik';
import { Formik } from 'formik';
import type { FC } from 'react';
import { useRef, useState } from 'react';
import type { FloatingInlineEditProps } from '.';
import FloatingEditableAttribute from '../FloatingEditableAttribute';
import './FloatingInlineDropdownEdit.less';

export type Option = {
  label: string | number | boolean;
  value: string;
};

export interface FloatingInlineDropdownEditProps
  extends Omit<FloatingInlineEditProps, 'inputRender'> {
  options: Option[];
  textColor?: string;
  editTooltip?: string;
  hideEdit?: boolean;
}

type Values = {
  field: string;
};

const FloatingInlineDropdownEdit: FC<FloatingInlineDropdownEditProps> = ({
  options,
  initialValue,
  displayValue,
  onConfirm,
  textColor,
  editDisabled,
  editTooltip,
  hideEdit = false,
}) => {
  const [editing, setEditing] = useState(false);
  const baseRef = useRef<HTMLDivElement>(null);
  const formRef = useRef<FormikProps<Values>>(null);

  const submit = (values: Values) => {
    onConfirm(
      values.field,
      () => {
        setEditing(false);
      },
      () => {
        formRef.current?.setSubmitting(false);
      },
    );
  };

  return (
    <div ref={baseRef} className="floating-editing-container">
      {editing ? (
        baseRef.current && (
          <Formik<Values>
            innerRef={formRef}
            initialValues={{
              field: initialValue || '',
            }}
            onSubmit={submit}
          >
            {({ isSubmitting, handleSubmit, values, setFieldValue, isValid }) => (
              <FloatingEditableAttribute
                baseElement={baseRef.current!}
                loading={isSubmitting}
                onCancel={() => setEditing(false)}
                onConfirm={handleSubmit}
                disabled={!isValid}
              >
                <Select
                  disabled={isSubmitting}
                  options={options}
                  style={{ width: '100%' }}
                  value={values.field}
                  onChange={(newValue) => {
                    setFieldValue('field', newValue);
                  }}
                />
              </FloatingEditableAttribute>
            )}
          </Formik>
        )
      ) : (
        <div className="display-value">
          <span className="value" style={{ color: textColor }}>
            {displayValue}
          </span>

          {!hideEdit && (
            <Tooltip title={editTooltip}>
              <Button
                className="edit-button"
                icon={<EditOutlined />}
                type="link"
                size="small"
                onClick={() => setEditing(true)}
                disabled={editDisabled}
              />
            </Tooltip>
          )}
        </div>
      )}
    </div>
  );
};

export default FloatingInlineDropdownEdit;
