import type { Enum } from './base';

export enum ProcessType {
  MOVE_IN = 'MOVE_IN',
  MOVE_OUT = 'MOVE_OUT',
  TURN_REHAB = 'TURN_REHAB',
  LISTING_PROCESS = 'LISTING_PROCESS',
  UNIT_ONBOARDING_PRE_CLOSING = 'UNIT_ONBOARDING_PRE_CLOSING',
  UNIT_ONBOARDING_POST_CLOSING = 'UNIT_ONBOARDING_POST_CLOSING',
  LEASING = 'LEASING',
  DISPUTE = 'DISPUTE',
  UNIT_APPLICATION = 'UNIT_APPLICATION',
  RENEWAL = 'RENEWAL',
  DISPOSITION = 'DISPOSITION',
  DISPOSITION_OFFER = 'DISPOSITION_OFFER',
}

export interface ProcessTypeEnum extends Enum<ProcessType> {
  associatedProcesses: string[];
  hasResponsible: boolean;
  hasTasks: boolean;
}
