import { Card } from 'antd';
import classNames from 'classnames';
import type { CSSProperties, FC, ReactNode } from 'react';
import type { InfoBoxesSection } from '../InfoBox/InfoBoxSection';
import InfoBoxSection from '../InfoBox/InfoBoxSection';
import type { EditableAttributesProps } from './EditableAttributes';
import EditableAttributes from './EditableAttributes';
import type { EditablePageSaveFn, SavedEditableAttributes } from './EditableAttributes/types';

type SectionType = 'INFO_BOXES' | 'EDITABLE_ATTRIBUTES' | 'WRAPPER';

interface SectionWithChildren {
  title: string;
}

export type Section = (EditableAttributesProps | SectionWithChildren | InfoBoxesSection) & {
  type: SectionType;
  children?: ReactNode;
  hide?: boolean;
  extraTitle?: ReactNode;
  breakAfter?: boolean;
  cardExtra?: ReactNode;
  style?: CSSProperties;
};

interface Props {
  sections: Section[];
  onSave?: EditablePageSaveFn<SavedEditableAttributes<any>>;
  withSpacing?: boolean;
  fullWidth?: boolean;
  singleColumn?: boolean;
  columns?: number;
  style?: CSSProperties;
  ['data-testid']?: string;
}

const EditablePage: FC<Props> = ({
  withSpacing = false,
  sections = [],
  onSave,
  fullWidth,
  singleColumn,
  columns,
  style,
  ...rest
}) => {
  return (
    <div
      data-testid={rest['data-testid']}
      className={classNames('masonryCardsContainer', {
        withSpacing: withSpacing,
        masonryFullWidth: fullWidth,
      })}
      style={{
        ...style,
        columns: singleColumn ? 'unset' : columns || 3,
      }}
    >
      {sections.map((section) => {
        if (section.hide) {
          return null;
        }

        if (section.type === 'INFO_BOXES') {
          return (
            <InfoBoxSection
              key={`info-box-section-${section.title}`}
              section={section as InfoBoxesSection}
            />
          );
        }

        if (section.type === 'WRAPPER') {
          return (
            <div
              key={`wrapper-section-${section.title}`}
              className={section.breakAfter ? 'breakAfter' : ''}
              style={section.style}
            >
              <div className="grid-bluecards">
                <Card
                  title={
                    section.extraTitle ? (
                      <>
                        {section.title} {section.extraTitle}
                      </>
                    ) : (
                      section.title
                    )
                  }
                  bordered={false}
                  extra={section.cardExtra}
                >
                  {section.children}
                </Card>
              </div>
            </div>
          );
        }

        const editableSection = section as EditableAttributesProps;
        return (
          <EditableAttributes
            key={editableSection.title}
            onSave={editableSection.onSave || onSave}
            {...editableSection}
          />
        );
      })}
    </div>
  );
};

export default EditablePage;
