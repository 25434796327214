import type { New, PropifyEntity } from '@/domain/base';
import type { Property, PropertySearchResult } from '@/domain/property';
import type { Amenity } from './amenity';

export enum ListingStatus {
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
  COMING_SOON = 'COMING_SOON',
  HOLD = 'HOLD',
}

export interface Floorplan extends PropifyEntity {
  description: string;
}

export enum UnitStatus {
  DOWN = 'DOWN',
  OCCUPIED = 'OCCUPIED',
  VACANT = 'VACANT',
  UNKNOWN = 'UNKNOWN',
}

export interface UnitSearchParams {
  id?: number[];
  propertyId?: number;
  keyword?: string;
  listingStatus?: ListingStatus | ListingStatus[];
}

export interface UnitBulkUpdateBody {
  ids: number[];
  propifyShowing?: boolean;
  listingStatus?: ListingStatus[];
  marketRent?: number;
  marketRentDelta?: number;
  marketRentPercentage?: number;
  promotionIds?: number[];
  pricingCadenceDays?: number;
}

export interface Unit extends PropifyEntity {
  property?: Property;
  propertyId?: number;
  status: UnitStatus;
  statusUpdateTime?: string;
  description?: string;
  bedroomCount?: number;
  fullBathroomCount?: number;
  threeQuarterBathroomCount?: number;
  halfBathroomCount?: number;
  quarterBathroomCount?: number;
  squareFeet?: number;
  rentPrice?: number;
  unitNumber?: string;
  ready?: boolean;
  rented?: boolean;
  notice?: boolean;
  readyDate?: string;
  leasebackDate?: string;
  amenities?: Amenity[];
  marketRent?: number;
  listedRent?: number;
  listingStatus?: ListingStatus;
  foreignShowingLink?: string;
  foreignApplicationLink?: string;
  zillowCompLink?: string;
  floorplan?: Floorplan;
  estimatedRentGrowth?: number;
  proFormaRent?: number;
  rentlyId?: number;
  zillowEstimatedRent: number;
  smartHomeSystemId?: number;
  propifyShowing?: boolean;
  stories?: number;
  floor?: string;
  smartHomeSetupEnabled?: boolean;
  applicationEnabled?: boolean;
  commonDoorLockCode?: string;
  promotionIds: number[];
  monthlyHoaAmount?: string;
  pricingCadenceDays?: number;
  foreignDbCode?: string;
  foreignDbId?: number;
}

export interface CreateUnitDTO extends Omit<Unit, 'status'> {
  status?: UnitStatus;
}

export interface UnitUpdateRequest extends Omit<Unit, 'amenities'> {
  amenities?: New<Amenity>[];
}

export interface UnitPricingSearchResult extends Unit {
  property: Property;
  lastMarketRentUpdateTime?: string;
  previousMarketRent?: number;
  daysOnMarket: number;
  lastRentUpdatedBy?: number;
}

export interface UnitSearchResult extends Omit<Unit, 'property'> {
  property: PropertySearchResult;
}

export interface YardiMismatch extends PropifyEntity {
  type?: string;
  unitId: number;
  yardiValue?: string;
  propifyValue?: string;
}
