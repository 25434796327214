import type { Lookup, PropifyEntity } from './base';

export enum PromotionType {
  FIXED_AMOUNT = 'FIXED_AMOUNT',
  RENT_MULTIPLE = 'RENT_MULTIPLE',
}

export interface Promotion extends PropifyEntity {
  name: string;
  type: PromotionType;
  amount: number;
  adjustListedRent: boolean;
  display: boolean;
  text: string;
  featured: boolean;
}

export interface PromotionSearchResult extends Promotion {
  unitIds: number[];
  lastUpdateTime: string;
  lastUpdateLoginId: number;
}

export interface PromotionLookup extends Lookup {
  text: string;
  displayBeforeMarketingDescription: boolean;
}
