import type { Party, PartyType } from '@/domain/person';
import type {
  Ticket,
  TicketReason,
  TicketSearchResult,
  TicketStatus,
  TicketType,
} from '@/domain/ticket';
import { api } from '@/utils/configuration';
import { crudService, getAxiosFetcher, getEvents } from '@/utils/request';
import type { Operation } from 'fast-json-patch';

export type TicketSearchParams = {
  id?: number;
  keywords?: string;
  dueTime?: string;
  priority?: string;
  type?: TicketType;
  status?: TicketStatus[];
  claimUserId?: number;
  partyId?: number;
  subskillId?: number;
  ticketGroupId?: number;
  preferredUserId?: number | '';
  uncategorizedPreferredUser?: boolean;
  finishedTime?: string;
  createTime?: string;
  uncategorizedTicketGroup?: boolean;
  createLoginId?: number;
};

export interface NewContactFormType {
  partyType: PartyType;
  name?: string;
  organizationType?: string;
  firstName?: string;
  lastName?: string;
  phone?: string;
  email?: string;
}

export type UpdateContactForm = Omit<NewContactFormType, 'partyType'>;

export type TicketReasonSearchParams = {
  ticketGroupId?: number;
  followUp?: boolean;
};

interface TicketWrapUpBody {
  followUp: boolean;
  resolved: boolean;
  dueTime?: string;
  priority?: string;
  reason?: string;
  description?: string;
}

export interface TicketResolveBody {
  terminationReason: string;
  terminationDescription: string;
}

type TicketBulkUpdateParams = {
  ids: number[];
  priority?: string;
  ticketGroupId?: number | undefined;
  subskillId?: number | undefined;
  dueTime?: string | undefined;
  clearPreferredUser?: boolean | undefined;
  status?: TicketStatus;
};

export type TicketBulkCreateBody = {
  partyIds: number[];
  type: TicketType;
  dueTime: string;
  ticketGroupId?: number;
  subskillId?: number;
  reason?: string;
  reasonDescription?: string;
  preferredUserId?: number;
};

export const TicketService = {
  findById: getAxiosFetcher<Ticket, [number]>((id) => [`${api.TICKETS}/${id}`]),

  search: getAxiosFetcher<TicketSearchResult[], [TicketSearchParams?]>((params) => [
    api.TICKETS,
    { params },
  ]),

  create: (newTicket: Partial<Ticket>) => crudService.post<Ticket>(newTicket, api.TICKETS),

  update: (ticket: Partial<Ticket> & Required<Pick<Ticket, 'id'>>) =>
    crudService.put<Ticket>(ticket, `${api.TICKETS}/${ticket.id}`),

  patch: (ticketId: number, operations: Operation[]) =>
    crudService.patch<Ticket>(operations, `${api.TICKETS}/${ticketId}`),

  resolve: (ticketId: number, body: TicketResolveBody) =>
    crudService.put<void>(body, `${api.TICKETS}/${ticketId}/resolve`),

  claim: (ticketId: number) => crudService.post<Ticket>({}, `${api.TICKETS}/${ticketId}/claim`),

  cancelTicket: (ticketId: number) =>
    crudService.post<Ticket>({}, `${api.TICKETS}/${ticketId}/cancel`),

  cancelClaim: (ticketId: number) => crudService.delete<Ticket>(`${api.TICKETS}/${ticketId}/claim`),

  reset: (ticketId: number) =>
    crudService.post<Ticket>({}, `${api.TICKETS}/${ticketId}/claim/reset`),

  markAsSpam: (ticketId: number) => crudService.post<Ticket>({}, `${api.TICKETS}/${ticketId}/spam`),

  managed: getAxiosFetcher<Ticket[], [number]>((ticketId) => [
    `${api.TICKETS}/${ticketId}/managed`,
  ]),

  wrapUp: (id: number, data: TicketWrapUpBody) =>
    crudService.post<Ticket>(data, `${api.TICKETS}/${id}/wrap-up`),

  followUp: (ticketId: number, followUpTicket: Partial<Ticket>) =>
    crudService.post<Ticket>(followUpTicket, `${api.TICKETS}/${ticketId}/follow-up`),

  createContact: (ticketId: number, newContact: NewContactFormType) =>
    crudService.post<Party>(newContact, `${api.TICKETS}/${ticketId}/contact`),

  updateContact: (ticketId: number, contactId: number, contact: UpdateContactForm) =>
    crudService.post<Party>(contact, `${api.TICKETS}/${ticketId}/contact/${contactId}`),

  getReasons: getAxiosFetcher<TicketReason[], [TicketReasonSearchParams?]>((params) => [
    api.TICKET_REASONS,
    { params },
  ]),

  bulkUpdate: (params: TicketBulkUpdateParams) => crudService.put<void>(params, api.TICKETS),

  bulkCreate: (body: TicketBulkCreateBody) =>
    crudService.post<Ticket[]>(body, `${api.TICKETS}/bulk`),

  getEvents: (): EventSource => getEvents(`${api.TICKETS}/events`),
};
