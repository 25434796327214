import NoteAudit from '@/components/Notes/NoteAudit/NoteAudit';
import { useAppContext } from '@/contexts/AppContext';
import type { Note } from '@/domain/note';
import { replaceMentionedUserIdsByNames } from '@/pages/notes/utils';
import { FileOutlined } from '@ant-design/icons';
import type { FC } from 'react';
import { useMemo } from 'react';
import '../../styles.less';

type Props = {
  note: Note;
  onNoteClick: boolean;
  setOnNoteClick: (b: boolean) => void;
  setSelectedNote?: (n: Note) => void;
};

const NotesDescription: FC<Props> = ({ note, setSelectedNote, setOnNoteClick, onNoteClick }) => {
  const { users } = useAppContext();

  const usersForMentions = useMemo(
    () =>
      users.map((user) => ({
        id: user.id,
        value: user.displayName,
      })),
    [users],
  );

  return (
    <div
      className="notes-description"
      onClick={() => {
        setSelectedNote?.(note);
        setOnNoteClick(!onNoteClick);
      }}
    >
      <div className="icon">
        <FileOutlined />
      </div>
      <div className="section">
        <div className="description">
          {replaceMentionedUserIdsByNames(note.note || '', usersForMentions)}
        </div>
        <NoteAudit note={note} />
      </div>
    </div>
  );
};

export default NotesDescription;
