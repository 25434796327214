import type { New } from '@/domain/base';
import type {
  BulkUpdateTaskRequest,
  CompleteTaskRequest,
  MoveTaskRequest,
  Task,
  TaskSearchResult,
  TasksSearchParams,
} from '@/domain/tasks';
import { api } from '@/utils/configuration';
import { crudService, getAxiosFetcher, getEvents } from '@/utils/request';

export const TasksService = {
  search: getAxiosFetcher<TaskSearchResult[], [TasksSearchParams?]>((params = {}) => [
    api.TASKS,
    { params },
  ]),

  update: (task: Task) => crudService.put<Task>(task, `${api.TASKS}/${task.id}`),

  create: (task: New<Task>) => crudService.post<Task>(task, api.TASKS),

  delete: (taskId: number) => crudService.delete<void>(`${api.TASKS}/${taskId}`),

  moveTask: (body: MoveTaskRequest) => crudService.put<void>(body, `${api.TASKS}/rank`),

  complete: (taskId: number, body: CompleteTaskRequest) =>
    crudService.post<void>(body, `${api.TASKS}/${taskId}/complete`),

  bulkUpdate: (body: BulkUpdateTaskRequest) => crudService.put<void>(body, api.TASKS),

  getEvents: (): EventSource => getEvents(`${api.TASKS}/events`),
};
