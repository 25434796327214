import { PropertyGroup } from '@/domain/property-groups';
import type { ColumnComponentType, ColumnType } from '@/notmagic/components/EntityTable/types';
import { useEntityTableContext } from '@/notmagic/Context';
import { useImmutableEndpoint } from '@/notmagic/hooks/request';
import { Popover } from 'antd';
import { useMemo } from 'react';
import { Link } from 'react-router-dom';

const Component: ColumnComponentType = ({ value }) => {
  const { entityTypes } = useEntityTableContext();
  const entityType = entityTypes.PROPERTY_GROUP;
  const { data: allPropertyGroups } = useImmutableEndpoint<PropertyGroup[]>(entityType.endpoint);

  const propertyGroups = useMemo(
    () =>
      value && allPropertyGroups
        ? value.map((propertyGroupId: number) =>
            allPropertyGroups?.find((propertyGroup) => propertyGroup.id === propertyGroupId),
          )
        : undefined,
    [allPropertyGroups, value],
  );

  return propertyGroups && value ? (
    <Popover
      placement="bottomLeft"
      trigger="hover"
      content={
        <div style={{ margin: '10px' }}>
          {propertyGroups.map((propertyGroup: PropertyGroup) => (
            <p>
              <Link to={`/properties/property-groups/${propertyGroup.id}`} target="_blank">
                {propertyGroup.name}
              </Link>
            </p>
          ))}
        </div>
      }
    >
      {propertyGroups.map((propertyGroup: PropertyGroup, index: number) => (
        <>
          {index ? ', ' : ''}
          <Link to={`/properties/property-groups/${propertyGroup.id}`} target="_blank">
            {propertyGroup.name}
          </Link>
        </>
      ))}
    </Popover>
  ) : null;
};

const Definition: ColumnType = {
  Component,
  hideSort: true,
};

export default Definition;
