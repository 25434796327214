import type {
  ColumnComponentType,
  ColumnComponentTypeProps,
} from '@/notmagic/components/EntityTable/types';
import moment from 'moment/moment';

type Props = ColumnComponentTypeProps<string> & {};

const Component: ColumnComponentType<Props> = ({ value, textIfNull }) => {
  return <>{value ? moment(value).fromNow() : textIfNull}</>;
};

export default {
  Component,
  sort: (a: string, b: string) => (a === b ? 0 : a < b ? -1 : 1),
  getValueForExport: (value: string) => (value ? moment(value).fromNow() : ''),
};
