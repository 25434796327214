import type { EnumerationName } from '@/domain/base';
import useEnumeration from '@/hooks/useEnumeration';
import { Spin } from 'antd';
import type { FC } from 'react';
import { useMemo } from 'react';

interface Props {
  enumName: EnumerationName | string;
  value?: string;
}

const EnumValue: FC<Props> = ({ enumName, value }) => {
  const { data: enumeration } = useEnumeration(enumName);

  const result = useMemo(
    () =>
      enumeration.reduce((hash, next) => {
        // eslint-disable-next-line no-param-reassign
        hash[next.value] = next.displayName;
        return hash;
      }, {}),
    [enumeration],
  );

  if (!enumeration.length) {
    return <Spin size="small" />;
  }

  if (!value) {
    return null;
  }

  return result[value] ? result[value] : value;
};

export default EnumValue;
