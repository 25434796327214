import type { PropifyEntity } from '@/domain/base';
import type { Bid } from '@/domain/bid';
import type { File } from '@/domain/file';
import type { TransactionStatus } from '@/domain/property';
import type { PropertyManagerService } from '@/domain/property-manger-services';
import type { Service, ServiceCategory } from '@/domain/service';
import type { Unit, UnitStatus } from '@/domain/unit';
import type { Vendor } from '@/domain/vendor';
import type { WorkOrderParticipantType } from '@/domain/work-order-participants';
import type { Address } from './address';
import type { JobType } from './job';
import type { ScopeItemSearchResult } from './scope-items';
import type { WorkOrderMessage } from './work-order-message';

export interface WorkOrderImage extends File {
  createPartyRoleType: WorkOrderParticipantType;
  type: WorkOrderImageType;
  createdBy: string;
  fileId: number;
}

export enum ParentWorkOrderRelationshipType {
  RECALL = 'RECALL',
  FOLLOW_UP = 'FOLLOW_UP',
}

export interface WorkOrder extends PropifyEntity {
  unit?: Unit;
  vendor?: Vendor;
  timeClosed?: string;
  category?: string;
  subcategoryId?: number;
  status?: WorkOrderStatus;
  priority?: string;
  description?: string;
  propertyId: number;
  serviceId?: number;
  address?: Address;
  fixedAssetId?: number;
  residentDeferred?: boolean;
  residentResponsibility?: boolean;
  nte?: number;
  displayNte?: number;
  bidRequired?: boolean;
  acceptanceDue?: string;
  scheduledDue?: string;
  resolvedDue?: string;
  acceptedTime?: string;
  scheduledTime?: string;
  resolvedTime?: string;
  accessInformation?: string;
  completionNote?: string;
  jobId?: number;
  jobType: JobType;
  propertyAddress?: Address;
  unitId?: number;
  vendorId?: number;
  images: WorkOrderImage[];
  files: WorkOrderFile[];
  bids: Bid[];
  unitStatus: UnitStatus;
  transactionStatus: TransactionStatus;
  lastIncomingMessage?: WorkOrderMessage;
  residentBillback?: boolean;
  preferredUserId?: number;
  userGroupId?: number;
  unitHasOtherJobs?: boolean;
  assignVendorDue: string;
  propertyTimezone?: string;
  approvedCost?: number;
  deferredUntil?: string;
  partyResponsibleForDeferral?: WorkOrderParticipantType;
  recallWorkOrderId?: number;
  recalled: boolean;
  scopeItems?: WorkOrderScopeItemSearchResult[];
  parentWorkOrderId?: number;
  parentWorkOrderRelationshipType?: ParentWorkOrderRelationshipType;
  cancelledReason: string;
}

export interface WorkOrderSearchResult extends WorkOrder {
  service?: string;
  serviceCategory?: string;
  serviceCategoryId?: number;
  assignedVendor?: string;
  propertyVentureId?: number;
  unreadMessageCount: number;
  unreadPropertyManagerMessageByResidentCount: number;
  unreadPropertyManagerMessageByVendorCount: number;
  unreadResidentMessageByPropertyManagerCount: number;
  unreadResidentMessageByVendorCount: number;
  unreadVendorMessageByPropertyManagerCount: number;
  unreadVendorMessageByResidentCount: number;
  oldestUnreadByVendor?: string;
  oldestUnreadByResident?: string;
  oldestUnreadByPropertyManager?: string;
  lastPropertyManagerActivity?: string;
  lastVendorActivity?: string;
  lastVendorAccessTime?: string;
  relatedWorkOrderCount: number;
  assignedToAClosedTurnSlashRehab: boolean;
  notAssociatedWithATurnSlashRehab: boolean;
  leaseStartDate?: string;
  pendingBidsCount: number;
  scopeReview?: boolean;
  imagesCount: number;
}

export interface WorkOrderFile extends File {
  createPartyRoleType: WorkOrderParticipantType;
}

export interface WorkOrderPriority extends PropifyEntity {
  acceptanceDueInHours: number;
  resolvedDueInHours: number;
  scheduleDueInHours: number;
  priority: string;
}

export interface WorkOrdersCount {
  userGroupId: number;
  openWorkOrders: number;
}

export enum WorkOrderPriorityEnum {
  ROUTINE = 'ROUTINE',
  URGENT = 'URGENT',
  EMERGENCY = 'EMERGENCY',
}

export enum WorkOrderImageType {
  BEFORE = 'BEFORE',
  AFTER = 'AFTER',
}

export interface WorkOrderImageRequest {
  type: WorkOrderImageType;
  description?: string;
}

export interface WorkOrderFileRequest {
  description?: string;
}

export enum WorkOrderStatus {
  NEW = 'NEW',
  PENDING_APPROVAL = 'PENDING_APPROVAL',
  ASSIGN_VENDOR = 'ASSIGN_VENDOR',
  PENDING_ACCEPTANCE = 'PENDING_ACCEPTANCE',
  PENDING_SCHEDULE = 'PENDING_SCHEDULE',
  SCHEDULED = 'SCHEDULED',
  CANCELLED = 'CANCELLED',
  WORK_COMPLETE = 'WORK_COMPLETE',
  UNABLE_TO_COMPLETE = 'UNABLE_TO_COMPLETE',
  FINALIZED = 'FINALIZED',
}

export interface WorkOrderSearchParams {
  id?: number;
  status?: WorkOrderStatus | WorkOrderStatus[];
  vendorId?: number[];
  ventureId?: number | number[];
  priority?: string;
  createTime?: string;
  scheduleDue?: string;
  categoryId?: number;
  serviceId?: number;
  addressId?: number;
  propertyId?: number;
  unitId?: number;
  fixedAssetId?: number;
  jobId?: number;
  nte?: number[];
  keywords?: string;
  preferredUserId?: number | number[];
  residentResponsibility?: boolean;
  residentDeferred?: boolean;
  bidRequired?: boolean;
  lastIncomingMessageCreateTime?: string;
  lastIncomingMessageRead?: boolean;
  userGroupId?: number[];
  recurring?: boolean;
  resolvedDue?: string;
  resolvedTime?: string;
  assignVendorDue?: string;
  state?: string | string[];
  hasPendingBids?: boolean;
  hasPendingBidRevisions?: boolean;
  hasSubmittedBids?: boolean;
  unitStatus?: UnitStatus;
  transactionStatus?: TransactionStatus;
  jobType?: JobType;
}

export type WorkOrderPriorityRow = {
  service: Service;
  category: ServiceCategory;
  propertyManagerService?: PropertyManagerService;
};

export interface WorkOrderActivity extends PropifyEntity {
  workOrderId: number;
  type: string;
  file?: File;
  createPartyRoleType: WorkOrderParticipantType;
  comments: string;
  createdBy: string;
}

export interface WorkOrderActivityGrouped extends WorkOrderActivity {
  files: File[];
}

export interface WorkOrderVendorRequest {
  vendorId: number;
}

export interface WorkOrderActivityCommentsRequest {
  comments: string;
}

export interface ReOpenWorkOrderBody {
  comment: string;
}

export interface WorkOrderBulkUpdateRequest {
  ids: number[];
  jobId?: number;
  userGroupId?: number;
  preferredUserId?: number;
}

export interface WorkOrderImageBulkUpdateRequest {
  ids: number[];
  type: WorkOrderImageType;
}

export interface WorkOrderScheduleProposal extends PropifyEntity {
  fromTime: string;
  toTime: string;
  proposedBy: string;
  status: string;
  workOrderId?: number;
}

export enum WorkOrderScopeItemStatus {
  INCOMPLETE = 'INCOMPLETE',
  COMPLETED = 'COMPLETED',
  REVIEW = 'REVIEW',
  REVISIT_NEEDED = 'REVISIT_NEEDED',
  VENDOR_UNABLE_TO_COMPLETE = 'VENDOR_UNABLE_TO_COMPLETE',
  UNNECESSARY = 'UNNECESSARY',
  NEW_WORK_ORDER_CREATED = 'NEW_WORK_ORDER_CREATED',
}

export interface WorkOrderScopeItem extends PropifyEntity {
  workOrderId: number;
  scopeItemId: number;
  status: WorkOrderScopeItemStatus;
}

export interface WorkOrderScopeItemSearchResult extends WorkOrderScopeItem {
  scopeItem: ScopeItemSearchResult;
  originWorkOrderId: number;
  currentWorkOrderId: number;
}

export interface WorkOrderScopeItemImageRequest {
  fileIds: number[];
}

export type WorkOrderCancelledRequest = {
  reason: string;
  note: string;
};
