import DocIcon from '@/assets/icons/docx.svg?react';
import PdfIcon from '@/assets/icons/pdf.svg?react';
import UnknownIcon from '@/assets/icons/unknown.svg?react';
import type { File } from '@/domain/file';
import { downloadFile } from '@/utils/files';
import { CloudDownloadOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import type { FC } from 'react';
import { useState } from 'react';
import classes from './styles.module.less';

interface Props {
  file: File;
}

const FilePreview: FC<Props> = ({ file }) => {
  const [downloading, setDownloading] = useState(false);

  const handleDownload = () => {
    setDownloading(true);
    downloadFile(file).finally(() => setDownloading(false));
  };

  const getIcon = () => {
    const splittedName = file.fileName.split('.');
    const extension = splittedName[splittedName.length - 1];

    switch (extension) {
      case 'doc':
      case 'docx':
        return <DocIcon />;
      case 'pdf':
        return <PdfIcon />;
      default:
        return <UnknownIcon />;
    }
  };

  return (
    <div className={classes.filePreviewContainer}>
      <div className={classes.icon}>{getIcon()}</div>
      <div className={classes.label}>{file.fileName}</div>
      <div className={classes.actions}>
        <Button
          onClick={handleDownload}
          loading={downloading}
          type="link"
          icon={<CloudDownloadOutlined />}
        />
      </div>
    </div>
  );
};

export default FilePreview;
