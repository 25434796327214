import type { Column } from '@/@propify-components';
import { booleanColumn, dateColumn, Table } from '@/@propify-components';
import { strikeThroughIfCancelled } from '@/components/Renewals/CarryoverResidents/CarryoverResidents';
import { ApplicationType } from '@/domain/applications';
import type { RenewalApplicationSearchResult } from '@/domain/renewal';
import { settings } from '@/services/settings';
import { getFullName } from '@/utils/utils';
import { StarFilled } from '@ant-design/icons';
import type { FC } from 'react';
import { Link } from 'react-router-dom';

const tableSettings = settings('renewals-residents-table');

interface Props {
  residents: RenewalApplicationSearchResult[];
}

const RenewalsResidentsTable: FC<Props> = ({ residents }) => {
  const columns: Column<RenewalApplicationSearchResult>[] = [
    {
      key: 'name',
      title: 'Resident Name',
      render: (resident) =>
        resident.person?.id && (
          <Link to={`/person/${resident.person?.id}`}>
            {getFullName(resident.person)}{' '}
            {resident.type === ApplicationType.PRIMARY ? <StarFilled /> : null}
          </Link>
        ),
      sortFn: (r1, r2) => getFullName(r1.person).localeCompare(getFullName(r2.person)),
      width: 200,
      className: strikeThroughIfCancelled,
    },
    {
      ...dateColumn({ title: 'Resident Since', key: 'residentSince' }, { defaultValue: 'null' }),
      width: 150,
      className: strikeThroughIfCancelled,
    },
    booleanColumn({
      key: 'requiresScreening',
      title: 'Requires Screening?',
      width: 170,
      className: strikeThroughIfCancelled,
    }),
  ];

  return (
    <Table
      style={{ marginTop: '15px' }}
      columns={columns}
      data={residents}
      rowKeyExtractor={(row) => row?.id}
      settings={tableSettings}
    />
  );
};

export default RenewalsResidentsTable;
