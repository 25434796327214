import type { DropboxSignConfiguration } from '@/domain/document-templates';
import { Button, Divider, message, Modal, Typography } from 'antd';
import HelloSign from 'hellosign-embedded';
import type { FC } from 'react';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import type { ModalFormProps } from '../Button/formButton';
import styles from './styles.module.less';

const { Title, Text, Paragraph } = Typography;

interface Props extends ModalFormProps {
  onBack: () => void;
  config: DropboxSignConfiguration;
  documentTemplateURL: string;
  documentTemplateTitle: string;
  refreshData: () => void;
}

const AddDocumentTemplateModalStep2: FC<Props> = ({
  onCancel,
  onBack,
  config,
  documentTemplateURL,
  documentTemplateTitle,
  refreshData,
  onSuccess,
}) => {
  const [client, setClient] = useState<HelloSign>();

  useEffect(() => {
    if (!config.clientId) {
      return;
    }
    setClient(
      new HelloSign({
        clientId: config.clientId,
      }),
    );
  }, [config.clientId]);

  useEffect(() => {
    if (client) {
      client.open(documentTemplateURL, {
        container: document.getElementById('hellosign-document-container')!,
        testMode: config.testMode,
        skipDomainVerification: config.skipDomainVerification,
        locale: HelloSign.locales[config.locale],
      });

      client.on('finish', () => {
        message.success('Document Template saved successfully');
        refreshData();
        onSuccess?.();
      });
    }
  }, [client]);

  return (
    <Modal
      visible
      onCancel={onCancel}
      title="Add Document Template: Step 2 of 2"
      maskClosable={false}
      width={980}
      bodyStyle={{ height: 1000 }}
      footer={
        <div className={styles.modalFooter}>
          <Button
            key="back"
            onClick={() => {
              client?.close();
              onBack();
            }}
          >
            Back
          </Button>
          <Button key="cancel" onClick={onCancel}>
            Cancel
          </Button>
        </div>
      }
    >
      <Paragraph>
        <Title level={5}>Upload Lease Template Document</Title>
        <Text>
          Drag signature fields and variable fields onto the document template to construct a
          Document Template. Values in the &lsquo;Auto-Fill Fields&lsquo; list will be filled in
          dynamically with the relevant information to the Unit for which a document is being
          generated. For more information on the significance of each value shown in this list,{' '}
          <Link to="/document-templates/autofill-dictionary">click here.</Link>
        </Text>
      </Paragraph>

      <Divider />

      <Title level={5}>{documentTemplateTitle}</Title>
      <div id="hellosign-document-container" style={{ height: '90%' }} />
    </Modal>
  );
};

export default AddDocumentTemplateModalStep2;
