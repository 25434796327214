import { Button, Input } from 'antd';
import type { FC } from 'react';
import { useState } from 'react';
import './styles.less';

interface Props {
  value: string;
  onChange: (value: string) => void;
}

const ActiveFilterText: FC<Props> = ({ value, onChange }) => {
  const [text, setText] = useState(value);

  const handleApply = () => {
    if (text) {
      onChange(text);
    }
  };

  return (
    <div className="container-filter-text">
      <Input value={text} onChange={(ev) => setText(ev.target.value)} />
      <Button size="large" className="button" onClick={() => handleApply()}>
        Apply
      </Button>
    </div>
  );
};

export default ActiveFilterText;
