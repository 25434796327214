import type { DocumentTemplateSearchParams } from '@/domain/document-templates';
import { DocumentTemplatesService } from '@/services/document-templates';
import { useFetch } from '@/utils/request';

export const useDocumentTemplates = (params?: DocumentTemplateSearchParams) => {
  return useFetch(
    {
      fetcher: DocumentTemplatesService.search,
      errorMessage: 'There was an error fetching the document templates',
    },
    params,
  );
};
