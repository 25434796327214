import { EntityType } from '@/domain/entity-type';
import { NoteType } from '@/domain/note';
import type { WorkOrder } from '@/domain/work-order';
import useEnumeration from '@/hooks/useEnumeration';
import { NoteService } from '@/services/notes';
import { WorkOrdersService } from '@/services/work-orders';
import { SHORT_DATE_FORMAT } from '@/utils/time';
import type { FormikSubmitFn } from '@/utils/types';
import { handleError } from '@/utils/utils';
import { Button, Modal } from 'antd';
import type { Operation } from 'fast-json-patch';
import { Formik } from 'formik';
import { DatePicker, Form, Input, Select } from 'formik-antd';
import moment from 'moment';
import type { FC } from 'react';
import { useState } from 'react';
import * as Yup from 'yup';

interface Props {
  workOrder: Pick<
    WorkOrder,
    'id' | 'version' | 'status' | 'deferredUntil' | 'partyResponsibleForDeferral'
  >;
  onCancel: () => void;
  onSuccess: (wo: WorkOrder) => void;
}

export interface DeferWorkOrderValues {
  deferredUntil: string | undefined;
  partyResponsibleForDeferral: string | undefined;
  note: string;
}

const fieldValidation = Yup.string()
  .typeError('This field is required')
  .required('This field is required');

const validationSchema = Yup.object().shape({
  deferredUntil: fieldValidation.test(
    'isFuture',
    'This date should be in the future',
    (value: string) => {
      if (!value) {
        return true;
      }

      return moment(value).isAfter(moment());
    },
  ),
  partyResponsibleForDeferral: fieldValidation,
  note: fieldValidation,
});

const DeferWorkOrderModal: FC<Props> = ({ onCancel, workOrder, onSuccess }) => {
  const { options: responsiblePartyOptions } = useEnumeration('work-order-participant-type');
  const [isEndingDeferral, setIsEndingDeferral] = useState(false);
  const editMode = !!workOrder.deferredUntil;

  const handleDefer = async (
    operations: Operation[],
    note: string,
    stopLoadingCallback?: () => void,
  ) => {
    let updatedWorkOrder: WorkOrder;

    try {
      updatedWorkOrder = await WorkOrdersService.patch(workOrder.id, operations, workOrder.version);
    } catch (error) {
      handleError(error, { toastFallbackMessage: 'There was an error updating the work order' });
      stopLoadingCallback?.();
      return;
    }

    try {
      await NoteService.create({
        type: NoteType.GENERAL,
        note,
        alertRole: '',
        entities: {
          [EntityType.WORK_ORDER]: [workOrder.id],
        },
      });
    } catch (error) {
      handleError(error, {
        toastFallbackMessage: 'There was an error creating the note for the work order',
      });
    } finally {
      stopLoadingCallback?.();
    }

    onSuccess(updatedWorkOrder);
  };

  const onSubmit: FormikSubmitFn<DeferWorkOrderValues> = (values, actions) => {
    const operations: Operation[] = [
      {
        op: 'replace',
        path: '/deferredUntil',
        value: values.deferredUntil,
      },
      {
        op: 'replace',
        path: '/partyResponsibleForDeferral',
        value: values.partyResponsibleForDeferral,
      },
    ];

    handleDefer(operations, values.note, () => actions.setSubmitting(false));
  };

  const endDeferral = (note: string) => {
    setIsEndingDeferral(true);

    const operations: Operation[] = [
      {
        op: 'remove',
        path: '/deferredUntil',
      },
      {
        op: 'remove',
        path: '/partyResponsibleForDeferral',
      },
    ];

    handleDefer(operations, note, () => setIsEndingDeferral(false));
  };

  return (
    <Formik<DeferWorkOrderValues>
      onSubmit={onSubmit}
      validationSchema={validationSchema}
      initialValues={{
        deferredUntil: workOrder.deferredUntil || '',
        partyResponsibleForDeferral: workOrder.partyResponsibleForDeferral || '',
        note: '',
      }}
    >
      {({ submitForm, handleSubmit, isValid, isSubmitting, values }) => (
        <Modal
          visible
          title="Defer Work Order"
          width={800}
          onCancel={onCancel}
          footer={[
            <Button key="cancel" onClick={onCancel}>
              Cancel
            </Button>,
            editMode && (
              <Button
                className="green"
                onClick={() => endDeferral(values.note)}
                loading={isEndingDeferral}
                disabled={!values.note}
              >
                End Deferral
              </Button>
            ),
            <Button
              key="submit"
              type="primary"
              onClick={submitForm}
              disabled={!isValid || isEndingDeferral}
              loading={isSubmitting}
            >
              {editMode ? 'Update' : 'Defer Work Order'}
            </Button>,
          ]}
        >
          <Form labelCol={{ span: 24 }} onSubmitCapture={handleSubmit}>
            <Form.Item name="deferredUntil" label="Deferred Until">
              <DatePicker
                format={SHORT_DATE_FORMAT}
                name="deferredUntil"
                style={{ width: '100%' }}
              />
            </Form.Item>
            <Form.Item name="partyResponsibleForDeferral" label="Responsible Party">
              <Select name="partyResponsibleForDeferral" options={responsiblePartyOptions} />
            </Form.Item>
            <Form.Item name="note" label="Notes">
              <Input.TextArea rows={5} name="note" />
            </Form.Item>
          </Form>
        </Modal>
      )}
    </Formik>
  );
};

export default DeferWorkOrderModal;
