import type { Property } from '@/domain/property';
import type { PropertyGroup } from '@/domain/property-groups';
import type { Effect, Model, Reducer } from '@/models/connect';
import { PropertyGroupService } from '@/services/property-groups';
import { handleError } from '@/utils/utils';
import { message } from 'antd';

type State = {
  propertyGroups: PropertyGroup[];
  editing: boolean;
  selectedPropertyGroup?: PropertyGroup;
  properties: Property[];
};

type Effects = {
  fetchPropertyGroups: Effect;
  selectPropertyGroup: Effect;
  rename: Effect;
};

type Reducers = {
  reset: Reducer<State>;
  setPropertyGroups: Reducer<State>;
  setEditing: Reducer<State>;
  setSelectedPropertyGroup: Reducer<State>;
  setProperties: Reducer<State>;
};

const initialState: State = {
  propertyGroups: [],
  editing: false,
  selectedPropertyGroup: undefined,
  properties: [],
};

const model: Model<State, Effects, Reducers> = {
  namespace: 'property-groups',

  state: { ...initialState },

  effects: {
    *fetchPropertyGroups({ payload }, { call, put }) {
      const response = yield call(PropertyGroupService.search, payload);

      yield put({
        type: 'setPropertyGroups',
        payload: response,
      });
    },
    *selectPropertyGroup({ payload: id }, { call, put }) {
      if (id) {
        const properties = yield call(PropertyGroupService.searchProperties, id);

        yield put({
          type: 'setProperties',
          payload: properties,
        });
      }

      yield put({
        type: 'setSelectedPropertyGroup',
        payload: id,
      });
    },
    *rename({ payload }, { call, put }) {
      try {
        yield call(PropertyGroupService.update, payload);

        message.success('Group renamed successfully.');

        yield put({
          type: 'fetchPropertyGroups',
          payload: {},
        });
      } catch (error) {
        handleError(error, { toastFallbackMessage: 'Group rename failed.' });
      }
    },
  },

  reducers: {
    reset() {
      return { ...initialState };
    },
    setPropertyGroups(state, action) {
      return {
        ...state,
        propertyGroups: action.payload,
      };
    },
    setEditing(state, action) {
      return {
        ...state,
        editing: action.payload,
      };
    },
    setSelectedPropertyGroup(state, action) {
      return {
        ...state,
        selectedPropertyGroup: state.propertyGroups.find(
          (propertyGroup) => propertyGroup.id === action.payload,
        ),
      };
    },
    setProperties(state, action) {
      return {
        ...state,
        properties: action.payload,
      };
    },
  },
};

export default model;
