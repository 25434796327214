import SectionSplashScreen from '@/components/PageLoading/SplashSection';
import RawHtml from '@/components/RawHtml';
import { LeadService } from '@/services/lead';
import { useFetch } from '@/utils/request';
import { SHORT_DATE_TIME_FORMAT } from '@/utils/time';
import { Modal } from 'antd';
import moment from 'moment';
import type { FC } from 'react';
import './LeadsTable.less';

interface Props {
  leadIds: number[];
  onClose: () => void;
}

const MessagesModal: FC<Props> = ({ leadIds, onClose }) => {
  const { data: leads = [], isValidating: loadingLeads } = useFetch(
    {
      fetcher: LeadService.search,
      errorMessage: 'There was an error fetching the leads',
    },
    {
      id: leadIds,
    },
  );

  return (
    <Modal
      title="All lead messages"
      visible
      onOk={onClose}
      onCancel={onClose}
      cancelButtonProps={{ hidden: true }}
    >
      {loadingLeads ? (
        <SectionSplashScreen />
      ) : (
        leads
          .filter((l) => l.message?.trim())
          .map((lead) => (
            <div key={lead.id} className="leadMessages">
              <div>{moment(lead.createTime).format(SHORT_DATE_TIME_FORMAT)}:</div>
              <div className="singleMessageContent">
                <RawHtml html={lead.message || ''} />
              </div>
            </div>
          ))
      )}
    </Modal>
  );
};

export default MessagesModal;
