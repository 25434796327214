import Map from '@/components/map';
import type { Point } from '@/utils/map';
import Modal from 'antd/lib/modal';
import './styles.nomodule.less';

interface MapModalProps {
  visible: boolean;
  onCancel: () => void;
  points: Point[];
}
const MapModal = ({ visible, onCancel, points }: MapModalProps) => {
  return (
    <Modal
      visible={visible}
      footer={null}
      onCancel={onCancel}
      wrapClassName="mapModal"
      width="80vw"
    >
      <div className="mapModalContent">
        <Map
          points={points}
          options={{
            zoom: 50,
          }}
        />
      </div>
    </Modal>
  );
};

export default MapModal;
