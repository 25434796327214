import { NextArrow, PrevArrow } from '@/components/Carousel/arrows';
import type { Theme, WithStyles } from '@material-ui/core';
import { createStyles } from '@material-ui/core';
import type { FC, ReactNode } from 'react';
import type { Settings } from 'react-slick';
import Slider from 'react-slick';

export type CarouselOption = {
  src?: string;
  label?: string;
  caption?: string;
  overlay?: ReactNode;
};

export const carouselStyles = (theme: Theme) =>
  createStyles({
    caption: {
      margin: '10px 0',
      color: 'white',
      textAlign: 'center',
    },
    imageContainer: {
      position: 'relative',
      margin: '0 auto',
      display: 'table',
    },
    modalImage: {
      maxHeight: 'calc(85vh - 150px)',
      maxWidth: '100%',
      margin: 'auto',
      [theme.breakpoints.down('md')]: {
        maxHeight: 'calc(100vh - 75px)',
      },
    },
    overlay: {
      position: 'absolute',
      top: 0,
      padding: '0.5em 1em',
      fontWeight: 'bold',
      color: '#fff',
      background: 'rgba(0, 0, 0, 0.75)',
      borderRadius: '0  0 4px 0',
    },
    centerSpin: {
      textAlign: 'center',
      padding: '30px 50px',
      margin: '20px 0',
    },
  });

type Props = Settings &
  WithStyles<typeof carouselStyles> & {
    options?: CarouselOption[];
    height?: string | number;
  };

const DefaultCarousel: FC<Props> = ({ options = [], height, classes, ...props }) => (
  <Slider
    infinite
    dots={false}
    useCSS={false}
    // cspell:ignore ondemand
    lazyLoad="ondemand"
    speed={0}
    slidesToShow={1}
    slidesToScroll={1}
    nextArrow={<NextArrow />}
    prevArrow={<PrevArrow />}
    {...props}
  >
    {options.map((option) => (
      <div key={option.src}>
        <div style={{ position: 'relative' }}>
          {option.overlay ? <span className={classes.overlay}>{option.overlay}</span> : null}
          <div
            style={{
              width: '100%',
              height: height ?? '256px',
              background: `url("${option.src}")`,
              backgroundPosition: 'center',
              backgroundSize: 'cover',
            }}
          />
        </div>
      </div>
    ))}
  </Slider>
);

export default DefaultCarousel;
