import type {
  ColumnComponentType,
  ColumnComponentTypeProps,
  ColumnType,
} from '@/notmagic/components/EntityTable/types';
import { UnitApplicationsService } from '@/services/unit-applications';
import { handleError } from '@/utils/utils';
import { Button, Modal } from 'antd';

type RowType = {
  id: number;
  version: number;
};

const Component: ColumnComponentType<ColumnComponentTypeProps<RowType>> = ({
  value,
  onValidateEntity,
}) => {
  // Must set `"value": {props in RowType}` in metadata
  if (!value?.id) {
    return null;
  }

  const handleClick = () => {
    const performJob = async () => {
      try {
        await UnitApplicationsService.approve(value.id, value.version);
        onValidateEntity();
      } catch (error) {
        handleError(error, {
          toastFallbackMessage: 'There was an error approving the unit application',
        });
      }
    };

    return Modal.confirm({
      content: (
        <h3>
          Are you sure you wish to promote this Held application to the Leasing Process? This action
          will fail if another application is already in the Leasing Process.
        </h3>
      ),
      okText: 'Continue',
      onOk: () => {
        performJob();
      },
    });
  };

  return <Button onClick={handleClick}>Approve</Button>;
};

const Definition: ColumnType = {
  Component,
  getValueForExport: () => '',
};

export default Definition;
