import { Tabs } from 'antd';
import type { TabsProps } from 'antd/lib/tabs';
import type { FC } from 'react';
import { Children, useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router';

interface HashTabProps extends TabsProps {
  defaultTab?: string;
}

const SEPARATOR = ',';
const removeDuplicates = (array: string[]) => {
  return [...new Set(array).values()];
};

const HashTabs: FC<HashTabProps> = (props) => {
  const [activeKey, setActiveKey] = useState<string>(props.defaultTab || '');

  const { defaultTab, ...nativeProps } = props;
  const location = useLocation();
  const navigate = useNavigate();

  // NOTE: the types "any" are used because there are some issues with React types
  // For example: "child?.key" throws an error because of the type of "child" (React.ReactNode)
  const getKeys = (): string[] => {
    const isTabPane = (element: any) => {
      return element && element.type === Tabs.TabPane;
    };

    const extractKeys = (children: any[]): string[] => {
      return Children.map<string, any>(children || [], (child) => {
        if (isTabPane(child) && typeof child.key === 'string') {
          return child.key;
        }

        return extractKeys(child?.props?.children);
      });
    };

    return extractKeys(props.children as any[]);
  };

  const getTabsInHash = () => {
    const hash = location.hash?.replace('#', '') || '';
    return (hash || null)?.split(SEPARATOR) || [];
  };

  useEffect(() => {
    const tabsInHash = getTabsInHash();
    if (tabsInHash.length > 0) {
      const keys = getKeys();
      const newHash = keys.find((key) => tabsInHash.includes(key));
      if (newHash) {
        setActiveKey(newHash);
      }
    }
  }, [location.hash, props.children]);

  return (
    <Tabs
      className="propify-subheader-tabs"
      onTabClick={(key: string) => {
        const tabsInHash = getTabsInHash();
        const keys = getKeys();
        const newTabs = tabsInHash.map((tab) => (keys.includes(tab) ? key : tab));
        newTabs.push(key);
        navigate({
          hash: removeDuplicates(newTabs)
            .filter((tab) => (tab === props.defaultTab ? null : tab))
            .join(SEPARATOR),
          search: location.search,
        });
        setActiveKey(key);
      }}
      activeKey={activeKey}
      defaultActiveKey={location.hash?.replace('#', '') || props.defaultTab}
      animated={false}
      {...nativeProps}
    >
      {props.children}
    </Tabs>
  );
};

export default HashTabs;
