import type { RenewalDTO } from '@/domain/renewal';
import type { Pet } from '@/domain/unit-applications';
import PetInformation from '@/pages/prospects/unit-applications/details/PetInformation';
import { RenewalService } from '@/services/renewal';
import { handleError } from '@/utils/utils';
import { DeleteOutlined } from '@ant-design/icons';
import { Button, Popconfirm } from 'antd';
import type { FC } from 'react';
import { useState } from 'react';

interface Props {
  renewal: RenewalDTO;
  refetch: () => void;
}

const RenewalPetsWrapper: FC<Props> = ({ renewal, refetch }) => {
  const [loading, setLoading] = useState(false);

  const onDelete = async (pet: Pet) => {
    setLoading(true);
    try {
      await RenewalService.deletePet(renewal.id, pet.id);
      refetch();
    } catch (error) {
      handleError(error, { toastFallbackMessage: 'There was an error deleting the pet' });
    } finally {
      setLoading(false);
    }
  };

  const petActions = (pet: Pet) => (
    <div>
      <Popconfirm
        title="Are you sure?"
        onConfirm={() => onDelete(pet)}
        okText="Yes"
        cancelText="No"
        okButtonProps={{
          loading,
        }}
      >
        <Button icon={<DeleteOutlined />} type="link" danger />
      </Popconfirm>
    </div>
  );

  return <PetInformation pets={renewal.pets} renderActions={petActions} />;
};

export default RenewalPetsWrapper;
