import type { New } from '@/domain/base';
import type { Occupant } from '@/domain/unit-applications';
import { SHORT_DATE_FORMAT } from '@/utils/time';
import { Button, Form, Modal } from 'antd';
import { Field, Formik } from 'formik';
import moment from 'moment';
import type { FC } from 'react';
import * as Yup from 'yup';
import type { ModalFormProps } from '../Button/formButton';
import { AntDatePicker } from '../Formik/CreateAntField';

interface Props extends ModalFormProps<[New<Occupant>]> {
  minor?: boolean;
  initialValues?: Occupant;
  loading?: boolean;
}

const OccupantFormModal: FC<Props> = ({ minor, initialValues, onCancel, onSuccess, loading }) => {
  const validationSchema = Yup.object().shape({
    dateOfBirth: Yup.string()
      .required('Date Of Birth is required')
      .test(
        'minor',
        minor ? 'Date of birth can be up to one year in the future' : 'Invalid date',
        function testDate(this: Yup.TestContext, value: string | undefined) {
          if (!value) {
            return true;
          }

          const date = moment(value);
          const limit = moment().endOf('day');
          if (minor) {
            limit.add(1, 'year');
          }

          return !date.isAfter(limit);
        },
      ),
  });

  return (
    <Formik<New<Occupant>>
      onSubmit={onSuccess}
      validationSchema={validationSchema}
      initialValues={
        initialValues || {
          dateOfBirth: '',
        }
      }
    >
      {({ handleSubmit, isValid, submitCount }) => (
        <Form labelCol={{ span: 6 }} wrapperCol={{ span: 18 }} onSubmitCapture={handleSubmit}>
          <Modal
            visible
            onCancel={onCancel}
            okText="Invite"
            title={initialValues ? 'Edit Occupant' : 'Add Additional Occupant'}
            maskClosable={false}
            footer={[
              <Button key="cancel" onClick={onCancel}>
                Cancel
              </Button>,
              <Button
                key="submit"
                type="primary"
                loading={loading}
                disabled={loading || !isValid}
                onClick={() => handleSubmit()}
              >
                Submit
              </Button>,
            ]}
          >
            <div>
              <span>Date of birth</span>
              <Field
                style={{ width: '100%' }}
                component={AntDatePicker}
                format={SHORT_DATE_FORMAT}
                name="dateOfBirth"
                placeholder="Date of Birth"
                hasFeedback
                submitCount={submitCount}
              />
            </div>
          </Modal>
        </Form>
      )}
    </Formik>
  );
};

export default OccupantFormModal;
