import type { Point } from '@/utils/map';
import type { Theme, WithStyles } from '@material-ui/core';
import { createStyles, Link, Tooltip, withStyles } from '@material-ui/core';
import type { FC, ReactNode } from 'react';
import { Link as RouterLink } from 'react-router-dom';

export const TAG_SIZE = 25;
export const CLUSTER_SIZE = TAG_SIZE * 1.5;

const styles = (theme: Theme) =>
  createStyles({
    point: {
      position: 'absolute',
      width: TAG_SIZE,
      height: TAG_SIZE,
      left: -TAG_SIZE / 2,
      top: -TAG_SIZE / 2,

      border: (props: any) =>
        `4px solid ${
          props.open ? theme.palette.primary.light : props.color ?? theme.palette.primary.main
        }`,
      borderRadius: TAG_SIZE,
      backgroundColor: 'white',
      textAlign: 'center',
      color: '#3f51b5',
      fontSize: 16,
      fontWeight: 'bold',
      padding: 4,
      cursor: 'pointer',

      '&:hover': {
        border: `4px solid ${theme.palette.primary.light}`,
        color: '#f44336',
      },
    },
    cluster: {
      position: 'absolute',
      width: CLUSTER_SIZE,
      height: CLUSTER_SIZE,
      left: -CLUSTER_SIZE / 2,
      top: -CLUSTER_SIZE / 2,

      border: '0px solid transparent',
      color: theme.palette.primary.contrastText,
      borderRadius: CLUSTER_SIZE,
      backgroundColor: theme.palette.primary.main,
      textAlign: 'center',
      fontSize: 16,
      fontWeight: 'bold',
      padding: `${CLUSTER_SIZE / 4}px 0`,
      cursor: 'pointer',

      '&:hover': {
        backgroundColor: theme.palette.primary.light,
      },
    },
  });

type Props = WithStyles<typeof styles> &
  Point & {
    index: number;
    open?: boolean;
    to?: string;
    onActivePlace: (value?: { index: number; source: string }) => void;
    onInactivePlace: (value?: { index: number; source: string }) => void;
    content?: ReactNode;
    isCluster?: boolean;
  };

const HtmlTooltip = withStyles(() => ({
  popper: {
    zIndex: 1299,
  },
  tooltip: {
    backgroundColor: '#fff',
    color: '#000',
    maxWidth: 350,
    fontSize: '0.9em',
    border: '1px solid #ddd',
    pointerEvents: 'auto',
    width: 'max-content',
  },
}))(Tooltip);

const Geotag: FC<Props> = ({
  children,
  open,
  index,
  to,
  content,
  isCluster,
  onActivePlace,
  onInactivePlace,
  onClick,
  classes,
}) => {
  const handleActive = () => {
    onActivePlace?.(open ? undefined : { index, source: 'geotag' });
  };

  const handleInactive = () => {
    onInactivePlace?.(open ? undefined : { index, source: 'geotag' });
  };

  const markerClass = isCluster ? classes.cluster : classes.point;

  const marker = (
    <div
      className={markerClass}
      onClick={onClick}
      onMouseEnter={handleActive}
      onFocus={handleActive}
      onMouseLeave={handleInactive}
      onBlur={handleInactive}
    >
      {content}
    </div>
  );

  const childrenWithLink = to ? (
    <Link component={RouterLink} to={to}>
      {children}
    </Link>
  ) : (
    children
  );

  return children ? (
    <HtmlTooltip
      title={childrenWithLink!}
      placement="top"
      open={open}
      PopperProps={{
        disablePortal: true,
        popperOptions: {
          modifiers: {
            preventOverflow: {
              enabled: false,
            },
          },
        },
      }}
    >
      {marker}
    </HtmlTooltip>
  ) : (
    marker
  );
};

export default withStyles(styles)(Geotag);
