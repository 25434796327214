import type { Enum } from '@/domain/base';
import useEnumeration from '@/hooks/useEnumeration';
import { Select } from 'antd';
import type { FC } from 'react';

type Props = {
  enumeration: string;
  multiple?: boolean;
  value?: string | string[];
  onChangeValue: (value?: string | string[]) => void;
  allowClear?: boolean;
};

const EnumSelect: FC<Props> = ({ enumeration, multiple, value, onChangeValue, allowClear }) => {
  const { data: options, isValidating: loading } = useEnumeration(enumeration);

  return (
    <Select
      value={value}
      options={options.map((type: Enum) => ({
        label: type.displayName,
        value: type.value,
      }))}
      loading={loading}
      onChange={onChangeValue}
      allowClear={allowClear}
      defaultActiveFirstOption={false}
      mode={multiple ? 'multiple' : undefined}
      style={{ width: '100%' }}
    />
  );
};

export default EnumSelect;
