import { Tooltip } from '@/@propify-components/shared/utils/material';
import { useParameters } from '@/notmagic/components/EntityTable/hooks/useParameters';
import type {
  ColumnComponentType,
  ColumnComponentTypeProps,
  ColumnType,
} from '@/notmagic/components/EntityTable/types';
import { FilterOutlined } from '@ant-design/icons';

type Value = {
  display: number;
  parameters: Record<string, string>;
};

interface Props extends ColumnComponentTypeProps<Value> {
  tooltip?: string;
}

const Component: ColumnComponentType<Props> = ({ value, tooltip = '' }) => {
  const { updateParameters } = useParameters();

  return value ? (
    <>
      {value.display}
      <Tooltip title={tooltip}>
        <FilterOutlined
          style={{ marginLeft: 4 }}
          onClick={() => {
            updateParameters(value.parameters);
          }}
        />
      </Tooltip>
    </>
  ) : null;
};

const Definition: ColumnType = {
  Component,
  sort: (a: Value, b: Value) => {
    if (a === undefined) return -1;
    if (b === undefined) return 1;
    return a.display === b.display ? 0 : a.display < b.display ? -1 : 1;
  },
};

export default Definition;
