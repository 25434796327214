import { DocumentTemplatesService } from '@/services/document-templates';
import { useFetch } from '@/utils/request';
import { Button, Divider, Modal, Typography } from 'antd';
import HelloSign from 'hellosign-embedded';
import type { FC } from 'react';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import type { ModalFormProps } from '../Button/formButton';

const { Title, Text, Paragraph } = Typography;

interface Props extends ModalFormProps {
  documentTemplateId: number;
  documentTemplateTitle: string;
}

const EditDocumentTemplate: FC<Props> = ({
  onCancel,
  documentTemplateTitle,
  documentTemplateId,
}) => {
  const [client, setClient] = useState<HelloSign>();

  const { data: config } = useFetch({
    fetcher: DocumentTemplatesService.config,
    errorMessage: 'There was an error while loading the document template config',
  });

  useEffect(() => {
    if (!config?.clientId) {
      return;
    }
    setClient(
      new HelloSign({
        clientId: config.clientId,
        container: document.getElementById('hellosign-document-container-edit')!,
        testMode: config.testMode,
        skipDomainVerification: config.skipDomainVerification,
        locale: HelloSign.locales[config.locale],
      }),
    );
  }, [config]);

  const { data: editUrl } = useFetch(
    {
      fetcher: documentTemplateId ? DocumentTemplatesService.getEditUrl : undefined,
      errorMessage: 'There was an error trying to get the template document edit url',
    },
    documentTemplateId,
  );

  useEffect(() => {
    if (client && editUrl) {
      client.open(editUrl);

      client.on('finish', () => {
        onCancel?.();
      });
    }
  }, [client, config, onCancel, editUrl]);

  return (
    <Modal
      visible
      onCancel={onCancel}
      title="Edit Document Template"
      maskClosable={false}
      width={980}
      bodyStyle={{ height: 1000 }}
      footer={
        <Button key="cancel" onClick={onCancel}>
          Cancel
        </Button>
      }
    >
      <Paragraph>
        <Title level={5}>Upload Lease Template Document</Title>
        <Text>
          Drag signature fields and variable fields onto the document template to construct a
          Document Template. Values in the &lsquo;Auto-Fill Fields&lsquo; list will be filled in
          dynamically with the relevant information to the Unit for which a document is being
          generated. For more information on the significance of each value shown in this list,{' '}
          <Link to="/document-templates/autofill-dictionary">click here.</Link>
        </Text>
      </Paragraph>

      <Divider />

      <Title level={5}>{documentTemplateTitle}</Title>
      <div id="hellosign-document-container-edit" style={{ height: '90%' }} />
    </Modal>
  );
};

export default EditDocumentTemplate;
