// Fix eslint warnings in separate ticket: https://skyboxcapital.atlassian.net/browse/PN-8348
/* eslint-disable @typescript-eslint/no-explicit-any */
import { FilterOutlined } from '@ant-design/icons';
import classNames from 'classnames';
import type { ReactElement, ReactNode } from 'react';
import React from 'react';
import { ArrowDown, FlagOutlined } from '../../shared/utils/icons';
import type { ClassNameMap } from '../../shared/utils/material';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Checkbox,
  Radio,
  Tooltip,
  useMediaQuery,
  useTheme,
} from '../../shared/utils/material';
import type { Column } from '../index';
import { Align, DEFAULT_COLUMN_WIDTH } from '../index';
import HeaderTitle from './HeaderTitle';
import StickyColumns from './StickyColumns';

interface Props<T = any> {
  columns: Column<T>[];
  item: T;
  itemIndex: number;
  selectable: boolean;
  draggable?: boolean;
  selected: boolean;
  selectionDisabled: boolean;
  toggleSelected: (event: any) => void;
  pinnedColumns: string[];
  className?: string;
  showTooltip?: boolean;
  tooltipText?: string;
  onClick?: (row: T) => void;
  singleSelection?: boolean;
  responsive?: boolean;
  styles: ClassNameMap<string>;
}

function Row<T>(props: React.PropsWithChildren<Props<T>>, ref: React.Ref<HTMLDivElement>) {
  const styles = props.styles;
  const responsive = typeof props.responsive === 'undefined' ? true : props.responsive;
  const theme = useTheme();
  let isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  if (!responsive) {
    isMobile = false;
  }

  const handleClick = () => {
    if (props.onClick) {
      props.onClick(props.item);
    }
  };

  const renderColumns = (withLabel?: boolean) => {
    return (
      <StickyColumns
        styles={styles}
        renderContainer={(children) => <>{children}</>}
        columns={props.columns}
        isSelectable={props.selectable}
        singleSelection={!!props.singleSelection}
        isDraggable={!!props.draggable}
        renderTooltip={
          props.showTooltip
            ? () => (
                <span className={`${styles.tableCell} ${styles.tableFlagCell}`}>
                  <Tooltip
                    title={props.tooltipText || ''}
                    placement="right"
                    PopperProps={{
                      style: {
                        whiteSpace: 'pre-line',
                      },
                    }}
                    classes={{ tooltip: styles.tooltip }}
                  >
                    <span style={{ display: 'flex' }}>
                      <FlagOutlined
                        className="flagIcon"
                        style={{
                          visibility: props.tooltipText ? 'visible' : 'hidden',
                        }}
                      />
                    </span>
                  </Tooltip>
                </span>
              )
            : null
        }
        renderCheckbox={() => {
          if (withLabel && isMobile) {
            return null;
          }

          return (
            <span
              className={classNames(
                styles.tableCell,
                styles.tableDragDropCell,
                styles.tableCheckbox,
              )}
              data-test="tableRowCheckbox"
              role="checkbox"
              aria-checked={props.selected}
            >
              <Checkbox
                tabIndex={-1}
                checked={props.selected}
                onChange={props.toggleSelected}
                disabled={props.selectionDisabled}
                size="small"
              />
            </span>
          );
        }}
        renderRadioButton={() => {
          return (
            <span className={classNames(styles.tableCell, styles.tableDragDropCell)}>
              <Radio checked={props.selected} onChange={props.toggleSelected} size="small" />
            </span>
          );
        }}
        renderCell={(column, _, pinned) => {
          const width = column.width ? column.width : DEFAULT_COLUMN_WIDTH;

          let value: string | number | ReactNode;
          if (column.render) {
            value = column.render(props.item, props.itemIndex);
          } else {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            const item = props.item as any;
            value = item[column.key];
          }

          const renderFilterableCell = () => {
            if (typeof column.onFilter === 'function') {
              const filter = (
                <FilterOutlined
                  className={styles.filterableIcon}
                  onClick={() => column.onFilter?.(props.item, props.itemIndex)}
                />
              );
              return column.filterTooltip ? (
                <Tooltip title={column.filterTooltip}>{filter}</Tooltip>
              ) : (
                filter
              );
            }
            return null;
          };

          const cellContent = (
            <span
              onClick={handleClick}
              key={column.key}
              style={{
                width,
                maxWidth: width,
                ...(column.styles ? column.styles(props.item) : {}),
              }}
              className={classNames(styles.tableCell, column.className?.(props.item), {
                [styles.tableCellRight]: column.align === Align.right,
                [styles.tableCellCenter]: column.align === Align.center,
                [styles.tableCellMobile]: withLabel && isMobile,
                [styles.tableCellMobileTitle]: !withLabel && isMobile,
                activePinned: pinned,
              })}
              data-test="tableCell"
              data-column-key={column.key}
              role="cell"
            >
              {withLabel && (
                <span>
                  <HeaderTitle column={column} styles={styles} />
                </span>
              )}
              {!(value instanceof HTMLElement) && !isMobile ? <span>{value}</span> : value}
              {renderFilterableCell()}
            </span>
          );

          const tooltipContent = column.showTooltip
            ? column.renderTooltip
              ? column.renderTooltip(props.item, props.itemIndex)
              : value
            : null;

          return column.showTooltip && tooltipContent ? (
            <Tooltip
              title={tooltipContent}
              arrow
              placement="top"
              classes={{ tooltip: styles.cellTooltip }}
            >
              {cellContent}
            </Tooltip>
          ) : (
            cellContent
          );
        }}
        pinnedColumns={props.pinnedColumns}
      />
    );
  };

  const renderMobile = () => {
    return (
      <Accordion>
        <AccordionSummary expandIcon={<ArrowDown />} aria-controls="table-row">
          <div className={styles.tableRowMobileTitle}>{renderColumns()}</div>
        </AccordionSummary>
        <AccordionDetails className="detailsRow">{renderColumns(true)}</AccordionDetails>
      </Accordion>
    );
  };

  return (
    <div
      ref={ref}
      className={classNames('propify-table-row', styles.tableRow, {
        [styles.clickableRow]: props.onClick !== undefined,
        selected: props.selected,
        [props.className!]: !!props.className,
        [styles.tableRowMobile]: isMobile,
      })}
      data-test="tableRow"
      role="row"
    >
      {isMobile ? renderMobile() : renderColumns()}
    </div>
  );
}

export default React.forwardRef(Row) as <T>(
  props: Props<T> & { ref?: React.Ref<HTMLDivElement> },
) => ReactElement;
