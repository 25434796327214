import type { UtilityResponsiblePartyRoleType } from '@/domain/lease';
import type { UtilityType } from '@/domain/lease';
import type { RenewalUtility } from '@/domain/renewal';
import { useFormikContext } from 'formik';
import { useCallback, useMemo, useState } from 'react';

export type UtilitiesMapType = Record<UtilityType, UtilityResponsiblePartyRoleType>;

const parseUtilitiesArrayToMap = (utilities: RenewalUtility[]) =>
  utilities.reduce<Partial<UtilitiesMapType>>(
    (map, ut) => ({
      ...map,
      [ut.type]: ut.responsiblePartyRoleType,
    }),
    {},
  );

type RenewalType = {
  id: number;
  orgId: number;
  utilities: RenewalUtility[];
};

export const useRenewalUtilities = (enabled: boolean, renewal: RenewalType) => {
  const [values, setValues] = useState<Partial<UtilitiesMapType>>(() =>
    parseUtilitiesArrayToMap(renewal.utilities),
  );

  const { setFieldValue } = useFormikContext();

  const getUpdatedUtilities = useCallback(
    (newValues?: typeof values): RenewalUtility[] => {
      if (!enabled) {
        return [];
      }

      const valuesToUse = newValues || values;

      const result = renewal.utilities
        .filter((u) => u.type in valuesToUse)
        .map((utility) => ({
          ...utility,
          responsiblePartyRoleType: valuesToUse[utility.type]!,
        }));

      Object.keys(valuesToUse)
        .filter((type) => !result.some((r) => r.type === type))
        .forEach((type) => {
          const newValue: Partial<RenewalUtility> = {
            orgId: renewal.orgId,
            renewalId: renewal.id,
            type: type as UtilityType,
            responsiblePartyRoleType: valuesToUse[type],
          };

          result.push(newValue as RenewalUtility);
        });

      return result;
    },
    [enabled, renewal.id, renewal.orgId, renewal.utilities, values],
  );

  const setUtilityValue = useCallback<
    (type: UtilityType, responsible: UtilityResponsiblePartyRoleType) => Partial<UtilitiesMapType>
  >(
    (type, responsible) => {
      if (!enabled) {
        return {};
      }

      const result = {
        ...values,
        [type]: responsible,
      };

      setValues(result);
      setFieldValue('utilities', getUpdatedUtilities(result));
      return result;
    },
    [enabled, values, getUpdatedUtilities, setFieldValue],
  );

  const result = useMemo(
    () => ({
      values,
      setUtilityValue,
      getUpdatedUtilities,
    }),
    [values, setUtilityValue, getUpdatedUtilities],
  );

  return result;
};
