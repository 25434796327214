import OccupantsTable from '@/components/Occupants/OccupantsTable';
import type { RenewalDTO } from '@/domain/renewal';
import type { Occupant } from '@/domain/unit-applications';
import { RenewalService } from '@/services/renewal';
import { handleError } from '@/utils/utils';
import { message } from 'antd';
import type { FC } from 'react';
import { useState } from 'react';

interface Props {
  renewal: RenewalDTO;
  refetchRenewal: () => void;
}

const RenewalOccupantsTab: FC<Props> = ({ renewal, refetchRenewal }) => {
  const [loading, setLoading] = useState(false);

  const handleDelete = (occupant: Occupant) => {
    setLoading(true);
    RenewalService.deleteOccupant(renewal.id, occupant.id)
      .then(() => {
        message.success('Occupant deleted');
        refetchRenewal();
      })
      .catch((error) => {
        handleError(error, { toastFallbackMessage: 'There was an error deleting the occupant' });
      })
      .finally(() => {
        setLoading(false);
      });
  };
  return <OccupantsTable occupants={renewal.occupants} onDelete={handleDelete} loading={loading} />;
};

export default RenewalOccupantsTab;
