import type { FC } from 'react';
import { Link } from 'react-router-dom';
import './styles.less';

type Item = {
  label: string;
  link?: string;
  value?: string;
};

interface Props {
  items: Item[];
}

const HeaderInfoBlocks: FC<Props> = ({ items }) => {
  const renderItem = (item: Item) => {
    return (
      <div className="headerInfoItem" key={item.label}>
        <h5>{item.label}</h5>
        <span data-testid={item?.label}>{item.value}</span>
      </div>
    );
  };
  return (
    <div className="headerInfoBlocksContainer" data-testid="headerInfoBlocksContainer">
      {items.map((item) => {
        if (item.link) {
          return (
            <Link key={item.label} to={item.link}>
              {renderItem(item)}
            </Link>
          );
        }
        return renderItem(item);
      })}
    </div>
  );
};

export default HeaderInfoBlocks;
