import { ModalFormButton } from '@/components/Button/formButton';
import { EditOutlined } from '@ant-design/icons';
import { Button, Modal, Tooltip } from 'antd';
import type { FormikContextType } from 'formik';
import type { FC } from 'react';
import { useEffect, useRef, useState } from 'react';
import FloatingEditableAttribute from '../../FloatingEditableAttribute';
import styles from './styles.module.less';
import type { Attribute, AttributeAction, AttributeValue, EditablePageSaveFn } from './types';
import { getDisplayableValue, getEditableValue } from './utils';

interface AttributeFieldProps {
  attribute: Attribute;
  formProps: FormikContextType<AttributeValue>;
  errorSubmitting?: boolean;
  onSave?: EditablePageSaveFn;
}

const Action = ({
  attribute,
  action,
  onSave,
}: {
  attribute: Attribute;
  action: AttributeAction;
  onSave?: EditablePageSaveFn;
}) => {
  const [loading, setLoading] = useState(false);
  const onClick = async () => {
    if (action.confirmation) {
      return Modal.confirm({
        title: action.confirmation || 'Are you sure?',
        icon: action.icon,
        onOk: () => action.onClick?.(attribute),
      });
    }

    setLoading(true);

    await action.onClick?.(attribute);

    setLoading(false);

    return undefined;
  };

  if (action.modal) {
    const ModalComponent = action.modal as React.ElementType;
    return (
      <Tooltip title={action.tooltip}>
        <ModalFormButton
          buttonType="link"
          icon={action.icon}
          onSuccess={action.onSave || attribute.onSave || onSave}
        >
          {(p) => <ModalComponent {...action} {...attribute} {...p} />}
        </ModalFormButton>
      </Tooltip>
    );
  }

  return (
    <Tooltip title={action.tooltip}>
      <Button
        key={action.key}
        icon={action.icon}
        type="link"
        onClick={() => onClick()}
        loading={loading}
      />
    </Tooltip>
  );
};

const AttributeField: FC<AttributeFieldProps> = ({
  onSave,
  attribute,
  errorSubmitting = false,
  formProps,
}) => {
  const { isSubmitting, values } = formProps;
  const [editing, setEditing] = useState(false);

  useEffect(() => {
    if (!isSubmitting && !errorSubmitting) {
      setEditing(false);
    }
  }, [isSubmitting, errorSubmitting]);

  const baseRef = useRef<HTMLDivElement>(null);

  const handleCancel = () => {
    if (!isSubmitting) {
      setEditing(false);
      formProps.resetForm();
    }
  };

  return (
    <>
      <div
        className={`${styles.actionButtons} ${isSubmitting ? styles.isSaving : ''}`}
        style={
          attribute.editButtonAlwaysVisible
            ? {
                display: 'block',
              }
            : {}
        }
      >
        <div style={{ display: 'flex' }}>
          {attribute.actions
            ?.filter((action) => !!action)
            .map((action) => (
              <Action
                key={attribute.fieldName}
                attribute={attribute}
                action={action as AttributeAction}
                onSave={onSave}
              />
            ))}
          {!editing &&
            !attribute.readOnly &&
            (attribute.editButton ? (
              attribute.editButton
            ) : (
              <Button
                onClick={() => setEditing((e) => !e)}
                shape="circle"
                type="link"
                icon={<EditOutlined />}
                data-testid="editable-attribute-edit-button"
              />
            ))}
        </div>
      </div>

      <div
        ref={baseRef}
        style={{ overflow: 'hidden', textOverflow: 'ellipsis', marginRight: 5, width: '100%' }}
      >
        {editing
          ? !attribute.readOnly &&
            baseRef.current && (
              <FloatingEditableAttribute
                baseElement={baseRef.current}
                onConfirm={() => formProps.submitForm()}
                onCancel={handleCancel}
                loading={isSubmitting}
                error={(formProps.errors[attribute.fieldName] as string) || ''}
                disabled={!formProps.isValid}
              >
                {getEditableValue(attribute, formProps)}
              </FloatingEditableAttribute>
            )
          : getDisplayableValue(attribute, values)}
      </div>
    </>
  );
};

export default AttributeField;
