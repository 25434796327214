import type { Column } from '@/@propify-components';
import { dateColumn, Table } from '@/@propify-components';
import type { Occupant } from '@/domain/unit-applications';
import { DeleteOutlined } from '@ant-design/icons';
import { Button, Popconfirm } from 'antd';
import type { CSSProperties, FC } from 'react';

interface Props {
  occupants: Occupant[];
  style?: CSSProperties;
  loading?: boolean;
  onDelete?: (occupant: Occupant) => void;
}

const OccupantsTable: FC<Props> = ({ occupants, style, loading, onDelete }) => {
  const occupantsColumns: Column<Occupant>[] = [
    {
      key: 'name',
      title: 'Name',
      render: (_, index) => `Minor ${index + 1}`,
    },
    dateColumn({ title: 'Date of Birth', key: 'dateOfBirth' }),
    ...(onDelete
      ? [
          {
            title: '',
            key: 'actions',
            width: 100,
            render: (occupant: Occupant) => (
              <div>
                <Popconfirm
                  title="Are you sure?"
                  onConfirm={() => onDelete(occupant)}
                  okText="Yes"
                  cancelText="No"
                  okButtonProps={{
                    loading,
                  }}
                >
                  <Button icon={<DeleteOutlined />} type="link" danger />
                </Popconfirm>
              </div>
            ),
          },
        ]
      : []),
  ];

  return (
    <Table
      columns={occupantsColumns}
      data={occupants}
      rowKeyExtractor={(row) => row.id}
      style={style}
      loading={loading}
    />
  );
};

export default OccupantsTable;
