import type { Column } from '@/@propify-components';
import { Table } from '@/@propify-components';
import type { Pet } from '@/domain/unit-applications';
import { settings } from '@/services/settings';
import { enumToLabel } from '@/utils/utils';
import type { FC } from 'react';

export type PetColumnType = Pick<Pet, 'name' | 'type' | 'weight'>;

const tableSettings = settings('pets-table');

interface Props {
  pets: PetColumnType[];
}

const PetsTable: FC<Props> = ({ pets }) => {
  const columns: Column[] = [
    {
      key: 'name',
      title: 'Pet Name',
    },
    {
      key: 'type',
      title: 'Pet Type',
      render: (row) => enumToLabel(row.type),
    },
    {
      key: 'weight',
      title: 'Weight (lbs.)',
    },
  ];

  return (
    <Table
      style={{ marginTop: '15px' }}
      columns={columns}
      data={pets}
      rowKeyExtractor={(row) => row.name}
      settings={tableSettings}
    />
  );
};

export default PetsTable;
