import SectionSplashScreen from '@/components/PageLoading/SplashSection';
import { Ellipsis } from '@/utils/icons';
import { RouteContext } from '@ant-design/pro-layout';
import { Button, Dropdown, Menu } from 'antd';
import classNames from 'classnames';
import type { CSSProperties, FC, ReactNode } from 'react';
import { useContext, useMemo } from 'react';
import type { GlobalAction, GlobalActionData } from '../../types';
import GlobalActionComponent from './GlobalActionComponent';
import styles from './styles.module.less';

export interface PageProps {
  title?: string | ReactNode;
  className?: string;
  refreshData: () => void;
  bulkUpdate: (ids: number[]) => void;
  fullHeight?: boolean;
  scrollX?: boolean;
  extra?: ReactNode[];
  content?: ReactNode | undefined;
  globalActions?: GlobalAction[];
  actionsData: GlobalActionData;
  loading?: boolean;
  pageContentStyle?: CSSProperties;
  pageHeaderClassName?: string;
  children: ReactNode;
}

const Page: FC<PageProps> = (props) => {
  const {
    title,
    children,
    globalActions,
    actionsData,
    refreshData,
    bulkUpdate,
    className,
    scrollX = false,
    fullHeight = false,
    extra,
    content,
    loading = false,
    pageContentStyle = {},
    pageHeaderClassName = '',
  } = props || {};
  const { title: titleRoute } = useContext(RouteContext) || {};

  const [globalNavActions, globalPopoverActions] = useMemo(() => {
    const _actions = (globalActions || []).filter((act) => !act.hidden);
    const primaryPresent = _actions.some((a) => a.primary);
    if (!primaryPresent) {
      return [_actions[0] ? [_actions[0]] : [], _actions.slice(1)];
    }

    return [_actions.filter((a) => a.primary), _actions.filter((a) => !a.primary)];
  }, [globalActions]);

  const renderGlobalActionComponent = (
    type: 'nav' | 'popover',
    componentProps: GlobalAction,
    disabled: boolean,
    index: number,
  ) => (
    <GlobalActionComponent
      key={index}
      type={type}
      {...componentProps}
      data={actionsData}
      refreshData={refreshData}
      bulkUpdate={bulkUpdate}
      disabled={disabled}
    />
  );

  const renderRightBar = () => {
    if (!globalNavActions.length) {
      return null;
    }

    const selectionIsEmpty = !actionsData.selection?.length;

    return (
      <>
        {globalNavActions.map((componentProps, index) =>
          renderGlobalActionComponent(
            'nav',
            componentProps,
            !!componentProps.needsSelection && selectionIsEmpty,
            index,
          ),
        )}

        {globalPopoverActions.length ? (
          <Dropdown
            trigger={['click']}
            overlay={
              <Menu>
                {globalPopoverActions.map((componentProps, index) => (
                  <Menu.Item
                    key={index}
                    style={{ padding: 0 }}
                    disabled={!!componentProps.needsSelection && selectionIsEmpty}
                  >
                    {renderGlobalActionComponent(
                      'popover',
                      componentProps,
                      !!componentProps.needsSelection && selectionIsEmpty,
                      index,
                    )}
                  </Menu.Item>
                ))}
              </Menu>
            }
          >
            <Button icon={<Ellipsis />} className={styles.navHandler} />
          </Dropdown>
        ) : null}
      </>
    );
  };

  return (
    <>
      {/* TODO: pageHeader should compose propify-page-header when global css is migrated to css modules */}
      <div className={`${styles.pageHeader} propify-page-header ${pageHeaderClassName}`}>
        <div className={styles.pageHeaderLeft}>
          {content ? content : <h1>{title || titleRoute}</h1>}
        </div>
        <div className={styles.pageHeaderRight}>
          <div className={styles.pageHeaderRightExtra}>{extra}</div>
          {renderRightBar()}
        </div>
      </div>
      <div
        className={classNames(styles.pageContent, className, {
          [styles.fullHeight]: fullHeight,
          [styles.noHorizontalScroll]: !scrollX,
        })}
        style={pageContentStyle}
      >
        {loading ? <SectionSplashScreen /> : children}
      </div>
    </>
  );
};

export default Page;
