import { useAppContext } from '@/contexts/AppContext';
import type {
  FilterComponentType,
  FilterComponentTypeProps,
  FilterDefinition,
} from '@/notmagic/components/EntityTable/types';
import { useEntityTableContext } from '@/notmagic/Context';
import { useImmutableEndpoint } from '@/notmagic/hooks/request';
import type { Entity } from '@/notmagic/types';
import { filterSelectOptions } from '@/utils/utils';
import { Select } from 'antd';
import jsonata from 'jsonata';
import { useEffect, useMemo, useState } from 'react';

type Props = FilterComponentTypeProps & {
  entityType?: string;
  multiple?: boolean;
  filter?: string;
};

const Component: FilterComponentType<Props> = ({
  editable,
  entityType: entityTypeName,
  multiple,
  filter,
  value,
  onChangeValue,
  otherValues,
}) => {
  const { currentUser } = useAppContext();
  const { entityTypes } = useEntityTableContext();
  const entityType =
    entityTypeName && entityTypes[entityTypeName] ? entityTypes[entityTypeName] : undefined;
  const [search, setSearch] = useState('');
  const [filteredEntities, setFilteredEntities] = useState<Entity[]>();
  const [options, setOptions] = useState<any[]>();

  const { data: entities, isValidating: loading } = useImmutableEndpoint<Entity[]>(
    entityType?.endpoint,
  );

  useEffect(() => {
    if (entities) {
      const expression = `[
        $filter(
          $,
          function($v, $i, $a) {
            ${filter?.trim() ? filter : 'true'}
          }
        )
      ]`;

      jsonata(expression)
        .evaluate(entities, { ...otherValues, currentUser })
        .then(setFilteredEntities)
        // eslint-disable-next-line no-console
        .catch((error) => console.error(error));
    }
  }, [filter, otherValues, entities]);

  const numberValue = useMemo(() => {
    if (Array.isArray(value)) {
      return value.map((each) => parseInt(each));
    }

    return value ? parseInt(value) : undefined;
  }, [value]);

  useEffect(() => {
    if (filteredEntities) {
      const valueExp = entityType?.keyExpression ? entityType?.keyExpression : 'id';
      const labelExp = entityType?.displayNameExpression
        ? entityType?.displayNameExpression
        : 'name';

      const expression = `[$map(
        $,
        function($v, $i, $a) {
          $v.{
            'value': ${valueExp},
            'label': ${labelExp}
          }
        }
      )]`;

      jsonata(expression)
        .evaluate(filteredEntities)
        .then(setOptions)
        // eslint-disable-next-line no-console
        .catch((error) => console.error(error));
    }
  }, [
    filteredEntities,
    entityType?.displayNameExpression,
    entityType?.keyExpression,
    search,
    filter,
  ]);

  if (!entityTypeName) {
    return (
      <div style={{ color: 'red' }}>
        {`"entityType" must be configured for this type of filter`}
      </div>
    );
  }

  if (!editable) {
    if (!options) {
      return <></>;
    }

    if (Array.isArray(numberValue)) {
      const selectedOptions = options.filter((each) => numberValue.includes(each.value))!;
      return <>{selectedOptions.map((each) => each.label).join(', ')}</>;
    }

    const selectedOption = options.find((each) => numberValue === each.value)!;
    return <>{selectedOption.label}</>;
  }

  const onChange = (newValue: number | number[]) => {
    if (Array.isArray(newValue)) {
      onChangeValue(newValue.map((each) => each.toString()));
    } else if (newValue) {
      onChangeValue(newValue.toString());
    } else {
      onChangeValue(undefined);
    }
  };

  return (
    <Select
      value={numberValue}
      options={options}
      loading={loading}
      onChange={onChange}
      onSearch={setSearch}
      showSearch
      allowClear
      defaultActiveFirstOption={false}
      notFoundContent={null}
      filterOption={filterSelectOptions('label')}
      mode={multiple ? 'multiple' : undefined}
    />
  );
};

const Filter: FilterDefinition = {
  Component,
  jsonataQuery: (value: string | string[], key: string) => {
    // Having a multiple filter and a value that is also an array is not yet supported
    if (Array.isArray(value)) {
      return `$v.${key} in [${value.join(', ')}]`;
    }

    return `${value} in [$v.${key}]`;
  },
};

export default Filter;
