import type { ModalFormProps } from '@/components/Button/formButton';
import { ModalFormButton } from '@/components/Button/formButton';
import type { Charge, UtilityType } from '@/domain/lease';
import { ChargeFrequency } from '@/domain/lease';
import type { RenewalOption, RenewalUtility } from '@/domain/renewal';
import { RenewalStatus } from '@/domain/renewal';
import useEnumeration from '@/hooks/useEnumeration';
import '@/pages/prospects/styles.less';
import { RenewalService } from '@/services/renewal';
import { useFetch } from '@/utils/request';
import type { FormikSubmitFn } from '@/utils/types';
import { handleError } from '@/utils/utils';
import { Button, Checkbox, message, Modal } from 'antd';
import { Formik } from 'formik';
import moment from 'moment';
import type { FC } from 'react';
import * as Yup from 'yup';
import DenyRenewalModal from './DenyRenewalModal';
import RenewalsModalContent from './RenewalsModalContent';

interface Props extends ModalFormProps {
  renewalId: number;
  showDeclineRenewal?: boolean;
  showAllowCounterOffer?: boolean;
  showSaveProposal?: boolean;
  showSaveAndSendOffer?: boolean;
  onRefresh: () => void;
}

// This is to determine which action to take in the 'onSubmit' function
type SUBMIT_TYPE = 'save-proposal' | 'save-and-send-offer' | 'decline-renewal';

export type RenewalsModalValues = {
  submitType: SUBMIT_TYPE;
  startDate?: string;
  options: RenewalOption[];
  charges: Charge[];
  utilities: Partial<RenewalUtility>[];
  counterOfferAllowed: boolean;
};

const RenewalsModal: FC<Props> = ({
  renewalId,
  onCancel,
  showDeclineRenewal = true,
  showAllowCounterOffer = true,
  showSaveAndSendOffer = true,
  showSaveProposal = true,
  onRefresh,
}) => {
  const { data: renewal, isValidating: isLoading } = useFetch(
    {
      fetcher: renewalId ? RenewalService.get : undefined,
      errorMessage: 'There was an error fetching the renewal',
    },
    renewalId,
  );

  const { data: utilityTypes } = useEnumeration<UtilityType>('lease-utility-type');

  const validationSchema = Yup.object({
    submitType: Yup.string<SUBMIT_TYPE>().required(),
    startDate: Yup.string()
      .required('Start Date is required')
      .test('start-date', 'invalid date', (date) => {
        return moment(date).isAfter(moment().subtract(1, 'day'));
      }),
    utilities: Yup.array(
      Yup.object({
        id: Yup.number().min(1),
        orgId: Yup.number().min(1),
        renewalId: Yup.number().min(1).required('Renewal ID is required'),
        responsiblePartyRoleType: Yup.string().required('Responsible Party Role Type is required'),
        type: Yup.string().required('Type is required'),
      }),
    ).test('utilities-error', 'Utilities missing', (validationValue) => {
      return utilityTypes.every((ut) => validationValue?.some((v) => v.type === ut.value));
    }),
    options: Yup.array(
      Yup.object({
        yearOverYearEscalator: Yup.number()
          .min(0, 'This number should be between 0 and 100')
          .max(100, 'This number should be between 0 and 100')
          .nullable('This field is required')
          .required('This field is required')
          .test('is-decimal', 'This field should be a percentage with 2 decimal points', (value) =>
            value ? /^\d+(\.\d{1,2})?$/.test(value.toString()) : true,
          ),
      }),
    ),
  });

  const validateMonthlyFeeType = (options: RenewalOption[]) => {
    if (
      options.every((o) =>
        o.charges.some((c) => c.frequency === ChargeFrequency.MONTHLY && c.type === 'RENT'),
      )
    ) {
      return false;
    }

    message.error('Unable to save changes. Monthly Rent charge is missing.');
    return true;
  };

  const onDeny: FormikSubmitFn<RenewalsModalValues> = () => {
    if (!renewal) {
      return;
    }

    RenewalService.update({
      ...renewal,
      status: RenewalStatus.REFUSED,
    })
      .then(() => {
        message.success('Renewal successfully updated');
        onRefresh();
        onCancel?.();
      })
      .catch((error) => {
        handleError(error, { toastFallbackMessage: 'Something went wrong updating the renewal' });
      });
  };

  const onSave: FormikSubmitFn<RenewalsModalValues> = (values, helpers) => {
    if (!renewal) {
      return;
    }

    if (validateMonthlyFeeType(values.options)) {
      return;
    }

    RenewalService.update({
      ...renewal,
      status:
        renewal.status === RenewalStatus.APPROVED ? RenewalStatus.OFFER_PENDING : renewal.status,
      options: values.options,
      startDate: values.startDate,
      utilities: values.utilities as RenewalUtility[],
      charges: values.charges,
      counterOfferAllowed: values.counterOfferAllowed,
    })
      .then(() => {
        onRefresh();
        onCancel?.();
      })
      .finally(() => {
        helpers.setSubmitting(false);
      });
  };

  const onSaveAndSend: FormikSubmitFn<RenewalsModalValues> = (values, helpers) => {
    if (!renewal) {
      return;
    }

    if (validateMonthlyFeeType(values.options)) {
      return;
    }

    RenewalService.update({
      ...renewal,
      status:
        renewal.status === RenewalStatus.OFFER_PENDING
          ? RenewalStatus.OFFERED
          : renewal.status === RenewalStatus.OFFERED
          ? renewal.status
          : RenewalStatus.NEGOTIATION,
      options: values.options,
      startDate: values.startDate,
      utilities: values.utilities as RenewalUtility[],
      charges: values.charges,
      counterOfferAllowed: values.counterOfferAllowed,
    })
      .then(() => {
        message.success('Renewal successfully updated');
        onRefresh();
        onCancel?.();
      })
      .catch((error) => {
        handleError(error, { toastFallbackMessage: 'Something went wrong updating the renewal' });
      })
      .finally(() => {
        helpers.setSubmitting(false);
      });
  };

  const submitModal: FormikSubmitFn<RenewalsModalValues> = (values, actions) => {
    switch (values.submitType) {
      case 'decline-renewal':
        onDeny(values, actions);
        break;

      case 'save-proposal':
        onSave(values, actions);
        break;

      case 'save-and-send-offer':
        onSaveAndSend(values, actions);
        break;
    }
  };

  const initialRenewalOptions: RenewalOption[] =
    renewal?.options.map((o) =>
      o.yearOverYearEscalator ? o : { ...o, yearOverYearEscalator: 0 },
    ) || [];

  const initialValues: RenewalsModalValues = {
    submitType: 'decline-renewal',
    startDate: renewal?.startDate,
    options: initialRenewalOptions,
    utilities: [],
    charges: renewal?.charges || [],
    counterOfferAllowed: renewal?.counterOfferAllowed ?? false,
  };

  return (
    <Formik<RenewalsModalValues>
      initialValues={initialValues}
      onSubmit={submitModal}
      validationSchema={validationSchema}
      enableReinitialize
    >
      {({ handleSubmit, setFieldValue, values }) => (
        <Modal
          visible
          title="Renewal Terms"
          className="renewals-modal"
          onCancel={onCancel}
          closable
          maskClosable={false}
          width={1200}
          footer={
            <div className="footer">
              <div className="footer-actions">
                <Button className="cancel" onClick={onCancel}>
                  Cancel
                </Button>

                {showDeclineRenewal && renewal && (
                  <ModalFormButton
                    buttonText="Decline Renewal"
                    onSuccess={() => {
                      setFieldValue('submitType', 'decline-renewal');
                      handleSubmit();
                    }}
                    buttonClassName="danger"
                  >
                    {(props) => <DenyRenewalModal {...props} renewalId={renewal.id} />}
                  </ModalFormButton>
                )}
              </div>

              <div className="footer-actions">
                {showAllowCounterOffer && (
                  <Checkbox
                    className="allow-counter-offer"
                    checked={values.counterOfferAllowed}
                    onChange={(ev) => setFieldValue('counterOfferAllowed', ev.target.checked)}
                  >
                    Allow Counter-Offer
                  </Checkbox>
                )}

                {showSaveProposal && (
                  <Button
                    className="save-proposal"
                    type="primary"
                    disabled={values.options.length === 0}
                    onClick={() => {
                      setFieldValue('submitType', 'save-proposal');
                      handleSubmit();
                    }}
                  >
                    Save Proposal
                  </Button>
                )}

                {showSaveAndSendOffer && (
                  <Button
                    className="save-offer"
                    disabled={values.options.length === 0}
                    onClick={() => {
                      setFieldValue('submitType', 'save-and-send-offer');
                      handleSubmit();
                    }}
                  >
                    Save and Send Offer
                  </Button>
                )}
              </div>
            </div>
          }
        >
          {renewal && <RenewalsModalContent renewal={renewal} isLoading={isLoading} />}
        </Modal>
      )}
    </Formik>
  );
};

export default RenewalsModal;
