import type { NumRange } from '@/components/NumberRange';
import NumberRange from '@/components/NumberRange';
import type {
  FilterComponentType,
  FilterComponentTypeProps,
} from '@/notmagic/components/EntityTable/types';
import { numberRangeToQueryParam, queryParamToNumberRange } from '@/utils/utils';

type Props = FilterComponentTypeProps;

const Component: FilterComponentType<Props> = ({ value, onChangeValue, editable }) => {
  if (Array.isArray(value)) {
    // eslint-disable-next-line no-console
    console.error('ERROR: Filters of type NUMBER_RANGE only support a single value');
    return <></>;
  }

  const parsedValue = value ? queryParamToNumberRange(value) : undefined;

  if (!editable) {
    return <>{value}</>;
  }

  return (
    <NumberRange
      value={parsedValue ? (parsedValue as NumRange)! : [undefined, undefined]}
      onChange={(newValue: any) => {
        onChangeValue(newValue ? numberRangeToQueryParam(newValue as NumRange) : undefined);
      }}
    />
  );
};

export default {
  Component,
  jsonataQuery: (value: string, key: string) => {
    const parsedValue = queryParamToNumberRange(value);
    return (
      (parsedValue[0] !== undefined ? `$v.${key} >= ${parsedValue[0]}` : 'true') +
      ' and ' +
      (parsedValue[1] !== undefined ? `$v.${key} <= ${parsedValue[1]}` : 'true')
    );
  },
};
