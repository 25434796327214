import { ModalFormButton } from '@/components/Button/formButton';
import type { CommunityAccessInformation } from '@/domain/community';
import { commonAccessInformationPreview } from '@/pages/property/components/general';
import { Popover } from 'antd';
import { CommunityAccessInformationModal } from './components/CommunityAccessInformationModal';

export const getCommonAccessInformation = (
  propertyAccessInformation?: string,
  communityAccessInformation?: CommunityAccessInformation,
) => {
  if (!propertyAccessInformation && !communityAccessInformation) {
    return;
  }

  return (
    <div className="common-access-information">
      {propertyAccessInformation ? (
        <Popover
          content={() => commonAccessInformationPreview(propertyAccessInformation)}
          title="Common Access Information"
          placement="left"
        >
          <span className="text">{propertyAccessInformation}</span>
        </Popover>
      ) : (
        communityAccessInformation && (
          <ModalFormButton
            buttonType="link"
            buttonClassName="view-community-information"
            buttonText="View Community Access Information"
          >
            {(props) => (
              <CommunityAccessInformationModal
                {...props}
                communityAccessInformation={communityAccessInformation}
              />
            )}
          </ModalFormButton>
        )
      )}
    </div>
  );
};
