import type { Column, ColumnSettings } from '@/@propify-components';
import { useAppContext } from '@/contexts/AppContext';
import { Spin } from 'antd';
import type { FC } from 'react';
import { useMemo } from 'react';

interface Props {
  brandId: number | string | undefined;
}

const BrandColumn: FC<Props> = ({ brandId }) => {
  const { brands: data } = useAppContext();

  const result = useMemo(
    () =>
      data.length > 0 && typeof brandId === 'number'
        ? data.find((brand) => brand.id === brandId)?.name
        : '',
    [brandId, data],
  );

  if (!data.length) {
    return <Spin size="small" />;
  }

  return <>{typeof brandId === 'number' ? result : brandId}</>;
};

export const brandColumn = <T extends any = any>(
  column: Column<T>,
  settings?: ColumnSettings<T>,
): Column<T> => ({
  render: (data) => (
    <BrandColumn
      brandId={settings?.getValue ? settings.getValue(data) : data[column.key as keyof T]}
    />
  ),
  sortBy: !settings?.getValue ? column.key : undefined,
  sortFn: settings?.getValue
    ? (a, b) => (settings?.getValue?.(a) > settings?.getValue?.(b) ? 1 : -1)
    : undefined,
  getValueForExport: (row) =>
    settings?.getValue ? settings?.getValue(row) : row[column.key as keyof T] ?? '',
  ...column,
});

export default BrandColumn;
