import type { Column, ColumnSettings } from '@/@propify-components';
import { Tooltip } from 'antd';
import './styles.less';

export const popoverColumn = <T extends any = any>(
  column: Column<T>,
  settings?: ColumnSettings<T>,
): Column<T> => ({
  getValueForExport: (row) =>
    settings?.getValue ? settings?.getValue(row) : row[column.key as keyof T] ?? '',
  render: (row: T) => {
    const value = settings?.getValue ? settings?.getValue(row) : row[column.key as keyof T];
    return value ? (
      <Tooltip
        placement="leftTop"
        title={
          <div className="popover-tooltip-content">
            <div className="popover-text-wrapper">
              <div className="popover-text">{value}</div>
            </div>
          </div>
        }
        arrowPointAtCenter
        overlayClassName="popover-tooltip"
      >
        <span
          className="latest-popover-text"
          style={{ overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}
        >
          {value}
        </span>
      </Tooltip>
    ) : (
      value
    );
  },
  ...column,
});

export default popoverColumn;
