import type { ModalFormProps } from '@/components/Button/formButton';
import { enumColumn } from '@/components/TableUtils';
import type { RenewalApplication } from '@/domain/renewal';
import type { UnitApplicationApplication } from '@/domain/unit-applications';
import { settings } from '@/services/settings';
import { DeleteOutlined } from '@ant-design/icons';
import type { Column } from '@propify/components';
import { dateColumn, Table } from '@propify/components';
import { Button, Modal, Popconfirm } from 'antd';
import type { FC } from 'react';

const tableSettings = settings('applications-list-modal');

export type ApplicationType = RenewalApplication | UnitApplicationApplication;

interface Props extends ModalFormProps {
  type: 'renewal' | 'unitApplication';
  applications: ApplicationType[];
  onApplicationRemove: (applicationId: number) => void;
  title?: string;
  closeButtonText?: string;
}

const ApplicationsListModal: FC<Props> = ({
  type,
  applications,
  onApplicationRemove,
  onCancel,
  submitting,
  title = type === 'renewal' ? 'Outstanding Applications' : 'Co-Signer Applications',
  closeButtonText = 'Ok',
}) => {
  const columns: Column<ApplicationType>[] = [
    {
      key: 'emailAddress',
      title: 'Email',
      width: 175,
    },
    enumColumn(
      {
        key: 'type',
        title: 'Role',
        width: 150,
      },
      { enumName: 'application-type' },
    ),
    dateColumn({
      key: 'createTime',
      title: 'Date Invited',
      width: 120,
    }),
    {
      key: 'actions',
      title: 'Actions',
      width: 105,
      render: (row) => {
        return (
          <Popconfirm
            title="Are you sure you wish to rescind this invitation?"
            onConfirm={() => onApplicationRemove(row.id)}
            okText="Yes"
            cancelText="No"
            okButtonProps={{
              // @ts-ignore
              ['data-testid']: 'applications-list-modal-remove-confirm-button',
            }}
          >
            <Button
              data-testid="applications-list-modal-remove-button"
              icon={<DeleteOutlined />}
              type="link"
              danger
            />
          </Popconfirm>
        );
      },
    },
  ];

  return (
    <Modal
      wrapProps={{
        ['data-testid']: 'applications-list-modal',
      }}
      footer={[
        <Button data-testid="applications-list-modal-close-button" key="back" onClick={onCancel}>
          {closeButtonText}
        </Button>,
      ]}
      onCancel={onCancel}
      visible
      title={title}
      width={600}
    >
      <Table
        data-testid="applications-list-modal-table"
        loading={submitting}
        columns={columns}
        data={applications.filter((a) => a.type !== 'PRIMARY' && a.status === 'PENDING')}
        rowKeyExtractor={(row) => row?.id}
        settings={tableSettings}
      />
    </Modal>
  );
};

export default ApplicationsListModal;
