import type { Column } from '@/@propify-components';
import { Table } from '@/@propify-components';
import EmptyState from '@/components/EmptyState/EmptyState';
import type { Vehicle } from '@/domain/unit-applications';
import { settings } from '@/services/settings';
import type { CSSProperties, FC } from 'react';

interface Props {
  vehicles: Vehicle[];
  renderActions?: (v: Vehicle) => void;
  style?: CSSProperties;
}

const VehiclesInformation: FC<Props> = ({ vehicles, renderActions, style }) => {
  const columns: Column<Vehicle>[] = [
    {
      key: 'id',
      title: 'ID',
    },
    {
      key: 'make',
      title: 'Make',
    },
    {
      key: 'model',
      title: 'Model',
    },
    {
      key: 'year',
      title: 'Year',
    },
    {
      key: 'color',
      title: 'Color',
    },
    {
      key: 'licensePlate',
      title: 'License Plate',
    },
    {
      key: 'state',
      title: 'State',
    },
    ...(renderActions
      ? [
          {
            title: '',
            key: 'actions',
            width: 100,
            render: (vehicle: Vehicle) => renderActions?.(vehicle),
          },
        ]
      : []),
  ];

  if (vehicles.length === 0) {
    return <EmptyState description="No vehicles yet" />;
  }

  return (
    <Table
      data={vehicles}
      className="full-height"
      columns={columns}
      rowKeyExtractor={(row) => row.id}
      settings={settings('unit-application-vehicles-information')}
      style={style}
    />
  );
};

export default VehiclesInformation;
