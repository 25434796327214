import type { Column } from '@/@propify-components';
import { dateColumn, Table } from '@/@propify-components';
import { settings } from '@/@propify-components/Table/utils/settings';
import { ApplicationStatus, ApplicationType } from '@/domain/applications';
import type { RenewalApplicationSearchResult, RenewalDTO } from '@/domain/renewal';
import { RenewalApplicationStatus, RenewalStatus } from '@/domain/renewal';
import { RenewalService } from '@/services/renewal';
import { handleError } from '@/utils/utils';
import { Alert, Modal, Space } from 'antd';
import { useMemo, useState, type FC } from 'react';
import { Link } from 'react-router-dom';

interface Props {
  renewal: RenewalDTO;
  renewalApplications: RenewalApplicationSearchResult[];
  onClose: () => void;
  onRemove: () => void;
}

interface Row {
  id: number;
  personId: number;
  name: string;
  residentSince?: string;
}

const RemovePrimaryApplicantModal: FC<Props> = ({
  onClose,
  renewal,
  onRemove,
  renewalApplications,
}) => {
  const [selectedApplicant, setSelectedApplicant] = useState<string[]>([]);
  const [loading, setLoading] = useState(false);

  const primaryApplication = renewalApplications.find((a) => a.type === ApplicationType.PRIMARY);

  const data: Row[] = useMemo(
    () =>
      renewalApplications
        .filter(
          (renewalApplication) =>
            renewalApplication.type === ApplicationType.COSIGNER &&
            (!renewalApplication.status ||
              renewalApplication.status === RenewalApplicationStatus.ACCEPTED) &&
            (!renewalApplication.application ||
              renewalApplication.application?.status !== ApplicationStatus.DENIED),
        )
        .map((renewalApplication) => ({
          id: renewalApplication.id,
          personId: renewalApplication.person.id,
          name: `${renewalApplication.person.firstName} ${renewalApplication.person.lastName}`,
          residentSince: renewalApplication.residentSince,
        })),
    [renewalApplications],
  );

  if (!primaryApplication) {
    return null;
  }

  const primaryApplicantName = `${primaryApplication.person.firstName} ${primaryApplication.person.lastName}`;

  const columns: Column<Row>[] = [
    {
      key: 'name',
      title: 'Applicant',
      render: (row) => {
        return <Link to={`/person/${row.personId}`}>{row.name}</Link>;
      },
    },
    dateColumn({ key: 'residentSince', title: 'Resident Since' }, { defaultValue: '---' }),
  ];

  const handleRemovePrimaryApplicant = async () => {
    if (!selectedApplicant[0]) {
      return;
    }

    const applicationToMakePrimary = renewal.applications.find(
      (application) => application.id === Number(selectedApplicant[0]),
    );

    if (!applicationToMakePrimary) {
      return;
    }

    setLoading(true);

    try {
      await RenewalService.updateApplication(renewal.id, {
        ...applicationToMakePrimary,
        type: ApplicationType.PRIMARY,
      });
    } catch (err) {
      handleError(err, {
        toastFallbackMessage: 'There was an error updating the primary application',
      });
      setLoading(false);

      return;
    }

    try {
      await RenewalService.deleteApplication(renewal.id, Number(primaryApplication.id));
      onRemove();
    } catch (err) {
      handleError(err, {
        toastFallbackMessage: 'There was an error deleting the primary application',
      });

      onRemove();
    } finally {
      setLoading(false);
      onClose();
    }
  };

  return (
    <Modal
      visible
      title="Confirm Resident Removal"
      okText="Confirm Removal"
      okButtonProps={{ danger: true, disabled: !selectedApplicant.length, loading }}
      onOk={handleRemovePrimaryApplicant}
      onCancel={onClose}
    >
      <Space direction="vertical" size={18}>
        <Alert
          showIcon
          type="warning"
          message={
            <div>
              <strong>
                Are you sure you want to remove{' '}
                <Link to={`/person/${primaryApplication.person.id}`} target="_blank">
                  {primaryApplicantName}
                </Link>{' '}
                from this lease?
              </strong>
              <div>
                The individual being removed from this Renewal is the current Primary Resident.
                Please designate a new Primary Resident. All existing residents and prospective
                residents who have accepted their invitation to join the Renewal are listed bellow.
              </div>
            </div>
          }
        />
        {renewal?.status === RenewalStatus.COUNTERED ? (
          <Alert
            showIcon
            type="warning"
            message={
              <div>
                <strong>This Renewal is in a Countered status.</strong> Renewal Terms must be
                resubmitted before the applicants will be able to view and respond to the proposed
                terms (once all non-removed applicants are approved).
              </div>
            }
          />
        ) : null}

        <Table
          singleSelection
          selection={selectedApplicant}
          onSelectionChange={setSelectedApplicant}
          responsive={false}
          rowKeyExtractor={(row) => row.id.toString()}
          data={data}
          columns={columns}
          settings={settings('remove-primary-applicant')}
        />
      </Space>
    </Modal>
  );
};

export default RemovePrimaryApplicantModal;
