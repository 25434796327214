import { SHORT_DATE_FORMAT } from '@/utils/time';
import { EditOutlined } from '@ant-design/icons';
import { Button, DatePicker } from 'antd';
import type { FormikProps } from 'formik';
import { Formik } from 'formik';
import type { Moment } from 'moment';
import moment from 'moment';
import type { FC } from 'react';
import { useRef, useState } from 'react';
import type { FloatingInlineEditProps } from '.';
import FloatingEditableAttribute from '../FloatingEditableAttribute';

export interface FloatingInlineDateEditProps extends Omit<FloatingInlineEditProps, 'inputRender'> {
  initialValue?: string;
  format: string;
}

type Values = {
  field: string;
};

const FloatingInlineDateEdit: FC<FloatingInlineDateEditProps> = ({
  initialValue,
  displayValue,
  onConfirm,
}) => {
  const [editing, setEditing] = useState(false);
  const baseRef = useRef<HTMLDivElement>(null);
  const formRef = useRef<FormikProps<Values>>(null);

  const getFormattedValue = (value: string) => (value ? moment(value) : '');

  const submit = (values: Values) => {
    onConfirm(
      values.field,
      () => {
        setEditing(false);
      },
      () => {
        formRef.current?.setSubmitting(false);
      },
    );
  };

  return (
    <div ref={baseRef} className="floating-editing-container">
      {editing ? (
        baseRef.current && (
          <Formik<Values>
            innerRef={formRef}
            initialValues={{
              field: initialValue || '',
            }}
            onSubmit={submit}
          >
            {({ isSubmitting, handleSubmit, values, setFieldValue, isValid }) => (
              <FloatingEditableAttribute
                baseElement={baseRef.current!}
                loading={isSubmitting}
                onCancel={() => setEditing(false)}
                onConfirm={handleSubmit}
                disabled={!isValid}
              >
                <DatePicker
                  disabled={isSubmitting}
                  value={getFormattedValue(values.field) as Moment}
                  onChange={(newValue) => {
                    setFieldValue('field', newValue);
                  }}
                  style={{ width: '100%' }}
                  format={SHORT_DATE_FORMAT}
                />
              </FloatingEditableAttribute>
            )}
          </Formik>
        )
      ) : (
        <div className="display-value">
          <span>{displayValue}</span>

          <Button
            className="edit-button"
            icon={<EditOutlined />}
            type="link"
            size="small"
            onClick={() => setEditing(true)}
          />
        </div>
      )}
    </div>
  );
};

export default FloatingInlineDateEdit;
