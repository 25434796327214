import FloatingInlineDateEdit from '@/components/FloatingInlineEdit/FloatingInlineDateEdit';
import type {
  ColumnComponentType,
  ColumnComponentTypeProps,
} from '@/notmagic/components/EntityTable/types';
import { SHORT_DATE_FORMAT } from '@/utils/time';
import { handleError } from '@/utils/utils';
import moment from 'moment/moment';

type Props = ColumnComponentTypeProps<string>;

const Component: ColumnComponentType<Props> = ({ value, textIfNull, editable, update }) => {
  const displayValue = value ? moment(value).format(SHORT_DATE_FORMAT) : textIfNull;
  if (!editable) {
    return <>{displayValue}</>;
  }

  return (
    <FloatingInlineDateEdit
      displayValue={displayValue}
      initialValue={value}
      onConfirm={(newValue: string, done, fail) => {
        // FIXME: It sends a value like "2023-08-30T03:00:00.000Z", which works with task due date, but might not work
        //  with other local date attributes.
        update!(newValue)
          .then(done)
          .catch((error: Error) => {
            handleError(error, { displayToast: false });
            fail(error);
          });
      }}
      format={SHORT_DATE_FORMAT}
      // TODO add configuration to disable dates
    />
  );
};

export default {
  Component,
  sort: (a: string, b: string) => {
    if (!a && !b) {
      return 0;
    }
    if (!a) {
      return -1;
    }
    if (!b) {
      return 1;
    }
    return new Date(a).getTime() - new Date(b).getTime();
  },
  getValueForExport: (value: string) => (value ? moment(value).format(SHORT_DATE_FORMAT) : ''),
};
