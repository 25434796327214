import { ModalFormButton } from '@/components/Button/formButton';
import type { ApplicationFile } from '@/domain/applications';
import { ApplicationFileStatus } from '@/domain/applications';
import FilePreview from '@/pages/maintenance/components/FilePreview';
import { ApplicationsService } from '@/services/applications';
import { enumToLabel, handleError } from '@/utils/utils';
import { Button, Col, Image, message, Modal, Row } from 'antd';
import type { FC } from 'react';
import { useState } from 'react';
import type { RejectFileValues } from './RejectFileModal';
import RejectFileModal from './RejectFileModal';

interface Props {
  applicationId: number;
  file: ApplicationFile;
  onClose: () => void;
  onSuccess: () => void;
}

const ApproveRejectFileModal: FC<Props> = ({ file, onClose, onSuccess, applicationId }) => {
  const [updating, setUpdating] = useState<ApplicationFileStatus | undefined>(undefined);

  const updateFileStatus = (status: ApplicationFileStatus, values?: RejectFileValues) => {
    const reason: Partial<RejectFileValues> = {};

    if (values?.rejectionReasonType) {
      reason.rejectionReasonType = values.rejectionReasonType;
    }

    if (values?.rejectionReason?.length ?? 0 > 0) {
      reason.rejectionReason = values?.rejectionReason;
    }

    setUpdating(status);
    ApplicationsService.updateFile(applicationId, {
      ...file,
      status,
      ...reason,
    })
      .then(() => {
        message.success(
          `File ${status === ApplicationFileStatus.ACCEPTED ? 'approved' : 'rejected'}`,
        );
        onSuccess();
      })
      .catch((error) => {
        handleError(error, { toastFallbackMessage: 'There was an error processing your request' });
      })
      .finally(() => setUpdating(undefined));
  };

  return (
    <Modal
      visible
      title={enumToLabel(file.type)}
      onCancel={updating ? undefined : onClose}
      width={800}
      footer={[
        <Button type="default" key="close" onClick={onClose} disabled={!!updating}>
          Close
        </Button>,
        <ModalFormButton
          key="Reject"
          buttonText="Reject"
          buttonClassName="danger"
          onSuccess={(values: RejectFileValues) =>
            updateFileStatus(ApplicationFileStatus.REJECTED, values)
          }
          loading={updating === ApplicationFileStatus.REJECTED}
          disabled={!!updating}
        >
          {(props) => <RejectFileModal {...props} />}
        </ModalFormButton>,
        <Button
          key="Approve"
          onClick={() => updateFileStatus(ApplicationFileStatus.ACCEPTED)}
          disabled={!!updating}
          loading={updating === ApplicationFileStatus.ACCEPTED}
          className="green"
        >
          Approve
        </Button>,
      ]}
    >
      <Row align="middle" gutter={20} wrap={false}>
        <Col flex="auto">
          {file.thumbnailUrl ? (
            <Image height={120} preview={false} src={file.url} alt={file.description} />
          ) : (
            <FilePreview file={file} />
          )}
        </Col>
      </Row>
    </Modal>
  );
};

export default ApproveRejectFileModal;
