import type { Column } from '@/@propify-components';
import { Align, booleanColumn, currencyColumn, dateColumn } from '@/@propify-components';
import ExternalLink from '@/components/ExternalLink';
import { enumColumn } from '@/components/TableUtils';
import { brandColumn } from '@/components/TableUtils/BrandColumn';
import { useAppContext } from '@/contexts/AppContext';
import type { CustomField } from '@/domain/custom-fields';
import type { PropertySearchResult } from '@/domain/property';
import { usePropertyGroups } from '@/hooks/services/usePropertyGroups';
import useVentures from '@/hooks/useVentures';
import { getCommonAccessInformation } from '@/pages/communities/utils';
import { FundsService } from '@/services/funds';
import { HoldingCompaniesService } from '@/services/holding-companies';
import { PetPolicyService } from '@/services/pet-policy';
import { customFieldColumns } from '@/utils/custom-fields';
import { useFetch } from '@/utils/request';
import { Popover } from 'antd';
import { useMemo } from 'react';
import { Link } from 'react-router-dom';

type PropertyColumnKey =
  | 'address.addr1'
  | 'address.city'
  | 'address.state'
  | 'msa'
  | 'type'
  | 'hasLeaseDocumentTemplates'
  | 'transactionStatus'
  | 'ventureId'
  | 'yearBuilt'
  | 'brandId'
  | 'holdingCompanyId'
  | 'community'
  | 'commonDoorLockCode'
  | 'petPolicy'
  | 'valuationAmount'
  | 'valuationDate'
  | 'valuationSource'
  | 'propertyGroups'
  | 'acquisitionDate';

interface PropertiesTableColumn extends Column<PropertySearchResult> {
  key: PropertyColumnKey;
}

interface Params {
  customFields?: CustomField[];
  columns?: PropertyColumnKey[];
  propertyLinkOnNewTab?: boolean;
}

export const usePropertiesTableColumns = ({
  customFields,
  columns,
  propertyLinkOnNewTab,
}: Params): {
  columns: PropertiesTableColumn[];
  loading: boolean;
  refetchPropertyGroups: () => void;
} => {
  const { ventureNames } = useVentures();
  const { brands } = useAppContext();

  const { data: funds, isValidating: loadingFunds } = useFetch({
    fetcher: FundsService.search,
    errorMessage: 'There was a error getting funds',
  });

  const {
    data: propertyGroups,
    isValidating: loadingPropertyGroups,
    mutate: refetchPropertyGroups,
  } = usePropertyGroups();

  const ventureIdByFundId = useMemo(
    () => (funds || []).reduce((map, fund) => ({ ...map, [fund.id]: fund.ventureId }), {}),
    [funds],
  );

  const getCommunityInfo = (property: PropertySearchResult) => {
    return (
      property.community?.accessInformationList?.find((i) => i.primary) ||
      property.community?.accessInformationList?.[0]
    );
  };

  const { data: holdingCompanies, isValidating: loadingHoldingCompanies } = useFetch({
    fetcher: HoldingCompaniesService.search,
    errorMessage: 'There was a error getting holding companies',
  });

  const { data: petPolicies = [], isValidating: loadingPetPolicies } = useFetch({
    fetcher: PetPolicyService.search,
    errorMessage: 'There was a error getting the pet policies',
  });

  const defaultColumns: PropertiesTableColumn[] = useMemo(
    () => [
      {
        title: 'Address',
        key: 'address.addr1',
        width: 300,
        render: (row) =>
          propertyLinkOnNewTab ? (
            <ExternalLink href={`/properties/${row.id}`}>{row.address?.addr1}</ExternalLink>
          ) : (
            <Link to={`/properties/${row.id}`}>{row.address?.addr1}</Link>
          ),
      },
      {
        title: 'City',
        key: 'address.city',
        width: 200,
        render: (row) => row.address?.city,
      },
      {
        title: 'State',
        key: 'address.state',
        width: 100,
        render: (row) => row.address?.state,
      },
      {
        title: 'MSA',
        key: 'msa',
        sortBy: 'msa',
      },
      {
        ...enumColumn({ title: 'Type', key: 'type', width: 150 }, { enumName: 'property-type' }),
        key: 'type',
      },
      {
        ...enumColumn(
          { title: 'Status', key: 'transactionStatus', width: 100 },
          { enumName: 'transaction-status' },
        ),
        key: 'transactionStatus',
      },
      {
        ...booleanColumn({ key: 'hasLeaseDocumentTemplates', title: 'Lease Template?' }),
        key: 'hasLeaseDocumentTemplates',
      },
      {
        title: 'Venture',
        key: 'ventureId',
        width: 150,
        hideSort: true,
        render: (property) => ventureNames[ventureIdByFundId[property.fundId || 0] || 0] ?? '',
      },
      {
        key: 'propertyGroups',
        title: 'Property Groups',
        hideSort: true,
        render: (row) => {
          const rowPropertyGroups = propertyGroups?.filter((propertyGroup) =>
            row.propertyGroupIds?.includes(propertyGroup.id),
          );
          return (
            <Popover
              content={
                <div className="propertyGroupPopoverList">
                  {rowPropertyGroups?.map((propertyGroup) => (
                    <Link
                      key={propertyGroup?.id}
                      target="_blank"
                      to={`/properties/property-groups/${propertyGroup?.id}`}
                    >
                      {propertyGroup?.id} - {propertyGroup?.name}
                    </Link>
                  ))}
                </div>
              }
              placement="bottomLeft"
              trigger="hover"
            >
              {rowPropertyGroups?.map((propertyGroup) => propertyGroup?.name)?.join(', ')}
            </Popover>
          );
        },
      },
      {
        title: 'Year Built',
        key: 'yearBuilt',
        width: 100,
        align: Align.right,
      },
      {
        ...brandColumn(
          { title: 'Brand', key: 'brandId' },
          { getValue: (row) => brands.find((b) => b.id === row.brandId)?.name },
        ),
        key: 'brandId',
      },
      {
        title: 'HoldCo',
        key: 'holdingCompanyId',
        width: 200,
        render: (p) =>
          p.holdingCompanyId && holdingCompanies?.find((hc) => hc.id === p.holdingCompanyId)?.name,
      },
      {
        title: 'Community',
        key: 'community',
        width: 200,
        render: (p) =>
          p.community && (
            <ExternalLink href={`/properties/communities/${p.community.id}`}>
              {p.community.name}
            </ExternalLink>
          ),
      },
      {
        title: 'Common Access Information',
        key: 'commonDoorLockCode',
        width: 200,
        getValueForExport: (property) => property.commonDoorLockCode,
        render: (property) =>
          getCommonAccessInformation(property.commonDoorLockCode, getCommunityInfo(property)),
      },
      {
        title: 'Pet Policy',
        key: 'petPolicy',
        width: 200,
        render: (property) =>
          petPolicies.find((policy) => policy.id === property.petPolicyId)?.name ?? '',
      },
      {
        ...currencyColumn(
          {
            title: 'Valuation Amount',
            key: 'valuationAmount',
            width: 165,
          },
          { getValue: (property: PropertySearchResult) => property.valuation?.amount },
        ),
        key: 'valuationAmount',
      },
      {
        ...dateColumn(
          { title: 'Valuation Date', key: 'valuationDate', width: 140 },
          { getValue: (property: PropertySearchResult) => property.valuation?.date },
        ),
        key: 'valuationDate',
      },
      {
        ...dateColumn({ title: 'Acquisition Date', key: 'acquisitionDate', width: 140 }),
        key: 'acquisitionDate',
      },
      {
        title: 'Valuation Source',
        key: 'valuationSource',
        render: (property) => property.valuation?.source,
      },
      ...(customFields ? (customFieldColumns(customFields) as PropertiesTableColumn[]) : []),
    ],
    [
      brands,
      customFields,
      holdingCompanies,
      petPolicies,
      propertyLinkOnNewTab,
      ventureNames,
      ventureIdByFundId,
      propertyGroups,
    ],
  );

  const filteredColumns = columns
    ? defaultColumns.filter((column) => columns.includes(column.key))
    : defaultColumns;

  return {
    columns: filteredColumns,
    loading: loadingPetPolicies || loadingFunds || loadingHoldingCompanies || loadingPropertyGroups,
    refetchPropertyGroups: refetchPropertyGroups,
  };
};
