import type { LatLong } from '@/domain/base';
import type { Point } from '@/utils/map';
import { averageGeolocation } from '@/utils/map';
import type { Maps } from 'google-map-react';
import GoogleMapReact from 'google-map-react';
import isEmpty from 'lodash/isEmpty';
import type { FC } from 'react';
import { useEffect, useMemo, useState } from 'react';
import Supercluster from 'supercluster';
import Geotag, { TAG_SIZE } from './Geotag';

type Props = {
  points: Point[];
  zoom?: number;
  center?: [number, number];
  activePlace?: ActivePlace;
  polygonCoords?: LatLong[];
  isRoadmap?: boolean;
  options?: Record<string, string | number | boolean>;
};

type Center = {
  latitude: number;
  longitude: number;
};

const apiKey = process.env.GMAPS_API_KEY as string;
const MAX_ZOOM = 15;

export type ActivePlace = {
  index: number;
  source: 'geotag' | 'unit-list';
};

const Map: FC<Props> = ({ points = [], zoom = 10, polygonCoords, options, activePlace }) => {
  const [mapData, setMapData] = useState<Record<string, any>>({});
  const [center, setCenter] = useState<Center>({ longitude: 0, latitude: 0 });
  const [reCentering, setReCentering] = useState(false);
  const [map, setMap] = useState<any>();
  const [maps, setMaps] = useState<any>();
  const [polygon, setPolygon] = useState<any>();
  const [currentActivePlace, setCurrentActivePlace] = useState(activePlace);

  const renderPolygon = () => {
    if (polygonCoords && maps && map) {
      const bounds = new maps.LatLngBounds();
      const triangleCoords = polygonCoords.map((coord: any) => {
        const googleCoord = {
          lat: coord.latitude,
          lng: coord.longitude,
        };
        bounds.extend(googleCoord);
        return googleCoord;
      });

      if (polygon) {
        polygon.setMap(null);
      }

      setPolygon(
        new maps.Polygon({
          paths: triangleCoords,
          strokeColor: '#49b0e4',
          strokeOpacity: 0.8,
          strokeWeight: 2,
          fillColor: '#49b0e4',
          fillOpacity: 0.35,
        }),
      );

      polygon.setMap(map);
      map.fitBounds(bounds);
    }
  };

  const setupMap = () => {
    if (!map || !maps || isEmpty(points)) {
      return;
    }

    const bounds = new maps.LatLngBounds();

    points
      .filter((point) => point.id !== 'current-location')
      .forEach(({ latitude, longitude }) => {
        const location = new maps.LatLng(latitude, longitude);
        bounds.extend(location);
      });

    map.fitBounds(bounds);
    map.panToBounds(bounds);

    map.addListener('center_changed', () => setReCentering(true));
    map.addListener('idle', () => setReCentering(false));
  };

  useEffect(() => {
    if (!isEmpty(points)) {
      setCenter(averageGeolocation(points));
      renderPolygon();
      setupMap();
    }
  }, [points]);

  useEffect(() => {
    return () => {
      if (maps && map) {
        maps.event.clearInstanceListeners(map);
      }
      setMapData({});
    };
  }, [maps, map]);

  const handleApiLoaded = (apiMaps: any) => {
    setMap(apiMaps.map);
    apiMaps.map.setOptions({ maxZoom: MAX_ZOOM });
    setMaps(apiMaps.maps);
    setupMap();
    renderPolygon();
  };

  const fitCluster = (cluster: { children: Supercluster.PointFeature<any>[] }) => () => {
    const clusterPoints = cluster.children.map((point) => point.properties.point);
    const bounds = new maps.LatLngBounds();

    clusterPoints.forEach(({ latitude, longitude }) => {
      const location = new maps.LatLng(latitude, longitude);
      bounds.extend(location);
    });

    map.fitBounds(bounds);
    map.panToBounds(bounds);
  };

  const getOptions = (googleMaps: Maps) => ({
    gestureHandling: 'greedy',
    mapTypeControl: true,
    streetViewControl: false,
    mapTypeControlOptions: {
      style: googleMaps.MapTypeControlStyle.HORIZONTAL_BAR,
      position: googleMaps.ControlPosition.BOTTOM_CENTER,
      mapTypeIds: [
        googleMaps.MapTypeId.ROADMAP,
        googleMaps.MapTypeId.SATELLITE,
        googleMaps.MapTypeId.HYBRID,
      ],
    },
    ...options,
  });

  const handleChange = ({ zoom: currentZoom, bounds }: any) => {
    const mapBounds = map?.getBounds();

    setMapData({
      zoom: currentZoom,
      bounds: mapBounds,
      mapBoxBounds: [bounds.nw.lng, bounds.se.lat, bounds.se.lng, bounds.nw.lat],
    });
  };

  const clusters = useMemo(() => {
    const zoomInt = Math.round(mapData?.zoom) || 10;
    const bounds = mapData?.mapBoxBounds;

    const clusterPoints: Supercluster.PointFeature<{}>[] = points.map((point) => ({
      id: point.id,
      type: 'Feature',
      properties: {
        cluster: false,
        point,
      },
      geometry: {
        type: 'Point',
        coordinates: [point.longitude, point.latitude],
      },
    }));

    const supercluster = new Supercluster({ radius: 75, maxZoom: MAX_ZOOM });
    supercluster.load(clusterPoints);

    const activePoint = points.find((point) => point.id === currentActivePlace?.index);

    return bounds
      ? supercluster.getClusters(bounds, zoomInt).map((cluster) => {
          const clusterId = cluster.properties.cluster_id;
          const children = clusterId
            ? supercluster.getLeaves(clusterId, Number.MAX_SAFE_INTEGER)
            : [];
          const showPopup = children.find(
            (point) => activePoint?.id && point?.properties?.point?.id === activePoint.id,
          );

          return {
            ...cluster,
            open: showPopup && !reCentering,
            render: showPopup ? activePoint?.render : undefined,
            children,
          };
        })
      : clusterPoints;
  }, [currentActivePlace?.index, mapData?.mapBoxBounds, mapData?.zoom, points, reCentering]);

  if (isEmpty(points)) {
    return null;
  }

  return (
    <GoogleMapReact
      bootstrapURLKeys={{ key: apiKey }}
      center={{
        lat: center.latitude,
        lng: center.longitude,
      }}
      yesIWantToUseGoogleMapApiInternals
      onGoogleApiLoaded={handleApiLoaded}
      zoom={zoom}
      hoverDistance={TAG_SIZE / 2}
      options={getOptions}
      onChange={handleChange}
      resetBoundsOnResize={false}
    >
      {clusters.map((cluster: any, index) => {
        const { point } = cluster.properties;
        const [longitude, latitude] = cluster.geometry.coordinates;
        const clusterId = cluster.properties.cluster_id;
        const pointCount = cluster.properties.point_count;
        const isCluster = cluster.properties.cluster;

        if (isCluster) {
          return (
            <Geotag
              {...point}
              index={index}
              lat={latitude}
              lng={longitude}
              // eslint-disable-next-line react/no-array-index-key
              key={`${latitude},${longitude},${clusterId},${index}`}
              content={pointCount}
              open={cluster.open}
              isCluster
              onClick={fitCluster(cluster)}
            >
              {typeof cluster.render === 'function' ? cluster.render() : null}
            </Geotag>
          );
        }

        if (point) {
          return (
            // <Dropdown overlay={<div>:D</div>} placement="bottomLeft" arrow>
            <Geotag
              {...point}
              index={point.id}
              lat={point.latitude}
              lng={point.longitude}
              // eslint-disable-next-line react/no-array-index-key
              key={`${point.latitude},${point.longitude},${point.key},${index}`}
              open={activePlace?.index === point.id && !reCentering}
              onActivePlace={setCurrentActivePlace}
              onInactivePlace={() => setCurrentActivePlace(undefined)}
            >
              {typeof point.render === 'function' ? point.render() : null}
            </Geotag>
            // </Dropdown>
          );
        }

        return null;
      })}
    </GoogleMapReact>
  );
};

export default Map;
