import { AntDatePicker, AntSelect, AntTextArea } from '@/components/Formik/CreateAntField';
import SectionSplashScreen from '@/components/PageLoading/SplashSection';
import { useAppContext } from '@/contexts/AppContext';
import type { Lookup } from '@/domain/base';
import type { TicketReason } from '@/domain/ticket';
import { getTicketTypeLabel, TicketType } from '@/domain/ticket';
import type { SubSkill, TicketGroup } from '@/domain/ticket-groups';
import useEnumeration from '@/hooks/useEnumeration';
import { TicketGroupService } from '@/services/ticket-groups';
import { TicketService } from '@/services/tickets';
import { useFetch } from '@/utils/request';
import { SHORT_DATE_FORMAT } from '@/utils/time';
import { filterSelectOptions } from '@/utils/utils';
import { Button, Drawer, Form } from 'antd';
import type { FormikProps } from 'formik';
import { Field, Formik } from 'formik';
import type { Moment } from 'moment';
import moment from 'moment';
import type { FC, ReactElement } from 'react';
import { useEffect, useMemo, useRef } from 'react';
import * as Yup from 'yup';

export type CollectionTicketFormValues = {
  dueTime: string;
  type?: TicketType;
  ticketGroupId?: number;
  subskillId?: number;
  reasonId?: number;
  reasonDescription?: string;
  priority: string;
  preferredUserId?: number;
};

type CollectionTicketValues = Pick<
  CollectionTicketFormValues,
  'dueTime' | 'reasonDescription' | 'subskillId' | 'ticketGroupId' | 'priority' | 'preferredUserId'
> & {
  type: TicketType;
  reason?: string;
};

type Props = {
  onConfirm: (values: CollectionTicketValues) => void;
  onCancel: () => void;
  saving: boolean;
  subSkill?: string;
  type?: TicketType;
};

const validationSchema = Yup.object({
  dueTime: Yup.string().required('A due date is required'),
  type: Yup.string().nullable().required('The ticket type is required'),
  ticketGroupId: Yup.number(),
  subskillId: Yup.number(),
  priority: Yup.string().required('Priority is required'),
  preferredUserId: Yup.number(),
});

type Option = { value: string | number; label: string };

const BulkCreateTicketsForm: FC<{
  formProps: FormikProps<CollectionTicketFormValues>;
  ticketGroups: TicketGroup[];
  subSkills: SubSkill[];
  ticketReasons: TicketReason[];
  userList: Lookup[];
  saving: boolean;
  subSkill?: string;
  type?: TicketType;
}> = ({ formProps, ticketGroups, subSkills, ticketReasons, userList, subSkill, type }) => {
  const { handleSubmit, submitCount, setFieldValue, values } = formProps;

  const { data: ticketPriorities } = useEnumeration('ticket-priority');
  const ticketGroupOptions = useMemo<Option[]>(
    () => [
      { value: '', label: '' },
      ...ticketGroups.map((tg) => ({
        value: tg.id,
        label: tg.name,
      })),
    ],
    [ticketGroups],
  );

  const subSkillsOptions = useMemo<Option[]>(
    () => [
      { value: '', label: '' },
      ...subSkills
        .filter((ss) => ss.ticketGroupId === values.ticketGroupId)
        .map((ss) => ({
          value: ss.id,
          label: ss.displayName,
        })),
    ],
    [values.ticketGroupId, subSkills],
  );

  const reasonOptions = useMemo<Option[]>(
    () => [
      { value: '', label: '' },
      ...ticketReasons
        .filter((tr) => tr.ticketGroupId === values.ticketGroupId)
        .map((ss) => ({
          value: ss.id,
          label: ss.reason,
        })),
    ],
    [values.ticketGroupId, ticketReasons],
  );

  const sortedUsersOptions = useMemo(
    () =>
      userList
        .map((user) => ({
          label: user.displayName,
          value: user.id,
        }))
        .sort((a, b) => (a.label > b.label ? 1 : -1)),
    [userList],
  );

  useEffect(() => {
    if (reasonOptions.length > 0 && !reasonOptions.some((opt) => opt.value === values.reasonId)) {
      setFieldValue('reasonId', undefined);
    }
  }, [reasonOptions, values.reasonId]);

  return (
    <Form className="form-container" onSubmitCapture={handleSubmit}>
      <Field
        component={AntDatePicker}
        label="Due Date"
        name="dueTime"
        hasFeedback
        submitCount={submitCount}
        format={SHORT_DATE_FORMAT}
        style={{ width: '100%' }}
        disabledDate={(date: Moment) => date.isBefore(moment().startOf('day'))}
      />

      <Field
        label="Ticket Type"
        formItemStyle={{ display: 'block' }}
        component={AntSelect}
        options={[
          {
            label: getTicketTypeLabel(type || TicketType.AGENT_CREATED),
            value: type || TicketType.AGENT_CREATED,
          },
        ]}
        name="type"
        disabled
        submitCount={submitCount}
        hasFeedback
      />

      <Field
        label="Ticket Group"
        formItemStyle={{ display: 'block' }}
        component={AntSelect}
        options={ticketGroupOptions}
        name="ticketGroupId"
        submitCount={submitCount}
        hasFeedback
        disabled={subSkill === 'COLLECTIONS' || subSkill === 'APPLICATION_PROCESSING'}
      />

      <Field
        label="Sub-skill"
        formItemStyle={{ display: 'block' }}
        component={AntSelect}
        options={subSkillsOptions}
        name="subskillId"
        submitCount={submitCount}
        hasFeedback
        disabled={
          !values.ticketGroupId ||
          subSkill === 'COLLECTIONS' ||
          subSkill === 'APPLICATION_PROCESSING'
        }
      />

      <Field
        label="Priority"
        formItemStyle={{ display: 'block' }}
        component={AntSelect}
        options={ticketPriorities.map((priority) => ({
          label: priority.displayName,
          value: priority.value,
        }))}
        name="priority"
        submitCount={submitCount}
        hasFeedback
      />

      <Field
        label="Preferred Agent"
        formItemStyle={{ display: 'block' }}
        component={AntSelect}
        options={sortedUsersOptions}
        allowClear
        showSearch
        filterOption={filterSelectOptions('label')}
        name="preferredUserId"
        submitCount={submitCount}
        hasFeedback
      />

      <Field
        label="Reason"
        formItemStyle={{ display: 'block' }}
        component={AntSelect}
        options={reasonOptions}
        name="reasonId"
        submitCount={submitCount}
        hasFeedback
        disabled={!values.ticketGroupId}
        showSearch
        filterOption={filterSelectOptions('label')}
      />

      <Field
        label="Reason Description"
        formItemStyle={{ display: 'block' }}
        style={{ minHeight: '100px' }}
        component={AntTextArea}
        type="text"
        name="reasonDescription"
        submitCount={submitCount}
        hasFeedback
      />
    </Form>
  );
};

const BulkCreateTickets: FC<Props> = ({
  saving,
  onCancel,
  onConfirm,
  subSkill,
  type,
}): ReactElement => {
  const formRef = useRef<FormikProps<CollectionTicketFormValues>>(null);
  const { agents, ticketGroups } = useAppContext();
  const { data: ticketPriorities } = useEnumeration('ticket-priority');

  const { data: subSkills = [], isValidating: loadingSubSkills } = useFetch({
    fetcher: TicketGroupService.getSubSkills,
    errorMessage: 'There was an error loading the sub-skills list',
  });

  const { data: ticketReasons = [], isValidating: loadingTicketReasons } = useFetch({
    fetcher: TicketService.getReasons,
    errorMessage: 'There was an error loading the ticket reasons',
  });

  const defaultSubSkill = useMemo(
    () => (subSkill ? subSkills.find((ss) => ss.name === subSkill) : undefined),
    [subSkill, subSkills],
  );

  const handleOnSubmit = (values: CollectionTicketFormValues) => {
    if (!saving) {
      const result: CollectionTicketValues = {
        dueTime: moment(values.dueTime).endOf('day').format(),
        type: values.type!,
        reason: ticketReasons.find((tr) => tr.id === values.reasonId)?.reason ?? '',
        reasonDescription: values.reasonDescription,
        ticketGroupId: values.ticketGroupId,
        subskillId: values.subskillId,
        priority: values.priority,
        preferredUserId: values.preferredUserId || undefined,
      };

      onConfirm(result);
    }
  };

  const loading = loadingSubSkills || !ticketGroups.length || loadingTicketReasons;

  return (
    <Formik<CollectionTicketFormValues>
      innerRef={formRef}
      onSubmit={handleOnSubmit}
      validationSchema={validationSchema}
      enableReinitialize
      initialValues={{
        dueTime: '',
        type: type || TicketType.AGENT_CREATED,
        priority: ticketPriorities[0]?.value,
        reasonDescription: '',
        subskillId: defaultSubSkill?.id || undefined,
        ticketGroupId: defaultSubSkill?.ticketGroupId || undefined,
        preferredUserId: undefined,
      }}
    >
      {(formProps) => (
        <Drawer
          title="Create Ticket"
          className="createTicketDrawer"
          placement="right"
          visible
          width={350}
          onClose={onCancel}
          footer={
            <div style={{ textAlign: 'right' }}>
              <Form.Item>
                <Button
                  loading={saving}
                  type="primary"
                  onClick={() => formRef.current?.submitForm()}
                >
                  Save
                </Button>
              </Form.Item>
            </div>
          }
        >
          {loading ? (
            <SectionSplashScreen />
          ) : (
            <BulkCreateTicketsForm
              formProps={formProps}
              saving={saving}
              subSkills={subSkills}
              ticketGroups={ticketGroups}
              ticketReasons={ticketReasons}
              userList={agents}
              subSkill={subSkill}
              type={type}
            />
          )}
        </Drawer>
      )}
    </Formik>
  );
};

export default BulkCreateTickets;
