import { useEntityTableContext } from '@/notmagic/Context';
import type { ParsedQuery } from 'query-string';
import { useEffect, useMemo, useState } from 'react';
import type { FilterJsonataQueryFn, TableFilter } from '../types';
import { useJsonata } from './useJsonata';

export const keywordsFilter = (keywords: string[], value: any) => {
  const tableKeywords = keywords.map((keyword) => `$string($v.${keyword})`).join(', ');

  const parsedValue = `${value}`
    .replaceAll("'", '\\u0027')
    .replaceAll('"', '\\u0022')
    .toLowerCase();

  return `$contains(
    $lowercase(
      $join(
        [${tableKeywords}],
        ' '
      )
    ),
    '${parsedValue}'
  )`;
};

export const useFilteredData = (
  query: ParsedQuery,
  keywords?: string[],
  filters?: TableFilter[],
  unfilteredData?: any[],
) => {
  const [filteredData, setFilteredData] = useState<NonNullable<typeof unfilteredData>>([]);
  const { filterTypes } = useEntityTableContext();
  const [filtering, setFiltering] = useState(false);

  const [runJsonata] = useJsonata<typeof unfilteredData, typeof filteredData>((result) => {
    setFiltering(false);
    setFilteredData(result);
  });

  const expressions = useMemo<Record<string, FilterJsonataQueryFn>>(
    () =>
      (filters || []).reduce(
        (obj, filter) => ({
          ...obj,
          [filter.type]: filterTypes[filter.type].jsonataQuery,
        }),
        {},
      ),
    [filters, filterTypes],
  );

  useEffect(() => {
    if (unfilteredData) {
      const filterExpression =
        Object.entries(query)
          .map(([key, value]) => {
            if (key === 'keywords' && keywords) {
              return keywordsFilter(keywords, value);
            }

            if (value != null) {
              const filter = filters?.find((each) => each.key === key);

              if (!filter) {
                // eslint-disable-next-line no-console
                console.error(`Invalid filter type '${key}'`);
                return 'true';
              }

              return expressions[filter!.type](value, key);
            }

            return 'true';
          })
          .filter((e) => e !== 'true')
          .join(' and ') || 'true';

      const expression = `[
        $filter(
          $,
          function($v, $i, $a) { ${filterExpression} }
        )
      ]`;

      setFiltering(true);
      runJsonata({
        action: 'filter',
        data: unfilteredData,
        expression,
      });
    }
  }, [expressions, unfilteredData, query, filters, runJsonata]);

  return { filteredData, filtering };
};
