import { ModalFormButton } from '@/components/Button/formButton';
import CancelRenewalModal from '@/components/CancelRenewalModal/CancelRenewalModal';
import type { UpdateRenewalDTO } from '@/domain/renewal';
import { RenewalStatus } from '@/domain/renewal';
import type {
  ColumnComponentType,
  ColumnComponentTypeProps,
} from '@/notmagic/components/EntityTable/types';
import DenyRenewalModal from '@/pages/prospects/renewals/components/DenyRenewalModal';
import { RenewalService } from '@/services/renewal';
import { handleError } from '@/utils/utils';
import { Button, message } from 'antd';
import { useMemo } from 'react';

type ActionType = 'APPROVE' | 'DENY' | 'CANCEL';

type RowType = {
  row: UpdateRenewalDTO;
  actions: ActionType[];
};

type Props = ColumnComponentTypeProps<RowType>;

const Component: ColumnComponentType<Props> = ({ value, refreshEntities }: Props) => {
  const [row, showApprove, showDeny, showCancel] = useMemo(() => {
    if (!value || !value.row) {
      return [];
    }

    const { row: _row, actions = [] } = value;

    return [
      _row,
      actions.includes('APPROVE'),
      actions.includes('DENY'),
      actions.includes('CANCEL'),
    ];
  }, [value]);

  if (!row) {
    return null;
  }

  const onApprove = () => {
    if (row.status !== RenewalStatus.APPROVED) {
      RenewalService.update({
        ...row,
        status: RenewalStatus.APPROVED,
      })
        .then(() => {
          refreshEntities?.();
          message.success('Renewal successfully updated');
        })
        .catch((error) => {
          handleError(error, { toastFallbackMessage: 'Something went wrong updating the renewal' });
        });
    }
  };

  const onDeny = () => refreshEntities?.();

  const onCancel = () => refreshEntities?.();

  return (
    <>
      {showApprove ? (
        <Button
          size="small"
          onClick={() => onApprove()}
          style={{ marginRight: 5 }}
          className="green"
        >
          Approve
        </Button>
      ) : null}

      {showDeny ? (
        <ModalFormButton
          buttonText="Deny"
          onSuccess={onDeny}
          buttonClassName="danger"
          buttonStyle={{ marginRight: 5 }}
        >
          {(props) => <DenyRenewalModal {...props} renewalId={row.id} />}
        </ModalFormButton>
      ) : null}

      {showCancel ? (
        <ModalFormButton buttonText="Cancel" onSuccess={onCancel} buttonClassName="danger">
          {(props) => <CancelRenewalModal {...props} renewalId={row.id} />}
        </ModalFormButton>
      ) : null}
    </>
  );
};

export default {
  Component,
  getValueForExport: () => '',
};
