import { PropifyCRMTabs } from '@/components/PropifyCRM/utils';
import type { Ticket, TicketSearchResult } from '@/domain/ticket';
import { TicketStatus, TicketType } from '@/domain/ticket';
import type { TicketGroup } from '@/domain/ticket-groups';
import type { UserLookup } from '@/domain/user';
import type { TicketSearchParams } from '@/services/tickets';
import { TicketService } from '@/services/tickets';
import { enumToLabel, isDateInRange, queryParamToDateRange } from '@/utils/utils';
import pickBy from 'lodash/pickBy';
import toNumber from 'lodash/toNumber';
import moment from 'moment';
import queryString from 'query-string';

export const CLAIMED_TICKETS_LIMIT = 2;

const checkSubSkill = (ticket: Ticket, subSkill: string | null | undefined) =>
  subSkill === 'general'
    ? ticket.subskillId === undefined
    : ticket.subskillId?.toString() === subSkill?.toString();

export const checkGroup = (ticket: Ticket, group: string) => {
  return group === 'uncategorized'
    ? ticket.ticketGroupId === undefined
    : ticket.ticketGroupId === parseInt(group, 10);
};

export const getGroupName = (ticketGroups: TicketGroup[], id: number | string) => {
  if (id === 'uncategorized') {
    return 'Uncategorized';
  }

  if (id === 'my-tickets') {
    return 'My Tickets';
  }

  if (id === 'all-tickets') {
    return 'All Tickets';
  }

  const nId = toNumber(id);
  return ticketGroups.find((tg) => tg.id === nId)?.name ?? 'Unknown';
};

const checkOpenStatus = (ticket: Ticket) => ticket.status === TicketStatus.OPEN;

const isClaimExpired = (ticket: Ticket) => {
  if (ticket.ticketClaimExpirationTime) {
    return moment.utc(ticket.ticketClaimExpirationTime).isBefore(moment().utc());
  }

  return false;
};

const getUniqueClaimsIds = (tickets: TicketSearchResult[]) => {
  const uniquePersonIds = new Set<number>();
  const uniqueContacts = new Set<string>();

  tickets.forEach((ticket) => {
    if (ticket.ticketClaimExpirationTime && !isClaimExpired(ticket)) {
      if (ticket.partyId) {
        uniquePersonIds.add(ticket.partyId);
      } else if (ticket.contact) {
        uniqueContacts.add(ticket.contact);
      }
    }
  });

  return { uniquePersonIds, uniqueContacts };
};

export const filterTicketResults = (
  tickets: TicketSearchResult[],
  subSkill: string | undefined,
  group: string,
) =>
  tickets.filter(
    (ticket) =>
      checkSubSkill(ticket, subSkill) && checkOpenStatus(ticket) && checkGroup(ticket, group),
  );

export const getUserNameById = (
  userId: number | undefined,
  globalUsers: UserLookup[],
  field: keyof UserLookup = 'id',
): string => {
  if (!userId) {
    return 'N/A';
  }

  const user = globalUsers.find((u) => u[field] === userId);
  return user?.displayName ?? '';
};

export const replaceUpdatedTickets = (
  initialTickets: TicketSearchResult[],
  updatedTickets: TicketSearchResult[],
) => {
  if (initialTickets === updatedTickets) {
    return [...initialTickets];
  }

  return initialTickets.map((ticket) => updatedTickets.find((ut) => ut.id === ticket.id) || ticket);
};

export const filterClaimedTickets = (
  initialTickets: TicketSearchResult[],
  updatedTickets: TicketSearchResult[],
) => {
  const { uniquePersonIds, uniqueContacts } = getUniqueClaimsIds(updatedTickets);

  return replaceUpdatedTickets(initialTickets, updatedTickets).filter((ticket) =>
    ticket.partyId ? !uniquePersonIds.has(ticket.partyId) : !uniqueContacts.has(ticket.contact),
  );
};

export const checkIfCanClaimTicket = async (
  userId: number,
  handlers: {
    onLimitReached: () => void;
    onHasAnotherClaim: (claim: TicketSearchResult) => void;
    onCanClaim: () => void;
  },
) => {
  const claimedTickets = await TicketService.search({
    claimUserId: userId,
  });

  if (claimedTickets.length >= CLAIMED_TICKETS_LIMIT) {
    handlers.onLimitReached();
    return;
  }

  const [firstClaimedTicket] = claimedTickets.sort(
    (a, b) =>
      moment(a.ticketClaimExpirationTime).valueOf() - moment(b.ticketClaimExpirationTime).valueOf(),
  );

  if (firstClaimedTicket) {
    handlers.onHasAnotherClaim(firstClaimedTicket);
  } else {
    handlers.onCanClaim();
  }
};

export const getContactPageURL = (ticket: Ticket | TicketSearchResult) => {
  const expirationTimestamp = moment(ticket.ticketClaimExpirationTime).valueOf();

  return `/contactDetails/${expirationTimestamp}?${queryString.stringify({
    ...(ticket.partyId
      ? { partyId: ticket.partyId }
      : {
          newContact: 'contact' in ticket ? ticket.contact : undefined,
          ticketId: ticket.id,
        }),
  })}#${PropifyCRMTabs.TICKETS}`;
};

export const getTypeForSelect = (value: string) => {
  switch (value) {
    case TicketType.UNREAD_SMS:
      return 'SMS';
    case TicketType.UNREAD_EMAIL:
      return 'Email';
    default:
      return enumToLabel(value);
  }
};

export const filterTickets = (tickets: TicketSearchResult[], filters: TicketSearchParams) => {
  const params: Partial<TicketSearchParams> = pickBy(filters, (v) => v !== undefined);
  if (typeof params.status === 'string') {
    params.status = (params.status as string).split(',').filter((v) => v) as TicketStatus[];
  }

  return tickets.filter((ticket) => {
    if (params.ticketGroupId) {
      if (params.ticketGroupId !== ticket.ticketGroupId) {
        return false;
      }
    }

    if (params.preferredUserId) {
      if (ticket.preferredUserId !== params.preferredUserId) {
        return false;
      }
    }

    if (params.type) {
      if (ticket.type !== params.type) {
        return false;
      }
    }

    if (params.createLoginId) {
      if (ticket.createLoginId !== params.createLoginId) {
        return false;
      }
    }

    if (params.priority) {
      if (ticket.priority !== params.priority) {
        return false;
      }
    }

    if (params.subskillId) {
      // This checks that the ticket doesn't have a subskill
      if (params.subskillId === -1 && ticket.subskillId) {
        return false;
      }
      // This checks that the ticket subskill matches the selected one
      if (params.subskillId !== -1 && ticket.subskillId !== params.subskillId) {
        return false;
      }
    }

    if (params.status?.length) {
      if (!params.status.includes(ticket.status)) {
        return false;
      }
    }

    if (params.keywords?.trim()) {
      if (
        !(ticket.reasonDescription || '')
          .toLowerCase()
          .includes(params.keywords.trim().toLowerCase())
      ) {
        return false;
      }
    }

    if (params.dueTime) {
      const range = queryParamToDateRange(params.dueTime);
      if (range && !isDateInRange(moment(ticket.dueTime), range)) {
        return false;
      }
    }

    if (params.finishedTime) {
      const range = queryParamToDateRange(params.finishedTime);
      if (range && (!ticket.finishedTime || !isDateInRange(moment(ticket.finishedTime), range))) {
        return false;
      }
    }

    return true;
  });
};
