import { ModalFormButton } from '@/components/Button/formButton';
import type {
  ColumnComponentType,
  ColumnComponentTypeProps,
} from '@/notmagic/components/EntityTable/types';
import AddNextPaymentModal from '@/pages/resident/components/AddNextPaymentModal';
import { CollectionPaymentsService } from '@/services/collection-payments';
import { SHORT_DATE_FORMAT } from '@/utils/time';
import { handleError } from '@/utils/utils';
import DeleteOutlined from '@ant-design/icons/DeleteOutlined';
import PlusOutlined from '@ant-design/icons/PlusOutlined';
import { formatCurrency } from '@propify/components';
import { Button, message, Popconfirm, Spin } from 'antd';
import moment from 'moment';
import { useState } from 'react';

type RowType = {
  collectionId: number;
  nextPaymentId?: number;
  nextPaymentDate?: string;
  nextPaymentAmount?: number;
};

type Props = ColumnComponentTypeProps<RowType>;

const Component: ColumnComponentType<Props> = ({ value, onValidateEntity }) => {
  const [loading, setLoading] = useState(false);
  const { collectionId, nextPaymentId, nextPaymentDate, nextPaymentAmount } = value || {};

  const removeNextPayment = () => {
    if (!collectionId || !nextPaymentId) {
      return;
    }

    setLoading(true);
    CollectionPaymentsService.delete(collectionId, nextPaymentId)
      .then(() => {
        message.success('Collection Next Payment Due deleted successfully');
        onValidateEntity();
      })
      .catch((error) => {
        handleError(error, {
          toastFallbackMessage: 'There was an error deleting the collection Next Payment Due',
        });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  if (!collectionId) {
    return null;
  }

  if (loading) {
    return <Spin size="small" />;
  }

  const formattedText = nextPaymentDate
    ? `${moment(nextPaymentDate).format(SHORT_DATE_FORMAT)} - ${formatCurrency(nextPaymentAmount, {
        noEmptyDecimals: true,
      })}`
    : '';

  return (
    <div className="nextPaymentCell">
      <span className="formattedText" title={formattedText}>
        {formattedText}
      </span>

      {nextPaymentDate && (
        <span>
          <Popconfirm
            title="Are you sure?"
            onConfirm={() => removeNextPayment()}
            okText="Yes"
            cancelText="No"
          >
            <Button danger icon={<DeleteOutlined />} type="link" size="small" />
          </Popconfirm>
        </span>
      )}

      <span>
        <ModalFormButton
          key="add-next-payment-btn"
          icon={<PlusOutlined />}
          buttonType="link"
          onSuccess={() => onValidateEntity()}
        >
          {(props) => <AddNextPaymentModal {...props} collectionId={collectionId} />}
        </ModalFormButton>
      </span>
    </div>
  );
};

export default {
  Component,
};
