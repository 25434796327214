import type {
  Collection,
  CollectionSearchParams,
  CollectionSearchResult,
} from '@/domain/resident-collection';
import { api } from '@/utils/configuration';
import { crudService, getAxiosFetcher } from '@/utils/request';
import type { Operation } from 'fast-json-patch';

export type PostDateType = 'WARNING' | 'NOTICE' | 'EVICTION_FILED' | 'LATE_FEE_DATE';

interface BulkUpdateBody {
  ids: number[];
  status?: string;
  risk?: string;
  event?: {
    residentId?: number;
    type: PostDateType;
    date: string;
  };
}

const ResidentCollectionsService = {
  search: getAxiosFetcher<CollectionSearchResult[], [CollectionSearchParams]>((params) => [
    api.RESIDENT_COLLECTIONS,
    { params },
  ]),

  patch: (id: number, operations: Operation[], version: number) =>
    crudService.patch<Collection>(
      operations,
      `${api.RESIDENT_COLLECTIONS}/${id}?version=${version}`,
    ),

  bulkUpdate: (body: BulkUpdateBody) => crudService.put<void>(body, api.RESIDENT_COLLECTIONS),
};

export default ResidentCollectionsService;
