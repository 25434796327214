import type { Column } from '@/@propify-components';
import { Align, Table } from '@/@propify-components';
import { enumColumn } from '@/components/TableUtils';
import type { PromotionSearchResult } from '@/domain/promotions';
import { settings } from '@/services/settings';
import { DeleteOutlined } from '@ant-design/icons';
import { Button, Col, Popconfirm, Row, Typography } from 'antd';
import type Big from 'big.js';
import type { FC } from 'react';
import { formatAdjValue } from '../RentAdjValue';

interface Props {
  associatedPromotions: PromotionSearchResult[];
  onRemovePromotion: (id: number) => void;
  getAdjustmentRentValue: (promotion: PromotionSearchResult) => Big;
}

const { Title } = Typography;

const tableSettings = settings('associated-promotions');

const AssociatedPromotionsTable: FC<Props> = ({
  associatedPromotions,
  onRemovePromotion,
  getAdjustmentRentValue,
}) => {
  const columns: Column<PromotionSearchResult>[] = [
    {
      title: 'Name',
      key: 'name',
    },
    enumColumn({ title: 'Type', key: 'type', width: 119 }, { enumName: 'promotion-type' }),
    {
      title: 'Promotion Text',
      key: 'text',
      showTooltip: true,
      autoWidth: true,
    },
    {
      title: 'Adjustment to Rent',
      key: 'amount',
      render: (row) =>
        formatAdjValue(getAdjustmentRentValue(row), { showNAIfZero: true, orangeIfZero: true }),
    },
    {
      title: '',
      key: 'actions',
      align: Align.right,
      hideSort: true,
      width: 75,
      render: (row) => (
        <Popconfirm
          title="Are you sure?"
          okText="Yes"
          cancelText="No"
          onConfirm={() => onRemovePromotion(row.id)}
        >
          <Button icon={<DeleteOutlined />} type="link" danger />
        </Popconfirm>
      ),
    },
  ];
  return (
    <Row gutter={[8, 8]}>
      <Col xs={24}>
        <Title level={4}>Associated Promotions</Title>
      </Col>
      <Col xs={24}>
        <Table
          columns={columns}
          data={associatedPromotions}
          rowKeyExtractor={(promotion) => promotion.id}
          className="propify-scrollable-table"
          settings={tableSettings}
        />
      </Col>
    </Row>
  );
};

export default AssociatedPromotionsTable;
