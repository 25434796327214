import { store } from '@/store';
import { Provider } from 'react-redux';
import { RouterProvider } from 'react-router-dom';
import router from './routes';

const App = () => (
  <Provider store={store}>
    <RouterProvider router={router} />
  </Provider>
);

export default App;
