import { useAppContext } from '@/contexts/AppContext';
import isEqual from 'lodash/isEqual';
import { useEffect, useState } from 'react';
import type { Tab, Table } from '../types';
import { useJsonata } from './useJsonata';

export const useDataGroupedByTabs = <T = any>(
  table: Table,
  transformedData: T[],
  currentTab: Tab,
) => {
  const [tabRowMap, setTabRowMap] = useState<Record<string, T>>({});
  const [grouping, setGrouping] = useState(false);
  const { currentUser } = useAppContext();

  const [runJsonata] = useJsonata<T[], typeof tabRowMap>((result) => {
    setGrouping(false);
    setTabRowMap(result);
  });

  const mapTab = (tab: Tab) => `
    '${tab.key}': [
      $filter(
        $,
        function($v, $i, $a) {
          ${tab.filter || 'true'}
        }
      )
    ]
  `;

  useEffect(() => {
    const expression = `{ ${(table?.tabs || [currentTab])
      .filter((tab) => isEqual(tab.queryParameters, currentTab.queryParameters))
      .map(mapTab)
      .join(', ')} }`;
    setGrouping(true);
    if (transformedData) {
      runJsonata({
        action: 'group',
        data: transformedData,
        expression,
        bindings: {
          currentUser,
        },
      });
    }
  }, [transformedData, table, runJsonata, currentUser]);

  return { tabRowMap, grouping };
};
