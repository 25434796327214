import EntityForm from '@/notmagic/components/EntityTable/components/EntityForm';
import type { Entity, EntityType } from '@/notmagic/types';
import { crudService } from '@/utils/request';
import { handleError } from '@/utils/utils';
import { EditOutlined } from '@ant-design/icons';
import { Button, Modal } from 'antd';
import type { FC } from 'react';
import { useMemo, useState } from 'react';

type Props = {
  entityType: EntityType;
  entity: Entity;
  onSuccess: (entity: Entity) => void;
};

const EditEntityButton: FC<Props> = ({ entityType, onSuccess, entity }) => {
  const [modalVisible, setModalVisible] = useState(false);
  const [isSaving, setIsSaving] = useState(false);

  const values = useMemo(
    () =>
      entityType.attributes!.reduce(
        (result, attribute) => ({
          ...result,
          [attribute.name]: entity[attribute.name],
        }),
        {},
      ),
    [entity, entityType],
  );

  const onSubmit = (newValues: any) => {
    setIsSaving(true);
    crudService
      .put<Entity>(
        { ...newValues, id: entity.id, orgId: entity.orgId, version: entity.version },
        `${entityType.endpoint!}/${entity.id}`,
      )
      .then((updatedEntity) => {
        setModalVisible(false);
        onSuccess(updatedEntity);
      })
      .catch((error) => {
        handleError(error, {
          toastFallbackMessage: `There was an error while updating the ${entityType.displayName}`,
        });
      })
      .finally(() => setIsSaving(false));
  };

  return (
    <>
      <Button type="link" icon={<EditOutlined />} onClick={() => setModalVisible(true)} />
      {modalVisible && (
        <EntityForm entityType={entityType} values={values} onSubmit={onSubmit}>
          {({ children, handleSubmit, dirty, isValid }) => (
            <Modal
              title={`Edit ${entityType.displayName}`}
              visible
              onCancel={() => {
                setModalVisible(false);
              }}
              onOk={handleSubmit}
              okText="Save"
              okButtonProps={{
                disabled: !dirty || !isValid,
                loading: isSaving,
              }}
              maskClosable={false}
            >
              {children}
            </Modal>
          )}
        </EntityForm>
      )}
    </>
  );
};

export default EditEntityButton;
