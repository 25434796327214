import type { Column } from '@/@propify-components';
import CheckIcon from '@/assets/icons/check.svg?react';
import ColumnsIcon from '@/assets/icons/columns.svg?react';
import type { Settings } from '@/services/settings';
import { Button, Dropdown } from 'antd';
import classNames from 'classnames';
import type { FC } from 'react';
import { memo, useEffect, useMemo, useState } from 'react';
import classes from './styles.module.less';

export type DisplayColumnsProps = {
  columns: Column[];
  setColumns: (columns: Column[]) => void;
  settings?: Settings;
  className?: string;
};

const DisplayColumns: FC<DisplayColumnsProps> = ({ columns, setColumns, settings, className }) => {
  const [show, setShow] = useState(false);
  const [internalColumns, setInternalColumns] = useState([...columns]);

  useEffect(() => {
    setInternalColumns(columns);
  }, [columns]);

  const isApplyDisabled = useMemo(() => {
    const visibleColumns = columns
      .filter((c) => typeof c.visible === 'undefined' || c.visible)
      .map((c) => c.key)
      .join(',');

    const visibleInternalColumns = internalColumns
      .filter((c) => typeof c.visible === 'undefined' || c.visible)
      .map((c) => c.key)
      .join(',');

    return visibleColumns === visibleInternalColumns;
  }, [columns, internalColumns]);

  useEffect(() => {
    const visibleColumns = settings?.getVisibleColumns();
    setColumns(
      columns.map((column) => ({
        ...column,
        visible: visibleColumns?.[column.key] ?? true,
      })),
    );
  }, [columns.length]);

  const toggleColumn = (index: number) => {
    const newColumns = [...internalColumns];
    if (newColumns[index]) {
      const prevValue = newColumns[index].visible ?? true;
      newColumns[index] = {
        ...newColumns[index],
        visible: !prevValue,
      };

      setInternalColumns(newColumns);
    }
  };

  const handleApply = () => {
    setColumns(internalColumns);
    settings?.setVisibleColumns(
      internalColumns.reduce(
        (visibleColumns, column) => ({
          ...visibleColumns,
          [column.key]: column.visible ?? true,
        }),
        {},
      ),
    );
    setShow(false);
  };

  const menu = (
    <div className={classes.displayColumns}>
      <div className={classes.columnsContainer}>
        {internalColumns.map((column, index) => (
          <div
            key={column.key}
            className={`${classes.displayColumnsItem} ${column.visible ? classes.visible : ''}`}
            onClick={() => toggleColumn(index)}
          >
            <div className={classes.displayColumnLabel}>{column.title}</div>
            {(typeof column.visible === 'undefined' || column.visible) && <CheckIcon />}
          </div>
        ))}
      </div>
      <Button disabled={isApplyDisabled} onClick={handleApply} className={classes.applyButton}>
        Apply
      </Button>
    </div>
  );

  return (
    <Dropdown
      overlay={menu}
      trigger={['click']}
      visible={show}
      onVisibleChange={(visible) => setShow(visible)}
      className={className}
      placement="bottomRight"
      data-test="displayColumns"
    >
      <Button
        type="primary"
        className={classNames(classes.displayColumnButton, 'columnVisibilityButton')}
      >
        <ColumnsIcon />
      </Button>
    </Dropdown>
  );
};

export default memo(DisplayColumns);
