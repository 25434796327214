import type { Person } from '@/domain/person';
import { StarFilled } from '@ant-design/icons';
import { Tooltip } from 'antd';
import type { FC } from 'react';
import { useMemo } from 'react';
import { Link } from 'react-router-dom';

interface Props {
  person: Person;
  primary: boolean;
  url?: string;
}

const ApplicantName: FC<Props> = ({ person, url, primary }) => {
  const redirectUrl = useMemo(() => {
    if (url) {
      return url;
    }
    return `/person/${person.id}`;
  }, [person.id, url]);

  return (
    <div style={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
      <Link to={redirectUrl} style={{ overflow: 'hidden', textOverflow: 'ellipsis' }}>{`${
        person.firstName ?? ''
      } ${person.lastName ?? ''}`}</Link>

      {primary && (
        <Tooltip title="Primary Applicant">
          <StarFilled />
        </Tooltip>
      )}
    </div>
  );
};

export default ApplicantName;
