import { Input, InputNumber } from 'antd';
import type { InputNumberProps } from 'antd/lib/input-number';
import type { FC } from 'react';

export type NumRange = [number | undefined, number | undefined];

type Props = {
  value: NumRange;
  onChange: (value: NumRange) => void;
  width?: number | string;
  inputProps?: InputNumberProps;
  displayFlex?: boolean;
};

const NumberRange: FC<Props> = ({ onChange, value, displayFlex, inputProps, width = 100 }) => {
  const [min] = value || [];

  const handleMinChange = (newMin: string | number | undefined | null) => {
    const newValue = newMin || newMin === 0 ? Number(newMin) : undefined;
    onChange([newValue, value ? value[1] : undefined]);
  };

  const handleMaxChange = (newMax: string | number | undefined | null) => {
    const newValue = newMax || newMax === 0 ? Number(newMax) : undefined;
    onChange([value ? value[0] : undefined, newValue]);
  };

  return (
    <Input.Group compact style={displayFlex ? { display: 'flex' } : {}}>
      <InputNumber
        size="large"
        className="site-input-left"
        style={{ width, textAlign: 'center', flex: 1 }}
        placeholder="Min"
        min={0}
        max={Number.MAX_SAFE_INTEGER}
        value={value && value[0] !== undefined ? value[0] : undefined}
        onChange={handleMinChange}
        {...inputProps}
      />

      <Input
        size="large"
        className="site-input-split"
        style={{
          width: 25,
          borderLeft: 0,
          borderRight: 0,
          pointerEvents: 'none',
        }}
        placeholder="-"
        disabled
      />

      <InputNumber
        size="large"
        className="site-input-right"
        style={{ width, textAlign: 'center', flex: 1 }}
        placeholder="Max"
        value={value && value[1] !== undefined ? value[1] : undefined}
        min={min || 0}
        max={Number.MAX_SAFE_INTEGER}
        onChange={handleMaxChange}
        {...inputProps}
      />
    </Input.Group>
  );
};

export default NumberRange;
