import columnTypes from '@/notmagic/components/EntityTable/column-types';
import filterTypes from '@/notmagic/components/EntityTable/filter-types';
import entityTypes from '@/notmagic/metadata/entity-types';
import { apiClient } from '@/services/apiClient';
import queryString from 'query-string';
import { createContext, useContext } from 'react';
import type { ColumnType, FilterDefinition } from './components/EntityTable/types';
import type { EntityType } from './types';

export const fetcher = (url: string, params?: Record<string, any>, signal?: AbortSignal) => {
  const qs = queryString.stringify(params || {});

  return apiClient
    .get(qs ? `${url}?${qs}` : url, {
      signal,
    })
    .then((response) => response.data);
};

export interface ContextValues {
  fetcher: (url: string, params?: any, signal?: AbortSignal) => any;
  columnTypes: Record<string, ColumnType>;
  filterTypes: Record<string, FilterDefinition>;
  entityTypes: Record<string, EntityType>;
}

const Context = createContext<ContextValues>({ fetcher, columnTypes, filterTypes, entityTypes });

export default Context;

export const useEntityTableContext = () => {
  return useContext(Context);
};
