import classNames from 'classnames';
import type { FC } from 'react';
import type { ClassNameMap } from '../../shared/utils/material';
import type { Column } from '../index';
import { Align, DEFAULT_COLUMN_WIDTH } from '../index';

interface Props {
  column: Column;
  styles: ClassNameMap<string>;
}

const FooterCell: FC<Props> = ({ column, styles }) => {
  const width = column.width ? column.width : DEFAULT_COLUMN_WIDTH;
  return (
    <div
      className={classNames(styles.tableCell, {
        [styles.tableCellRight]: column.align === Align.right,
        [styles.tableCellCenter]: column.align === Align.center,
      })}
      style={{ minWidth: width, display: 'flex', flex: 1 }}
      data-test="footerTableCell"
      role="cell"
    >
      {column.total}
    </div>
  );
};

export default FooterCell;
