import type {
  FilterComponentType,
  FilterComponentTypeProps,
} from '@/notmagic/components/EntityTable/types';
import { Input } from 'antd';

type Props = FilterComponentTypeProps<number>;

const Component: FilterComponentType<Props> = ({ editable, value, onChangeValue }) => {
  if (Array.isArray(value)) {
    // eslint-disable-next-line no-console
    console.error('ERROR: Filters of type NUMBER only support a single value');
    return <></>;
  }

  if (!editable) {
    return <>{value}</>;
  }
  return (
    <Input
      value={value}
      type="number"
      onChange={(event) => {
        const newValue = event.target.value;
        onChangeValue(newValue ? +newValue : undefined);
      }}
      min={0}
      style={{ flex: 1 }}
    />
  );
};

export default {
  Component,
  jsonataQuery: (value: string, key: string) => `$v.${key} = ${value}`,
};
