import type { ModalFormProps } from '@/components/Button/formButton';
import { DocumentTemplatesService } from '@/services/document-templates';
import { useFetch } from '@/utils/request';
import { Alert, Button, Modal, Typography } from 'antd';
import HelloSign from 'hellosign-embedded';
import { useEffect, useState, type FC } from 'react';

export interface Props extends ModalFormProps {
  documentTemplateName: string | string[];
  documentTemplateDescription?: string;
  templateIds: number[];
  multiple?: boolean;
}

const { Title, Text, Paragraph } = Typography;

const DocumentTemplatePreviewModal: FC<Props> = ({
  documentTemplateName,
  documentTemplateDescription = '',
  templateIds,
  onCancel,
  multiple = false,
}) => {
  const [client, setClient] = useState<HelloSign>();

  const { data: config } = useFetch({
    fetcher: DocumentTemplatesService.config,
    errorMessage: 'There was an error while loading the document template config',
  });

  useEffect(() => {
    if (!config?.clientId) {
      return;
    }
    setClient(
      new HelloSign({
        clientId: config.clientId,
        container: document.getElementById('hellosign-document-preview')!,
        testMode: config.testMode,
        skipDomainVerification: config.skipDomainVerification,
        locale: HelloSign.locales[config.locale],
      }),
    );
  }, [config]);

  const { data: previewUrl } = useFetch(
    {
      fetcher: templateIds.length > 0 ? DocumentTemplatesService.getPreviewUrl : undefined,
      errorMessage: 'There was an error trying to get the template document preview url',
    },
    { templateIds },
  );

  useEffect(() => {
    if (client && previewUrl) {
      client.open(previewUrl);

      client.on('finish', () => {
        onCancel?.();
      });
    }
  }, [client, config, onCancel, previewUrl]);

  return (
    <Modal
      title="Lease Template Preview"
      visible
      maskClosable={false}
      width={980}
      bodyStyle={{ height: 1000 }}
      onCancel={onCancel}
      footer={
        <Button key="cancel" onClick={onCancel}>
          Cancel
        </Button>
      }
    >
      {multiple ? (
        <>
          <Paragraph>
            <Title level={5}>Document Templates</Title>
            <Text>{[...documentTemplateName].join(', ')}</Text>
          </Paragraph>
        </>
      ) : (
        <>
          <Paragraph>
            <Title level={5}>Lease Template Name</Title>
            <Text>{documentTemplateName}</Text>
          </Paragraph>
          <Paragraph>
            <Title level={5}>Lease Template Description</Title>
            <Text>{documentTemplateDescription}</Text>
          </Paragraph>
        </>
      )}

      <Alert
        message="Clicking the 'Send for signature' button will not trigger the sending of a document."
        type="warning"
        showIcon
      />

      <div id="hellosign-document-preview" style={{ height: '85%', marginTop: '15px' }} />
    </Modal>
  );
};

export default DocumentTemplatePreviewModal;
