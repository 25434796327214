import type { Column } from '@/@propify-components';
import { Table } from '@/@propify-components';
import { ModalFormButton } from '@/components/Button/formButton';
import { AntDatePicker } from '@/components/Formik/CreateAntField';
import type { Charge, UtilityType } from '@/domain/lease';
import { ChargeFrequency } from '@/domain/lease';
import type { RenewalDTO, RenewalOption } from '@/domain/renewal';
import useEnumeration from '@/hooks/useEnumeration';
import '@/pages/prospects/styles.less';
import { SHORT_DATE_FORMAT } from '@/utils/time';
import { InfoCircleFilled, PlusOutlined } from '@ant-design/icons';
import { Alert, Button, Col, Row, Tooltip } from 'antd';
import { Field, useFormikContext } from 'formik';
import noop from 'lodash/noop';
import moment from 'moment';
import type { FC } from 'react';
import { useState } from 'react';
import type { FeeFormValues } from './AddItemModal';
import AddItemModal from './AddItemModal';
import type { LeaseTermFormValues } from './AddLeaseTerm';
import AddLeaseTerm from './AddLeaseTerm';
import EditTermWrapper from './EditTermWrapper';
import type { RenewalsModalValues } from './RenewalsModal';
import TableWithSummary from './TableWithSummary';

interface Props {
  renewal: RenewalDTO;
}

const RenewalTerms: FC<Props> = ({ renewal }) => {
  const { values, setFieldValue } = useFormikContext<RenewalsModalValues>();
  const [showAddTerm, setShowAddTerm] = useState(false);

  const renewalOptions = values.options;

  const { data: utilities, isValidating: loadingUtilityTypes } =
    useEnumeration<UtilityType>('lease-utility-type');

  const updateRenewalOption = (renewalOptionToUpdate: RenewalOption, index: number) => {
    const newList = renewalOptions.map((r, i) => (i === index ? renewalOptionToUpdate : r));

    setFieldValue('options', newList);
  };

  const updateRenewalCharges = (renewalChargesToUpdate: Partial<Charge>[]) => {
    setFieldValue('charges', renewalChargesToUpdate);
  };

  const leaseTermsColumns: Column<RenewalOption>[] = [
    {
      key: 'minMonths',
      title: 'Min',
      hideSort: true,
      width: 80,
    },
    {
      key: 'maxMonths',
      title: 'Max',
      hideSort: true,
      width: 80,
    },
    {
      key: 'actions',
      title: 'Actions',
      hideSort: true,
      width: 100,
      render: (value: RenewalOption, index) => {
        const handleDelete = () => {
          const newList = renewalOptions.filter(
            (r) => r.minMonths !== value.minMonths && r.maxMonths !== value.maxMonths,
          );
          setFieldValue('options', newList);
        };

        const handleEdit = (newValues: LeaseTermFormValues, renewalOption: RenewalOption) => {
          const optionToEditIndex = renewalOptions.findIndex(
            (r) =>
              r.minMonths === renewalOption.minMonths && r.maxMonths === renewalOption.maxMonths,
          );
          const newList = [...renewalOptions];
          newList[optionToEditIndex] = {
            ...renewalOption,
            ...newValues,
          };

          setFieldValue('options', newList);
        };

        return (
          <EditTermWrapper
            optionToEditIndex={index}
            renewalOptions={renewalOptions}
            renewalOptionToEdit={value}
            onDelete={handleDelete}
            onEdit={handleEdit}
            // TODO we'll remove this condition to always display this button in the future
            showDelete={index + 1 === renewalOptions.length}
          />
        );
      },
    },
  ];

  const leaseTermOptionsTooltip = (
    <span>
      Each set of Term lengths added in this section represents a range of lease length options (in
      months) which a resident may select when deciding whether to renew their lease. For example,
      inputs of &apos;1&apos; as a minimum length and &apos;12&apos; as a maximum length would allow
      a resident to select a renewal lease length of any full month increment between one and
      twelve.
      <br />
      <br />
      Each set of term lengths added will tie directly to a Monthly Fees and Promotions section.
      This allows you to tie different monthly rent values to different lease lengths.
    </span>
  );

  return (
    <>
      <Row gutter={24} className="section-container">
        <Col xs={8} className="section">
          <div className="section">
            <div className="description">Lease Start Date</div>
            <Field
              name="startDate"
              component={AntDatePicker}
              format={SHORT_DATE_FORMAT}
              disabledDate={(current: object) => {
                return current < moment().subtract(1, 'day');
              }}
            />
          </div>

          <div>
            <div className="wrapper">
              <div className="description">
                Lease Terms (Months) Options
                <Tooltip title={leaseTermOptionsTooltip}>
                  <InfoCircleFilled style={{ color: '#1890FF', marginLeft: 8 }} />
                </Tooltip>
              </div>

              <Button size="small" onClick={() => setShowAddTerm(true)}>
                <PlusOutlined />
              </Button>
            </div>

            <Table
              columns={leaseTermsColumns}
              data={renewalOptions}
              className="lease-terms-table"
              rowKeyExtractor={(r) => r.id}
            />
          </div>
        </Col>

        <Col xs={8} className="section">
          <div className="description">Monthly Fees</div>

          {renewalOptions.map((r, index) => (
            <div key={r.id} className="divider">
              <TableWithSummary
                renewalOption={r}
                updateRenewalOption={updateRenewalOption}
                renewal={renewal}
                summaryText="Monthly Total"
                type="monthly-fee"
                charges={r.charges}
                showActions
                hideCollapseButton={
                  !r.charges ||
                  !r.charges.filter((c) => c.frequency === ChargeFrequency.MONTHLY).length
                }
                title={`${r.minMonths}-${r.maxMonths}`}
                showYearOverYearEscalator
                renewalOptionIndex={index}
              />
            </div>
          ))}
        </Col>

        <Col xs={8} className="section">
          <div className="description">Promotions</div>

          {renewalOptions.map((r, index) => (
            <div key={r.id} className="divider">
              <TableWithSummary
                renewalOption={r}
                updateRenewalOption={updateRenewalOption}
                renewalOptionIndex={index}
                renewal={renewal}
                data={[]}
                type="promotion"
                title={`${r.minMonths}-${r.maxMonths}`}
              />
            </div>
          ))}
        </Col>
      </Row>

      <Row gutter={24} className="section-container">
        <Col xs={8} className="section">
          <div className="wrapper">
            <div className="description">One-Time Fees</div>

            <ModalFormButton
              key="add-profile"
              icon={<PlusOutlined />}
              onSuccess={(fee: FeeFormValues) =>
                setFieldValue('charges', [
                  ...values.charges,
                  {
                    name: fee.name,
                    amount: fee.amount,
                    type: fee.type,
                    frequency: ChargeFrequency.ONE_TIME,
                  } as Charge,
                ])
              }
            >
              {(props) => <AddItemModal {...props} onAdd={props.onSuccess} />}
            </ModalFormButton>
          </div>

          <div className="divider">
            <TableWithSummary
              renewal={renewal}
              charges={values.charges}
              data={values.charges.map((c) => ({ value: c.amount }))}
              updateRenewalCharges={updateRenewalCharges}
              updateRenewalOption={noop}
              summaryText="One-Time Total"
              type="one-time-fee"
              editable
            />
          </div>
        </Col>

        <Col xs={8} className="section">
          <div className="description">Utilities</div>

          <Alert
            className="utilities-info"
            message="Inherit from Previous Lease"
            type="info"
            showIcon
          />

          <div className="divider">
            <TableWithSummary
              type="utilities"
              loading={loadingUtilityTypes}
              renewal={renewal}
              updateRenewalOption={noop}
              data={(utilities || []).map((u, index) => ({
                id: index,
                description: u.displayName,
                value: u.value,
              }))}
              editable={false}
            />
          </div>
        </Col>
      </Row>

      <AddLeaseTerm
        visible={showAddTerm}
        renewalOptions={renewalOptions}
        onCancel={() => setShowAddTerm(false)}
        onAdd={(formValues) => {
          setFieldValue('options', [
            ...renewalOptions,
            { ...formValues, charges: [], yearOverYearEscalator: '' },
          ]);
          setShowAddTerm(false);
        }}
      />
    </>
  );
};

export default RenewalTerms;
