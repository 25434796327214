import type { ModalFormProps } from '@/components/Button/formButton';
import { AntSelect, AntTextArea } from '@/components/Formik/CreateAntField';
import type { Option } from '@/domain/base';
import useEnumeration from '@/hooks/useEnumeration';
import { Button, Form, Modal } from 'antd';
import { Field, Formik } from 'formik';
import type { FC } from 'react';
import * as Yup from 'yup';

export interface RejectFileValues {
  rejectionReasonType: string;
  rejectionReason?: string;
}

interface Props extends ModalFormProps<[RejectFileValues]> {
  disabled?: boolean;
  loading?: boolean;
}

const validationSchema = Yup.object({
  rejectionReasonType: Yup.string().required('This field is required'),
  rejectionReason: Yup.string()
    .trim('Rejection reason should not start or end with a blank space.')
    .nullable(),
});

const RejectFileModal: FC<Props> = ({ onSuccess, onCancel, disabled, loading }) => {
  const { options: rejectionReasonTypes } = useEnumeration(
    'application-file-rejection-reason-type',
  );

  return (
    <Formik<RejectFileValues>
      onSubmit={onSuccess}
      validationSchema={validationSchema}
      initialValues={{ rejectionReason: '', rejectionReasonType: '' }}
    >
      {({ handleSubmit, submitForm, isValid }) => (
        <Form onSubmitCapture={handleSubmit}>
          <Modal
            visible
            title="Reject reason"
            onCancel={onCancel}
            width={800}
            footer={[
              <Button
                type="default"
                htmlType="button"
                key="close"
                onClick={onCancel}
                disabled={disabled}
                loading={loading}
              >
                Close
              </Button>,
              <Button
                key="Reject"
                htmlType="submit"
                onClick={() => submitForm()}
                disabled={disabled || !isValid}
                loading={loading}
                className="danger"
              >
                Reject
              </Button>,
            ]}
          >
            <Field
              component={AntSelect}
              label="Rejection reason"
              name="rejectionReasonType"
              hasFeedback
              formItemStyle={{ width: '100%', display: 'block' }}
              selectOptions={rejectionReasonTypes}
              getOptionLabel={(option: Option) => option.label}
              getOptionValue={(option: Option) => option.value}
            />

            <Field
              formItemStyle={{ display: 'block' }}
              style={{ width: '100%', minHeight: '100px' }}
              component={AntTextArea}
              type="text"
              rows={4}
              label="Additional Notes"
              name="rejectionReason"
              hasFeedback
            />
          </Modal>
        </Form>
      )}
    </Formik>
  );
};

export default RejectFileModal;
