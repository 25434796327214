import type { Unit } from '@/domain/unit';
import type { Address } from './address';
import type { BooleanString, PropifyEntity } from './base';
import type { File } from './file';
import type { Person } from './person';
import type {
  UnitApplication,
  UnitApplicationStatus,
  UnitApplicationType,
} from './unit-applications';

export interface ApplicationResolutionForApproveRequest {
  resolution: string;
}

export interface ApplicationResolutionForDenyRequest {
  resolution?: string;
  resolutionType: string;
}

export enum ApplicationStatus {
  NEW = 'NEW',
  SUBMITTED = 'SUBMITTED',
  UNDER_REVIEW = 'UNDER_REVIEW',
  MISSING_INFORMATION = 'MISSING_INFORMATION',
  APPROVED = 'APPROVED',
  DENIED = 'DENIED',
}

export enum EmploymentStatus {
  EMPLOYED = 'EMPLOYED',
  RETIRED = 'RETIRED',
  STUDENT = 'STUDENT',
  UNEMPLOYED = 'UNEMPLOYED',
}

export enum ApplicationType {
  PRIMARY = 'PRIMARY',
  COSIGNER = 'COSIGNER',
  GUARANTOR = 'GUARANTOR',
}

export enum NameSuffix {
  JR = 'JR',
  SR = 'SR',
  I = 'I',
  II = 'II',
  III = 'III',
  IV = 'IV',
}

export enum Frequency {
  WEEKLY = 'WEEKLY',
  MONTHLY = 'MONTHLY',
  YEARLY = 'YEARLY',
}

export enum ApplicationFileStatus {
  REQUESTED = 'REQUESTED',
  SUBMITTED = 'SUBMITTED',
  ACCEPTED = 'ACCEPTED',
  REJECTED = 'REJECTED',
}

export enum CreditRecommendation {
  APPROVED = 'APPROVED',
  REJECTED = 'REJECTED',
  PENDING = 'PENDING',
  CONDITIONAL = 'CONDITIONAL',
}

export interface ApplicationSearchParams {
  keywords?: string;
  reviewDueDate?: string;
  id?: number | number[];
  personId?: number | number[];
  unitApplicationId?: number | number[];
  renewalId?: number | number[];
  status?: ApplicationStatus[];
  unitApplicationStatus?: UnitApplicationStatus[];
  unitApplicationType?: UnitApplicationType[];
  renewalStatus?: string[]; // TODO: Change this to RenewalStatus[]
  marketRent?: number[] | string;
  validatedIncome?: number[] | string;
  type?: ApplicationType[];
  createTime?: string;
  submittedTime?: string;
  noUnitApplicationApproved?: BooleanString;
  someApplicationSubmitted?: BooleanString;
  hasOpenTicketDue?: BooleanString;
  expired?: BooleanString;
  withExternalId?: BooleanString;
  ventureId?: number;
  excludeCancelledUnitApplicationApplications?: boolean;
  excludeCancelledRenewalApplications?: boolean;
}

export interface ApplicationAddress extends Address {
  monthlyRentOrMortgagePaymentAmount?: number;
  reasonForMoving?: string;
  was30DaysNoticeGiven?: boolean;
  moveInDate?: string;
  moveOutDate?: string;
  owned?: boolean;
}

export interface ApplicationEmployer extends PropifyEntity {
  addr1?: string;
  addr2?: string;
  city?: string;
  state?: string;
  postalCode?: string;
  country?: string;
  jobTitle: string;
  employmentStartDate?: string;
  employmentEndDate?: string;
  monthlyIncome: number;
  employer: string;
  supervisorName: string;
  phoneNumber: string;
}

export interface AdditionalIncome {
  amount: number;
  frequency: Frequency;
  source: string;
}

export interface AdditionalIncome {
  amount: number;
  frequency: Frequency;
  source: string;
}

export interface ApplicationFile extends File {
  applicationId: number;
  fileId: number;
  type: string;
  status: ApplicationFileStatus;
  submitTime: string;
  reviewTime: string;
  reviewedByLoginId: number;
  rejectionReason?: string;
  sendEmail?: boolean;
  noteToApplicant?: string;
}

export interface ApplicationFiles {
  type: string;
  sendEmail: boolean;
}

export interface ApplicationFileBulkCreateRequest {
  applicationFiles: ApplicationFiles[];
}

export interface Application extends PropifyEntity {
  person: Person;
  status: ApplicationStatus;
  title?: string;
  firstName?: string;
  middleName?: string;
  lastName?: string;
  nameSuffix?: NameSuffix;
  currentAddress?: ApplicationAddress;
  previousAddress?: ApplicationAddress;
  employmentStatus?: EmploymentStatus;
  currentEmployer?: ApplicationEmployer;
  previousEmployer?: ApplicationEmployer;
  additionalIncome?: AdditionalIncome;
  reviewDueDate?: string;
  creditRecommendations: CreditRecommendation;
  criminalRecommendation?: boolean;
  backgroundRecommendation?: boolean;
  screeningResultLink?: string;
  idApproval?: boolean | null;
  creditApproval?: boolean | null;
  criminalApproval?: boolean | null;
  backgroundApproval?: boolean | null;
  validatedIncome?: number;
  expirationDate?: string;
  resolution?: string;
  submittedTime?: string;
  resolvedTime?: string;
  resolvedByLoginId?: number;
  files: ApplicationFile[];
}

export interface ApplicationSearchResult extends Application {
  unitApplication?: UnitApplication;
  renewal?: UnitApplication;
  unit: Unit;
  type: ApplicationType;
  invitationStatus?: string;
  nextContactDueTime: string;
  lastOutboundContact: string;
  noUnitApplicationApproved: boolean;
  someApplicationSubmitted: boolean;
}

export interface ApplicationFee {
  applicationFee: number;
}
