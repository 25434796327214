import { useCallback, useEffect, useMemo, useState, type FC } from 'react';
import { useEntityTableContext } from '../Context';
import { useEndpoint } from '../hooks/request';
import type { EntityType } from '../types';
import { parseResponse } from './utils';

type EntityResponseType = Record<string, any>;

const SingleFetcher: FC<{
  endpoint: string;
  onFetch: (result: EntityResponseType[]) => void;
}> = ({ endpoint, onFetch }) => {
  const { data } = useEndpoint<EntityResponseType[]>(endpoint);

  useEffect(() => {
    if (data) {
      onFetch(data);
    }
  }, [data]);

  return null;
};

export type EntitiesFetcherProps = {
  entityTypeNames: string[];
  onFetchAll: (entities: Record<string, Record<string, string>>) => void;
};

const EntitiesFetcher: FC<EntitiesFetcherProps> = ({ entityTypeNames, onFetchAll }) => {
  const { entityTypes } = useEntityTableContext();

  const entityTypesToFetch = useMemo(
    () =>
      entityTypeNames.reduce<Record<string, EntityType>>(
        (map, name) => ({
          ...map,
          [name]: entityTypes[name],
        }),
        {},
      ),
    [entityTypes, entityTypeNames],
  );

  const [results, setResults] = useState<Record<string, Record<string, string>>>({});

  const allFetched = useMemo(
    () => entityTypeNames.every((name) => results[name]),
    [results, entityTypeNames],
  );

  useEffect(() => {
    if (allFetched) {
      onFetchAll(results);
    }
  }, [allFetched]);

  const handleFetch = useCallback(
    (entityTypeName: string, entityResponse: EntityResponseType[]) => {
      setResults((previous) => ({
        ...previous,
        [entityTypeName]: parseResponse(entityResponse, entityTypesToFetch[entityTypeName]),
      }));
    },
    [entityTypesToFetch],
  );

  return (
    <>
      {entityTypeNames
        .filter((name) => {
          if (!entityTypesToFetch[name]) {
            // eslint-disable-next-line no-console
            console.error(`There was an error loading entity type '${name}'`);
            return false;
          }

          return true;
        })
        .map((name) => (
          <SingleFetcher
            key={name}
            endpoint={entityTypesToFetch[name].endpoint}
            onFetch={(data) => handleFetch(name, data)}
          />
        ))}
    </>
  );
};

export default EntitiesFetcher;
