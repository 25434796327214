import React, { useState } from 'react';
import { Arrow } from '../../shared/utils/icons';
import type { ClassNameMap } from '../../shared/utils/material';
import { Button, Menu, MenuItem, usePagination } from '../../shared/utils/material';

interface Props {
  total: number;
  current: number;
  pageSize: number;
  onChange: (pageNumber: number) => void;
  onShowSizeChange: (pageSize: number) => void;
  pageSizeOptions?: string[] | number[];
  responsive?: boolean;
  styles: ClassNameMap<string>;
}

const Pagination: React.FC<Props> = ({
  total,
  pageSize,
  current,
  onChange,
  pageSizeOptions = [],
  onShowSizeChange,
  styles,
}) => {
  const [anchorDisplayMenu, setAnchorDisplayMenu] = useState<Element | null>(null);
  const count = Math.ceil(total / pageSize);

  const { items } = usePagination({
    count,
    page: current,
  });

  const summaryStart = (current - 1) * pageSize + 1;
  const summaryEnd = summaryStart + pageSize - 1;

  const handleCloseDisplayMenu = () => {
    setAnchorDisplayMenu(null);
  };
  const pageSizeMenu = (
    <Menu
      anchorEl={anchorDisplayMenu}
      open={Boolean(anchorDisplayMenu)}
      onClose={handleCloseDisplayMenu}
    >
      {pageSizeOptions?.map((option) => (
        <MenuItem
          key={option}
          onClick={() => {
            handleCloseDisplayMenu();
            onShowSizeChange(Number(option));
          }}
        >
          {option}
        </MenuItem>
      ))}
    </Menu>
  );

  return (
    <div className={styles.paginationContainer} data-test="tablePagination" role="navigation">
      <div className={styles.displayRow}>
        {pageSizeMenu}
        {pageSizeOptions.length > 0 ? (
          <Button
            onClick={(event) => {
              setAnchorDisplayMenu(event.currentTarget);
            }}
            variant="text"
            size="small"
            endIcon={<Arrow direction="DOWN" width={8} style={{ marginTop: -1 }} />}
          >
            Display {pageSize} Rows / Page
          </Button>
        ) : (
          `Display ${pageSize} Rows / Page`
        )}
      </div>

      <div className={styles.pagination}>
        <div className={styles.arrowButtons}>
          <Button
            disabled={current === 1}
            className={styles.arrowButton}
            onClick={() => onChange(current - 1)}
            size="small"
          >
            <Arrow direction="LEFT" />
          </Button>
          <Button
            disabled={count === current}
            className={styles.arrowButton}
            onClick={() => onChange(current + 1)}
            size="small"
          >
            <Arrow direction="RIGHT" />
          </Button>
        </div>
        <div className={styles.pagesSummary}>
          {total === 0 ? 0 : summaryStart} - {total < summaryEnd ? total : summaryEnd} of {total}
        </div>
        <div className={styles.paginationButtons}>
          {items.map(({ page, type, selected }) => {
            if (type === 'start-ellipsis' || type === 'end-ellipsis') {
              return (
                <Button
                  key={type}
                  color="primary"
                  variant="text"
                  size="small"
                  disableElevation
                  onClick={() => {
                    switch (type) {
                      case 'start-ellipsis':
                        return onChange(current - 3);
                      case 'end-ellipsis':
                        return onChange(current + 3);
                    }
                  }}
                >
                  ...
                </Button>
              );
            }

            if (type === 'page') {
              return (
                <Button
                  key={page}
                  onClick={() => onChange(page)}
                  color="primary"
                  variant={selected ? 'contained' : 'text'}
                  style={{
                    backgroundColor: !selected ? '#F5F5F5' : undefined,
                  }}
                  size="small"
                  disableElevation
                >
                  {page}
                </Button>
              );
            }

            return null;
          })}
        </div>
      </div>
    </div>
  );
};

export default Pagination;
