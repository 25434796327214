import {
  CheckOutlined,
  CloseOutlined,
  ExclamationCircleOutlined,
  LoadingOutlined,
} from '@ant-design/icons';
import { Form, Modal, Spin } from 'antd';
import classNames from 'classnames';
import pick from 'lodash/pick';
import type { CSSProperties, ReactNode } from 'react';
import { useEffect, useRef, useState } from 'react';
import './styles.less';

export interface FloatingEditableAttributeProps {
  baseElement: HTMLElement;
  onConfirm: () => void;
  onCancel: () => void;
  loading: boolean;
  style?: CSSProperties;
  error?: string;
  disabled?: boolean;
  children?: ReactNode | undefined;
}

function FloatingEditableAttribute({
  baseElement,
  onConfirm,
  onCancel,
  loading,
  style = {},
  error,
  disabled,
  children,
}: FloatingEditableAttributeProps) {
  const containerRef = useRef<HTMLDivElement>(null);
  const [coords, setCoords] = useState<DOMRect>();
  const [containerCoords, setContainerCoords] = useState<DOMRect>();
  const [measures, setMeasures] = useState({
    top: 0,
    left: 0,
    height: 0,
    width: 0,
  });
  const getMeasures = () => ({
    ...measures,
    minWidth: measures.width,
    width: undefined,
    height: undefined,
  });

  useEffect(() => {
    const handleResize = () => {
      setCoords(baseElement.getBoundingClientRect());
      setContainerCoords(containerRef.current?.getBoundingClientRect());
    };

    handleResize();

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [baseElement, error]);

  useEffect(() => {
    if (coords) {
      const vpHeight = window.innerHeight;
      const vpWidth = window.innerWidth;
      const newValues = pick(coords, 'top', 'left', 'height', 'width');

      // Set minimum width
      newValues.width = Math.max(newValues.width, 250);

      // To prevent the component to render outside the viewport
      const offsetX = newValues.left + newValues.width - vpWidth;
      if (offsetX > 0) {
        // @ts-ignore
        newValues.left -= offsetX;
      }
      const offsetY =
        newValues.top +
        Math.max(newValues.height, (containerCoords?.height || 0) + 8, 25) -
        vpHeight;
      if (offsetY > 0) {
        // @ts-ignore
        newValues.top -= offsetY;
      }

      setMeasures(newValues);
    }
  }, [coords, containerCoords]);

  return (
    <Modal
      visible
      transitionName=""
      maskClosable={false}
      maskStyle={{ backdropFilter: 'none' }}
      onCancel={onCancel}
      modalRender={() => (
        <div
          className="editable-attr-container"
          ref={containerRef}
          style={{
            ...getMeasures(),
            ...style,
          }}
        >
          <Form onSubmitCapture={onConfirm}>
            {children}

            {error && (
              <div className="floating-error-message">
                <ExclamationCircleOutlined size={20} />
                <div className="message-container">{error}</div>
              </div>
            )}

            <div className="floating-buttons-container">
              <div className="floating-buttons">
                <button
                  data-testid="floating-button-cancel"
                  className="floating-action-button button-close"
                  type="button"
                  onClick={onCancel}
                >
                  <CloseOutlined />
                </button>

                <button
                  data-testid="floating-button-submit"
                  className={classNames('floating-action-button button-confirm', { disabled })}
                  type="submit"
                  disabled={disabled}
                >
                  {loading ? (
                    <Spin size="small" indicator={<LoadingOutlined />} />
                  ) : (
                    <CheckOutlined />
                  )}
                </button>
              </div>
            </div>
          </Form>
        </div>
      )}
    />
  );
}

export default FloatingEditableAttribute;
