import type { New } from '@/domain/base';
import type { HoldingCompany } from '@/domain/holding-companies';
import { api } from '@/utils/configuration';
import { crudService, getAxiosFetcher } from '@/utils/request';

export const HoldingCompaniesService = {
  search: getAxiosFetcher<HoldingCompany[], []>(() => [api.HOLDING_COMPANIES]),

  create: (holdingCompany: New<HoldingCompany>) =>
    crudService.post<HoldingCompany>(holdingCompany, api.HOLDING_COMPANIES),

  update: (holdingCompany: HoldingCompany) =>
    crudService.put<HoldingCompany>(
      holdingCompany,
      `${api.HOLDING_COMPANIES}/${holdingCompany.id}`,
    ),
};
