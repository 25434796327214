import type { New } from '@/domain/base';
import type { SubSkill, TicketGroup, TicketGroupEmail } from '@/domain/ticket-groups';
import { api } from '@/utils/configuration';
import { crudService, getAxiosFetcher } from '@/utils/request';

export const TicketGroupService = {
  create: (ticketGroup: New<TicketGroup>) =>
    crudService.post<TicketGroup>(ticketGroup, api.TICKET_GROUPS),

  delete: (ticketGroupId: number) =>
    crudService.delete<void>(`${api.TICKET_GROUPS}/${ticketGroupId}`),

  findById: getAxiosFetcher<TicketGroup, [number]>((id) => [`${api.TICKET_GROUPS}/${id}`]),

  update: (ticketGroup: TicketGroup) =>
    crudService.put<TicketGroup>(ticketGroup, `${api.TICKET_GROUPS}/${ticketGroup.id}`),

  search: getAxiosFetcher<TicketGroup[], []>(() => [api.TICKET_GROUPS]),

  getSubSkills: getAxiosFetcher<SubSkill[], []>(() => [api.SUB_SKILLS]),

  findEmailById: getAxiosFetcher<TicketGroupEmail, [number]>((id) => [
    `${api.TICKET_GROUPS_EMAILS}/${id}`,
  ]),

  getEmails: getAxiosFetcher<TicketGroupEmail[], []>(() => [api.TICKET_GROUPS_EMAILS]),
};
