import type { ModalFormProps } from '@/components/Button/formButton';
import type { CommunityAccessInformation } from '@/domain/community';
import { makeStyles } from '@material-ui/core';
import { Button, Modal } from 'antd';
import type { FC } from 'react';

interface Props extends ModalFormProps {
  communityAccessInformation: CommunityAccessInformation;
}

const useStyles = makeStyles(() => ({
  title: {
    fontWeight: 600,
    fontSize: '1.3rem',
  },
  row: {
    marginBottom: '10px',
  },
}));

export const CommunityAccessInformationModal: FC<Props> = ({
  communityAccessInformation,
  onCancel,
}) => {
  const classes = useStyles();
  return (
    <Modal
      visible
      title={<div className={classes.title}>Access Information</div>}
      data-testid="community-access"
      closable
      maskClosable
      onCancel={onCancel}
      footer={<Button onClick={onCancel}>Close</Button>}
      width={650}
    >
      <div className={classes.row} data-testid="entrance-name">
        <b>Entrance Name</b>
        <div>{communityAccessInformation.entranceName}</div>
      </div>

      <div className={classes.row}>
        <b>Description</b>
        <div>{communityAccessInformation.description}</div>
      </div>

      <div className={classes.row}>
        <b>Code</b>
        <div>{communityAccessInformation.code}</div>
      </div>

      <div>
        <b>Emergency Contact</b>
        <div>{communityAccessInformation.emergencyContact}</div>
      </div>
    </Modal>
  );
};
