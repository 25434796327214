import type { ModalFormProps } from '@/components/Button/formButton';
import { AntDatePicker, AntInputNumber } from '@/components/Formik/CreateAntField';
import { CollectionPaymentsService } from '@/services/collection-payments';
import { handleError } from '@/utils/utils';
import { Form, message } from 'antd';
import Modal from 'antd/lib/modal/Modal';
import { Field, Formik } from 'formik';
import type { Moment } from 'moment';
import moment from 'moment';
import type { FC } from 'react';
import { useState } from 'react';
import * as Yup from 'yup';

interface Values {
  date: string;
  amount: number;
}

interface Props extends ModalFormProps {
  collectionId: number;
}

const validationSchema = Yup.object({
  date: Yup.string().min(1, 'Due Date is required').required('Due Date is required'),
  amount: Yup.number().required('Amount is required'),
});

const AddNextPaymentModal: FC<Props> = ({ collectionId, onSuccess, onCancel }) => {
  const [saving, setSaving] = useState(false);

  const submit = (values: Values) => {
    setSaving(true);

    CollectionPaymentsService.create(collectionId, {
      date: values.date,
      amount: values.amount,
    })
      .then(() => {
        message.success('The collection payment was successfully added');
        onSuccess();
      })
      .catch((error) => {
        handleError(error, {
          toastFallbackMessage: 'There was an error saving the next payment info',
        });
      })
      .finally(() => {
        setSaving(false);
      });
  };

  const handleCancel = () => {
    if (!saving) {
      onCancel?.();
    }
  };

  return (
    <Formik<Values>
      onSubmit={submit}
      initialValues={{
        date: '',
        amount: 0,
      }}
      validationSchema={validationSchema}
    >
      {({ handleSubmit, submitCount }) => (
        <Modal
          visible
          title="Add Next Payment"
          onOk={() => handleSubmit()}
          onCancel={handleCancel}
          okText="Save"
          okButtonProps={{ loading: saving }}
          maskClosable={false}
        >
          <Form onSubmitCapture={handleSubmit}>
            <Field
              component={AntDatePicker}
              label="Due Date"
              name="date"
              hasFeedback
              submitCount={submitCount}
              disabledDate={(date: Moment) => date.isBefore(moment().startOf('day'))}
            />
            <Field
              component={AntInputNumber}
              label="Amount"
              name="amount"
              hasFeedback
              submitCount={submitCount}
              min={0}
            />
          </Form>
        </Modal>
      )}
    </Formik>
  );
};

export default AddNextPaymentModal;
