export interface Settings {
  getColumnOrder: () => string[] | undefined;
  setColumnOrder: (columnOrder: string[]) => void;
  getColumnWidth: (columnKey: string) => number | undefined;
  setColumnWidth: (columnKey: string, width: number) => void;
  getVisibleColumns: () => { [columnKey: string]: boolean } | undefined;
  setVisibleColumns: (visibleColumns: { [columnKey: string]: boolean }) => void;
  getPinnedColumns: () => string[] | undefined;
  setPinnedColumns: (pinnedColumns: string[]) => void;
}

export const settings = (settingsKey: string): Settings => ({
  getColumnOrder: () => {
    const item = localStorage.getItem(`settings:${settingsKey}:columnOrder`);
    return item ? JSON.parse(item) : undefined;
  },
  setColumnOrder: (columnOrder: string[]) => {
    localStorage.setItem(`settings:${settingsKey}:columnOrder`, JSON.stringify(columnOrder));
  },
  getColumnWidth: (columnKey: string) => {
    const item = localStorage.getItem(`settings:${settingsKey}:columnWidths:${columnKey}`);
    return item ? JSON.parse(item) : undefined;
  },
  setColumnWidth: (columnKey: string, width: number) => {
    localStorage.setItem(
      `settings:${settingsKey}:columnWidths:${columnKey}`,
      JSON.stringify(width),
    );
  },

  getVisibleColumns: () => {
    const item = localStorage.getItem(`settings:${settingsKey}:visibleColumns`);
    return item ? JSON.parse(item) : undefined;
  },
  setVisibleColumns: (visibleColumns) => {
    localStorage.setItem(`settings:${settingsKey}:visibleColumns`, JSON.stringify(visibleColumns));
  },
  getPinnedColumns: () => {
    const item = localStorage.getItem(`settings:${settingsKey}:pinnedColumns`);
    return item ? JSON.parse(item) : undefined;
  },
  setPinnedColumns: (pinnedColumns: string[]) => {
    localStorage.setItem(`settings:${settingsKey}:pinnedColumns`, JSON.stringify(pinnedColumns));
  },
});
