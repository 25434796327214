import { ProcessType } from '@/domain/process';

export const getDisputeProcessLink = (id: number) => `/residents/disputes/${id}`;
export const getLeasingProcessLink = (id: number) => `/prospects/leasing/${id}`;
export const getListingProcessLink = (id: number) => `/properties/listings/${id}`;
export const getMoveInProcessLink = (id: number) => `/residents/move-ins/${id}`;
export const getMoveOutProcessLink = (id: number) => `/residents/move-outs/${id}`;
export const getTurnRehabProcessLink = (id: number) => `/maintenance/turn-rehabs/${id}`;
export const getUnitOnboardingProcessLink = (id: number) => `/properties/onboardings/${id}`;

export const getProcessLink = (processId: number, processType: ProcessType) => {
  switch (processType) {
    case ProcessType.DISPUTE:
      return getDisputeProcessLink(processId);
    case ProcessType.LEASING:
      return getLeasingProcessLink(processId);
    case ProcessType.LISTING_PROCESS:
      return getListingProcessLink(processId);
    case ProcessType.MOVE_IN:
      return getMoveInProcessLink(processId);
    case ProcessType.MOVE_OUT:
      return getMoveOutProcessLink(processId);
    case ProcessType.TURN_REHAB:
      return getTurnRehabProcessLink(processId);
    case ProcessType.UNIT_ONBOARDING_POST_CLOSING:
    case ProcessType.UNIT_ONBOARDING_PRE_CLOSING:
      return getUnitOnboardingProcessLink(processId);
    default:
      // This should never happen
      return '';
  }
};
