import { ModalFormButton } from '@/components/Button/formButton';
import ViewAllNotesModal from '@/components/Notes/ViewAllNotesModal';
import { EntityType } from '@/domain/entity-type';
import { Col, Input, Row, Typography } from 'antd';
import type { FC } from 'react';

const { Title } = Typography;

interface Props {
  onAddNote: (note: string) => void;
  unitId?: number;
  required?: boolean;
}
const { TextArea } = Input;

const NoteForm: FC<Props> = ({ onAddNote, unitId, required = false }) => {
  const onChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    onAddNote(e.target.value);
  };

  return (
    <Row gutter={[8, 8]}>
      <Col xs={24} style={{ display: 'flex', justifyContent: 'space-between' }}>
        <div>
          <Title level={4}>Add Note</Title>
        </div>
        {unitId && (
          <ModalFormButton buttonText="See all notes">
            {(properties) => (
              <ViewAllNotesModal
                {...properties}
                entityType={EntityType.UNIT}
                entityId={unitId}
                hideCreate
              />
            )}
          </ModalFormButton>
        )}
      </Col>
      <Col xs={24}>
        <TextArea
          style={{
            height: 90,
            background: '#EAF8FF',
            border: '1px solid #D8ECF6',
            borderRadius: '4px',
          }}
          onChange={onChange}
          required={required}
        />
      </Col>
    </Row>
  );
};

export default NoteForm;
