import type { LookupParams } from '@/domain/base';
import type { AccountValidations } from '@/domain/person';
import type { Prospect, ProspectBulkUpdateRequest, ProspectSearchResult } from '@/domain/prospect';
import LookupService from '@/services/lookup';
import { api } from '@/utils/configuration';
import { crudService, getAxiosFetcher } from '@/utils/request';
import type { Operation } from 'fast-json-patch';

export type ProspectSearchParams = {
  id?: number;
  partyId?: number;
  keywords?: string;
  createTime?: string;
  leadCreateTime?: string;
  sortBy?: string;
  limit?: number;
  minActiveLeadCount?: number;
  qualifiedLead?: boolean;
  ventureId?: number | number[];
};

export const ProspectService = {
  findById: getAxiosFetcher<Prospect, [id: number]>((id) => [`${api.PROSPECT}/${id}`]),

  search: getAxiosFetcher<ProspectSearchResult[], [ProspectSearchParams]>((params) => [
    api.PROSPECT,
    { params },
  ]),

  update: (prospect: Partial<Prospect>) =>
    crudService.put<Prospect>(prospect, `${api.PROSPECT}/${prospect.id}`),

  patch: (id: number, operations: Operation[], version: number) =>
    crudService.patch<Prospect>(operations, `${api.PROSPECT}/${id}?version=${version}`),

  canSchedule: getAxiosFetcher<boolean, [number]>((prospectId) => [
    `${api.PROSPECT}/${prospectId}/can-schedule`,
  ]),

  validations: getAxiosFetcher<AccountValidations, [number]>((prospectId) => [
    `${api.PROSPECT}/${prospectId}/validations`,
  ]),

  lookup: (params: LookupParams = {}) => LookupService.prospect(params),

  bulkUpdate: (request: ProspectBulkUpdateRequest) => crudService.put<void>(request, api.PROSPECT),
};
