import type { ApplicationStatus, ApplicationType, CreditRecommendation } from './applications';
import type { New, PropifyEntity } from './base';
import type { Note } from './note';
import type { Person } from './person';
import type { Unit } from './unit';

export type DeniedReasonType = 'CREDIT' | 'CRIMINAL_HISTORY' | 'RENTAL_HISTORY' | 'POTENTIAL_FRAUD';

export enum UnitApplicationStatus {
  NEW = 'NEW',
  HELD = 'HELD',
  UNDER_REVIEW = 'UNDER_REVIEW',
  APPROVED = 'APPROVED',
  LEASED = 'LEASED',
  DENIED = 'DENIED',
  CANCELLED = 'CANCELLED',
}

export interface UnitApplicationSearchParams {
  personId?: number;
  keywords?: string;
  unitId?: number[] | number;
  applicationId?: number[];
  status?: UnitApplicationStatus[];
  applicationStatus?: ApplicationStatus[];
  moveInDate?: string;
  leaseTerm?: string;
  usingVoucher?: boolean;
  state?: string[];
  residentId?: number[];
  preferredUserId?: number;
  type?: UnitApplicationType;
  reviewDueDate?: string;
  unitMarketRent?: number[] | string;
  createTime?: string;
  nextContactDueTime?: string;
  ventureId?: number;
}

export enum PetSex {
  M = 'M',
  F = 'F',
}

export enum PetType {
  CAT = 'CAT',
  DOG = 'DOG',
}

export enum UnitApplicationType {
  MARKET = 'MARKET',
  SUBSIDY = 'SUBSIDY',
}

export interface Occupant extends PropifyEntity {
  unitApplicationId?: number;
  dateOfBirth: string;
}

export interface Vehicle extends PropifyEntity {
  unitApplicationId?: number;
  make: string;
  model: string;
  year: number;
  color: string;
  licensePlate: string;
  state: string;
}

export interface Pet extends PropifyEntity {
  unitApplicationId?: number;
  type: PetType;
  weight: number;
  age: number;
  color: string;
  sex: PetSex;
  spayedNeutered: boolean;
  serviceAnimal: boolean;
  breed: string;
  name: string;
}

export interface UnitApplication extends PropifyEntity {
  unitId: number;
  type: UnitApplicationType;
  status: UnitApplicationStatus;
  moveInDate: string;
  leaseTerm: number;
  usingVoucher: boolean;
  preferredUserId?: number;
  resolvedTime: string;
  resolvedByLoginId: number;
  resolution: string;
  reviewDueDate?: string;
  occupants: Occupant[];
  invitationEmailAddresses: string[];
  applications: UnitApplicationApplication[];
  applicationIds: number[];
  pets: Pet[];
  vehicles: Vehicle[];
  primaryApplicant?: Person;
  primaryApplicantId?: number;
  deniedReasonType?: DeniedReasonType;
  terminationDescription?: string;
}

export interface UnitApplicationSearchResult extends UnitApplication {
  unit: Unit;
  creditRecommendation: CreditRecommendation;
  criminalRecommendation: boolean;
  backgroundRecommendation: boolean;
  idApproval: boolean;
  creditApproval: boolean;
  criminalApproval: boolean;
  backgroundApproval: boolean;
  totalApplicants: number;
  pendingApplicants: number;
  approvedApplicants: number;
  deniedApplicants: number;
  rentToVerifiedIncome?: number;
  nextContactDueTime?: string;
  lastNote?: Note;
  lastContact?: string;
  lastSubmission?: string;
}

export interface UnitApplicationAnalytics {
  otherActiveUnitApplications: number;
  otherApprovedUnitApplications: number;
  totalValidatedIncome: number;
  rentToVerifiedIncome?: number;
}

export interface UnitApplicationCancelRequest {
  comment?: string;
}

export interface UnitApplicationDeniedRequest extends UnitApplicationCancelRequest {
  deniedReasonType: DeniedReasonType;
  terminationDescription?: string;
}

export enum UnitApplicationApplicationStatus {
  PENDING = 'PENDING',
  REJECTED = 'REJECTED',
  ACCEPTED = 'ACCEPTED',
  CANCELLED = 'CANCELLED',
}

export interface UnitApplicationApplication extends PropifyEntity {
  unitApplicationId: number;
  applicationId?: number;
  type: ApplicationType;
  emailAddress?: string;
  status?: UnitApplicationApplicationStatus;
}

export type CreateUnitApplicationApplicationRequest = New<
  Omit<UnitApplicationApplication, 'unitApplicationId' | 'applicationId' | 'status'>
>;
