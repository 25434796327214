import type { ModalFormProps } from '@/components/Button/formButton';
import ExternalLink from '@/components/ExternalLink';
import EditablePage from '@/components/Formik/EditablePage';
import SectionSplashScreen from '@/components/PageLoading/SplashSection';
import { useAppContext } from '@/contexts/AppContext';
import type { PromotionSearchResult } from '@/domain/promotions';
import { PromotionType } from '@/domain/promotions';
import type { Unit } from '@/domain/unit';
import { UnitApplicationStatus } from '@/domain/unit-applications';
import useVentures from '@/hooks/useVentures';
import { PromotionService } from '@/services/promotion';
import { UnitApplicationsService } from '@/services/unit-applications';
import { UnitService } from '@/services/units';
import { useFetch } from '@/utils/request';
import { blue } from '@/utils/styles/colors';
import { getAddressDescription, handleError } from '@/utils/utils';
import { InfoCircleFilled, LinkOutlined } from '@ant-design/icons';
import { formatCurrency } from '@propify/components';
import {
  Button,
  Checkbox,
  Col,
  Input,
  message,
  Modal,
  Row,
  Space,
  Tooltip,
  Typography,
} from 'antd';
import { Big } from 'big.js';
import type { Operation } from 'fast-json-patch';
import type { FC } from 'react';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import { getRentAdjustment } from '../../utils';
import { formatAdjValue } from '../RentAdjValue';
import AssociatedPromotionsTable from './AssociatedPromotionsTable';
import NoteForm from './NoteForm';
import OtherPromotionsTable from './OtherPromotionsTable';

interface Props extends ModalFormProps<[Unit]> {
  unitId: number;
}

const commonAttributes = {
  readOnly: true,
  colSize: 6,
};

const border = '2px solid #EBF0F4';
const renderLink = (href: string | undefined, content: any) => {
  if (!href) {
    return '';
  }
  return <ExternalLink href={href}>{content}</ExternalLink>;
};

const AdjustRentModal: FC<Props> = ({ onCancel, unitId, onSuccess }) => {
  const [otherPromotions, setOtherPromotions] = useState<PromotionSearchResult[]>([]);
  const [associatedPromotions, setAssociatedPromotions] = useState<PromotionSearchResult[]>([]);
  const [newNote, setNewNote] = useState('');
  const [newMarketRentString, setNewMarketRent] = useState('0');
  const [updatingUnit, setUpdatingUnit] = useState(false);
  const { ventureNames } = useVentures();
  const { brands, users } = useAppContext();
  const [priceReviewCompleted, setPriceReviewCompleted] = useState(true);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);

  const newMarketRent = useMemo(() => +newMarketRentString, [newMarketRentString]);

  const getBrandValue = (brandId: number | undefined) => {
    if (!brandId) {
      return '';
    }

    return brands.length > 0 ? brands.find((brand) => brand.id === brandId)?.name : '';
  };

  const { data: pricingResult, isValidating: loadingPricingResult } = useFetch(
    {
      fetcher: UnitService.searchForPricing,
      errorMessage: 'There was an error fetching the unit pricing',
    },
    { id: [unitId] },
  );

  const { data: promotionsResult, isValidating: loadingPromotionsResult } = useFetch({
    fetcher: PromotionService.search,
    errorMessage: 'There was an error fetching the promotions',
  });

  const unit = useMemo(() => pricingResult && pricingResult[0], [pricingResult]);

  useEffect(() => {
    if (!promotionsResult || !unit) {
      return;
    }
    setAssociatedPromotions(promotionsResult.filter((p) => unit.promotionIds?.includes(p.id)));
    setOtherPromotions(promotionsResult.filter((p) => !unit.promotionIds?.includes(p.id)));

    setNewMarketRent((unit.marketRent || 0) + '');
  }, [unit, promotionsResult]);

  const onAddPromotion = (id: number) => {
    const promotion = promotionsResult?.find((p) => p.id === id);

    if (!promotion) {
      return;
    }

    const newAssociatedPromotions = [...associatedPromotions, promotion];
    setAssociatedPromotions(newAssociatedPromotions);
    setOtherPromotions([...otherPromotions.filter((p) => p.id !== id)]);
  };

  const onRemovePromotion = (id: number) => {
    const promotion = promotionsResult?.find((p) => p.id === id);

    if (!promotion) {
      return;
    }

    const newOtherPromotions = [...otherPromotions, promotion];
    setOtherPromotions(newOtherPromotions);
    setAssociatedPromotions([...associatedPromotions.filter((p) => p.id !== id)]);
  };

  const onAddNote = (note: string) => {
    setNewNote(note);
  };

  const link = unit?.property.address?.postalCode
    ? `https://www.zillow.com/homes/for_rent/${unit?.property.address?.postalCode}_rb/`
    : undefined;

  const save = async () => {
    if (!unit) {
      return;
    }

    const operations: Operation[] = [
      {
        op: 'replace',
        path: '/promotionIds',
        value: associatedPromotions.map((p) => p.id),
      },
      {
        op: 'replace',
        path: '/marketRent',
        value: newMarketRent,
      },
    ];

    if (newNote) {
      operations.push({
        op: 'add',
        path: '/notes/-',
        value: { note: newNote, type: 'PRICING' },
      });
    }

    try {
      setUpdatingUnit(true);
      const updatedUnit = await UnitService.patch(unit.id, operations, unit.version);
      if (priceReviewCompleted) {
        await UnitService.completeReview(unit.id);
      }
      message.success('Unit updated successfully');
      onSuccess(updatedUnit);
    } catch (error) {
      handleError(error, { toastFallbackMessage: 'There was an error updating the unit' });
    } finally {
      setUpdatingUnit(false);
    }
  };

  const handleSave = async () => {
    if (!unit) {
      return;
    }

    setUpdatingUnit(true);

    // Checks for open unit applications
    try {
      const unitApps = await UnitApplicationsService.search({ unitId: unit.id });
      /** @see https://skyboxcapital.atlassian.net/browse/PR-1617 */
      const hasOpenUnitApps = unitApps.some(
        (ua) =>
          ![
            UnitApplicationStatus.CANCELLED,
            UnitApplicationStatus.DENIED,
            UnitApplicationStatus.LEASED,
          ].includes(ua.status),
      );

      if (hasOpenUnitApps) {
        setShowConfirmationModal(true);
        return;
      }
    } catch (error) {
      handleError(error, {
        toastFallbackMessage: 'There was an error checking for open unit applications',
      });
      setUpdatingUnit(false);
      return;
    }

    save();
  };

  const rentAdjustmentValue = useMemo(
    () => getRentAdjustment(newMarketRentString ? newMarketRent : 0, associatedPromotions),
    [newMarketRentString, newMarketRent, associatedPromotions],
  );

  const displayRentValue = useMemo(
    () =>
      formatCurrency(Big(newMarketRent || 0).minus(rentAdjustmentValue), {
        noEmptyDecimals: true,
      }),
    [newMarketRent, rentAdjustmentValue],
  );

  const getAdjustmentRentValue = useCallback(
    (promotion: PromotionSearchResult) => {
      let value = Big(0);
      if (promotion.amount > 0 && promotion.adjustListedRent) {
        if (promotion.type === PromotionType.FIXED_AMOUNT) {
          value = Big(promotion.amount);
        }

        if (promotion.type === PromotionType.RENT_MULTIPLE) {
          value = Big(newMarketRent || 0).times(promotion.amount);
        }
      }

      return value.div(12);
    },
    [newMarketRent],
  );

  const isMarketRentValid = newMarketRentString && !isNaN(newMarketRent) && newMarketRent > 0;

  return (
    <>
      <Modal
        visible
        title={
          unit && (
            <>
              Adjust Rent for{' '}
              <Link to={`/properties/${unit.property?.id}`}>
                {getAddressDescription(unit.property?.address)}
              </Link>
            </>
          )
        }
        onCancel={onCancel}
        footer={[
          <Checkbox
            key="checkbox"
            defaultChecked={true}
            value={priceReviewCompleted}
            onChange={() =>
              setPriceReviewCompleted((prevPriceReviewCompleted) => !prevPriceReviewCompleted)
            }
          >
            Price Review Completed
          </Checkbox>,
          <Button type="default" htmlType="button" key="close" onClick={onCancel}>
            Close
          </Button>,
          <Button
            key="save"
            htmlType="submit"
            onClick={handleSave}
            type="primary"
            disabled={updatingUnit || (priceReviewCompleted && !newNote) || !isMarketRentValid}
            loading={updatingUnit}
          >
            {updatingUnit ? 'Saving...' : 'Save'}
          </Button>,
        ]}
        maskClosable={false}
        width={1180}
      >
        {loadingPricingResult || loadingPromotionsResult ? (
          <SectionSplashScreen />
        ) : (
          <Row gutter={[24, 24]}>
            <Col xs={24}>
              <EditablePage
                key={`unit-details-${unit?.id}`}
                fullWidth
                sections={[
                  {
                    title: '',
                    type: 'EDITABLE_ATTRIBUTES',
                    attributes: [
                      {
                        value: ventureNames[unit?.property.ventureId || 0] ?? '',
                        fieldName: 'venture',
                        label: 'Venture',
                        type: 'Text',
                        ...commonAttributes,
                      },
                      {
                        value: getBrandValue(unit?.property.brandId),
                        fieldName: 'brand',
                        label: 'Brand',
                        type: 'Text',
                        ...commonAttributes,
                      },
                      {
                        value: unit?.zillowEstimatedRent,
                        fieldName: 'zillowEstimatedRent',
                        label: 'Zestimate',
                        type: 'Number',
                        format: formatCurrency,
                        ...commonAttributes,
                      },
                      {
                        value: unit?.zillowCompLink
                          ? renderLink(unit?.zillowCompLink, <LinkOutlined />)
                          : renderLink(link, 'View'),
                        fieldName: 'zillowCompLink',
                        label: 'Comp. Link',
                        type: 'Text',
                        ...commonAttributes,
                      },
                      {
                        value: unit?.previousMarketRent,
                        fieldName: 'previousMarketRent',
                        label: 'Previous Listed Rent',
                        type: 'Number',
                        format: formatCurrency,
                        ...commonAttributes,
                      },
                      {
                        value: unit?.lastMarketRentUpdateTime,
                        fieldName: 'lastMarketRentUpdateTime',
                        label: 'Last Rent Update',
                        type: 'Date',
                        ...commonAttributes,
                      },
                      {
                        value: users.find((user) => user.loginId === unit?.lastRentUpdatedBy)
                          ?.displayName,
                        fieldName: 'lastRentUpdatedBy',
                        label: 'Last Update By',
                        type: 'Text',
                        ...commonAttributes,
                      },
                      {
                        value: unit?.daysOnMarket,
                        fieldName: 'daysOnMarket',
                        label: 'DOM',
                        type: 'Text',
                        ...commonAttributes,
                      },
                    ],
                  },
                ]}
              />
            </Col>
            <Col xs={8}>
              <Space direction="vertical" className="full-width">
                <Typography.Text strong>Base Rent</Typography.Text>
                <Input
                  type="number"
                  className="full-width hide-arrows-number-input"
                  style={{ background: '#EAF8FF', width: '100%' }}
                  value={newMarketRentString}
                  onChange={(e) => setNewMarketRent(e.target.value.replace(',', '.'))}
                  addonBefore="$"
                />
              </Space>
            </Col>
            <Col xs={8}>
              <Space direction="vertical" size={12}>
                <Typography.Text strong>Display Rent Adjustment</Typography.Text>
                <Space>
                  <span>{formatAdjValue(rentAdjustmentValue.times(-1))}</span>
                  <Tooltip title="The monthly amount by which applied promotions impact the Display Rent of the unit. Calculated assuming a 12 month lease.">
                    <InfoCircleFilled style={{ color: blue }} />
                  </Tooltip>
                </Space>
              </Space>
            </Col>
            <Col xs={8}>
              <Space direction="vertical" size={12}>
                <Typography.Text strong>Display Rent Adjustment</Typography.Text>
                {displayRentValue}
              </Space>
            </Col>
            <Col xs={24} style={{ borderTop: border, marginTop: 8 }}>
              <AssociatedPromotionsTable
                associatedPromotions={associatedPromotions}
                onRemovePromotion={onRemovePromotion}
                getAdjustmentRentValue={getAdjustmentRentValue}
              />
            </Col>
            <Col
              xs={24}
              style={{ paddingBottom: 4, paddingTop: 16, borderTop: border, borderBottom: border }}
            >
              <OtherPromotionsTable
                otherPromotions={otherPromotions}
                onAddPromotion={onAddPromotion}
                getAdjustmentRentValue={getAdjustmentRentValue}
              />
            </Col>

            <Col xs={24}>
              <NoteForm onAddNote={onAddNote} unitId={unit?.id} required />
            </Col>
          </Row>
        )}
      </Modal>

      <Modal
        visible={showConfirmationModal}
        onOk={() => {
          setShowConfirmationModal(false);
          save();
        }}
        onCancel={() => {
          setShowConfirmationModal(false);
          setUpdatingUnit(false);
        }}
        okText="Save"
      >
        One or more Unit Applications for this Unit are in an open status.
        <br />
        Are you sure you wish to proceed with updating the Unit&apos;s Display Price?
      </Modal>
    </>
  );
};

export default AdjustRentModal;
