import type { Column, TableRef } from '@/@propify-components';
import FilterBar from '@/components/FilterBarWithURLQuery';
import {
  FilterType,
  type Filter,
  type FilterValuesType,
} from '@/components/FilterBarWithURLQuery/types';
import ExportButton from '@/components/TableUtils/ExportButton';
import { useAppContext } from '@/contexts/AppContext';
import type { PropertySearchResult } from '@/domain/property';
import { usePropertyGroups } from '@/hooks/services/usePropertyGroups';
import useEnumeration from '@/hooks/useEnumeration';
import useStates from '@/hooks/useStates';
import useVentures from '@/hooks/useVentures';
import { HoldingCompaniesService } from '@/services/holding-companies';
import { PropertyService } from '@/services/property';
import type { Settings } from '@/services/settings';
import { useFetch } from '@/utils/request';
import { enumToLabel } from '@/utils/utils';
import { useMemo, type FC, type RefObject } from 'react';

export type FilterParams = {
  keyword?: string;
  transactionStatus?: string | string[] | null;
  state?: string | string[] | null;
  msa?: string | string[] | null;
  brandId?: string | string[] | null;
  ventureId?: string | string[] | null;
  city?: string | string[] | null;
  holdingCompanyId?: string | string[] | null;
};

interface Props {
  tableRef?: RefObject<TableRef<PropertySearchResult, number>>;
  columns: Column<PropertySearchResult>[];
  setColumns?: (columns: Column<PropertySearchResult>[]) => void;
  tableSettings: Settings;
  properties: PropertySearchResult[];
  filters: FilterParams;
  setFilters: (values: FilterValuesType) => void;
  showExport?: boolean;
}

const PropertiesTableFilters: FC<Props> = ({
  tableRef,
  columns,
  setColumns,
  tableSettings,
  filters,
  setFilters,
  properties,
  showExport = true,
}) => {
  const { ventureOptions } = useVentures();
  const { brands } = useAppContext();
  const { data: transactionStatuses } = useEnumeration('transaction-status');
  const { stateOptions } = useStates();

  const { data: holdingCompanies } = useFetch({
    fetcher: HoldingCompaniesService.search,
    errorMessage: 'There was an error getting holding companies',
  });

  const { data: cities } = useFetch({
    fetcher: PropertyService.cities,
    errorMessage: 'There was an error getting cities',
  });

  const { data: msas } = useFetch({
    fetcher: PropertyService.msas,
    errorMessage: 'There was an error getting msas',
  });

  const { data: propertyGroups } = usePropertyGroups();

  const filtersFields: Filter[] = useMemo(
    () => [
      {
        key: 'state',
        label: 'State',
        formatTag: (option: string | undefined) => (option ? enumToLabel(option) : 'State'),
        type: FilterType.SELECT,
        options: stateOptions,
      },
      {
        key: 'transactionStatus',
        label: 'Status',
        formatTag: (option: string | undefined) => (option ? enumToLabel(option) : 'TX Status'),
        type: FilterType.SELECT,
        options: transactionStatuses.map((stat) => ({
          label: stat.displayName,
          value: stat.value,
        })),
        required: true,
      },
      {
        key: 'propertyGroupId',
        label: 'Property Group',
        formatTag: (option: string | undefined) => (option ? enumToLabel(option) : 'TX Status'),
        type: FilterType.SELECT,
        options: (propertyGroups || []).map((stat) => ({
          label: stat.name,
          value: `${stat.id}`,
        })),
      },
      {
        key: 'hasLeaseDocumentTemplates',
        label: 'Lease Template?',
        type: FilterType.BOOLEAN_OPTIONAL,
      },
      {
        type: FilterType.SELECT,
        key: 'ventureId',
        label: 'Venture',
        options: ventureOptions,
      },
      {
        key: 'msa',
        label: 'MSA',
        formatTag: (option: string | undefined) => (option ? enumToLabel(option) : 'MSA'),
        type: FilterType.SELECT,
        options: msas?.map((stat) => ({ label: stat, value: stat })) || [],
      },
      {
        type: FilterType.SELECT,
        key: 'brandId',
        label: 'Brand',
        formatTag: (option: string | undefined) => (option ? enumToLabel(option) : 'Brand'),
        options: brands.map((brand) => ({
          label: brand.name,
          value: `${brand.id}`,
        })),
      },
      {
        key: 'holdingCompanyId',
        label: 'HoldCo',
        type: FilterType.SELECT,
        formatTag: (option: string | undefined) => (option ? option : 'Holding Company'),
        options:
          holdingCompanies?.map((hoa) => ({
            label: hoa.name,
            value: `${hoa.id}`,
          })) || [],
      },
      {
        key: 'city',
        label: 'City',
        formatTag: (option: string | undefined) => (option ? enumToLabel(option) : 'City'),
        type: FilterType.SELECT,
        options: cities?.map((city) => ({ label: city, value: city })) || [],
        showSearch: true,
      },
    ],
    [
      stateOptions,
      transactionStatuses,
      ventureOptions,
      msas,
      brands,
      cities,
      holdingCompanies,
      propertyGroups,
    ],
  );

  return (
    <FilterBar
      values={filters}
      filters={filtersFields}
      onFilterApplied={setFilters}
      myFilterKey="properties"
      searchFilterKey="keyword"
      searchPlaceholder="Search Keywords, Phone, Email"
      searchInputWidth="280px"
      columnSelectorProps={
        setColumns
          ? {
              columns: columns,
              setColumns: setColumns,
              settings: tableSettings,
            }
          : undefined
      }
      actions={
        showExport && tableRef
          ? [
              <ExportButton
                key="export-button"
                tableRef={tableRef}
                filename="properties"
                totalResults={properties?.length}
              />,
            ]
          : undefined
      }
    />
  );
};

export default PropertiesTableFilters;
