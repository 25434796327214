import type { CreateDocumentTemplateResponse } from '@/domain/document-templates';
import type { GlobalActionComponentProps } from '@/notmagic/components/EntityTable/types';
import { DocumentTemplatesService } from '@/services/document-templates';
import { useFetch } from '@/utils/request';
import { handleError } from '@/utils/utils';
import type { FC } from 'react';
import { useState } from 'react';
import type { DocumentTemplateValues } from './AddDocumentTemplateModalStep1';
import AddDocumentTemplateModalStep1 from './AddDocumentTemplateModalStep1';
import AddDocumentTemplateModalStep2 from './AddDocumentTemplateModalStep2';

const AddDocumentTemplate: FC<GlobalActionComponentProps> = ({ onFinish, refreshData }) => {
  const [showDocumentTemplateStep2, setShowDocumentTemplateStep2] = useState(false);
  const [loadingDocumentTemplate, setLoadingDocumentTemplate] = useState(false);
  const [file, setFile] = useState<File>();
  const [request, setRequest] = useState<DocumentTemplateValues>({
    name: '',
    description: '',
    signers: 8,
  });
  const [documentTemplate, setDocumentTemplate] = useState<CreateDocumentTemplateResponse>();

  const { data: config } = useFetch({
    fetcher: DocumentTemplatesService.config,
    errorMessage: 'There was an error while loading the document template config',
  });

  const createDocumentTemplate = (fileValue: File, requestValue: DocumentTemplateValues) => {
    setLoadingDocumentTemplate(true);
    DocumentTemplatesService.create(fileValue, requestValue)
      .then((response) => {
        setDocumentTemplate(response);
        setShowDocumentTemplateStep2(true);
        setLoadingDocumentTemplate(false);
      })
      .catch((error) => {
        handleError(error, {
          toastFallbackMessage: 'There was an error trying to get the template document',
        });
      });
  };

  const handleCancel = () => {
    if (documentTemplate) {
      DocumentTemplatesService.delete(documentTemplate.id)
        .then(() => {
          onFinish();
        })
        .catch((error) => {
          handleError(error, {
            toastFallbackMessage: 'There was an error trying to delete the template document',
          });
        });
    } else {
      onFinish();
    }
  };

  const handleBack = () => {
    if (documentTemplate) {
      DocumentTemplatesService.delete(documentTemplate.id)
        .then(() => {
          setShowDocumentTemplateStep2(false);
        })
        .catch((error) => {
          handleError(error, {
            toastFallbackMessage: 'There was an error trying to delete the template document',
          });
        });
    }
  };

  if (showDocumentTemplateStep2 && config && documentTemplate) {
    return (
      <AddDocumentTemplateModalStep2
        onCancel={handleCancel}
        onBack={handleBack}
        config={config}
        documentTemplateURL={documentTemplate.url}
        documentTemplateTitle={documentTemplate.name}
        refreshData={refreshData}
        onSuccess={onFinish}
      />
    );
  }

  return (
    <AddDocumentTemplateModalStep1
      initialValues={request}
      fileValue={file}
      onSuccess={(val) => {
        const { name, description, signers } = val;
        setRequest({
          name,
          description,
          signers,
        });
        setFile(val.file);
        createDocumentTemplate(val.file, {
          name,
          description,
          signers,
        });
      }}
      onCancel={handleCancel}
      loadingDocumentTemplate={loadingDocumentTemplate}
    />
  );
};

export default AddDocumentTemplate;
