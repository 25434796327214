import type {
  FilterComponentType,
  FilterComponentTypeProps,
} from '@/notmagic/components/EntityTable/types';
import { Select } from 'antd';

type Props = FilterComponentTypeProps;

const Component: FilterComponentType<Props> = ({ editable, value, onChangeValue }) => {
  if (!editable) {
    return <>{value?.join(', ')}</>;
  }

  return (
    <Select
      mode="tags"
      value={value}
      onChange={onChangeValue}
      style={{ width: '100%' }}
      allowClear
    />
  );
};

export default {
  Component,
  jsonataQuery: (value: string, key: string) => `$v.${key} = '${value}'`,
};
