import type { SavedFilter } from '@/domain/saved-filter';
import { SavedFilterService } from '@/services/saved-filter';
import {
  dateRangeToQueryParam,
  handleError,
  mapDateRange,
  queryParamToDateRange,
} from '@/utils/utils';
import { CloseCircleFilled, DeleteOutlined, DownOutlined } from '@ant-design/icons';
import FilterListIcon from '@material-ui/icons/FilterList';
import {
  Button,
  Col,
  Drawer,
  Empty,
  Input,
  message,
  Popconfirm,
  Popover,
  Radio,
  Row,
  Tabs,
  Typography,
} from 'antd';
import type { FormikProps, FormikValues } from 'formik';
import { Formik } from 'formik';
import cloneDeep from 'lodash/cloneDeep';
import type { Moment } from 'moment';
import moment from 'moment';
import type { FC, ReactNode } from 'react';
import { useEffect, useMemo, useRef, useState } from 'react';
import SectionSplashScreen from '../PageLoading/SplashSection';
import DisplayColumns from '../TableUtils/DisplayColumns';
import type { DisplayColumnsProps } from './DisplayColumns';
import FilterDrawerField from './FilterDrawerField';
import SearchField from './SearchField';
import './styles.less';
import type { Filter, FilterValuesType } from './types';
import { FilterType } from './types';
import { getSubmittableValues } from './utils';

const { Text, Title } = Typography;

const { TabPane } = Tabs;

interface Props {
  filters: Filter[];
  onFilterApplied: (filters: FilterValuesType) => void;
  searchFilterKey?: string;
  columnSelectorProps?: DisplayColumnsProps;
  exportResultsButton?: boolean;
  onSearch?: (value: string) => void;
  myFilterKey: string;
  values?: FilterValuesType;
  searchPlaceholder?: string;
  actions?: ReactNode[];
  searchInputWidth?: string;
}

const FilterBar: FC<Props> = ({
  filters,
  searchFilterKey,
  columnSelectorProps,
  onFilterApplied,
  onSearch,
  myFilterKey,
  values,
  searchPlaceholder = 'Search',
  actions = [],
  searchInputWidth = '220px',
}) => {
  const filtersMap = useMemo<Record<string, Filter>>(
    () => filters.reduce((map, filter) => ({ ...map, [filter.key]: filter }), {}),
    [filters],
  );
  const [panelVisible, setPanelVisible] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const [filterTab, setFilterTab] = useState<'allFilters' | 'myFilters'>('allFilters');
  const [filterName, setFilterName] = useState('');
  const [loadingMyFilters, setLoadingMyFilters] = useState(false);
  const [myFilters, setMyFilters] = useState<SavedFilter[]>([]);
  const [filterSelected, setFilterSelected] = useState(0);
  const [filterSelectedActive, setFilterSelectedActive] = useState('');
  const [visible, setVisible] = useState(false);
  const [currentFilter, setCurrentFilter] = useState<FormikValues | undefined>({});

  const formRef = useRef<FormikProps<FormikValues>>(null);

  const updateValues = (currentValues: FilterValuesType = {}) => {
    return {
      ...(searchFilterKey ? { [searchFilterKey]: searchValue } : {}),
      ...currentValues,
    };
  };

  useEffect(() => {
    setCurrentFilter(cloneDeep(formRef.current?.values));
  }, [formRef.current?.values]);

  const [filterValues, setFilterValues] = useState<FilterValuesType>({});

  useEffect(() => {
    setFilterValues(updateValues(values));
    setFilterSelectedActive('');
  }, [values]);

  const activeFilters = useMemo(() => {
    const list: string[] = [];
    // eslint-disable-next-line no-restricted-syntax
    for (const key in filterValues) {
      if (
        (filterValues[key] || typeof filterValues[key] === 'boolean') &&
        key !== searchFilterKey
      ) {
        list.push(key);
      }
    }
    return list;
  }, [filterValues, searchFilterKey]);

  const openFilterPanel = () => setPanelVisible(true);
  const closeFilterPanel = () => setPanelVisible(false);

  const submit = (submitValues: FilterValuesType) => {
    setFilterValues(submitValues);
    onFilterApplied(getSubmittableValues(submitValues, filters, searchFilterKey));
    closeFilterPanel();
  };

  const handleSearch = (value: string) => {
    setSearchValue(value);
    submit(
      updateValues({
        ...filterValues,
        ...(searchFilterKey ? { [searchFilterKey]: value } : {}),
      }),
    );
  };

  const clearFilters = () => {
    formRef.current?.resetForm();
    submit(updateValues({}));
    setFilterSelected(0);
  };

  const handleCloseTag = (key: string) => {
    const filter = filtersMap[key];
    if (filter) {
      let updatedValues;
      if (filter.fatherOf) {
        updatedValues = updateValues({
          ...filterValues,
          [key]: undefined,
          [filter.fatherOf]: undefined,
        });
      } else {
        updatedValues = updateValues({
          ...filterValues,
          [key]: undefined,
        });
      }
      submit(updatedValues);
      setFilterSelected(0);
    }
  };

  const getInitialValues = () =>
    filters
      .filter((filter) => !filter.hide)
      .reduce(
        (hash, filter) => ({
          ...hash,
          [filter.key]: values?.[filter.key],
        }),
        {},
      );

  const loadMyFilters = () => {
    setLoadingMyFilters(true);
    SavedFilterService.search({ key: myFilterKey })
      .then(setMyFilters)
      .catch((error) => {
        handleError(error, {
          toastFallbackMessage: 'There has been an error while loading my filters',
        });
      })
      .finally(() => {
        setLoadingMyFilters(false);
      });
  };

  useEffect(() => {
    if (filterTab === 'myFilters') {
      loadMyFilters();
    }
  }, [filterTab]);

  const handleAddFilter = () => {
    let parameters = {};

    Object.entries(formRef.current?.values || {}).forEach(([key, value]) => {
      if (value === undefined || value === null) {
        return;
      }

      if (Array.isArray(value) && moment.isMoment(value[0])) {
        parameters = {
          ...parameters,
          [key]: dateRangeToQueryParam(value as [Moment, Moment]),
        };
      } else {
        parameters = { ...parameters, [key]: value };
      }
    });

    SavedFilterService.create({
      key: myFilterKey,
      name: filterName,
      parameters,
    })
      .then(() => {
        message.success('Filter successfully saved.');
      })
      .catch((error) => {
        handleError(error, {
          toastFallbackMessage: 'There has been an error while saving the filter',
        });
      });
  };

  const handledFilter = (id: number) => setFilterSelected(id);

  const handleDeleteFilter = (id: number) => {
    SavedFilterService.delete(id)
      .then(() => {
        message.success('Filter successfully removed.');
        loadMyFilters();
      })
      .catch((error) => {
        handleError(error, {
          toastFallbackMessage: 'There has been an error while deleting my filter',
        });
      });
  };

  const getFormBody = () => {
    if (filterTab === 'allFilters') {
      return filters
        .filter((filter) => !filter.hide)
        .map((filter) => {
          return (
            <div
              key={filter.key}
              data-testid={`filter-drawer-item-${filter.key}`}
              className="filter-drawer-item"
            >
              <FilterDrawerField
                filter={filter}
                formProps={formRef.current!}
                onChange={(newValue) => {
                  formRef.current?.setFieldValue(filter.key, newValue);
                  setFilterSelected((prevFilterSelected) =>
                    prevFilterSelected === 0 ? filterSelected : 0,
                  );
                }}
                value={formRef.current?.values[filter.key]}
              />
            </div>
          );
        });
    }

    if (loadingMyFilters) {
      return <SectionSplashScreen />;
    }

    return myFilters.length > 0 ? (
      myFilters.map((filter) => (
        <Row key={filter.id} gutter={[8, 8]}>
          <Col span={2}>
            <Radio
              checked={filterSelected === filter.id}
              onClick={() => handledFilter(filter.id)}
            />
          </Col>
          <Col span={19}>{filter.name}</Col>
          <Col span={3}>
            <Popconfirm
              title="Are you sure to delete this saved filter?"
              onConfirm={() => {
                handleDeleteFilter(filter.id);
              }}
              okText="Yes"
              cancelText="No"
            >
              <DeleteOutlined />
            </Popconfirm>
          </Col>
        </Row>
      ))
    ) : (
      <Empty
        description={
          <div>
            <Title level={4}>You have no saved filters.</Title>
            <Text>You can create you own combination of filters and save it for future use.</Text>
          </div>
        }
      />
    );
  };

  const getValue = (filter: Filter) => {
    if (filter.type === FilterType.DATE_RANGE) {
      return `${filter.label}: ${mapDateRange(filterValues[filter.key])}`;
    }

    if (filter.type === FilterType.BOOLEAN_OPTIONAL) {
      const keyValue = filterValues[filter.key];

      const booleanValue = typeof keyValue === 'string' ? keyValue === 'true' : keyValue;

      return `${filter.label}: ${booleanValue ? 'Yes' : 'No'}`;
    }

    if (filter.type === FilterType.SELECT) {
      const options = filter.optionsForGetValue || filter.options;

      if (filter.multiple) {
        return `${filter.label}: ${options
          .filter((o) => {
            const value = filterValues[filter.key];
            if (typeof value === 'string') {
              return value === o.value;
            }
            return value?.includes(o.value);
          })
          .map((o) => o.label)
          .join(', ')}`;
      }
      return `${filter.label}: ${
        options.find((o) =>
          typeof o.value === 'string'
            ? o.value === filterValues[filter.key]?.toString()
            : o.value === filterValues[filter.key],
        )?.label
      }`;
    }

    return `${filter.label}: ${filterValues[filter.key]}`;
  };

  const handleVisibleChange = (visibleValue: boolean) => {
    setVisible(visibleValue);
  };

  const getFilterActive = () => {
    if (activeFilters.length === 0) {
      return '';
    }

    return activeFilters.map((activeFilter) => {
      const filter = filters.find((f) => f.key === activeFilter)!;
      if (!filter || filter.hide) {
        return null;
      }

      const filterBox = (
        <div
          data-testid={`active-filter-${activeFilter}`}
          className="filter-active-container"
          key={activeFilter}
          style={
            filterSelectedActive === activeFilter ? { background: '#1890ff', color: 'white' } : {}
          }
          onClick={() => setFilterSelectedActive(activeFilter)}
          onBlurCapture={() => setFilterSelectedActive('')}
        >
          <Text style={filterSelectedActive === activeFilter ? { color: 'white' } : {}}>
            {getValue(filter)}
          </Text>
          {!filter.readOnly && (
            <>
              <DownOutlined
                className="arrow-button"
                style={filterSelectedActive === activeFilter ? { color: 'white' } : {}}
              />
              {!filter.required && (
                <CloseCircleFilled
                  className="close-button"
                  style={filterSelectedActive === activeFilter ? { color: 'white' } : {}}
                  onClick={() => handleCloseTag(filter.key)}
                />
              )}
            </>
          )}
        </div>
      );

      if (filter.readOnly) {
        return filterBox;
      }

      return (
        <Popover
          key={activeFilter}
          placement="bottom"
          content={
            <div
              style={{
                minWidth: '250px',
                minHeight: '40px',
              }}
            >
              {formRef.current && (
                <FilterDrawerField
                  filter={filter}
                  onChange={(newValue) => {
                    const updatedValues = updateValues({
                      ...filterValues,
                      [filter.key]: newValue,
                    });
                    if (filter.fatherOf && formRef.current?.values[filter.fatherOf]) {
                      delete updatedValues[filter.fatherOf];
                    }
                    submit(updatedValues);
                    setFilterSelected(0);
                    handleVisibleChange(false);
                  }}
                  value={formRef.current?.values[filter.key]}
                  hiddenLabel
                  formProps={{
                    values: formRef.current.values,
                    setFieldValue: formRef.current.setFieldValue,
                  }}
                  isActiveField
                />
              )}
            </div>
          }
          trigger="click"
          visible={filterSelectedActive === activeFilter && visible}
          onVisibleChange={handleVisibleChange}
        >
          {filterBox}
        </Popover>
      );
    });
  };

  const handleLoadFilters = () => {
    const filterParams = myFilters.find((filter) => filter.id === filterSelected)?.parameters;
    if (!filterParams) {
      return;
    }

    let parameters: any = {};

    Object.keys(filterParams).forEach((key) => {
      if (typeof filterParams[key] === 'string' && filterParams[key].split('~').length === 2) {
        parameters = {
          ...parameters,
          [key]: queryParamToDateRange(filterParams[key]),
        };
      } else if (parseInt(filterParams[key], 10)) {
        parameters = { ...parameters, [key]: parseInt(filterParams[key], 10) };
      } else {
        parameters = { ...parameters, [key]: filterParams[key] };
      }
    });

    if (parameters.userGroupId && typeof parameters.userGroupId === 'number') {
      parameters.userGroupId = [parameters.userGroupId];
    }

    submit(
      updateValues({
        ...parameters,
        ...(searchFilterKey ? { [searchFilterKey]: searchValue } : {}),
      }),
    );
    formRef.current?.setValues(parameters as FormikValues);
  };

  const getFooter = (handleSubmit: any) => {
    if (filterTab === 'allFilters') {
      return (
        <>
          <Popconfirm
            placement="topLeft"
            title={
              <div>
                <div>Add a name to your filters:</div>
                <div>
                  <Input
                    placeholder="Enter the filter name"
                    onChange={(e) => setFilterName(e.target.value)}
                  />
                </div>
              </div>
            }
            onConfirm={handleAddFilter}
            okText="Save"
            cancelText="Cancel"
            okButtonProps={{ disabled: filterName === '' }}
          >
            <Button
              data-testid="filter-drawer-save-filters"
              size="large"
              type="link"
              style={{ marginRight: '15px' }}
            >
              Save Filters
            </Button>
          </Popconfirm>
          <Button
            data-testid="filter-drawer-clear-filters"
            size="large"
            onClick={() => clearFilters()}
            style={{ marginRight: '15px', color: '#1890FF', border: '0.846154px solid #1890FF' }}
          >
            Clear Filters
          </Button>
          <Button
            data-testid="filter-drawer-submit"
            size="large"
            htmlType="submit"
            type="primary"
            onClick={() => handleSubmit()}
            style={{ backgroundColor: '#00C62B' }}
          >
            Apply Filters
          </Button>
        </>
      );
    }

    return myFilters.length > 0 ? (
      <Button
        data-testid="filter-drawer-submit"
        htmlType="submit"
        type="primary"
        onClick={handleLoadFilters}
        style={{ backgroundColor: '#00C62B' }}
      >
        Load Filters
      </Button>
    ) : null;
  };

  return (
    <div className="filter-container">
      <Row className="first-row" gutter={[8, 8]} style={{ padding: 0 }}>
        <Col flex={1} className="col-filter-active">
          <Button
            data-testid="open-filters-button"
            type={activeFilters.length > 0 ? 'primary' : 'default'}
            icon={<FilterListIcon style={{ fontSize: '1.2rem' }} />}
            onClick={openFilterPanel}
            className="filterIconButton"
          />
          {getFilterActive()}
        </Col>
        {columnSelectorProps?.columns && (
          <DisplayColumns
            {...columnSelectorProps}
            className={`display-columns ${columnSelectorProps?.className ?? ''}`}
          />
        )}
        {actions.map((action, index) => (
          // eslint-disable-next-line react/no-array-index-key
          <Col key={`action-${index}`}>{action}</Col>
        ))}
        <Col flex={searchInputWidth}>
          {searchFilterKey || onSearch ? (
            <SearchField
              defaultValue={searchFilterKey ? (values?.[searchFilterKey] as string) : ''}
              placeholder={searchPlaceholder}
              onSearch={handleSearch}
            />
          ) : null}
        </Col>
      </Row>

      <Formik
        innerRef={formRef}
        enableReinitialize
        initialValues={getInitialValues()}
        onSubmit={(formValues) =>
          submit(
            updateValues({
              ...formValues,
              ...(searchFilterKey ? { [searchFilterKey]: searchValue } : {}),
            }),
          )
        }
      >
        {({ handleSubmit }) => (
          <Drawer
            data-testid="filter-drawer"
            className="filter-drawer"
            title="Filters"
            placement="right"
            visible={panelVisible}
            width={350}
            onClose={() => {
              if (currentFilter) {
                formRef.current?.setValues(currentFilter);
              }

              setPanelVisible(false);
            }}
            footer={
              <div data-testid="filter-drawer-footer" className="filter-drawer-footer">
                {getFooter(handleSubmit)}
              </div>
            }
          >
            <Tabs
              defaultActiveKey="allFilters"
              onChange={(value) => setFilterTab(value as 'allFilters' | 'myFilters')}
              centered
            >
              <TabPane tab="All Filters" key="allFilters" style={{ marginBottom: '12px' }}>
                {getFormBody()}
              </TabPane>
              <TabPane tab="My Filters" key="myFilters" style={{ marginBottom: '12px' }}>
                {getFormBody()}
              </TabPane>
            </Tabs>
          </Drawer>
        )}
      </Formik>
    </div>
  );
};

export default FilterBar;
