import type { TableRef } from '@/@propify-components';
import { type Column } from '@/@propify-components';
import AddPropertiesToPropertyGroupsModal from '@/components/AddPropertiesToPropertyGroupsModal/AddPropertiesToPropertyGroupsModal';
import AssignLeaseTemplatesModal from '@/components/AssignLeaseTemplatesModal/AssignLeaseTemplatesModal';
import { BulkUpdateFieldType } from '@/components/BulkUpdate/BulkUpdateForm';
import type { FilterValuesType } from '@/components/FilterBarWithURLQuery/types';
import Map from '@/components/map';
import Page from '@/components/Page';
import PropertiesTable from '@/components/PropertiesTable/PropertiesTable';
import type { FilterParams } from '@/components/PropertiesTableFilters/PropertiesTableFilters';
import PropertiesTableFilters from '@/components/PropertiesTableFilters/PropertiesTableFilters';
import RemovePropertiesFromPropertyGroupsModal from '@/components/RemovePropertiesFromPropertyGroupsModal/RemovePropertiesFromPropertyGroupsModal';
import { EntityType } from '@/domain/entity-type';
import type {
  PropertyBulkUpdateRequest,
  PropertySearchResult,
  PropertyType,
} from '@/domain/property';
import { useProperties } from '@/hooks/services/useProperties';
import useEnumeration from '@/hooks/useEnumeration';
import { usePropertiesTableColumns } from '@/hooks/usePropertiesTableColumns';
import type { ConnectState } from '@/models/connect';
import { PetPolicyService } from '@/services/pet-policy';
import type { PropertySearchParams } from '@/services/property';
import { PropertyService } from '@/services/property';
import { settings } from '@/services/settings';
import type { Point } from '@/utils/map';
import { useFetch } from '@/utils/request';
import { handleError } from '@/utils/utils';
import {
  CloseOutlined,
  CompassOutlined,
  FileTextOutlined,
  PlusOutlined,
  TableOutlined,
} from '@ant-design/icons';
import { message, Radio } from 'antd';
import type { StringifiableRecord } from 'query-string';
import queryString from 'query-string';
import type { FC } from 'react';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { useLocation, useNavigate } from 'react-router';
import AddPropertyGroupModal from './AddPropertyGroupModal';
import PropertySummary from './PropertySummary';
import './styles.less';
import styles from './styles.module.less';

type Props = ReturnType<typeof mapStateToProps>;

interface BulkValues {
  petPolicy?: number;
  commonDoorLockCode?: string;
  promotionIds?: number[];
}

const propertiesSettings = settings('properties');
type ViewMode = 'TABLE' | 'MAP';

export const commonAccessInformationPreview = (text: string | undefined) => {
  return <div style={{ width: '300px', whiteSpace: 'pre-line', padding: '5px 10px' }}>{text}</div>;
};

const General: FC<Props> = ({ customFields }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const queryFilters = useMemo(
    () => queryString.parse(location.search) as FilterParams,
    [location.search],
  );
  const tableRef = useRef<TableRef<PropertySearchResult, number>>(null);
  const {
    columns: tableColumns,
    loading: loadingColumns,
    refetchPropertyGroups,
  } = usePropertiesTableColumns({
    customFields,
  });
  const [columns, setColumns] = useState<Column<PropertySearchResult>[]>([]);
  const [selection, setSelection] = useState<number[]>([]);
  const [viewMode, setViewMode] = useState<ViewMode>('TABLE');
  const { data: propertyTypes } = useEnumeration<PropertyType>('property-type');

  const { data: petPolicies = [] } = useFetch({
    fetcher: PetPolicyService.search,
    errorMessage: 'There was a error getting the pet policies',
  });

  const filters = useMemo(
    () => ({
      transactionStatus: queryFilters.transactionStatus || 'ACTIVE',
      ...queryFilters,
    }),
    [queryFilters],
  );

  useEffect(() => {
    if (!loadingColumns) {
      setColumns(tableColumns);
    }
  }, [tableColumns, loadingColumns]);

  const {
    data: properties = [],
    isValidating: loadingProperties,
    mutate: refetchProperties,
  } = useProperties(filters as PropertySearchParams);

  const pointsForMap = useMemo<Point[]>(() => {
    return properties
      .filter((p) => p.address?.location?.latitude && p.address?.location?.longitude)
      .map((p) => ({
        id: p.id,
        latitude: p.address!.location!.latitude!,
        longitude: p.address!.location!.longitude!,
        onClick: () => window.open(`/properties/${p.id}`, '_blank'),
        render: () => <PropertySummary property={p} typesEnum={propertyTypes} />,
      }));
  }, [properties, propertyTypes]);

  const handleBulkUpdate = useCallback(
    (values: BulkValues) => {
      if (!selection || !selection.length) {
        return;
      }
      const change: PropertyBulkUpdateRequest = { ids: selection };

      if (values.petPolicy) {
        change.petPolicyId = Number(values.petPolicy);
      }

      if (values.commonDoorLockCode) {
        change.commonDoorLockCode = values.commonDoorLockCode;
      }

      PropertyService.bulkUpdate(change)
        .then(() => {
          message.success('Successful Bulk update');
          refetchProperties();
        })
        .catch((error) => {
          handleError(error, { toastFallbackMessage: 'Bulk update failed' });
        });
    },
    [selection, refetchProperties],
  );

  const setFilters = (values: FilterValuesType) => {
    const newFilters: FilterParams = {
      ...(values.keyword ? { keyword: values.keyword as string } : {}),
      ...(values.ventureId ? { ventureId: values.ventureId as string } : {}),
      ...(values.msa ? { msa: values.msa as string } : {}),
      ...(values.brandId ? { brandId: values.brandId as string } : {}),
      ...(values.propertyGroupId ? { propertyGroupId: values.propertyGroupId as string } : {}),
      ...(values.state ? { state: values.state as string } : {}),
      ...(typeof values.hasLeaseDocumentTemplates === 'boolean'
        ? { hasLeaseDocumentTemplates: values.hasLeaseDocumentTemplates }
        : {}),
      ...(values.transactionStatus
        ? { transactionStatus: values.transactionStatus as string }
        : {}),
      ...(values.city ? { city: values.city as string } : {}),
      ...(values.holdingCompanyId ? { holdingCompanyId: values.holdingCompanyId as string } : {}),
    };

    navigate({
      search: queryString.stringify(newFilters as StringifiableRecord),
    });
  };

  const addProperty = () => navigate('/properties/create');

  const selectedUnitIds = useMemo(
    () => properties.filter((p) => selection.includes(p.id) && !!p.unitId).map((p) => p.unitId!),
    [properties, selection],
  );

  return (
    <Page
      fullHeight
      loading={!properties}
      title="Properties"
      actions={[
        {
          type: 'bulkUpdate',
          key: 'bulk-update',
          buttonType: 'default',
          disabled: !selection?.length,
          onSuccess: handleBulkUpdate,
          variant: 'modal',
          fields: [
            {
              title: 'Pet Policy',
              name: 'petPolicy',
              type: BulkUpdateFieldType.Select,
              options:
                petPolicies &&
                petPolicies.map(({ id, name }) => ({
                  value: String(id),
                  label: name,
                })),
            },
            {
              title: 'Common Access Information',
              name: 'commonDoorLockCode',
              type: BulkUpdateFieldType.TextArea,
            },
          ],
        },
        {
          type: 'primary',
          key: 'add-property',
          onClick: addProperty,
          children: 'Add Property',
        },
        {
          key: 'add-property-group',
          type: 'modalFormButton',
          buttonText: 'Create Property Group',
          onSuccess: () => {
            refetchPropertyGroups();
            refetchProperties();
          },
          disabled: !selection?.length,
          children: (props) => <AddPropertyGroupModal {...props} propertyIds={selection} />,
        },
        {
          key: 'add-to-property-group',
          type: 'modalFormButton',
          buttonText: 'Add to Property Group',
          buttonType: 'link',
          icon: <PlusOutlined />,
          onSuccess: () => {
            refetchProperties();
          },
          disabled: !selection?.length,
          children: (props) => (
            <AddPropertiesToPropertyGroupsModal {...props} propertyIds={selection} />
          ),
        },
        {
          key: 'remove-from-property-group',
          type: 'modalFormButton',
          buttonText: 'Remove from Property Group',
          buttonClassName: styles.removeFromPropertyGroupButton,
          buttonType: 'link',
          danger: !!selection?.length,
          icon: <CloseOutlined />,
          onSuccess: () => {
            refetchProperties();
          },
          disabled: !selection?.length,
          children: (props) => (
            <RemovePropertiesFromPropertyGroupsModal {...props} propertyIds={selection} />
          ),
        },
        {
          key: 'assign-lease-templates',
          type: 'modalFormButton',
          buttonText: 'Assign Lease Template(s)',
          buttonType: 'link',
          icon: <FileTextOutlined />,
          disabled: !selection?.length,
          children: (props) => <AssignLeaseTemplatesModal {...props} unitIds={selectedUnitIds} />,
          onSuccess: () => {
            setSelection([]);
          },
        },
      ]}
      extra={[
        <Radio.Group
          key="view-type"
          className="toggle-group"
          value={viewMode}
          onChange={(e) => setViewMode(e.target.value)}
        >
          <Radio.Button value="TABLE">
            <TableOutlined />
          </Radio.Button>
          <Radio.Button value="MAP">
            <CompassOutlined />
          </Radio.Button>
        </Radio.Group>,
      ]}
      className="properties-view"
    >
      <PropertiesTableFilters
        tableRef={tableRef}
        columns={columns}
        setColumns={setColumns}
        filters={filters}
        setFilters={setFilters}
        properties={properties}
        tableSettings={propertiesSettings}
      />

      {viewMode === 'TABLE' ? (
        <PropertiesTable
          tableRef={tableRef}
          properties={properties}
          columns={columns}
          selection={selection}
          setSelection={setSelection}
          tableSettings={propertiesSettings}
          loadingProperties={loadingProperties}
        />
      ) : (
        <div style={{ flex: 1 }}>
          <Map points={pointsForMap} />
        </div>
      )}
    </Page>
  );
};

const mapStateToProps = (state: ConnectState) => ({
  customFields:
    state.global.customFields?.filter(
      (customField) => customField.entityType === EntityType.PROPERTY,
    ) ?? [],
});

const areStatesEqual = (next: ConnectState, prev: ConnectState): boolean =>
  next.global.customFields === prev.global.customFields;

export default connect(mapStateToProps, null, null, { areStatesEqual })(General);
