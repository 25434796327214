import type { Option } from '@/domain/base';
import { DeleteFilled, PaperClipOutlined, UploadOutlined } from '@ant-design/icons';
import { Button, Col, Divider, Form, Modal, Row, Typography, Upload } from 'antd';
import { Field, Formik } from 'formik';
import upperCase from 'lodash/upperCase';
import { useState, type FC } from 'react';
import * as Yup from 'yup';
import type { ModalFormProps } from '../Button/formButton';
import { AntInput, AntSelect, AntTextArea } from '../Formik/CreateAntField';
import styles from './styles.module.less';

export type DocumentTemplateValues = {
  name: string;
  description: string;
  signers: number;
};

const validationSchema = Yup.object().shape({
  name: Yup.string().required('Required'),
  description: Yup.string().required('Required'),
  signers: Yup.number().required('Required'),
});

const requiredLabel = (text: string) => (
  <>
    <strong>{upperCase(text)}</strong> <span style={{ color: 'red', marginLeft: 5 }}>*</span>
  </>
);

const inputStyle = {
  background: 'linear-gradient(0deg, #D8ECF6, #D8ECF6), linear-gradient(0deg, #EAF8FF, #EAF8FF)',
  border: '1px solid #D8ECF6',
  color: '#001529',
};

const { Title, Text, Paragraph } = Typography;

type Result = {
  name: string;
  description: string;
  signers: number;
  file: File;
};

type Props = ModalFormProps<[Result]> & {
  initialValues: DocumentTemplateValues;
  fileValue: File | undefined;
  loadingDocumentTemplate: boolean;
};

const AddDocumentTemplateModalStep1: FC<Props> = ({
  onSuccess,
  onCancel,
  initialValues,
  fileValue,
  loadingDocumentTemplate,
}) => {
  const [file, setFile] = useState<File | undefined>(fileValue);
  const submit = (values: DocumentTemplateValues) => {
    if (file) {
      onSuccess({ ...values, file: file });
    }
  };

  return (
    <Formik<DocumentTemplateValues>
      onSubmit={submit}
      validationSchema={validationSchema}
      initialValues={initialValues}
    >
      {({ handleSubmit, submitCount, submitForm, isValid }) => (
        <Modal
          visible
          onCancel={onCancel}
          onOk={submitForm}
          okText="Continue to Next Step"
          okButtonProps={{
            disabled: !isValid || !file,
            loading: loadingDocumentTemplate,
          }}
          title="Add Document Template: Step 1 of 2"
          maskClosable={false}
        >
          <Paragraph>
            <Title level={5}>Upload Lease Template Document</Title>
            <Text>
              A Document Template requires a file to be uploaded off of which a signature document
              can be generated. Once a file is uploaded, you will be given the opportunity to select
              and position signature fields, as well as insert dynamic values which will be
              generated based off of each Unit for which the document is being created.
            </Text>
          </Paragraph>

          <Divider />

          <Form onSubmitCapture={handleSubmit}>
            <Field
              formItemStyle={{ display: 'block' }}
              style={inputStyle}
              component={AntInput}
              label={requiredLabel('Document Template Name')}
              name="name"
              placeholder="Enter Document Template Name"
              type="text"
              hasFeedback
              submitCount={submitCount}
            />

            <Field
              className={styles.input}
              formItemStyle={{ display: 'block' }}
              style={{
                ...inputStyle,
                minHeight: '100px',
              }}
              component={AntTextArea}
              label={requiredLabel('Template Description')}
              placeholder="Enter Template Description"
              name="description"
              type="text"
              hasFeedback
              submitCount={submitCount}
            />

            <Field
              className={styles.input}
              formItemStyle={{ display: 'block' }}
              style={inputStyle}
              label={requiredLabel('Number of Signers')}
              placeholder="Select of Number of Signers"
              component={AntSelect}
              name="signers"
              selectOptions={[...Array(8)].map((_, index) => ({
                value: index + 1,
                label: index + 1,
              }))}
              getOptionLabel={(option: Option<number>) => option.label}
              getOptionValue={(option: Option<number>) => option.value}
              hasFeedback
              submitCount={submitCount}
            />
            {!file ? (
              <div className={styles.upload}>
                <Upload
                  onRemove={() => {
                    setFile(undefined);
                  }}
                  beforeUpload={(uploadedFile) => {
                    setFile(uploadedFile);
                    return false;
                  }}
                  accept=".doc,.docx,.pdf"
                >
                  <Button
                    className={styles.uploadButton}
                    size="large"
                    block
                    icon={<UploadOutlined />}
                  >
                    Upload Template File
                  </Button>
                </Upload>
              </div>
            ) : (
              <div>
                <Row className={styles.displayUploadFile}>
                  <Col>
                    <PaperClipOutlined className={styles.paperClip} />{' '}
                    <span className={styles.fileName}>{file.name}</span>
                  </Col>
                  <Col>
                    <Button
                      icon={<DeleteFilled style={{ color: 'red' }} />}
                      onClick={() => setFile(undefined)}
                    />
                  </Col>
                </Row>
              </div>
            )}
          </Form>
        </Modal>
      )}
    </Formik>
  );
};

export default AddDocumentTemplateModalStep1;
