import type {
  ColumnComponentType,
  ColumnComponentTypeProps,
} from '@/notmagic/components/EntityTable/types';
import { SHORT_TIME_FORMAT } from '@/utils/time';
import moment from 'moment/moment';

type Props = ColumnComponentTypeProps<string>;

const Component: ColumnComponentType<Props> = ({ value, textIfNull }) => {
  // TODO use right format for value: "moment(value, 'HH:MM:ss.SSS')"
  return <>{value ? moment(value).format(SHORT_TIME_FORMAT) : textIfNull}</>;
};

export default {
  Component,
  sort: (a: string, b: string) => (a === b ? 0 : a < b ? -1 : 1),
  getValueForExport: (value: string) => (value ? moment(value).format(SHORT_TIME_FORMAT) : ''),
};
