import type {
  ColumnComponentType,
  ColumnComponentTypeProps,
  ColumnType,
} from '@/notmagic/components/EntityTable/types';
import ApplicationApprovals from '@/pages/prospects/unit-applications/ApplicationApprovals';

type Value = {
  idApproval: boolean;
  creditApproval: boolean;
  criminalApproval: boolean;
  backgroundApproval: boolean;
};

const Component: ColumnComponentType<ColumnComponentTypeProps<Value>> = ({ value }) => {
  return value ? (
    <ApplicationApprovals
      id={value.idApproval}
      credit={value.creditApproval}
      criminal={value.criminalApproval}
      background={value.backgroundApproval}
    />
  ) : null;
};

const Definition: ColumnType = {
  Component,
  getValueForExport: (value: Value) =>
    `${value.creditApproval ? `Credit: ${value.creditApproval} | ` : ''} ${
      value.criminalApproval ? `Criminal: ${value.criminalApproval} | ` : ''
    }${value.backgroundApproval ? `Background: ${value.backgroundApproval}` : ''}`,
};

export default Definition;
