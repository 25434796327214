import ExternalLink from '@/components/ExternalLink';
import RawHtml from '@/components/RawHtml';
import type { Option } from '@/domain/base';
import { SHORT_DATE_FORMAT } from '@/utils/time';
import { getBooleanOptions } from '@/utils/utils';
import { Editor } from '@tinymce/tinymce-react';
import { Tag } from 'antd';
import type { FormikProps } from 'formik';
import { Field } from 'formik';
import moment from 'moment';
import {
  AntDatePicker,
  AntInput,
  AntInputNumber,
  AntPhoneMaskInput,
  AntSelect,
  AntTextArea,
} from '../CreateAntField';
import FieldList from './FieldList';
import ImageBlock from './ImageBlock';
import type { Attribute, AttributeValue } from './types';

const plugins = [
  'advlist',
  'autolink',
  'lists',
  'link',
  'image',
  'charmap',
  'print',
  'preview',
  'anchor',
  'searchreplace',
  'visualblocks',
  'code',
  'fullscreen',
  'insertdatetime',
  'media',
  'table',
  'paste',
  'code',
  'help',
  'wordcount',
];

// cspell:ignore formatselect backcolor alignleft aligncenter alignright alignjustify bullist numlist outdent removeformat
const toolbar = `undo redo | formatselect | bold italic backcolor |
                 alignleft aligncenter alignright alignjustify |
                 bullist numlist outdent indent | removeformat | help`;

const BOOLEAN_OPTIONS = getBooleanOptions().map((item) => ({
  ...item,
  displayName: item.label,
}));

const DEFAULT_VALUE = 'N/A';

export const getDisplayableValue = (attr: Attribute, values: AttributeValue) => {
  let displayableValue = values[attr.fieldName] || attr.value;

  if (attr.type === 'Date') {
    return displayableValue
      ? moment(displayableValue).format(attr.dateFormat || SHORT_DATE_FORMAT)
      : '';
  }

  if (attr.type === 'Boolean') {
    if (displayableValue === 'true' || displayableValue === 'false') {
      displayableValue = displayableValue === 'true';
    }

    if (typeof displayableValue !== 'boolean') {
      return '';
    }

    displayableValue = BOOLEAN_OPTIONS.find((opt) => opt.value === displayableValue)?.displayName;
  }

  if (attr.type === 'Number') {
    if (!displayableValue && displayableValue !== 0) {
      return '';
    }
  }

  if (attr.type === 'Select') {
    if (attr.multiple) {
      displayableValue = displayableValue.map((value: number) => {
        return (
          <Tag key={value}>{attr.options?.find((option) => option.value === value)?.label}</Tag>
        );
      });
    }
    displayableValue = attr.options
      ? attr.options.find((option) => option.value === displayableValue)?.label || displayableValue
      : displayableValue;
  }

  if (attr.type === 'Image') {
    return <ImageBlock images={attr.value} extra={attr.extra!} />;
  }

  if (attr.type === 'Link' && attr.value) {
    return (
      <ExternalLink
        href={attr.value}
        styles={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}
      >
        {attr.value}
      </ExternalLink>
    );
  }

  if (attr.type === 'TextEditor') {
    return <RawHtml html={attr.value} />;
  }

  if (attr.type === 'TextArea') {
    return <div style={{ whiteSpace: 'pre-line' }}>{attr.value}</div>;
  }

  if (attr.type === 'Attributes') {
    if (!attr.nestedAttributes) {
      // eslint-disable-next-line no-console
      console.error('Need to set "nestedAttributes" prop for type "Attributes"');
      return 'Error rendering component';
    }

    return <FieldList attributes={attr.nestedAttributes} onSave={attr.onSave} />;
  }

  const value =
    attr.format && displayableValue !== DEFAULT_VALUE
      ? attr.format(displayableValue)
      : displayableValue;

  if (typeof attr.render === 'function') {
    return attr.render(value);
  }
  return value;
};

export const getEditableValue = (attr: Attribute, formProps: FormikProps<AttributeValue>) => {
  switch (attr.type) {
    case 'Select':
      return (
        <Field
          component={AntSelect}
          name={attr.fieldName}
          hasFeedback
          style={{ width: '100%', ...attr.fieldStyle }}
          selectOptions={attr.options}
          getOptionLabel={(option: Option) => option.label}
          getOptionValue={(option: Option) => option.value}
          mode={attr.multiple ? 'multiple' : ''}
          data-testid="editable-attribute-field-input"
          popupMatchSelectWidth={700}
        />
      );
    case 'Boolean':
      return (
        <Field
          component={AntSelect}
          name={attr.fieldName}
          hasFeedback
          style={{ width: '100%' }}
          selectOptions={attr.booleanOptions || BOOLEAN_OPTIONS}
          getOptionLabel={(option: Option) => option.label}
          getOptionValue={(option: Option) => option.value}
          data-testid="editable-attribute-field-input"
        />
      );
    case 'Number':
      return (
        <Field
          component={AntInputNumber}
          name={attr.fieldName}
          hasFeedback
          style={{ width: '100%' }}
          data-testid="editable-attribute-field-input"
          type="number"
          precision={attr.precision}
        />
      );
    case 'Date':
      return (
        <Field
          component={AntDatePicker}
          name={attr.fieldName}
          hasFeedback
          style={{ width: '100%' }}
          format={SHORT_DATE_FORMAT}
          data-testid="editable-attribute-field-input"
          disabledDate={attr.disabledDate}
        />
      );

    case 'TextArea':
      return (
        <Field
          component={AntTextArea}
          name={attr.fieldName}
          hasFeedback
          type="text"
          style={{ width: '100%' }}
          autoSize
          data-testid="editable-attribute-field-input"
        />
      );

    case 'TextEditor':
      return (
        <Editor
          init={{
            menubar: false,
            plugins,
            toolbar,
            contextmenu: 'image',
            browser_spellcheck: true,
          }}
          value={formProps.values[attr.fieldName]}
          onEditorChange={(value) => formProps.setFieldValue(attr.fieldName, value)}
          data-testid="editable-attribute-field-input"
        />
      );

    case 'Phone':
      return (
        <Field
          component={AntPhoneMaskInput}
          type="text"
          name={attr.fieldName}
          value={formProps.values[attr.fieldName]}
          style={{ width: '100%' }}
          hasFeedback
          data-testid="editable-attribute-field-input"
        />
      );

    default:
      return (
        <Field
          component={AntInput}
          name={attr.fieldName}
          hasFeedback
          type="text"
          style={{ width: '100%', marginBottom: 0 }}
          data-testid="editable-attribute-field-input"
        />
      );
  }
};
