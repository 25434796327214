import type { Charge } from '@/domain/lease';
import type { RenewalOption } from '@/domain/renewal';
import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { Popconfirm } from 'antd';
import { useFormikContext } from 'formik';
import type { FC } from 'react';
import { useState } from 'react';
import type { FeeFormValues } from './AddItemModal';
import AddItemModal from './AddItemModal';
import type { RenewalsModalValues } from './RenewalsModal';

interface Props {
  renewalOption?: RenewalOption;
  chargeToEdit: Charge;
  chargeIndex: number;
  renewalCharges?: Charge[];
}

const EditFeeWrapper: FC<Props> = ({
  renewalOption,
  chargeToEdit,
  renewalCharges,
  chargeIndex,
}) => {
  const { values, setFieldValue } = useFormikContext<RenewalsModalValues>();
  const [editing, setEditing] = useState(false);

  const handleDelete = () => {
    if (renewalOption) {
      const charges = renewalOption.charges.filter(
        (c) => c.name !== chargeToEdit.name && c.amount !== chargeToEdit.amount,
      );

      const updatedRenewalOption = {
        ...renewalOption,
        charges,
      };

      const updatedRenewalOptions = values.options.map((ro) =>
        ro.minMonths === renewalOption.minMonths && ro.maxMonths === renewalOption.maxMonths
          ? updatedRenewalOption
          : ro,
      );

      setFieldValue('options', updatedRenewalOptions);
    }

    if (renewalCharges) {
      const charges = renewalCharges.filter((_, index) => !(index === chargeIndex));

      setFieldValue('charges', charges);
    }
  };

  const handleEdit = (newValues: FeeFormValues) => {
    if (renewalOption) {
      const charges = renewalOption.charges.map((c) =>
        c.name === chargeToEdit.name && c.amount === chargeToEdit.amount
          ? { ...chargeToEdit, ...newValues }
          : c,
      );

      const updatedRenewalOption = {
        ...renewalOption,
        charges,
      };

      const renewalOptionToUpdateIndex = values.options.findIndex(
        (ro) =>
          ro.minMonths === renewalOption.minMonths && ro.maxMonths === renewalOption.maxMonths,
      );

      const updatedRenewalOptions = [...values.options];

      updatedRenewalOptions[renewalOptionToUpdateIndex] = updatedRenewalOption;

      setFieldValue('options', updatedRenewalOptions);
    }

    if (renewalCharges) {
      const charges = renewalCharges.map((c, index) =>
        index === chargeIndex ? { ...chargeToEdit, ...newValues } : c,
      );

      setFieldValue('charges', charges);
    }
    setEditing(false);
  };

  return (
    <>
      <AddItemModal
        visible={editing}
        chargeToEdit={chargeToEdit}
        onCancel={() => setEditing(false)}
        onEdit={handleEdit}
      />
      <div className="__actions">
        <div onClick={() => setEditing(true)}>
          <EditOutlined className="edit" />
        </div>
        <Popconfirm
          title="Are you sure?"
          onConfirm={() => handleDelete()}
          okText="Yes"
          cancelText="No"
        >
          <div>
            <DeleteOutlined className="delete" />
          </div>
        </Popconfirm>
      </div>
    </>
  );
};

export default EditFeeWrapper;
