import type { New } from '@/domain/base';
import type { EntityType } from '@/domain/entity-type';
import type { Note, NoteContext, NoteSearchParams } from '@/domain/note';
import { api } from '@/utils/configuration';
import { crudService, getAxiosFetcher } from '@/utils/request';
import type { Operation } from 'fast-json-patch';
import LookupService from './lookup';

export const NoteService = {
  findById: getAxiosFetcher<Note, [number]>((id) => [`${api.NOTE}/${id}`]),

  search: getAxiosFetcher<Note[], [NoteSearchParams]>((params) => [api.NOTE, { params }]),

  create: (note: New<Note>) => crudService.post<Note>(note, api.NOTE),

  patch: (id: number, operations: Operation[], version: number) =>
    crudService.patch<Note>(operations, `${api.NOTE}/${id}?version=${version}`),

  delete: (id: number) => crudService.delete<void>(`${api.NOTE}/${id}`),

  lookup: (entityId: number, entityType: EntityType | string, context: NoteContext) =>
    LookupService.notes({ entityId, entityType, context }),
};
