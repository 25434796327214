import { formatCurrency } from '@/@propify-components/shared/utils';
import { Tooltip } from 'antd';
import Big from 'big.js';
import classNames from 'classnames';
import type { FC } from 'react';

export interface FormatAdjValueConfig {
  orangeIfZero?: boolean;
  showNAIfZero?: boolean;
  disableAccountingStyle?: boolean;
}

export const formatAdjValue = (value: Big, config?: FormatAdjValueConfig) => (
  <span
    data-testid="rent-adj-value"
    className={classNames({
      ['green-rent-adj']: !value.eq(0),
      ['orange-rent-adj']: config?.orangeIfZero && value.eq(0),
    })}
  >
    {config?.showNAIfZero && value.eq(0)
      ? 'N/A'
      : formatCurrency(value, {
          noEmptyDecimals: true,
          disableAccountingStyle: config?.disableAccountingStyle,
        })}
  </span>
);

type Props = {
  listedRent: number | undefined;
  marketRent: number | undefined;
  tooltip?: string;
};

const RentAdjValue: FC<Props> = ({ listedRent, marketRent, tooltip }) => {
  const content = formatAdjValue(Big(listedRent || 0).minus(marketRent || 0));

  return tooltip?.trim() ? <Tooltip title={tooltip}>{content}</Tooltip> : content;
};

export default RentAdjValue;
