import { Select } from 'antd';
import type { FC } from 'react';
import { useMemo } from 'react';
import type { ApprovalIconProps } from '../ApprovalIcon';
import ApprovalIcon from '../ApprovalIcon';
import './styles.less';

interface Props extends ApprovalIconProps {
  onChange?: (approved: boolean | null) => void;
  label: string;
  disableSelect?: boolean;
}

const options = [
  { label: 'Approved', value: 'accepted', booleanValue: true },
  { label: 'Rejected', value: 'denied', booleanValue: false },
  { label: 'Pending', value: 'waiting', booleanValue: null },
];

const ScreeningResultCard: FC<Props> = ({
  onChange,
  disableSelect,
  icon,
  label,
  size,
  status,
  applicantRemoved,
}) => {
  const selectedOption = useMemo(() => options.find((o) => o.value === status), [status]);

  return (
    <div className="screening-result-card">
      {onChange ? (
        <Select
          value={selectedOption?.value}
          onChange={(value: string) =>
            onChange(options.find((o) => o.value === value)!.booleanValue)
          }
          options={options}
          disabled={disableSelect}
          className="approval-select"
        />
      ) : (
        <div className={`card-header ${selectedOption?.value}`}>{selectedOption?.label}</div>
      )}
      <div className="card-body">
        <div className="card-icon">
          <ApprovalIcon
            status={status}
            icon={icon}
            size={size}
            applicantRemoved={applicantRemoved}
          />
        </div>
        <div className="card-label">{label}</div>
      </div>
    </div>
  );
};

export default ScreeningResultCard;
