import type { ModalFormProps } from '@/components/Button/formButton';
import type { TaskSearchResult } from '@/domain/tasks';
import useEnumeration from '@/hooks/useEnumeration';
import { TasksService } from '@/services/tasks';
import { getProcessLink } from '@/utils/links';
import { getAddressDescription, handleError } from '@/utils/utils';
import { Button, Form, Modal } from 'antd';
import { Field, Formik } from 'formik';
import type { FC } from 'react';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import * as Yup from 'yup';
import { AntTextArea } from '../Formik/CreateAntField';

interface CompleteTaskValues {
  note?: string;
}

interface Props extends ModalFormProps<[CompleteTaskValues]> {
  task: Pick<
    TaskSearchResult,
    'id' | 'name' | 'noteRequired' | 'address' | 'processId' | 'processType'
  >;
}

const CompleteTaskModal: FC<Props> = ({ task, onSuccess, onCancel }) => {
  const [saving, setSaving] = useState(false);

  const { data: processTypes = [] } = useEnumeration('process-type');

  const onSave = (values: CompleteTaskValues) => {
    setSaving(true);

    TasksService.complete(task.id, {
      note: values.note,
    })
      .then(() => {
        onSuccess(values);
      })
      .catch((error) => {
        handleError(error, { toastFallbackMessage: 'There was an error completing the task' });
      })
      .finally(() => {
        setSaving(false);
      });
  };

  return (
    <Formik<CompleteTaskValues>
      initialValues={{ note: undefined }}
      validationSchema={
        task.noteRequired
          ? Yup.object().shape({
              note: Yup.string().trim().required('A note is required to complete this task'),
            })
          : undefined
      }
      onSubmit={onSave}
    >
      {({ handleSubmit, submitCount }) => {
        return (
          <Modal
            className="completeTaskModal"
            visible
            title="Complete Task"
            onCancel={onCancel}
            footer={[
              <Button key="cancel" onClick={() => onCancel?.()}>
                Cancel
              </Button>,
              <Button
                key="save"
                className="green"
                loading={saving}
                disabled={saving}
                onClick={() => handleSubmit()}
              >
                Complete Task
              </Button>,
            ]}
          >
            <Form
              onSubmitCapture={handleSubmit}
              labelCol={{ span: 4 }}
              wrapperCol={{ span: 16 }}
              labelAlign="left"
            >
              <div>
                <div className="instructions">
                  The below task will be marked as <span className="cellGreenText">complete</span>:
                </div>

                <Form.Item label="Address">{getAddressDescription(task.address)}</Form.Item>
                <Form.Item label="Subprocess">
                  {`${processTypes.find((p) => p.value === task.processType)?.displayName} - `}
                  <Link to={getProcessLink(task.processId, task.processType)}>
                    {task.processId}
                  </Link>
                </Form.Item>
                <Form.Item label="Task">{task.name}</Form.Item>

                <Field
                  label="Note"
                  className="notesInput"
                  name="note"
                  type="text"
                  component={AntTextArea}
                  submitCount={submitCount}
                  hasFeedback
                  autoFocus
                />
              </div>
            </Form>
          </Modal>
        );
      }}
    </Formik>
  );
};

export default CompleteTaskModal;
