import { SearchOutlined } from '@ant-design/icons';
import { Button, Input } from 'antd';
import type { CSSProperties, FC } from 'react';
import { useState } from 'react';
import styles from './styles.module.less';

interface SearchFieldProps {
  defaultValue: string;
  placeholder: string;
  onSearch: (value: string) => void;
  style?: CSSProperties;
}

const SearchField: FC<SearchFieldProps> = ({ defaultValue, onSearch, placeholder, style }) => {
  const [value, setValue] = useState<string>(defaultValue);

  return (
    <Input
      className={styles.searchField}
      value={value}
      placeholder={placeholder}
      style={style}
      suffix={
        <Button
          className={`${styles.searchIconButton} ${value ? styles.searchIconButtonActive : ''}`}
          type="text"
          icon={<SearchOutlined />}
          onClick={() => onSearch(value)}
        />
      }
      onPressEnter={(ev) => {
        const newValue = (ev.target as HTMLTextAreaElement).value;
        if (newValue) {
          onSearch(newValue);
        } else {
          onSearch('');
        }
      }}
      onChange={(e) => {
        setValue(e.target.value);
      }}
    />
  );
};

export default SearchField;
