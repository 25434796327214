import type { LatLong } from '@/domain/base';
import type { LatLngExpression } from 'leaflet';
import isEmpty from 'lodash/isEmpty';
import type { ReactNode } from 'react';

export type Point = {
  id: number | string;
  key?: string | number;
  latitude: number;
  longitude: number;
  color?: string;
  render?: () => ReactNode;
  onClick?: () => void;
};

export const RADIUS_OF_EARTH_IN_KM = 6371;

export function averageGeolocation(coords: Point[]) {
  if (coords.length === 1) {
    return coords[0];
  }

  let x = 0.0;
  let y = 0.0;
  let z = 0.0;

  // eslint-disable-next-line no-restricted-syntax
  for (const coord of coords) {
    const latitude = (coord.latitude * Math.PI) / 180;
    const longitude = (coord.longitude * Math.PI) / 180;

    x += Math.cos(latitude) * Math.cos(longitude);
    y += Math.cos(latitude) * Math.sin(longitude);
    z += Math.sin(latitude);
  }

  const total = coords.length;

  x /= total;
  y /= total;
  z /= total;

  const centralLongitude = Math.atan2(y, x);
  const centralSquareRoot = Math.sqrt(x * x + y * y);
  const centralLatitude = Math.atan2(z, centralSquareRoot);

  return {
    latitude: (centralLatitude * 180) / Math.PI,
    longitude: (centralLongitude * 180) / Math.PI,
  };
}

export const toRadians = (degree: number) => (degree * Math.PI) / 180;

export const distance = (
  { latitude: lat1, longitude: lon1 }: Point,
  { latitude: lat2, longitude: lon2 }: Point,
) => {
  if (lat1 === lat2 && lon1 === lon2) {
    return 0;
  }

  const lat = toRadians(lat2 - lat1);
  const lon = toRadians(lon2 - lon1);
  const lat1InRadians = toRadians(lat1);
  const lat2InRadians = toRadians(lat2);

  const a =
    Math.sin(lat / 2) * Math.sin(lat / 2) +
    Math.sin(lon / 2) * Math.sin(lon / 2) * Math.cos(lat1InRadians) * Math.cos(lat2InRadians);
  const c = 2 * Math.asin(Math.sqrt(a));

  return RADIUS_OF_EARTH_IN_KM * c;
};

export const DEFAULT_CENTER = { lat: 41.9, lng: -87.75 };

export const calculateCenter = (coordinates?: LatLong[]): LatLngExpression => {
  if (isEmpty(coordinates)) {
    return DEFAULT_CENTER;
  }
  const x = coordinates!.map((xy) => xy.latitude);
  const y = coordinates!.map((xy) => xy.longitude);
  const cx = (Math.min(...x) + Math.max(...x)) / 2;
  const cy = (Math.min(...y) + Math.max(...y)) / 2;
  return { lat: cx, lng: cy };
};
