import type { FC } from 'react';
import ApprovalIcon, { getBooleanStatus } from './ApprovalIcon';

type Props = {
  id?: boolean | null;
  credit?: boolean | null;
  criminal?: boolean | null;
  background?: boolean | null;
  singleApplication?: boolean;
  iconSize?: number;
  marginTop?: number;
  /**
   * This will show gray icons and the same text in all the pop-overs
   * @see https://skyboxcapital.atlassian.net/browse/FB-614
   */
  applicantRemoved?: boolean;
};

const ApplicationApprovals: FC<Props> = ({
  id,
  credit,
  criminal,
  background,
  singleApplication,
  iconSize,
  marginTop = 12,
  applicantRemoved,
}) => {
  return (
    <div style={{ marginTop, display: 'flex', justifyContent: 'center' }}>
      <ApprovalIcon
        size={iconSize}
        status={getBooleanStatus(id)}
        icon="id"
        singleApplication={singleApplication}
        applicantRemoved={applicantRemoved}
      />

      <ApprovalIcon
        size={iconSize}
        status={getBooleanStatus(credit)}
        icon="credit"
        singleApplication={singleApplication}
        applicantRemoved={applicantRemoved}
      />

      <ApprovalIcon
        size={iconSize}
        status={getBooleanStatus(criminal)}
        icon="criminal"
        singleApplication={singleApplication}
        applicantRemoved={applicantRemoved}
      />

      <ApprovalIcon
        size={iconSize}
        status={getBooleanStatus(background)}
        icon="background"
        singleApplication={singleApplication}
        applicantRemoved={applicantRemoved}
      />
    </div>
  );
};

export default ApplicationApprovals;
