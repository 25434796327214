import { ModalFormButton } from '@/components/Button/formButton';
import EditTaskModal from '@/components/tasks/EditTaskModal';
import { useAppContext } from '@/contexts/AppContext';
import type { ColumnComponentType, ColumnType } from '@/notmagic/components/EntityTable/types';

const Component: ColumnComponentType = ({ value, refreshEntities }) => {
  const { users } = useAppContext();

  return (
    <ModalFormButton buttonText={value.name} link onSuccess={refreshEntities}>
      {(props) => (
        <EditTaskModal
          {...props}
          onDelete={() => refreshEntities?.()}
          task={value}
          users={users}
          showResponsibleField
          onNoteSaved={() => refreshEntities?.()}
        />
      )}
    </ModalFormButton>
  );
};

const Definition: ColumnType = {
  Component,
  hideSort: true,
};

export default Definition;
