import type { Column } from '@/@propify-components';
import { Align, SortDirection, Table } from '@/@propify-components';
import SearchField from '@/components/FilterBarWithURLQuery/SearchField';
import { enumColumn } from '@/components/TableUtils';
import type { PromotionSearchResult } from '@/domain/promotions';
import { DownOutlined, PlusOutlined, UpOutlined } from '@ant-design/icons';
import { Button, Checkbox, Col, Row, Space, Typography } from 'antd';
import type Big from 'big.js';
import type { FC } from 'react';
import { useCallback, useMemo, useState } from 'react';
import { formatAdjValue } from '../RentAdjValue';

const { Title } = Typography;

interface Props {
  otherPromotions: PromotionSearchResult[];
  onAddPromotion: (id: number) => void;
  getAdjustmentRentValue: (promotion: PromotionSearchResult) => Big;
}

const OtherPromotionsTable: FC<Props> = ({
  otherPromotions,
  onAddPromotion,
  getAdjustmentRentValue,
}) => {
  const [filter, setFilter] = useState('');
  const [pageSize, setPageSize] = useState(5);
  const [onlyShowRentAltPromotions, setOnlyShowRentAltPromotions] = useState(false);
  const [sortedBy, setSortedBy] = useState<string>();
  const [sortDir, setSortDir] = useState<SortDirection>(SortDirection.ASCENDING);
  const [isCollapsed, setIsCollapsed] = useState(true);

  const handleSearch = (value: string) => {
    setFilter(value);
  };

  const handlePageSizeChange = useCallback((newSize) => {
    setPageSize(newSize);
  }, []);

  const filteredPromotions = useMemo(() => {
    let promotions = otherPromotions.map((row) => ({
      ...row,
      promotionAmount: getAdjustmentRentValue(row),
    }));

    if (onlyShowRentAltPromotions) {
      promotions = promotions.filter((promotion) => !!promotion.promotionAmount.toNumber());
    }
    return !filter.trim()
      ? promotions
      : promotions.filter((p) =>
          p.name.toLowerCase().concat(p.text.toLowerCase()).includes(filter.trim().toLowerCase()),
        );
  }, [filter, getAdjustmentRentValue, onlyShowRentAltPromotions, otherPromotions]);

  const handleSortChange = (key: string, direction: SortDirection) => {
    setSortedBy(key);
    setSortDir(direction);
  };

  const columns: Column<PromotionSearchResult>[] = [
    {
      title: 'Name',
      key: 'name',
    },
    enumColumn({ title: 'Type', key: 'type', width: 119 }, { enumName: 'promotion-type' }),
    {
      title: 'Promotion Text',
      key: 'text',
      showTooltip: true,
      autoWidth: true,
    },
    {
      title: 'Adjustment to Rent',
      key: 'amount',
      render: (row) =>
        formatAdjValue(getAdjustmentRentValue(row), { showNAIfZero: true, orangeIfZero: true }),
    },
    {
      title: '',
      key: 'plus',
      hideSort: true,
      width: 75,
      align: Align.center,
      render: (row) => (
        <Button type="primary" size="small" className="add" onClick={() => onAddPromotion(row.id)}>
          <PlusOutlined />
        </Button>
      ),
    },
  ];

  return (
    <Row gutter={[8, 8]}>
      <Col xs={24} style={{ display: 'flex', justifyContent: 'space-between' }}>
        <Space align="center">
          <Title level={4} style={{ margin: 0 }}>
            Other Promotions
          </Title>
          <Button
            icon={isCollapsed ? <DownOutlined /> : <UpOutlined />}
            onClick={() => setIsCollapsed(!isCollapsed)}
          />
        </Space>
        <Space>
          <Checkbox
            value={onlyShowRentAltPromotions}
            onChange={(e) => setOnlyShowRentAltPromotions(e.target.checked)}
          >
            Only Show Rent Altering Promotions
          </Checkbox>
          <SearchField
            style={{ width: '151px' }}
            onSearch={handleSearch}
            defaultValue=""
            placeholder="Search"
          />
        </Space>
      </Col>
      <Col xs={24} style={{ height: isCollapsed ? 0 : undefined }}>
        <Table
          pageSize={pageSize}
          onPageSizeChange={handlePageSizeChange}
          columns={columns}
          data={filteredPromotions}
          rowKeyExtractor={(promotion) => promotion.id}
          className="propify-scrollable-table"
          sortedBy={sortedBy}
          sortDir={sortDir}
          onSortChange={handleSortChange}
        />
      </Col>
    </Row>
  );
};

export default OtherPromotionsTable;
