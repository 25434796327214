import type { Enum, Lookup } from '@/domain/base';
import type { PromotionSearchResult } from '@/domain/promotions';
import { PromotionType } from '@/domain/promotions';
import type { Property } from '@/domain/property';
import type { Unit } from '@/domain/unit';
import { ListingStatus } from '@/domain/unit';
import Big from 'big.js';
import isEmpty from 'lodash/isEmpty';

export const getListingStatusesOptions = (
  listingStatuses: Enum[],
  unit: Pick<Unit, 'marketRent' | 'listingStatus'>,
) =>
  listingStatuses.map((option) => {
    const disabled =
      (typeof unit?.marketRent !== 'number' &&
        [ListingStatus.ACTIVE, ListingStatus.COMING_SOON].includes(
          option.value as ListingStatus,
        )) ||
      (option.value === ListingStatus.HOLD &&
        ![ListingStatus.ACTIVE, ListingStatus.HOLD].includes(unit.listingStatus!));

    const title = disabled
      ? `Status can't be set to ACTIVE or COMING SOON unless there is a Market Rent`
      : '';

    return { ...option, disabled, title };
  });

export const getPromotionsPreview = <T extends Lookup>(
  promotionIds: number[],
  promotions: T[] | undefined,
): T[] => {
  const previewValues: T[] = [];

  if (!isEmpty(promotionIds) && !isEmpty(promotions)) {
    promotionIds.forEach((promotionId) => {
      const result = (promotions || []).find((lookup) => lookup.id === promotionId);
      if (result) {
        previewValues.push(result);
      }
    });
  }

  return previewValues;
};

export const getCommonAccessInformation = (property?: Property) => {
  if (!property) {
    return '';
  }

  if (property.commonDoorLockCode) {
    return property.commonDoorLockCode;
  }

  if (property.community?.accessInformationList?.length) {
    const accessInformation =
      property.community.accessInformationList.find((a) => a.primary) ||
      property.community.accessInformationList[0];

    return `${accessInformation.entranceName}\n${accessInformation.description}\n${accessInformation.code}\n${accessInformation.emergencyContact}`;
  }

  return '';
};

export const getRentAdjustment = (
  newMarketRent: number | Big,
  associatedPromotions: Pick<PromotionSearchResult, 'amount' | 'adjustListedRent' | 'type'>[],
) =>
  associatedPromotions
    .filter((p) => p.amount > 0 && p.adjustListedRent)
    .reduce((total, p) => {
      let value = Big(0);
      if (p.type === PromotionType.FIXED_AMOUNT) {
        value = Big(p.amount);
      }

      if (p.type === PromotionType.RENT_MULTIPLE) {
        value = Big(newMarketRent).times(p.amount);
      }

      return total.plus(value);
    }, Big(0))
    .div(12);
