import type {
  FilterComponentType,
  FilterComponentTypeProps,
} from '@/notmagic/components/EntityTable/types';
import { Input } from 'antd';

type Props = FilterComponentTypeProps;

const Component: FilterComponentType<Props> = ({ editable, value, onChangeValue }) => {
  if (Array.isArray(value)) {
    // eslint-disable-next-line no-console
    console.error('ERROR: Filters of type TEXT only support a single value');
    return <></>;
  }

  if (!editable) {
    return <>{value}</>;
  }
  return (
    <Input
      value={value}
      onChange={(event) => onChangeValue(event.target.value)}
      min={0}
      style={{ flex: 1 }}
    />
  );
};

export default {
  Component,

  // This is for an exact match (case-sensitive)
  jsonataQuery: (value: string, key: string) => `$v.${key} = '${value}'`,

  // This is for a partial match (case-sensitive)
  // jsonataQuery: (value: string, key: string) => `$contains($v.${key}, '${value}')`,

  // This is for a partial match
  // jsonataQuery: (value: string, key: string) =>
  //   `$contains($lowercase($v.${key}), $lowercase('${value}'))`,
};
