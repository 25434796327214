import { crudService } from '@/utils/request';
import { handleError } from '@/utils/utils';
import { message } from 'antd';
import update from 'immutability-helper';
import queryString from 'query-string';
import { useEffect, useRef } from 'react';
import useSWR from 'swr';
import type { SWRConfiguration } from 'swr/dist/types';
import useSWRImmutable from 'swr/immutable';
import { useEntityTableContext } from '../Context';
import type { Entity } from '../types';

export const useEndpoint = <T>(key: any) => {
  const { fetcher } = useEntityTableContext();
  const controllerRef = useRef<AbortController>();

  useEffect(() => {
    return () => {
      controllerRef.current?.abort();
    };
  }, []);

  return useSWR<T>(
    key,
    (url: string, params?: Record<string, any>) => {
      const newController = new AbortController();
      controllerRef.current?.abort();
      controllerRef.current = newController;

      return fetcher(url, params, newController.signal)
        .then((data: T) => {
          // Check if aborted and throw an error to avoid caching invalid data
          if (newController.signal.aborted) {
            throw new Error('Fetch Aborted');
          }
          return data;
        })
        .catch((error: Error) => {
          if (newController.signal.aborted) {
            throw error;
          }
          handleError(error, {
            displayToast: false,
            rethrowError: true,
          });
        });
    },
    {
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
    },
  );
};

export const useImmutableEndpoint = <T>(key: any, config?: SWRConfiguration<T>) => {
  const { fetcher } = useEntityTableContext();
  return useSWRImmutable<T>(key, fetcher, {
    revalidateOnFocus: false,
    revalidateOnReconnect: false,
    ...config,
  });
};

const DEFAULT_EMPTY_ARRAY: Entity[] = [];

export const useEntitySearch = (
  getUrl: string,
  updateUrl: string,
  parameters?: Record<string, unknown>,
) => {
  const {
    data: rows = DEFAULT_EMPTY_ARRAY,
    mutate,
    isValidating,
  } = useEndpoint<Entity[]>([getUrl, parameters]);

  const rowsReference = useRef(rows);

  useEffect(() => {
    rowsReference.current = rows;
  }, [rows]);

  const onBulkValidate = async (ids: number[]) => {
    if (!rowsReference.current?.length || !ids?.length) {
      return;
    }
    const qs = queryString.stringify({ ...parameters, id: ids } || {}, { arrayFormat: 'none' });
    const response = await crudService.get<Entity[]>(qs ? `${getUrl}?${qs}` : getUrl);

    const keyedResponse = response.reduce(
      (map, row) => ({
        ...map,
        [row.id]: row,
      }),
      {},
    );

    const mutated = rowsReference.current?.map((row) =>
      keyedResponse[row.id] ? keyedResponse[row.id] : row,
    );
    response.forEach((row) => {
      if (!rowsReference.current?.some((each) => each.id === row.id)) {
        mutated.push(row);
      }
    });

    return mutate(mutated, false);
  };

  const onValidateEntity = (id: number) => {
    onBulkValidate([id]);
  };

  const onDelete = (id: number) => {
    if (!rowsReference.current?.length || !id) {
      return;
    }

    const entityIndex = rowsReference.current?.findIndex((row) => row.id === id);
    if (entityIndex === -1) {
      return;
    }

    crudService
      .delete<Entity>(`${updateUrl || getUrl}/${id}`)
      .then(() => {
        message.success(`Entity deleted`);
        mutate(
          update(rowsReference.current, {
            $splice: [[entityIndex, 0]],
          }),
        );
      })
      .catch((error) => {
        handleError(error, { toastMessage: `There was an error while deleting the entity` });
      });
  };

  return {
    data: rows,
    onDelete,
    onValidate: onValidateEntity,
    onBulkValidate,
    mutate,
    isValidating,
  };
};
