import AutoFillDictionaryPage from '@/components/DocumentTemplates/AutoFillDictionaryPage';
import DocumentTemplatesPage from '@/components/DocumentTemplates/DocumentTemplatesPage';
import PageLoading from '@/components/PageLoading';
import { getHistory } from '@/history';
import NotFoundPage from '@/pages/404';
import ErrorPage from '@/pages/error';
import { SentryService } from '@/services/sentry';
import {
  BarChartOutlined,
  CheckCircleOutlined,
  CodeSandboxOutlined,
  ContactsOutlined,
  ControlOutlined,
  FileTextOutlined,
  FolderOpenOutlined,
  GlobalOutlined,
  HomeOutlined,
  MenuOutlined,
  PhoneOutlined,
  SettingOutlined,
  TagOutlined,
  TeamOutlined,
  ToolOutlined,
  UserAddOutlined,
} from '@ant-design/icons';
import type { FC, ReactNode } from 'react';
import { lazy, Suspense, useLayoutEffect } from 'react';
import { Navigate, Outlet, useLocation, useNavigate, useRouteError } from 'react-router';
import type { RouteObject } from 'react-router-dom';
import PropertyGroupDetails from './pages/property/property-group-details';

const AuthCallbackPage = lazy(() => import('@/pages/auth/callback'));
const SecurityLayout = lazy(() => import('@/layouts/SecurityLayout'));
const BasicLayout = lazy(() => import('@/layouts/BasicLayout'));
const IframePage = lazy(() => import('@/pages/dashboard/components/IframePage'));
const ContactPage = lazy(() => import('@/pages/contact'));
const CommunicationsPhoneCallsPage = lazy(() => import('@/pages/communications/PhoneCalls'));
const CommunicationsMessagesPage = lazy(() => import('@/pages/communications/messages'));
const CommunicationsEmailsPage = lazy(() => import('@/pages/communications/emails'));
const TicketsPage = lazy(() => import('@/pages/tickets'));
const MyTasksPage = lazy(() => import('@/pages/tasks/my-tasks'));
const MyProcessTasksPage = lazy(() => import('@/pages/tasks/my-process-tasks'));
const OnboardingTasksPage = lazy(() => import('@/pages/tasks/onboarding-tasks'));
const RenovationTasksPage = lazy(() => import('@/pages/tasks/renovation-tasks'));
const ListingTasksPage = lazy(() => import('@/pages/tasks/listing-tasks'));
const LeasingTasksPage = lazy(() => import('@/pages/tasks/leasing-tasks'));
const MoveInTasksPage = lazy(() => import('@/pages/tasks/move-in-tasks'));
const MoveOutTasksPage = lazy(() => import('@/pages/tasks/move-out-tasks'));
const TurnTasksPage = lazy(() => import('@/pages/tasks/turn-tasks'));
const DispositionTasksPage = lazy(() => import('@/pages/tasks/disposition-tasks'));
const AcquisitionsPropertiesPage = lazy(() => import('@/pages/acquisitions/properties'));
const AcquisitionsProfilesPage = lazy(() => import('@/pages/acquisitions/profiles'));
const AcquisitionsProfilePage = lazy(() => import('@/pages/acquisitions/profile'));
const AcquisitionsPortfolioPage = lazy(() => import('@/pages/acquisitions/portfolios'));
const AcquisitionsPropertyDetailsPage = lazy(() => import('@/pages/acquisitions/property-details'));
const AcquisitionsPortfolioDetailsPage = lazy(
  () => import('@/pages/acquisitions/portfolio-details'),
);
const AcquisitionsRegionalAssumptionsPage = lazy(
  () => import('@/pages/acquisitions/regional-assumptions'),
);
const AcquisitionsStateDetailsPage = lazy(() => import('@/pages/acquisitions/property-details'));
const PropertiesPage = lazy(() => import('@/pages/property/view'));
const PropertyCreatePage = lazy(() => import('@/pages/property/create'));
const UnitRentalPricingPage = lazy(() => import('@/pages/property/unit-rental-pricing'));
const UnitsPage = lazy(() => import('@/pages/unit/view'));
const UnitsDetailsPage = lazy(() => import('@/pages/unit/manage'));
const ListingsPage = lazy(() => import('@/pages/property/listings/ListingsPage'));
const ListingsDetailsPage = lazy(() => import('@/pages/property/listings/ListingDetails'));
const OnboardingPage = lazy(() => import('@/pages/property/onboardings/OnboardingsPage'));
const OnboardingDetailsPage = lazy(() => import('@/pages/property/onboardings/OnboardingDetails'));
const PropertyUtilitiesPage = lazy(() => import('@/pages/utilities/UtilitiesPage'));
const PropertyCommunitiesPage = lazy(() => import('@/pages/communities/index'));
const PropertyManageCommunityPage = lazy(() => import('@/pages/communities/CommunityDetails'));
const PropertyDetailsPage = lazy(() => import('@/pages/property/manage'));
const SystemsPage = lazy(() => import('@/pages/iot/view'));
const SystemsAddPage = lazy(() => import('@/pages/iot/add'));
const SystemsVendorCodesPage = lazy(() => import('@/pages/iot/vendor-codes'));
const SystemsVendorCodesDetailsPage = lazy(() => import('@/pages/iot/vendor-code'));
const SystemsManagePage = lazy(() => import('@/pages/iot/manage'));
const SystemsUnassignedPropertiesPage = lazy(() => import('@/pages/iot/unassigned-properties'));
const SystemsVacantPropertiesPage = lazy(() => import('@/pages/iot/vacant-properties'));
const SystemsOccupiedPropertiesPage = lazy(() => import('@/pages/iot/occupied-properties'));
const PropertyGroups = lazy(() => import('@/pages/property/property-groups'));
const PersonDetailsPage = lazy(() => import('@/pages/person/details'));

const LeadsPage = lazy(() => import('@/pages/prospects/leads/index'));
const ShowingsPage = lazy(() => import('@/pages/prospects/showings'));
const LeasingPage = lazy(() => import('@/pages/prospects/leasing'));
const LeasingDetailsPage = lazy(() => import('@/pages/prospects/leasing/details'));
const UnitApplicationsPage = lazy(
  () => import('@/pages/prospects/unit-applications/ApplicationsPage'),
);
const UnitApplicationDetailsPage = lazy(
  () => import('@/pages/prospects/unit-applications/details'),
);
const ApplicationsPage = lazy(() => import('@/pages/prospects/applications'));
const RenewalsPage = lazy(() => import('@/pages/prospects/renewals'));
const RenewalsDetailsPage = lazy(
  () => import('@/pages/prospects/renewals/components/RenewalsDetail'),
);
const TurnsPage = lazy(() => import('@/pages/turns/turns'));
const TurnWorkOrdersPage = lazy(() => import('@/pages/turns/turn-work-orders'));
const TurnJobsPage = lazy(() => import('@/pages/turns/turn-jobs'));
const RehabPage = lazy(() => import('@/pages/turns/rehabs'));
const RehabWorkOrdersPage = lazy(() => import('@/pages/turns/rehab-work-orders'));
const RehabJobsPage = lazy(() => import('@/pages/turns/rehab-jobs'));
const TurnsDetailsPage = lazy(() => import('@/pages/turns/details'));
const JobsPage = lazy(() => import('@/pages/maintenance/jobs'));
const JobWorkOrdersPage = lazy(() => import('@/pages/maintenance/jobs-work-orders'));
const JobsDetailsPage = lazy(() => import('@/pages/maintenance/job-details'));
const WorkOrdersPage = lazy(() => import('@/pages/maintenance/work-orders'));
const MyWorkOrdersPage = lazy(() => import('@/pages/maintenance/my-work-orders'));
const WorkOrdersDetailsPage = lazy(() => import('@/pages/maintenance/work-order-details'));
const RecurringWorkOrdersPage = lazy(() => import('@/pages/maintenance/recurring-work-orders'));
const RecurringServicePropertiesPage = lazy(
  () => import('@/pages/maintenance/recurring-service-properties'),
);
const BidsPage = lazy(() => import('@/pages/maintenance/bids'));
const VendorsPage = lazy(() => import('@/pages/maintenance/vendors'));
const MaintenanceManagePage = lazy(() => import('@/pages/maintenance/manage'));
const RecurringServiceProvidersPage = lazy(
  () => import('@/pages/maintenance/recurring-service-providers'),
);
const ResidentsPage = lazy(() => import('@/pages/resident/view'));
const ResidentsCollectionsPage = lazy(() => import('@/pages/resident/collections'));
const ResidentsMoveInsPage = lazy(() => import('@/pages/resident/moveins'));
const ResidentsMoveInDetailsPage = lazy(() => import('@/pages/resident/moveinDetails'));
const ResidentsMoveOutsPage = lazy(() => import('@/pages/resident/moveOuts'));
const ResidentsMoveOutDetailsPage = lazy(() => import('@/pages/resident/moveOutDetails'));
const ResidentsDisputesPage = lazy(() => import('@/pages/resident/disputes'));
const ResidentsDetailsPage = lazy(() => import('@/pages/resident/disputes/details'));
const OrganizationsPage = lazy(() => import('@/pages/organizations/view'));
const OrganizationsManagePage = lazy(() => import('@/pages/organizations/manage'));
const ReportsPage = lazy(() => import('@/pages/report/run'));
const ReportsEditPage = lazy(() => import('@/pages/report/edit'));
const UsersPage = lazy(() => import('@/pages/user/view'));
const UsersManagePage = lazy(() => import('@/pages/user/manage'));
const SurveysPage = lazy(() => import('@/pages/surveys'));
const SurveysDetailsPage = lazy(() => import('@/pages/surveys/surveyDetails'));
const SurveysResponseDetailsPage = lazy(() => import('@/pages/surveys/components/ResponseDetails'));
const SettingsPage = lazy(() => import('@/pages/settings'));
const SettingsUsersPage = lazy(() => import('@/pages/settings/users/view'));
const SettingsUserGroupsPage = lazy(() => import('@/pages/settings/user-groups/view'));
const SettingsUserGroupsDetailsPage = lazy(() => import('@/pages/settings/user-groups/details'));
const SettingsUsersEditPage = lazy(() => import('@/pages/settings/users/edit'));
const SettingsPropertyGroupsPage = lazy(
  () => import('@/pages/settings/property-groups/property-groups'),
);
const SettingsPermissionsPage = lazy(() => import('@/pages/settings/permissions'));
const SettingsCompaniesPage = lazy(() => import('@/pages/settings/companies'));
const SettingsIlsSyndicationPage = lazy(() => import('@/pages/settings/ils-syndication'));
const SettingsPropertyManagerPage = lazy(() => import('@/pages/settings/property-managers/view'));
const SettingsPropertyManagerDetailsPage = lazy(
  () => import('@/pages/settings/property-managers/manage'),
);
const SettingsPropertyManagerOfficePage = lazy(
  () => import('@/pages/settings/property-managers/office'),
);
const SettingsEmailsPage = lazy(() => import('@/pages/settings/email/view'));
const SettingsEmailsCreatePage = lazy(() => import('@/pages/settings/email/create'));
const SettingsEmailsDetailsPage = lazy(() => import('@/pages/settings/email/details'));
const FormViewPage = lazy(() => import('@/pages/form/view'));
const FormViewDetailsPage = lazy(() => import('@/pages/form/details'));
const PetPolicyPage = lazy(() => import('@/pages/pet-policy/view'));
const CustomFieldsPage = lazy(() => import('@/pages/custom-fields/view'));
const CustomFieldsDetailsPage = lazy(() => import('@/pages/custom-fields/details'));
const TemperatureProfilesPage = lazy(() => import('@/pages/temperature-profiles/view'));
const MetadataPlaygroundPage = lazy(() => import('@/pages/metadata-playground/view'));
const PromotionsPage = lazy(() => import('@/pages/promotions/view'));
const PromotionsDetailsPage = lazy(() => import('@/pages/promotions/manage'));
const TicketGroupsPage = lazy(() => import('@/pages/settings/ticket-groups/view'));
const MaintenanceSettingsPage = lazy(() => import('@/pages/settings/maintenance-settings/view'));
const EmailGroupsPage = lazy(() => import('@/pages/settings/email-groups/view'));
const TicketCreationRulesPage = lazy(() => import('@/pages/settings/ticket-creation-rules'));
const SettingsRegionsPage = lazy(() => import('@/pages/settings/regions'));
const SettingsBrandsPage = lazy(() => import('@/pages/settings/brands/index'));
const SettingsBrandDetailsPage = lazy(() => import('@/pages/settings/brands/details'));
const SettingsTaskTemplatesPage = lazy(() => import('@/pages/settings/task-templates/index'));
const SettingsPresetTasksPage = lazy(() => import('@/pages/settings/preset-tasks/index'));
const SettingsHoldingCompaniesPage = lazy(() => import('@/pages/settings/holding-companies/index'));
const SettingsApplicationFeesPage = lazy(() => import('@/pages/settings/application-fees'));
const UserMePage = lazy(() => import('@/pages/user/me'));
const DispositionsPage = lazy(() => import('@/pages/property/dispositions'));
const DispositionOfferDetailsPage = lazy(
  () => import('@/pages/property/dispositions/offers/details'),
);
const DispositionDetailsPage = lazy(
  () => import('@/pages/property/dispositions/DispositionDetailsPage'),
);

export type PropifyRoute = {
  path: string;
  component?: Function;
  routes?: PropifyRoute[];
  redirect?: string;
  props?: {
    name?: string;
    icon?: ReactNode;
    hideInMenu?: boolean;
    authority?: [string];
    [key: string]: unknown;
  };
};

export const routes: PropifyRoute[] = [
  {
    path: '/auth',
    routes: [
      {
        path: '/auth/callback',
        component: AuthCallbackPage,
      },
    ],
  },
  {
    path: '/',
    component: SecurityLayout,
    routes: [
      {
        path: '/',
        component: BasicLayout,
        props: {
          authority: ['USER'],
        },
        routes: [
          {
            path: '/',
            redirect: '/dashboard/leasing',
          },
          {
            path: '/metadata-playground',
            component: MetadataPlaygroundPage,
            props: {
              name: 'Metadata Playground',
              hideInMenu: true,
            },
          },
          {
            path: '/dashboard',
            props: {
              name: 'Dashboard',
              icon: <BarChartOutlined />,
            },
            routes: [
              {
                path: '/dashboard',
                redirect: '/dashboard/leasing',
              },
              {
                path: '/dashboard/:name',
                component: IframePage,
                props: {
                  hideInMenu: true,
                },
              },
            ],
          },
          {
            path: '/contact',
            component: ContactPage,
            props: {
              name: 'Contact',
              icon: <ContactsOutlined />,
            },
          },
          {
            path: '/contactDetails/:id',
            component: ContactPage,
            props: {
              name: 'Contact Details',
              hideInMenu: true,
            },
          },
          {
            path: '/tickets',
            props: {
              name: 'Tickets',
              icon: <TagOutlined />,
            },
            routes: [
              {
                path: '/tickets',
                redirect: '/tickets/all-tickets',
              },
              {
                path: '/tickets/:groupId',
                component: TicketsPage,
                props: {
                  hideInMenu: true,
                },
              },
            ],
          },
          {
            path: '/tasks',
            props: {
              name: 'Tasks',
              icon: <MenuOutlined />,
            },
            routes: [
              {
                path: '/tasks/mine',
                component: MyTasksPage,
                props: {
                  name: 'My Tasks',
                },
              },
              {
                path: '/tasks/my-process',
                component: MyProcessTasksPage,
                props: {
                  name: 'My Process Tasks',
                },
              },
              {
                path: '/tasks/onboarding',
                component: OnboardingTasksPage,
                props: {
                  name: 'Onboarding Tasks',
                },
              },
              {
                path: '/tasks/renovation',
                component: RenovationTasksPage,
                props: {
                  name: 'Renovation Tasks',
                },
              },
              {
                path: '/tasks/listing',
                component: ListingTasksPage,
                props: {
                  name: 'Listing Tasks',
                },
              },
              {
                path: '/tasks/leasing',
                component: LeasingTasksPage,
                props: {
                  name: 'Leasing Tasks',
                },
              },
              {
                path: '/tasks/move-in',
                component: MoveInTasksPage,
                props: {
                  name: 'Move-In Tasks',
                },
              },
              {
                path: '/tasks/move-out',
                component: MoveOutTasksPage,
                props: {
                  name: 'Move-Out Tasks',
                },
              },
              {
                path: '/tasks/turn',
                component: TurnTasksPage,
                props: {
                  name: 'Turn Tasks',
                },
              },
              {
                path: '/tasks/dispositions',
                component: DispositionTasksPage,
                props: {
                  name: 'Disposition Tasks',
                  hideInMenu: true, // TODO: Show in menu when the feature is complete
                },
              },
            ],
          },
          {
            path: '/prospects',
            props: {
              name: 'Leasing',
              icon: <UserAddOutlined />,
            },
            routes: [
              {
                path: '/prospects',
                redirect: '/prospects/leasing',
              },
              {
                path: '/prospects/unit-rental-pricing',
                component: UnitRentalPricingPage,
                props: {
                  name: 'Unit Rental Pricing',
                },
              },
              {
                path: '/prospects/leads',
                component: LeadsPage,
                props: {
                  name: 'Leads',
                },
              },
              {
                path: '/prospects/showings',
                component: ShowingsPage,
                props: {
                  name: 'Showings',
                },
              },
              {
                path: '/prospects/leasing/:id',
                component: LeasingDetailsPage,
                props: {
                  name: 'Details',
                  hideInMenu: true,
                },
              },
              {
                path: '/prospects/unit-applications',
                component: UnitApplicationsPage,
                props: {
                  name: 'Unit Applications',
                },
              },
              {
                path: '/prospects/unit-applications/:id',
                component: UnitApplicationDetailsPage,
                props: {
                  name: 'Details',
                  hideInMenu: true,
                },
              },
              {
                path: '/prospects/applications',
                component: ApplicationsPage,
                props: {
                  name: 'Prospect Applications',
                },
              },
              {
                path: '/prospects/leasing',
                component: LeasingPage,
                props: {
                  name: 'Leasing Process',
                },
              },
            ],
          },
          {
            path: '/document-templates',
            props: {
              name: 'Document Templates',
              icon: <FileTextOutlined />,
              // This is going to be available when document templates feature is complete
              hideInMenu: true,
            },
            routes: [
              {
                path: '/document-templates/view',
                component: DocumentTemplatesPage,
                props: {
                  name: 'Document Templates',
                },
              },
              {
                path: '/document-templates/autofill-dictionary',
                component: AutoFillDictionaryPage,
                props: {
                  name: 'Auto-Fill Dictionary',
                },
              },
            ],
          },
          {
            path: '/maintenance',
            props: {
              name: 'Field Operations',
              icon: <ToolOutlined />,
            },
            routes: [
              {
                path: '/maintenance',
                redirect: '/maintenance/work-orders',
              },
              {
                path: '/maintenance/my-work-orders',
                component: MyWorkOrdersPage,
                props: {
                  name: 'My Work Orders',
                },
              },
              {
                path: '/maintenance/turns',
                component: TurnsPage,
                props: {
                  name: 'Turns',
                  showTurnRehabsCounts: false, // I'm kipping this in case we need to use them again
                },
              },
              {
                path: '/maintenance/turn-work-orders',
                component: TurnWorkOrdersPage,
                props: {
                  name: 'Turn Work Orders',
                },
              },
              {
                path: '/maintenance/turn-jobs',
                component: TurnJobsPage,
                props: {
                  name: 'Turn Jobs',
                },
              },
              {
                path: '/maintenance/rehabs',
                component: RehabPage,
                props: {
                  name: 'Renovations',
                  showTurnRehabsCounts: false, // I'm kipping this in case we need to use them again
                },
              },
              {
                path: '/maintenance/rehab-work-orders',
                component: RehabWorkOrdersPage,
                props: {
                  name: 'Renovation Work Orders',
                },
              },
              {
                path: '/maintenance/rehab-jobs',
                component: RehabJobsPage,
                props: {
                  name: 'Renovation Jobs',
                },
              },
              {
                path: '/maintenance/turn-rehabs/:id',
                component: TurnsDetailsPage,
                props: {
                  name: 'Turns/Rehabs',
                  hideInMenu: true,
                },
              },
              {
                path: '/maintenance/jobs',
                component: JobsPage,
                props: {
                  // cspell:ignore Maint
                  name: 'Maint. Jobs',
                  showJobsCounts: false, // I'm kipping this in case we need to use them again
                },
              },
              {
                path: '/maintenance/job-work-orders',
                component: JobWorkOrdersPage,
                props: {
                  // cspell:ignore Maint
                  name: 'Maint. Job Work Orders',
                  showJobsCounts: false,
                },
              },
              {
                path: '/maintenance/jobs/:id',
                component: JobsDetailsPage,
                props: {
                  name: 'Details',
                  hideInMenu: true,
                },
              },
              {
                path: '/maintenance/work-orders',
                component: WorkOrdersPage,
                props: {
                  name: 'Work Orders',
                  showWorkOrdersCount: false, // I'm kipping this in case we need to use them again
                },
              },
              {
                path: '/maintenance/work-orders/:id',
                component: WorkOrdersDetailsPage,
                props: {
                  name: 'Details',
                  hideInMenu: true,
                },
              },
              {
                path: '/maintenance/bids',
                component: BidsPage,
                props: {
                  name: 'Bids',
                },
              },
              {
                path: '/maintenance/vendors',
                component: VendorsPage,
                props: {
                  name: 'Vendors',
                },
              },
              {
                path: '/maintenance/vendors/:id',
                component: MaintenanceManagePage,
                props: {
                  name: 'Details',
                  hideInMenu: true,
                },
              },
              {
                path: '/maintenance/recurring-work-orders',
                component: RecurringWorkOrdersPage,
                props: {
                  name: 'Recurring Work Orders',
                },
              },
              {
                path: '/maintenance/recurring-service-properties',
                component: RecurringServicePropertiesPage,
                props: {
                  name: 'Recurring WO Properties',
                },
              },
              {
                path: '/maintenance/recurring-service-providers',
                component: RecurringServiceProvidersPage,
                props: {
                  name: 'Recurring WO Providers',
                },
              },
            ],
          },
          {
            path: '/residents',
            props: {
              name: 'Residents',
              icon: <TeamOutlined />,
            },
            routes: [
              {
                path: '/residents',
                redirect: '/residents/view',
              },
              {
                path: '/residents/view',
                component: ResidentsPage,
                props: {
                  name: 'Residents',
                },
              },
              {
                path: '/residents/move-ins',
                component: ResidentsMoveInsPage,
                props: {
                  name: 'Move-Ins',
                  hideInMenu: true,
                },
              },
              {
                path: '/residents/move-ins',
                component: ResidentsMoveInsPage,
                props: {
                  name: 'Move-Ins',
                },
              },
              {
                path: '/residents/move-ins/:id',
                component: ResidentsMoveInDetailsPage,
              },
              {
                path: '/residents/renewals',
                component: RenewalsPage,
                props: {
                  name: 'Renewals',
                },
              },
              {
                path: '/residents/renewals/:id',
                component: RenewalsDetailsPage,
                props: {
                  name: 'Renewal',
                  hideInMenu: true,
                },
              },
              {
                path: '/residents/move-outs',
                component: ResidentsMoveOutsPage,
                props: {
                  name: 'Move-Outs',
                  hideInMenu: true,
                },
              },
              {
                path: '/residents/move-outs',
                component: ResidentsMoveOutsPage,
                props: {
                  name: 'Move-Outs',
                },
              },
              {
                path: '/residents/move-outs/:id',
                component: ResidentsMoveOutDetailsPage,
              },
              {
                path: '/residents/collections',
                component: ResidentsCollectionsPage,
                props: {
                  name: 'Collections',
                },
              },
              {
                path: '/residents/disputes',
                component: ResidentsDisputesPage,
                props: {
                  name: 'Disputes',
                  hideInMenu: true,
                },
              },
              {
                path: '/residents/disputes/:id',
                component: ResidentsDetailsPage,
                props: {
                  hideInMenu: true,
                },
              },
            ],
          },
          {
            path: '/acquisitions',
            props: {
              name: 'Acquisitions',
              icon: <CodeSandboxOutlined />,
              hideInMenu: true,
            },
            routes: [
              {
                path: '/acquisitions',
                redirect: '/acquisitions/properties',
              },
              {
                path: '/acquisitions/properties',
                component: AcquisitionsPropertiesPage,
                props: {
                  name: 'Properties',
                },
              },
              {
                path: '/acquisitions/portfolios',
                component: AcquisitionsPortfolioPage,
                props: {
                  name: 'Portfolios',
                },
              },
              {
                path: '/acquisitions/profiles',
                component: AcquisitionsProfilesPage,
                props: {
                  name: 'Underwriting Profiles',
                },
              },
              {
                path: '/acquisitions/properties/:id',
                component: AcquisitionsPropertyDetailsPage,
                props: {
                  name: 'Property',
                  hideInMenu: true,
                },
              },
              {
                path: '/acquisitions/portfolios/:id',
                component: AcquisitionsPortfolioDetailsPage,
                props: {
                  name: 'Portfolio',
                  hideInMenu: true,
                },
              },
              {
                path: '/acquisitions/portfolios/:id/properties/:propertyId',
                component: AcquisitionsPropertyDetailsPage,
                props: {
                  name: 'Portfolio Property',
                  hideInMenu: true,
                },
              },
              {
                path: '/acquisitions/profiles/:id',
                component: AcquisitionsProfilePage,
                props: {
                  name: 'Profile',
                  hideInMenu: true,
                },
              },
              {
                path: '/acquisitions/regional-assumptions',
                component: AcquisitionsRegionalAssumptionsPage,
                props: {
                  name: 'Regional Assumptions',
                },
              },
              {
                path: '/acquisitions/regional-assumptions/:state',
                component: AcquisitionsStateDetailsPage,
                props: {
                  name: 'State Details',
                  hideInMenu: true,
                },
              },
            ],
          },
          {
            path: '/properties',
            props: {
              name: 'Properties',
              icon: <HomeOutlined />,
            },
            routes: [
              {
                path: '/properties',
                redirect: '/properties/view',
              },
              {
                path: '/properties/view',
                component: PropertiesPage,
                props: {
                  name: 'Properties',
                },
              },
              {
                path: '/properties/property-groups',
                component: PropertyGroups,
                props: {
                  name: 'Property Groups',
                },
              },
              {
                path: '/properties/property-groups/:id',
                component: PropertyGroupDetails,
                props: {
                  name: 'Property Group Details',
                  hideInMenu: true,
                },
              },
              {
                path: '/properties/create',
                component: PropertyCreatePage,
                props: {
                  name: 'Add',
                  hideInMenu: true,
                },
              },
              {
                path: '/properties/units',
                component: UnitsPage,
                props: {
                  name: 'Units',
                },
              },
              {
                path: '/properties/listings',
                component: ListingsPage,
                props: {
                  name: 'Listings',
                },
              },
              {
                path: '/properties/listings/:id',
                component: ListingsDetailsPage,
                props: {
                  hideInMenu: true,
                },
              },
              {
                path: '/properties/onboardings',
                component: OnboardingPage,
                props: {
                  name: 'Onboardings',
                },
              },
              {
                path: '/properties/dispositions',
                component: DispositionsPage,
                props: {
                  name: 'Dispositions',
                  hideInMenu: true, // TODO: Show in menu when the feature is complete
                },
              },
              {
                path: '/properties/dispositions/:dispositionId/offers/:id',
                component: DispositionOfferDetailsPage,
                props: {
                  name: 'Offer',
                  hideInMenu: true,
                },
              },
              {
                path: '/properties/dispositions/:id',
                component: DispositionDetailsPage,
                props: {
                  hideInMenu: true,
                },
              },
              {
                path: '/properties/onboardings/:id',
                component: OnboardingDetailsPage,
                props: {
                  name: 'Details',
                  hideInMenu: true,
                },
              },
              {
                path: '/properties/utilities',
                component: PropertyUtilitiesPage,
                props: {
                  name: 'Utilities',
                },
              },
              {
                path: '/properties/communities',
                component: PropertyCommunitiesPage,
                props: {
                  name: 'Communities',
                },
              },
              {
                path: '/properties/communities/:id',
                component: PropertyManageCommunityPage,
                props: {
                  name: 'Community',
                  hideInMenu: true,
                },
              },
              {
                path: '/properties/:id',
                component: PropertyDetailsPage,
                props: {
                  name: 'Manage',
                  hideInMenu: true,
                },
              },
            ],
          },
          {
            path: '/systems',
            props: {
              name: 'Smart Home',
              icon: <ControlOutlined />,
            },
            routes: [
              {
                path: '/systems',
                redirect: '/systems/view',
              },
              {
                path: '/systems/view',
                component: SystemsPage,
                props: {
                  name: 'Systems',
                },
              },
              {
                path: '/systems/add',
                component: SystemsAddPage,
                props: {
                  name: 'Add',
                },
              },
              {
                path: '/systems/vendor-codes',
                component: SystemsVendorCodesPage,
                props: {
                  name: 'Vendor Codes',
                },
              },
              {
                path: '/systems/system-installation',
                component: SystemsUnassignedPropertiesPage,
                props: {
                  name: 'System Installation',
                },
              },
              {
                path: '/systems/vacant-properties',
                component: SystemsVacantPropertiesPage,
                props: {
                  name: 'Vacant Properties',
                },
              },
              {
                path: '/systems/occupied-properties',
                component: SystemsOccupiedPropertiesPage,
                props: {
                  name: 'Occupied Properties',
                },
              },
              {
                path: '/systems/vendor-codes/:id',
                component: SystemsVendorCodesDetailsPage,
                props: {
                  name: 'Vendor Code',
                  hideInMenu: true,
                },
              },
              {
                path: '/systems/:id',
                component: SystemsManagePage,
                props: {
                  name: 'Manage',
                  hideInMenu: true,
                },
              },
            ],
          },
          {
            path: '/units/:id',
            component: UnitsDetailsPage,
            props: {
              name: 'unit',
              hideInMenu: true,
            },
          },
          {
            path: '/person/:id',
            component: PersonDetailsPage,
            props: {
              name: 'Person Details',
              hideInMenu: true,
            },
          },
          {
            path: '/organizations',
            props: {
              name: 'Organizations',
              icon: <GlobalOutlined />,
              hideInMenu: true,
            },
            routes: [
              {
                path: '/organizations',
                redirect: '/organizations/view',
              },
              {
                path: '/organizations/view',
                component: OrganizationsPage,
                props: {
                  name: 'View',
                },
              },
              {
                path: '/organizations/:id',
                component: OrganizationsManagePage,
                props: {
                  name: 'Details',
                  hideInMenu: true,
                },
              },
            ],
          },
          {
            path: '/reports',
            props: {
              name: 'Reports',
              hideInMenu: true,
              icon: <FolderOpenOutlined />,
            },
            routes: [
              {
                path: '/reports',
                component: ReportsPage,
                props: {
                  name: 'View',
                  reportId: 4,
                },
              },
              {
                path: '/reports/create',
                component: ReportsEditPage,
                props: {
                  name: 'Create',
                },
              },
              {
                path: '/reports/:id',
                component: ReportsPage,
                props: {
                  name: 'run',
                  hideInMenu: true,
                },
              },
              {
                path: '/reports/edit/:id',
                component: ReportsEditPage,
                props: {
                  name: 'edit',
                  hideInMenu: true,
                },
              },
            ],
          },
          {
            path: '/users',
            props: {
              name: 'users',
              icon: <TeamOutlined />,
              hideInMenu: true,
            },
            routes: [
              {
                path: '/users/view',
                component: UsersPage,
                props: {
                  name: 'View',
                },
              },
              {
                path: '/users/:id',
                component: UsersManagePage,
                props: {
                  name: 'manage',
                  hideInMenu: true,
                },
              },
            ],
          },
          {
            path: '/communications',
            props: {
              name: 'Communications',
              icon: <PhoneOutlined />,
            },
            routes: [
              {
                path: '/communications',
                redirect: '/communications/messages',
              },
              {
                path: '/communications/phone-calls',
                component: CommunicationsPhoneCallsPage,
                props: {
                  name: 'Calls',
                },
              },
              {
                path: '/communications/messages',
                component: CommunicationsMessagesPage,
                props: {
                  name: 'Messages',
                },
              },
              {
                path: '/communications/emails',
                component: CommunicationsEmailsPage,
                props: {
                  name: 'Emails',
                },
              },
            ],
          },
          {
            path: '/surveys',
            props: {
              name: 'Surveys',
              icon: <CheckCircleOutlined />,
            },
            routes: [
              {
                path: '/surveys',
                component: SurveysPage,
                props: {
                  name: 'Surveys',
                  hideInMenu: true,
                },
              },
              {
                path: '/surveys/:id',
                component: SurveysDetailsPage,
                props: {
                  name: 'Survey',
                  hideInMenu: true,
                },
              },
              {
                path: '/surveys/:surveyId/answers/:answerId',
                component: SurveysResponseDetailsPage,
                props: {
                  name: 'Survey Response',
                  hideInMenu: true,
                },
              },
            ],
          },
          {
            path: '/settings',
            props: {
              name: 'Settings',
              icon: <SettingOutlined />,
            },
            routes: [
              {
                path: '/settings',
                component: SettingsPage,
                props: {
                  name: 'Settings',
                  hideInMenu: true,
                },
              },
              {
                path: '/settings/users',
                component: SettingsUsersPage,
                props: {
                  name: 'Users',
                  hideInMenu: true,
                },
              },
              {
                path: '/settings/user-groups',
                component: SettingsUserGroupsPage,
                props: {
                  name: 'User Groups',
                  hideInMenu: true,
                },
              },
              {
                path: '/settings/user-groups/:id',
                component: SettingsUserGroupsDetailsPage,
                props: {
                  name: 'User Group Details',
                  hideInMenu: true,
                },
              },
              {
                path: '/settings/users/:id',
                component: SettingsUsersEditPage,
                props: {
                  name: 'Edit Users',
                  hideInMenu: true,
                },
              },
              {
                path: '/settings/property-groups',
                component: SettingsPropertyGroupsPage,
                props: {
                  name: 'Property Groups',
                  hideInMenu: true,
                },
              },
              {
                path: '/settings/permissions',
                component: SettingsPermissionsPage,
                props: {
                  name: 'Permissions',
                  hideInMenu: true,
                },
              },
              {
                path: '/settings/companies',
                component: SettingsCompaniesPage,
                props: {
                  name: 'Companies',
                  hideInMenu: true,
                },
              },
              {
                path: '/settings/ils-syndication',
                component: SettingsIlsSyndicationPage,
                props: {
                  name: 'ils-syndication',
                  hideInMenu: true,
                },
              },
              {
                path: '/settings/property-managers',
                component: SettingsPropertyManagerPage,
                props: {
                  name: 'property-managers',
                  hideInMenu: true,
                },
              },
              {
                path: '/settings/property-managers/:id',
                component: SettingsPropertyManagerDetailsPage,
                props: {
                  name: 'property-managers',
                  hideInMenu: true,
                },
              },
              {
                path: '/settings/property-managers/:companyId/office/:id',
                component: SettingsPropertyManagerOfficePage,
                props: {
                  name: 'office',
                  hideInMenu: true,
                },
              },
              {
                path: '/settings/email-templates',
                component: SettingsEmailsPage,
                props: {
                  name: 'email-templates',
                  hideInMenu: true,
                },
              },
              {
                path: '/settings/email-templates/create',
                component: SettingsEmailsCreatePage,
                props: {
                  name: 'email-template-create',
                  hideInMenu: true,
                },
              },
              {
                path: '/settings/email-templates/:id',
                component: SettingsEmailsDetailsPage,
                props: {
                  name: 'email-template',
                  hideInMenu: true,
                },
              },
              {
                path: '/settings/forms',
                component: FormViewPage,
                props: {
                  name: 'forms',
                  hideInMenu: true,
                },
              },
              {
                path: '/settings/forms/:id',
                component: FormViewDetailsPage,
                props: {
                  name: 'form',
                  hideInMenu: true,
                },
              },
              {
                path: '/settings/pet-policy',
                component: PetPolicyPage,
                props: {
                  name: 'form',
                  hideInMenu: true,
                },
              },
              {
                path: '/settings/custom-fields',
                component: CustomFieldsPage,
                props: {
                  name: 'custom-fields',
                  hideInMenu: true,
                },
              },
              {
                path: '/settings/custom-fields/:id',
                component: CustomFieldsDetailsPage,
                props: {
                  name: 'custom-fields-details',
                  hideInMenu: true,
                },
              },
              {
                path: '/settings/temperature-profiles',
                component: TemperatureProfilesPage,
                props: {
                  name: 'temperature-profiles',
                  hideInMenu: true,
                },
              },
              {
                path: '/settings/promotions',
                component: PromotionsPage,
                props: {
                  name: 'Promotions',
                  hideInMenu: true,
                },
              },
              {
                path: '/settings/promotions/:id',
                component: PromotionsDetailsPage,
                props: {
                  name: 'Promotions Manage',
                  hideInMenu: true,
                },
              },
              {
                path: '/settings/ticket-groups',
                component: TicketGroupsPage,
                props: {
                  name: 'Ticket Groups',
                  hideInMenu: true,
                },
              },
              {
                path: '/settings/maintenance-settings',
                component: MaintenanceSettingsPage,
                props: {
                  name: 'Maintenance Settings',
                  hideInMenu: true,
                },
              },
              {
                path: '/settings/email-groups',
                component: EmailGroupsPage,
                props: {
                  name: 'Email Groups',
                  hideInMenu: true,
                },
              },
              {
                path: '/settings/ticket-creation-rules',
                component: TicketCreationRulesPage,
                props: {
                  name: 'Ticket Creation Rules',
                  hideInMenu: true,
                },
              },
              {
                path: '/settings/regions',
                component: SettingsRegionsPage,
                props: {
                  name: 'Regions',
                  hideInMenu: true,
                },
              },
              {
                path: '/settings/brands',
                component: SettingsBrandsPage,
                props: {
                  name: 'Brands',
                  hideInMenu: true,
                },
              },
              {
                path: '/settings/brands/:id',
                component: SettingsBrandDetailsPage,
                props: {
                  name: 'Brand Details',
                  hideInMenu: true,
                },
              },
              {
                path: '/settings/task-templates',
                component: SettingsTaskTemplatesPage,
                props: {
                  name: 'Task Templates',
                  hideInMenu: true,
                },
              },
              {
                path: '/settings/preset-tasks',
                component: SettingsPresetTasksPage,
                props: {
                  name: 'Preset Tasks',
                  hideInMenu: true,
                },
              },
              {
                path: '/settings/holding-companies',
                component: SettingsHoldingCompaniesPage,
                props: {
                  name: 'Holding Companies',
                  hideInMenu: true,
                },
              },
              {
                path: '/settings/application-fees',
                component: SettingsApplicationFeesPage,
                props: {
                  name: 'Application Fees',
                  hideInMenu: true,
                },
              },
            ],
          },
          {
            path: '/me',
            component: UserMePage,
            props: {
              name: 'mySettings',
              hideInMenu: true,
            },
          },
        ],
      },
    ],
  },
];

const ErrorBoundary: FC = () => {
  const error: any = useRouteError();
  if (error && error.status === 404 && error.internal) {
    return <NotFoundPage />;
  }

  SentryService.trackError(error);
  return <ErrorPage />;
};

const generateRouterRoute: (routesList: PropifyRoute[]) => RouteObject[] = (routesList) =>
  routesList.map((route) => ({
    path: route.path,
    element: route.redirect ? (
      <Navigate to={route.redirect} />
    ) : route.component ? (
      <Suspense fallback={<PageLoading />}>
        <route.component route={route} {...route.props} />
      </Suspense>
    ) : (
      <Outlet />
    ),
    children: !route.redirect && route.routes ? generateRouterRoute(route.routes) : undefined,
    ErrorBoundary,
  }));

const Root: FC = () => {
  const navigate = useNavigate();
  const location = useLocation();

  useLayoutEffect(() => {
    if (
      location.pathname === getHistory().location.pathname &&
      location.search === getHistory().location.search &&
      location.hash === getHistory().location.hash
    ) {
      return;
    }
    getHistory().replace(location.pathname + location.search + location.hash);
  }, [location.pathname, location.search, location.hash]);

  useLayoutEffect(() => {
    return getHistory().listen((change) => {
      if (
        location.pathname === change.location.pathname &&
        location.search === change.location.search &&
        location.hash === change.location.hash
      ) {
        return;
      }
      navigate(change.location.pathname + change.location.search + change.location.hash, {
        replace: true,
      });
    });
  }, [location.pathname, location.search, location.hash, navigate]);

  return <Outlet />;
};

const router = SentryService.sentryCreateBrowserRouter([
  {
    path: '/',
    element: <Root />,
    children: generateRouterRoute(routes),
    ErrorBoundary,
  },
]);

export default router;
