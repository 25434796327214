import type { Option } from '@/domain/base';

export enum FilterType {
  BOOLEAN = 'BOOLEAN',
  BOOLEAN_OPTIONAL = 'BOOLEAN_OPTIONAL',
  DATE = 'DATE',
  DATE_RANGE = 'DATE_RANGE',
  PRICE_RANGE = 'PRICE_RANGE',
  SELECT = 'SELECT',
  FREE_SELECT = 'FREE_SELECT',
  TEXT = 'TEXT',
  UNIT = 'UNIT',
  PROSPECT = 'PROSPECT',
  NUMBER = 'NUMBER',
}

export type SelectFilterType = {
  type: FilterType.SELECT;
  options: Option[];
  filterBy?: string;
  loading?: boolean;
  required?: boolean;
  multiple?: boolean;
  optionsForGetValue?: Option[];
  showSearch?: boolean;
};

export type FilterByType =
  | {
      type: FilterType.BOOLEAN;
      labelNo?: string;
      labelYes?: string;
    }
  | {
      type: FilterType.BOOLEAN_OPTIONAL;
      labelNo?: string;
      labelYes?: string;
    }
  | {
      type: FilterType.DATE;
    }
  | {
      type: FilterType.DATE_RANGE;
    }
  | {
      type: FilterType.PRICE_RANGE;
    }
  | {
      type: FilterType.NUMBER;
    }
  | SelectFilterType
  | {
      type: FilterType.FREE_SELECT;
      required?: boolean;
      defaultValue?: string[] | undefined;
    }
  | {
      type: FilterType.TEXT;
    }
  | {
      type: FilterType.UNIT;
    }
  | {
      type: FilterType.PROSPECT;
    };

export type Filter = {
  key: string;
  label: string;
  hide?: boolean;
  fatherOf?: string;
  // Includes the value in the filter values even if it is the default one
  includeWhenDefault?: boolean;
  readOnly?: boolean;
  required?: boolean;
} & FilterByType;

export type FilterValuesType = Record<string, any>;
