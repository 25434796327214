import { AntInput, AntSelect } from '@/components/Formik/CreateAntField';
import { ApplicationType } from '@/domain/applications';
import type { Option } from '@/domain/base';
import useEnumeration from '@/hooks/useEnumeration';
import { Button, Form, Modal } from 'antd';
import type { FormikHelpers } from 'formik';
import { Field, Formik } from 'formik';
import * as Yup from 'yup';

export interface InviteCoSignerValues {
  emailAddress: string;
  type: ApplicationType;
}

export interface InviteCoSignerModalProps {
  onSubmit: (values: InviteCoSignerValues, helpers: FormikHelpers<InviteCoSignerValues>) => void;
  loading: boolean;
  onCancel: () => void;
}

const validationSchema = Yup.object().shape({
  emailAddress: Yup.string()
    .typeError('Email is required')
    .email('Invalid Email')
    .required('Email is required'),
  type: Yup.string().required('Role is required'),
});

const InviteCoSignerModal = ({ onSubmit, loading, onCancel }: InviteCoSignerModalProps) => {
  const { options: applicationTypeOptions } = useEnumeration(
    'application-type',
    (at) => at.value !== ApplicationType.PRIMARY,
  );

  return (
    <Formik<InviteCoSignerValues>
      onSubmit={onSubmit}
      validationSchema={validationSchema}
      initialValues={{
        emailAddress: '',
        type: ApplicationType.COSIGNER,
      }}
    >
      {({ handleSubmit, submitCount }) => {
        return (
          <Form
            data-testid="invite-cosigner-modal"
            labelCol={{ span: 6 }}
            wrapperCol={{ span: 18 }}
            onSubmitCapture={handleSubmit}
          >
            <Modal
              visible
              onCancel={onCancel}
              title="Invite Co-Signer"
              maskClosable={false}
              footer={[
                <Button key="cancel" onClick={onCancel}>
                  Cancel
                </Button>,
                <Button
                  key="submit"
                  type="primary"
                  loading={loading}
                  onClick={() => handleSubmit()}
                  disabled={loading}
                  data-testid="invite-cosigner-submit"
                >
                  Submit
                </Button>,
              ]}
            >
              <div>
                <span>Email</span>
                <Field
                  component={AntInput}
                  name="emailAddress"
                  type="text"
                  placeholder="Email Address"
                  hasFeedback
                  data-testid="invite-cosigner-email"
                  submitCount={submitCount}
                />
              </div>
              <div>
                <span>Role</span>
                <Field
                  name="type"
                  component={AntSelect}
                  selectOptions={applicationTypeOptions}
                  getOptionLabel={(option: Option) => option.label}
                  getOptionValue={(option: Option) => option.value}
                  submitCount={submitCount}
                />
              </div>
            </Modal>
          </Form>
        );
      }}
    </Formik>
  );
};

export default InviteCoSignerModal;
