import type { Column, ColumnSettings } from '@/@propify-components';
import EnumValue from '@/components/TableUtils/EnumValue';
import type { EnumerationName } from '@/domain/base';
import { enumToLabel } from '@/utils/utils';
import isNil from 'lodash/isNil';

export interface EnumColumnSettings<T> extends ColumnSettings<T> {
  enumName: EnumerationName | string;
  getValue?: (rowData: T) => string | undefined;
}

export const enumColumn = <T extends any = any>(
  column: Column<T>,
  settings: EnumColumnSettings<T>,
): Column<T> => ({
  render: (data) => {
    return (
      <EnumValue
        enumName={settings.enumName}
        value={
          settings.getValue ? settings.getValue(data) : data[column.key as keyof T]?.toString()
        }
      />
    );
  },
  sortBy: !settings.getValue ? column.key : undefined,
  sortFn: settings.getValue
    ? (a, b) => {
        const aValue = settings.getValue?.(a);
        const bValue = settings.getValue?.(b);
        return isNil(bValue) ? 1 : isNil(aValue) ? -1 : aValue > bValue ? 1 : -1;
      }
    : undefined,
  getValueForExport: (row) =>
    settings.getValue
      ? settings.getValue(row)
      : enumToLabel(row[column.key as keyof T]?.toString() ?? ''),
  ...column,
});
