import type { Column } from '@/@propify-components';
import {
  Align,
  currencyColumn,
  dateColumn,
  dateTimeColumn,
  percentageColumn,
  timeColumn,
} from '@/@propify-components';
import ExternalLink from '@/components/ExternalLink';
import type { CustomField } from '@/domain/custom-fields';
import { CheckOutlined } from '@ant-design/icons';

export const customFieldColumns = (customFields: CustomField[]): Column[] =>
  customFields.map((customField) => {
    switch (customField.type) {
      case 'INTEGER':
      case 'DECIMAL':
        return {
          title: customField.description,
          key: customField.name,
          width: 100,
          align: Align.right,
        };
      case 'CURRENCY':
        return currencyColumn({ title: customField.description, key: customField.name });
      case 'PERCENT':
        return percentageColumn({ title: customField.description, key: customField.name });
      case 'DATE_TIME':
        return dateTimeColumn({ title: customField.description, key: customField.name });
      case 'DATE':
        return dateColumn({ title: customField.description, key: customField.name });
      case 'TIME':
        return timeColumn({ title: customField.description, key: customField.name });
      case 'URL':
        return {
          title: customField.description,
          key: customField.name,
          render: (row: any) =>
            row[customField.name] && (
              <ExternalLink href={row[customField.name]}>{row[customField.name]}</ExternalLink>
            ),
        };
      case 'EMAIL':
        return {
          title: customField.description,
          key: customField.name,
          render: (row: any) =>
            row[customField.name] && (
              <a href={`mailto:${row[customField.name]}`}>{row[customField.name]}</a>
            ),
        };
      case 'PHONE_NUMBER':
        return {
          title: customField.description,
          key: customField.name,
          render: (row: any) =>
            row[customField.name] && (
              <a href={`tel:${row[customField.name]}`}>{row[customField.name]}</a>
            ),
        };
      case 'LOCATION':
        return {
          title: customField.description,
          key: customField.name,
          width: 180,
          render: (row: any) =>
            row[customField.name] && (
              <ExternalLink
                href={`https://www.google.com/maps/place/${row[customField.name].latitude},${
                  row[customField.name].longitude
                }`}
              >
                {row[customField.name].latitude}, {row[customField.name].longitude}
              </ExternalLink>
            ),
        };
      case 'CHECKBOX':
        return {
          title: customField.description,
          key: customField.name,
          align: Align.center,
          width: 100,
          render: (row: any) => row[customField.name] && <CheckOutlined />,
        };
      default:
        return {
          title: customField.description,
          key: customField.name,
        };
    }
  });
