import { getEvents } from '@/utils/request';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { Button, notification } from 'antd';
import type { FC } from 'react';
import { useCallback, useEffect } from 'react';
import type { EntityType } from '../../../types';
import styles from './styles.module.less';

interface Props {
  entityType: EntityType;
  refreshEntities: () => void;
}

const requestEvents = (url: string): EventSource => getEvents(`${url}/events`);

const Events: FC<Props> = ({ entityType, refreshEntities }) => {
  const [api, contextHolder] = notification.useNotification();

  const openNotification = useCallback(() => {
    const key = `events`;
    const btn = (
      <Button
        type="link"
        size="small"
        onClick={() => {
          refreshEntities();
          notification.destroy();
        }}
      >
        Refresh
      </Button>
    );
    api.open({
      duration: 0,
      message: 'New Updates on the Page',
      icon: <ExclamationCircleOutlined style={{ color: '#7b61ff' }} />,
      btn,
      key,
      placement: 'bottomLeft',
      className: styles.newUpdatesSnackbar,
    });
  }, [api, refreshEntities]);

  const eventListener = useCallback(() => {
    openNotification();
  }, [openNotification]);

  useEffect(() => {
    const eventSource = requestEvents(entityType.endpoint);
    eventSource.addEventListener(`${entityType.name}_CREATED`, eventListener);
    eventSource.addEventListener(`${entityType.name}_UPDATED`, eventListener);
    eventSource.addEventListener(`${entityType.name}_DELETED`, eventListener);

    return () => {
      if (eventSource.readyState !== EventSource.CLOSED) {
        eventSource.close();
      }
    };
  }, [entityType, eventListener]);

  return <>{contextHolder}</>;
};

export default Events;
