import type { Lookup, LookupParams } from '@/domain/base';
import type { EntityType } from '@/domain/entity-type';
import type { NoteLookupParams } from '@/domain/note';
import type {
  Organization,
  PartyLookup,
  PartyLookupParams,
  Person as PersonLookup,
} from '@/domain/person';
import type { PromotionLookup } from '@/domain/promotions';
import type { Property } from '@/domain/property';
import type { ProspectLookup } from '@/domain/prospect';
import type { SmartHomeSystemLookupParams } from '@/domain/smart-home';
import type { UserLookup } from '@/domain/user';
import type { WorkOrder } from '@/domain/work-order';
import { api } from '@/utils/configuration';
import { getAxiosFetcher } from '@/utils/request';

interface SearchParams {
  q: string;
  entityType?: EntityType;
}

export type SearchResultRow = PersonLookup | Organization | Property | WorkOrder;
export interface LookupResults {
  PERSON: PersonLookup[];
  ORGANIZATION: Organization[];
  PROPERTY: Property;
  WORK_ORDER: WorkOrder[];
}

const LookupService = {
  notes: getAxiosFetcher<Lookup[], [NoteLookupParams]>((params) => [
    `${api.NOTE}/entities`,
    { params },
  ]),

  smartHomeSystem: getAxiosFetcher<Lookup[], [SmartHomeSystemLookupParams]>((params = {}) => [
    `${api.SMART_HOME_SYSTEM}/lookup`,
    { params },
  ]),

  units: getAxiosFetcher<Lookup[], [LookupParams]>((params = {}) => [
    `${api.UNIT}/lookup`,
    { params },
  ]),

  vendors: getAxiosFetcher<Lookup[], [LookupParams]>((params = {}) => [
    `${api.VENDOR}/lookup`,
    { params },
  ]),

  user: getAxiosFetcher<UserLookup[], [LookupParams?]>((params) => [
    `${api.USER}/lookup`,
    { params },
  ]),

  party: getAxiosFetcher<PartyLookup[], [PartyLookupParams]>((params = {}) => [
    `${api.PARTIES}/lookup`,
    { params },
  ]),

  promotion: getAxiosFetcher<PromotionLookup[], [LookupParams]>((params = {}) => [
    `${api.PROMOTIONS}/lookup`,
    { params },
  ]),

  prospect: getAxiosFetcher<ProspectLookup[], [LookupParams]>((params = {}) => [
    `${api.PROSPECT}/lookup`,
    { params },
  ]),

  search: getAxiosFetcher<LookupResults, [Partial<SearchParams>]>((params = {}) => [
    api.LOOKUP,
    { params },
  ]),
};

export type LookupType = keyof typeof LookupService;

export default LookupService;
